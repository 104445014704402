import "./updateAmount.scss";
import React from "react";
import { Formik, Form } from "formik";
import Select from "../formUiElements/select/Select";
import Button from "../formUiElements/Button";

export const UpdateAmount = (props) => {
  const reasons = [
    "Member Save",
    "Membership Upgrade",
    "Membership Downgrade",
    "Membership Discount",
    "Additional Purchase",
    "Other",
  ];
  const billingCredit = props?.billingDetails?.BillingCredit?.items || [];

  const totalBillingCredit = billingCredit?.length
    ? billingCredit.reduce((total, item) => total + (item.amount || 0), 0)
    : 0;

  const amount = totalBillingCredit
    ? (props.initialAmount - totalBillingCredit).toFixed(2)
    : props.initialAmount;

  console.log("amount", amount);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        amount: amount,
        reason: "",
        description: "",
      }}
      onSubmit={(values, OnSubmitProps) => {
        props.handleSubmit(values, OnSubmitProps);
      }}
      validate={(values, props) => {
        const { amount, reason, description } = values;

        let errors = {};

        if (amount.length === 0) {
          const errorText = "Please put amount";
          errors.amount = errorText;
        }

        if (amount < 0) {
          const errorText = "Amount needs to be greater than 0";
          errors.amount = errorText;
        }
        if (!reason) {
          const errorText = "Please choose a reason";
          errors.reason = errorText;
        }

        if (!description) {
          const errorText = "Please provide description";
          errors.description = errorText;
        }
        return errors;
      }}
    >
      {(formik) => {
        return (
          <Form className="status-modal edit-amount pd-32">
            <h3 className="fs-35 uppercase ">{props.headerText}</h3>

            <div className="input-container">
              <div className="input-and-dropdown pb-18">
                <div className="drop-down">
                  <label className=" reason fs-12" htmlFor="reason">
                    Reason *
                  </label>
                  <Select
                    label=""
                    name="reason"
                    optionvalues={reasons}
                    selecttype="array"
                    autoComplete="off"
                    className="fs-10 select-element-value"
                  />
                </div>
                <div className="amount-container">
                  <label className="fs-12" htmlFor="amount">
                    Amount *
                  </label>

                  <input
                    type="number"
                    name="amount"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    checked={formik.values.amountRadio}
                  />
                  {/* {billingCredit.length > 0 && (
                    <span className="fs-12 billing-credit">
                      ${billingCredit[0]?.amount} Billing Credit
                    </span>
                  )} */}

                  <div className="fs-12 error">
                    {formik.errors.amount && formik.errors.amount}
                  </div>
                </div>
              </div>
              <div className="desc-row">
                <h3 className="fs-12">Description *</h3>
                <textarea
                  name="description"
                  id="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                ></textarea>
                {formik.errors.description && formik.touched.description && (
                  <div className="fs-12 error">{formik.errors.description}</div>
                )}
              </div>
            </div>
            <div className="btn-group">
              {/* {formik.isSubmitting && (
                <Button
                  name={<BeatLoader color="white" />}
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-edit save btn-small fs-12"
                />
              )}
              {!formik.isSubmitting && (
                <Button
                  name="Save"
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-confirm btn-small"
                />
              )} */}
              <Button
                loading={formik.isSubmitting}
                name={"Save"}
                btntype="submit"
                btnname="submit"
                className="btn btn-confirm btn-small"
                disabled={formik.isSubmitting ? true : false}
              />
              <Button
                name="Cancel"
                btntype="submit"
                btnname="submit"
                className="btn btn-deny btn-small"
                click={props.cancelFn}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UpdateAmount;
