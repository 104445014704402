import React, { useEffect, useState } from "react";
import { Modal } from "../../../../../components";
import axios from "axios";
import "./modalTermsAndCondition.css";
import ReactQuill from "react-quill";
import { AiOutlineCloseCircle } from "react-icons/ai";
function ModalPrivacyPolicy({ onClose }) {
  const [privacyPolicy, setPrivacyPolicy] = useState();
  useEffect(() => {
    axios
      .get("https://cms.test.plus.fitness/api/privacy-policy")
      .then((res) => {
        setPrivacyPolicy(res.data);
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);
  return (
    <Modal>
      {privacyPolicy && (
        <div className="modalTermsAndCondition">
          {" "}
          <div
            className="closeButton"
            onClick={() => {
              if (typeof onClose === "function") {
                onClose();
              }
            }}
          >
            <AiOutlineCloseCircle />
          </div>
          <h1 class="heading heading-underline text-white">
            {privacyPolicy.data.attributes.title}
          </h1>
          <ReactQuill
            theme={"bubble"}
            readOnly={true}
            value={privacyPolicy.data.attributes.content}
          />
        </div>
      )}
    </Modal>
  );
}

export default ModalPrivacyPolicy;
