import "./filter.scss";


import Checkbox from "../formUiElements/Checkbox";
import Button from "../formUiElements/Button";

const Filter = (props) => {
  return (
    <div className="filter">
      {/* <div className="sort">
        <img src={desc_sort_icon} alt="sort" />
        <p className="fs-12">Sort A to Z</p>
      </div>
      <div className="sort">
        <img src={asc_sort_icon} alt="sort" />
        <p className="fs-12">Sort Z to A</p>
      </div> */}
      <div className="first-row">
        <Checkbox label="Sort A to Z" className="fs-12" />

        <Checkbox label="Sort Z to A" className="fs-12" />
      </div>
      <div className="mid-row">
        <Checkbox label="Select All" className="fs-12" />

        <Checkbox label="X-CELL HIIT" className="fs-12" />

        <Checkbox label="HIIT STEP" className="fs-12" />

        <Checkbox label="PUNCHFIT" className="fs-12 " />
        <Checkbox label="MOVE" className="fs-12 " />
        <Checkbox label="YOGA MASTER CLASS" className="fs-12 " />
        <Checkbox label="HYBRID" className="fs-12 nowrap" />
      </div>

      <div className="btn-group">
        <Button
          name="OK"
          btntype="button"
          className="btn btn-transparent fs-10"
        />
        <Button
          name="cancel"
          btntype="button"
          className="btn btn-transparent-red fs-10"
          click={props.closefilter}
        />
      </div>
    </div>
  );
};

export default Filter;
