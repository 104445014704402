const validateTimePeriodInput = (timePeriods, openTime, closeTime) => {
  let result = true;
  if (timePeriods && timePeriods.length !== 0) {
    const existTimePeriods = timePeriods.map((i) => {
      return (({ openTime, closeTime }) => ({ openTime, closeTime }))(i);
    });
    for (let i = 0; i < existTimePeriods.length; i++) {
      if (
        (openTime >= existTimePeriods[i].openTime &&
          openTime <= existTimePeriods[i].closeTime) ||
        (closeTime >= existTimePeriods[i].openTime &&
          closeTime <= existTimePeriods[i].closeTime) ||
        (openTime <= existTimePeriods[i].openTime &&
          closeTime >= existTimePeriods[i].closeTime)
      ) {
        result = false;
      }
    }
  }
  return result;
};

export default validateTimePeriodInput;
