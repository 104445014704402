import React, { useEffect, useState } from "react";
import { Button } from "../../../components";
import "./style.css";
import { useAddMemberContext } from "../../../contexts/AddMemberContext";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { useMemberContext } from "../../../contexts/MemberContext";
import CL_Logo from "../../../assets/images/icons/brand_logos/CL_Logo.png";
import VL_Logo from "../../../assets/images/icons/brand_logos/VL_Logo.png";
import PLUS_Logo from "../../../assets/images/icons/brand_logos/PF_Logo.png";
import RB_Logo from "../../../assets/images/icons/brand_logos/RB_Logo.png";
import HIIT_Logo from "../../../assets/images/icons/brand_logos/HR_Logo.png";
import GU_Logo from "../../../assets/images/icons/brand_logos/GU_Logo.png";
import UserDetails from "./elements/UserDetails";
import Membership from "./elements/Membership";
import Health from "./elements/Health";
import Payment from "./elements/Payment";
import Congrats from "./elements/Congrats";
import YourMembership from "./elements/YourMembership";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";

function AddMemberProcess() {
  const AddMemberStep = [
    { value: 0, name: "Contact" },
    { value: 1, name: "membership" },
    { value: 2, name: "Details" },
    { value: 3, name: "payment" },
  ];

  const { step, setStepHandler, prevStep } = useAddMemberContext();
  const { sidebarBrandName, sidebarLocationId } = useMemberContext();
  const [isExpand, setIsExpand] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const checkMobile = () => {
    setIsMobile(window.innerWidth < 1585);
  };

  window.addEventListener("resize", function () {
    checkMobile();
  });

  useEffect(() => {
    if (step > 1) {
      setStepHandler(1);
    }
  }, [sidebarLocationId]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 300);
  }, []);

  if (!sidebarLocationId) {
    return (
      <div className="no-data-holder">
        <div className="no-data">
          <h3 className="fs-12 uppercase">
            Please Choose a Club Location First
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`addMemberProcess ${isMobile ? "block" : ""}   ${
        loading && "overflowClip"
      }`}
    >
      <div
        className={`add-member-container ${
          step <= 1 || isMobile ? "full-width" : ""
        }`}
      >
        <header>
          <img
            id="logo"
            className="cl-logo"
            src={`${
              sidebarBrandName === "Club Lime"
                ? CL_Logo
                : sidebarBrandName === "GroundUp"
                ? GU_Logo
                : sidebarBrandName === "Hiit Republic"
                ? HIIT_Logo
                : sidebarBrandName === "Rebalance"
                ? RB_Logo
                : sidebarBrandName === "Plus Fitness"
                ? PLUS_Logo
                : sidebarBrandName === "Viva Leisure"
                ? VL_Logo
                : ""
            }`}
            alt="logo"
            width="100%"
          />
          <Link to={`/dashboard/member`}>
            <div className={step > 1 ? "close-icon" : ""}>
              <AiOutlineCloseCircle />
            </div>
          </Link>
        </header>
        {/* top navigate bar */}
        <div className="NavbarContainer">
          {AddMemberStep.map((i, index) => (
            <div
              className={`navbarItem fs-14 ${index <= step ? "active" : ""}`}
            >
              <span>{i.value + 1}</span>
              <p>{i.name}</p>
            </div>
          ))}
        </div>
        {/* components */}
        <div className={`step-detail-container `}>
          {loading && (
            <BeatLoader className="isloading" color="white" size="20" />
          )}
          <div className={`${step === 0 ? "moveInRight" : "moveOut"}`}>
            <UserDetails />
          </div>
          <div
            className={`${
              step === 1
                ? prevStep > 1
                  ? "moveInRight"
                  : "moveIn"
                : step < 1
                ? "rightFullOut  "
                : "moveOut "
            }`}
          >
            <Membership />
          </div>
          <div
            className={`${
              step === 2
                ? prevStep > 2
                  ? "moveInRight"
                  : "moveIn"
                : step < 2
                ? "rightFullOut"
                : "moveOut"
            }`}
          >
            <Health />
          </div>
          <div
            className={`${
              step === 3
                ? prevStep > 3
                  ? "moveInRight"
                  : "moveIn"
                : step < 3
                ? "rightFullOut"
                : "moveOut"
            }`}
          >
            <Payment />
          </div>
          <div
            className={`${
              step === 4 ? "moveIn" : step < 4 ? "rightFullOut" : "moveOut"
            }`}
          >
            <Congrats />
          </div>
        </div>
      </div>
      {step > 1 && !isMobile && (
        <div className="your-membership">
          <YourMembership />
        </div>
      )}
      {step > 1 && isMobile && (
        <div
          className={`your-membership-mobile ${isExpand ? "expanded" : ""}`}
          onClick={() => !isExpand && setIsExpand(!isExpand)}
        >
          {isExpand ? (
            <div className="container">
              <div
                className="collapse-btn"
                onClick={() => setIsExpand(false)}
              ></div>
              <YourMembership />
            </div>
          ) : (
            <div className="title">Your Membership</div>
          )}
        </div>
      )}
    </div>
  );
}
export const NavigateButtonGroup = () => {
  const { step, setStepHandler } = useAddMemberContext();

  return (
    <div>
      <div
        className={`button-navigate-container ${
          step === 0 ? "align-right" : ""
        }`}
      >
        {step !== 0 && (
          <Button
            click={() => {
              step > 0 && setStepHandler(step - 1);
            }}
            name="prev"
            btntype="button"
            btnname="button"
            className="btn btn-transparent btn-small"
          />
        )}
        {step !== 3 ? (
          <div>
            {step !== 4 && (
              <Button
                name="next"
                btntype="submit"
                btnname="submit"
                className="btn btn-transparent btn-small"
              />
            )}
          </div>
        ) : (
          <Button
            name="sign up"
            btntype="submit"
            btnname="submit"
            className="btn btn-primary btn-small"
          />
        )}
      </div>
    </div>
  );
};
export default AddMemberProcess;
