import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { GET_MEMBER_BY_ID } from "../../gqloperations/queries";
import { BeatLoader } from "react-spinners";
import {
  UserEmploymentInfo,
  UserContactInfo,
  UserPersonalInfo,
  Header,
} from "../../components";

const EditStaff = () => {
  const { id: staffId } = useParams();
  const { loggedUser } = useAuthContext();
  const { loading, error, data, refetch } = useQuery(GET_MEMBER_BY_ID, {
    variables: {
      memberId: staffId,
    },
  });
  if (loading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (error)
    return (
      <div>
        <div>Problem in getting members</div>
      </div>
    );
  const canEdit =
    loggedUser.getMember.role.substring(1) >
      data?.getMember.role.substring(1) || loggedUser.getMember.role === "L5";
  const pageTile = canEdit ? "Edit Staff" : "Staff Profile";
  return (
    <div>
      <Header pageTitle={pageTile} />
      {data && (
        <>
          <div className="profile-page mt-32">
            <UserPersonalInfo
              staffData={data}
              refetch={refetch}
              staffId={staffId}
              showEditBTN={canEdit}
            />
            <UserContactInfo
              staffData={data}
              refetch={refetch}
              showEditBTN={canEdit}
            />
            <UserEmploymentInfo
              staffData={data}
              refetch={refetch}
              showEditBTN={canEdit}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EditStaff;
