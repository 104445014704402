import "./bannerdetails.scss";

const BannerDetails = ({ data }) => {
  return (
    <div className="interaction-container pd-12">
      <h3 className="fs-14 mb-12 uppercase">Summary</h3>
      <div className="summary fs-12">
        <div className="summary-field-container">
          <span className="category fs-10">DD failure count:</span>
          <span className="fs-10">
            {data?.ddFailureCount ? data?.ddFailureCount : 0}
          </span>
        </div>
        <div className="summary-field-container">
          <span className="category fs-10">Active membership:</span>
          <span className="fs-10">
            {data?.hasActiveMembership ? "YES" : "NO"}
          </span>
        </div>
        <div className="summary-field-container">
          <span className="category fs-10">Active Fob:</span>
          <span className="fs-10">{data?.hasActiveFob ? "YES" : "NO"}</span>
        </div>
        <div className="summary-field-container">
          <span className="category fs-10">Payment details :</span>
          <span className="fs-10">
            {data?.hasPaymentDetails ? "YES" : "NO"}
          </span>
        </div>
        <div className="summary-field-container">
          <span className="category fs-10">No Visits (Days):</span>
          <span className="fs-10">
            {data?.noClubVisitsInDays ? data?.noClubVisitsInDays : 0}
          </span>
        </div>
      </div>
      <div>
        <label className="fs-10 category" htmlFor="fitnessPassport">
          Fitness passport barcode:
        </label>
        {data?.fpBarCode.length > 0 && (
          <ul className="barcode-list mb-12">
            {data.fpBarCode.map((cur, index) => (
              <li key={index} className="fs-10 text-gray">
                {index + 1}. <span className="text-white">{cur}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default BannerDetails;
