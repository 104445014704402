import increasingarrow_icon from "../../../assets/images/increasingarrow_icon.svg";
import decreasingarrow_icon from "../../../assets/images/decreasingarrow_icon.svg";
import "./trending.scss";

export default function Trend({ rate, text }) {
  return (
    <>
      {rate && (
        <div>
          <img
            src={rate >= 0 ? increasingarrow_icon : decreasingarrow_icon}
            alt="chat"
          />
          <span
            className={
              rate >= 0
                ? "rate increase-color fs-10"
                : "rate decrease-color fs-10"
            }
          >{`${rate}%`}</span>
        </div>
      )}
      <div>
        {text && !rate && (
          <>
            <span
              className={
                text === "increasing"
                  ? "rate increase-color fs-10"
                  : "rate decrease-color fs-10"
              }
            >
              Trending&nbsp;
            </span>

            <img
              src={
                text === "increasing"
                  ? increasingarrow_icon
                  : decreasingarrow_icon
              }
              alt="chat"
            />
          </>
        )}
      </div>
    </>
  );
}
