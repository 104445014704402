import { Formik, Form } from "formik";
import Button from "../formUiElements/Button";
import { REPORT_CARD_LOST } from "../../gqloperations/mutations";
import { GET_CARD_DETAILS_BY_MEMBERID } from "../../gqloperations/queries";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useMemberContext } from "../../contexts/MemberContext";
const DamagedCardModal = ({ cardId, modalClose }) => {
  const { member } = useMemberContext();
  const [reportCardLost] = useMutation(REPORT_CARD_LOST);

  const onSubmit = (values, OnSubmitProps) => {
    reportCardLost({
      variables: {
        ...values,
        id: cardId,
        isLost: true,
        operationName: "reportCardLost",
      },
      onCompleted: () => {
        OnSubmitProps.setSubmitting(false);
        toast.success("Updated Successfully");
        modalClose();
      },
      refetchQueries: [
        {
          query: GET_CARD_DETAILS_BY_MEMBERID,
          variables: {
            memberId: member.getMember.memberId,
          },
        },
      ],
    });
    OnSubmitProps.setSubmitting(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          lostReason: "",
        }}
        // validationSchema={guestEntrySchema}
        onSubmit={async (values, OnSubmitProps) => {
          await onSubmit(values, OnSubmitProps);
        }}
      >
        {(formik) => {
          return (
            <div className="damaged-card-container status-modal pd-32">
              <Form>
                <div className="damaged-card-header uppercase">
                  <h2 className="fs-35">Damaged/ Lost card</h2>
                </div>

                <div className="note-content-container">
                  <h3 className="fs-14 text-gray mb-8 item-left ">Reason *</h3>
                  <div className="note-content">
                    <textarea
                      className="reason-box fs-12"
                      id="lostReason"
                      name="lostReason"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lostReason}
                    ></textarea>
                  </div>
                </div>

                <div className="btn-container">
                  <Button
                    loading={formik.isSubmitting}
                    name={"Save"}
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-primary btn-lg fs-12"
                    disabled={formik.isSubmitting ? true : false}
                  />
                  <Button
                    name="cancel"
                    btntype="button"
                    btnname="cancel"
                    click={modalClose}
                    className="btn btn-deny btn-small fs-12"
                  />
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default DamagedCardModal;
