import React, { useEffect, useRef, useState } from "react";
import "./Voucher.scss";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useLazyQuery, useMutation } from "@apollo/client";
import actions from "../../../assets/images/icons/action_menu.png";
import {
  GET_VOUCHER_BY_CODE,
  LIST_VOUCHERS,
} from "../../../gqloperations/queries";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { useMemberContext } from "../../../contexts/MemberContext";
import {
  DELETE_VOUCHER,
  UPDATE_VOUCHER,
} from "../../../gqloperations/mutations";
import { Toggle, Button, Modal, StatusModal, Table } from "../../../components";
import SendVoucherModal from "./sendVoucherModal";
import { BeatLoader } from "react-spinners";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";
import { useHandleError } from '../../../hooks/useHandleError';

const Voucher = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const { createLog, voucherType, setVoucherType, sidebarBrandId } =
    useMemberContext();
  const { permissions } = useAuthContext();
  const [sorting, setSorting] = useState([]);
  const [showActions, setShowActions] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [modalSendVoucher, setModalSendVoucher] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [tableData, setTableData] = useState();
  const [voucherId, setVoucherId] = useState();
  const [deleteVoucher, { loading: deleteVoucherLoading }] =
    useMutation(DELETE_VOUCHER);
  const [updateVoucher, { loading: updateVoucherLoading }] =
    useMutation(UPDATE_VOUCHER);
  const [activeVoucherModal, setActiveModalVoucher] = useState(false);
  const [deactivateVoucherModal, setDeactivateModalVoucher] = useState(false);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [voucherMemberType, setVoucherMembertype] = useState("");
  const [nextToken, setNextToken] = useState();
  const handleError = useHandleError();
  const [fetchListVoucherQuery, { loading: fetchListVouchersLoading }] =
    useLazyQuery(LIST_VOUCHERS);
  const [getVoucherByCode] = useLazyQuery(GET_VOUCHER_BY_CODE);

  const compareDates = (a, b) => {
    const dateA = new Date(a?.createdAt);
    const dateB = new Date(b?.createdAt);

    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }
    return 0;
  };
  const fetchListVoucher = async () => {
    fetchListVoucherQuery({
      fetchPolicy: "no-cache",
      variables: {
        filter: {
          type: { eq: voucherType },
        },
        limit: 1000,
      },
      onCompleted: (data) => {
        setNextToken(data?.listVouchers?.nextToken);
        let copyData = data?.listVouchers?.items;
        copyData.sort(compareDates);
        setTableData(copyData);
      },
      onError: (error) => {
        handleError(`Error:${error}`);
      },
    });
  };
  const handleNextToken = async () => {
    if (nextToken) {
      fetchListVoucherQuery({
        fetchPolicy: "no-cache",
        variables: {
          filter: {
            type: { eq: voucherType },
          },
          limit: 1000,
          nextToken: nextToken,
        },
        onCompleted: (data) => {
          setNextToken(data?.listVouchers?.nextToken);
          let copyData = data?.listVouchers?.items;
          copyData.sort(compareDates);
          setTableData([...tableData, ...copyData]);
        },
        onError: (error) => {
          handleError(`Error:${error}`);
        },
      });
    }
  };
  useEffect(() => {
    fetchListVoucher();
  }, [voucherType]);

  const handleViewVoucher = (idVoucher) => {
    navigate(`/dashboard/membership/viewvoucher/${idVoucher}`);
  };

  const handleSearch = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
  };

  const onSearchBarSubmit = async (event) => {
    event.preventDefault();
    if (searchBarInputVal) {
      await getVoucherByCode({
        fetchPolicy: "no-cache",
        variables: {
          voucherCode: searchBarInputVal.trim(),
        },
        onCompleted: (data) => {
          const filterVoucherBytype = data.getVoucherByVoucherCode.items.filter(
            (item) => item.type === voucherType
          );

          setTableData(filterVoucherBytype);
        },
      });
    } else {
      fetchListVoucher();
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      setShowActions({});
    };

    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [ref]);

  const confirmDeleteVoucher = (voucherId) => {
    setDeleteModal(true);
    setVoucherId(voucherId);
  };
  const handleDeleteVoucher = () => {
    deleteVoucher({
      variables: {
        input: {
          id: voucherId,
        },
      },
      onCompleted: () => {
        setDeleteModal(false);
        toast.success("Deleted voucher successfully");
        fetchListVoucher();
      },
      onError: (e) => {
        handleError(`Error: ${e}`);
      },
    });
  };

  const confirmActiveVoucher = (voucherId) => {
    setActiveModalVoucher(true);
    setVoucherId(voucherId);
  };
  const activeVoucher = () => {
    updateVoucher({
      variables: {
        input: {
          id: voucherId,
          isActive: true,
        },
      },
      onCompleted: () => {
        toast.success("Voucher Activated Successfully");
        fetchListVoucher();
        setActiveModalVoucher(false);
      },
      onError: (error) => {
        handleError(`${error}`);
        return;
      },
    });
  };
  const confirmDeactivateVoucher = (voucherId) => {
    setDeactivateModalVoucher(true);
    setVoucherId(voucherId);
  };
  const deactivateVoucher = () => {
    updateVoucher({
      variables: {
        input: {
          id: voucherId,
          isActive: false,
        },
      },
      onCompleted: () => {
        toast.success("Voucher Deactivated Successfully");
        fetchListVoucher();
        setDeactivateModalVoucher(false);
      },
      onError: (error) => {
        handleError(`${error}`);
        return;
      },
    });
  };

  const columns = [
    {
      accessorFn: (row) => row.membershipDetail?.membershipName,
      header: "Membership",
      cell: (info) => {
        return <a href="membership#">{info?.getValue()}</a>;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "membershipType",
    },
    {
      accessorFn: (row) => row?.voucherCode,
      header: "Code",
      cell: (info) => {
        return <a href="membership#">{info?.getValue()}</a>;
      },
      enableSorting: true,
      enableGlobalFilter: true,
      id: "code",
    },
    ...(voucherType === "VISIT_PASS"
      ? [
          {
            accessorFn: (row) => row?.visit,
            header: "Visit",
            cell: (info) => {
              return <a href="membership#">{info?.getValue()}</a>;
            },
            enableSorting: false,
            enableGlobalFilter: false,
            id: "visit",
          },
        ]
      : []),
    ...(voucherType !== "VISIT_PASS"
      ? [
          {
            accessorFn: (row) => row?.startDateTime,
            header: "From",
            cell: (info) => {
              const date = info?.getValue()
                ? format(new Date(info?.getValue()), "dd/MM/yyyy")
                : "";
              return date;
            },
            enableSorting: false,
            enableGlobalFilter: false,
            id: "from",
          },
        ]
      : []),
    ...(voucherType !== "VISIT_PASS"
      ? [
          {
            accessorFn: (row) => row?.endDateTime,
            header: "To",
            cell: (info) => {
              const date = info?.getValue()
                ? format(new Date(info?.getValue()), "dd/MM/yyyy")
                : "";
              return date;
            },
            enableSorting: false,
            enableGlobalFilter: false,
            id: "to",
          },
        ]
      : []),
    {
      accessorFn: (row) => row?.quantity,
      header: "Quantity",
      cell: (info) => {
        return <a href="membership#">{info?.getValue()}</a>;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "quantity",
    },
    {
      accessorFn: (row) => (row?.used ? row?.used : 0),
      header: "used",
      cell: (info) => {
        return <a href="membership#">{info?.getValue()}</a>;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "used",
    },
    ...(voucherType !== "COMPLIMENTARY"
      ? [
          {
            accessorFn: (row) => row,
            header: "Cost",
            cell: (info) => {
              const voucherDetails = info?.getValue();
              return (
                <a href="membership#">
                  $
                  {voucherType === "PERCENT"
                    ? (
                        voucherDetails?.membershipDetail?.costPrice -
                        voucherDetails?.membershipDetail?.costPrice *
                          (voucherDetails?.cost * 0.01)
                      ).toFixed(2)
                    : voucherDetails?.cost}
                </a>
              );
            },
            enableSorting: false,
            enableGlobalFilter: false,
            id: "cost",
          },
        ]
      : [
          {
            accessorFn: (row) => row,
            header: "Activation",
            cell: (info) => {
              return (
                <a href="membership#">{info?.getValue()?.activationFee}</a>
              );
            },
            enableSorting: false,
            enableGlobalFilter: false,
            id: "cost",
          },
        ]),
    {
      accessorFn: (row) => {
        return (
          <Toggle
            checked={row.isActive ?? false}
            value={row.isActive ?? false}
            onChange={(e) => {
              if (row.isActive) {
                confirmDeactivateVoucher(row?.id);
              } else {
                confirmActiveVoucher(row?.id);
              }
            }}
          />
        );
      },
      header: "Status",
      cell: (info) => {
        return <a href="membership#">{info?.getValue()}</a>;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "status",
    },
    {
      id: "row-actions5",
      header: "ACTIONS",
      cell: (props) => {
        const isVoucherAppliedToLocation =
          props?.row?.original?.voucherLocation?.items.length > 0;
        const {
          id: voucherId,
          isActive,
          voucherMembers,
          memberType,
          type,
        } = props?.row?.original;
        const areActionsVisible = showActions.hasOwnProperty(voucherId);
        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              alt="action-icon"
              onClick={(e) => {
                e.stopPropagation();
                setShowActions({ [voucherId]: true });
              }}
            />
            {areActionsVisible && (
              <span className="action-options-menu " ref={ref}>
                <ul className="membership-options">
                  <li onClick={() => handleViewVoucher(voucherId)}>
                    <Button
                      name="view"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li
                    onClick={() =>
                      navigate(`/dashboard/membership/editvoucher/${voucherId}`)
                    }
                  >
                    <Button
                      name="edit"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li
                    onClick={() => {
                      setVoucherId(voucherId);
                      setModalSendVoucher(true);
                      setVoucherMembertype(memberType);
                    }}
                    className={
                      !isActive || !isVoucherAppliedToLocation ? "disabled" : ""
                    }
                  >
                    <Button
                      name="send"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent  btn-small"
                    />
                  </li>

                  <li
                    onClick={
                      isVoucherAppliedToLocation
                        ? () =>
                            navigate(
                              `/dashboard/membership/addvouchertomember/${voucherId}`
                            )
                        : () => {}
                    }
                    className={
                      !isActive ||
                      !isVoucherAppliedToLocation ||
                      memberType === "NEW"
                        ? "disabled"
                        : ""
                    }
                  >
                    <Button
                      disabled={!isActive}
                      name="apply to a member"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li
                    onClick={() =>
                      navigate(
                        `/dashboard/membership/addvouchertolocation/${voucherId}`
                      )
                    }
                    className={!isActive ? "disabled" : ""}
                  >
                    <Button
                      disabled={!isActive}
                      name="apply to location"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  {permissions.includes("CanDeleteVoucher") && (
                    <li
                      onClick={() => confirmDeleteVoucher(voucherId)}
                      className={voucherMembers.items.length ? "disabled" : ""}
                    >
                      <Button
                        name="delete"
                        btntype="submit"
                        btnname="submit"
                        className="btn btn-transparent btn-small"
                      />
                    </li>
                  )}
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="voucher-container">
        <div className="tabs mt-32">
          <div className="tabs-list">
            <div
              className={
                voucherType !== "FIXED"
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => {
                setVoucherType("FIXED");
              }}
            >
              FIXED VOUCHERS
            </div>

            <div
              className={
                voucherType !== "PERCENT"
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => {
                setVoucherType("PERCENT");
              }}
            >
              % VOUCHERS
            </div>
            <span className="tooltip">
              <div
                className={
                  voucherType !== "VISIT_PASS"
                    ? "tab-head fs-14 back-mediumgray"
                    : "tab-head fs-14 back-persianblue"
                }
                // onClick={() => {
                //   setVoucherType("VISIT_PASS");
                // }}
              >
                VISIT PASS VOUCHERS
              </div>
              <span className="tooltip-text fs-10">Coming Soon</span>
            </span>

            <div
              className={
                voucherType !== "COMPLIMENTARY"
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => {
                setVoucherType("COMPLIMENTARY");
              }}
            >
              COMPLIMENTARY VOUCHERS
            </div>
          </div>
        </div>
        <div className="actions-row justify-end  mt-24">
          <div className="right-elements">
            <div className="search-and-button-row">
              <div className="search-container">
                <form onSubmit={onSearchBarSubmit}>
                  <div className="search-bar">
                    <div className="search-bar-container">
                      <input
                        className="fs-10"
                        type="text"
                        name="search"
                        id="search"
                        placeholder="Voucher code"
                        value={searchBarInputVal}
                        onChange={handleSearch}
                      />
                      <button type="submit">
                        <AiOutlineSearch />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* // <Link to="/dashboard/membership/addvoucher">
          //   <div className="add-membership-btn">
          //     <Button
          //       name="Add Voucher"
          //       btntype="submit"
          //       btnname="submit"
          //       className="btn btn-primary add-membership-btn"
          //     />
          //   </div>
          // </Link> */}
            {((voucherType === "FIXED" &&
              permissions.includes("CanCreateFixedPriceVoucher")) ||
              (voucherType === "PERCENT" &&
                permissions.includes("CanCreatePercentageVoucher")) ||
              (voucherType === "VISIT_PASS" &&
                permissions.includes("CanCreateLimitedVoucher")) ||
              (voucherType === "COMPLIMENTARY" &&
                permissions.includes("CanCreateComplimentaryVoucher"))) && (
              <Link to="/dashboard/membership/addvoucher">
                <div className="add-membership-btn">
                  <Button
                    name="Add Voucher"
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-primary add-membership-btn"
                  />
                </div>
              </Link>
            )}
          </div>
        </div>
        {fetchListVouchersLoading ? (
          <div className="">
            <BeatLoader className="loading" color="white" />
          </div>
        ) : (
          <div className="voucher-table">
            <Table
              onRowClick={(i) => {
                if (i.id) handleViewVoucher(i.id);
                createLog(`Viewed Voucher ${i.voucherCode}`);
              }}
              data={tableData}
              sorting={sorting}
              setSorting={setSorting}
              columns={columns}
              rowsPerPage={10}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              nextToken={nextToken}
              handleNextToken={handleNextToken}
            />
          </div>
        )}
      </div>
      {deleteModal && (
        <Modal>
          <StatusModal
            modalHeaderTxt={"Delete Voucher"}
            modalParaText={"Are you sure you want to delete this voucher?"}
            modalClose={() => setDeleteModal(false)}
            handleStatusClick={() => handleDeleteVoucher()}
            loading={deleteVoucherLoading}
          />
        </Modal>
      )}
      {activeVoucherModal && (
        <Modal>
          <StatusModal
            modalHeaderTxt={"Activate voucher"}
            modalParaText={"Are you sure you want to activate this voucher?"}
            handleStatusClick={activeVoucher}
            loading={updateVoucherLoading}
            modalClose={() => setActiveModalVoucher(false)}
          />
        </Modal>
      )}
      {deactivateVoucherModal && (
        <Modal>
          <StatusModal
            modalHeaderTxt={"Deactivate voucher"}
            modalParaText={"Are you sure you want to deactivate this voucher?"}
            handleStatusClick={deactivateVoucher}
            loading={updateVoucherLoading}
            modalClose={() => setDeactivateModalVoucher(false)}
          />
        </Modal>
      )}
      {modalSendVoucher && (
        <Modal>
          <SendVoucherModal
            voucherId={voucherId}
            sidebarBrandId={sidebarBrandId}
            setModalSendVoucher={setModalSendVoucher}
            memberType={voucherMemberType}
          />
        </Modal>
      )}
    </>
  );
};

export default Voucher;
