import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { SEND_SLACK_MESSAGE } from "../gqloperations/mutations";
import { mapStackTrace } from "sourcemapped-stacktrace";
import { useAuthContext } from "../contexts/AuthContext/AuthProvider";

// USAGE:
// import { useHandleError } from '../../../utils/errorHandler';
// const handleError = useHandleError();
// handleError("THIS IS AN ERROR", { context: "here" });

// Function to find the common prefix
function trimPathsInBracketsBeforeSrc(lines) {
  return lines.map(line => {
    const regex = /\(([^)]+)\)/; // Regular expression to find text within parentheses
    const match = line.match(regex);

    if (match) {
      const path = match[1]; // Extract the path within parentheses
      const srcIndex = path.indexOf('src/');
      
      if (srcIndex !== -1) {
        const trimmedPath = path.substring(srcIndex);
        return line.replace(path, trimmedPath);
      }
    }
    return line;
  });
}

function capitalize(word) {
  return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
}

// Create a custom hook to use the Slack message mutation
export const useHandleError = () => {
  const { loggedUser } = useAuthContext();
  const [sendSlackMessage] = useMutation(SEND_SLACK_MESSAGE);

  // Error handling function
  const handleError = async (errorMessage, context, sendSlack = false) => {
    const cleanedErrorMessage = errorMessage.replace("ApolloError: ", "");

    // Display toast notification
    toast.error(cleanedErrorMessage);

    if (!sendSlack) return;

    // Get mapped stack
    let mappedStack = await new Promise((resolve) => {
      mapStackTrace(context?.stack || new Error().stack, (mappedStack) => {
        resolve(mappedStack);
      });
    });

    // Remove references to this error handler and promise creation
    mappedStack = mappedStack.filter((line, i) => !line.includes("errorHandler.js") && !(line.includes("at new Promise (<anonymous>)") && i < 3));

    // Remove everyhing before the src folder
    const cleanedStackTrace = trimPathsInBracketsBeforeSrc(mappedStack);

    const user = {
      givenName: capitalize(loggedUser.getMember.givenName),
      surname: capitalize(loggedUser.getMember.surname),
      id: loggedUser.getMember.memberId,
      email: loggedUser.getMember.email.toLowerCase(),
      mobileNumber: loggedUser.getMember.mobileNumber,
    };

    // Send error message to Slack

    const slackMessage = `‼️ *${errorMessage}*
Location: ${window.location.pathname}`;

    const attachments = [
      {
        // color: "#1f2937",
        title: `User: ${user.givenName} ${user.surname} - ${user.email}`,
        text: "```" + JSON.stringify(user, null, 2) + "```",
      },
    ];
    if (context) {
      attachments.push({
        // color: "#1f2937",
        title: `Context`,
        text: "```" + JSON.stringify(context, null, 2) + "```",
      });
    }
    attachments.push({
      // color: "#ff0000",
      title: "Stack Trace",
      text: "```" + errorMessage + "\n" + cleanedStackTrace.join("\n") + "```",
    });
    try {
      await sendSlackMessage({
        variables: {
          operationName: "SEND_SLACK_MESSAGE",
          destination: "FRONTEND",
          message: JSON.stringify({
            text: slackMessage,
            attachments
          }),
        },
      });
    } catch (mutationError) {
      console.error("Failed to send Slack message:", mutationError);
    }
  };

  return handleError;
};
