import "./healthQuestionnaire.scss";
import React, { useEffect, useState } from "react";
import { AiOutlineEdit, AiOutlineSearch } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import getTypeIcon from "../../utils/getTypeIcon";
import { brandTypes } from "../../constants/commonConstant";
import { useMemberContext } from "../../contexts/MemberContext";
import { BiDetail, BiTrashAlt } from "react-icons/bi";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { GET_ALL_HEALTHQUESTIONNAIRE } from "../../gqloperations/queries";
import { useMutation, useQuery } from "@apollo/client";
import { StatusModal, Modal, Button, Table, Header } from "../../components";
import { toast } from "react-toastify";
import { EDIT_HEALTHQUESTION } from "../../gqloperations/mutations";
import { BeatLoader } from "react-spinners";
import { isAfter } from "date-fns";
import { useHandleError } from '../../hooks/useHandleError';

function HealthQuestionnaire() {
  const navigate = useNavigate();
  const { permissions } = useAuthContext();
  const { sidebarBrandId } = useMemberContext();
  const [tableData, setTableData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [componentName, setComponentName] = useState("");
  const [curRow, setCurRow] = useState({});
  const [isPaginating, setIsPaginating] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const handleError = useHandleError();
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [updateHealthQuestionnaire, { loading: updateHealthQuestionLoading }] =
    useMutation(EDIT_HEALTHQUESTION);

  const columns = [
    {
      accessorKey: "brandDetails",
      header: "BRAND",

      cell: (info) => {
        const brandName = info.getValue() ? info.getValue()?.name : "";
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "brandDetails",
    },
    {
      accessorFn: (row) => {
        return row.question;
      },
      header: "QUESTION",
      enableSorting: true,
      enableGlobalFilter: true,
      id: "question",
      cell: (info) => {
        return <span className="question-text">{info.getValue()}</span>;
      },
    },
    {
      accessorKey: "sectionLabel",
      header: "LABEL",
      enableSorting: true,
      enableGlobalFilter: false,
      id: "sectionLabel",
      cell: (info) => {
        return info?.getValue();
      },
    },
    {
      header: "STATUS",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "isActive",
      cell: (info) => {
        return info.getValue();
      },
      accessorFn: (row) => {
        if (row.isActive) return <span className="active">Active</span>;
        return <span className="inactive">Inactive</span>;
      },
    },
    {
      id: "row-actions5",
      header: "",
      cell: (props) => {
        const { id } = props?.row?.original;
        return (
          <span className="button-td">
            <AiOutlineEdit
              onClick={() => {
                handleEdit(id);
              }}
            />
            <BiDetail
              onClick={() => {
                handleView(id);
              }}
            />
            <BiTrashAlt
              onClick={() => {
                handleComponent("delete-template", {
                  questionId: id,
                });
              }}
            />
          </span>
        );
      },
    },
  ];

  const handleView = (questionId) => {
    navigate(`/dashboard/healthquestions/viewhealthquestion/${questionId}`);
  };

  const handleEdit = (questionId) => {
    navigate(`/dashboard/healthquestions/edithealthquestion/${questionId}`);
  };

  const {
    data: listHealthQuestionData,
    loading: listHealthQuestionLoading,
    error: listHealthQuestionError,
    refetch,
  } = useQuery(GET_ALL_HEALTHQUESTIONNAIRE, {
    fetchPolicy: "no-cache",
    variables: {
      nextToken: nextToken,
      filter: {
        and: [
          { isDeleted: { ne: true } },
          { brandId: { eq: `${sidebarBrandId}` } },
        ],
      },
    },
  });

  useEffect(() => {
    if (listHealthQuestionData) {
      const { items } = listHealthQuestionData.listHealthQuestionnaires;
      if (tableData && isPaginating) {
        const sortedCombinedItems = sortByCreatedAtDesc([
          ...tableData,
          ...items,
        ]);
        setTableData(sortedCombinedItems);
      } else {
        const sortedItems = sortByCreatedAtDesc([...items]);
        setTableData(sortedItems);
      }
      setIsPaginating(false);
      // setNextToken(nextToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listHealthQuestionData]);

  const handleNextToken = () => {
    if (listHealthQuestionData?.listHealthQuestionnaires?.nextToken) {
      setIsPaginating(true);
      refetch({
        nextToken: listHealthQuestionData.listHealthQuestionnaires.nextToken,
      });
    }
  };

  const handleComponent = (componentName, rowObj) => {
    setComponentName(componentName);
    setCurRow(rowObj);
  };

  const modalClose = () => {
    setComponentName("");
    setCurRow({});
  };

  const handleDeleteClick = async (rowObj) => {
    let values = {
      id: rowObj.questionId,
      isDeleted: true,
      isActive: false,
    };
    try {
      await updateHealthQuestionnaire({
        variables: {
          input: values,
        },
        onCompleted: () => {
          setNextToken();
          setIsPaginating(false);
          refetch();
          toast.success("Question has been deleted successfully");
          modalClose();
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };

  const handleStatusClick = async (rowObj) => {
    let values = {
      id: rowObj.id,
      isActive: !rowObj.isActive,
    };

    try {
      await updateHealthQuestionnaire({
        variables: {
          input: values,
        },
        onCompleted: () => {
          refetch();
          toast.success("Updated successfully");
          modalClose();
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };

  const sortByCreatedAtDesc = (arr) => {
    return arr.sort((a, b) => {
      const result = isAfter(new Date(a.createdAt), new Date(b.createdAt));
      return result ? -1 : 1;
    });
  };

  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal?.length === 0) {
      setGlobalFilter(searchVal);
    }
  };

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  if (listHealthQuestionLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );

  if (listHealthQuestionError) {
    return (
      <div>
        <div>Problem in getting health questions</div>
      </div>
    );
  }

  return (
    <div className="healthQuestionnaire-container">
      <Header pageTitle="Health Questionnaire" />
      <div className="healthQuestionnaire-landing-content">
        {componentName && (
          <Modal>
            {componentName === "delete-template" && (
              <StatusModal
                curRow={curRow}
                modalHeaderTxt={"Delete Question"}
                modalParaText={"Are you sure you want to delete this question?"}
                handleStatusClick={handleDeleteClick}
                loading={updateHealthQuestionLoading}
                modalClose={modalClose}
              />
            )}
            {componentName === "status-confirm" && (
              <StatusModal
                curRow={curRow}
                modalHeaderTxt={`${
                  curRow.isActive ? "deactivate" : "activate"
                } question`}
                modalParaText={`Are you sure you want to ${
                  curRow.isActive ? "deactivate" : "activate"
                } this question?`}
                handleStatusClick={handleStatusClick}
                modalClose={modalClose}
              />
            )}
          </Modal>
        )}
        {
          <>
            <div className="actions-row mt-32  feature-row ">
              <div className="right-elements">
                <div className="search-and-button-row">
                  <div className="search-container">
                    <form onSubmit={onSearchBarSubmit}>
                      <div className="search-bar">
                        <div className="search-bar-container">
                          <input
                            className="fs-10"
                            type="text"
                            name="search"
                            id="search"
                            value={searchBarInputVal}
                            onChange={handleChange}
                            placeholder="Question name"
                          />
                          <button type="submit">
                            <AiOutlineSearch />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {
                  <Link to="/dashboard/healthquestions/addquestionnaire">
                    <div className="add-questionnaire-btn">
                      <Button
                        name="Add question"
                        btntype="submit"
                        btnname="submit"
                        className="btn btn-primary add-questionnaire-btn"
                        click={() => {}}
                      />
                    </div>
                  </Link>
                }
              </div>
            </div>

            <div className="landing-table">
              {!listHealthQuestionLoading && !listHealthQuestionError && (
                <Table
                  onRowClick={(i) => handleView(i.id)}
                  data={tableData}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  columns={columns}
                  sorting={sorting}
                  setSorting={setSorting}
                  rowsPerPage={10}
                  handleNextToken={handleNextToken}
                  nextToken={
                    listHealthQuestionData?.listHealthQuestionnaires?.nextToken
                  }
                  columnFilters={columnFilters}
                  setColumnFilters={setColumnFilters}
                />
              )}
            </div>
          </>
        }
      </div>
    </div>
  );
}

export default HealthQuestionnaire;
