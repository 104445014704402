import "./clubFeatures.scss";
import Toggle from "../../../formUiElements/Toggle";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_LOCATION_FEATURE,
  UPDATE_LOCATION_FEATURE,
} from "../../../../gqloperations/mutations";
import FeatureTileGrid from "./feature_tiles/FeatureTileGrid";
import { toast } from "react-toastify";
import { GET_LIST_FEATURES } from "../../../../gqloperations/queries";
import { Storage } from "aws-amplify";
import StatusModal from "../../../modal/StatusModal";
import Modal from "../../../modal/Modal";
import { useLocationContext } from "../../../../contexts/LocationContext";
import { useHandleError } from '../../../../hooks/useHandleError';

const ClubFeatures = (props) => {
  const { state, getLocationById } = useLocationContext();

  const [currentFeature, setCurrentFeature] = useState({
    isAvailable: "",
    featureId: "",
    existingFeature: [],
    featureName: "",
  });
  const [componentName, setComponentName] = useState("");
  const [listFeatureImgs, setListFeatureImgs] = useState([]);
  const { data: listFeatureData } = useQuery(GET_LIST_FEATURES);
  const [updateLocationFeature, { loading: updateFeatureLoading }] =
    useMutation(UPDATE_LOCATION_FEATURE);
  const [createLocationFeature, { loading: createFetureLoading }] = useMutation(
    CREATE_LOCATION_FEATURE
  );
  const handleError = useHandleError();

  const featureAvailableImg = async (locationFeatures) => {
    try {
      if (locationFeatures) {
        const newFeatureImages = [];

        if (locationFeatures.items && locationFeatures.items.length > 0) {
          for (const feature of locationFeatures.items) {
            if (feature.isAvailable) {
              try {
                const data = await Storage.get(
                  feature.featureDetails.imageUrl,
                  {
                    download: true,
                  }
                );

                const reader = new FileReader();
                reader.readAsDataURL(data.Body);

                const decodedImage = await new Promise((resolve) => {
                  reader.onloadend = () => resolve(reader.result);
                });

                newFeatureImages.push({
                  clubImgUrl: decodedImage,
                  featureDetails: { ...feature.featureDetails },
                });
              } catch (e) {
                console.log(e);
              }
            }
          }
          setListFeatureImgs([...listFeatureImgs, ...newFeatureImages]);
        }
      }
    } catch (error) {
      handleError(`Error: ${error}`);
    }
  };

  useEffect(() => {
    if (state.index === 2) {
      featureAvailableImg(state?.location?.getLocation.locationFeatures);
    }
  }, [state.index]);

  const newFeatureAvailableImg = (feature) => {
    Storage.get(feature.featureDetails.imageUrl, {
      download: true,
    })
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data.Body);
        reader.onloadend = () => {
          const decodedImage = reader.result;
          setListFeatureImgs((prevList) => [
            ...prevList,
            {
              clubImgUrl: decodedImage,
              featureDetails: { ...feature.featureDetails },
            },
          ]);
        };
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getIsFeatureAvailable = (featureId) => {
    let existingFeature =
      state?.location?.getLocation.locationFeatures.items.some(
        (obj) => obj.featureDetails.id === featureId && obj.isAvailable
      ) || false;
    return existingFeature;
  };

  const handleAddFeatureClick = async () => {
    try {
      await createLocationFeature({
        variables: {
          input: {
            locationId: props.locationId,
            isAvailable: currentFeature.isAvailable,
            featureId: currentFeature.featureId,
          },
        },
        onCompleted: (data) => {
          getLocationById({
            fetchPolicy: "no-cache",
            variables: { id: props.locationId },
          });
          setComponentName("");
          if (data.createLocationFeature.isAvailable) {
            newFeatureAvailableImg(data.createLocationFeature);
          }
          toast.success("Saved");
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };

  const handleUpdateFeatureClick = async () => {
    try {
      await updateLocationFeature({
        variables: {
          input: {
            id: currentFeature.existingFeature?.[0].id,
            locationId: props.locationId,
            isAvailable: currentFeature.isAvailable,
            featureId: currentFeature.featureId,
          },
        },
        onCompleted: (data) => {
          setComponentName("");
          if (data.updateLocationFeature.isAvailable) {
            newFeatureAvailableImg(data.updateLocationFeature);
          } else {
            setListFeatureImgs((currentList) => {
              const updatedList = currentList.filter(
                (item) =>
                  item.featureDetails.id !==
                  data.updateLocationFeature?.featureDetails.id
              );

              return updatedList;
            });
          }
          getLocationById({
            fetchPolicy: "no-cache",
            variables: { id: props.locationId },
          });
          toast.success("Saved");
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };

  return (
    <>
      {listFeatureData?.listFeatures?.items.length && (
        <div className="club-features-container pd-12">
          <div className="title-bar">
            <h3 className="fs-14">Club Features</h3>
          </div>
          <div className="club-features ">
            <div className="voucher-grid-4">
              {listFeatureData?.listFeatures?.items?.map((feature, idx) => (
                <div key={idx}>
                  <Toggle
                    label={feature.name}
                    value={currentFeature.featureId}
                    name={feature.id}
                    onChange={(event) => {
                      const existingFeature =
                        state?.location?.getLocation.locationFeatures.items.filter(
                          (obj) => obj.featureDetails.id === feature.id
                        );
                      setCurrentFeature({
                        isAvailable: event.target.checked,
                        featureId: event.target.name,
                        existingFeature: existingFeature,
                        featureName: feature.name,
                      });

                      if (existingFeature.length > 0) {
                        setComponentName("update-feature");
                      } else {
                        setComponentName("add-feature");
                      }
                    }}
                    checked={getIsFeatureAvailable(feature.id)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {state?.location?.getLocation.locationFeatures.items.some(
        (feature) => feature.isAvailable
      ) ? (
        <FeatureTileGrid listFeatureImgs={listFeatureImgs} />
      ) : null}
      {componentName === "add-feature" && (
        <Modal>
          <StatusModal
            modalHeaderTxt="Add Feature"
            modalParaText={`Are you sure you want to add ${currentFeature.featureName} feature to this club?`}
            handleStatusClick={handleAddFeatureClick}
            modalClose={() => setComponentName("")}
            loading={createFetureLoading}
            btnName={"Yes"}
          />
        </Modal>
      )}
      {componentName === "update-feature" && (
        <Modal>
          <StatusModal
            modalHeaderTxt={
              currentFeature.isAvailable ? "Add Feature" : "Remove Feature"
            }
            modalParaText={`Are you sure you want to ${
              currentFeature.isAvailable ? "add" : "remove"
            } ${currentFeature.featureName} feature ${
              currentFeature.isAvailable ? "to" : "from"
            } this club?`}
            handleStatusClick={handleUpdateFeatureClick}
            modalClose={() => setComponentName("")}
            loading={updateFeatureLoading}
            btnName={"Yes"}
          />
        </Modal>
      )}
    </>
  );
};

export default ClubFeatures;
