import "./loadingModalSignup.scss";

const LoadingModalSignup = ({ children }) => {
  return (
    <div className="modal-backdrop-loading">
      <div className="modal">{children}</div>
    </div>
  );
};

export default LoadingModalSignup;
