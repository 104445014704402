import "./rolesAndPermissions.scss";
import React, { useState, useEffect } from "react";
import {
  RolesAndPermissionsCell,
  SelectWithoutFormik,
  Header,
} from "../../components";
import { useMemberContext } from "../../contexts/MemberContext";
import { FaBedPulse } from "react-icons/fa6";

const RolesAndPermissions = () => {
  const [index, setIndex] = useState(0);
  const { createLog } = useMemberContext();
  const [permissionType, setPermissionType] = useState("");

  const modules = [
    // "Members",
    "Member Details",
    "Memberships - Members",
    "Payments",
    "Interactions",
    // " ",
    "Vouchers",
    // " ",
    "Classes",
    "Classes Templates",
    // " ",
    // "Management",
    "Access Logs",
    "Locations",
    "Memberships - Management",
    "Staff",
    "Groups",
    "Instructors",
    "Screens",
    "Roles & Permissions",
    // "Reporting",
    "Member Report",
    "Membership Report",
    "Financial Report",
    // " ",
    "POS",
    "User Access",
    "Refunds",
  ];
  const handleTypeChange = (e) => {
    setPermissionType(e.target.value);
  };

  useEffect(() => {
    createLog("CLICKED Roles and Permissions on the sidebar", "sidebar");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Header pageTitle="Roles and Permissions" />
      <div className="rp-main-container">
        <div className="tabs mt-32">
          <div className="tabs-list">
            <div
              className={
                index !== 0
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => setIndex(0)}
            >
              Corporate Users
            </div>
            {/* <div
              className={
                index !== 1
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => setIndex(1)}
            >
              Franchise Users
            </div> */}
          </div>
          <SelectWithoutFormik
            label="Permission Types"
            name="permissionType"
            onChange={handleTypeChange}
            optionvalue={modules}
            selecttype="array"
            autoComplete="off"
            className="select-element-value"
          />
          {/* ------ Corporate Users area ------ */}
          <div className="tabContent" hidden={index !== 0}>
            {permissionType && (
              <>
                {permissionType === "Member Details" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="view member profiles"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="Edit Member Details (Name, DOB, Gender)"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="Edit Contact Details"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit emergency contact"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="request new photo"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="request ID"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="update/take photo"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="FOB/Card update function"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="block member access"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="add note"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="permit entry"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="send sms"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="SMS arrears payment URL"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="manually adjust password for member"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="adjust member bookings"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="create fixed voucher for member"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="add member profile"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Memberships - Members" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="add membership"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="cancel membership"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="revoke cancellation"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit membership amount"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit payment frequency"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit debit date"
                          level0={false}
                          level1={false}
                        level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="download agreement"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit start date"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="suspend membership - standard (with fee)"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="override suspension fee"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="suspend membership - medical"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="activate U-18 memberships"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="resend consent for U-18 members"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Payments" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="view payments"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="add payment method"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit/remove payment method"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit debit amount"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Interactions" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="view interactions"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="view entry logs"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="view visit history"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Vouchers" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="read voucher"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="create vouchers (fixed price)"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="create vouchers (percentage %)"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="create vouchers (limited)"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="create vouchers (complimentary)"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="delete any voucher"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="delete location voucher"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Classes" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="add class"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="add/remove attendee from class"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="add/remove attendee from waitlist"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit classes"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="delete class"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Classes Templates" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="add class template"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="toggle class template"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit class template"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="delete class template"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Access Logs" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="view member access logs"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="guest entry"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Locations" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="view location"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="add location"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit location"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="Membership in Location Tab - edit price"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Memberships - Management" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="add membership"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="apply to location"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="change price"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="view"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="delete"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="toggle status"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Staff" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="add staff"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="add/remove from group"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="staff audit report"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit staff"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="view staff"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="deactivate staff"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Groups" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="add group"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="actions list"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Instructors" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="add instructor"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit instructor"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="delete instructor"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Screens" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="view screen info (location/group based)"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit screens"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Roles & Permissions" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="view roles & permissions"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Member Report" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="all members"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="member visits"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="non attendance"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="new member yield"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="suspension ending"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="personal trainers"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Membership Report" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="membership movement"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="membership breakup"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="upgrade/downgrade"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Financial Report" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="payments"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="direct debits"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="PIF expiring"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="DD - failed payments"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "POS" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="use POS"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit transaction pricing"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit product pricing"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="POS reversals/VOID"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="generate POS reporting"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "User Access" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="can assign and manage all roles with equal or lower access"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Refunds" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="issue refunds"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>

          {/* ------ Franchise Users area ------ */}
          <div className="tabContent" hidden={index !== 1}>
            {permissionType && (
              <>
                {permissionType === "Memberships" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="add membership"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="apply to location"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="change price"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="view"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="delete"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="toggle status"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Staff" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="add staff"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="add/remove from group"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="staff audit report"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit staff"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="view staff"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Groups" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="add group"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="actions list"
                          level0={false}
                          level1={false}
                          level2={false}
                          level3={false}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Instructors" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="add instructor"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit instructor"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="delete instructor"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Member Report" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="all members"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="member visits"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="non attendance"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="new member yield"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="suspension ending"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="personal trainers"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Membership Report" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="membership movement"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="membership breakup"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="upgrade/downgrade"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "Financial Report" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="payments"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="direct debits"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="PIF expiring"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="DD - failed payments"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "POS" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="use POS"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit transaction pricing"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="edit product pricing"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="POS reversals/VOID"
                          level0={false}
                          level1={true}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                        <RolesAndPermissionsCell
                          rowHeading="generate POS reporting"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
                {permissionType === "User Access" && (
                  <div className="table roles-and-permissions-table">
                    <table>
                      <thead>
                        <tr className="fs-12">
                          <th>Permission</th>
                          <th>Level 0</th>
                          <th>Level 1</th>
                          <th>Level 2</th>
                          <th>Level 3</th>
                          {/* <th>Marketing</th> */}
                          <th>Level 4</th>
                          <th>Level 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <RolesAndPermissionsCell
                          rowHeading="can assign and manage all roles with equal or lower access"
                          level0={false}
                          level1={false}
                          level2={true}
                          level3={true}
                          level4={true}
                          level5={true}
                          readOnly
                        />
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesAndPermissions;
