import "./classScheduleTemplate.scss";

import { useEffect, useState } from "react";
import { useClassContext } from "../../../contexts/ClassContext";
import { useMutation } from "@apollo/client";
import { AiOutlineEdit, AiOutlineSearch } from "react-icons/ai";
import { toast } from "react-toastify";
import Button from "../../formUiElements/Button";
import SelectWithoutFormik from "../../formUiElements/select/SelectWithoutFormik";
import InputWithoutFormik from "../../formUiElements/input/InputWithoutFormik";
import Toggle from "../../formUiElements/Toggle";
import {
  UPDATE_CLASS_SCHEDULE,
  UPDATE_CLASS_SCHEDULE_SERIES,
} from "../../../gqloperations/mutations";
import { GET_CLASS_SCHEDULE_BY_ID } from "../../../gqloperations/queries";
import { isThisSeries } from "../../../constants/commonConstant";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";
import { useHandleError } from '../../../hooks/useHandleError';

const ClassScheduleTemplate = (props) => {
  const { permissions } = useAuthContext();
  const navigate = useNavigate();
  const { classScheduledata, id } = props;
  const {
    getTemplateById,
    classTemplateDataById,
    classTemplateId,
    getClassTemplateById,
    classScheduleById,
    classTemplateByBrandId,
    classTemplateByBrandIdData,
  } = useClassContext();
  const handleError = useHandleError();

  const [initialTemplateId, setInitialTemplateId] = useState(
    classScheduledata?.getClassSchedule?.classTemplateId
  );
  const [selectedTemplate, setSelectedTemplate] = useState(
    classScheduledata?.getClassSchedule?.classTemplateDetails?.className
  );

  useEffect(() => {
    classTemplateByBrandId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialTemplateId) {
      getTemplateById(initialTemplateId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTemplateId]);

  useEffect(() => {
    if (classTemplateId) {
      getClassTemplateById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classTemplateId]);

  const [selectType, setSelectType] = useState(
    classScheduledata?.getClassSchedule?.isThisSeries
      ? "Whole Series"
      : "Single Class"
  );
  const [isActive, setIsActive] = useState(true);

  const [errorMessage, setErrorMessage] = useState("");

  const handleIsActive = () => {
    setIsActive(false);
  };

  const handleCancel = () => {
    setIsActive(true);
    toast.warning("Update Cancelled");
    classScheduleById();
  };

  const handleChange = (templateId) => {
    setInitialTemplateId(templateId);
  };

  const handleTypeChange = (e) => {
    setSelectType(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrorMessage("");
      if (classScheduledata?.getClassSchedule?.isThisRepeated && !selectType) {
        return setErrorMessage("Please select type");
      }
      if (selectType === "Whole Series") {
        updateSeriesClass({
          variables: {
            isThisSeries: true,
            seriesId: classScheduledata?.getClassSchedule?.seriesId,
            classScheduleId: classScheduledata?.getClassSchedule?.id,
            classTemplateId: initialTemplateId,
          },

          onCompleted: () => {
            toast.success("Update successfully");
            setIsActive(true);
          },
          refetchQueries: [
            {
              query: GET_CLASS_SCHEDULE_BY_ID,
              variables: {
                id: `${id}`,
              },
            },
          ],
        });
      } else {
        updateClassSchedule({
          variables: {
            input: {
              id: classScheduledata?.getClassSchedule?.id,
              classTemplateId: initialTemplateId,
            },
          },

          onCompleted: () => {
            toast.success("Update successfully");
            setIsActive(true);
          },
          refetchQueries: [
            {
              query: GET_CLASS_SCHEDULE_BY_ID,
              variables: {
                id: `${id}`,
              },
            },
          ],
        });
      }
    } catch (error) {
      handleError(`${error}`);
    }
    navigate("/dashboard/classes");
  };

  const [updateClassSchedule, { error: updateclasserror }] = useMutation(
    UPDATE_CLASS_SCHEDULE
  );

  const [updateSeriesClass, { error: updateclassSerieserror }] = useMutation(
    UPDATE_CLASS_SCHEDULE_SERIES
  );

  if (updateclassSerieserror) {
    return (
      <>
        <p>{updateclassSerieserror}</p>
      </>
    );
  }
  if (updateclasserror) {
    <>
      <p>{updateclasserror}</p>
    </>;
  }
  return (
    <>
      {classTemplateByBrandIdData && (
        <form
          className="class-schedule-template-form pd-18 mt-32 mb-12"
          onSubmit={onSubmit}
        >
          <div>
            {isActive ? (
              <>
                <div className="title-bar">
                  <h3 className="fs-14 uppercase">Class Template</h3>
                  {permissions.includes("CanEditMemberClassSchedule") &&
                    !classScheduledata?.getClassSchedule?.bookingCount && (
                      <span
                        className="icon-container edit"
                        onClick={handleIsActive}
                      >
                        <AiOutlineEdit />
                      </span>
                    )}
                </div>
              </>
            ) : (
              <div className="title-bar">
                <h3 className="fs-14 uppercase">Class Template</h3>
                <span className="icon-container ">
                  <div className="sub-containers">
                    <div className="sub-container save">
                      {/* <IoIosSave /> */}
                      <Button
                        iconname="BiSave"
                        btntype="submit"
                        btnname="submit"
                        className="btn btn-edit save btn-lg fs-12 "
                      />
                      {/* <BiSave /> */}
                    </div>
                    <div className="sub-container cancel">
                      {/* <ImCancelCircle /> */}
                      {/* <AiOutlineCloseCircle  onClick={handleCancel}/> */}
                      <Button
                        iconname="AiOutlineCloseCircle"
                        btntype="reset"
                        btnname="reset"
                        className="btn  btn-edit cancel btn-lg fs-12"
                        click={handleCancel}
                      />
                    </div>
                  </div>
                </span>
              </div>
            )}

            <>
              <div className="template-inputs mb-32 flex-wrap">
                {classScheduledata?.getClassSchedule?.isThisRepeated && (
                  <div>
                    <SelectWithoutFormik
                      label="Select Single/Series *"
                      initialValue={
                        classScheduledata?.getClassSchedule?.isThisSeries
                          ? "Whole Series"
                          : "Single Class"
                      }
                      optionvalue={isThisSeries}
                      selecttype="array"
                      onChange={handleTypeChange}
                      name="isThisSeries"
                      id="isThisSeries"
                      autoComplete="off"
                      disabled={isActive ? true : false}
                      className={
                        isActive
                          ? "select-element-value "
                          : "select-element-value blue"
                      }
                    />

                    {errorMessage && (
                      <div className="fs-10 error">{errorMessage}</div>
                    )}
                  </div>
                )}
                <div className="membership-select select-element">
                  <span className="heading-and-icon">
                    <label
                      className="location-heading fs-12"
                      htmlFor="membershipId"
                    >
                      Select Template*
                      <AiOutlineSearch />
                    </label>
                  </span>

                  <div className="location-value loc-select">
                    <input
                      id="id"
                      type="text"
                      list="template-list-2"
                      placeholder="Search a template"
                      disabled={isActive ? true : false}
                      className={
                        isActive
                          ? "select-element-value"
                          : "select-element-value blue"
                      }
                      name="id"
                      onChange={(e) => {
                        const selectedOption = classTemplateByBrandIdData.find(
                          (option) => option.className === e.target.value
                        );
                        setSelectedTemplate(e.target.value);

                        if (selectedOption) {
                          handleChange(selectedOption.id);
                        }
                      }}
                      value={selectedTemplate}
                      autoComplete="off"
                    />

                    <datalist id="template-list-2">
                      {classTemplateByBrandIdData.map((option) => {
                        return (
                          <option
                            key={option.id}
                            value={option.className}
                            className="fs-14"
                          ></option>
                        );
                      })}
                    </datalist>
                  </div>
                </div>
                <InputWithoutFormik
                  type="number"
                  label="Duration *"
                  name="classDuration"
                  id="classDuration"
                  className="element-value"
                  value={
                    classTemplateDataById?.getClassTemplate?.classDuration ?? 0
                  }
                  readOnly
                />
                <InputWithoutFormik
                  type="number"
                  label="Prebook Days"
                  name="daysWithin"
                  id="daysWithin"
                  className="element-value"
                  value={
                    classTemplateDataById?.getClassTemplate?.daysWithin ?? 0
                  }
                  readOnly
                />
              </div>

              <div className="mb-18">
                <p className="reason-title fs-12 text-gray ">
                  Class Description
                </p>
                <textarea
                  className="reason-box fs-12"
                  id="classDescription"
                  name="classDescription"
                  value={
                    classTemplateDataById?.getClassTemplate?.classDescription
                  }
                  readOnly="readOnly"
                ></textarea>
              </div>
              <div className="schedule-template-toggles">
                <Toggle
                  label="Virtual Class"
                  id="hasVirtualClass"
                  name="hasVirtualClass"
                  value={
                    !!classTemplateDataById?.getClassTemplate?.hasVirtualClass
                  }
                  checked={
                    !!classTemplateDataById?.getClassTemplate?.hasVirtualClass
                  }
                  readOnly="readOnly"
                />
              </div>
            </>
          </div>
        </form>
      )}
    </>
  );
};

export default ClassScheduleTemplate;
