import "./membershipLocation.scss";
import React from "react";
import Toggle from "../../../formUiElements/Toggle";
import { useState, useEffect, useRef } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { AiOutlineSearch } from "react-icons/ai";
import actions from "../../../../assets/images/icons/action_menu.png";
import { useMemberContext } from "../../../../contexts/MemberContext";
import {
  UPDATE_PRICE_FOR_MEMBERSHIP_LOCATION,
  UPDATE_MEMBERSHIP_LOCATION,
} from "../../../../gqloperations/mutations";
import { GET_ALL_LOCATIONS_BY_LOCATIONID } from "../../../../gqloperations/queries";
import getTypeIcon from "../../../../utils/getTypeIcon";
import StatusModal from "../../../modal/StatusModal";
import { useAuthContext } from "../../../../contexts/AuthContext/AuthProvider";
import Button from "../../../formUiElements/Button";
import { brandTypes } from "../../../../constants/commonConstant";
import Modal from "../../../modal/Modal";
import Table from "../../../table/Table";
import { useParams } from "react-router-dom";
import {
  getPaymentFrequencyString,
  getContractLengthString,
} from "../../../../utils/getDisplayString";
import { useApolloClient } from "@apollo/client";
import { useLocationContext } from "../../../../contexts/LocationContext";
import { useHandleError } from "../../../../hooks/useHandleError";
const MembershipLocation = () => {
  const client = useApolloClient();
  const { permissions } = useAuthContext();

  const { state } = useLocationContext();
  let { id: locationId } = useParams();
  const ref = useRef();
  const [showActions, setShowActions] = useState({});
  const [tableData, setTableData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [componentName, setComponentName] = useState("");
  const [curRow, setCurRow] = useState({});
  const [nextToken, setNextToken] = useState();
  const handleError = useHandleError();
  const [updateMembershipLocationPrice] = useMutation(
    UPDATE_PRICE_FOR_MEMBERSHIP_LOCATION
  );

  const [updateMembershipLocation] = useMutation(UPDATE_MEMBERSHIP_LOCATION);

  const modalClose = () => {
    setComponentName("");
    setCurRow({});
  };
  const handleComponent = (componentName, rowObj) => {
    setComponentName(componentName);
    setCurRow(rowObj);
  };

  const handlePriceChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*\.?\d*$/.test(inputValue)) {
      setCurRow({ ...curRow, [e.target.name]: inputValue });
    }
  };

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const [updating, setUpdating] = useState(false);
  const handlePriceClick = async () => {
    setUpdating(true);
    let values = {
      locationIds: locationId,
      membershipId: curRow.membershipId,
      costPrice: Number(curRow.costPrice).toFixed(2),
      joiningFee: Number(curRow.joiningFee).toFixed(2),
      fobFee: Number(curRow.fobFee).toFixed(2),
      activationFee: Number(curRow.activationFee).toFixed(2),
    };
    try {
      await updateMembershipLocationPrice({
        variables: {
          ...values,
        },
        onCompleted: () => {
          setNextToken(null);
          getAllMembershipByLocationid();
          toast.success("Membership price has been updated sucessfully!");
          modalClose();
        },
      });
    } catch (error) {
      handleError(`${error}`);
    } finally {
      setUpdating(false);
    }
  };

  const handleStatusClick = async (rowObj) => {
    let values = {
      id: rowObj.itemId,
      locationId: rowObj.locationId,
      membershipId: rowObj.membershipId,
      isActive: !rowObj.isActive,
    };
    setCurRow(rowObj);

    try {
      await updateMembershipLocation({
        variables: {
          input: values,
        },
        onCompleted: () => {
          getAllMembershipByLocationid();
          toast.success("Updated successfully");
          modalClose();
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };

  const columns = [
    {
      accessorFn: (row) => {
        if (!row?.membershipDetails?.brandDetails?.name) return;
        const brandName = row?.membershipDetails?.brandDetails?.name
          ? row?.membershipDetails?.brandDetails?.name
          : "";
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },

      header: "Brand",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: true,
      enableGlobalFilter: false,
      id: "branddetails",
    },
    {
      accessorFn: (row) => row?.membershipDetails?.membershipName,
      header: "MEMBERSHIP NAME",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "membershipName",
    },
    {
      accessorFn: (row) =>
        !row.membershipDetails?.recurring
          ? "PIF"
          : getPaymentFrequencyString(row?.membershipDetails?.paymentFrequency),
      // return !row.recurring ? "PIF" : getPaymentFrequencyString(row.paymentFrequency);
      // return !row.membershipDetails.recurring ? "PIF" : getPaymentFrequencyString(row?.membershipDetails?.paymentFrequency);

      header: "BILLING FREQUENCY",
      cell: (info) => info?.getValue(),

      enableSorting: false,
      enableGlobalFilter: false,
      id: "paymentFrequency",
    },
    {
      accessorFn: (row) => row?.membershipDetails?.contractLength,
      header: "CONTRACT LENGTH",
      cell: (info) =>
        info.getValue() ? getContractLengthString(info?.getValue()) : "",
      enableSorting: true,
      enableGlobalFilter: false,
      id: "contractLength",
    },
    {
      accessorFn: (row) => row?.costPrice,
      header: "COST",
      cell: (info) => `$${info?.getValue()}`,
      enableSorting: false,
      enableGlobalFilter: false,
      id: "costPrice",
    },

    {
      accessorFn: (row) => {
        return row?.membershipDetails?.isLiveOnWebsite;
      },
      header: "LIVE",
      cell: (info) =>
        info.getValue() ? (
          <span className="text-green">YES</span>
        ) : (
          <span className="text-red">NO</span>
        ),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "isLiveOnWebsite",
      // Basic sorting enables sorting booleans
      sortingFn: "basic",
    },
    {
      accessorFn: (row) => {
        return row?.membershipDetails?.isActive;
      },
      header: "GLOBAL STATUS",
      cell: (info) =>
        info.getValue() ? (
          <span className="text-green">YES</span>
        ) : (
          <span className="text-red">NO</span>
        ),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "globalStatus",
      sortingFn: "basic",
    },

    {
      accessorFn: (row) => {
        return row.isActive;
      },
      accessorKey: "isActive",
      header: "STATUS",
      cell: (info) => {
        return (
          <Toggle
            checked={info.getValue()}
            value={info.getValue()}
            onChange={() =>
              handleComponent("status-confirm", {
                itemId: info?.row?.original.id,
                membershipId: info?.row?.original.membershipDetails?.id,
                membershipName:
                  info?.row?.original.membershipDetails?.membershipName,
                isActive: info.getValue(),
                locationId,
              })
            }
          />
        );
      },
      enableSorting: true,
      enableGlobalFilter: false,
      id: "active",
      sortingFn: "basic",
    },

    {
      id: "row-actions5",
      header: "ACTIONS",
      cell: (props) => {
        const { id } = props?.row?.original?.membershipDetails;
        const areActionsVisible = showActions.hasOwnProperty(id);
        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              onClick={(e) => {
                e.stopPropagation();
                setShowActions({
                  [id]: true,
                });
              }}
              alt=""
            />
            {areActionsVisible && (
              <span className="action-options-menu memberships" ref={ref}>
                <ul className="membership-options">
                  {permissions.includes(
                    "CanEditMembershipLocationCostPrice"
                  ) && (
                    <li
                      onClick={() => {
                        handleComponent("change-price", {
                          membershipId: id,
                          membershipName:
                            props?.row?.original?.membershipDetails
                              ?.membershipName,
                          costPrice: props?.row?.original?.costPrice,
                          joiningFee: props?.row?.original?.joiningFee,
                          activationFee: props?.row?.original?.activationFee,
                          fobFee: props?.row?.original?.fobFee,
                        });
                      }}
                    >
                      <Button
                        name="change price"
                        btntype="submit"
                        btnname="submit"
                        className="btn btn-transparent btn-small"
                      />
                    </li>
                  )}
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const getAllMembershipByLocationid = async (nextToken = null) => {
    try {
      const result = await client.query({
        query: GET_ALL_LOCATIONS_BY_LOCATIONID,
        fetchPolicy: "no-cache",
        variables: {
          locationId,
          nextToken,
          limit: 1000,
        },
      });

      const tableItems = result?.data?.getMembershipByLocationId.map((cur) => {
        const { items, nextToken: nextTokenFromResult } =
          cur.membershipLocations;

        return { items, nextTokenFromResult };
      });

      const flattendItems = tableItems.flatMap((cur) => {
        const { items } = cur;
        return items;
      });

      // const nextTokenFinal =
      //   tableItems.length > 0 ? tableItems[0].nextTokenFromResult : null;

      // if (result && nextTokenFinal) {
      //   setTableData([...tableData, ...flattendItems]);
      // } else {
      //   setTableData([...flattendItems]);
      // }

      if (result) {
        setTableData([...flattendItems]);
      }
      // setNextToken(nextTokenFinal);
    } catch (error) {
      handleError(`${error}`);
    }
  };

  useEffect(() => {
    if (state?.index === 5) {
      getAllMembershipByLocationid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.index]);
  const handleNextToken = () => {
    if (nextToken) {
      getAllMembershipByLocationid(nextToken);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      setShowActions({});
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [ref]);

  return (
    <>
      <div className="membership-container location-memberships">
        <div className="membership-landing-content">
          {componentName && (
            <Modal>
              {componentName === "live-confirm" && (
                <StatusModal
                  curRow={curRow}
                  modalHeaderTxt={`${
                    curRow.isLiveOnWebsite ? "Deactivate" : "Activate"
                  } Live Membership  `}
                  modalParaText={`Are you sure you  want to make this membership ${
                    curRow.isLiveOnWebsite ? "no longer live " : "live"
                  } on the website?`}
                  modalClose={modalClose}
                />
              )}
              {componentName === "status-confirm" && (
                <StatusModal
                  curRow={curRow}
                  modalHeaderTxt={`${
                    curRow.isActive ? "deactivate" : "activate"
                  } Membership`}
                  modalParaText={`Are you sure you want to ${
                    curRow.isActive ? "deactivate" : "activate"
                  } this membership?`}
                  handleStatusClick={handleStatusClick}
                  modalClose={modalClose}
                />
              )}
              {componentName === "change-price" && (
                <>
                  <div className="member-changes-modal location-memberships flex-col">
                    <h2 className="modal-title fs-35">Change Price</h2>
                    <div className="flex-row">
                      <div className="flex-col form-group input-container">
                        <label htmlFor="change-joiningFee" className="fs-14">
                          Joining Fee
                        </label>
                        <input
                          type="text"
                          // min="0"
                          id="change-joiningFee"
                          name="joiningFee"
                          className="select-element"
                          value={curRow?.joiningFee}
                          onChange={(e) => handlePriceChange(e)}
                        />
                      </div>

                      <div className="flex-col form-group input-container">
                        <label htmlFor="change-activationFee" className="fs-14">
                          Activation Fee
                        </label>
                        <input
                          type="text"
                          // min="0"
                          id="change-activationFee"
                          name="activationFee"
                          className="select-element"
                          value={curRow?.activationFee}
                          onChange={(e) => handlePriceChange(e)}
                        />
                      </div>
                      <div className="flex-col form-group input-container">
                        <label htmlFor="change-fobFee" className="fs-14">
                          Fob/Pass Fee
                        </label>
                        <input
                          type="text"
                          // min="0"
                          id="change-fobFee"
                          name="fobFee"
                          className="select-element"
                          value={curRow?.fobFee}
                          onChange={(e) => handlePriceChange(e)}
                        />
                      </div>
                      <div className="flex-col form-group input-container">
                        <label htmlFor="change-price" className="fs-14">
                          Cost Price
                        </label>
                        <input
                          type="text"
                          // min="0"
                          id="change-price"
                          name="costPrice"
                          className="select-element"
                          value={curRow?.costPrice}
                          onChange={(e) => handlePriceChange(e)}
                        />
                      </div>
                    </div>
                    <div className="btn-container">
                      <Button
                        loading={updating}
                        name={"SAVE"}
                        btnname="submit"
                        btntype="submit"
                        className="btn btn-small btn-confirm"
                        click={handlePriceClick}
                      ></Button>
                      <button
                        className="btn btn-small btn-confirm"
                        onClick={() => {
                          modalClose();
                        }}
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Modal>
          )}
          {tableData?.length > 0 && (
            <>
              <div className="actions-row mt-32">
                <div className="left-elements">
                  <div className="search-and-button-row">
                    <div className="search-container">
                      <form onSubmit={onSearchBarSubmit}>
                        <div className="search-bar">
                          <div className="search-bar-container">
                            <input
                              className="fs-10"
                              type="text"
                              name="search"
                              id="search"
                              value={searchBarInputVal}
                              onChange={handleChange}
                              placeholder="Membership name"
                            />
                            <button type="submit">
                              <AiOutlineSearch />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="landing-table location-memberships">
                <Table
                  data={tableData}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  sorting={sorting}
                  setSorting={setSorting}
                  columns={columns}
                  rowsPerPage={10}
                  columnFilters={columnFilters}
                  setColumnFilters={setColumnFilters}
                  handleNextToken={handleNextToken}
                  nextToken={nextToken}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MembershipLocation;
