import "./button.scss";
import copy_icon from "../../assets/images/copy_icon.svg";
import export_excel_icon from "../../assets/images/export_excel_icon.svg";
import export_csv_icon from "../../assets/images/export_csv_icon.svg";
import export_pdf_icon from "../../assets/images/export_pdf_icon.svg";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { BeatLoader } from "react-spinners";

const Button = (props) => {
  return (
    <div className="button">
      {props.btntype === "submit" && props.btnname === "submit" && (
        <>
          <button
            type="submit"
            className={props.className}
            style={props.style}
            onClick={props.click}
            disabled={props.disabled || props.loading}
            btnfunction={props.btnfunction}
            {...props}
          >
            {props.iconname === "BiSave" && <BiSave />}
            {props.loading ? (
              <div className="pt-2">
                <BeatLoader color="white" size={10} />
              </div>
            ) : props.name ? (
              props.name
            ) : (
              ""
            )}
          </button>
        </>
      )}

      {props.btntype === "button" && (
        <>
          <button
            type="button"
            className={props.className}
            onClick={props.click}
            disabled={props.disabled || props.loading}
          >
            {props.iconname === "BiSave" && <BiSave />}
            {props.loading ? (
              <BeatLoader color="white" size={10} />
            ) : props.name ? (
              props.name
            ) : (
              ""
            )}
          </button>
        </>
      )}

      {props.btntype === "reset" && (
        <button
          type="reset"
          className={props.className}
          onClick={props.click}
          disabled={props.disabled || props.loading}
        >
          {props.iconname === "AiOutlineCloseCircle" && (
            <AiOutlineCloseCircle />
          )}
          {props.loading ? (
            <BeatLoader color="white" size={10} />
          ) : props.name ? (
            props.name
          ) : (
            ""
          )}
        </button>
      )}

      {props.btntype === "submit" && props.btnname === "icon-btn" && (
        <button
          type="submit"
          className={props.className}
          onClick={props.click}
          disabled={props.disabled || props.loading}
        >
          <div className="icon-btn-group">
            <span>
              {props.icon && props.icon === "copy_icon" && (
                <img src={copy_icon} alt="copy icon" className="icon" />
              )}
              {props.icon && props.icon === "excel_icon" && (
                <img
                  src={export_excel_icon}
                  alt="excel icon"
                  className="icon"
                />
              )}
              {props.icon && props.icon === "csv_icon" && (
                <img src={export_csv_icon} alt="csv icon" className="icon" />
              )}
              {props.icon && props.icon === "pdf_icon" && (
                <img src={export_pdf_icon} alt="pdf icon" className="icon" />
              )}
            </span>
            <span>{props.name}</span>
          </div>
        </button>
      )}
    </div>
  );
};

export default Button;
