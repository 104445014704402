export function isFutureDate({ startDateTime, amount, type }) {
  const startDate = new Date(startDateTime);

  let endDate;
  switch (type) {
    case "DAYS":
      endDate = new Date(startDate.getTime() + amount * 24 * 60 * 60 * 1000);
      break;
    case "WEEKS":
      endDate = new Date(
        startDate.getTime() + amount * 7 * 24 * 60 * 60 * 1000
      );
      break;
    case "MONTHS":
      endDate = new Date(startDate);
      endDate.setMonth(startDate.getMonth() + amount);
      break;
    case "YEARS":
      endDate = new Date(startDate);
      endDate.setFullYear(startDate.getFullYear() + amount);
      break;
    default:
      throw new Error("Invalid type");
  }

  // Compare the end date with the current date
  const currentDate = new Date();
  return endDate >= currentDate;
}
