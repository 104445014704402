export const GET_CLASS_TEMPLATE_DATA_BY_ID = " GET_CLASS_TEMPLATE_DATA_BY_ID";
export const LIST_CLASS_TEMPLATE_DATA = "LIST_CLASS_TEMPLATE_DATA";
export const SET_CLASS_TEMPLATE_ID = "SET_CLASS_TEMPLATE_ID";
export const LIST_STUDIO_DATA_BY_LOCATION_ID =
  "LIST_STUDIO_DATA_BY_LOCATION_ID";
export const LIST_INSTRUCTOR = "LIST_INSTRUCTOR";
export const CLASS_SCHEDULE_BY_ID = "CLASS_SCHEDULE_BY_ID";
export const EDIT_TABLE_DATA = "EDIT_TABLE_DATA";
export const SET_MEMBER = "SET_MEMBER";
export const SET_SIDEBAR_BRAND_LOCATION_ID = "SET_SIDEBAR_BRAND_LOCATION_ID";
export const SET_LOCATION_DATA_BY_BRAND_ID = "SET_LOCATION_DATA_BY_BRAND_ID";
export const SET_SIDEBAR_LOCATION_ID = "SET_SIDEBAR_LOCATION_ID";
export const SET_LOCATION_BRAND_ID = "SET_LOCATION_BRAND_ID";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SET_SIDEBAR_BRAND_ID = "SET_SIDEBAR_BRAND_ID";
export const SET_OLD_VALUES = "SET_OLD_VALUES";
export const CLASSTEMPLATE_BY_BRAND_ID = "CLASSTEMPLATE_BY_BRAND_ID";
export const CLEAR_ID = "CLEAR_ID";
export const SET_IMG_URL = "SET_IMG_URL";
export const SET_MEMBER_ID = "SET_MEMBER_ID";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export const SET_INDEX = "SET_INDEX";
export const SET_MEMBERSHIPS_BY_LOCATION_ID = "SET_MEMBERSHIPS_BY_LOCATION_ID";
export const SET_MEMBERCONTRACT_BY_MEMBERSHIP_ID =
  "SET_MEMBERCONTRACT_BY_MEMBERSHIP_ID";
export const SET_SELECTED_MEMBERSHIP = "SET_SELECTED_MEMBERSHIP";
export const SET_IPADDRESS = "SET_IPADDRESS";
export const CLEAR_CLASS_TEMPLATE_DATA = "CLEAR_CLASS_TEMPLATE_DATA";
export const SET_MEMBER_INTERACTION = "SET_MEMBER_INTERACTION";
export const SET_VOUCHER_TYPE = "SET_VOUCHER_TYPE";
export const SET_STATS_DATA = "SET_STATS_DATA";
export const SET_SEARCH_MEMBER_VALUE = "SET_SEARCH_MEMBER_VALUE";
export const SET_PAGE_HISTORY = "SET_PAGE_HISTORY";
