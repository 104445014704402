import { useEffect, useState, useRef } from "react";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import { AiOutlineSearch } from "react-icons/ai";
import { ReportGeneratorFormWithMonth } from "../../../pages/index";
import { BeatLoader } from "react-spinners";
import useCustomFetch from "../../../hooks/useCustomFetch";
import { useCheckMemberProfile } from "../../../hooks/useCheckMemberProfile";
import { useMemberContext } from "../../../contexts/MemberContext";
import { API } from "../../../utils/API";
import getTypeIcon from "../../../utils/getTypeIcon";
import { dateFormatForColumn } from "../../../utils/dateFormatForColumn";
import { brandTypes } from "../../../constants/commonConstant";
import { Table, ExportGroup } from "../../../components/index";

export default function TotalMemberReport() {
  const paginationData = useRef();
  const { checkMemberProfile } = useCheckMemberProfile();
  const [generate, setGenerate] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [baseParameters, setBaseParameters] = useState({});
  const [tableData, setTableData] = useState();
  const { sidebarBrandName } = useMemberContext();
  const [endPoint, setEndpoint] = useState();
  const [params, setParams] = useState();
  const { data, isLoading, error } = useCustomFetch(
    endPoint && endPoint,
    params && params
  );
  useEffect(() => {
    if (generate) {
      handleGenerate(100, 1);
    }
  }, [generate]);
  useEffect(() => {
    if (error && endPoint) {
      setTableData([]);
      toast.error(error?.response?.data?.message ?? "Error when fetching api");
    } else if (data) {
      if (params.page !== 1) {
        setTableData([...tableData, ...data.data.data]);
      } else setTableData([...data.data.data]);
    }
    setGenerate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  const handleSubmit = (values) => {
    setGenerate(true);
    setBaseParameters({
      brandId: values.brand,
      locationType: values.additionalSelect,
      ...(values.location === "-1" ? {} : { locationId: values.location }),
    });
  };
  const handleGenerate = (limit, page) => {
    paginationData.current = { limit: limit, page: page };
    const pagination = limit ? { limit: limit, page: page } : undefined;
    setParams(
      pagination ? { ...baseParameters, ...pagination } : baseParameters
    );
    setEndpoint(API.youthMembershipReport);
  };
  const handleNextToken = () => {
    handleGenerate(
      paginationData.current.limit,
      paginationData.current.page + 1
    );
  };

  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal?.length === 0) {
      setGlobalFilter(searchVal);
    }
  };

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  const columns = [
    {
      accessorFn: (row) =>
        row.surname && row.givenName ? `${row.surname}, ${row.givenName}` : "",
      header: "SURNAME, NAME",
      cell: (info) => {
        return info.getValue();
      },
      footer: (info) => "TOTAL",
      enableSorting: true,
      enableGlobalFilter: true,
      id: "surname",
    },
    {
      // accessorKey: "aliasMemberId",
      accessorFn: (originalRow) => originalRow?.aliasMemberId?.toString() ?? "",
      header: "MEMBER ID",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "memberid",
    },
    {
      accessorFn: (originalRow) => originalRow?.brandDetails?.name,
      header: "BRAND",
      cell: (info) => {
        const icon = getTypeIcon(sidebarBrandName, brandTypes);
        return icon;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "homeclub",
    },
    {
      accessorFn: (originalRow) => originalRow?.homeLocationName,
      header: "HOME CLUB",
      cell: (info) => {
        // const icon = getTypeIcon(sidebarBrandName, brandTypes);
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "homeclub2",
    },
    {
      accessorKey: "mobileNumber",
      header: "MOBILE",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "mobile",
    },
    {
      accessorKey: "email",
      header: "EMAIL",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "email",
    },
    {
      accessorKey: "joinedDateTime",
      header: "JOIN DATE",
      cell: (info) => format(parseISO(info.getValue()), "dd/MM/yyyy"),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "joindate1",
    },

    {
      accessorFn: (originalRow) => {
        const active = originalRow?.contracts;
        if (active?.length > 1)
          return (
            <details>
              <summary>
                {dateFormatForColumn(active[0].startDateTime)},...
              </summary>
              {active?.map((i, index) => (
                <p key={index}>
                  {index + 1 + ". " + dateFormatForColumn(i?.startDateTime)}
                </p>
              ))}
            </details>
          );
        else if (active?.length === 1)
          return active?.map((i, index) => (
            <p key={index}>{dateFormatForColumn(i?.startDateTime)}</p>
          ));
        else return "";
      },
      header: "START DATE",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "startdate1",
    },
    {
      accessorKey: "memberType",
      header: "TYPE",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "type1",
    },
    {
      accessorFn: (originalRow) => {
        const active = originalRow?.contracts;
        if (active?.length > 1)
          return (
            <details>
              <summary>{active[0].name},...</summary>
              {active?.map((i, index) => (
                <p key={index}>{index + 1 + ". " + i?.name}</p>
              ))}
            </details>
          );
        else if (active?.length === 1)
          return active?.map((i, index) => <p key={index}>{i?.name}</p>);
        else return "";
      },
      header: "MEMBERSHIP NAME",
      cell: (info) => {
        return info.getValue();
      },

      enableSorting: false,
      enableGlobalFilter: false,
      id: "membershipName1",
    },
    {
      accessorFn: (originalRow) => {
        const active = originalRow?.contracts;
        if (active?.length > 1)
          return (
            <details>
              <summary>{active[0].costPrice.toFixed(2)},...</summary>
              {active?.map((i, index) => (
                <p key={index}>
                  {index + 1 + ". " + (i?.costPrice).toFixed(2)}
                </p>
              ))}
            </details>
          );
        else if (active?.length === 1)
          return active?.map((i, index) => (
            <p key={index}>{(i?.costPrice).toFixed(2)}</p>
          ));
        else return "";
      },
      header: "MEMBERSHIP PRICE",
      cell: (info) => {
        return info.getValue();
      },

      enableSorting: false,
      enableGlobalFilter: false,
      id: "membershipPrice1",
    },
    {
      accessorKey: "outstandingBalance",
      header: "OUTSTANDING BALANCE",
      cell: (info) => {
        return info.getValue() ? "$" + info.getValue().toFixed(2) : "$0.00";
      },
      footer:
        data && data.data
          ? "$" + Number(data.data.total.outstandingAmount)?.toFixed(2)
          : "$0.00",

      enableSorting: false,
      enableGlobalFilter: false,
      id: "billingbalance1",
    },

    {
      accessorKey: "totalDD",
      header: "TOTAL DIRECT DEBITS",
      cell: (info) => {
        return "$" + Number(info.getValue())?.toFixed(2);
      },
      footer:
        data && data.data
          ? "$" + Number(data.data.total.ddAmount)?.toFixed(2)
          : "$0.00",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "totaldirectdebits1",
    },

    {
      accessorFn: (originalRow) => {
        const active = originalRow?.contracts;
        if (active?.length > 1)
          return (
            <details>
              <summary>
                {active[0].expiryDateTime
                  ? dateFormatForColumn(active[0]?.expiryDateTime)
                  : dateFormatForColumn(active[0]?.endDateTime)}
                ,...
              </summary>
              {active?.map((i, index) => {
                return !(
                  i?.expiryDateTime === null && i?.endDateTime === null
                ) ? (
                  <p key={index}>
                    {index +
                      1 +
                      ". " +
                      (i.expiryDateTime
                        ? dateFormatForColumn(i?.expiryDateTime)
                        : dateFormatForColumn(i?.endDateTime))}
                  </p>
                ) : (
                  <p key={index}>no Expiry</p>
                );
              })}
            </details>
          );
        else if (active?.length === 1)
          return active?.map((i, index) => {
            return (
              !(i?.expiryDateTime === null && i?.endDateTime === null) && (
                <p key={index}>
                  {i.expiryDateTime
                    ? dateFormatForColumn(i?.expiryDateTime)
                    : dateFormatForColumn(i?.endDateTime)}
                </p>
              )
            );
          });
        else return "";
      },
      header: "DATE EXPIRING",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "dateexpiring1",
    },
    {
      accessorKey: "totalFailedDD",
      header: "DD FAILED PAYMENT",
      // accessorKey: "countFailedDD",
      cell: (info) => "$" + (Number(info.getValue())?.toFixed(2) ?? "0.00"),
      footer:
        data && data.data
          ? "$" + Number(data.data.total.failedDDAmount)?.toFixed(2)
          : "$0.00",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "ddfailedpayment1",
    },
    {
      accessorFn: (originalRow) => {
        const suspend = originalRow?.suspensions;
        if (suspend?.length > 1)
          return (
            <details>
              <summary>
                {dateFormatForColumn(suspend[0].startDateTime)},...
              </summary>
              {suspend?.map((i, index) => (
                <p key={index}>
                  {index +
                    1 +
                    ". " +
                    (i?.startDateTime
                      ? dateFormatForColumn(i?.startDateTime)
                      : "")}
                </p>
              ))}
            </details>
          );
        else if (suspend?.length === 1)
          return suspend?.map((i, index) => (
            <p key={index}>
              {i?.startDateTime ? dateFormatForColumn(i?.startDateTime) : ""}
            </p>
          ));
        else return "";
      },
      header: "SUSPENSION START DATE",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionstartdate1",
    },
    {
      accessorFn: (originalRow) => {
        const suspend = originalRow?.suspensions;
        if (suspend?.length > 1)
          return (
            <details>
              <summary>
                {dateFormatForColumn(suspend[0].endDateTime)},...
              </summary>
              {suspend?.map((i, index) => {
                return (
                  <p key={index}>
                    {index +
                      1 +
                      ". " +
                      (i?.endDateTime
                        ? dateFormatForColumn(i?.endDateTime)
                        : "")}
                    <br />
                  </p>
                );
              })}
            </details>
          );
        else if (suspend?.length === 1)
          return suspend?.map((i, index) => {
            return (
              <p key={index}>
                {i?.endDateTime ? dateFormatForColumn(i?.endDateTime) : ""}
              </p>
            );
          });
        else return "";
      },
      header: "SUSPENSION END DATE",
      cell: (info) => {
        return info.getValue();
      },

      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionenddate1",
    },
  ];
  return (
    <>
      <ReportGeneratorFormWithMonth
        brandAndLocation
        noTimezone
        noDefaultSelect
        noDate
        additionalSelect={{
          option: [
            { id: "ALL", name: "ALL" },
            { id: "FRANCHISEE", name: "FRANCHISEE" },
            { id: "CORPORATE", name: "CORPORATE" },
          ],
          label: "Location type",
        }}
        handleSubmit={handleSubmit}
      />
      {isLoading && (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      )}
      {!isLoading && generate && tableData?.length > 0 && (
        <div className="actions-row report-tables mt-24 mb-12">
          <ExportGroup
            data={tableData ? tableData : []}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
          />
          <div className="search-container">
            <form onSubmit={onSearchBarSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="search"
                    id="search"
                    value={searchBarInputVal}
                    onChange={handleChange}
                    placeholder="Search"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {tableData?.length > 0 && (
        <p className="mt-24 fs-14">TOTAL: {data?.data?.total.records}</p>
      )}
      {!isLoading && (
        <div className="report-table-container-all">
          <Table
            onRowClick={(i) => {
              checkMemberProfile(i.memberName, i.memberId);
            }}
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            nextToken={tableData?.length < data?.data?.total.records}
            handleNextToken={handleNextToken}
          />
        </div>
      )}
    </>
  );
}
