import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { MEDICAL_SUSPENSIONS_BY_ID } from "../../gqloperations/queries";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useMemberContext } from "../../contexts/MemberContext";
import "./viewMedicalSuspension.scss";
import { BiDetail } from "react-icons/bi";
import { Button, Header } from "../../components";
import { Storage } from "aws-amplify";
function ViewMedicalSuspension() {
  const { id } = useParams();
  const { createLog } = useMemberContext();
  const { loading, error } = useQuery(MEDICAL_SUSPENSIONS_BY_ID, {
    variables: {
      id,
    },
    onCompleted: (data) => {
      if (data?.getMemberContractSuspension) {
        createLog("Clicked view member Medical Suspension id: " + id, "member");
        setMedicalSuspension(data?.getMemberContractSuspension);
      }
    },
  });
  const [medicalSuspension, setMedicalSuspension] = useState();
  // const { sidebarBrandName } = useMemberContext();
  const navigate = useNavigate();

  const previewFile = async () => {
    const data = await Storage.get(medicalSuspension.imageUrl);
    // const blob = new Blob([userPdf.current], {
    //   type: "application/pdf",
    // });
    // const blobURL = URL.createObjectURL(blob);
    // window.open(blobURL);
    const a = document.createElement("a");
    a.href = data;
    a.target = "_blank";
    a.click();
  };
  const closeSuspension = () => {
    navigate("/dashboard/medicalsuspensions");
  };

  if (loading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (error) {
    return (
      <div>
        <div>Problem in getting user medical suspension</div>
      </div>
    );
  }
  return (
    <>
      {!loading && !error && (
        <>
          <Header pageTitle="view suspension" />
          <div className="view-suspension-page main mt-32 mb-12">
            <div className="view-card mb-24">
              <div className="suspension-title-details">
                <p className="fs-12">
                  {medicalSuspension?.memberDetails?.surname}
                </p>
                <div className="inputs">
                  <div>
                    <h3 className="fs-14">SUSPENSION DETAILS</h3>
                  </div>
                  <div>
                    <h3 className="fs-12 text-gray">start date:</h3>
                    <p className="fs-12">
                      {medicalSuspension?.suspensionStartDateTime
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/")}
                    </p>
                  </div>
                  <div>
                    <h3 className="fs-12 text-gray">End date:</h3>
                    <p className="fs-12">
                      {medicalSuspension?.suspensionEndDateTime
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/")}
                    </p>
                  </div>
                  <div>
                    <h3 className="fs-12 text-gray">reason</h3>
                    <p className="reason-box fs-12">
                      {medicalSuspension?.suspensionReason}
                    </p>
                  </div>
                  <div>
                    <div className="cert-box">
                      <div>
                        <h3 className="fs-14">SUSPENSION DETAILS</h3>
                      </div>
                      <div className="cert-list">
                        <div className="fs-12 text-gray">File name</div>
                        <div className="fs-12 text-gray">Upload date</div>
                        <div className="fs-12">
                          {
                            medicalSuspension?.imageUrl?.split(
                              "medicalsuspension/"
                            )[1]
                          }
                        </div>
                        <div className="fs-12">
                          {medicalSuspension?.createdAt
                            ?.split("T")[0]
                            .split("-")
                            .reverse()
                            .join("/")}
                        </div>
                        <div className="button-td">
                          <BiDetail
                            onClick={() => {
                              previewFile();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-btn pb-24">
              <Button
                name="close"
                btntype="submit"
                btnname="submit"
                className="btn btn-large btn-confirm  fs-12"
                click={() => closeSuspension()}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ViewMedicalSuspension;
