import "../../../pages/report/reportgeneratorform.scss";
import { Formik, Form } from "formik";
import DatePicker from "../../formUiElements/input/DatePicker";
import { getUTCdate } from "../../../utils/getUTCdate";
import Button from "../../formUiElements/Button";
import { format, isAfter, addDays } from "date-fns";

const GenerateReportForm = ({ handleSubmit, shouldValidateDate }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        chooseDateFrom: "",
        chooseDateTo: "",
      }}
      validate={(values) => {
        let errors = {};
        if (shouldValidateDate) {
          const { chooseDateFrom, chooseDateTo } = values;
          const formattedFrom = chooseDateFrom
            ? format(new Date(chooseDateFrom), "yyyy-MM-dd")
            : "";
          const formattedTo = chooseDateTo
            ? format(new Date(chooseDateTo), "yyyy-MM-dd")
            : "";
          if (!chooseDateFrom) {
            errors.chooseDateFrom = "Please select a start date";
          }
          if (
            chooseDateFrom &&
            chooseDateTo &&
            isAfter(new Date(formattedFrom), new Date(formattedTo))
          ) {
            errors.chooseDate = "Invalid date has been selected";
          }
        }
        return errors;
      }}
      onSubmit={async (values, OnSubmitProps) => {
        const utcFrom = values.chooseDateFrom
          ? getUTCdate(values.chooseDateFrom)
          : "";
        const utcTo = values.chooseDateTo
          ? getUTCdate(
              format(addDays(new Date(values.chooseDateTo), 1), "yyyy-MM-dd")
            )
          : "";
        const formatedValues = {
          ...values,
          chooseDateFrom: utcFrom,
          chooseDateTo: utcTo,
        };
        await handleSubmit(formatedValues, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <div>
            <Form>
              <div className="report-options-container flex-wrap">
                <div className="date-input-container fs-10 flex-wrap pb-8">
                  <p className="show-subtitle text-gray">
                    Show reports between
                  </p>
                  <div className="date-input">
                    <DatePicker
                      name="chooseDateFrom"
                      value={formik.values.chooseDateFrom}
                      onChange={(e) => {
                        const newToValue =
                          formik.values.chooseDateTo?.length > 0
                            ? formik.values.chooseDateTo
                            : format(new Date(), "yyyy-MM-dd");

                        formik.setFieldValue("chooseDateTo", newToValue);
                        formik.handleChange(e);
                      }}
                    />
                  </div>
                  <div className="date-input">
                    {/* <p>To</p> */}
                    <DatePicker
                      name="chooseDateTo"
                      value={formik.values.chooseDateTo}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </div>
                </div>
                {(formik.errors.chooseDateFrom || formik.errors.chooseDate) && (
                  <div>
                    <p className="warning-text error fs-10">
                      {formik.errors.chooseDateFrom || formik.errors.chooseDate}
                    </p>
                  </div>
                )}

                <Button
                  loading={formik.isSubmitting}
                  name="GENERATE"
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-transparent block btn-lg fs-12"
                />
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default GenerateReportForm;
