import { createContext, useReducer, useEffect, useContext } from "react";
import { GET_LOCTAION_BY_ID } from "../gqloperations/queries";
import { useLazyQuery } from "@apollo/client";

export const LocationContext = createContext();

export const LocationReducer = (state, action) => {
  if (action.type === "SET_LOCATION_ID") {
    return { ...state, id: action.payload };
  }
  if (action.type === "SET_LOCATION") {
    return { ...state, location: action.payload };
  }
  if (action.type === "UPDATE_LOCATION") {
    return { ...state };
  }
  if (action.type === "SET_INDEX") {
    return { ...state, index: action.payload };
  }
};

export const LocationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LocationReducer, {
    location: null,
    id: null,
    index: 0,
  });

  const [getLocationById, { data: locationByIdData }] = useLazyQuery(
    GET_LOCTAION_BY_ID,
    {
      fetchPolicy: "no-cache",
      variables: { id: `${state.id}` },
      onCompleted: (data) => {},
    }
  );

  useEffect(() => {
    if (state.id) {
      getLocationById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.id]);

  useEffect(() => {
    if (locationByIdData) {
      dispatch({ type: "SET_LOCATION", payload: locationByIdData });
    }
  }, [locationByIdData]);

  return (
    <LocationContext.Provider value={{ state, dispatch, getLocationById }}>
      {children}
    </LocationContext.Provider>
  );
};
// custom hooks in order to use the context-> what makes other component to be able to use
export const useLocationContext = () => {
  return useContext(LocationContext);
};
