import "./report.scss";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  memberReports,
  membershipReports,
  financialReports,
  executiveReports,
  snapshotReports,
} from "../../constants/commonConstant";
import { AiOutlineSearch } from "react-icons/ai";
import { searchReports } from "../../constants/commonConstant";
import { ReportCategory, Header } from "../../components";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useMemberContext } from "../../contexts/MemberContext";

const Report = () => {
  const navigate = useNavigate();
  const { permissions } = useAuthContext();
  const { createLog } = useMemberContext();
  const [currentCategory, setCurrentCategory] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState(false);
  const [linkObj, setLinkObj] = useState([]);

  useEffect(() => {
    createLog("CLICKED Reporting tab on the sidebar", "sidebar");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!searchValue) setSearch(false);
    const filteredLinks = searchReports.filter(
      (item) =>
        item?.link
          .toLowerCase()
          .includes(searchValue.toLowerCase().replace(/ /g, "")) ||
        item?.title
          .toLowerCase()
          .replace(/ /g, "")
          .includes(searchValue.toLowerCase().replace(/ /g, ""))
    );
    setLinkObj(filteredLinks);
  }, [searchValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!searchValue) return;
    setSearch(true);
  };
  return (
    <div>
      <Header pageTitle="Reporting" />
      <div className="search-container mt-32 mb-32">
        <form onSubmit={handleSubmit}>
          <div className="search-bar">
            <div className="search-bar-container">
              <input
                className="fs-10"
                type="text"
                name="search-member-input"
                id="search-member-input"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value.trim())}
                placeholder="search by report name"
              />
              <button type="submit">
                <AiOutlineSearch />
              </button>
            </div>
          </div>
        </form>
      </div>
      {search && (
        <div className="links-container flex-col">
          {linkObj.length === 0 ? (
            <div className="text-gray fs-12">
              {/* Didn't find what you were looking for? Refine your search and
                try again. */}
            </div>
          ) : (
            linkObj.map((item, index) => {
              return (
                <div key={item?.link}>
                  <Link className="" to={item?.link}>
                    <p className="capitalize fs-14 link-title mb-8">{`${
                      index + 1
                    }. ${item?.title}`}</p>
                    <p className="fs-12 text-gray">{item?.link}</p>
                  </Link>
                </div>
              );
            })
          )}
        </div>
      )}
      {!search && (
        <div className="report">
          <ReportCategory
            title="MEMBER REPORT"
            reports={memberReports}
            currentCategory={currentCategory}
            setCurrentCategory={setCurrentCategory}
          />
          <ReportCategory
            title="MEMBERSHIP REPORT"
            reports={membershipReports}
            currentCategory={currentCategory}
            setCurrentCategory={setCurrentCategory}
          />
          {permissions.includes("CanViewFinancialReporting") && (
            <ReportCategory
              title="FINANCIAL REPORT"
              reports={financialReports}
              currentCategory={currentCategory}
              setCurrentCategory={setCurrentCategory}
            />
          )}
          {/* Executive report - L5:
            #1. sale report
            #2. total member report
            #3. suspended report. */}
          <ReportCategory
            title="EXECUTIVE REPORT"
            reports={executiveReports}
            currentCategory={currentCategory}
            setCurrentCategory={setCurrentCategory}
          />
          {permissions.includes("CanViewSnapshotReport") && (
            <ReportCategory
              title="SNAPSHOT REPORT"
              reports={snapshotReports}
              currentCategory={currentCategory}
              setCurrentCategory={setCurrentCategory}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default Report;
