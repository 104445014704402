import "./table.scss";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { BiSortAlt2, BiSortUp, BiSortDown } from "react-icons/bi";
import Button from "../formUiElements/Button";
import { BeatLoader } from "react-spinners";
import { AiOutlineSearch } from "react-icons/ai";
import { useEffect, useState } from "react";

const Table = (props) => {
  const {
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    nextToken,
    rowSelection,
    setRowSelection,
    handleNextToken,
    columnFilters,
    setColumnFilters,
    hasFooter,
    showBtn,
    handleClick,
    btnName,
    isBtnLoading = false,
    isBtnDisabled = false,
    showTwoBtns = false,
    handleCancel,
    hasSearchBar = false,
    searchFn,
    brandWiseSearch,
    globalFilterFn = "includesString",
  } = props;
  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    initialState: { pagination: { pageSize: props.rowsPerPage ?? 10 } },
    getPaginationRowModel: getPaginationRowModel(),
    filterFns: props.filterFns,
    state: {
      sorting,
      globalFilter,
      columnFilters,
      rowSelection,
    },
    enableColumnFilter: true,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    autoResetSelectedRows: false,
  });
  const [searchBarValue, setSearchBarValue] = useState("");

  const searchBarHandler = (e) => {
    searchFn(e);
    setSearchBarValue(e.target.value);
  };
  const [currentSelectedRow, setSelectedRow] = useState("");
  const [prevSelectedRow, setPrevSelectedRow] = useState();
  const sortingArrow = (direction) => {
    if (direction) {
      return {
        asc: <BiSortUp />,
        desc: <BiSortDown />,
      }[direction];
    } else {
      return <BiSortAlt2 />;
    }
  };
  useEffect(() => {
    if (currentSelectedRow !== "") {
      if (prevSelectedRow)
        prevSelectedRow
          .querySelectorAll("details")
          .forEach((el) => (el.open = false));
      const currentRow = document.getElementById(currentSelectedRow);
      const allDetailsTag = currentRow.querySelectorAll("details");
      if (allDetailsTag?.length > 0)
        allDetailsTag.forEach((el) => (el.open = true));
      setPrevSelectedRow(currentRow);
    }
  }, [currentSelectedRow]);
  return (
    <>
      {props?.data?.length > 0 || props.nextToken ? (
        <div className="table">
          <div className="table-container">
            <table className={props.className}>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr className="fs-14" key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        className={`table_header_field ${
                          header.column.columnDef.className || ""
                        }`}
                        key={header.id}
                        colSpan={header.colSpan}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none header-cell"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {header.column.getCanSort()
                              ? sortingArrow(header.column.getIsSorted())
                              : ""}
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
                {hasSearchBar && (
                  <tr className="no-hover-style">
                    <th
                      id="embedded-search"
                      colSpan={props?.columns?.length ?? 7}
                    >
                      <div className="embedded-search-container">
                        {searchFn || brandWiseSearch ? (
                          <input
                            value={searchBarValue}
                            onChange={searchBarHandler}
                            className="fs-10"
                            placeholder={`${
                              brandWiseSearch
                                ? "FIRST NAME, SURNAME, MOBILE or Location"
                                : "FIRST NAME, SURNAME, MOBILE OR EMAIL"
                            }`}
                          />
                        ) : (
                          <input
                            value={globalFilter}
                            onChange={(e) =>
                              table.setGlobalFilter(e.target.value)
                            }
                            className="fs-10"
                            placeholder="FIRST NAME, SURNAME, MOBILE, EMAIL or Location"
                          />
                        )}
                        <button type="submit">
                          <AiOutlineSearch />
                        </button>
                      </div>
                    </th>
                  </tr>
                )}
              </thead>

              <tbody>
                {table?.getRowModel()?.rows?.length > 0 ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr
                        className="fs-12"
                        key={row.id}
                        id={row.id}
                        onDoubleClick={() => {
                          props?.onRowClick && props.onRowClick(row.original);
                        }}
                        onClick={() => setSelectedRow(row.id)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className="center">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <tr className="no-hover-style">
                    <td colSpan={10} className="no-data-holder in-table">
                      <div className="no-data">
                        <h3 className="norecords-text fs-12 uppercase">
                          No Records Found
                        </h3>{" "}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>

              {hasFooter && (
                <tfoot>
                  {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id}>
                      {footerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.footer,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              )}
            </table>
          </div>
          {table?.getRowModel().rows?.length > 0 && (
            <>
              <div className="table-bottom-opts flex-row flex-wrap">
                <div className="pagination ">
                  <div className="pagination-container">
                    <div className="page-nav flex-row">
                      <span className="page-info">
                        <div>PAGE</div>
                        <strong>
                          {table.getState().pagination.pageIndex + 1} OF{" "}
                          {table.getPageCount()}
                        </strong>
                      </span>
                      <button
                        className="btn btn-lightgray page"
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                      >
                        {"<<"}
                      </button>
                      <button
                        className="btn btn-lightgray page"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                      >
                        {"<"}
                      </button>
                      <button
                        className="btn btn-lightgray page"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                      >
                        {">"}
                      </button>
                      <button
                        className="btn btn-lightgray page"
                        onClick={() =>
                          table.setPageIndex(table.getPageCount() - 1)
                        }
                        disabled={!table.getCanNextPage()}
                      >
                        {">>"}
                      </button>
                    </div>

                    <div className="page-info-container">
                      <div className="results-drop flex-row">
                        <span className="page-info-text">
                          {" "}
                          RESULTS PER PAGE
                        </span>
                        <select
                          className="page-info-dropdown"
                          value={table.getState().pagination.pageSize}
                          onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                          }}
                        >
                          {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        {nextToken ? (
                          <button
                            className="btn-small btn-transparent fs-10 btn-load-more "
                            onClick={handleNextToken}
                          >
                            DOWNLOAD MORE RESULTS
                          </button>
                        ) : (
                          <button
                            className="btn-small btn-transparent fs-10 btn-load-more"
                            disabled
                          >
                            NO MORE RESULTS
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-btn-container">
                  {
                    // btn1
                    showBtn && (
                      <div className="right-btn">
                        {isBtnLoading ? (
                          <Button
                            name={<BeatLoader color="white" />}
                            btntype="submit"
                            btnname="submit"
                            className="btn btn-small-xwide btn-lightgray"
                            disabled
                          />
                        ) : (
                          <Button
                            name={btnName}
                            btntype="submit"
                            btnname="submit"
                            className={`btn btn-small-xwide btn-lightgray ${
                              isBtnDisabled && "btn-disabled"
                            }`}
                            click={() =>
                              handleClick(table.getSelectedRowModel().flatRows)
                            }
                            disabled={isBtnDisabled}
                          />
                        )}
                      </div>
                    )
                  }

                  {showTwoBtns && (
                    <div className="right-btn twobtn-container flex-row flex-wrap">
                      {isBtnLoading ? (
                        <Button
                          name={<BeatLoader color="white" />}
                          btntype="submit"
                          btnname="submit"
                          className="btn btn-small-xwide btn-lightgray"
                          disabled
                        />
                      ) : (
                        <Button
                          name={btnName}
                          btntype="submit"
                          btnname="submit"
                          className={`btn btn-small-xwide btn-confirm ${
                            isBtnDisabled ? "btn-disabled" : ""
                          }`}
                          click={() =>
                            handleClick(table.getSelectedRowModel().flatRows)
                          }
                          disabled={isBtnDisabled}
                        />
                      )}
                      <Button
                        name="cancel"
                        btntype="reset"
                        btnname="cancel"
                        className="btn btn-deny btn-small"
                        click={() => handleCancel()}
                        // disabled={isBtnDisabled}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {/* {props.nextToken && (
            <div className="pagination">
              <div className="pagination-container">
                <span className="page-info">
                  <div>PAGE</div>
                  <strong>
                    {table.getState().pagination.pageIndex + 1} OF{" "}
                    {table.getPageCount()}
                  </strong>
                </span>
                <button
                  className="btn btn-lightgray page"
                  onClick={() => table.setPageIndex(0)}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<<"}
                </button>
                <button
                  className="btn btn-lightgray page"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<"}
                </button>
                <button
                  className="btn btn-lightgray page"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {">"}
                </button>
                <button
                  className="btn btn-lightgray page"
                  onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                  disabled={!table.getCanNextPage()}
                >
                  {">>"}
                </button>
                <div className="page-info-container">
                  <span className="page-info-text"> RESULTS PER PAGE</span>
                  <select
                    className="page-info-dropdown"
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                  <div>
                    {nextToken ? (
                      <button
                        className="btn-small btn-transparent fs-10 btn-load-more "
                        onClick={handleNextToken}
                      >
                        DOWNLOAD MORE RESULTS
                      </button>
                    ) : (
                      <button
                        className="btn-small btn-transparent fs-10 btn-load-more"
                        disabled
                      >
                        NO MORE RESULTS
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
      ) : (
        <div className="no-hover-style">
          <div className="no-data-holder ">
            <div className="no-data">
              <h3 className="fs-12 uppercase">No Records Found</h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
