import "./smslocation.scss";
import { useState, useEffect } from "react";
import { Table, Modal } from "../../../../components";
import { useMemberContext } from "../../../../contexts/MemberContext";
import { AiOutlineSearch } from "react-icons/ai";
import { GET_SMS_OUTBOX_BY_LOCATIONID } from "../../../../gqloperations/queries";
import { useParams } from "react-router-dom";
import { BsCardText } from "react-icons/bs";
import { dateFormatForColumn } from "../../../../utils/dateFormatForColumn";
import { format } from "date-fns";
import { useTableStateProps } from "../../../../hooks/useTableStateprops";
import { useApolloClient } from "@apollo/client";
import { useHandleError } from '../../../../hooks/useHandleError';

const OutgoingSMS = () => {
  const client = useApolloClient();
  const { createLog } = useMemberContext();
  const { id: locationId } = useParams();
  const {
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
  } = useTableStateProps();
  const [tableData, setTableData] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [detailsData, setDetailsData] = useState("");
  const handleError = useHandleError();

  const getOutgoingSMS = async (nextToken = null) => {
    try {
      const result = await client.query({
        query: GET_SMS_OUTBOX_BY_LOCATIONID,
        fetchPolicy: "no-cache",
        variables: {
          locationId,
          sortDirection: "DESC",
          nextToken,
        },
      });

      if (result && nextToken) {
        setTableData([
          ...tableData,
          ...result.data.getSmsOutboxByLocationId.items,
        ]);
      } else {
        setTableData(result.data.getSmsOutboxByLocationId.items);
      }

      setNextToken(result.data.getSmsOutboxByLocationId.nextToken);
    } catch (error) {
      handleError(`${error}`);
    }
  };

  useEffect(() => {
    getOutgoingSMS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNextToken = () => {
    if (nextToken) {
      getOutgoingSMS(nextToken);
    }
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };

  const viewMessage = (data) => {
    setDetailsData(data);
  };

  const columns = [
    {
      accessorFn: (row) => {
        return row?.identityName ?? "";
      },
      header: "Identity Name",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "identifyName",
    },
    {
      accessorFn: (row) => {
        const { givenName, surname } = row.memberDetails ?? {};

        const fullName =
          (givenName ?? "") +
          (givenName && surname ? ", " : "") +
          (surname ?? "");

        return fullName;
      },
      header: "MEMBER NAME",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "memberName",
    },
    {
      accessorFn: (row) => {
        return row?.memberDetails?.email ?? "";
      },
      header: "EMAIL",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "email",
    },
    {
      accessorFn: (row) => {
        return dateFormatForColumn(row.sentDateTime);
      },
      header: "SENT DATE",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "sentDateTime",
    },

    {
      id: "row-actions-sms",
      cell: (props) => (
        <BsCardText
          onClick={() => {
            viewMessage(props.row.original);
            createLog(
              `Clicked view message icon for memberID: ${props.row.original.memberId}`
            );
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="">
        <>
          {tableData.length > 0 && (
            <div className="actions-row">
              <div className="left-elements ">
                <div className="search-and-button-row">
                  <div className="search-container">
                    <form onSubmit={onSearchBarSubmit}>
                      <div className="search-bar">
                        <div className="search-bar-container">
                          <input
                            className="fs-10"
                            type="text"
                            name="search"
                            id="search"
                            value={searchBarInputVal}
                            onChange={handleChange}
                            placeholder="Member name"
                          />
                          <button type="submit">
                            <AiOutlineSearch />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="landing-table location-sms">
            <Table
              data={tableData}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              sorting={sorting}
              setSorting={setSorting}
              columns={columns}
              rowsPerPage={10}
              handleNextToken={handleNextToken}
              nextToken={nextToken}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
            />
          </div>
        </>

        {detailsData && (
          <Modal>
            <div className="member-changes-modal">
              <h2 className="modal-title fs-35">SMS</h2>
              <div className="details-container">
                <div className="detail-entire-row">
                  <div className="detail">
                    <h3 className="fs-10">Date</h3>
                    <p className="fs-12">
                      {format(new Date(detailsData?.sentDateTime), "E d/LL/yy")}
                    </p>
                  </div>
                </div>
                <div className="detail">
                  <h3 className="fs-10">Sent from</h3>
                  <p className="fs-12">
                    {detailsData?.status === "sent" ? detailsData?.from : ""}
                  </p>
                </div>
                <div className="detail">
                  <h3 className="fs-10">Status</h3>
                  <p className="fs-12">{detailsData?.status}</p>
                </div>
                <div className="msg-container">
                  <h3 className="fs-10">Message</h3>

                  <textarea
                    className="msg-container fs-12"
                    defaultValue={detailsData?.message}
                  ></textarea>
                </div>
              </div>
              <button
                className="btn btn-small btn-confirm"
                onClick={() => setDetailsData("")}
              >
                CLOSE
              </button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default OutgoingSMS;
