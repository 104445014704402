import "./manualMemberEntry.scss";

import { Formik, Form } from "formik";

import React from "react";
import { useQuery } from "@apollo/client";
import { LIST_LOCATION } from "../../gqloperations/queries";

// import { CREATE_MANUAL_MEMBER_ENTRY } from "../../gqloperations/mutations";
import { BeatLoader } from "react-spinners";
import Button from "../formUiElements/Button";
import Input from "../formUiElements/input/Input";
import Select from "../formUiElements/select/Select";
import entry from "../../assets/images/entry.png";
import { guestEntrySchema } from "../../validations/Validation";

const ManualMemberEntry = (props) => {
  //Fetching Locations
  const { data: locationdata } = useQuery(LIST_LOCATION);

  const onSubmit = (values, OnSubmitProps) => {
    // createManualMemberEntry({
    //   variables: {
    //     input: {
    //       ...values,
    //       date: new Date(timestamp),
    //     },
    //   },
    //   onCompleted: () => {
    //     OnSubmitProps.resetForm();
    //     props.onClick();
    //     toast.success("Manual Member Entry Created Successfully");
    //   },
    //   // refetchQueries: [{ query: LIST_MEMBERS }],
    // });
    // OnSubmitProps.setSubmitting(false);
  };

  //calling a mutations to Guest Entry

  return (
    <Formik
      initialValues={{
        // date: `${date}`,
        locationId: "",
        firstName: "",
        lastName: "",
        contactNumber: "+61 ",
        reasonForEntry: "",
      }}
      validationSchema={guestEntrySchema}
      onSubmit={async (values, OnSubmitProps) => {
        await onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <div className="manual-container pd-32">
            {locationdata && (
              <Form>
                <div className="entry-info-container">
                  <div className="entry-title">
                    <h3 className="fs-35 uppercase pb-12">
                      Allow Member Entry to Selected Club
                    </h3>
                  </div>

                  <div className="entry-info">
                    <div className="date">
                      <Input
                        type="datetime"
                        label="Date"
                        name="date"
                        id="date"
                        autoComplete="off"
                        disabled="on"
                      />
                    </div>
                    <div className="location">
                      <Select
                        label="Select Location"
                        name="locationId"
                        className="select-element-value"
                        optionvalues={locationdata.listLocations.items}
                        selecttype="object"
                        objectvalue="id"
                        objectname="name"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="guest-info-container pb-24">
                  <div className="guest-title pt-18 pb-18">
                    <h3 className="fs-14">Member Details</h3>
                  </div>
                  <div className="guest-details">
                    <div className="fn">
                      <Input
                        type="text"
                        label="First Name*"
                        name="firstName"
                        id="firstName"
                        autoComplete="off"
                      />
                    </div>
                    <div className="ln">
                      <Input
                        type="text"
                        label="Last Name*"
                        name="lastName"
                        id="lastName"
                        autoComplete="off"
                      />
                    </div>
                    <div className="mobile">
                      <Input
                        type="text"
                        label="Contact Number*"
                        name="contactNumber"
                        id="contactNumber"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="reason-container">
                  <div className="reason-message">
                    <p className="reason-title text-gray fs-10 uppercase">
                      Reason for Entry
                    </p>
                    <textarea
                      className="reason-box fs-12"
                      id="reasonForEntry"
                      name="reasonForEntry"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.reasonForEntry}
                    ></textarea>
                  </div>
                  <div className="entry-view">
                    <p className="reason-title text-gray fs-10 uppercase">
                      Club Entry Live View
                    </p>
                    <img src={entry} alt=""></img>
                  </div>
                </div>
                {formik.errors.reasonForEntry &&
                  formik.touched.reasonForEntry && (
                    <div id="feedback" className="error fs-12">
                      {formik.errors.reasonForEntry}
                    </div>
                  )}
                <div className="entry-btn-group">
                  {/* {formik.isSubmitting && (
                    <Button
                      name={<BeatLoader color="white" />}
                      btntype="Grant Access"
                      btnname="submit"
                      disabled={true}
                      className="btn btn-confirm btn-lg fs-12"
                    />
                  )}
                  {!formik.isSubmitting && (
                    <Button
                      name="Grant Access"
                      btntype="submit"
                      btnname="submit"
                      // disabled={!Formik.isValid || Formik.isSubmitting}
                      className="btn btn-confirm btn-small fs-12"
                    />
                  )} */}
                  <Button
                    loading={formik.isSubmitting}
                    name={"Grant Access"}
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-confirm btn-small fs-12"
                    disabled={formik.isSubmitting ? true : false}
                  />
                  <Button
                    name="cancel"
                    btntype="button"
                    btnname="cancel"
                    click={props.onClick}
                    className="btn btn-deny btn-small fs-12"
                  />
                </div>
              </Form>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default ManualMemberEntry;
