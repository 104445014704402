import "./upgradeDowngrade.scss";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMemberContext } from "../../../contexts/MemberContext";
import {
  GET_MEMBER_BY_ID,
  GET_CHANGE_MEMBERSHIP_DETAILS,
} from "../../../gqloperations/queries";
import { CHANGE_MEMBERSHIP_FOR_MEMBER } from "../../../gqloperations/mutations";
import {
  ConditionsTabContent,
  ConfirmationTabContent,
  LocationTabContent,
  Header,
} from "../../../components";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import isAfter from "date-fns/isAfter";
import isSameDay from "date-fns/isSameDay";
import isBefore from "date-fns/isBefore";
import { useHandleError } from '../../../hooks/useHandleError';

export const UpgradeDowngrade = () => {
  const oldMemberContractId = useParams();
  const navigate = useNavigate();
  const { member } = useMemberContext();
  const [newMembershipData, setNewMembershipData] = useState();
  const [selectedLocation, setSelectedLocation] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tab, setTab] = useState("location");
  const [confirmationData, setConfirmationData] = useState({});
  const [currentMembership, setCurrentMembership] = useState();
  const handleError = useHandleError();
  const [changeMembership, { loading: changeMembershipLoading }] = useMutation(
    CHANGE_MEMBERSHIP_FOR_MEMBER
  );

  const handleLocationNextClick = (newMembership) => {
    setTab("conditions");
    setNewMembershipData(newMembership);
  };
  const handleCancel = () => {
    navigate(-1);
  };
  const handleConditionNextClick = () => {
    changeMembership({
      variables: {
        memberId: member?.getMember.memberId,
        oldMemberContractId: oldMemberContractId.contractId,
        newMembershipId: newMembershipData.membershipDetails?.id,
        membershipLocationId: newMembershipData.id,
        getInformation: true,
      },
      onCompleted: (data) => {
        setConfirmationData(data.changeMembership);
        setTab("confirmation");
      },
      onError: (err) => {
        handleError(`${err}`);
      },
    });
  };
  const handleSubmit = () => {
    setIsSubmitting(true);
    changeMembership({
      variables: {
        memberId: member?.getMember.memberId,
        oldMemberContractId: oldMemberContractId.contractId,
        newMembershipId: newMembershipData?.id,
        membershipLocationId: newMembershipData?.id,
        getInformation: true,
      },
      onCompleted: () => {
        setIsSubmitting(false);
        toast.success("Transfer successfully");
        navigate(-1);
      },
      refetchQueries: [
        {
          query: GET_MEMBER_BY_ID,
          variables: {
            memberId: member?.getMember.memberId,
          },
        },
      ],
      onError: (error) => {
        handleError(`${error}`);
        setIsSubmitting(false);
      },
    });
  };
  const filterCurrentMembership = (data) => {
    let status = "ACTIVE";

    const { isPending, startDateTime } = data;

    if (isPending && !startDateTime) status = "PENDING";
    if (startDateTime && isAfter(new Date(startDateTime), new Date()))
      status = "FUTURE";

    let isCancellationAfterNow = false;
    let isCancellationToday = false;
    let isCancellationBeforeToday = false;
    const cancellationDate = data?.expiryDateTime;
    if (cancellationDate) {
      isCancellationToday = isSameDay(new Date(cancellationDate), new Date());
      isCancellationBeforeToday = isBefore(
        new Date(cancellationDate),
        new Date()
      );
      isCancellationAfterNow = isAfter(new Date(cancellationDate), new Date());
    }
    if (isCancellationBeforeToday || isCancellationToday) status = "CANCELLED";

    return {
      status,
    };
  };

  useEffect(() => {
    const membershipCurrentData = member?.getMember?.contracts.items.filter(
      (i) => {
        return filterCurrentMembership(i).status === "ACTIVE";
      }
    );
    setCurrentMembership(membershipCurrentData);
  }, [member]);

  return (
    <>
      <div className="upgrade-downgrade-main-container">
        <Header pageTitle="Upgrade/downgrade" />
        <div className="name fs-35 mt-32">
          {member?.getMember?.givenName} {member?.getMember?.surname}
        </div>
        <div className="tabs">
          <div className="tabs-list button-type">
            <div
              className={
                tab !== "location"
                  ? "tab-head fs-14 back-transparent"
                  : "tab-head fs-14 back-mediumgray"
              }
            >
              LOCATION
            </div>

            <div
              className={
                tab !== "conditions"
                  ? "tab-head fs-14 back-transparent"
                  : "tab-head fs-14 back-mediumgray"
              }
            >
              TERMS AND CONDITIONS
            </div>
            <div
              className={
                tab !== "confirmation"
                  ? "tab-head fs-14 back-transparent"
                  : "tab-head fs-14 back-mediumgray"
              }
            >
              SUMMARY AND CONFIRMATION
            </div>
          </div>
        </div>

        {tab === "location" && (
          <LocationTabContent
            member={member}
            handleClick={handleLocationNextClick}
            handleBack={handleCancel}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            currentMembership={currentMembership}
          />
        )}

        {tab === "conditions" && (
          <ConditionsTabContent
            newMembershipData={newMembershipData}
            selectedLocation={selectedLocation}
            handleClick={handleConditionNextClick}
            handleBack={() => setTab("location")}
            isSubmitting={changeMembershipLoading}
          />
        )}
        {tab === "confirmation" && (
          <ConfirmationTabContent
            // isSubmitting={isSubmitting}
            confirmationData={confirmationData}
            membershipData={newMembershipData}
            selectedLocation={selectedLocation}
            member={member}
            handleClick={() => setTab("payment")}
            handleBack={() => setTab("conditions")}
            navigate={navigate}
            currentMembership={currentMembership}
            oldMemberContractId={oldMemberContractId}
          />
        )}
      </div>
    </>
  );
};

export default UpgradeDowngrade;
