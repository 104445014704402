import "./addMembershipActivationDate.scss";
import { Formik, Form } from "formik";
import { useState } from "react";
import { format } from "date-fns";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CREATE_MEMBERSHIP_ACTIVATION_DATE } from "../../gqloperations/mutations";
import { addMembershipActivationDateSchema } from "../../validations/MembershipValidation";
import { Input, ToggleFormik, Button } from "../../components";
import { getUTCdate } from "../../utils/getUTCdate";
import { useHandleError } from '../../hooks/useHandleError';

export default function AddMembershipActivationDate({
  refetch,
  membershipId,
  membershipName,
  existDatePeriods,
}) {
  const [createActivationDate] = useMutation(CREATE_MEMBERSHIP_ACTIVATION_DATE);
  const [dateInputValid, setDateInputValid] = useState(true);
  const handleError = useHandleError();
  const validateDateInput = (values) => {
    const startDateInputTimeStamp = new Date(values.startDateTime).getTime();
    let result = true;
    if (!values.endDateTime) {
      for (let i = 0; i < existDatePeriods.length; i++) {
        if (
          !existDatePeriods[i].endDateTime ||
          startDateInputTimeStamp <= existDatePeriods[i].endDateTime
        ) {
          result = false;
        }
      }
    } else {
      const endDateInputTimeStamp = new Date(values.endDateTime).getTime();
      for (let i = 0; i < existDatePeriods.length; i++) {
        if (!existDatePeriods[i].endDateTime) {
          if (endDateInputTimeStamp >= existDatePeriods[i].startDate) {
            result = false;
          }
        } else {
          if (
            (startDateInputTimeStamp >= existDatePeriods[i].startDateTime &&
              startDateInputTimeStamp <= existDatePeriods[i].endDateTime) ||
            (startDateInputTimeStamp <= existDatePeriods[i].startDateTime &&
              endDateInputTimeStamp >= existDatePeriods[i].endDateTime) ||
            (endDateInputTimeStamp >= existDatePeriods[i].startDateTime &&
              endDateInputTimeStamp <= existDatePeriods[i].endDateTime)
          ) {
            result = false;
          }
        }
      }
    }
    return result;
  };
  const onSubmit = async (values, OnSubmitProps) => {
    setDateInputValid(true);
    const result = validateDateInput(values);
    if (!result) {
      setDateInputValid(false);
      OnSubmitProps.setSubmitting(false);
      return;
    }
    let variableInput = {};
    if (values.endDateTime) {
      variableInput = {
        startDateTime: getUTCdate(values.startDateTime),
        endDateTime: getUTCdate(values.endDateTime),
        isActive: values.isActive,
      };
    } else {
      variableInput = {
        startDateTime: getUTCdate(values.startDateTime),
        isActive: values.isActive,
      };
    }
    try {
      await createActivationDate({
        variables: {
          input: { ...variableInput, membershipId },
        },
        onCompleted: () => {
          OnSubmitProps.resetForm();
          toast.success("Membership Activation Date Created Successfully");
          refetch();
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
    OnSubmitProps.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        startDateTime: "",
        endDateTime: "",
        isActive: false,
      }}
      validationSchema={addMembershipActivationDateSchema}
      onSubmit={(values, OnSubmitProps) => onSubmit(values, OnSubmitProps)}
    >
      {(formik) => {
        return (
          <Form>
            <div className="add-membership-page flex-col edit mt-32 pd-12 mb-12">
              <div className="edit-container">
                <div className="name">
                  <h6 className="fs-12">Membership Name:</h6>
                  <p className="fs-12 uppercase ">{membershipName}</p>
                </div>
                <div className="activation-title">
                  <h3 className="fs-14 uppercase">Add Activation Date</h3>
                </div>
                <div className="date-range flex-wrap">
                  <Input
                    type="date"
                    label="Start Date "
                    name="startDateTime"
                    className="fs-10"
                    value={formik.values.startDateTime}
                    min={format(new Date(), "yyyy-MM-dd")}
                    max={formik.values.endDateTime}
                  />

                  <Input
                    type="date"
                    label="End Date "
                    name="endDateTime"
                    className="fs-10"
                    value={formik.values.endDateTime}
                    min={
                      formik.values.startDateTime
                        ? formik.values.startDateTime
                        : format(new Date(), "yyyy-MM-dd")
                    }
                  />
                </div>
                <div className="status ">
                  <ToggleFormik
                    label=""
                    name="isActive"
                    id="isActive"
                    value={formik.values.isActive}
                    className={formik.values.isActive ? "" : "blue"}
                  />
                  <p className="fs-10 text-gray">Date Status</p>
                </div>
              </div>
              {!dateInputValid && Object.keys(formik.errors).length === 0 && (
                <p className="error fs-10">Date exist!</p>
              )}
            </div>
            <div className="right-btn edit-btns">
              <Button
                loading={formik.isSubmitting}
                name={"Save"}
                btntype="submit"
                btnname="submit"
                className="btn btn-large btn-confirm fs-12"
                disabled={formik.isSubmitting ? true : false}
              />
              <Link to={`/dashboard/membership`}>
                <Button
                  name="Cancel"
                  btntype="reset"
                  btnname="submit"
                  className="btn btn-large btn-deny fs-12"
                />
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
