import "./cardlg.scss";
import debit_icon from "../../assets/images/debit_icon.svg";

const Cardlg = ({title, subtitle, children}) => {
  return (
    <div className="cardlg">
      <div className="cardlg-container">
        <div className="title">
          <div className="fs-14 value"> {subtitle?`${title} (${subtitle})` : title}</div>
          <div className="debit_icon">
            <img src={debit_icon} alt="chat" />
          </div>
        </div>
        <div className="billing-info">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Cardlg;
