import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  ExportGroup,
  Header,
  Modal,
  GenerateReportForm,
  StaffReportDetailsModal,
} from "../../../components";
import { AiOutlineSearch } from "react-icons/ai";
import { GET_AUDIT_VISIT_LOG_CLICKED_BY } from "../../../gqloperations/queries";
import getTypeIcon from "../../../utils/getTypeIcon";
import { brandTypes } from "../../../constants/commonConstant";
import { useTableStateProps } from "../../../hooks/useTableStateprops";
import { format } from "date-fns";
import { useApolloClient } from "@apollo/client";
import { BsCardText } from "react-icons/bs";
import "../../../pages/staff/staff.scss";

export default function StaffReport() {
  const client = useApolloClient();
  const { id } = useParams();
  const [tableData, setTableData] = useState();
  const [nextToken, setNextToken] = useState();
  const [componentName, setComponentName] = useState("");
  const [whatWasDoneDetails, setWhatWasDoneDetails] = useState({});
  const {
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
  } = useTableStateProps();
  const [searchBarInputVal, setSearchBarInputVal] = useState("");

  const auditVisitLogClickedBy = async (variable, isPaginating = false) => {
    try {
      const result = await client.query({
        query: GET_AUDIT_VISIT_LOG_CLICKED_BY,
        fetchPolicy: "no-cache",
        variables: variable,
        // variables: { ...variable, limit: 2 }
      });

      if (!result) {
        return;
      }

      const auditVisitLogData =
        result?.data?.getAuditVisitLogByClickedBy?.items;
      const nextToken = result?.data?.getAuditVisitLogByClickedBy?.nextToken;
      if (result && isPaginating) {
        setTableData([...tableData, ...auditVisitLogData]);
      } else {
        setTableData(auditVisitLogData);
      }

      setNextToken(nextToken);
    } catch (err) {
      console.log(err);
    }
  };

  const handleComponent = (componentname) => {
    setComponentName(componentname);
  };
  const modalClose = () => {
    setComponentName("");
  };

  const handleSubmit = async (values, OnSubmitProps) => {
    const { chooseDateFrom, chooseDateTo } = values;
    if (chooseDateFrom && chooseDateTo) {
      const formattedFrom = format(new Date(chooseDateFrom), "yyyy-MM-dd");
      const formattedTo = format(new Date(chooseDateTo), "yyyy-MM-dd");

      auditVisitLogClickedBy({
        clickedBy: id,
        createdAt: {
          between: [formattedFrom, formattedTo],
        },
      });
      return;
    } else {
      auditVisitLogClickedBy({ clickedBy: id });
    }
  };

  const handleNextToken = () => {
    if (!nextToken) {
      return;
    }

    auditVisitLogClickedBy({ clickedBy: id, nextToken: nextToken }, true);
  };

  const columns = [
    {
      accessorFn: (row) => row.createdAt.split("T")[0].replaceAll("-", "/"),
      header: "Date",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "date",
    },
    {
      accessorFn: (row) =>
        `${row?.clickedByDetails?.givenName} ${row?.clickedByDetails?.surname}`,

      header: "Clicked By",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "name",
    },

    {
      accessorFn: (row) =>
        `${row.memberDetails?.givenName ?? ""} ${
          row.memberDetails?.surname ?? ""
        }`,

      header: "Member",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "memberName",
    },

    {
      accessorFn: (row) => row?.locationDetails?.brandDetails?.name ?? "",
      header: "Location",
      cell: (info) => {
        const brandName = info.getValue();
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },
      enableSorting: false,
      enableGlobalFilter: true,
      id: "locationName",
    },

    {
      accessorFn: (row) =>
        row?.classScheduleDetails?.classTemplateDetails?.className ?? "",
      header: "Class",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "className",
    },
    {
      id: "row-actions",
      cell: (props) => (
        <span className="button-td">
          <BsCardText
            onClick={() => {
              // const id = props.row.original.memberId;
              handleComponent("staff-report-details");
              setWhatWasDoneDetails({
                whatWasDoneDetails: props.row.original.whatwasDone,
                date: props.row.original.createdAt
                  .split("T")[0]
                  .replaceAll("-", "/"),
              });
            }}
          />
        </span>
      ),
    },
  ];

  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal?.length === 0) {
      setGlobalFilter(searchVal);
    }
  };

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  console.log(tableData);
  return (
    <>
      <Header pageTitle="Staff Audit Report" />
      <GenerateReportForm handleSubmit={handleSubmit} />
      {tableData?.length > 0 && (
        <div className="actions-row report-tables mt-24 mb-12 flex-wrap">
          <ExportGroup
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
          />
          <div className="search-container pt-12">
            <form onSubmit={onSearchBarSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="search"
                    id="search"
                    value={searchBarInputVal}
                    onChange={handleChange}
                    placeholder="Search by Member, location, class"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {componentName && (
        <Modal>
          {componentName === "staff-report-details" && (
            <StaffReportDetailsModal
              modalClose={modalClose}
              details={whatWasDoneDetails}
            />
          )}
        </Modal>
      )}
      {tableData && (
        <div className="staff-report-table">
          <Table
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            nextToken={nextToken}
            handleNextToken={handleNextToken}
          />
        </div>
      )}
    </>
  );
}
