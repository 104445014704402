import "./login.scss";
import { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { loginSchema } from "../../validations/Validation";
import hub_logo from "../../assets/images/hub_logo.svg";
import VivaLabs_Logo from "../../assets/images/icons/brand_logos/VivaLabs_Logo.png";
import { OTPInput, Button, Input } from "../../components";
import { toast } from "react-toastify";
import { useHandleError } from '../../hooks/useHandleError';

const Login = () => {
  const { handleSignIn, loggedUser, memberId, verifyOtp, handleResendOtp } =
    useAuthContext();

  const navigate = useNavigate();
  const handleError = useHandleError();
  const [otpValue, setOtpValue] = useState("");
  useEffect(() => {
    if (loggedUser) {
      navigate("/dashboard");
    }
  }, [loggedUser, navigate]);

  const [loginType, setLoginType] = useState("LAND");

  const loginTypeMessage = useMemo(() => {
    let result;
    if (loginType === "APP")
      result = (
        <>
          <p>CHECK YOUR MEMBER ID APP FOR A CODE</p>
          <p>
            We’ve sent a 6-Character code to your Members ID App via
            Notification
            <br /> message. Please check in-app messages if you have
            notifications turned off.
          </p>
        </>
      );
    if (loginType === "EMAIL")
      result = (
        <>
          <p>CHECK YOUR EMAIL FOR A CODE</p>
          <p>
            We’ve sent a 6-Character code to your Email.
            <br /> Please check your mailbox.
          </p>
        </>
      );
    if (loginType === "ERROR")
      result = (
        <>
          <p>THERE IS AN ERROR WHEN SEND YOU THE CODE</p>
          <p className="error-text">
            We are trying to send a 6-Character code.
            <br />
            But there is an error please try again or use a different method.
          </p>
        </>
      );
    if (loginType === "SMS")
      result = (
        <>
          <p>CHECK YOUR SMS FOR A CODE</p>
          <p>
            We’ve sent a 6-Character code to your SMS.
            <br /> Please check the SMS on your phone.
          </p>
        </>
      );
    if (loginType === "LAND")
      result = (
        <>
          <p>HOW WOULD YOU LIKE TO RECEIVE THE CODE?</p>
          <p>
            {" "}
            Please select one of the option below to receive the 6 digits code
            <br /> and then input the code to login.
          </p>
        </>
      );
    return result;
  }, [loginType]);

  const loginOption = [
    { type: "EMAIL", message: "Send code by Email" },
    { type: "APP", message: "Send code by App" },
    // { type: "SMS", message: "Resend by SMS" },
  ];

  const [OTPExpire, setOtpExpire] = useState({ seconds: 0, minutes: 0 });
  const [resendTimeOut, setResendTimeOut] = useState({
    seconds: 0,
    minutes: 0,
  });
  // function to handle countdown for 1 second and minute
  const countDown = (timeValue, setValue, interval) => {
    if (timeValue.seconds > 0) {
      setValue({ ...timeValue, seconds: timeValue.seconds - 1 });
    }

    if (timeValue.seconds === 0) {
      if (timeValue.minutes === 0) {
        clearInterval(interval);
      } else {
        setValue({ minutes: timeValue.minutes - 1, seconds: 59 });
        // setOtpExpire({...minutes:timeValue.minutes - 1});
      }
    }
  };
  const resendOtp = async (i, formik) => {
    try {
      setLoginType(i.type);
      await handleResendOtp(i.type, formik.values);
      setOtpExpire({ seconds: 60, minutes: 4 });
      setResendTimeOut({ seconds: 60, minutes: 0 });
    } catch (e) {
      // console.log(e)
      setOtpExpire({ seconds: 0, minutes: 0 });
      setResendTimeOut({ seconds: 0, minutes: 0 });
      setLoginType("ERROR");
      if (e.message.includes("VERIFY_PHONE_FAILED")) {
        handleError(
          "You haven't verified your phone number. please try another way to login."
        );
      } else {
        handleError(e.message);
      }
      setLoginType("EMAIL");
      formik.values.step = 0;
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      countDown(OTPExpire, setOtpExpire, interval);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [OTPExpire.seconds]);

  useEffect(() => {
    const interval = setInterval(() => {
      countDown(resendTimeOut, setResendTimeOut, interval);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [resendTimeOut.seconds]);

  if (memberId) {
    return (
      <div className="login">
        <BeatLoader color="white" />
      </div>
    );
  }
  return (
    <Formik
      initialValues={{ email: "", step: 0 }}
      validationSchema={loginSchema}
      onSubmit={async (values, OnSubmitProps) => {
        await handleSignIn(values.email, OnSubmitProps);
        // setOtpExpire({ seconds: 60, minutes: 4 });
        // setResendTimeOut({ seconds: 60, minutes: 0 });
      }}
    >
      {(formik) => {
        return (
          <>
            <div className="login">
              <div className="logo">
                <img src={hub_logo} alt="logo" className="logo-img" />
                <span className="sub-logo">
                  <p className="fs-12 text-gray">by</p>
                  <img src={VivaLabs_Logo} alt="logo" />
                </span>
              </div>
              <div className="login-container">
                <Form className="frm-group">
                  {formik.values.step === 0 && (
                    <>
                      <h1 className="heading fs-35">User Login </h1>
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        label="Email address"
                        autoComplete="off"
                      />
                      <Button
                        loading={formik.isSubmitting}
                        name={"CONTINUE"}
                        btntype="submit"
                        btnname="submit"
                        className="btn-primary"
                        disabled={formik.isSubmitting ? true : false}
                      />
                    </>
                  )}
                  {formik.values.step === 1 && (
                    <>
                      <>{loginTypeMessage}</>
                      {(OTPExpire.seconds > 0 || OTPExpire.minutes > 0) && (
                        <span className="expired-Otp">
                          Your OTP will expire in:{" "}
                          {OTPExpire.minutes < 10
                            ? `0${OTPExpire.minutes}`
                            : OTPExpire.minutes}
                          :
                          {OTPExpire.seconds < 10
                            ? `0${OTPExpire.seconds}`
                            : OTPExpire.seconds}
                        </span>
                      )}
                      {loginType !== "ERROR" && loginType !== "LAND" ? (
                        <OTPInput
                          autoFocus
                          isNumberInput
                          length={6}
                          value={otpValue}
                          onChangeOTP={(otp) => {
                            if (otp.length === 6)
                              verifyOtp(
                                otp,
                                () => {
                                  setOtpValue("");
                                  formik.values.step = 0;
                                },
                                () => setOtpValue("")
                              );
                            else setOtpValue(otp);
                          }}
                        />
                      ) : (
                        <div className="otp-option-container">
                          {loginOption.map((i, index) => (
                            <span
                              key={index}
                              onClick={() => {
                                resendOtp(i, formik);
                              }}
                            >
                              {i.message}
                            </span>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </Form>
              </div>
              {formik.values.step === 1 ? (
                resendTimeOut.seconds > 0 || resendTimeOut.minutes > 0 ? (
                  <p>
                    Didn't receive the code try again after{" "}
                    {resendTimeOut.minutes < 10
                      ? `0${resendTimeOut.minutes}`
                      : resendTimeOut.minutes}
                    :
                    {resendTimeOut.seconds < 10
                      ? `0${resendTimeOut.seconds}`
                      : resendTimeOut.seconds}
                  </p>
                ) : (
                  loginType !== "ERROR" &&
                  loginType !== "LAND" && (
                    <div className="otp-option-container">
                      {loginOption.map((i, index) => (
                        <span
                          key={index}
                          onClick={() => {
                            resendOtp(i, formik);
                          }}
                        >
                          {i.message}
                        </span>
                      ))}
                    </div>
                  )
                )
              ) : (
                ""
              )}
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default Login;
