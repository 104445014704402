import "./pageNotFound.scss";
import React from 'react';
import { Link } from "react-router-dom";


const PageNotFound = () => {
  return (
    <div className="page-error-container">
      <div className="message-container pd-32">
        <div className="error-message fs-12 uppercase">
          Page Not Found.
        </div>
        <div className="error-message-sub fs-12 text-gray">
          We could not find the page you were looking for.
        </div>
        <Link to="/dashboard">
          <button className="btn btn-primary btn-confirm">
            Return To Home Page
          </button>
        </Link>
      </div>
    </div>
  )
}

export default PageNotFound
