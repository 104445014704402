import "../../pages/membership/changeMembershipPrice.scss";
import React from "react";
import { Formik, Form } from "formik";
import Checkbox from "../formUiElements/Checkbox";
import Toggle from "../formUiElements/Toggle";
import InputWithoutFormik from "../formUiElements/input/InputWithoutFormik";
import Button from "../formUiElements/Button";
import { BeatLoader } from "react-spinners";
export const BulkPriceStatusModal = (props) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        statusCheckbox: false,
        statusToggle: false,
        priceCheckbox: false,
        priceInput: 0,
      }}
      onSubmit={async (values, OnSubmitProps) => {
        await props.handleSubmit(values, OnSubmitProps);
      }}
      validate={(values) => {
        const { statusCheckbox, priceCheckbox, priceInput } = values;
        let errors = {};
        if (!statusCheckbox && !priceCheckbox) {
          const errorText = "Please check at least one checkbox";
          errors.checkBox = errorText;
        }
        if (priceCheckbox && priceInput < 0) {
          errors.priceInput = "Price should not be less than 0";
        }
        return errors;
      }}
    >
      {(formik) => {
        return (
          <Form className="bulk-modal-form">
            <div className="bulk-change pd-24">
              <h3 className="title fs-35">Change Price / Status</h3>
              <div className="details-container">
                <div className="detail">
                  <Checkbox
                    name="statusCheckbox"
                    value={formik.values.statusCheckbox}
                    checked={formik.values.statusCheckbox}
                    onChange={formik.handleChange}
                  />
                  <Toggle
                    label="Status"
                    name="statusToggle"
                    value={formik.values.statusToggle}
                    checked={formik.values.statusToggle}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    name="priceCheckbox"
                    value={formik.values.priceCheckbox}
                    checked={formik.values.priceCheckbox}
                    onChange={formik.handleChange}
                  />
                  <InputWithoutFormik
                    type="number"
                    id="changePriceModal"
                    name="priceInput"
                    onChange={formik.handleChange}
                    placeholder="Price"
                    className={`fs-12 noselect`}
                    value={formik.values.priceInput}
                  />
                </div>
              </div>
              {formik.errors.checkBox && (
                <div className="warning-text">{formik.errors.checkBox}</div>
              )}
              {formik.errors.priceInput && (
                <div className="warning-text">{formik.errors.priceInput}</div>
              )}
              <div className="modal-btn-container">
                {/* {formik.isSubmitting && (
                  <Button
                    name={<BeatLoader color="white" />}
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-xsmall btn-confirm save-btn fs-12"
                  />
                )}
                {!formik.isSubmitting && (
                  <Button
                    name="Save"
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-confirm btn-small"
                  />
                )} */}
                <Button
                  loading={formik.isSubmitting}
                  name={"Save"}
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-confirm btn-small"
                  disabled={formik.isSubmitting ? true : false}
                />
                <Button
                  name="Cancel"
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-deny btn-small"
                  click={props.modalClose}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BulkPriceStatusModal;
