import React from "react";
import { Formik, Form } from "formik";
import { format, isBefore } from "date-fns";
import { Input, Button } from "../../components";

const ChangeExpiryDateModal = (props) => {
  const minDate = isBefore(new Date(props.expiryDate), new Date())
    ? format(new Date(), "yyyy-MM-dd")
    : format(new Date(props.expiryDate), "yyyy-MM-dd");

  return (
    <Formik
      enableReinitialize
      initialValues={{
        dateOfExpiration: "",
      }}
      onSubmit={(values) => {
        props.handleSubmit(new Date(values?.dateOfExpiration));
      }}
      validate={(values, props) => {
        const { dateOfExpiration } = values;

        let errors = {};

        if (!dateOfExpiration) {
          const errorText = "Please select expiry date";
          errors.dateOfExpiration = errorText;
        }

        return errors;
      }}
    >
      {(formik) => {
        return (
          <Form className="status-modal cancel-membership pd-32">
            <h3 className="fs-35 uppercase ">{props.headerText}</h3>

            <div className="input-container">
              <h3 className="fs-16 uppercase pb-18 member-name">
                {props?.memberDetails?.givenName +
                  " " +
                  props?.memberDetails?.surname}
              </h3>
              <div className="amount-container pb-18">
                <div className="flex-col">
                  <label className="fs-12 text-gray" htmlFor="dateOfExpiration">
                    Date of Expiration
                  </label>

                  <Input
                    type="date"
                    name="dateOfExpiration"
                    autoComplete="off"
                    min={minDate}
                  />
                </div>
              </div>
            </div>
            <div className="btn-group">
              <Button
                loading={formik.isSubmitting}
                name={"Save"}
                btntype="submit"
                btnname="submit"
                className="btn btn-confirm btn-small"
                disabled={formik.isSubmitting ? true : false}
              />
              <Button
                name="Cancel"
                btntype="submit"
                btnname="submit"
                className="btn btn-deny btn-small"
                click={props.cancelFn}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangeExpiryDateModal;
