import { useLazyQuery, useMutation } from "@apollo/client";
// import { GET_SETTLEMENT_REPORT_BY_LOCATION_ID } from "../../../../gqloperations/queries";
import { GET_SETTLEMENT_REPORT_BY_BRAND_ID } from "../../gqloperations/queries";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FiDownload } from "react-icons/fi";
import "./settlementreport.scss";
import { BeatLoader } from "react-spinners";
import { RiMailSendLine } from "react-icons/ri";
import { isAfter } from "date-fns";
import { Table } from "../../components";
import { SEND_SETTLEMENT_REPORT } from "../../gqloperations/mutations";
import { ReportGeneratorFormWithMonth } from "..";
import { useHandleError } from '../../hooks/useHandleError';

function SettlementReport() {
  const [getSettleMentReport, { data, loading, error, refetch }] = useLazyQuery(
    GET_SETTLEMENT_REPORT_BY_BRAND_ID
  );
  const [sendSettlementEmail] = useMutation(SEND_SETTLEMENT_REPORT);
  const handleError = useHandleError();

  const [tableData, setTableData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [nextToken, setNextToken] = useState();

  const [downloadLoading, setDownloadLoading] = useState({});
  const [sendEmailLoading, setSendEmailLoading] = useState({});

  const handleSendEmail = async (id) => {
    setSendEmailLoading({ ...sendEmailLoading, [id]: true });
    try {
      const response = await sendSettlementEmail({
        variables: {
          input: {
            operation: "SEND_SETTLEMENT_REPORT",
            settlementReportId: id,
          },
        },
      });
      toast.success(response.data.sendSettlementReportEmail.message);
    } catch (err) {
      handleError(err.message);
    } finally {
      setSendEmailLoading({ ...sendEmailLoading, [id]: false });
    }
  };

  const columns = [
    {
      accessorFn: (row) => row?.startDate?.split("-").reverse().join("/"),
      header: "START DATE",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "startDate",
    },
    {
      accessorFn: (row) => row?.endDate?.split("-").reverse().join("/"),
      header: "END DATE",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "endDate",
    },
    {
      accessorFn: (row) => row?.locationName,
      header: "LOCATION",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "location",
    },
    {
      accessorFn: (row) => row?.locationDetails?.settlementEmail,
      header: "SETTLEMENT EMAIL",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "email",
    },

    {
      cell: (props) => (
        <div className="email-btn-container">
          <div className="beat-or-send">
            <span className="tooltip">
              {sendEmailLoading[props.row.original.id] ? (
                <BeatLoader className="loading" color="white" size={4} />
              ) : (
                <RiMailSendLine
                  className={
                    sendEmailLoading[props.row.original?.id] ||
                    props.row.original?.locationDetails?.settlementEmail ===
                      "" ||
                    props.row.original?.locationDetails?.settlementEmail ===
                      null ||
                    props.row.original?.locationDetails?.settlementEmail ===
                      undefined
                      ? "disabled"
                      : ""
                  }
                  onClick={() => {
                    if (!sendEmailLoading[props.row.original?.id]) {
                      handleSendEmail(props.row.original?.id);
                    }
                  }}
                />
              )}
              <span className="tooltip-text fs-10">Send EMAIL</span>
            </span>
          </div>
        </div>
      ),
      header: "SEND EMAIL",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "actioncell",
    },
  ];

  const sortByCreatedAtDesc = (arr) => {
    return arr.sort((a, b) => {
      const result = isAfter(new Date(a.startDate), new Date(b.startDate));
      return result ? -1 : 1;
    });
  };
  const [isPaginating, setIsPaginating] = useState(false);
  const handleGenerate = (values) => {
    if (values.chooseDateFrom && values.chooseDateTo) {
      const variables = {
        brandId: values.brand,
        limit: 100,

        filter: {
          startDate: { ge: values.chooseDateFrom },
          endDate: { le: values.chooseDateTo },
          ...(values.location === "-1"
            ? {}
            : { locationId: { eq: values.location } }),
        },
      };
      getSettleMentReport({
        fetchPolicy: "no-cache",
        variables,
        // {
        //   filter: { locationId },
        // },
      });
    }
  };
  useEffect(() => {
    if (data && data?.getSettlementReportByBrandId) {
      const { items, nextToken } = data?.getSettlementReportByBrandId;

      if (tableData && isPaginating) {
        const sortedCombinedItems = sortByCreatedAtDesc([
          ...tableData,
          ...items,
        ]);
        setTableData(sortedCombinedItems);
      } else {
        const sortedItems = sortByCreatedAtDesc([...items]);
        setTableData(sortedItems);
      }

      setIsPaginating(false);
      setNextToken(nextToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleNextToken = () => {
    setIsPaginating(true);
    refetch({ nextToken: nextToken });
  };

  if (error) return <p>SORRY THERE IS AN ERROR WHEN FETCHING DATA</p>;
  return (
    <div className="financial-settlement-report-tab">
      <ReportGeneratorFormWithMonth
        brandAndLocation
        noDefaultSelect
        noTimezone
        startDateRequired
        handleSubmit={handleGenerate}
      />
      {loading && (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      )}

      {!loading && (
        <Table
          data={tableData}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          sorting={sorting}
          setSorting={setSorting}
          columns={columns}
          rowsPerPage={10}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          handleNextToken={handleNextToken}
          nextToken={nextToken}
        />
      )}
    </div>
  );
}

export default SettlementReport;
