import "./searchbar.scss";
import { Formik, Form } from "formik";
import { searchSchema } from "../../validations/Validation";
import { AiOutlineSearch } from "react-icons/ai";

const SearchBar = (props) => {
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ search: "" }}
        validationSchema={searchSchema}
        onSubmit={(values, OnSubmitProps) => {
          if (values) {
            props.onclick(values, OnSubmitProps);
          }
        }}
      >
        {(formik) => {
          return (
            <Form>
              <>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="search"
                      id="search"
                      placeholder={props.placeholder}
                      onChange={formik.handleChange}
                      value={formik.values.search}
                    />

                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
                <div className="error fs-12">
                  {formik.errors.search && formik.touched.search
                    ? formik.errors.search
                    : null}
                </div>
              </>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SearchBar;
