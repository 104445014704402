import "./classScheduleMessage.scss";
import { useState } from "react";
import { Formik, Form } from "formik";

import { toast } from "react-toastify";
import Button from "../../formUiElements/Button";

import { AiOutlineEdit } from "react-icons/ai";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";

const ClassScheduleMessage = (props) => {
  const { permissions } = useAuthContext();
  const { classScheduledata, id, onSubmit } = props;

  const [isActive, setIsActive] = useState(true);

  const handleIsActive = () => {
    setIsActive(false);
  };

  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("class-schedule-message-form").reset();
    setIsActive(true);
  };

  return (
    <>
      {classScheduledata?.getClassSchedule?.classTemplateDetails && (
        <Formik
          enableReinitialize
          initialValues={{
            id: `${id}`,
            bookingMessage: classScheduledata.getClassSchedule.bookingMessage?? "",
          }}
          onSubmit={(values, OnSubmitProps) => {
            onSubmit(values, OnSubmitProps);
            setIsActive(true);
          }}
        >
          {(formik) => {
            return (
              <Form
                className="class-message-form mb-12 pd-18"
                id="class-message-form"
              >
                {isActive ? (
                  <>
                    <div className="title-bar uppercase">
                      <h3 className="fs-14">Manual Message (SMS)</h3>
                      {permissions.includes("CanEditMemberClassSchedule") && (
                        <span
                          className="icon-container edit"
                          onClick={handleIsActive}
                        >
                          <AiOutlineEdit />
                        </span>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="title-bar uppercase">
                    <h3 className="fs-14">Manual Message (SMS)</h3>
                    <span className="icon-container ">
                      <div className="sub-containers">
                        <div className="sub-container save">
                          <Button
                            iconname="BiSave"
                            btntype="submit"
                            btnname="submit"
                            className="btn btn-edit save btn-lg fs-12 "
                          />
                        </div>
                        <div className="sub-container cancel">
                          <Button
                            iconname="AiOutlineCloseCircle"
                            btntype="reset"
                            btnname="reset"
                            className="btn  btn-edit cancel btn-lg fs-12"
                            click={handleCancel}
                          />
                        </div>
                      </div>
                    </span>
                  </div>
                )}

                <div className="message-content">
                  <p className="fs-12">Message</p>
                  <textarea
                    id="bookingMessage"
                    name="bookingMessage"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bookingMessage}
                    disabled={isActive ? true : false}
                    className={isActive ? "" : "blue"}
                  ></textarea>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default ClassScheduleMessage;
