import { useNavigate } from "react-router-dom";
import "./notifications.scss";
import { useMemberContext } from "../../contexts/MemberContext";
import { SET_MEMBER_ID } from "../../actions";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Button from "../formUiElements/Button";
import { useMutation } from "@apollo/client";
import {
  CREATE_ACTION_NOTIFICATION,
  NOTIFICATION_ACTION,
} from "../../gqloperations/mutations";
import { useNotificationContext } from "../../contexts/NotificationContext";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";

const Notifications = ({
  show,
  notificationListProps,
  setNumberOfNotification,
  numberOfNotifications,
}) => {
  const navigate = useNavigate();
  const { dispatch, sidebarBrandId, sidebarLocationId } = useMemberContext();
  const { dispatch: dispatchNotification } = useNotificationContext();
  const { memberId } = useAuthContext();
  const [createActionNotification] = useMutation(CREATE_ACTION_NOTIFICATION);
  const [notificationList, setNotificationList] = useState();
  const [showMore, setShowMore] = useState(false);
  const [displayedNotifications, setDisplayedNotifications] = useState([]);
  const [notificationAction] = useMutation(NOTIFICATION_ACTION);

  const handleNotificationclick = (notificationDetail) => {
    if (notificationDetail.eventType === "ADD_VOUCHER") {
      navigate(`/dashboard/membership`);
    } else if (notificationDetail.eventType === "ADD_STAFF") {
      navigate(`/dashboard/staff`);
    } else {
      dispatch({
        type: SET_MEMBER_ID,
        payload: {
          memberId: notificationDetail.memberId,
        },
      });
      navigate(`/dashboard/member/${notificationDetail.memberId}`);
    }
  };

  const handleLoadMoreNotification = () => {
    setDisplayedNotifications(
      notificationList.slice(0, displayedNotifications.length + 25)
    );
  };

  const handleRemoveNoti = (notiId) => {
    setDisplayedNotifications((prevState) => {
      return prevState.filter((noti) => noti.id !== notiId);
    });
    setNumberOfNotification((prevState) => prevState - 1);
    dispatchNotification({
      type: "REMOVE_NOTIFICATION",
      payload: notiId,
    });
    createActionNotification({
      variables: {
        input: {
          notificationId: notiId,
          memberId: memberId,
          action: "REMOVE",
        },
      },
    });
  };
  const handleReadAllNoti = async () => {
    dispatchNotification({
      type: "REMOVE_ALL_NOTIFICATION",
    });
    let payload = {
      action: "clear_all_notifications",
      brandId: sidebarBrandId,
    };
    if (sidebarLocationId !== 0) {
      payload.locationId = sidebarLocationId;
    }
    await notificationAction({
      variables: {
        input: payload,
      },
    });
  };
  useEffect(() => {
    setNotificationList(notificationListProps);

    if (notificationListProps.length > 25) {
      setShowMore(true);
      setDisplayedNotifications(notificationListProps.slice(0, 25));
    } else {
      setDisplayedNotifications(notificationListProps);
    }
  }, [notificationListProps]);

  useEffect(() => {
    if (displayedNotifications?.length === notificationList?.length) {
      setShowMore(false);
    }
  }, [displayedNotifications]);

  const getEventName = (eventType, noti) => {
    // console.log({ eventType, noti });
    const eventName = {
      ADD_MEMBER: "NEW MEMBER",
      ADD_STAFF: "NEW STAFF",
      SIGN_UP_MEMBER_UNDER_18: "NEW UNDER 18 MEMBER",
      SIGN_UP_MEMBER_OVER_18: "NEW OVER 18 MEMBER",
      ADD_MEDICAL_SUSPENSION: "NEW MEDICAL SUSPENSION SUBMIT",
      ADD_STANDARD_SUSPENSION: "NEW STANDARD SUSPENSION SUBMIT",
      CANCEL_MEMBERSHIP: "NEW MEMBERSHIP CANCEL",
      MEMBER_ASSIGNED_CONSENT_FORM: "MEMBER ASSIGNED CONSENT FORM",
      ADD_VOUCHER: "NEW VOUCHER",
      MEMBER_OUTSTANDING: "NEW OUTSTANDING",
      MEMBER_INCREASE_OUTSTANDING: "NEW INCREASE OUTSTANDING",
      MEMBER_CHARGED_OUTSTANDING: "NEW CHARGED OUTSTANDING",
      CREATE_CLASS_BOOKING: "NEW CLASS BOOKING",
      CREATE_CLASS_BOOKING_WAIT_LIST: "NEW CLASS BOOKING WAITLIST",
      CANCEL_CLASS_BOOKING_WAIT_LIST: "CANCEL CLASS BOOKING WAITLIST",
      CANCEL_CLASS_BOOKING: "CANCEL CLASS BOOKING",
      REFUND_APPROVED: "REFUND APPROVED",
    };

    if (eventType === "EDIT_OUTSTANDING") {
      return `${noti?.actionByMemberDetails?.givenName}  changed outstanding balance to  ${noti.memberDetails?.outstandingBalance}`;
    } else if (eventType === "REFUND_APPROVED") {
      return `${noti?.actionByMemberDetails?.surname} ${noti?.actionByMemberDetails?.givenName}  ${eventName[eventType]}`;
    }

    return eventName[eventType] || "";
  };
  return (
    <div>
      {show.notification && (
        <>
          {numberOfNotifications > 0 ? (
            <div className="outer-container">
              <h3 className="fs-14 uppercase notification-header">
                Notifications
              </h3>
              <p className="make-read" onClick={handleReadAllNoti}>
                Make all as read
              </p>
              {displayedNotifications.map((noti, idx) => (
                <div key={idx} className="notifications-container">
                  <div
                    className="single-container blue-border"
                    onClick={() => handleNotificationclick(noti)}
                  >
                    <p className="title fs-10">
                      {getEventName(noti.eventType, noti)}
                    </p>
                    <p className="title fs-10">
                      {noti?.memberDetails?.surname}{" "}
                      {noti?.memberDetails?.givenName}-{" "}
                      {noti?.locationDetails?.name}
                    </p>
                    <p className="subtitle fs-10">
                      {format(new Date(noti.createdAt), "dd/MM/yyyy hh:mma")}
                    </p>
                  </div>
                  <div
                    className="sub-container cancel"
                    onClick={() => handleRemoveNoti(noti.id)}
                  >
                    <Button
                      iconname="AiOutlineCloseCircle"
                      btntype="reset"
                      btnname="reset"
                      className="btn btn-edit cancel btn-lg fs-12"
                    />
                  </div>
                </div>
              ))}
              {showMore && (
                <span
                  className="load-more"
                  onClick={() => handleLoadMoreNotification()}
                >
                  Load more
                </span>
              )}
            </div>
          ) : (
            <div className="outer-container">
              <div className="notifications-container">
                <div className="empty-noti ">No notification</div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Notifications;
