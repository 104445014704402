import Button from "../formUiElements/Button";
import { Formik, Field, Form } from "formik";
import { useMemberContext } from "../../contexts/MemberContext";
import { useEffect } from "react";

const FilterByBrand = (props) => {
  const { getAllBrands, brandData } = useMemberContext();

  useEffect(() => {
    getAllBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let brandNameList = [];

  brandData?.listBrands?.items.map((brand) => brandNameList.push(brand.name));
  return (
    <Formik
      enableReinitialize
      initialValues={{
        checked: [],
        selectAll: false,
      }}
      onSubmit={async (values, OnSubmitProps) => {
        await props.handleSubmit(values, OnSubmitProps);
      }}
      validate={(values, props) => {
        const { checked } = values;
        let errors = {};

        if (checked.length <= 0) {
          const errorText = "Please check at least one";
          errors.checked = errorText;
        }
        return errors;
      }}
    >
      {(formik) => {
        return (
          <Form className="status-modal">
            <div className="checkbox-container">
              <label htmlFor="selectAll">
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={formik.values.selectAll}
                  value={formik.values.selectAll}
                  onChange={() => {
                    const newSelectAllValue = !formik.values.selectAll;
                    const newCheckboxValues = newSelectAllValue
                      ? [...brandNameList]
                      : [];
                    formik.setFieldValue("checked", newCheckboxValues, false);
                    formik.setFieldValue("selectAll", newSelectAllValue, false);
                  }}
                />
                Select All
              </label>
              <div role="group" aria-labelledby="checkbox-group">
                {brandData?.listBrands?.items.map((brand) => (
                  <label key={brand.id}>
                    <Field type="checkbox" name="checked" value={brand.name} />
                    {brand.name}
                  </label>
                ))}
              </div>
              <div className="fs-12 error">
                {formik.errors.checked && formik.errors.checked}
              </div>
            </div>
            <div className="btn-group">
              {formik.isSubmitting && (
                <Button
                  loading={formik.isSubmitting}
                  name="OK"
                  btntype="submit"
                  btnname="submit"
                  disabled
                  className="btn btn-small btn-disabled"
                />
              )}
              {!formik.isSubmitting && (
                <Button
                  loading={formik.isSubmitting}
                  name="OK"
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-green-standard btn-small"
                />
              )}

              <Button
                name="Cancel"
                btntype="submit"
                btnname="submit"
                className="btn btn-transparent-red btn-small"
                click={props.cancelFn}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FilterByBrand;
