import axios from "axios";
import { Auth } from "aws-amplify";

const URL = process.env.REACT_APP_HUB_INSIGHT_URL;

export const axiosClient = axios.create({
  baseURL: URL,
});
axiosClient.interceptors.request.use(
  async (request) => {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    request.headers["Authorization"] = `Bearer ${jwtToken}`;
    request.headers["Accept"] = "application/json";
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
