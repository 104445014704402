import "./rolesAndPermissionsCell.scss";
import React from "react";
import Checkbox from "../formUiElements/Checkbox";

const RolesAndPermissionsCell = (props) => {
  return (
    <>
      <tr className="randp-main-row fs-12">
        <td className="header-td">{props.rowHeading}</td>
        <td>
          <Checkbox checked={props.level0} readOnly />
        </td>
        <td>
          <Checkbox checked={props.level1} readOnly />
        </td>
        <td>
          <Checkbox checked={props.level2} readOnly />
        </td>
        <td>
          <Checkbox checked={props.level3} readOnly />
        </td>
        {/* <td>
          <Checkbox checked={props.marketing} readOnly />
        </td> */}
        <td>
          <Checkbox checked={props.level4} readOnly />
        </td>
        <td>
          <Checkbox checked={props.level5} readOnly />
        </td>
      </tr>
    </>
  );
};

export default RolesAndPermissionsCell;
