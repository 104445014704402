import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTableStateProps } from "../../../../hooks/useTableStateprops";
import Table from "../../../table/Table";
import { AiOutlineSearch } from "react-icons/ai";
import { GET_VOUCHER_LOCATION_BY_LOCATIONID } from "../../../../gqloperations/queries";
import { sortDesc } from "../../../../utils/sort";
import { useApolloClient } from "@apollo/client";
import { useLocationContext } from "../../../../contexts/LocationContext";
import { useHandleError } from "../../../../hooks/useHandleError";

const VoucherLocation = () => {
  const client = useApolloClient();
  const { state } = useLocationContext();
  const { id: locationId } = useParams();
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [tableData, setTableData] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const handleError = useHandleError();
  const navigate = useNavigate();

  const {
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
  } = useTableStateProps();

  const getVoucherLocationByLocationid = async (nextTokenFromResult = null) => {
    try {
      const result = await client.query({
        query: GET_VOUCHER_LOCATION_BY_LOCATIONID,
        fetchPolicy: "no-cache",
        variables: {
          locationId,
          // limit: 1,
          nextToken: nextTokenFromResult,
        },
      });

      if (result && nextTokenFromResult) {
        const mergedTable = [
          ...tableData,
          ...result?.data?.getVoucherLocationByLocationId?.items,
        ];
        const sortedResult = sortDesc(mergedTable, "createdAt");
        setTableData([...sortedResult]);
      } else {
        setTableData(
          sortDesc(
            result?.data?.getVoucherLocationByLocationId?.items,
            "createdAt"
          )
        );
      }

      setNextToken(result?.data?.getVoucherLocationByLocationId?.nextToken);
    } catch (error) {
      handleError(`${error}`);
    }
  };

  useEffect(() => {
    if (state?.index === 7) {
      getVoucherLocationByLocationid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.index]);
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const handleNextToken = () => {
    if (nextToken) {
      getVoucherLocationByLocationid(nextToken);
    }
  };

  const columns = [
    {
      accessorFn: (row) => {
        return row?.voucherDetails?.membershipDetail?.membershipName;
      },
      header: "Membership",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "membershipType",
    },

    {
      accessorFn: (row) => row?.voucherDetails?.voucherCode,
      header: "Code",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: true,
      enableGlobalFilter: true,
      id: "code",
    },
    {
      accessorFn: (row) => row?.voucherDetails?.type,
      header: "Type",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "type",
    },
    {
      accessorFn: (row) => row?.voucherDetails?.quantity,
      header: "Quantity",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "quantity",
    },
    {
      accessorFn: (row) =>
        row?.voucherDetails?.used ? row?.voucherDetails?.used : 0,
      header: "used",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "used",
    },
  ];
  return (
    <div>
      <div className="actions-row ">
        <div></div>
        <div className="left-elements ">
          <div className="search-and-button-row">
            <div className="search-container">
              <form onSubmit={onSearchBarSubmit}>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="search"
                      id="search"
                      value={searchBarInputVal}
                      onChange={handleChange}
                      placeholder="Voucher Code"
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {tableData && (
        <div className="landing-table location-vouchers">
          <Table
            onRowClick={(i) => {
              const id = i?.voucherId;
              navigate(`/dashboard/membership/viewvoucher/${id}`);
            }}
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            handleNextToken={handleNextToken}
            nextToken={nextToken}
          />
        </div>
      )}
    </div>
  );
};

export default VoucherLocation;
