import React, { useEffect, useReducer, useState } from "react";
import { useMemberContext } from "../../contexts/MemberContext";
import { Header, Table, Toggle } from "../../components";
import { SET_USER_SETTINGS } from "../../actions";

function Allusersetting() {
  const [tableData, setTableData] = useState([
    {
      id: "setting1",
      setting: "Member Welcome Pop-up when entering a gym location",
      value: false,
    },
  ]);
  const { userSettings } = useMemberContext();
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const { dispatch } = useMemberContext();
  const columns = [
    {
      accessorKey: "setting",
      header: "setting",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "setting",
    },
    {
      accessorKey: "value",
      header: "setting",
      enableSorting: false,
      id: "row-actions",
      cell: (info) => (
        <Toggle
          checked={info.getValue()}
          onChange={(e) => {
            const valueToggle = e.target.checked;
            const settingIndex = tableData.findIndex(
              (i) => i.id === info.row.original.id
            );
            const newTableData = [...tableData];
            newTableData[settingIndex].value = valueToggle;
            setTableData(newTableData);
            localStorage.setItem(
              "userSettingAll",
              JSON.stringify(newTableData)
            );
            dispatch({
              type: SET_USER_SETTINGS,
              payload: {
                userSettings: newTableData,
              },
            });
          }}
        />
      ),
    },
  ];
  useEffect(() => {
    if (userSettings) {
      const tableSettings = userSettings.map((setting) => {
        return {
          id: setting.id,
          setting: setting.setting,
          value: setting.value,
        };
      });
      setTableData(tableSettings);
    }
  }, []);
  return (
    <>
      <Header pageTitle="All User Setting" />
      <div className="tabs mt-32">
        <Table
          data={tableData}
          columns={columns}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          sorting={sorting}
          setSorting={setSorting}
          rowsPerPage={10}
          //   handleNextToken={handleNextToken}
          //   nextToken={nextToken}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
        />
      </div>
    </>
  );
}

export default Allusersetting;
