const paymentFrequencyType = [
  { amount: 1, type: "WEEKS", display: "WEEKLY" },
  { amount: 2, type: "WEEKS", display: "FORTNIGHTLY" },
  { amount: 1, type: "DAYS", display: "DAILY" },
  { amount: 1, type: "MONTHS", display: "MONTHLY" },
  { amount: 1, type: "YEARS", display: "ANNUALLY" },
];
export const getPaymentFrequencyString = ({ amount, type }) => {
  if (amount === 0) return "";
  const match = paymentFrequencyType.find(
    (item) => item.amount === amount && item.type === type
  );
  return match ? match.display : `${amount} ${type}`;
};
export const getContractLengthString = ({ amount, type }) => {
  if (amount === 0) return "";
  if (amount === 1) {
    return amount + " " + type.slice(0, -1);
  } else {
    return amount + " " + type;
  }
};
