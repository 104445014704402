import "./classTemplates.scss";
import { useState, useEffect } from "react";
import { UPDATE_CLASS_TEMPLATE } from "../../gqloperations/mutations";
import { useQuery, useMutation } from "@apollo/client";
import { GET_CLASS_TEMPLATE_BY_BRANDID } from "../../gqloperations/queries";
import { useMemberContext } from "../../contexts/MemberContext";
import { Link, useNavigate } from "react-router-dom";
import getTypeIcon from "../../utils/getTypeIcon";
import { brandTypes } from "../../constants/commonConstant";
import { AiOutlineSearch, AiOutlineEdit } from "react-icons/ai";
import { toast } from "react-toastify";
import { MdOutlineDelete } from "react-icons/md";
import {
  ClassStats,
  Button,
  Header,
  Toggle,
  Table,
  ExportGroup,
  Modal,
  StatusModal,
} from "../../components";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";

const ClassTemplates = (props) => {
  const { permissions } = useAuthContext();
  let navigate = useNavigate();

  const { sidebarBrandId, createLog } = useMemberContext();

  const [tableData, setTableData] = useState([]);
  const [nextToken, setNextToken] = useState(null);

  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [componentName, setComponentName] = useState("");
  const [curRow, setCurRow] = useState({
    id: "",
    className: "",
    isActive: false,
  });

  const handleComponent = (componentname, rowObj) => {
    setComponentName(componentname);
    setCurRow(rowObj);
  };
  const modalClose = () => {
    setComponentName("");
    setCurRow({ id: "", isActive: false });
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleEditClick = (id) => {
    navigate(`/dashboard/classTemplates/editClassTemplate/${id}`);
  };

  const handleStatusClick = (rowObj) => {
    let values = {
      id: rowObj.id,
      isActive: !rowObj.isActive,
    };

    updateClassTemplate({
      variables: {
        input: values,
      },

      onCompleted: () => {
        // OnSubmitProps.resetForm();
        setTableData([]);
        setNextToken();
        toast.success("Updated successfully");
        modalClose();
      },
      refetchQueries: [
        {
          query: GET_CLASS_TEMPLATE_BY_BRANDID,
          variables: {
            brandId: `${sidebarBrandId}`,
            sortDirection: "DESC",
            nextToken: null,
            filter: null,
            limit: 1000,
          },
        },
      ],
    });
  };

  const handleDeleteClick = (rowObj) => {
    let values = {
      id: rowObj.id,
      isDeleted: true,
    };

    updateClassTemplate({
      variables: {
        input: values,
      },

      onCompleted: () => {
        // OnSubmitProps.resetForm();
        setTableData([]);
        setNextToken();
        toast.success("Updated successfully");
        modalClose();
      },
      refetchQueries: [
        {
          query: GET_CLASS_TEMPLATE_BY_BRANDID,
          variables: {
            brandId: `${sidebarBrandId}`,
            sortDirection: "DESC",
            nextToken: null,
            filter: null,
            limit: 1000,
          },
        },
      ],
    });
  };

  //calling a mutation
  const [updateClassTemplate] = useMutation(UPDATE_CLASS_TEMPLATE);

  const columns = [
    {
      accessorKey: "brandDetails",
      header: "Brand",
      cell: (info) => {
        const brandName = info.getValue() ? info.getValue().name : "";
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "className",
      header: "CLASS NAME",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "classname",
    },

    {
      accessorKey: "classDuration",
      header: "DURATION",
      cell: (info) => {
        return info?.getValue() ? `${info.getValue()}  MINS` : "";
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "duration",
    },
    {
      accessorFn: (originalRow) => {
        return (
          <Toggle
            checked={originalRow.isActive}
            value={originalRow.isActive}
            onChange={() =>
              handleComponent("status-confirm", {
                id: originalRow.id,
                className: originalRow.className,
                isActive: originalRow.isActive,
              })
            }
          />
        );
      },
      accessorKey: "isActive",
      header: "ACTIVE",
      cell: (info) => info.getValue(),

      enableSorting: false,
      enableGlobalFilter: false,
      id: "active",
    },
    {
      id: "row-actions",
      cell: (props) => (
        <span className="button-td">
          {permissions.includes("CanEditClassTemplate") && (
            <AiOutlineEdit
              onClick={() => {
                handleEditClick(props.row.original.id);
              }}
            />
          )}
          {permissions.includes("CanDeleteClassTemplate") && (
            <MdOutlineDelete
              onClick={() =>
                handleComponent("delete-template", {
                  id: props.row.original.id,
                  className: props.row.original.className,
                })
              }
              className="delete-icon"
            />
          )}
        </span>
      ),
    },
  ];

  const {
    data: getclasstemplatebybrandiddata,
    loading: getclasstemplatebybrandidloading,
    error: getclasstemplatebybrandiderror,
    refetch,
  } = useQuery(GET_CLASS_TEMPLATE_BY_BRANDID, {
    variables: {
      brandId: `${sidebarBrandId}`,
      sortDirection: "DESC",
      nextToken: null,
      filter: null,
      limit: 1000,
    },
    fetchPolicy: "no-cache",
  });

  const handleNextToken = () => {
    if (getclasstemplatebybrandiddata.getClassTemplateByBrandId.nextToken) {
      refetch({
        nextToken:
          getclasstemplatebybrandiddata.getClassTemplateByBrandId.nextToken,
      });
    }
  };

  useEffect(() => {
    createLog("CLICKED Class Template tab on the sidebar", "sidebar");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getclasstemplatebybrandiddata) {
      const isNotDeleteClassTemplate =
        getclasstemplatebybrandiddata.getClassTemplateByBrandId.items.filter(
          (item) => item.isDeleted !== true
        );
      if (nextToken)
        isNotDeleteClassTemplate &&
          setTableData([...tableData, ...isNotDeleteClassTemplate]);
      else setTableData([...isNotDeleteClassTemplate]);
    }
  }, [getclasstemplatebybrandiddata]);

  useEffect(() => {
    setNextToken(
      getclasstemplatebybrandiddata?.getClassTemplateByBrandId?.nextToken
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, nextToken, tableData]);

  const onSubmit = (event) => {
    event.preventDefault();
    const search = searchValue.toUpperCase();
    setNextToken();
    refetch({ filter: { className: { contains: search } } });
  };

  return (
    <div>
      <Header pageTitle="Class Templates" />

      <div className="class">
        <div className="class-container template">
          <div className="class-info">
            <ClassStats />

            {/* <div className="row1-infocards">
              <div className="col1">
                <Card
                  title="Classes Per Week"
                  heading="Scheduled classes"
                  value=""
                  icon="AiOutlineCalendar"
                />
              </div>
              <div className="col2">
                <Card
                  title="AVG. Attendance"
                  heading="Attendance per class"
                  value=""
                  icon="MdOutlineArrowDropDown"
                />
              </div>
              <div className="col3">
                <Card
                  title="Capacity"
                  heading="Club's class capacity"
                  value=""
                  icon="MdOutlineArrowDropUp"
                />
              </div>
              <div className="col4">
                <Card
                  title="Cost per head"
                  heading="Average cost per head"
                  value=""
                  icon="MdOutlineArrowDropDown"
                />
              </div>
            </div> */}
            {componentName && (
              <Modal>
                {componentName === "status-confirm" && (
                  <StatusModal
                    curRow={curRow}
                    className={curRow.className}
                    modalHeaderTxt={"Change Class Template Status"}
                    modalParaText={`Are you sure you want to ${
                      curRow.isActive ? "deactivate" : "activate"
                    } this class template?`}
                    handleStatusClick={handleStatusClick}
                    modalClose={modalClose}
                  />
                )}
                {componentName === "delete-template" && (
                  <StatusModal
                    curRow={curRow}
                    className={curRow.className}
                    modalHeaderTxt={"Delete Class Template"}
                    modalParaText={
                      "Are you sure you want to delete this class template?"
                    }
                    handleStatusClick={handleDeleteClick}
                    modalClose={modalClose}
                  />
                )}
              </Modal>
            )}
            {!getclasstemplatebybrandidloading &&
              !getclasstemplatebybrandiderror && (
                <>
                  <div className="row2 actions-row">
                    <ExportGroup
                      data={tableData}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                      columns={columns}
                      sorting={sorting}
                    />

                    <div className="left-elements">
                      <div className="search-container ">
                        <form onSubmit={onSubmit}>
                          <div className="search-bar">
                            <div className="search-bar-container flex-wrap">
                              <input
                                className="fs-10"
                                type="text"
                                name="search"
                                id="search"
                                value={searchValue}
                                onChange={handleChange}
                                placeholder="CLASS TEMPLATE"
                              />
                              <button type="submit">
                                <AiOutlineSearch />
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/* // <div className="right-btn">
                      //   <Link to="/dashboard/classTemplates/addClassTemplate">
                      //     <Button
                      //       name="Add Class Template"
                      //       btntype="submit"
                      //       btnname="submit"
                      //       className="btn  fs-12 btn-primary"
                      //     />
                      //   </Link>
                      // </div> */}
                      {permissions.includes("CanAddClassTemplate") && (
                        <div className="right-btn">
                          <Link to="/dashboard/classTemplates/addClassTemplate">
                            <Button
                              name="Add Class Template"
                              btntype="submit"
                              btnname="submit"
                              className="btn  fs-12 btn-primary"
                            />
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="class-template-container">
                    <div className=" template-table">
                      <Table
                        onRowClick={(i) => handleEditClick(i.id)}
                        data={tableData}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        sorting={sorting}
                        setSorting={setSorting}
                        columns={columns}
                        rowsPerPage={10}
                        handleNextToken={handleNextToken}
                        nextToken={nextToken}
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}
                      />
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassTemplates;
