import React, { useEffect, useState } from "react";
import { NavigateButtonGroup } from "../index";
import { Button } from "../../../../components";
import { Formik, Form } from "formik";
import { useAddMemberContext } from "../../../../contexts/AddMemberContext";
import "./membership.css";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_ALL_LOCATIONS_BY_LOCATIONID,
  GET_LOCTAION_BY_ID,
  GET_VOUCHER_BY_CODE,
} from "../../../../gqloperations/queries";
import { useMemberContext } from "../../../../contexts/MemberContext";
import {
  endOfDay,
  format,
  isAfter,
  isBefore,
  startOfDay,
  add,
  addDays,
} from "date-fns";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { sortAsc } from "../../../../utils/sort";
import checkDateOverlap from "../../../../utils/checkDateOverlap";
import { useHandleError } from "../../../../hooks/useHandleError";

function Membership() {
  const { sidebarLocationId, sidebarBrandId } = useMemberContext();

  const [getVoucherByCode, { loading: getVoucherByCodeLoading }] =
    useLazyQuery(GET_VOUCHER_BY_CODE);

  const [membership, setMembership] = useState([]);
  const [error, setError] = useState("");
  const [voucherDetail, setVoucherDetail] = useState({});
  const [membershipDetail, setMembershipDetail] = useState({});
  const [voucherCode, setVoucherCode] = useState("");
  const [currentMaintenance, setCurrentMaintenance] = useState({});
  const [membershipBelongToVoucher, setMembershipBelongToVoucher] = useState(
    {}
  );

  const handleError = useHandleError();

  const convertUTCtoAusTimezone = (utcDate, timeZone) => {
    return format(new Date(utcDate), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", {
      timeZone,
    });
  };
  const {
    data: membershipList,
    loading: allLocationDataLoading,
    error: allLocationDataError,
    refetch,
  } = useQuery(GET_ALL_LOCATIONS_BY_LOCATIONID, {
    fetchPolicy: "no-cache",
    variables: {
      locationId: sidebarLocationId,
      limit: 1000,
      //   nextToken: nextToken,
    },
    onCompleted: (data) => {
      const tableItems = data?.getMembershipByLocationId.map((cur) => {
        const { items } = cur.membershipLocations;
        return items;
      });
      const flattendItems = tableItems.flat();
      const validDateMembership = flattendItems?.filter((item) => {
        const activeMembership =
          item.membershipDetails?.activationDates.items?.some((i) => {
            const timezone = locationDetail?.getLocation?.timezone;
            const openingMembership = !isAfter(
              new Date(i.startDateTime),
              new Date()
            );
            const expiringMembership =
              (i.endDateTime &&
                !isBefore(
                  endOfDay(
                    new Date(convertUTCtoAusTimezone(i.endDateTime, timezone))
                  ),
                  new Date()
                )) ||
              !i.endDateTime;
            const globalActiveMemership = item.membershipDetails.isActive;
            return (
              openingMembership && expiringMembership && globalActiveMemership
            );
          });
        return activeMembership;
      });
      setMembership(validDateMembership);
    },
  });
  const { data: locationDetail, loading: locationDetailLoading } = useQuery(
    GET_LOCTAION_BY_ID,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: sidebarLocationId,
      },
      onCompleted: (data) => {
        const maintenanceDates =
          data?.getLocation?.locationMaintenance?.items.filter(
            (maintenance) => !maintenance.isDeleted
          );
        setCurrentMaintenance(
          maintenanceDates?.find(({ startDateTime, endDateTime }) => {
            return checkDateOverlap(new Date(), {
              start: startDateTime,
              end: endDateTime,
            });
          })
        );
        setMemberInformation({
          ...memberInformation,
          locationDetail: data.getLocation,
        });
      },
    }
  );

  const {
    handleMoveStepNext,
    memberData,
    setMemberData,
    memberInformation,
    setMemberInformation,
  } = useAddMemberContext();

  const [isRecurring, setIsRecurring] = useState(true);

  useEffect(() => {
    setMembershipDetail({});
    setVoucherDetail({});
    setVoucherCode("");
  }, [sidebarLocationId, memberInformation?.age]);

  const setInfoAndVoucherDetail = (voucher) => {
    setVoucherDetail(voucher);
  };

  const handleVoucherError = (errorMessage) => {
    setError(errorMessage);
    handleError(errorMessage);
    setVoucherDetail({});
  };

  const checkVoucherCode = (voucherCode) => {
    getVoucherByCode({
      fetchPolicy: "no-cache",
      variables: {
        voucherCode: voucherCode,
      },
      onCompleted: (data) => {
        if (data?.getVoucherByVoucherCode.items.length) {
          const voucher = data.getVoucherByVoucherCode.items[0];
          const voucherLocation = voucher.voucherLocation.items.filter(
            (item) => item.locationId === sidebarLocationId
          );
          if (
            voucher.memberType === "EXIST" ||
            voucher.memberType === "PREVIOUS"
          ) {
            handleVoucherError("This voucher is not valid for new member!");
          } else if (!voucherLocation.length) {
            handleVoucherError("This voucher is not valid for this location!");
          } else if (voucher.used >= voucher.quantity) {
            handleVoucherError("Voucher limit exceeded!");
          } else if (
            isAfter(new Date(), new Date(voucher.endDateTime)) &&
            voucher?.endDateTime
          ) {
            handleVoucherError("This voucher has expired!");
          } else if (isBefore(new Date(), new Date(voucher.startDateTime))) {
            handleVoucherError(
              `You cannot use this voucher before ${new Date(
                voucher.startDateTime
              ).toLocaleDateString("en-GB")}!`
            );
          } else if (
            !membership.some(
              (item) =>
                item?.membershipDetails.id === voucher.membershipDetail.id
            )
          ) {
            handleVoucherError("This voucher is not valid for this member!");
          } else {
            if (!voucher.isActive) {
              handleVoucherError("This voucher is not active!");
            } else {
              setMembershipBelongToVoucher(
                (membership?.filter(
                  (item) =>
                    item?.membershipDetails?.id ===
                    voucher?.membershipDetail?.id
                ))[0]
              );
              setInfoAndVoucherDetail(voucher);
              setError("");
            }
          }
        } else {
          setVoucherDetail({});
          handleVoucherError("This voucher does not exist!");
        }
      },
    });
  };
  const handleSelectMembership = (membershipDetail) => {
    setMembershipDetail(membershipDetail);
  };
  const submitUserData = (value) => {
    document
      .getElementById("logo")
      .scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
    const openDate = locationDetail.getLocation.openDate;
    const membershipBelongToVoucher = membership?.filter(
      (item) =>
        item?.membershipDetails?.id === voucherDetail?.membershipDetail?.id
    )[0];

    const maintenanceDates = sortAsc(
      memberInformation?.locationDetail?.locationMaintenance?.items,
      "startDateTime"
    ).filter((maintenance) => !maintenance.isDeleted);

    const overlappedMaintenance = maintenanceDates.find(
      ({ startDateTime, endDateTime }) => {
        return checkDateOverlap(new Date(), {
          start: startDateTime,
          end: endDateTime,
        });
      }
    );

    const maintenanceEnd =
      overlappedMaintenance && overlappedMaintenance.endDateTime
        ? add(new Date(overlappedMaintenance.endDateTime), { days: 1 })
        : null;

    const membershipStartDate =
      openDate && isAfter(new Date(openDate), new Date())
        ? openDate
        : maintenanceEnd
        ? maintenanceEnd
        : new Date();

    setMemberData({
      ...memberData,
      memberDetails: {
        ...memberData.memberDetails,
        homeLocationId: sidebarLocationId,
        joinLocationId: sidebarLocationId,
        country: locationDetail.getLocation?.country,
        brandId: sidebarBrandId,
      },
      membershipId:
        Object.keys(membershipBelongToVoucher || {}).length > 0
          ? voucherDetail?.membershipDetail?.id
          : membershipDetail.membershipDetails?.id,
      membershipLocationId:
        Object.keys(membershipBelongToVoucher || {}).length > 0
          ? membershipBelongToVoucher.id
          : membershipDetail?.id,
      membershipStartDate: membershipStartDate,
      voucherCode: voucherCode,
    });
    const isVoucherDelay =
      voucherDetail?.paymentDelay?.amount !== 0 &&
      voucherDetail?.paymentDelay !== null;

    const isPaymentDelay =
      membershipDetail.membershipDetails?.paymentDelay?.amount !== 0 &&
      membershipDetail.membershipDetails?.paymentDelay !== null;

    let membershipPrice = 0;
    let joiningFee = 0;
    let activationFee = 0;
    let accessFee = 0;
    let membershipPriceDelay = 0;

    const voucherExists = Object.keys(voucherDetail || {}).length > 0;
    if (voucherExists) {
      if (isVoucherDelay) {
        membershipPrice = 0;
        const discountPercentage = voucherDetail?.cost * 0.01;
        const originalPrice = membershipBelongToVoucher?.costPrice;
        const discountedPrice =
          originalPrice - originalPrice * discountPercentage;
        membershipPriceDelay =
          voucherDetail.type === "PERCENT"
            ? parseFloat(discountedPrice?.toFixed(2)) || 0
            : parseFloat(voucherDetail.cost?.toFixed(2)) || 0;
      } else {
        const discountPercentage = voucherDetail?.cost * 0.01;
        const originalPrice = membershipBelongToVoucher?.costPrice;
        const discountedPrice =
          originalPrice - originalPrice * discountPercentage;
        membershipPrice =
          voucherDetail.type === "PERCENT"
            ? parseFloat(discountedPrice?.toFixed(2)) || 0
            : parseFloat(voucherDetail.cost?.toFixed(2)) || 0;
      }
      joiningFee = voucherDetail?.joiningFee || 0;
      activationFee = voucherDetail?.activationFee || 0;
      accessFee = voucherDetail?.passFee || 0;
    } else {
      let finalFees = { joiningFee: 0, activationFee: 0, fobFee: 0 };
      if (
        membershipDetail.joiningFee === null &&
        membershipDetail.activationFee === null &&
        membershipDetail.fobFee === null
      ) {
        finalFees = {
          joiningFee: membershipDetail.membershipDetails.joiningFee,
          activationFee: membershipDetail.membershipDetails.joiningFee2,
          fobFee: membershipDetail.membershipDetails.joiningFee3,
        };
      } else {
        finalFees = {
          joiningFee: membershipDetail.joiningFee,
          activationFee: membershipDetail.activationFee,
          fobFee: membershipDetail.fobFee,
        };
      }
      membershipPrice =
        parseFloat(
          Number(isPaymentDelay ? 0 : membershipDetail?.costPrice).toFixed(2)
        ) || 0;
      membershipPriceDelay = membershipDetail.costPrice;
      joiningFee = finalFees?.joiningFee || 0;
      activationFee = finalFees?.activationFee || 0;
      accessFee = finalFees?.fobFee || 0;
    }

    setMemberInformation({
      ...memberInformation,
      membershipDetails:
        Object.keys(membershipBelongToVoucher || {}).length > 0
          ? membershipBelongToVoucher
          : membershipDetail,
      voucherDetail: voucherCode ? voucherDetail : {},
      yourMembershipData: {
        membershipPrice: membershipPrice,
        joiningFee: joiningFee,
        activationFee: activationFee,
        accessFee: accessFee,
        membershipPriceDisplay: membershipPriceDelay,
      },
    });
    setTimeout(() => {
      handleMoveStepNext();
    }, 700);
  };
  if (locationDetailLoading) {
    return (
      <div className="singup-loading-full-width">
        <BeatLoader className="loading" color="white" size={20} />
      </div>
    );
  }
  return (
    <div className="membership-container">
      <div>
        <div>
          <p className="membership-step-title">
            MEMBERSHIP OPTION FOR
            <br />
            <span>PLUS FITNESS {locationDetail?.getLocation?.name} </span>
            <br />
            {Object.keys(currentMaintenance || {}).length > 0 && (
              <div>
                <span className="location-maintenance">
                  {" "}
                  {`This location is currently undergoing maintenance from ${format(
                    new Date(currentMaintenance.startDateTime),
                    "dd/MM/yyyy"
                  )} to ${format(
                    new Date(currentMaintenance.endDateTime),
                    "dd/MM/yyyy"
                  )}. You can join now, but the club will not open until ${format(
                    addDays(new Date(currentMaintenance.endDateTime), 1),
                    "MMMM d, yyyy"
                  )}`}
                </span>
                <br />
              </div>
            )}
            <span>DO YOU HAVE A PROMO CODE?</span>
          </p>
          <div className="promoCodeInputs">
            <input
              maxLength={8}
              type="name"
              label="name"
              name="voucherCode"
              id="name"
              value={voucherCode}
              autoComplete="off"
              onChange={(e) => {
                setVoucherCode(e.target.value);
                if (!e.target.value) {
                  setVoucherDetail({});
                }
              }}
            />
            <Button
              className="btn btn-transparent btn-small"
              name="apply"
              btnname="button"
              btntype="button"
              loading={getVoucherByCodeLoading}
              click={() => checkVoucherCode(voucherCode)}
            ></Button>
          </div>{" "}
        </div>
        <div className="membership-type-container">
          <Button
            click={() => setIsRecurring(true)}
            name="FORTNIGHTLY"
            btntype="button"
            className={`membership-type-button ${isRecurring && "active"}`}
          />{" "}
          <Button
            click={() => setIsRecurring(false)}
            name="PAID IN FULL"
            btntype="button"
            className={`membership-type-button ${!isRecurring && "active"}`}
          />{" "}
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          voucherCode: "",
        }}
        onSubmit={async (values, OnSubmitProps) => {
          if (
            !Object.keys(membershipDetail)?.length &&
            !Object.keys(voucherDetail)?.length
          ) {
            handleError("Please select a membership");
          } else {
            submitUserData(values, OnSubmitProps);
          }
        }}
      >
        {(formik) => {
          return (
            <Form>
              {Object.keys(voucherDetail || {}).length && !error ? (
                <div className="voucher-card">
                  <div className="code-applied"> PROMO CODE APPLIED</div>
                  <div className="membership-content">
                    {voucherDetail.type === "PERCENT" ||
                    voucherDetail.type === "FIXED" ? (
                      <p>{voucherDetail?.membershipDetail?.membershipName}</p>
                    ) : (
                      <span className="complimentary-header">
                        PF-
                        <span className="">
                          {voucherDetail.type === "VISIT_PASS"
                            ? "VISIT"
                            : "COMPLIMENTARY"}
                        </span>
                      </span>
                    )}
                    {voucherDetail?.type !== "COMPLIMENTARY" && (
                      <p>
                        $
                        {voucherDetail?.membershipDetail?.recurring
                          ? (voucherDetail.type === "PERCENT"
                              ? membershipBelongToVoucher?.costPrice -
                                membershipBelongToVoucher?.costPrice *
                                  (voucherDetail?.cost * 0.01)
                              : voucherDetail?.cost) /
                            voucherDetail?.membershipDetail?.paymentFrequency.amount?.toFixed(
                              2
                            )
                          : voucherDetail?.cost?.toFixed(2)}
                        {/* period next to the price can consider make it function if needed */}
                        {voucherDetail?.membershipDetail?.recurring && (
                          <span>
                            /
                            {
                              voucherDetail?.membershipDetail?.paymentFrequency
                                .type
                            }
                          </span>
                        )}
                      </p>
                    )}

                    {/* gray line under big price */}
                    {voucherDetail?.type !== "COMPLIMENTARY" && (
                      <div>
                        {voucherDetail?.membershipDetail?.recurring ? (
                          <p className="price-sum">
                            {`$${
                              voucherDetail.type === "PERCENT"
                                ? membershipBelongToVoucher?.costPrice -
                                  membershipBelongToVoucher?.costPrice *
                                    (voucherDetail?.cost * 0.01)
                                : voucherDetail?.cost
                            } fortnightly Direct Debit`}
                          </p>
                        ) : (
                          <p className="price-sum">SINGLE PAYMENT</p>
                        )}
                      </div>
                    )}

                    <div className="description">
                      {(voucherDetail?.type === "FIXED" ||
                        voucherDetail?.type === "PERCENT") &&
                        [
                          ...(voucherDetail?.membershipDetail?.description.includes(
                            "All Plus Fitness 24/7"
                          )
                            ? ['"""All Plus Fitness 24/7']
                            : [""]),
                          ...(voucherDetail?.membershipDetail?.description.includes(
                            "Eligibility"
                          )
                            ? ["Eligibility: N/A"]
                            : [""]),
                          `$${voucherDetail?.joiningFee} Joining fee`,

                          `$${voucherDetail?.activationFee} Activation fee`,

                          `$${voucherDetail?.passFee} Pass fee`,
                        ].map(
                          (i, index) =>
                            i && (
                              <div key={index}>
                                <FaRegCircleCheck />
                                <p>{i}</p>
                              </div>
                            )
                        )}
                      {voucherDetail?.type !== "FIXED" &&
                        voucherDetail?.type !== "PERCENT" &&
                        [
                          ...voucherDetail?.note
                            .split(",")
                            .concat([
                              ...(voucherDetail.type === "VISIT_PASS"
                                ? [`For ${voucherDetail.visit} visits`]
                                : [`For ${voucherDetail.duration} days`]),
                              `$${voucherDetail?.joiningFee} Joining fee`,
                              `$${voucherDetail?.activationFee} Activation fee`,
                              `$${voucherDetail?.passFee} Pass fee`,
                            ]),
                        ].map(
                          (i, index) =>
                            i && (
                              <div key={index}>
                                <FaRegCircleCheck />
                                <p>{i}</p>
                              </div>
                            )
                        )}
                    </div>
                  </div>
                  <Button
                    btntype="button"
                    name="Choose Plan"
                    className={`select-membership active btn btn-transparent btn-lg`}
                  ></Button>
                </div>
              ) : (
                <div className="membership-list">
                  {membership
                    .filter(
                      (i) =>
                        i.membershipDetails.recurring === isRecurring &&
                        i.membershipDetails.membershipLevel ===
                          memberInformation.membershipLevel
                    )
                    .map((i, index) => {
                      let finalFees = {
                        joiningFee: 0,
                        activationFee: 0,
                        fobFee: 0,
                      };
                      if (
                        i.joiningFee === null &&
                        i.activationFee === null &&
                        i.fobFee === null
                      ) {
                        finalFees = {
                          joiningFee: i.membershipDetails.joiningFee,
                          activationFee: i.membershipDetails.joiningFee2,
                          fobFee: i.membershipDetails.joiningFee3,
                        };
                      } else {
                        finalFees = {
                          joiningFee: i.joiningFee,
                          activationFee: i.activationFee,
                          fobFee: i.fobFee,
                        };
                      }
                      return (
                        <div key={i.id}>
                          <div>
                            <p>{i.membershipDetails.membershipName}</p>

                            {/* big price in white */}
                            <p>
                              $
                              {isRecurring
                                ? (i.costPrice / 2)?.toFixed(2)
                                : i.costPrice?.toFixed(2)}
                              {/* period next to the price can consider make it function if needed */}
                              {isRecurring && (
                                <span>
                                  {" "}
                                  /
                                  {i.membershipDetails.paymentFrequency?.type?.charAt(
                                    i.membershipDetails?.paymentFrequency?.type
                                      ?.length - 1
                                  ) === "s"
                                    ? i.membershipDetails?.paymentFrequency?.type?.slice(
                                        0,
                                        -1
                                      )
                                    : i.membershipDetails?.paymentFrequency?.type?.slice(
                                        0,
                                        -1
                                      )}
                                </span>
                              )}
                            </p>

                            {/* gray line under big price */}
                            {isRecurring ? (
                              <p className="price-sum">
                                $
                                {i.costPrice.toFixed(2) +
                                  " fortnightly Direct Debit"}
                              </p>
                            ) : (
                              <p className="price-sum">SINGLE PAYMENT</p>
                            )}
                            <div className="description">
                              {[
                                ...i.membershipDetails.description?.split(","),
                                `Joining fee: $${
                                  finalFees.joiningFee?.toFixed(2) ?? "0.00"
                                }`,
                                `Activation fee: $${
                                  finalFees.activationFee?.toFixed(2) ?? "0.00"
                                }`,
                                `Access fee: $${
                                  finalFees.fobFee?.toFixed(2) ?? "0.00"
                                }`,
                              ].map((i) => (
                                <div>
                                  <FaRegCircleCheck />
                                  <p>{i}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <Button
                            click={() => handleSelectMembership(i)}
                            btntype="button"
                            name="Choose Plan"
                            className={`select-membership btn btn-transparent btn-lg ${
                              i.id === membershipDetail?.id && "active"
                            }`}
                          ></Button>
                        </div>
                      );
                    })}
                </div>
              )}
              <NavigateButtonGroup />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default Membership;
