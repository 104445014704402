import "./chatWindow.scss";
import hub_logo from "../../assets/images/hub_logo.svg";

import { AiOutlineRight } from "react-icons/ai";
import profile from "../../assets/images/icons/chat_profile.png";
import Button from "../formUiElements/Button";
import { SearchBar } from "../../components";

const ChatWindow = (props) => {
  return (
    <div className="outer-containers">
      <div className="top-container">
        <img src={hub_logo} alt="logo" width="90" height="40" />
        <div className="greeting-content">
          <h2 className="fs-25">Hey, Gordon</h2>
          <p className="fs-12">
            We're here to answer your questions about the Hub.
          </p>
        </div>
      </div>
      <div className="bot-container">
        <div className="first-container">
          <div className="search-container">
            <p className="fs-10">Search For Help</p>
            <SearchBar placeholder="" />
          </div>
          <div className="faq-container">
            <p className="fs-10 faq-title">Frequently Searched Topics</p>
            <div className="link-container">
              <div className="link">
                <p className="fs-10">User settings and access</p>
                <AiOutlineRight className="icon"></AiOutlineRight>
              </div>
              <div className="link">
                <p className="fs-10">Creating reports</p>
                <AiOutlineRight className="icon"></AiOutlineRight>
              </div>
              <div className="link">
                <p className="fs-10">Adding/Deleting a member</p>
                <AiOutlineRight className="icon"></AiOutlineRight>
              </div>
            </div>
          </div>
        </div>

        <div className="conversation-container">
          <p className="conversation-title fs-10">Start Another Conversation</p>
          <div>
            <div className="conversation-mid-container">
              <img src={profile} className="icon" alt="" />
              {/* <CgProfile className="icon"></CgProfile> */}
              <div className="mid-text-container">
                <p className="fs-10">We'll be back online</p>
                <p className="fs-10">Later today</p>
              </div>
            </div>
          </div>
          <Button
            name="Send Us A Message"
            btntype="button"
            btnname="cancel"
            className="btn btn-lg fs-10"
          />
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
