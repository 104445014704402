// import { RiStarSFill } from "react-icons/ri";
import "./reportCategory.scss";
import { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { IoIosArrowDown } from "react-icons/io";

export default function ReportCategory({
  title,
  reports,
  currentCategory,
  setCurrentCategory,
}) {
  const [dropdown, setDropdown] = useState(false);
  const navigate = useNavigate();
  const { permissions } = useAuthContext();
  const handleClick = () => {
    setCurrentCategory(title);
    setDropdown(!dropdown);
  };
  const handleOpen = (reportId) => {
    navigate(`/dashboard/report/${reportId}`);
  };

  const filteredMemberReports = reports.filter((report) =>
    permissions.includes(report.permission)
      ? report
      : !report.permission
      ? report
      : ""
  );

  return (
    <>
      <div className="report-type-card" onClick={handleClick}>
        <div className="front-face-elements">
          <h2 className="fs-35">{title}</h2>
          <div className={`expand-button ${currentCategory === title && dropdown ? "open" : ""}`} onClick={handleClick}>
            {/* {currentCategory === title && dropdown ? (
              <RiArrowDropUpLine />
            ) : (
              <RiArrowDropDownLine />
            )} */}
            <IoIosArrowDown />
          </div>
        </div>
      </div>
      <div className="report-body">
        {currentCategory === title && dropdown && (
          <table>
            <tbody>
              {filteredMemberReports.map((report) => (
                <tr key={report.id}>
                  {/*TODO: favorite fns next stage */}
                  {/* <td><button className={report.isFavorite? "starButton highlightStar" : "starButton"}><RiStarSFill /></button></td> */}
                  <td className="fs-12">{report.name}</td>
                  <td className="description">
                    <p className="fs-12">{report.description}</p>
                  </td>
                  <td>
                    <Button
                      name="Open"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-lg-xwide btn-darkgray"
                      onClick={() => handleOpen(report.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}
