import { useMemberContext } from "../../contexts/MemberContext";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import SidebarBrandItem from "./SidebarBrandItem";
import { brandTypes } from "../../constants/commonConstant";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import React, { useEffect, useRef } from "react";

const SidebarBrandList = ({ show, setShow, setBrand }) => {
  const { sidebarBrandName } = useMemberContext();
  const { locationAccess } = useAuthContext();
  const dropdownRef = useRef(null);

  const staffBrand = brandTypes.filter((brand) => {
    return brand.name === sidebarBrandName;
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow((show) => ({ ...show, brand: false }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {locationAccess?.brands?.length > 1 ? (
        <div ref={dropdownRef}>
          <div
            className="brand-selector"
            onClick={() => setShow((show) => ({ ...show, brand: !show.brand }))}
          >
            <span className="logo">
              <img
                className="cl-logo"
                src={staffBrand[0]?.logo}
                alt="logo"
                width="100%"
              />
            </span>
            <span className="arrow">
              {!show.brand ? <IoIosArrowDown /> : <IoIosArrowUp />}
            </span>
          </div>

          <div className="brand-list-dropdown">
            <>
              {show.brand && (
                <ul>
                  {locationAccess?.brands.map((brandItem) => {
                    return (
                      <SidebarBrandItem
                        brand={brandItem}
                        setBrand={setBrand}
                        key={brandItem.brandId}
                      />
                    );
                  })}
                </ul>
              )}
            </>
          </div>
        </div>
      ) : (
        <div>
          <span className="logo">
            {staffBrand[0]?.logo ? (
              <img
                className="cl-logo"
                src={staffBrand[0]?.logo}
                alt="logo"
                width="100%"
              />
            ) : (
              <div className="logo mb-8"></div>
            )}
          </span>
        </div>
      )}
    </>
  );
};
// };

export default SidebarBrandList;
