import "./datePicker.scss";

const DatePicker = (props) => {
  return (
    <>
      <div className="flex-col">
        <span className="datepicker-container">
          <input
            type={props?.type ? props?.type : "date"}
            id={props.id}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            className={props?.className ?? "fs-10"}
            disabled={props.disabled}
            min={props.min}
            max={props.max}
          />
        </span>
        {props?.error && <div className="error fs-12">{props?.error}</div>}
      </div>
    </>
  );
};

export default DatePicker;
