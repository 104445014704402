import "./addInstructor.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_CLASS_INSTRUCTOR } from "../../gqloperations/mutations";
import { Formik, Form } from "formik";
import { gender, state, country } from "../../constants/commonConstant";
import {
  InternationalPhoneInput,
  Button,
  Input,
  Select,
  Header,
} from "../../components";
import { useHandleError } from '../../hooks/useHandleError';
import { LIST_CLASS_INSTRUCTORS } from "../../gqloperations/queries";
import { toast } from "react-toastify";
import { instructorSchema } from "../../validations/Validation";

const AddInstructor = () => {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/dashboard/instructors");
  };

  const onSubmit = async (values, OnSubmitProps) => {
    // mutation
    try {
      await addInstructor({
        variables: {
          input: {
            ...values,
            instructorDisplayName: values?.instructorDisplayName?.toUpperCase(),
            givenName: values?.givenName?.toUpperCase(),
            surname: values?.surname?.toUpperCase(),
            instructorEmail: values?.instructorEmail?.toUpperCase(),
            gender: values?.gender?.toUpperCase(),
            address: values?.address?.toUpperCase(),
            suburb: values?.suburb?.toUpperCase(),
            state: values?.state?.toUpperCase(),
            country: values?.country?.toUpperCase(),
            description: values?.description?.toUpperCase(),
          },
        },
        onCompleted: () => {
          OnSubmitProps.setSubmitting(false);
          toast.success("Instructor Added Successfully");
          navigate("/dashboard/instructors");
        },
        refetchQueries: [
          {
            query: LIST_CLASS_INSTRUCTORS,
          },
        ],
      });
    } catch (error) {
      handleError(`${error}`);
    }
    OnSubmitProps.setSubmitting(false);
    OnSubmitProps.resetForm();
  };

  const [addInstructor] = useMutation(CREATE_CLASS_INSTRUCTOR);
  const handleError = useHandleError();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        instructorDisplayName: "",
        givenName: "",
        surname: "",
        dob: "",
        gender: "",
        instructorEmail: "",
        instructorMobileNumber: "",
        address: "",
        suburb: "",
        state: "",
        country: "",
        postCode: "",
        description: "",
      }}
      validationSchema={instructorSchema}
      onSubmit={(values, OnSubmitProps) => {
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <div className="add-staff">
            <Header pageTitle="Add Instructor" />
            <div className="mt-32">
              <Form className="add-instructor-container add-staff-container">
                <div className="first-card pd-18">
                  <div className="title-bar">
                    <h3 className="fs-14 ">Personal Information</h3>
                  </div>
                  <div className="instructor-personal-grid">
                    <div className="detail name">
                      <Input
                        type="text"
                        label="Given Name * "
                        name="givenName"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail surname">
                      <Input
                        type="text"
                        label="Surname * "
                        name="surname"
                        id="surname"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail title">
                      <Input
                        type="text"
                        label="Display Name *"
                        name="instructorDisplayName"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail dob">
                      <Input
                        type="date"
                        label="Date Of Birth *"
                        name="dob"
                        autoComplete="off"
                        max={new Date().toISOString().split("T")[0]}
                      />
                    </div>
                    <div className="detail gender">
                      <Select
                        label="Gender *"
                        optionvalues={gender}
                        selecttype="array"
                        name="gender"
                        autoComplete="off"
                        className="fs-12"
                      />
                    </div>
                  </div>
                </div>

                <div className="first-card pd-18">
                  <div className="title-bar">
                    <h3 className="fs-14">Contact Details</h3>
                  </div>
                  <div className="instructor-contact-grid">
                    <div className="detail">
                      <Input
                        type="text"
                        label="Email address * "
                        name="instructorEmail"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail">
                      <InternationalPhoneInput
                        title="Mobile Number *"
                        name="instructorMobileNumber"
                        editable={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="first-card pd-18">
                  <div className="title-bar">
                    <h3 className="fs-14">Address Details</h3>
                  </div>
                  <div className="address-info-grid">
                    <div className="detail address pb-12">
                      <Input
                        type="text"
                        label="Address"
                        name="address"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail suburb">
                      <Input
                        type="text"
                        label="Suburb *"
                        name="suburb"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail state">
                      <Select
                        label="State *"
                        optionvalues={state}
                        selecttype="array"
                        name="state"
                        autoComplete="off"
                        className="fs-12"
                      />
                    </div>
                    <div className="detail country">
                      <Select
                        label="Country *"
                        optionvalues={country}
                        selecttype="array"
                        name="country"
                        id="add-instructor-country"
                        autoComplete="off"
                        className="fs-12 select-element-value"
                      />
                      {/* <Input
                        type="text"
                        label="Country *"
                        name="country"
                        autoComplete="off"
                      /> */}
                    </div>
                    <div className="detail post">
                      <Input
                        type="text"
                        label="Post Code *"
                        name="postCode"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="first-card pd-18">
                  <div className="title-bar">
                    <h3 className="fs-14">Description</h3>
                  </div>
                  <div className="details-container">
                    <div className="detail">
                      <textarea
                        className="reason-box fs-12"
                        id="description"
                        name="description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                      ></textarea>
                      {/* <div className="fs-12 error">
                        {formik.errors.description &&
                          formik.touched.description &&
                          formik.errors.description}
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="add-btn-container right-btn mb-32 mt-8">
                  {/* {formik.isSubmitting && (
                    <Button
                      name={<BeatLoader color="white" />}
                      btntype="submit"
                      btnname="submit"
                      disabled={true}
                      className="btn btn-confirm fs-12"
                    />
                  )}
                  {!formik.isSubmitting && (
                    <Button
                      name="SAVE"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-confirm fs-12"
                    />
                  )} */}
                  <Button
                    loading={formik.isSubmitting}
                    name={"Submit"}
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-confirm fs-12"
                    disabled={formik.isSubmitting ? true : false}
                  />
                  <Button
                    name="Cancel"
                    btntype="button"
                    className="btn btn-deny  fs-12"
                    click={handleCancel}
                  />
                </div>
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddInstructor;
