import CL_Logo from "../../assets/images/icons/brand_logos/CL_Logo.png";
import VL_Logo from "../../assets/images/icons/brand_logos/VL_Logo.png";
import PLUS_Logo from "../../assets/images/icons/brand_logos/PF_Logo.png";
import RB_Logo from "../../assets/images/icons/brand_logos/RB_Logo.png";
import HIIT_Logo from "../../assets/images/icons/brand_logos/HR_Logo.png";
import GU_Logo from "../../assets/images/icons/brand_logos/GU_Logo.png";
const SidebarBrandItem = ({ brand, setBrand, staffBrand }) => {
  return (
    <>
      {brand.brandName !== "Club Lime Aquatics" &&
        brand.brandName !== "Club Lime Ladies" &&
        brand.brandName !== "Psycle Life" &&
        brand.brandName !== "Club Lime Studio" &&
        brand.brandName !== "Golf" && (
          <li onClick={() => setBrand(brand.brandId, brand.brandName)}>
            <img
              className="cl-logo"
              src={`${
                brand.brandName === "Club Lime"
                  ? CL_Logo
                  : brand.brandName === "GroundUp"
                  ? GU_Logo
                  : brand.brandName === "Hiit Republic"
                  ? HIIT_Logo
                  : brand.brandName === "Rebalance"
                  ? RB_Logo
                  : brand.brandName === "Plus Fitness"
                  ? PLUS_Logo
                  : brand.brandName === "Viva Leisure"
                  ? VL_Logo
                  : ""
              }`}
              alt="logo"
              width="100%"
            />
          </li>
        )}
    </>
  );
};

export default SidebarBrandItem;
