import { useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useMemberContext } from "../../../contexts/MemberContext";
import { BeatLoader } from "react-spinners";
import useCustomFetch from "../../../hooks/useCustomFetch";
import { useCheckMemberProfile } from "../../../hooks/useCheckMemberProfile";
import { API } from "../../../utils/API";
import { format } from "date-fns";
import { Table, ExportGroup, GenerateBox } from "../../../components";
import { useHandleError } from '../../../hooks/useHandleError';

export default function UpgradeDowngradeReport() {
  const [generate, setGenerate] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [tableData, setTableData] = useState([]);
  const { sidebarLocationId, sidebarBrandId } = useMemberContext();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endPoint, setEndPoint] = useState();
  const [params, setParams] = useState();
  const handleError = useHandleError();
  const { data, isLoading, error } = useCustomFetch(
    endPoint && endPoint,
    params && params
  );
  const { checkMemberProfile } = useCheckMemberProfile();
  const paginationData = useRef();
  useEffect(() => {
    if (sidebarBrandId && sidebarLocationId) handleGenerate(100, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarBrandId, sidebarLocationId]);
  useEffect(() => {
    if (endDate && startDate && generate) {
      handleGenerate(100, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generate]);
  useEffect(() => {
    if (error && endPoint) {
      setTableData([]);
      handleError(error?.response?.data?.message ?? "Error when fetching api");
    } else if (data) {
      if (params.page !== 1) {
        setTableData([...tableData, ...data.data.data]);
      } else setTableData([...data.data.data]);
    }
    setGenerate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);
  useEffect(() => {
    setGenerate(false);
  }, [sidebarBrandId, sidebarLocationId]);

  const handleGenerate = (limit, page) => {
    paginationData.current = { limit: limit, page: page };
    let parameters = {
      brandId: sidebarBrandId,
      startDate: startDate,
      locationId: sidebarLocationId,
      endDate: endDate,
    };
    if (sidebarLocationId === 0) {
      delete parameters.locationId;
    }
    if (startDate && endDate) {
      const pagination = limit ? { limit: limit, page: page } : undefined;
      if (pagination) parameters = { ...parameters, ...pagination };

      setEndPoint(API.membershipUpgradeDowngradeReport);
      setParams(parameters);
    }
  };
  const handleNextToken = () => {
    handleGenerate(
      paginationData.current.limit,
      paginationData.current.page + 1
    );
  };
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  const columns = [
    {
      accessorFn: (row) => {
        return row?.memberName ?? "";
      },
      header: "MEMBER NAME",
      cell: (info) => info.getValue(),
      enableGlobalFilter: true,
      enableSorting: false,
      id: "memberName",
    },
    {
      accessorFn: (row) => {
        return row?.createdAt
          ? format(new Date(row?.createdAt), "dd/MM/yyyy HH:mm:ss")
          : "";
      },
      header: "Date",
      cell: (info) => info.getValue(),
      enableGlobalFilter: true,
      enableSorting: false,
      id: "createdAt",
    },
    {
      accessorFn: (row) =>
        `OLD: ${row?.oldMembership.membershipName} TO:${row?.newMembership.membershipName}`,
      header: "MEMBERSHIP",
      cell: (info) => info.getValue(),
      enableGlobalFilter: true,
      enableSorting: false,
      id: "Membership",
    },
    {
      accessorFn: (row) =>
        `FROM: ${row?.oldMembership.locationName} TO:${row?.newMembership.locationName}`,
      header: "LOCATION",
      cell: (info) => info.getValue(),
      enableGlobalFilter: true,
      enableSorting: false,
      id: "location",
    },
    {
      accessorFn: (row) =>
        `FROM: $${row?.oldMembership.membershipAmount?.toFixed(
          2
        )} TO: $${row?.newMembership.membershipAmount?.toFixed(2)}`,
      header: "AMOUNT",
      cell: (info) => info.getValue(),
      enableGlobalFilter: true,
      enableSorting: false,
      id: "cost",
    },
  ];
  return (
    <>
      <GenerateBox
        type="Payment"
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setGenerate={setGenerate}
      />
      {tableData.length && !isLoading ? (
        <div className="actions-row report-tables mt-24 mb-12">
          <ExportGroup
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
          />
          <div className="search-container">
            <form onSubmit={onSearchBarSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="payment-report-search"
                    id="payment-report-search"
                    value={searchBarInputVal}
                    onChange={handleChange}
                    placeholder="Search by given name or surname"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : null}
      {!isLoading && tableData.length > 0 && (
        <div className="table">
          <table>
            <thead>
              <tr className="table-header fs-14">
                <th>Total</th>
                <th>Upgrades</th>
                <th>Downgrades</th>
                <th>Old Total</th>
                <th>New Total</th>
                <th>Difference</th>
              </tr>
              <tr className="fs-12">
                <td>{data?.data?.total.records}</td>
                <td>{data?.data?.total.upgradeCount}</td>
                <td>{data?.data?.total.downgradeCount}</td>
                <td>$ {data?.data?.total.oldAmount.toFixed(2)}</td>
                <td>$ {data?.data?.total.newAmount.toFixed(2)}</td>
                <td>$ {data?.data?.total.amountDifference.toFixed(2)}</td>
              </tr>
            </thead>
          </table>
        </div>
      )}

      {isLoading ? (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      ) : null}
      {!isLoading ? (
        <div className="report-table-container-all">
          <Table
            onRowClick={(i) => {
              checkMemberProfile(i.memberName, i.memberId);
            }}
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            nextToken={tableData.length < data?.data?.total.records}
            handleNextToken={handleNextToken}
          />
        </div>
      ) : null}
    </>
  );
}
