import "./input.scss";
import { useRef } from "react";
import { formatDOB } from "../../../utils/formatDob";
import { useField } from "formik";
import { AiOutlineCalendar } from "react-icons/ai";

const HiddenDatePicker = (props) => {
  const { name, id, className, label, disabled } = props;

  const dobInputRef = useRef();
  const [field, meta, helpers] = useField(props);

  const handleOpenDatePicker = () => {
    if (!disabled) {
      dobInputRef.current.showPicker();
    }
  };
  return (
    <div className="form-group">
      <label htmlFor={name} className={`fs-12`}>
        {label}
      </label>
      <div className="date-container" onClick={handleOpenDatePicker}>
        <input
          id="dob-display"
          type="text"
          {...props}
          placeholder={props.placeholder}
          className={`${
            props?.className ? props?.className : ""
          } fs-12 noselect `}
          value={formatDOB(field.value)}
          readOnly
        />
        <AiOutlineCalendar
          className={meta.error && meta.touched ? "small-box" : "large-box"}
        />
      </div>

      <input
        id={id}
        ref={dobInputRef}
        name={name}
        type="date"
        {...field}
        value=""
        onChange={(e) => {
          helpers.setValue(e.target.value);
        }}
        style={{ visibility: "hidden", height: "0px" }}
        className={`${className} fs-12 noselect`}
        disabled={disabled}
      />
      <div className="error fs-12">
        {meta.error && meta.touched ? meta.error : null}
      </div>
    </div>
  );
};

export default HiddenDatePicker;
