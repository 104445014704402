import { parseISO, format } from "date-fns";
import { utcToZonedTime, format as formatTz } from "date-fns-tz";
// utcTimeString example "2024-06-26T07:58:23.958Z"
export const utcTimeToPCTime = (utcTimeString) => {
  const utcDate = parseISO(utcTimeString);
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = utcToZonedTime(utcDate, localTimeZone);
  const formattedDate = format(zonedDate, "dd/MM/yyyy HH:mm:ss");
  const timeZoneOffset = formatTz(zonedDate, "XXX");
  const finalOutput = `${formattedDate} (${timeZoneOffset}) (${localTimeZone})`;
  return finalOutput;
};
