import "./checkbox.scss";
import { IoMdCheckmark } from "react-icons/io";
const Checkbox = (props) => {
  return (
    <div
      className="checkbox fs-12"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <label>
        <input
          type="checkbox"
          id={props.label}
          name={props.name}
          value={props.value}
          checked={props.checked}
          onChange={props.onChange}
          disabled={props.disabled}
          readOnly
        />
        <span>
          <IoMdCheckmark />
        </span>
      </label>

      <label htmlFor={props.label} className={props.classname}>
        {" "}
        {props.labelText}{" "}
      </label>
    </div>
  );
};

export default Checkbox;
