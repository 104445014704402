import { useFormik } from "formik";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
export const AddMemberContext = createContext();
export function AddMemberContextProvider({ children }) {
  const [step, setStep] = useState(0);
  const [memberData, setMemberData] = useState({
    memberDetails: {
      address: "",
      brandId: "",
      country: "",
      dob: "",
      email: "",
      gender: "",
      givenName: "",
      homeLocationId: "",
      joinLocationId: "",
      mobileNumber: "",
      postCode: "",
      state: "",
      suburb: "",
      surname: "",
      emergencyContactName: "",
      emergencyContactRelation: "",
      emergencyContactEmail: "",
      emergencyContactNumber: "",
      healthQuestionnaire: "",
    },
    membershipStartDate: "",
    membershipId: "",
    voucherCode: "",
    membershipLocationId: "",
  });

  const [memberInformation, setMemberInformation] = useState({
    age: 0,
    membershipLevel: "",
    membershipDetails: {},
  });
  const [selectedMembershipInfo, setSelectedMembershipInfo] = useState();
  const [prevStep, setPrevStep] = useState(0);
  const handleMoveStepNext = () => {
    setStep(step + 1);
  };
  const setStepHandler = (v) => {
    setPrevStep(step);
    setStep(v);
  };
  return (
    <AddMemberContext.Provider
      value={{
        step,
        setStepHandler,
        prevStep,
        handleMoveStepNext,
        memberInformation,
        setMemberInformation,
        selectedMembershipInfo,
        setSelectedMembershipInfo,
        setMemberData,
        memberData,
      }}
    >
      {children}
    </AddMemberContext.Provider>
  );
}
export const useAddMemberContext = () => {
  return useContext(AddMemberContext);
};
