import React from "react";
import { Modal } from "../../../../../components";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./modalTermsAndCondition.css";

function PreExerciseModal({ listQuestion, onClose }) {
  return (
    <Modal>
      {listQuestion && (
        <div className="modalTermsAndCondition pre_exercise">
          <div
            className="closeButton"
            onClick={() => {
              console.log(typeof onClose);
              if (typeof onClose === "function") {
                onClose();
              }
            }}
          >
            <AiOutlineCloseCircle />
          </div>
          <h1 class="heading heading-underline text-white">
            PRE-EXERCISE CHECKLIST
          </h1>
          <ul>
            {listQuestion
              .filter((i) => i.answerType === "NUMBER")
              .map((i, index) => {
                return (
                  <li key={index} className="exercise-item">
                    {i.question}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </Modal>
  );
}

export default PreExerciseModal;
