import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { useMemberContext } from "../../contexts/MemberContext";
import { API } from "../../utils/API";
import useCustomFetch from "../../hooks/useCustomFetch";
import { Card, InsightsError } from "../../components";

const ClassStats = () => {
  const { sidebarLocationId, sidebarBrandId } = useMemberContext();
  const [classStatsEndPoint, setClassStatsEndPoint] = useState();
  const [classStatsParams, setClassStatsParams] = useState();
  const {
    data: classStatsData,
    isLoading: classStatsLoading,
    error: classStatsError,
  } = useCustomFetch(
    classStatsEndPoint && classStatsEndPoint,
    classStatsParams && classStatsParams
  );
  useEffect(() => {
    setClassStatsEndPoint(API.classStats);
  }, []);
  useEffect(() => {
    setClassStatsParams(
      !sidebarLocationId
        ? { brandId: sidebarBrandId }
        : { brandId: sidebarBrandId, locationId: sidebarLocationId }
    );
  }, [sidebarLocationId, sidebarBrandId]);
  return (
    <div className="class-stats-cards">
      <Card
        title="Classes Per Week"
        heading="Scheduled classes"
        value={
          classStatsError ? (
            <InsightsError />
          ) : classStatsLoading ? (
            <BeatLoader color="white" />
          ) : (
            classStatsData?.data.classesPerWeek
          )
        }
        icon="AiOutlineCalendar"
      />
      <Card
        title="AVG. Attendance"
        heading="Attendance per class"
        value={
          classStatsError ? (
            <InsightsError />
          ) : classStatsLoading ? (
            <BeatLoader color="white" />
          ) : (
            classStatsData?.data.averageAttendance
          )
        }
        icon="BiTrendingUp"
      />
      <Card
        title="Capacity"
        heading="Club's class capacity"
        value={
          classStatsError ? (
            <InsightsError />
          ) : classStatsLoading ? (
            <BeatLoader color="white" />
          ) : (
            classStatsData?.data.capacity
          )
        }
        icon="BiUser"
        // trend={true}
      />
    </div>
  );
};
export default ClassStats;
