import React, { useEffect, useState } from "react";
import InputWithoutFormik from "../formUiElements/input/InputWithoutFormik";
import { Formik, Form, Field } from "formik";
import Button from "../formUiElements/Button";
import Input from "../formUiElements/input/Input";
import { format } from "date-fns";
import { useMutation } from "@apollo/client";
import { UPDATE_PAYMENT_INFORMATION } from "../../gqloperations/mutations";
import { toast } from "react-toastify";
import { useHandleError } from '../../hooks/useHandleError';
import Modal from "./Modal";
import "./modal.scss";
import axios from "axios";
const SingupModalPayment = (props) => {
  const [paymentType, setPaymentType] = useState("cardOnFile");
  const [openVivaPay, setOpenVivaPay] = useState(false);
  const [enablePaymentBtn, setEnablePaymentBtn] = useState(false);
  const handleError = useHandleError();
  const [paymentInfoBank, setPaymentInfoBank] = useState({
    accountName: "",
    accountNumber: "",
    bsb: "",
  });
  const [validBankAccount, setValidBankAccount] = useState(true);
  const [
    updatePaymentInformation,
    {
      data: updatePaymentInformationData,
      loading: updatePaymentInformationLoading,
    },
  ] = useMutation(UPDATE_PAYMENT_INFORMATION);
  const [futurePaymentType, setFuturePaymentType] = useState("");
  const iframeSrc = `${process.env.REACT_APP_VIVAPAY_IFRAME}/${
    futurePaymentType === "creditCard"
      ? updatePaymentInformationData?.updatePaymentInformation?.cardRequestId
      : props.cardRequestId
  }`;
  const apiEndpoint = `${process.env.REACT_APP_VIVAPAY_ENDPOINT}/${props.cardRequestId}`;

  const makeApiRequest = async () => {
    try {
      const response = await axios.get(apiEndpoint);
      return response; // Return the response when received
    } catch (error) {
      return null; // Return null on error
    }
  };

  useEffect(() => {
    const isCreditCardIncomplete =
      futurePaymentType === "creditCard" &&
      updatePaymentInformationData?.updatePaymentInformation?.body?.cardNumber;
    const isBankAccountInComplete =
      futurePaymentType === "bankAccount" &&
      paymentInfoBank.accountName &&
      paymentInfoBank.accountNumber &&
      paymentInfoBank.bsb &&
      validBankAccount;
    const isOneTimeDirectPayment =
      !props.showFuturePayment &&
      (paymentType === "CASH" || paymentType === "EFTPOS");

    const isEnable =
      isCreditCardIncomplete ||
      isBankAccountInComplete ||
      isOneTimeDirectPayment;
    setEnablePaymentBtn(isEnable);
  }, [
    paymentType,
    futurePaymentType,
    updatePaymentInformationData,
    paymentInfoBank,
    validBankAccount,
  ]);

  const handleSubmit = async (values) => {
    try {
      let payload = {
        paymentType: paymentType === "creditCard" ? "CREDIT_CARD" : paymentType,
        membershipStartDate: format(
          new Date(props.memberData.membershipStartDate),
          "yyyy-MM-dd"
        ),
        prospectId: props?.signupData?.signupData.prospectId,
      };
      if (paymentType === "EFTPOS") {
        payload = {
          ...payload,
          invoice: values.transactionNumber,
        };
      }
      if (futurePaymentType === "bankAccount") {
        await updatePaymentInformation({
          variables: {
            action: "ADD_BANK",
            memberId: props.signupData?.signupData.memberId,
            requestApplication: "HUB",
            bankAccount: paymentInfoBank,
            isDeleteOldPayment: true,
          },
          onCompleted: (data) => {
            payload.futurePaymentInformationId =
              data?.updatePaymentInformation?.body?.paymentInformationId;
            props.setShowModalPayment(false);
            props.onCompleted(payload);
          },
        });
      } else if (futurePaymentType === "creditCard") {
        props.setShowModalPayment(false);
        payload.futurePaymentInformationId =
          updatePaymentInformationData?.updatePaymentInformation?.body?.paymentInformationId;
        props.onCompleted(payload);
      } else {
        props.setShowModalPayment(false);
        props.onCompleted(payload);
      }
    } catch (e) {
      handleError(e.message);
    } finally {
    }
  };
  const openModalVivaPay = async (futurePayment) => {
    if (futurePayment !== "creditCard") {
      setOpenVivaPay(true);
    } else {
      await updatePaymentInformation({
        fetchPolicy: "no-cache",
        variables: {
          action: "CREATE_CARD_TOKEN",
          memberId: props.signupData?.signupData.memberId,
          requestApplication: "HUB",
        },
        onCompleted: (data) => {
          setOpenVivaPay(true);
        },
        onError: (error) => {
          handleError(`${error}`);
        },
      });
    }
  };
  const closeModalVivaPay = async () => {
    const payload = {
      prospectId: props?.signupData?.signupData?.prospectId,
      membershipStartDate: format(
        new Date(props.memberData.membershipStartDate),
        "yyyy-MM-dd"
      ),
    };
    setOpenVivaPay(false);
    if (futurePaymentType !== "creditCard") {
      makeApiRequest().then((res) => {
        if (res?.data?.success) {
          props?.onCompleted(payload);
          props.setShowModalPayment(false);
        } else {
          setPaymentType("");
        }
      });
    } else {
      await updatePaymentInformation({
        fetchPolicy: "no-cache",
        variables: {
          action: "ADD_CARD",
          memberId: props.signupData?.signupData.memberId,
          creditCard: {
            cardRequestId:
              updatePaymentInformationData?.updatePaymentInformation
                ?.cardRequestId,
          },
          isDeleteOldPayment: true,

          requestApplication: "HUB",
        },
        onCompleted: (data) => {
          if (!data?.updatePaymentInformation?.body?.cardNumber) {
            setFuturePaymentType("");
          }
        },
        onError: (error) => {
          handleError(`${error}`);
        },
      });
    }
  };
  const setDataBank = (e) => {
    setPaymentInfoBank({ ...paymentInfoBank, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Modal>
        <Formik
          enableReinitialize
          initialValues={{
            accountName: "",
            accountNumber: "",
            bsb: "",
            transactionNumber: "",
          }}
          validateOnChange
          validate={(values, props) => {
            let errors = {};

            if (futurePaymentType === "bankAccount") {
              if (!values?.accountName.trim()) {
                errors.accountName = "Please enter account name";
              }
              if (!values?.accountNumber.trim()) {
                errors.accountNumber = "Please enter account number";
              } else if (!/^\d+$/.test(values?.accountNumber.trim())) {
                errors.accountNumber =
                  "Account number should contain only digits";
              }
              if (!values?.bsb.trim()) {
                errors.bsb = "Please enter BSB";
              } else if (!/^\d{6}$/.test(values?.bsb.trim())) {
                errors.bsb = "BSB should be 6 digits";
              }
            }
            if (
              paymentType === "EFTPOS" &&
              values.transactionNumber &&
              !/^\d+$/.test(values?.transactionNumber.trim())
            ) {
              errors.transactionNumber =
                "Transaction number should contain only digits";
            }
            if (Object.keys(errors).length > 0) {
              setValidBankAccount(false);
            } else {
              setValidBankAccount(true);
            }
            return errors;
          }}
          onSubmit={(values, OnSubmitProps) => {
            handleSubmit(values);
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className="status-modal make-a-payment-modal signup-modal pd-32">
                  <div className="make-payment-title ">
                    <h3 className="fs-35 pb-24 uppercase">Make a Payment</h3>
                  </div>{" "}
                  <div>
                    <div className="member-details-modal-container">
                      <h3>Member Details</h3>
                      <div className="member-details-inputs pb-24">
                        <InputWithoutFormik
                          type="text"
                          label="member Name:"
                          name="memberName"
                          autoComplete="off"
                          id="memberName"
                          className="fs-12"
                          value={
                            props?.memberData?.memberDetails?.givenName +
                            " " +
                            props?.memberData?.memberDetails?.surname
                          }
                          readOnly
                        />
                        <InputWithoutFormik
                          type="text"
                          label="Amount:"
                          name="amount"
                          autoComplete="off"
                          id="amount"
                          className="fs-12"
                          value={props.amount || ""}
                          readOnly
                        />
                      </div>
                    </div>
                    <h3 className="pb-8">
                      Credit Card surcharge will be added to single payments
                      only.
                      <br />
                      <p className="pt-8">
                        There is no fee for ongoing fortnightly memberships.
                      </p>
                    </h3>
                    <h3 className="pb-8">
                      (VISA/MASTSERCARD: 1.5%, AMEX: 3.5%)
                    </h3>
                  </div>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    className="pb-18 fs-12 payment-options-singup"
                  >
                    <label>
                      <Field
                        type="radio"
                        name="paymentType"
                        checked={paymentType === "creditCard" || false}
                        onClick={(e) => {
                          setPaymentType("creditCard");
                          openModalVivaPay("");
                        }}
                      />
                      PAY WITH CREDIT CARD
                    </label>

                    <div className="direct-options">
                      <label>
                        <Field
                          type="radio"
                          name="paymentType"
                          checked={paymentType === "EFTPOS" || false}
                          onClick={(e) => {
                            setPaymentType("EFTPOS");
                          }}
                        />
                        EFTPOS
                      </label>{" "}
                      <label>
                        <Field
                          type="radio"
                          name="paymentType"
                          checked={paymentType === "CASH" || false}
                          onClick={(e) => {
                            setPaymentType("CASH");
                          }}
                        />
                        CASH
                      </label>
                    </div>
                  </div>
                  {paymentType === "EFTPOS" && (
                    <div className="payment-details-modal-container">
                      <Input
                        type="text"
                        label="Transaction number:"
                        name="transactionNumber"
                        id="transactionNumber"
                        autoComplete="off"
                        className="fs-12"
                      />
                    </div>
                  )}
                  {(paymentType === "CASH" || paymentType === "EFTPOS") &&
                    props.showFuturePayment && (
                      <div className="future-payment-container">
                        <h3>FUTURE PAYMENT OPTIONS</h3>
                        <p>
                          Select payment method to procceeded future payment
                        </p>
                        <div className="payment-types-container">
                          <div
                            className="payment-type"
                            onClick={() => {
                              setFuturePaymentType("creditCard");
                              if (
                                !updatePaymentInformationData
                                  ?.updatePaymentInformation?.body?.cardNumber
                              ) {
                                openModalVivaPay("creditCard");
                              }
                            }}
                          >
                            <label>
                              <Field
                                type="radio"
                                name="futurePaymentType"
                                value="creditCard"
                              />
                              Use Credit Card
                            </label>
                          </div>
                          <div
                            className="payment-type"
                            onClick={() => setFuturePaymentType("bankAccount")}
                          >
                            <label>
                              <Field
                                type="radio"
                                name="futurePaymentType"
                                value="bankAccount"
                              />
                              Use a bank account
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  {paymentType !== "creditCard" &&
                    futurePaymentType === "creditCard" &&
                    updatePaymentInformationData?.updatePaymentInformation.body
                      ?.cardNumber && (
                      <div className="payment-details-modal-container">
                        <Input
                          disabled={true}
                          type="text"
                          label="Type: "
                          name="cardType"
                          id="cardType"
                          autoComplete="off"
                          value={
                            updatePaymentInformationData
                              ?.updatePaymentInformation?.body?.cardType
                          }
                          className="fs-12"
                        />
                        <Input
                          disabled={true}
                          type="text"
                          label="Card"
                          name="cardNumber"
                          id="cardNumber"
                          autoComplete="off"
                          value={
                            updatePaymentInformationData
                              ?.updatePaymentInformation?.body?.cardNumber
                          }
                          className="fs-12"
                        />
                        <Input
                          disabled={true}
                          type="text"
                          label="Expiry"
                          name="expiry"
                          id="expiry"
                          autoComplete="off"
                          value={
                            updatePaymentInformationData
                              ?.updatePaymentInformation?.body?.cardExpiryDate
                          }
                          className="fs-12"
                        />
                      </div>
                    )}
                  {(paymentType === "CASH" || paymentType === "EFTPOS") &&
                    futurePaymentType === "bankAccount" && (
                      <div className="payment-details-modal-container bank-account">
                        <Input
                          type="text"
                          label="Account name: "
                          name="accountName"
                          id="accountName"
                          autoComplete="off"
                          className="fs-12"
                          value={formik.values.accountName || ""}
                          onChange={(e) => {
                            formik.handleChange(e);
                            setDataBank(e);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <Input
                          type="text"
                          label="Account number"
                          autoComplete="off"
                          name="accountNumber"
                          id="accountNumber"
                          className="fs-12"
                          value={formik.values.accountNumber || ""}
                          onChange={(e) => {
                            formik.handleChange(e);
                            setDataBank(e);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <Input
                          type="text"
                          label="BSB"
                          name="bsb"
                          id="bsb"
                          autoComplete="off"
                          className="fs-12"
                          value={formik.values.bsb || ""}
                          onChange={(e) => {
                            formik.handleChange(e);
                            setDataBank(e);
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    )}
                  {/* {memberContractId: {eq: "memberContractId"}} */}
                  <div className="make-payment-btn-container ">
                    {paymentType !== "creditCard" && (
                      <Button
                        loading={updatePaymentInformationLoading}
                        name={
                          props.signupData?.age > 17
                            ? "Make payment"
                            : "Confirm"
                        }
                        btntype="submit"
                        btnname="submit"
                        className={`btn btn-primary btn-lg fs-12  ${
                          enablePaymentBtn ? "" : "btn-disabled"
                        }`}
                        disabled={!enablePaymentBtn}
                      />
                    )}
                    <Button
                      name="CANCEL"
                      btntype="button"
                      btnname="cancel"
                      click={() => props.setShowModalPayment(false)}
                      className="btn btn-deny btn-lg fs-12"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      {(paymentType === "creditCard" || futurePaymentType === "creditCard") &&
        openVivaPay && (
          <Modal>
            <div className="status-modal make-a-payment-modal pd-32 viva-pay">
              <iframe
                id="pay-with-vivapay"
                title="pay with vivapay"
                src={iframeSrc}
                className="iframe-vivapay"
                loading="lazy"
              />
              <Button
                name="Continue"
                btntype="button"
                btnname="cancel"
                className="btn btn-confirm btn-small"
                click={() => closeModalVivaPay()}
              />
            </div>
          </Modal>
        )}
    </div>
  );
};
export default SingupModalPayment;
