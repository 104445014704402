import React, { useEffect, useState } from "react";
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from "recharts";
const initialData = [
  { time: "00:00", visits: 0 },
  { time: "01:00", visits: 0 },
  { time: "02:00", visits: 0 },
  { time: "03:00", visits: 0 },
  { time: "04:00", visits: 0 },
  { time: "05:00", visits: 0 },
  { time: "06:00", visits: 0 },
  { time: "07:00", visits: 0 },
  { time: "08:00", visits: 0 },
  { time: "09:00", visits: 0 },
  { time: "10:00", visits: 0 },
  { time: "11:00", visits: 0 },
  { time: "12:00", visits: 0 },
  { time: "13:00", visits: 0 },
  { time: "14:00", visits: 0 },
  { time: "15:00", visits: 0 },
  { time: "16:00", visits: 0 },
  { time: "17:00", visits: 0 },
  { time: "18:00", visits: 0 },
  { time: "19:00", visits: 0 },
  { time: "20:00", visits: 0 },
  { time: "21:00", visits: 0 },
  { time: "22:00", visits: 0 },
  { time: "23:00", visits: 0 },
];
const ArChart = ({ clubVisitsData }) => {
  const [data, setData] = useState(initialData);

  useEffect(() => {
    const updatedData = initialData.map((item) => {
      const match = clubVisitsData?.find(
        (visit) => visit.time.slice(11, 16) === item.time
      );
      return match ? { ...item, visits: match.total } : item;
    });

    setData(updatedData);
  }, [clubVisitsData]);

  return (
    <ResponsiveContainer width="100%" height="50%">
      <AreaChart
        data={data}
        margin={{
          top: 10,
          left: 10,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="colorAreaChart" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#444444" />
            <stop offset="95%" stopColor="#272727" />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="time"
          interval={3}
          scale="auto"
          fontSize={10}
          axisLine={true}
          tickLine={true}
          tickFormatter={(str) => {
            const hour = str.slice(0, 2);
            if (hour % 24 === 0) {
              return "";
            }
            if (hour % 16 === 0) {
              return "04 pm";
            }
            if (hour % 20 === 0) {
              return "08 pm";
            }
            if (hour >= 12) {
              return `${hour} pm`;
            }
            return `${hour} am`;
          }}
        />

        <Tooltip
          content={<CustomTooltip />}
          wrapperStyle={{ width: 50, fontSize: "10px" }}
        />
        <Area
          type="monotone"
          dataKey="visits"
          stroke="var(--color-persianblue)"
          strokeWidth={2}
          fill="url(#colorAreaChart)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

function CustomTooltip({ payload, label, active }) {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p className="intro">{`TIME: ${label}`} </p>
        <p className="label">{`VISITS: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
}

export default ArChart;
