import React from "react";
import { useState } from "react";
import { format } from "date-fns";
import { AiOutlineSearch } from "react-icons/ai";
import Table from "../../../components/table/Table";
import "./Voucher.scss";
import { BsCardText } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useMemberContext } from "../../../contexts/MemberContext";
import { SET_MEMBER_ID } from "../../../actions";
const ViewVoucherMembers = (props) => {
  let navigate = useNavigate();
  const [globalFilterMembers, setGlobalFilterMembers] = useState("");
  const [searchMemberBarInputVal, setSearchMemberBarInputVal] = useState("");
  const [columnMembersFilters, setColumnMembersFilters] = useState([]);
  const { dispatch } = useMemberContext();
  const onSearchMemberBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilterMembers(String(searchMemberBarInputVal));
  };

  const handleSearchMember = (event) => {
    const searchVal = event.target.value;
    setSearchMemberBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilterMembers(searchVal);
    }
  };

  const columnsMembers = [
    {
      accessorFn: (row) =>
        `${row?.memberDetail?.givenName} ${row?.memberDetail?.surname}`,
      header: "Name",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "name",
    },
    {
      accessorFn: (row) => row?.memberDetail?.createdAt,
      header: "Date",
      cell: (info) => {
        const date = info?.getValue()
          ? format(new Date(info?.getValue()), "dd/MM/yyyy")
          : "";
        return date;
      },
      enableSorting: true,
      enableGlobalFilter: true,
      id: "createdAt",
    },
    {
      accessorFn: (row) => {
        return (
          <>
            {row?.isRedeemed ? (
              <p className="text-green">Yes</p>
            ) : (
              <p className="red">No</p>
            )}
          </>
        );
      },
      header: "Redeemed",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "isRedeemed",
    },
    {
      id: "row-actions",
      cell: (props) => (
        <div className="row-action-container">
          <BsCardText
            onClick={() => {
              const memberId = props.row.original?.memberDetail?.memberId;
              dispatch({
                type: SET_MEMBER_ID,
                payload: {
                  memberId: memberId,
                },
              });
              navigate(`/dashboard/member/${memberId}`);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="search-and-button-row">
        <div className="search-container ">
          <form onSubmit={onSearchMemberBarSubmit}>
            <div className="search-bar">
              <div className="search-bar-container">
                <input
                  className="fs-10"
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Name, surname"
                  value={searchMemberBarInputVal}
                  onChange={handleSearchMember}
                />
                <button type="submit">
                  <AiOutlineSearch />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Table
        columns={columnsMembers}
        globalFilter={globalFilterMembers}
        setGlobalFilter={setGlobalFilterMembers}
        data={props.data}
        rowsPerPage={10}
        columnFilters={columnMembersFilters}
      />
    </div>
  );
};

export default ViewVoucherMembers;
