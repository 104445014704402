export const sortAsc = (items, column) => {
  const sortedData = [...items].sort((a, b) => {
    return new Date(a[column]) - new Date(b[column]);
  });
  return sortedData;
};

export const sortDesc = (items, column) => {
  const sortedData = [...items].sort((a, b) => {
    return new Date(b[column]) - new Date(a[column]);
  });
  return sortedData;
};

//How to use the function
//sortDesc(items, "createdAt")
