import { gql } from "@apollo/client";

export const MEMBER_ACCESS_LOGS_SUBSCRIPTION = gql`
  subscription MySubscription(
    $filter: ModelSubscriptionDoorAccessLogFilterInput
  ) {
    onCreateDoorAccessLog(filter: $filter) {
      id
      locationId
      memberId
      memberName
      swipePhoto
      memberPhoto
      createdAt
      isValid
      memberHomeClub
      deviceType
      cardNumber
      homeLocationId
      doorName
      memberShipName
      processLocationTime
      isPostData
      isOnline
    }
  }
`;
export const CREATE_NOTIFICATION_SUBSCRIPTION = gql`
  subscription onCreateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onCreateNotification(filter: $filter) {
      id
      eventType
      email
      suspensionStart
      suspensionType
      displayUntil
      outstandingBalance
      cancelDate
      memberId
      contractId
      createdAt
      locationId
      brandId
      actionByMemberId
      memberDetails {
        memberId
        givenName
        surname
        outstandingBalance
      }
      actionByMemberDetails {
        givenName
        surname
      }
      locationDetails {
        name
      }
      brandDetails {
        name
      }
    }
  }
`;
export const ON_APPROVE_TERMS_CONDITIONS = gql`
  subscription onApproveTermsConditions($prospectId: ID!) {
    onApproveTermsConditions(prospectId: $prospectId) {
      prospectId
      message
    }
  }
`;
