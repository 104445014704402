import "../../../../pages/member/membership/upgradeDowngrade.scss";
import { useState, useEffect } from "react";
import { useMemberContext } from "../../../../contexts/MemberContext";
import { useAuthContext } from "../../../../contexts/AuthContext/AuthProvider";
import {
  getPaymentFrequencyString,
  getContractLengthString,
} from "../../../../utils/getDisplayString";
import { useLazyQuery } from "@apollo/client";
import { toast } from "aws-amplify";
import { GET_ALL_LOCATIONS_BY_LOCATIONID } from "../../../../gqloperations/queries";
import { BeatLoader } from "react-spinners";
import differenceInYears from "date-fns/differenceInYears";
import { useHandleError } from "../../../../hooks/useHandleError";
import {
  Table,
  RadioButton,
  SelectWithoutFormik,
} from "../../../../components";
import { endOfDay, format, isAfter, isBefore } from "date-fns";

const LocationTabContent = ({
  handleClick,
  handleBack,
  selectedLocation,
  setSelectedLocation,
  currentMembership,
}) => {
  const { locationAccess } = useAuthContext();
  const [selectedState, setSelectedState] = useState();
  const [locationInputValue, setLocationInputValue] = useState("");
  const { member } = useMemberContext();
  const [brandLocations, setBrandLocations] = useState([]);
  const [locationsList, setLocationList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const handleError = useHandleError();
  const [sorting, setSorting] = useState([]);
  useEffect(() => {
    const sameBrandLocations = locationAccess.brands
      ?.find(
        (brand) => brand.brandId === currentMembership?.[0]?.brandDetails.id
      )
      ?.locations.filter((location) => !location?.openDate);
    setBrandLocations(sameBrandLocations);
    setLocationList(sameBrandLocations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMembership]);

  const convertUTCtoAusTimezone = (utcDate, timeZone) => {
    return format(new Date(utcDate), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", {
      timeZone,
    });
  };

  const [getMemberships, { loading: getMembershipLoading }] = useLazyQuery(
    GET_ALL_LOCATIONS_BY_LOCATIONID,
    {
      fetchPolicy: "no-cache",
      variables: {
        locationId: selectedLocation.locationId,
        limit: 1000,
      },
      onCompleted: (data) => {
        if (data.getMembershipByLocationId) {
          const age = differenceInYears(
            new Date(),
            new Date(member?.getMember?.dob) ?? new Date()
          );
          let membershipFiltered = [];
          const tableItems = data?.getMembershipByLocationId.map((cur) => {
            const { items, nextToken: nextTokenFromResult } =
              cur.membershipLocations;

            return { items, nextTokenFromResult };
          });
          const flattendItems = tableItems.flatMap((cur) => {
            const { items } = cur;
            return items;
          });
          const validDateMembership = flattendItems?.filter((item) => {
            const activeMembership =
              item.membershipDetails?.activationDates.items?.some((i) => {
                const timezone =
                  member.getMember?.homeLocationDetails?.timezone ??
                  "Australia/Sydney";
                const openingMembership = !isAfter(
                  new Date(i.startDateTime),
                  new Date()
                );
                const expiringMembership =
                  (i.endDateTime &&
                    !isBefore(
                      endOfDay(
                        new Date(
                          convertUTCtoAusTimezone(i.endDateTime, timezone)
                        )
                      ),
                      new Date()
                    )) ||
                  !i.endDateTime;
                return openingMembership && expiringMembership;
              });
            // return activeMembership;
            const isPaymentDelay =
              item.membershipDetails?.paymentDelay?.amount !== 0 &&
              item.membershipDetails?.paymentDelay !== null;
            if (isPaymentDelay) {
              return false;
            }
            if (!item.membershipDetails.isActive) return false;
            if (age >= 16) {
              //more than 16 is adult membership showed
              return (
                item.membershipDetails.membershipLevel === "ADULT" &&
                activeMembership
              );
            } else if (age > 13 && age < 16) {
              //less than 16 and more than 13 is youth membership showed
              return (
                item.membershipDetails.membershipLevel === "YOUTH" &&
                activeMembership
              );
            } else if (age <= 13) {
              //less than 13 is junior membership showed
              return (
                item.membershipDetails.membershipLevel === "JUNIOR" &&
                activeMembership
              );
            }
            return false;
          });

          setTableData(validDateMembership);
        } else setTableData();
        // setTableData(data.getMembershipByLocationId);
      },
      onError: (error) => {
        handleError("Couldn't get memberships within the selected location");
      },
    }
  );

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };
  const handleLocationInputChange = (e) => {
    setLocationInputValue(e.target.value);
  };

  useEffect(() => {
    if (selectedState) {
      const stateLocations = locationAccess.state.find(
        (i) => i.state === selectedState
      ).locations;
      const brandStateMatchedLocations = [];
      brandLocations.forEach((item1) => {
        const matchingObject = stateLocations.find(
          (item2) => item2.locationId === item1.locationId
        );
        if (matchingObject) {
          brandStateMatchedLocations.push({ ...item1, ...matchingObject });
        }
      });
      setLocationList(brandStateMatchedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);
  useEffect(() => {
    const getSelectedLocation = locationsList?.find(
      (item) =>
        item.locationName.toLowerCase() === locationInputValue?.toLowerCase()
    );
    if (getSelectedLocation) {
      setSelectedLocation({
        ...selectedLocation,
        // brandDetails: getSelectedLocation.brandDetails,
        locationId: getSelectedLocation.locationId,
        locationName: getSelectedLocation.locationName,
      });
      setLocationInputValue(getSelectedLocation.locationName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationInputValue]);
  useEffect(() => {
    if (selectedLocation.locationId) {
      getMemberships();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  const stateList = locationAccess.state.map((i) => i.state);
  const columns = [
    {
      id: "radio-select",
      header: "SELECT",
      cell: ({ row, table }) => {
        return (
          <div className="px-1">
            <RadioButton
              name="change-membership"
              disabled={currentMembership.some(
                (item) =>
                  item.membershipId === row.original.membershipDetails.id
              )}
              checked={row?.getIsSelected()}
              onChange={() => {
                const selected = row?.isSelected; // get selected status of current row.
                table?.toggleAllRowsSelected(false); // deselect all.
                row?.toggleSelected(!selected); // reverse selected status of current row.
              }}
            />
          </div>
        );
      },
    },
    {
      accessorFn: (row) => `${row?.membershipDetails.membershipName}`,
      header: "MEMBERSHIP NAME",
      cell: (info) => {
        return (
          <div
            className={
              currentMembership.some(
                (item) =>
                  item.membershipId === info?.row.original.membershipDetails.id
              )
                ? "disabled-text"
                : ""
            }
          >
            {info.getValue()}
          </div>
        );
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "membershipName",
    },
    {
      accessorFn: (row) => `${row?.membershipDetails?.contractLength}`,
      header: "CONTRACT LENGTH",
      cell: (info) => {
        return (
          <div
            className={
              currentMembership.some(
                (item) =>
                  item.membershipId === info?.row.original.membershipDetails.id
              )
                ? "disabled-text"
                : ""
            }
          >
            {info.row.original?.membershipDetails?.contractLength
              ? getContractLengthString(
                  info.row.original?.membershipDetails?.contractLength
                )
              : ""}
          </div>
        );
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "contractLength",
    },
    {
      accessorFn: (row) => {
        return !row?.membershipDetails.recurring
          ? "PIF"
          : getPaymentFrequencyString(row.membershipDetails.paymentFrequency);
      },
      header: "PAYMENT FREQUENCY",
      cell: (info) => {
        return (
          <div
            className={
              currentMembership.some(
                (item) =>
                  item.membershipId === info?.row.original.membershipDetails.id
              )
                ? "disabled-text"
                : ""
            }
          >
            {info.getValue()}
          </div>
        );
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "billingFrequency",
    },
    {
      accessorFn: (row) => {
        return row?.costPrice;
      },
      header: "COST",
      cell: (info) => {
        return (
          <div
            className={
              currentMembership.some(
                (item) =>
                  item.membershipId === info?.row.original.membershipDetails.id
              )
                ? "disabled-text"
                : ""
            }
          >
            {`$${info.getValue()}`}
          </div>
        );
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "cost",
    },
  ];
  return (
    <>
      <div className=" location-UD-content mt-32 ">
        <div className="info-card">
          <div className="first-row-settings text-gray flex-row fs-12">
            <div className="option-container">
              <SelectWithoutFormik
                label="State"
                edit={true}
                initialValue=""
                optionvalue={stateList}
                selecttype="array"
                onChange={handleStateChange}
                name="stateSelect"
                disabled={false}
                className="select-element-value"
              />
            </div>
            <div className="option-container location-details flex-col">
              <label>Location</label>
              <input
                type="text"
                list="locations-member-access-logs"
                onChange={handleLocationInputChange}
                value={locationInputValue}
                autoComplete="off"
                className="fs-12 select-element-value"
              />
              {locationsList && (
                <datalist id="locations-member-access-logs">
                  {locationsList.map((option) => {
                    return (
                      <option
                        key={option.locationId}
                        value={option.locationName}
                      />
                    );
                  })}
                </datalist>
              )}
            </div>
          </div>
        </div>
        {getMembershipLoading && (
          <div className="loader">
            <BeatLoader color="white" size={15} />
          </div>
        )}
        {selectedLocation.locationId && !getMembershipLoading && (
          <Table
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            columnFilter={isFiltered}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setColumnFilter={setIsFiltered}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            sorting={sorting}
            setSorting={setSorting}
            btnName="Next"
            showTwoBtns={true}
            handleClick={() => {
              handleClick(tableData[Object.keys(rowSelection)[0]]);
            }}
            handleCancel={handleBack}
            isBtnDisabled={Object.keys(rowSelection).length === 0}
          />
        )}
      </div>
    </>
  );
};
export default LocationTabContent;
