import { useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { role as staffRoles, staffTypes } from "../../constants/commonConstant";
import { getRoleOptionsByRole } from "../../utils/getRoleOptionsByRole";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import {
  UPDATE_MEMBER,
  UPDATE_STAFF_ROLE,
} from "../../gqloperations/mutations";
import { userEmploymentSchema } from "../../validations/profileValidation";
import { AiOutlineEdit } from "react-icons/ai";
import { Formik, Form } from "formik";
import { format } from "date-fns";
import { InputWithoutFormik, Input, Button, Select } from "../../components";
import { getUTCdate } from "../../utils/getUTCdate";
import { useHandleError } from '../../hooks/useHandleError';

const UserEmploymentInfo = ({ staffData, refetch, showEditBTN }) => {
  const { locationAccess, loggedUser } = useAuthContext();
  const [editable, setEditable] = useState(false);
  const [locationItems, setLocationItems] = useState([]);
  const handleError = useHandleError();
  const [selectedBrandId, seSelectedBrandId] = useState(
    staffData?.getMember?.brandDetails?.id
  );
  const [datalistInputValue, setDatalistInputValue] = useState(
    staffData?.getMember?.homeLocationDetails?.name
  );
  const [location, setLocation] = useState({
    locationId: staffData?.getMember?.homeLocationDetails?.id,
    locationName: staffData?.getMember?.homeLocationDetails?.name,
  });
  useEffect(() => {
    const selectedBrand = locationAccess.brands.find(
      (brand) => brand.brandId === selectedBrandId
    );
    setLocationItems(selectedBrand?.locations);
  }, [selectedBrandId, locationAccess.brands]);
  const [updateMember] = useMutation(UPDATE_MEMBER);
  const [updateStaffRole] = useMutation(UPDATE_STAFF_ROLE);
  useEffect(() => {
    if (selectedBrandId !== staffData.getMember.brandDetails.id) {
      setLocation({ ...location, locationId: "", locationName: "" });
      setDatalistInputValue("");
    } else {
      setLocation({
        ...location,
        locationId: staffData.getMember.homeLocationDetails?.id,
        locationName: staffData.getMember.homeLocationDetails?.name,
      });
      setDatalistInputValue(staffData.getMember.homeLocationDetails?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrandId]);

  useEffect(() => {
    const selectedLocation = locationItems.find(
      (item) =>
        item?.locationName?.toLowerCase() === datalistInputValue?.toLowerCase()
    );
    if (selectedLocation) {
      setLocation({
        ...location,
        locationId: selectedLocation.locationId,
        locationName: selectedLocation.locationName,
      });
      setDatalistInputValue(selectedLocation.locationName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datalistInputValue]);
  const {
    role,
    staffKind,
    joinedDateTime,
    aliasMemberId,
    memberId,
    brandDetails: { name: brandName },
  } = staffData.getMember;
  const handleChange = (e) => {
    setDatalistInputValue(e.target.value);
  };
  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("userEmploymentInfo").reset();
    setEditable(false);
    seSelectedBrandId(staffData.getMember.brandDetails.id);
    setDatalistInputValue(staffData.getMember.homeLocationDetails.name);
  };

  const onSubmit = async (values) => {
    if (!location.locationId) return;
    let updatedFields = {};
    if (selectedBrandId !== staffData.getMember.brandDetails.id) {
      updatedFields.brandId = selectedBrandId;
    }
    if (location.locationId !== staffData.getMember.homeLocationDetails.id) {
      updatedFields.homeLocationId = location.locationId;
    }
    for (let key in values) {
      if (
        String(values[key]).toUpperCase() !==
        String(staffData.getMember[key]).toUpperCase()
      ) {
        updatedFields[key] = values[key];
      }
    }
    if (
      updatedFields.joinedDateTime ===
      format(new Date(joinedDateTime), "yyyy-MM-dd")
    ) {
      delete updatedFields.joinedDateTime;
    } else {
      updatedFields.joinedDateTime = getUTCdate(updatedFields.joinedDateTime);
    }
    if (
      !updatedFields.role ||
      (updatedFields.role && Object.keys(updatedFields).length > 1)
    ) {
      try {
        await updateMember({
          variables: {
            input: { ...updatedFields, memberId },
          },
          onCompleted: () => {
            toast.success("Update successfully");
            setEditable(false);
            refetch();
          },
        });
      } catch (error) {
        handleError(`${error}`);
      }
    }
    if (updatedFields.role) {
      try {
        await updateStaffRole({
          variables: {
            input: {
              role: updatedFields.role,
              memberId,
              operation: "UPDATE_ROLE",
            },
          },
          onCompleted: () => {
            toast.success("Staff role has been updated successfully");
            setEditable(false);
            refetch();
          },
        });
      } catch (error) {
        handleError(`${error}`);
      }
    }
  };
  const roleOptions = showEditBTN
    ? getRoleOptionsByRole(loggedUser.getMember.role)
    : staffRoles;

  return (
    <Formik
      initialValues={{
        role: role ?? "",
        staffKind: staffKind ?? "",
        joinedDateTime: joinedDateTime
          ? format(new Date(joinedDateTime), "yyyy-MM-dd")
          : "",
        aliasMemberId: aliasMemberId ?? "",
      }}
      validationSchema={userEmploymentSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {(formik) => {
        return (
          <Form id="userEmploymentInfo">
            <div className="employment-card">
              <div className="title-bar mb-18">
                <h3 className="fs-14 uppercase ">Staff Access</h3>
                {showEditBTN && (
                  <span className="icon-container edit">
                    {editable === false ? (
                      <>
                        <span
                          className="icon-container edit"
                          onClick={() => setEditable(true)}
                        >
                          <AiOutlineEdit />
                        </span>
                      </>
                    ) : (
                      <span className="icon-container">
                        <div className="sub-container save">
                          <Button
                            iconname="BiSave"
                            btntype="submit"
                            btnname="submit"
                            className="btn btn-edit save btn-lg fs-12 "
                          />
                        </div>
                        <div className="sub-container cancel">
                          <Button
                            iconname="AiOutlineCloseCircle"
                            btntype="reset"
                            btnname="reset"
                            className="btn  btn-edit cancel btn-lg fs-12"
                            click={handleCancel}
                          />
                        </div>
                      </span>
                    )}
                  </span>
                )}
              </div>
              <div className="employment-info">
                <div className="join-date">
                  <Input
                    type="date"
                    label="Joining Date *"
                    name="joinedDateTime"
                    id="joinedDateTime"
                    autoComplete="off"
                    disabled={editable ? false : true}
                    className={editable ? "blue" : ""}
                  />
                </div>
                <div className="type">
                  <Select
                    label="User Type*"
                    name="staffKind"
                    id="staffKind"
                    optionvalues={staffTypes}
                    selecttype="array"
                    autoComplete="off"
                    disabled={editable ? false : true}
                    className={
                      editable
                        ? "fs-10 select-element-value blue"
                        : "fs-10 select-element-value"
                    }
                  />
                </div>
                <div className="role">
                  <Select
                    label="Role*"
                    name="role"
                    id="role"
                    optionvalues={roleOptions}
                    selecttype="array"
                    autoComplete="off"
                    disabled={editable ? false : true}
                    className={
                      editable
                        ? "fs-10 select-element-value blue"
                        : "fs-10 select-element-value"
                    }
                  />
                </div>
                <div className="alias">
                  <Input
                    type="text"
                    label="Member ID*"
                    name="aliasMemberId"
                    id="aliasMemberId"
                    autoComplete="off"
                    disabled
                  />
                </div>
                <div className="home-club">
                  {/* <InputWithoutFormik
                    type="text"
                    label="Home Club*"
                    value={homeClubName}
                    readOnly
                  /> */}
                  <div className="location-heading text-gray fs-12">
                    Home Club*
                  </div>
                  {editable ? (
                    <div className="select-element location-value loc-select ">
                      <input
                        type="text"
                        list="locations-edit-staff"
                        placeholder="Search a Location"
                        onChange={(e) => handleChange(e)}
                        value={datalistInputValue}
                        autoComplete="off"
                        className="fs-12"
                      />
                      {locationItems && (
                        <datalist id="locations-edit-staff">
                          {locationItems.map((option) => {
                            return (
                              <option
                                key={option.locationId}
                                value={option.locationName}
                              />
                            );
                          })}
                        </datalist>
                      )}
                      {!location.locationId && (
                        <p className="error">Please select a location!</p>
                      )}
                    </div>
                  ) : (
                    <p className="fs-12">{datalistInputValue}</p>
                  )}
                </div>
                <div className="brand-id">
                  {!editable ? (
                    <InputWithoutFormik
                      type="text"
                      label="Brand *"
                      value={brandName}
                      readOnly
                    />
                  ) : (
                    <div className="select-element">
                      <h5 className="location-heading text-gray fs-12">
                        Brand *
                      </h5>
                      <select
                        value={selectedBrandId}
                        disabled={editable ? false : true}
                        onChange={(e) => seSelectedBrandId(e.target.value)}
                        className="select-element-value fs-12"
                      >
                        {locationAccess.brands.map((brand) => {
                          return (
                            <option key={brand.brandId} value={brand.brandId}>
                              {brand.brandName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserEmploymentInfo;
