import {
  GET_CLASS_TEMPLATE_DATA_BY_ID,
  LIST_CLASS_TEMPLATE_DATA,
  SET_CLASS_TEMPLATE_ID,
  LIST_STUDIO_DATA_BY_LOCATION_ID,
  LIST_INSTRUCTOR,
  CLASS_SCHEDULE_BY_ID,
  EDIT_TABLE_DATA,
  CLASSTEMPLATE_BY_BRAND_ID,
  CLEAR_ID,
  CLEAR_CLASS_TEMPLATE_DATA,
} from "../actions";

const classes_reducer = (state, action) => {
  if (action.type === GET_CLASS_TEMPLATE_DATA_BY_ID) {
    return { ...state, classTemplateDataById: action.payload };
  }
  if (action.type === CLEAR_CLASS_TEMPLATE_DATA) {
    return { ...state, classTemplateId: "", classTemplateDataById: "" };
  }

  if (action.type === CLASSTEMPLATE_BY_BRAND_ID) {
    return { ...state, classTemplateByBrandIdData: action.payload };
  }
  if (action.type === CLEAR_ID) {
    return { ...state, id: "" };
  }

  if (action.type === LIST_CLASS_TEMPLATE_DATA) {
    return {
      ...state,
      listClassTemplates: {
        listClassTemplates: {
          items: action.payload.listClassTemplates.items.filter(
            (item) => item.isActive === true && item.isDeleted !== false
          ),
          nextToken: action.payload.nextToken,
        },
      },
    };
  }

  if (action.type === SET_CLASS_TEMPLATE_ID) {
    return { ...state, classTemplateId: action.payload };
  }
  if (action.type === LIST_STUDIO_DATA_BY_LOCATION_ID) {
    return { ...state, studioData: action.payload };
  }
  if (action.type === LIST_INSTRUCTOR) {
    return { ...state, allInstructor: action.payload };
  }
  if (action.type === CLASS_SCHEDULE_BY_ID) {
    return { ...state, classScheduleId: action.payload };
  }
  if (action.type === EDIT_TABLE_DATA) {
    return { ...state, editTableData: action.payload };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default classes_reducer;
