
const InfoDisplayField = ({ label, value }) => {
  return (
    <>
      <div className="flex-col">
        <h5 className="fs-12">{label}</h5>
        <p>{value}</p>
      </div>
    </>
  );
};
export default InfoDisplayField;
