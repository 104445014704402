import "./interactions.scss";
import { useState, useEffect } from "react";
import useCustomFetch from "../../hooks/useCustomFetch";
import { API } from "../../utils/API";
import { BeatLoader } from "react-spinners";

const Interactions = ({ onClick, memberId, brandId }) => {
  const [memberInteractionEndPoint, setMemberInteractionEndPoint] = useState();
  const [memberInteractionPrams, setMemberInteractionPrams] = useState();
  const {
    data: memberInteractionData,
    isLoading,
    error,
  } = useCustomFetch(
    memberInteractionEndPoint && memberInteractionEndPoint,
    memberInteractionPrams && memberInteractionPrams
  );

  useEffect(() => {
    if (brandId && memberId) {
      setMemberInteractionEndPoint(API.memberInteractionReport(memberId));
      setMemberInteractionPrams({ brandId: brandId });
    }
  }, [brandId, memberId]);
  return (
    <>
      <div className="interaction-container pd-12">
        <div className="int-title mb-12">
          <h3 className="fs-14">Interactions </h3>
          <h3 className="fs-10 days">(Last 30 Days)</h3>
        </div>
        {isLoading ? (
          <div className="center">
            <BeatLoader className="loading" color="white" size={10} />
          </div>
        ) : error ? (
          <p className="error"> Error loading member interactions data</p>
        ) : (
          <div className="interactions-info">
            <div className="interaction-row">
              <p className="category fs-10">Visits to Clubs</p>
              <p className="value fs-10">
                {memberInteractionData?.data?.clubVisits?.toString()}
              </p>
            </div>
            <div className="interaction-row">
              <p className="category fs-10">Logins to Member portal</p>
              <p className="value fs-10">
                {memberInteractionData?.data?.loginsCount?.toString()}
              </p>
            </div>
            <div className="interaction-row">
              <p className="category fs-10">Text Message Communications</p>
              <p className="value fs-10">
                {memberInteractionData?.data?.textMessagesCount?.toString()}
              </p>
            </div>
          </div>
        )}
        <div className="btn-container">
          <button className="btn btn-large btn-transparent" onClick={onClick}>
            Detailed View
          </button>
        </div>
      </div>
    </>
  );
};

export default Interactions;
