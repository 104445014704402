import "./dashboarddetails.scss";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useMemberContext } from "../../contexts/MemberContext";
import {
  PChart,
  GenderNumber,
  Title,
  Header,
  ArChart,
  Card,
  Cardlg,
  ValueInfo,
  InsightsError,
  Trend,
  BChart,
} from "../../components";
import useCustomFetch from "../../hooks/useCustomFetch";
import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
// import { getDashboardMemberSuspendedJoinerLeaverURL } from "../../dummyData";
import { API } from "../../utils/API";
import { format, set } from "date-fns";
import { getPercentage } from "../../utils/getPercentage";

const DashboardDetails = () => {
  const { loggedUser } = useAuthContext();

  const { sidebarLocationId, sidebarBrandId } = useMemberContext();

  const pageTitle = `Hey, ${loggedUser?.getMember?.givenName}`;

  const [memberStatsEndPoint, setMemberStatsEndPoint] = useState();
  const [paramsMemberStatsEndPoint, setParamsMemberStatsEndPoint] = useState();

  const [genderStatsEndPoint, setGenderStatsEndPoint] = useState();
  const [paramsgenderStatsEndPoint, setParamsGenderStatsEndPoint] = useState();

  const [clubVistsEndPoint, setClubVistsEndPoint] = useState();
  const [paramsClubVistsEndPoint, setParamsClubVistsEndPoint] = useState();

  const [averageYeildEndPoint, setAverageYeildEndPoint] = useState();
  const [paramsAverageYeildEndPoint, setParamsAverageYeildEndPoint] =
    useState();

  const [directDebitEndPoint, setDirectDebitEndPoint] = useState();
  const [paramsDirectDebitEndPoint, setParamsDirectDebitEndPoint] = useState();
  const {
    data: averageYeildData,
    isLoading: averageYeildLoading,
    error: averageYeildError,
  } = useCustomFetch(
    averageYeildEndPoint && averageYeildEndPoint,
    paramsAverageYeildEndPoint && paramsAverageYeildEndPoint
  );

  const {
    data: directDebitData,
    isLoading: directDebitLoading,
    error: directDebitError,
  } = useCustomFetch(
    directDebitEndPoint && directDebitEndPoint,
    paramsDirectDebitEndPoint && paramsDirectDebitEndPoint
  );

  const {
    isLoading: memberStatsLoading,
    error: memberStatsError,
    data: memberStatsData,
  } = useCustomFetch(
    memberStatsEndPoint && memberStatsEndPoint,
    paramsMemberStatsEndPoint && paramsMemberStatsEndPoint
  );

  const {
    isLoading: genderStatsLoading,
    error: genderStatsError,
    data: genderStatsData,
  } = useCustomFetch(
    genderStatsEndPoint && genderStatsEndPoint,
    paramsgenderStatsEndPoint && paramsgenderStatsEndPoint
  );

  const {
    data: clubVisitsData,
    isLoading: clubVisitsLoading,
    error: clubVisitsError,
  } = useCustomFetch(
    clubVistsEndPoint && clubVistsEndPoint,
    paramsClubVistsEndPoint && paramsClubVistsEndPoint
  );
  const totalSum = clubVisitsData?.data.reduce(
    (accumulator, currentValue) => accumulator + currentValue.total,
    0
  );
  const setLocationAndBrandQuery = () => {
    const todayDate = format(new Date(), "yyyy-MM-dd");
    if (sidebarBrandId && sidebarLocationId !== "")
      if (sidebarLocationId == 0) {
        setMemberStatsEndPoint(API.memberStatsReport);
        setParamsMemberStatsEndPoint({
          brandId: sidebarBrandId,
          dimensions: `active,
          suspended,
          joined,
          gender,
          left,
          future,
          average_age,
          returned,`,
        });

        setGenderStatsEndPoint(API.genderStatsReport);
        setParamsGenderStatsEndPoint({
          brandId: sidebarBrandId,
        });

        setClubVistsEndPoint(API.clubVisitsReport);
        setParamsClubVistsEndPoint({
          endDate: todayDate,
          startDate: todayDate,
          brandId: sidebarBrandId,
        });

        setAverageYeildEndPoint(API.averageMemberYeildReport);
        setParamsAverageYeildEndPoint({
          brandId: sidebarBrandId,
          period: "fortnightly",
        });

        setDirectDebitEndPoint(API.directDebitReport);
        setParamsDirectDebitEndPoint({
          brandId: sidebarBrandId,
          period: "fortnightly",
        });
      } else {
        setMemberStatsEndPoint(API.memberStatsReport);
        setParamsMemberStatsEndPoint({
          locationId: sidebarLocationId,
          brandId: sidebarBrandId,
          dimensions: `active,
          suspended,
          joined,
          gender,
          left,
          future,
          average_age,
          returned,`,
        });

        setGenderStatsEndPoint(API.genderStatsReport);
        setParamsGenderStatsEndPoint({
          locationId: sidebarLocationId,
          brandId: sidebarBrandId,
        });

        setClubVistsEndPoint(API.clubVisitsReport);
        setParamsClubVistsEndPoint({
          endDate: todayDate,
          startDate: todayDate,
          locationId: sidebarLocationId,
          brandId: sidebarBrandId,
        });

        setAverageYeildEndPoint(API.averageMemberYeildReport);
        setParamsAverageYeildEndPoint({
          locationId: sidebarLocationId,
          brandId: sidebarBrandId,
          period: "fortnightly",
        });

        setDirectDebitEndPoint(API.directDebitReport);
        setParamsDirectDebitEndPoint({
          locationId: sidebarLocationId,
          brandId: sidebarBrandId,
          period: "fortnightly",
        });
      }
  };

  useEffect(() => {
    setLocationAndBrandQuery();
  }, [sidebarLocationId, sidebarBrandId]);

  useEffect(() => {
    setLocationAndBrandQuery();
  }, []);

  const [clubVisitTotal, setClubVisitTotal] = useState(0);
  useEffect(() => {
    if (clubVisitsData && clubVisitsData?.data && clubVisitsData.data?.length) {
      const total = clubVisitsData?.data.reduce((total, curr) => {
        return (total += curr.total);
      }, 0);
      setClubVisitTotal(total);
    }
  }, [clubVisitsData]);
  const ddTotal =
    directDebitData?.data?.chequeSavingsTotal +
    directDebitData?.data?.creditCardTotal;
  return (
    <div>
      <Header pageTitle={pageTitle} backButton={false} />
      <div className="dashboard-details">
        <div className="featured-info">
          <div className="row1-infocards">
            <div className="col1">
              <Card
                title="total members"
                heading="Active Members"
                value={
                  memberStatsError ? (
                    <InsightsError />
                  ) : memberStatsLoading ? (
                    <BeatLoader color="white" />
                  ) : (
                    memberStatsData?.data?.active.toLocaleString()
                  )
                }
                fetchRecordError={memberStatsError}
                icon="BiUser"
              />
            </div>

            <div className="col2">
              <Card
                title="Suspended"
                heading="Total Suspended Members"
                value={
                  memberStatsError ? (
                    <InsightsError />
                  ) : (
                    memberStatsData?.data?.suspended.toLocaleString()
                  )
                }
                fetchRecordError={memberStatsError}
                icon="MdOutlineArrowDropDown"
              />
            </div>
            <div className="col3">
              <Card
                title="joiners"
                heading="Total Joiners"
                value={
                  memberStatsError ? (
                    <InsightsError />
                  ) : (
                    (
                      memberStatsData?.data?.joined +
                      (memberStatsData?.data?.returned ?? 0)
                    ).toLocaleString()
                  )
                }
                fetchRecordError={memberStatsError}
                icon="MdOutlineArrowDropUp"
              />
            </div>
            <div className="col4">
              <Card
                title="Leavers"
                heading="Total Leavers"
                value={
                  memberStatsError ? (
                    <InsightsError />
                  ) : (
                    memberStatsData?.data?.left.toLocaleString()
                  )
                }
                fetchRecordError={memberStatsError}
                icon="RiArrowUpDownFill"
              />
            </div>
            <div className="col5">
              <Title title="CLUB VISITS" subtitle="TODAY" />
              <div
                className={
                  clubVisitsError ? "member-info has-error" : "member-info"
                }
              >
                <div
                  className={
                    clubVisitsError ? "total-member " : "total-member mt-24"
                  }
                >
                  <div className="total-club-visit">
                    {clubVisitsError ? (
                      <InsightsError />
                    ) : clubVisitsLoading ? (
                      <BeatLoader color="white" />
                    ) : (
                      <>
                        <div className="fs-14 uppercase">total</div>
                        <span className="fs-54">
                          {" "}
                          {totalSum ? totalSum.toLocaleString() : 0}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {clubVisitsError || clubVisitsLoading ? null : (
                <ArChart clubVisitsData={clubVisitsData?.data} />
              )}
            </div>
            <div
              className={genderStatsError ? "col5 next has-error" : "col5 next"}
            >
              <Title title="GENDER" subtitle="TODAY" />
              <div
                className={
                  genderStatsError
                    ? "gender-chart-container has-error"
                    : "gender-chart-container"
                }
              >
                {genderStatsError ? (
                  <InsightsError />
                ) : genderStatsLoading ? (
                  <BeatLoader color="white" />
                ) : (
                  <>
                    <div className="gender-number-group">
                      <GenderNumber
                        className="male-bar"
                        number={
                          genderStatsError
                            ? "Error fetching record..."
                            : genderStatsData?.data?.male.toLocaleString()
                        }
                        gender="MALE"
                      />
                      <GenderNumber
                        className="female-bar"
                        number={
                          genderStatsError
                            ? "Error fetching record..."
                            : genderStatsData?.data?.female.toLocaleString()
                        }
                        gender="FEMALE"
                      />
                      <GenderNumber
                        className="other-bar"
                        number={
                          genderStatsError
                            ? "Error fetching record..."
                            : genderStatsData?.data?.other.toLocaleString()
                        }
                        gender="OTHER"
                      />
                    </div>
                    <div className="pie-chart-container">
                      <PChart genderStatsData={genderStatsData} />
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="col6 flex">
              <Cardlg title="AVERAGE MEMBER YIELD" subtitle="Last Fortnight">
                {averageYeildError ? (
                  <InsightsError />
                ) : averageYeildLoading ? (
                  <BeatLoader color="white" />
                ) : (
                  <>
                    <ValueInfo
                      heading="new member average yield"
                      value={averageYeildData?.data?.newMemberAverageYield.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    >
                      {/* <Trend rate={3.8} /> */}
                    </ValueInfo>
                    <ValueInfo
                      heading="total member average yield"
                      value={averageYeildData?.data?.totalMemberAverageYield.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    >
                      {/* <Trend rate={1.4} /> */}
                    </ValueInfo>
                  </>
                )}
              </Cardlg>{" "}
              {loggedUser?.getMember?.role === "L5" && (
                <Cardlg title="DIRECT DEBIT" subtitle="Last Fortnight">
                  {directDebitError ? (
                    <InsightsError />
                  ) : directDebitLoading ? (
                    <BeatLoader color="white" />
                  ) : (
                    <>
                      <ValueInfo
                        heading=""
                        value={ddTotal.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          style: "currency",
                          currency: "USD",
                        })}
                        childrenPosition="head"
                      ></ValueInfo>
                      <ValueInfo
                        heading="successful"
                        value={getPercentage(
                          directDebitData?.data?.successRate ?? 0
                        )}
                        childrenPosition="head"
                      ></ValueInfo>
                    </>
                  )}
                </Cardlg>
              )}
            </div>
            {/* <div className="col5 small">
              <Title title="MARKETING" subtitle="LAST 7 DAYS" />
              <div className="marketing-info">
                <div className="wrapper">
                  <ValueInfo heading="visits to club page" value="265">
                    <div className="fs-8 heading total">total</div>
                  </ValueInfo>
                  <BChart data={barChartData1} index="5" />
                </div>
                <ValueInfo heading="lead by source">
                  <div className="pie-chart-position">
                    <div>
                      <div className="fs-54">61</div>
                      <div className="fs-8 heading total">total</div>
                    </div>
                    <PChart />
                  </div>
                </ValueInfo>
              </div>
            </div> */}
            {/* <div className="col8">
              <Title title="MARKETING" subtitle="LAST 30 DAYS" />
              <div className="marketing-info">
                <ValueInfo heading="leads" value="241" chart={false} />
                <ValueInfo heading="appointments" value="56" chart={false} />
                <ValueInfo heading="walk-in's" value="33" chart={false} />
                <ValueInfo heading="leads to sale" value="32" chart={true} />
                <ValueInfo heading="appts to sale" value="84" chart={true} />
                <ValueInfo
                  heading="walk-in's to sale"
                  value="93"
                  chart={true}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardDetails;
