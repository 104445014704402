import React, { useState } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { AiOutlineEdit } from "react-icons/ai";
import { instructorContactDetailSchema } from "../../validations/Validation";
import Button from "../formUiElements/Button";
import Input from "../formUiElements/input/Input";
import InternationalPhoneInput from "../formUiElements/input/InternationalPhoneInput";

const InstructorContactDetail = ({ instructorInfo, onSubmit }) => {
  const [isActive, setIsActive] = useState(true);

  const handleIsActive = () => {
    setIsActive(false);
  };
  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("instructor-contact-form").reset();
    setIsActive(true);
  };

  return (
    <>
      {instructorInfo && (
        <Formik
          enableReinitialize
          initialValues={{
            instructorEmail: instructorInfo?.instructorEmail ?? "",
            instructorMobileNumber:
              instructorInfo?.instructorMobileNumber ?? "",
          }}
          validationSchema={instructorContactDetailSchema}
          onSubmit={async (values, OnSubmitProps) => {
            onSubmit(values, OnSubmitProps);
            setIsActive(true);
          }}
        >
          {(formik) => {
            return (
              <div className="instructor-contact-container">
                <Form className="info-card " id="instructor-contact-form">
                  {isActive ? (
                    <>
                      <div className="title-bar">
                        <h3 className="fs-14 uppercase">Contact Details</h3>
                        <span
                          className="icon-container edit"
                          onClick={handleIsActive}
                        >
                          <AiOutlineEdit />
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="title-bar">
                      <h3 className="fs-14 uppercase">Contact Detail</h3>
                      <span className="icon-container ">
                        <div className="sub-containers">
                          <div className="sub-container save">
                            <Button
                              loading={formik.isSubmitting}
                              name={""}
                              iconname={formik.isSubmitting ? "" : "BiSave"}
                              btntype="submit"
                              btnname="submit"
                              className="btn btn-edit save btn-lg fs-12"
                              disabled={formik.isSubmitting ? true : false}
                            />
                          </div>
                          <div className="sub-container cancel">
                            <Button
                              iconname="AiOutlineCloseCircle"
                              btntype="reset"
                              btnname="reset"
                              className="btn btn-icon btn-lg fs-12"
                              click={handleCancel}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  )}
                  <div className="instructor-contact-details">
                    <div className="detail">
                      <Input
                        type="text"
                        label="Email address*"
                        name="instructorEmail"
                        autoComplete="off"
                        disabled={isActive ? true : false}
                        className="fs-12"
                      />
                    </div>
                    <div className="detail">
                      <InternationalPhoneInput
                        title="Mobile Number*"
                        name="instructorMobileNumber"
                        editable={isActive ? false : true}
                        className="fs-12"
                      />
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      )}
    </>
  );
};
export default InstructorContactDetail;
