import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";

const COLORS = [
  "#595fa5",
  "var(--color-persianblue)",
  "var( --color-gray-100)",
];
const borderColor = "#000000"; // Border color
const borderWidth = 0.5; // Border width
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.45;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="white"
      fontSize={12}
      fontWeight="400"
      fontFamily="Bebas Neue"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  );
};

const PChart = ({ genderStatsData }) => {
  const data = [
    { name: "female", value: genderStatsData?.data?.female },
    { name: "male", value: genderStatsData?.data?.male },
    { name: "other", value: genderStatsData?.data?.other },
  ];
  return (
    <ResponsiveContainer>
      <PieChart width={200} height={200}>
        <defs>
          <radialGradient id="male" cx="100%" cy="0%" r="100%">
            <stop offset="50%" stopColor="var(--color-persianblue)" />
            <stop
              offset="90%"
              stopColor="var(--color-persianblue)"
              stopOpacity={0.9}
            />
            <stop
              offset="100%"
              stopColor="var(--color-persianblue)"
              stopOpacity={0.8}
            />
          </radialGradient>
          <radialGradient id="female" cx="0%" cy="100%" r="100%">
            <stop offset="50%" stopColor="#595fa5" />
            <stop offset="80%" stopColor="#595fa5" stopOpacity={0.9} />
            <stop offset="100%" stopColor="#595fa5" stopOpacity={0.8} />
          </radialGradient>
          <radialGradient id="other" cx="0%" cy="0%" r="100%">
            <stop offset="50%" stopColor="var( --color-gray-100)" />
            <stop
              offset="80%"
              stopColor="var( --color-gray-100)"
              stopOpacity={0.9}
            />
            <stop
              offset="100%"
              stopColor="var( --color-gray-100)"
              stopOpacity={0.8}
            />
          </radialGradient>
        </defs>
        <Pie
          data={data}
          cx={93.76}
          cy={93.76}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={93.76}
          fill="var(--color-persianblue)"
          dataKey="value"
        >
          {data.map((entry, index) => {
            if (index % COLORS.length === 1) {
              return (
                <Cell
                  key={`cell-${index}`}
                  stroke={borderColor}
                  strokeWidth={borderWidth}
                  fill="url(#male)"
                />
              );
            }
            if (index % COLORS.length === 0) {
              return (
                <Cell
                  key={`cell-${index}`}
                  stroke={borderColor}
                  strokeWidth={borderWidth}
                  fill="url(#female)"
                />
              );
            }
            if (index % COLORS.length === 2) {
              return (
                <Cell
                  key={`cell-${index}`}
                  stroke={borderColor}
                  strokeWidth={borderWidth}
                  fill="url(#other)"
                />
              );
            }
            return (
              <Cell
                key={`cell-${index}`}
                stroke={0}
                fill={COLORS[index % COLORS.length]}
              />
            );
          })}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
export default PChart;
