import "./modal.scss";
import Button from "../formUiElements/Button";
import { BeatLoader } from "react-spinners";

const StatusModal = (props) => {
  return (
    <div className="status-modal">
      <h3 className="fs-35 uppercase "> {props.modalHeaderTxt} </h3>
      {props.className && (
        <span className="className-span ">
          <p className="fs-12 uppercase">{props.className}</p>
        </span>
      )}

      <div className="uppercase fs-14 w-650">{props.modalParaText}</div>
      {props.showOneBtn ? (
        <Button
          name={props.oneButtonName ? props.oneButtonName : "Close"}
          btntype="button"
          btnname="cancel"
          className="btn btn-confirm btn-small"
          click={props.modalClose}
        />
      ) : (
        <div className="btn-group">
          <Button
            name={
              props.loading ? (
                <BeatLoader color="white" size={10} />
              ) : (
                props.btnName ?? "Yes"
              )
            }
            btntype="submit"
            btnname="submit"
            className="btn btn-confirm btn-small"
            disabled={props.loading || props?.disabled ? true : false}
            click={() => {
              props.handleStatusClick(props.curRow);
            }}
          />
          <Button
            name="No"
            btntype="button"
            btnname="cancel"
            className="btn btn-deny btn-small"
            click={props.modalClose}
            disabled={props.loading || props?.disabled ? true : false}
          />
        </div>
      )}
    </div>
  );
};

export default StatusModal;
