import { isWithinInterval } from "date-fns";

const checkDateOverlap = (specificDate, dateRange) => {
  const startDate = new Date(dateRange.start);
  const endDate = new Date(dateRange.end);

  return isWithinInterval(specificDate, { start: startDate, end: endDate });
};

export default checkDateOverlap;
