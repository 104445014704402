import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { ADD_DOOR_ACCESS } from "../../../../gqloperations/mutations";
import { accessPointSchema } from "../../../../validations/LocationValidation";
import { Header, Button, Input, ToggleFormik} from "../../../../components";
import { useLocationContext } from "../../../../contexts/LocationContext";
import { useHandleError } from '../../../../hooks/useHandleError';

export default function AddDoorAccess() {
  const navigate = useNavigate();
  const { state, getLocationById, dispatch } = useLocationContext();

  const [addAccessPoint] = useMutation(ADD_DOOR_ACCESS);
  const handleError = useHandleError();
  
  const onSubmit = async (values, OnSubmitProps) => {
    try {
      await addAccessPoint({
        variables: {
          input: {
            ...values,
            locationId: state.id,
            doorName: values.doorName.toUpperCase(),
            accessPointname: values.accessPointname.toUpperCase(),
            swipeMessageText: values.swipeMessageText,
            errorMessage: values.errorMessage,
            isMessageActive: values.isMessageActive,
            messageFrequency: values.messageFrequency,
          },
        },
        onCompleted: () => {
          OnSubmitProps.setSubmitting(false);
          OnSubmitProps.resetForm();
          toast.success("Location Added Successfully");
          getLocationById();
          dispatch({ type: "SET_INDEX", payload: 4 });
          navigate(`/dashboard/location/specificLocation/${state.id}`);
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
    OnSubmitProps.setSubmitting(false);
  };
  const handleCancel = () => {
    toast.warning("Add Door Access Cancelled!");
    dispatch({ type: "SET_INDEX", payload: 4 });
    navigate(`/dashboard/location/specificLocation/${state.id}`);
  };
  return (
    <Formik
      initialValues={{
        doorName: "",
        accessPointname: "",
        swipeMessageText: "",
        errorMessage: "",
        isMessageActive: false,
        messageFrequency: 5,
      }}
      validationSchema={accessPointSchema}
      onSubmit={(values, OnSubmitProps) => {
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <>
            <Header pageTitle="Add Door Access" />
            <Form>
              <div className="add-info-container">
                <div className="details-container loc-card">
                  <h3>Door Access Point</h3>
                  <div className="detail">
                    <div className="pb-12">
                      {" "}
                      <Input
                        type="text"
                        label="Door Name * "
                        name="doorName"
                        id="doorName"
                        autoComplete="off"
                      />
                    </div>

                    <Input
                      type="text"
                      label="Access Point Name *"
                      name="accessPointname"
                      id="accessPointname"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="details-container loc-card">
                  <h3 className="title">Message</h3>
                  <label
                    htmlFor="swipeMessageText"
                    className="fs-12 mb-8 text-gray"
                  >
                    Swipe Message
                  </label>
                  <textarea
                    id="swipeMessageText"
                    name="swipeMessageText"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.swipeMessageText}
                  ></textarea>
                  <label
                    htmlFor="errorMessage"
                    className="fs-12 mb-8 text-gray"
                  >
                    Error Message
                  </label>
                  <textarea
                    id="errorMessage"
                    name="errorMessage"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.errorMessage}
                  ></textarea>
                  <Input
                    type="number"
                    label="Swipe Message Frequency * "
                    name="messageFrequency"
                    id="messageFrequency"
                    autoComplete="off"
                  />
                  <ToggleFormik
                    label="Swipe Message Active"
                    name="isMessageActive"
                    id="isMessageActive"
                    value={formik.isMessageActive}
                  />
                </div>
              </div>
              <div className="save-btn right-btn">
                {/* {formik.isSubmitting && (
                  <Button
                    name={<BeatLoader color="white" />}
                    btntype="submit"
                    btnname="submit"
                    disabled={true}
                    className="btn btn-confirm btn-large fs-12"
                  />
                )}
                {!formik.isSubmitting && (
                  <>
                    <Button
                      name="SAVE"
                      btntype="submit"
                      btnname="submit"
                      // disabled={!Formik.isValid || Formik.isSubmitting}
                      className="btn btn-confirm  fs-12"
                    />
                    <Button
                      name="CANCEL"
                      btntype="reset"
                      btnname="reset"
                      className="btn btn-deny btn-large fs-12"
                      click={handleCancel}
                    />
                  </>
                )} */}
                <Button
                  loading={formik.isSubmitting}
                  name={"Save"}
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-confirm btn-large fs-12"
                  disabled={formik.isSubmitting ? true : false}
                />
                <Button
                  name="Cancel"
                  btntype="reset"
                  btnname="reset"
                  className="btn btn-deny btn-large fs-12"
                  click={handleCancel}
                />
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
