import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function usePageHistory() {
  // Get the current location object, which includes the current URL path
  const location = useLocation();

  // Initialize state to store the history of visited paths
  const [history, setHistory] = useState([]);

  useEffect(() => {
    // Update the history whenever the location changes
    setHistory((prevHistory) => {
      // Create a new history array with the current location added
      const newHistory = [...prevHistory, location.pathname];
      // Keep only the last two entries in the history
      return newHistory.slice(-2);
    });
  }, [location]);

  // Return the second last path if it exists, otherwise return an empty string
  return history.length >= 2 ? history[0] : "";
}

export default usePageHistory;
