import React from "react";
import "./cardInformation.scss";
import { useTableStateProps } from "../../hooks/useTableStateprops";
import Table from "../table/Table";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { useState } from "react";
import Modal from "../modal/Modal";
import DamagedCardModal from "./DamagedCardModal";
import { GET_CARD_DETAILS_BY_MEMBERID } from "../../gqloperations/queries";
import { BeatLoader } from "react-spinners";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import EditCardModal from "./EditCardModal";
import { format, isSameDay, isBefore } from "date-fns";
import { sortDesc } from "../../utils/sort";
import { utcTimeToPCTime } from "../../utils/utcTimeToPCTime";

const CardInformation = () => {
  const { id: memberId } = useParams();
  const [tableData, setTableData] = useState();
  const [componentName, setComponentName] = useState("");
  const [card, setCard] = useState("");

  const { loading: cardNumbersLoading, error: cardNumbersError } = useQuery(
    GET_CARD_DETAILS_BY_MEMBERID,
    {
      onCompleted: (data) => {
        const { items } = data?.getCardDetailsByMemberId;
        if (items) {
          setTableData(sortDesc(items, "createdAt"));
        }
      },
      variables: {
        memberId,
      },
    }
  );

  const {
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
  } = useTableStateProps();

  const handleComponent = (componentname, rowObj) => {
    setComponentName(componentname);
    setCard(rowObj);
  };

  const modalClose = () => {
    setComponentName("");
  };
  const columns = [
    {
      accessorFn: (row) => row?.cardNumber,
      header: "Card Number",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "cardNumber",
    },
    {
      accessorFn: (row) => {
        if (row?.isLost) {
          return <p className="text-red">INACTIVE/ LOST</p>;
        } else if (!row?.endDateTime) {
          return <p className="text-green">ACTIVE</p>;
        } else if (
          !isSameDay(new Date(row?.endDateTime), new Date()) &&
          isBefore(new Date(row?.endDateTime), new Date())
        ) {
          return <p className="text-red">INACTIVE</p>;
        } else {
          return <p className="text-green">ACTIVE</p>;
        }
      },

      header: "Status",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "cardStatus",
    },
    {
      accessorFn: (row) => {
        return row?.endDateTime
          ? `${format(new Date(row.endDateTime), "dd/MM/yy")}`
          : "";
      },

      header: "Expiry",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "cardExpiry",
    },
    {
      accessorFn: (row) =>
        row?.createdAt ? utcTimeToPCTime(row?.createdAt) : "",
      header: "created at",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "createdAt",
    },

    {
      accessorFn: (row) => {
        const showEditButton =
          (row.isLost !== true && !row?.endDateTime) ||
          // (row.isLost === false &&
          //   row.endDateTime >= `${format(new Date(), "yyyy-MM-dd")}`);
          (row.isLost !== true &&
            isSameDay(new Date(row?.endDateTime), new Date())) ||
          (row.isLost !== true &&
            isBefore(new Date(), new Date(row?.endDateTime)));

        return showEditButton ? (
          <AiOutlineEdit
            onClick={() => {
              handleComponent("edit-card", {
                id: row.id,
                cardNumber: row.cardNumber,
                endDateTime: row.endDateTime,
              });
            }}
          />
        ) : (
          ""
        );
      },
      header: " ",
      cell: (info) => info?.getValue(),

      enableSorting: false,
      enableGlobalFilter: false,
    },

    {
      id: "row-actions",
      cell: (props) => {
        return (
          props?.row?.original?.isLost !== true && (
            <BsFillPersonVcardFill
              onClick={() => {
                handleComponent("damaged-card", {
                  id: props.row.original.id,
                  cardNumber: props.row.original.cardNumber,
                });
              }}
            />
          )
        );
      },
    },
  ];

  if (cardNumbersLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (cardNumbersError) {
    return (
      <div>
        <div>Couldn't get card information</div>
      </div>
    );
  }
  return (
    <>
      {componentName === "damaged-card" && (
        <Modal>
          <DamagedCardModal cardId={card.id} modalClose={modalClose} />
        </Modal>
      )}
      {componentName === "edit-card" && (
        <Modal>
          <EditCardModal card={card} modalClose={modalClose} />
        </Modal>
      )}
      <div className="card-information-container">
        <Table
          data={tableData}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          sorting={sorting}
          setSorting={setSorting}
          columns={columns}
          rowsPerPage={10}
          // handleNextToken={handleNextToken}
          // nextToken={nextToken}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
        />
      </div>
    </>
  );
};

export default CardInformation;
