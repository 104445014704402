import { useState, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Loading, Modal } from "../components";
import { AuthContext } from "../contexts";
import "./protectedRoutes.scss";

const ProtectedRoutes = ({ children, permission, role }) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(true);
  const { loginUserLoading, loggedUser, permissions } = useContext(AuthContext);

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate(-1);
  };

  if (loginUserLoading) {
    return (
      <div className="align-center">
        <Loading />
      </div>
    );
  }

  if (!loggedUser) {
    return <Navigate to="/" />;
  }
  if (permissions.includes(permission)) {
    return children;
  }

  return (
    <>
      {openModal && (
        <>
          <div className="protected-route-container">
            <Modal>
              <div className="protected-route-content pd-32">
                <div className="uppercase fs-14">You don't have permission</div>
                <button
                  className="btn btn-primary btn-confirm"
                  onClick={handleCloseModal}
                >
                  Go Back
                </button>
              </div>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default ProtectedRoutes;
