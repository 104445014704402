import React from "react";
import "./notice.scss";
const Notice = () => {
  return (
    <div className="notice fs-14 text-gray">
      Important Notice:{" "}
      <span className="fs-12">
        This report updates in real-time as members pay their overdue amounts
        for rejected direct debits. Therefore, the numbers in this report may
        differ from those in the past settlement reports and invoices provided
        by VivaPay.
      </span>
    </div>
  );
};

export default Notice;
