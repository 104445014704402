import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Input,
  InputWithoutFormik,
  InternationalPhoneInput,
  Select,
} from "../../../../components";
import * as yup from "yup";
import { useAddMemberContext } from "../../../../contexts/AddMemberContext";
import "./health.scss";
import { NavigateButtonGroup } from "../index";
import { healthQuestionsByBrandId } from "../../../../gqloperations/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHandleError } from "../../../../hooks/useHandleError";
import {
  PRE_SIGNUP_UNDER_18,
  PRE_SINGUP_OVER_18,
} from "../../../../gqloperations/mutations";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import Switch from "../../../../components/formUiElements/Switch";
import { isValidPhoneNumber } from "libphonenumber-js";
import ModalTermsAndCondition from "./modals/ ModalTermsAndCondition";
import ModalPrivacyPolicy from "./modals/ModalPrivacyPolicy";
import PreExerciseModal from "./modals/PreExerciseModal";
import { ON_APPROVE_TERMS_CONDITIONS } from "../../../../gqloperations/subscriptions";
import { API, graphqlOperation } from "aws-amplify";
import { gender, state } from "../../../../constants/commonConstant";
function Health() {
  const {
    handleMoveStepNext,
    memberInformation,
    setMemberInformation,
    memberData,
    setMemberData,
    step,
    setStepHandler,
  } = useAddMemberContext();

  //
  const [preSignupMemberOver18, { loading: preSignupOver18Loading }] =
    useMutation(PRE_SINGUP_OVER_18);

  const handleError = useHandleError();

  const [preSignupMemberUnder18, { loading: preSignupUnder18Loading }] =
    useMutation(PRE_SIGNUP_UNDER_18);
  const [getListHealthQuestion] = useLazyQuery(healthQuestionsByBrandId);
  const [listHealthQuestion, setListHealthQuestion] = useState([]);
  const [isHaveInjury, setIsHaveInjury] = useState(false);
  const [isApproveTermsConditions, setIsApproveTermsConditions] =
    useState(false);
  const isPaymentDelay =
    memberInformation?.membershipDetails?.membershipDetails?.paymentDelay
      ?.amount !== 0 &&
    memberInformation?.membershipDetails.membershipDetails?.paymentDelay !==
      null;

  const isVoucherDelay =
    Object.keys(memberInformation?.voucherDetail || {}).length > 0 &&
    memberInformation?.voucherDetail?.paymentDelay !== null &&
    memberInformation?.voucherDetail?.paymentDelay?.amount !== 0;
  const zeroCostMembership =
    !memberInformation.yourMembershipData?.membershipPrice &&
    !memberInformation?.yourMembershipData?.joiningFee &&
    !memberInformation?.yourMembershipData?.activationFee &&
    !memberInformation?.yourMembershipData?.accessFee &&
    !isPaymentDelay &&
    !isVoucherDelay;

  const userEmergencySchema = yup.object().shape({
    emergencyContactName: yup.string().required("Emergency name is required"),
    emergencyContactEmail: yup
      .string()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .email("Invalid email address"),
    emergencyContactRelation: yup
      .string()
      .required("Emergency relation is Required"),
    emergencyContactNumber: yup
      .string()
      .test("is-valid-number", "Invalid Number", (mobileNumber) => {
        return mobileNumber ? isValidPhoneNumber(mobileNumber) : false;
      })
      .test(
        "is-unique",
        "You have entered a duplicate phone number",
        function (mobileNumber) {
          const isAdult = memberInformation?.age >= 18;
          if (isAdult) {
            return mobileNumber !== memberData?.memberDetails.mobileNumber;
          }
          return true;
        }
      ),
    gender: yup.string().required("Gender is Required"),
    postCode: yup
      .string()
      .matches(/^\d+$/, "Post code must contain only numbers")
      .required("Post code is Required"),
    address: yup.string().required("Address is Required"),
    suburb: yup.string().required("Suburb is Required"),
    state: yup.string().required("State is Required"),
  });

  useEffect(() => {
    getListHealthQuestion({ fetchPolicy: "no-cache" }).then((res) => {
      const questions = res.data?.getHealthQuestionnairesByBrandId.items.map(
        (i) => {
          return { ...i, userValue: "" };
        }
      );
      let sortedQuestion = [...questions];
      sortedQuestion.push(
        sortedQuestion.splice(
          sortedQuestion.findIndex((i) =>
            i.question
              .toLowerCase()
              .includes(
                "I am under the supervision of a medical practitioner or I will seek guidance from an appropriate health or medical practitioner prior to undertaking exercise.".toLowerCase()
              )
          ),
          1
        )[0]
      );
      setListHealthQuestion(sortedQuestion);
    });
  }, []);

  useEffect(() => {
    setIsApproveTermsConditions(false);
  }, [memberData]);

  const submitUserData = async (value) => {
    if (!Object.values(confirmBoxes).every((i) => i)) {
      toast.dismiss();
      handleError("Please accept our Terms and Conditions");
      return;
    }
    const payload = memberData;
    payload.memberDetails = {
      ...payload.memberDetails,
      ...value,
      emergencyContactEmail: value.emergencyContactEmail || null,
      healthQuestionnaire: JSON.stringify(
        listHealthQuestion.reduce(
          (obj, item) => ({ ...obj, [item.id]: item.userValue }),
          {}
        )
      ),
    };
    setMemberData(payload);
    const isAddCard =
      (memberInformation?.voucherDetail?.type === "COMPLIMENTARY" &&
        !memberInformation?.yourMembershipData?.joiningFee &&
        !memberInformation?.yourMembershipData?.activationFee &&
        !memberInformation?.yourMembershipData?.accessFee) ||
      zeroCostMembership
        ? false
        : true;
    if (memberInformation.age >= 18) {
      await preSignupMemberOver18({
        variables: {
          input: { ...payload, isAddCard: isAddCard, isApproveTerms: false },
        },
        onCompleted: (data) => {
          setMemberInformation({
            ...memberInformation,
            signupData: {
              memberId: data?.signupMemberViaWebPreparePaymentOver18?.memberId,
              prospectId:
                data?.signupMemberViaWebPreparePaymentOver18?.prospectId,
              tokenId: data?.signupMemberViaWebPreparePaymentOver18?.tokenId,
            },
          });
          if (data?.signupMemberViaWebPreparePaymentOver18?.memberId) {
            toast.success("SMS sent successfully");
            let subscriptionObject;
            const subscription = API.graphql(
              graphqlOperation(ON_APPROVE_TERMS_CONDITIONS, {
                prospectId:
                  data?.signupMemberViaWebPreparePaymentOver18?.prospectId,
              })
            );

            const subscriptionObserver = {
              next: (eventData) => {
                if (
                  eventData?.value?.data?.onApproveTermsConditions?.prospectId
                ) {
                  setIsApproveTermsConditions(true);
                  subscriptionObject.unsubscribe();
                }
              },
              error: (error) => {
                console.error("Subscription error:", error);
              },
            };
            subscriptionObject = subscription.subscribe(subscriptionObserver);
          } else {
            handleError(data?.signupMemberViaWebPreparePaymentOver18?.message);
          }
        },
        onError: (error) => {
          handleError(`${error}`);
        },
      });
    } else {
      await preSignupMemberUnder18({
        variables: { input: { ...payload, isAddCard: isAddCard } },
        onCompleted: (data) => {
          setMemberInformation({
            ...memberInformation,
            signupData: {
              memberId: data?.signupMemberViaWebPreparePaymentUnder18?.memberId,
              prospectId:
                data?.signupMemberViaWebPreparePaymentUnder18?.prospectId,
              tokenId: data?.signupMemberViaWebPreparePaymentUnder18?.tokenId,
            },
          });
          if (data?.signupMemberViaWebPreparePaymentUnder18?.memberId) {
            handleMoveStepNext();
          } else {
            handleError(data?.signupMemberViaWebPreparePaymentUnder18?.message);
          }
        },
        onError: (error) => {
          handleError(`${error}`);
        },
      });
    }
    // handleMoveStepNext();
  };

  const healthCheck = () => {
    if (
      listHealthQuestion.every((i) =>
        i.answerType === "NUMBER"
          ? i.mandatory
            ? i.expectedAnswer !== ""
              ? i.userValue === i.expectedAnswer
              : i.userValue !== ""
            : true
          : i.answerType === "STRING"
          ? i.mandatory
            ? i.userValue !== ""
            : true
          : false
      )
    ) {
      return true;
    }
    return { healthNotValid: "Please answer the health questions" };
  };

  const RenderHealthQuestionAndMedicalDeclaration = (type) => (
    <>
      {listHealthQuestion
        .filter((i) => i.sectionLabel.toLowerCase() === type)
        .filter((i) => i.answerType === "NUMBER")
        .map((i, index) => {
          return (
            <>
              <div key={index} className="medical-declare">
                <p>
                  {i.mandatory &&
                    ((i.expectedAnswer !== "" &&
                      i.userValue !== i.expectedAnswer) ||
                      i.userValue === "") &&
                    missMatchQuestionAnswerMessage}
                  {i.question}
                </p>
                <Switch
                  value={i.userValue}
                  onChange={(value) => {
                    const data = [...listHealthQuestion].map((item) => {
                      return {
                        ...item,
                        userValue:
                          item.id === i.id
                            ? value === true
                              ? "yes"
                              : value === false
                              ? "no"
                              : item.userValue
                            : item.userValue,
                      };
                    });
                    setListHealthQuestion([...data]);
                  }}
                />
              </div>
            </>
          );
        })}
      {listHealthQuestion
        .filter((i) => i.sectionLabel.toLowerCase() === type)
        .filter((i) => i.answerType === "STRING")
        .map((i, index) => {
          return (
            <div key={index} className="medical-declare stringInput">
              <InputWithoutFormik
                type="text"
                label={i.question}
                value={i.userValue}
                onChange={(e) => {
                  const data = [...listHealthQuestion].map((item) => {
                    return {
                      ...item,
                      userValue:
                        item.id === i.id ? e.target.value : item.userValue,
                    };
                  });
                  setListHealthQuestion([...data]);
                }}
                placeholder={i.question}
              />
              {i.mandatory &&
                ((i.expectedAnswer !== "" &&
                  i.userValue !== i.expectedAnswer) ||
                  i.userValue === "") &&
                missMatchQuestionAnswerMessage}
            </div>
          );
        })}
    </>
  );
  const [modalName, setModalName] = useState("");
  const [confirmBoxes, setConfirmBoxes] = useState({
    terms: false,
    pre_exercise: false,
  });

  if (preSignupUnder18Loading) {
    return (
      <div className="singup-loading">
        <BeatLoader className="loading" color="white" size={20} />
      </div>
    );
  }
  return (
    <div>
      {modalName === "pre_exercise" && (
        <PreExerciseModal
          listQuestion={listHealthQuestion}
          onClose={() => setModalName("")}
        />
      )}
      {modalName === "terms_and_condition" && (
        <ModalTermsAndCondition
          onClose={() => setModalName("")}
        ></ModalTermsAndCondition>
      )}
      {modalName === "privacy_policy" && (
        <ModalPrivacyPolicy
          onClose={() => setModalName("")}
        ></ModalPrivacyPolicy>
      )}
      <Formik
        enableReinitialize
        initialValues={{
          emergencyContactEmail:
            memberData?.memberDetails.emergencyContactEmail ?? "",
          emergencyContactName:
            memberData?.memberDetails.emergencyContactName ?? "",
          emergencyContactRelation:
            memberData?.memberDetails.emergencyContactRelation ?? "",
          emergencyContactNumber:
            memberData?.memberDetails.emergencyContactNumber ?? "",
          address: memberData?.memberDetails.address ?? "",
          gender: memberData?.memberDetails.gender ?? "",
          postCode: memberData?.memberDetails.postCode ?? "",
          suburb: memberData?.memberDetails.suburb ?? "",
          state: memberData?.memberDetails.state ?? "",
        }}
        validationSchema={userEmergencySchema}
        validate={(values) => {
          setIsApproveTermsConditions(false);
          if (isHaveInjury) return healthCheck();
          else return true;
        }}
        onSubmit={async (values, OnSubmitProps) => {
          submitUserData(values, OnSubmitProps);
        }}
      >
        {(formik) => {
          return (
            <Form id="user-details-form">
              <div
                className="user-details-form mb-24
              "
              >
                <p>YOUR DETAILS</p>
                <div className="user-details-form-inputs">
                  <Input
                    type="name"
                    label="Address*"
                    name="address"
                    id="address"
                    autoComplete="off"
                  />
                  <Input
                    type="name"
                    label="Postcode*"
                    name="postCode"
                    id="postCode"
                    autoComplete="off"
                  />
                  <Input
                    type="name"
                    label="Suburb*"
                    name="suburb"
                    id="suburb"
                    autoComplete="off"
                  />
                  <Select
                    label="Gender*"
                    optionvalues={gender}
                    selecttype="array"
                    name="gender"
                    id="gender"
                    autoComplete="off"
                    className="select-element-value blue"
                  />
                  <Select
                    label="State*"
                    optionvalues={state}
                    selecttype="array"
                    name="state"
                    id="state"
                    autoComplete="off"
                    className="select-element-value blue"
                  />
                </div>
              </div>
              <div className="user-details-form">
                <p>HEALTH CHECK</p>
                <div className="health-check">
                  <p>
                    Do you have any injuries, disabilities or current
                    conditions? *
                  </p>
                  <div className="yesNOcontainer">
                    <Button
                      name="YES"
                      btntype="button"
                      className={`btn  btn-small ${
                        isHaveInjury ? "btn-primary" : "btn-transparent"
                      } fs-12`}
                      click={() => setIsHaveInjury(true)}
                    ></Button>{" "}
                    <Button
                      name="NO"
                      btntype="button"
                      className={`btn  btn-small ${
                        !isHaveInjury ? "btn-primary" : "btn-transparent"
                      } fs-12`}
                      click={() => setIsHaveInjury(false)}
                    ></Button>
                  </div>
                </div>
                {isHaveInjury && (
                  <div>
                    <div className="relevant-box">
                      <h3 className="term-header">
                        PLEASE SELECT THE RELEVANT BOX BELOW*
                      </h3>
                      <p className="note">
                        Note you must select one option, in order to proceed
                        with this gym membership.
                      </p>
                    </div>
                    <p className="healthQuestion-section-title">HEALTH CHECK</p>
                    <div className="medical-declare-container">
                      {RenderHealthQuestionAndMedicalDeclaration(
                        "health check"
                      )}
                    </div>
                    <p className="healthQuestion-section-title">
                      MEDICAL DECLARATION
                    </p>
                    <div className="medical-declare-container">
                      {RenderHealthQuestionAndMedicalDeclaration(
                        "medical declaration"
                      )}
                    </div>
                  </div>
                )}

                <div className="term-container">
                  <h3 className="term-header">TERMS AND CONDITION*</h3>

                  <div className="term-content">
                    <p>
                      I agree to the{" "}
                      <span
                        onClick={() => {
                          setModalName("terms_and_condition");
                        }}
                      >
                        terms & conditions
                      </span>{" "}
                      and the{" "}
                      <span
                        onClick={() => {
                          setModalName("privacy_policy");
                        }}
                      >
                        privacy policy
                      </span>{" "}
                      attached to my membership.*
                    </p>
                    <Checkbox
                      checked={confirmBoxes.terms}
                      onChange={(e) => {
                        setConfirmBoxes({
                          ...confirmBoxes,
                          terms: e.target.checked,
                        });
                      }}
                    />
                  </div>
                  <div className="term-content">
                    <p>
                      I confirm I meet the requirements from the{" "}
                      <span
                        className="exercise-check-list"
                        onClick={() => {
                          setModalName("pre_exercise");
                        }}
                      >
                        pre-exercise checklist
                      </span>
                    </p>
                    <Checkbox
                      checked={confirmBoxes.pre_exercise}
                      onChange={(e) => {
                        setConfirmBoxes({
                          ...confirmBoxes,
                          pre_exercise: e.target.checked,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="user-details-form form-2">
                <p>EMERGENCY CONTACT</p>
                <div className="user-details-form-inputs">
                  <Input
                    type="name"
                    label="Emergency name*"
                    name="emergencyContactName"
                    id="emergencyContactName"
                    autoComplete="off"
                    disabled={memberInformation.age < 18}
                  />
                  <Input
                    type="name"
                    label="Emergency relationship*"
                    name="emergencyContactRelation"
                    id="emergencyContactRelation"
                    autoComplete="off"
                    disabled={memberInformation.age < 18}
                  />
                  <Input
                    type="name"
                    label="Emergency email"
                    name="emergencyContactEmail"
                    id="emergencyContactEmail"
                    autoComplete="off"
                    disabled={memberInformation.age < 18}
                  />{" "}
                  <InternationalPhoneInput
                    title="Emergency Number*"
                    name="emergencyContactNumber"
                    id="emergencyContactNumber"
                    editable={memberInformation.age >= 18}
                  />
                </div>
              </div>
              <div>
                {memberInformation.age >= 18 ? (
                  <div
                    className={`button-navigate-container ${
                      step === 0 ? "align-right" : ""
                    }`}
                  >
                    {step !== 0 && (
                      <Button
                        click={() => {
                          step > 0 && setStepHandler(step - 1);
                        }}
                        name="prev"
                        btntype="button"
                        btnname="button"
                        className="btn btn-transparent btn-small"
                      />
                    )}
                    {!isApproveTermsConditions ? (
                      <Button
                        name="Send sms"
                        btntype="submit"
                        btnname="submit"
                        className="btn btn-transparent btn-small"
                        loading={preSignupOver18Loading}
                      />
                    ) : (
                      <Button
                        name="Next"
                        btntype="button"
                        btnname="button"
                        className="btn btn-transparent btn-small"
                        click={handleMoveStepNext}
                      />
                    )}
                  </div>
                ) : (
                  <NavigateButtonGroup />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

const missMatchQuestionAnswerMessage = (
  <div className="tooltip">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{
        color: "red",
      }}
    >
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 8V13"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9941 16H12.0031"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>{" "}
    <span className="tooltiptext">
      You must seek medical guidance/advice before proceeding.
    </span>
  </div>
);

export default Health;
