import "./viewSuspension.scss";
import Button from "../../../formUiElements/Button";
import InputWithoutFormik from "../../../formUiElements/input/InputWithoutFormik";
import Header from "../../../header/Header";
import Loading from "../../../Loading";
import Error from "../../../Error";
import { useNavigate, useParams } from "react-router-dom";
import {
  GET_MEMBER_CONTRACT_BY_CONTRACTID,
  GET_MEMBER_CONTRACT_SUSPENSION_BY_SUSEPNSIONID,
} from "../../../../gqloperations/queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useMemberContext } from "../../../../contexts/MemberContext";
import { BsCardText } from "react-icons/bs";
import { BiDetail } from "react-icons/bi";
import { Storage } from "aws-amplify";

const ViewSuspension = () => {
  const { suspensionId } = useParams();
  const navigate = useNavigate();
  const { createAuditLog } = useMemberContext();
  const {
    data: suspensiondata,
    loading: suspensionloading,
    error: suspensionerror,
  } = useQuery(GET_MEMBER_CONTRACT_SUSPENSION_BY_SUSEPNSIONID, {
    variables: { id: suspensionId },
  });
  useEffect(() => {
    if (suspensionId && suspensiondata) {
      createAuditLog(
        {
          ...suspensiondata?.getMemberContractSuspension,
        },
        {},
        suspensiondata?.getMemberContractSuspension?.memberId,
        "READ",
        "getMemberContractSuspension"
      );
    }
  }, [suspensionId, suspensiondata]);
  const handleCancel = () => {
    navigate(-1);
  };

  if (suspensionloading) {
    return <Loading />;
  }

  if (suspensionerror) {
    return <Error>Couldn't get suspension information</Error>;
  }
  const previewFile = async (link) => {
    const data = await Storage.get(link);
    const a = document.createElement("a");
    a.href = data;
    a.target = "_blank";
    a.click();
  };
  return (
    <div className="view-suspension-container">
      <Header pageTitle="View suspension" />
      <div className="tabs mt-32 ">
        <div className="tabs-list">
          <div className="tab-head fs-14 back-persianblue">
            {suspensiondata?.getMemberContractSuspension?.suspensionType !==
            "MEDICAL"
              ? "STANDARD"
              : "MEDICAL"}
          </div>
        </div>
      </div>
      <div className="view-suspension-content-container pd-18">
        <h3 className="fs-14 uppercase">Suspension details</h3>
        <div className="view-suspension-input">
          {suspensiondata && (
            <InputWithoutFormik
              type="date"
              label="Start Date"
              name="suspensionStartDateTime"
              autoComplete="off"
              id="suspensionStartDateTime"
              className=""
              defaultValue=""
              value={
                suspensiondata?.getMemberContractSuspension?.suspensionStartDateTime?.split(
                  "T"
                )[0]
              }
              readOnly
            />
          )}
          {suspensiondata && (
            <InputWithoutFormik
              type="date"
              label="End Date"
              name="suspensionEndDateTime"
              autoComplete="off"
              id="suspensionEndDateTime"
              className=""
              defaultValue=""
              value={
                suspensiondata?.getMemberContractSuspension?.suspensionEndDateTime?.split(
                  "T"
                )[0]
              }
              readOnly
            />
          )}
          {suspensiondata &&
            suspensiondata?.getMemberContractSuspension?.suspensionType !==
              "MEDICAL" && (<></>
              // <InputWithoutFormik
              //   type="text"
              //   label="Suspension cost"
              //   name="suspensionCost"
              //   autoComplete="off"
              //   id="suspensionCost"
              //   className=""
              //   value={`$${
              //     suspensiondata?.getMemberContractSuspension?.suspensionCost ??
              //     ""
              //   }`}
              //   readOnly
              // />
            )}
        </div>
        <div className="desc-row">
          <h3 className="fs-12 mb-12 text-gray">Reason</h3>
          {suspensiondata && (
            <textarea
              className="reason-box fs-12"
              id="suspensionReason"
              name="suspensionReason"
              defaultValue={
                suspensiondata?.getMemberContractSuspension?.suspensionReason
              }
            ></textarea>
          )}
        </div>
        {suspensiondata &&
          suspensiondata?.getMemberContractSuspension?.suspensionType ===
            "MEDICAL" && (
            <div className="info-card input-details-row column">
              <div className="certificate-records">
                <h3 className="fs-14 uppercase">Medical Certificate</h3>
                <table>
                  <thead>
                    <tr className="table-header fs-14">
                      <th className="fs-12 text-gray">
                        <span>File Name</span>
                      </th>

                      <th className="fs-12 text-gray">
                        <span>Upload Date</span>
                      </th>

                      <th></th>
                    </tr>
                    <tr className="table-body fs-14">
                      <td className="fs-12 text-gray">
                        <span>
                          {
                            suspensiondata?.getMemberContractSuspension
                              ?.imageUrl
                          }
                        </span>
                      </td>

                      <td className="fs-12 text-gray">
                        <span>
                          {suspensiondata?.getMemberContractSuspension?.createdAt
                            ?.split("T")[0]
                            .split("-")
                            .reverse()
                            .join("/")}
                        </span>
                      </td>

                      <td>
                        <div className="button-td">
                          <BiDetail
                            onClick={() => {
                              previewFile(
                                suspensiondata?.getMemberContractSuspension
                                  ?.imageUrl
                              );
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          )}
      </div>
      <div className="right-btn mt-18">
        <Button
          name="Close"
          btntype="reset"
          btnname="reset"
          className="btn btn-large btn-confirm"
          click={handleCancel}
        />
      </div>
    </div>
  );
};

export default ViewSuspension;
