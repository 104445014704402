import { utils, writeFile } from "xlsx";
import Button from "../formUiElements/Button";

export default function EXCELExport(props) {
  const exportToExcel = () => {
    const wb = utils.book_new(),
      ws = utils.aoa_to_sheet(props.data);
    utils.book_append_sheet(wb, ws, props.sheetName);
    writeFile(wb, props.fileName);
  };
  return (
    <Button
      name="excel"
      btntype="submit"
      btnname="icon-btn"
      className="btn btn-transparent fs-12"
      icon="excel_icon"
      click={exportToExcel}
    />
  );
}
