import "./clubSummary.scss";
import { useState } from "react";
import { EditButton } from "../../../formUiElements/editButton/EditButton";
import { toast } from "react-toastify";
import { UPDATE_LOCATION } from "../../../../gqloperations/mutations";
import { useMutation } from "@apollo/client";
import { useHandleError } from '../../../../hooks/useHandleError';

const ClubSummary = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedSummaryTxt, setUpdatedSummaryTxt] = useState();
  const [updateLocation] = useMutation(UPDATE_LOCATION);
  const handleError = useHandleError();

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    toast.warning("Update Cancelled");
    setIsEditing(false);
  };

  const handleChange = (event) => {
    setUpdatedSummaryTxt(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsEditing(false);
    if (updatedSummaryTxt && updatedSummaryTxt !== props.summaryText) {
      try {
        await updateLocation({
          variables: {
            input: {
              id: props.locationId,
              summary: updatedSummaryTxt,
            },
          },
          onCompleted: () => {
            props.getLocationById();
            toast.success("Saved");
          },
        });
      } catch (error) {
        handleError(`${error}`);
      }
    }
    setIsEditing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="summary-container pd-12">
      <>
        <div className="title-bar">
          <h3 className="fs-14 uppercase">Club Summary</h3>
          <span className="icon-container edit">
            <EditButton
              isEditing={isEditing}
              handleCancel={handleCancel}
              handleClick={handleClick}
            />
          </span>
        </div>
        <div className="summary">
          <div className="summary-message">
            <p className="summary-title fs-12 text-gray pb-12">Summary Text</p>
            <textarea
              className="summary-box fs-12"
              id="summary"
              name="summary"
              value={updatedSummaryTxt ?? props?.summaryText}
              onChange={handleChange}
              disabled={!isEditing}
            ></textarea>
          </div>
        </div>
      </>
    </form>
  );
};

export default ClubSummary;
