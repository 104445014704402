import "./card.scss";
import { MdOutlineArrowDropUp } from "react-icons/md";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsCurrencyDollar } from "react-icons/bs";
import { BiTrendingUp } from "react-icons/bi";
import { BiUser } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import { RiArrowUpDownFill } from "react-icons/ri";

import totalIcon from "../../assets/images/icons/total_icon.svg";
import averageIcon from "../../assets/images/icons/average_icon.svg";
import { Trend } from "../../components";

const Card = ({
  title,
  heading,
  value,
  fetchRecordError,
  icon,
  trend,
  textColor,
}) => {
  return (
    <div className="card">
      <div className="card-container">
        <div className="title">
          <div className="fs-14 value">{title}</div>
          <div className="updownicon">
            {/* {icon} */}
            {icon === "AiOutlineCalendar" && <AiOutlineCalendar />}
            {icon === "RiArrowUpDownFill" && (
              <RiArrowUpDownFill className="small-icon" />
            )}
            {icon === "BiUser" && <BiUser className="small-icon" />}
            {icon === "BsCurrencyDollar" && <BsCurrencyDollar />}
            {icon === "BiTrendingUp" && <BiTrendingUp />}
            {icon === "MdOutlineArrowDropUp" && <MdOutlineArrowDropUp />}
            {icon === "MdOutlineArrowDropDown" && <MdOutlineArrowDropDown />}
            {icon === "total" && <img src={totalIcon} alt="total icon" />}
            {icon === "future" && <BsPlus />}
            {icon === "average" && <img src={averageIcon} alt="average icon" />}
          </div>
        </div>
        <div className="fs-12 heading">{heading}</div>
        <div className={`fs-54 value mt-auto ${textColor}`}>
          <div> {value}</div>

          {trend && <Trend text="increasing" />}
        </div>
      </div>
    </div>
  );
};

export default Card;
