import chart_icon from "../../../assets/images/chart_icon.svg";

export default function Title({title, subtitle}) {
  return (
    <div className="title">
      <div className="fs-14 value">{title}</div>
      <div className="dropdown">
        <div className="fs-10 heading">{subtitle}</div>
        <img src={chart_icon} alt="chat" />
      </div>
    </div>
  )
}
