import "./header.scss";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Notifications from "../notifications/Notifications";
import ManualEntry from "../member/ManualEntry";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { IoIosNotificationsOutline } from "react-icons/io";
import { TbDoorEnter } from "react-icons/tb";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { AiOutlineSearch } from "react-icons/ai";
import { useLazyQuery } from "@apollo/client";
import { LIST_NOTIFICATIONS } from "../../gqloperations/queries";
import { isAfter, isSameDay } from "date-fns";
import { sortDesc } from "../../utils/sort";
import { CREATE_NOTIFICATION_SUBSCRIPTION } from "../../gqloperations/subscriptions";
import { API, graphqlOperation } from "aws-amplify";
import { useNotificationContext } from "../../contexts/NotificationContext";
import { useMemberContext } from "../../contexts/MemberContext";

const Header = (props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState({
    notification: false,
    manualEntry: false,
  });
  const notificationRef = useRef(null);
  const bellRef = useRef(null);
  const { loggedUser, permissions } = useAuthContext();
  const { backButton = true } = props;
  const [searchValue, setSearchValue] = useState("");
  const [notificationList, setNotificationList] = useState([]);
  const [numberOfNotifications, setNumberOfNotification] = useState(0);
  const { notification, dispatch } = useNotificationContext();
  const { sidebarLocationId } = useMemberContext();
  const handleGuest = () => {
    setShow({ ...show, manualEntry: !show.manualEntry });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!searchValue) return;
    navigate(`/dashboard/sitelink/${searchValue}`);
  };

  useEffect(() => {
    setNumberOfNotification(notification.length);
  }, [notification]);

  useEffect(() => {}, [notification]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (bellRef.current && bellRef.current.contains(event.target)) {
        return;
      }

      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target) &&
        show.notification
      ) {
        setShow({ ...show, notification: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  return (
    <div className="header">
      <div className="header-container">
        <div className="top">
          <div className="left">
            <span
              className={backButton ? "show" : "hide"}
              onClick={() => navigate(-1)}
            >
              <IoArrowBackCircleOutline />
            </span>
            <div className="fs-54">{props.pageTitle}</div>
          </div>
          {process.env.REACT_APP_ENV === "DEV" ? (
            <span className="red fs-54">{process.env.REACT_APP_ENV}</span>
          ) : (
            ""
          )}
          <div className="right">
            <div className="search-container">
              <form onSubmit={handleSubmit}>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="search"
                      id="search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder="Search The HUB"
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
              </form>
            </div>

            {permissions.includes("CanAddGuestEntryRemote") && (
              <span
                className="tooltip"
                // onClick={() => handleComponent("entry-btn")}
                title="This is a tooltip"
              >
                {/* <TbDoorEnter className="entry-icon"></TbDoorEnter> */}
                <span className="tooltip-text fs-10">Coming Soon</span>
              </span>
              // <span
              //   className="manual-entry-container"
              //   onClick={() => handleGuest()}
              // >
              //   {/* <p className="entry-text fs-8">guest entry</p> */}
              //   <TbDoorEnter className="entry-icon"></TbDoorEnter>
              // </span>
            )}

            <span
              className="tooltip notification-icon"
              ref={bellRef}
              onClick={() => {
                setShow({ ...show, notification: !show.notification });
              }}
            >
              <IoIosNotificationsOutline className="icon" />
              {notification.length ? (
                <div className="notification-dot">
                  {" "}
                  <span className="notification-count">
                    {numberOfNotifications}
                  </span>
                </div>
              ) : null}
            </span>

            <Link to="/dashboard/profile">
              <div className="profile-pic">
                <div>
                  {loggedUser?.getMember?.givenName.substring(0, 1)}{loggedUser?.getMember?.surname.substring(0, 1)}
                </div>
              </div>
            </Link>

            <>
              <span className="notification-popup" ref={notificationRef}>
                <Notifications
                  show={show}
                  setShow={setShow}
                  notificationListProps={notification}
                  setNumberOfNotification={setNumberOfNotification}
                  numberOfNotifications={numberOfNotifications}
                ></Notifications>
              </span>
            </>

            {show.manualEntry && (
              <>
                <span className="modal-backdrop">
                  <div className="modal">
                    <ManualEntry onClick={handleGuest} />
                  </div>
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
