import "react-phone-input-2/lib/style.css";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import { UPDATE_MEMBER } from "../../gqloperations/mutations";
import { userContactInfoSchema } from "../../validations/profileValidation";
import {
  state as states,
  country as countries,
} from "../../constants/commonConstant";
import { Formik, Form } from "formik";
import { AiOutlineEdit } from "react-icons/ai";
import {
  Select,
  Input,
  Button,
  InternationalPhoneInput,
} from "../../components";
import { useHandleError } from '../../hooks/useHandleError';

const UserContactInfo = ({ staffData, refetch, showEditBTN }) => {
  const [editable, setEditable] = useState(false);
  const [updateMember] = useMutation(UPDATE_MEMBER);
  const handleError = useHandleError();
  const {
    email,
    secondaryEmail,
    mobileNumber,
    address,
    suburb,
    state,
    postCode,
    country,
    memberId,
  } = staffData.getMember;
  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("userContactInfo").reset();
    setEditable(false);
  };
  const onSubmit = async (values) => {
    let updatedFields = {};
    for (let key in values) {
      if (
        String(values[key]).toUpperCase() !==
        String(staffData.getMember[key]).toUpperCase()
      ) {
        if (key === "secondaryEmail" && values[key] === "") {
          updatedFields[key] = null;
        } else {
          updatedFields[key] = values[key].toUpperCase();
        }
      }
    }
    try {
      await updateMember({
        variables: {
          input: { ...updatedFields, memberId },
        },
        onCompleted: () => {
          toast.success("Update successfully");
          setEditable(false);
          refetch();
        },
      });
    } catch (error) {
      handleError(`{error}`);
    }
  };
  return (
    <Formik
      initialValues={{
        email: email ? email : "",
        secondaryEmail: secondaryEmail ? secondaryEmail : "",
        mobileNumber,
        address,
        suburb,
        state,
        postCode,
        country,
      }}
      validationSchema={userContactInfoSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {(formik) => {
        return (
          <Form id="userContactInfo">
            <div className="contact-info-card">
              <div className="title-bar">
                <h3 className="fs-14 mb-18 uppercase">Contact Information</h3>
                {showEditBTN && (
                  <span className="icon-container edit">
                    {editable === false ? (
                      <>
                        <span
                          className="icon-container edit"
                          onClick={() => setEditable(true)}
                        >
                          <AiOutlineEdit />
                        </span>
                      </>
                    ) : (
                      <span className="icon-container">
                        <div className="sub-container save">
                          <Button
                            iconname="BiSave"
                            btntype="submit"
                            btnname="submit"
                            className="btn btn-edit save btn-lg fs-12 "
                          />
                        </div>
                        <div className="sub-container cancel">
                          <Button
                            iconname="AiOutlineCloseCircle"
                            btntype="reset"
                            btnname="reset"
                            className="btn  btn-edit cancel btn-lg fs-12"
                            click={handleCancel}
                          />
                        </div>
                      </span>
                    )}
                  </span>
                )}
              </div>
              <div className="details-section">
                <div className="mobile">
                  <InternationalPhoneInput
                    title="Mobile Number *"
                    name="mobileNumber"
                    id="mobileNumber"
                    editable={editable}
                  />
                </div>
                <div className="prim-email">
                  <Input
                    type="text"
                    label="Email *"
                    name="email"
                    id="email"
                    autoComplete="off"
                    disabled={true}
                  />
                </div>
                <div className="sec-email">
                  <Input
                    type="text"
                    label="Secondary email"
                    name="secondaryEmail"
                    id="secondaryEmail"
                    autoComplete="off"
                    disabled={editable ? false : true}
                    className={
                      editable
                        ? "select-element-value blue"
                        : "select-element-value"
                    }
                  />
                </div>
                <div className="address">
                  <Input
                    type="text"
                    label="Address"
                    name="address"
                    id="address"
                    autoComplete="off"
                    disabled={editable ? false : true}
                    className={
                      editable
                        ? "select-element-value blue"
                        : "select-element-value"
                    }
                  />
                </div>
                <div className="suburb">
                  <Input
                    type="text"
                    label="Suburb *"
                    name="suburb"
                    id="suburb"
                    autoComplete="off"
                    disabled={editable ? false : true}
                    className={
                      editable
                        ? "select-element-value blue"
                        : "select-element-value"
                    }
                  />
                </div>
                <div className="state">
                  <Select
                    label="State *"
                    optionvalues={states}
                    selecttype="array"
                    name="state"
                    id="state"
                    autoComplete="off"
                    disabled={editable ? false : true}
                    className={
                      editable
                        ? "select-element-value blue"
                        : "select-element-value"
                    }
                  />
                </div>
                <div className="post">
                  <Input
                    type="text"
                    label="Post Code *"
                    name="postCode"
                    id="postCode"
                    autoComplete="off"
                    disabled={editable ? false : true}
                    className={
                      editable
                        ? "select-element-value blue"
                        : "select-element-value"
                    }
                  />
                </div>
                <div className="country">
                  <Select
                    label="Country *"
                    optionvalues={countries}
                    selecttype="array"
                    name="country"
                    autoComplete="off"
                    disabled={editable ? false : true}
                    className={
                      editable
                        ? "select-element-value blue"
                        : "select-element-value"
                    }
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default UserContactInfo;
