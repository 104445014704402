import * as yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";

export const addMembershipSchema = yup.object().shape({
  membershipType: yup.string().required("Membership Type is required"),
  membershipLevel: yup.string().required("Membership Level is required"),
  membershipInput: yup.object().shape({
    membershipName: yup.string().required("Membership Name is required"),
    costPrice: yup
      .number()
      .min(0, "Cost price should not be less than 0")
      .required("Cost Price is required"),
    description: yup.string().required("Description is required"),
    joiningFee: yup
      .number()
      .min(0, "Joining fee should not be less than 0")
      .required("Joining Fee is required"),
    joiningFee2: yup.number().min(0, "Joining fee 2 should not be less than 0"),
    joiningFee3: yup.number().min(0, "Joining fee 3 should not be less than 0"),
  }),
  paymentFrequency: yup.object().shape({
    amount: yup
      .number()
      .integer()
      .min(1, "The number should be equal or greater than 1")
      .required("Payment Frequency is required"),
    type: yup.string().required("Please Select an Option"),
  }),
  // paymentDelay: yup.object().shape({
  //   amount: yup
  //     .number()
  //     .integer()
  //     .min(1, "The number should be equal or greater than 1")
  //     .required("Payment Delay is required"),
  //   type: yup.string().required("Please Select an Option"),
  // }),
  // contractLength: yup.object().shape({
  //   amount: yup
  //     .number()
  //     .integer()
  //     .min(1, "The number should be equal or greater than 1")
  //     .required("Contract Length is required"),
  //   type: yup.string().required("Please Select an Option"),
  // }),
  noticePeriod: yup.object().shape({
    amount: yup
      .number()
      .integer()
      .min(1, "The number should be equal or greater than 1")
      .required("Notice Period is required"),
    type: yup.string().required("Notice Period is required"),
  }),
  membershipActivationDate: yup.object().shape({
    startDateTime: yup.string().required("Start Date required"),
  }),
});

export const addMembershipActivationDateSchema = yup.object().shape({
  startDateTime: yup.string().required("Start Date is required"),
});

export const contactDetailsSchema = yup.object().shape({
  secondaryEmail: yup.string().email("Email should be in porper format"),
  mobileNumber: yup
    .string()
    .required("Phone number is required")
    .test("is-valid-number", "Invalid Number", (mobileNumber) => {
      return mobileNumber ? isValidPhoneNumber(mobileNumber) : true;
    }),

  workPhone: yup
    .string()
    .test("is-valid-number", "Invalid Number", (workPhone) => {
      return workPhone ? isValidPhoneNumber(workPhone) : true;
    }),
  homePhone: yup
    .string()
    .test("is-valid-number", "Invalid Number", (homePhone) => {
      return homePhone ? isValidPhoneNumber(homePhone) : true;
    }),
  address: yup.string(),
  // suburb: yup.string().required("Suburb is required"),
  // state: yup.string().required("State is required"),
  // postCode: yup.string().required("Post code is required"),
});

export const contactInformationSchema = yup.object().shape({
  emergencyContactNumber: yup
    .string()
    .test("is-valid-number", "Invalid Number", (emergencyContactNumber) => {
      return emergencyContactNumber
        ? isValidPhoneNumber(emergencyContactNumber)
        : true;
    }),
  emergencyContactNumber2: yup
    .string()
    .test("is-valid-number", "Invalid Number", (emergencyContactNumber2) => {
      return emergencyContactNumber2
        ? isValidPhoneNumber(emergencyContactNumber2)
        : true;
    }),
});

export const smsSchema = yup.object().shape({
  message: yup.string().required("Message is required"),
});
export const oustandingSchema = yup.object().shape({
  oustandingAmount: yup
    .number()
    .required("Oustanding amount is required")
    .positive("Oustanding amount must be greater than 0"),
  reason: yup.string().required("Reason is required"),
});
