import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { searchLinks } from "../../constants/commonConstant";
import Header from "../header/Header";

const SiteLink = () => {
  const { searchValue } = useParams();
  const [linkObj, setLinkObj] = useState([]);

  useEffect(() => {
    const filteredLinks = searchLinks.filter(
      (item) =>
        item?.link
          .toLowerCase()
          .includes(searchValue.toLowerCase().replace(/ /g, "")) ||
        item?.title
          .toLowerCase()
          .replace(/ /g, "")
          .includes(searchValue.toLowerCase().replace(/ /g, ""))
    );
    setLinkObj(filteredLinks);
  }, [searchValue]);

  return (
    <>
      <div className="site-links-container">
        <Header pageTitle="Site Links" />
        <div className="mt-32 pb-32">
          <div className="search-result-text flex-col mb-32">
            <h3 className="fs-14 uppercase">Search Results</h3>
            <p className="fs-12 text-gray">
              Your search for "{searchValue}" returned {linkObj.length} possible
              matches
            </p>
          </div>
          <div className="links-container flex-col">
            {linkObj.length === 0 ? (
              <div className="text-gray fs-12">
                {/* Didn't find what you were looking for? Refine your search and
                try again. */}
              </div>
            ) : (
              linkObj.map((item, index) => {
                return (
                  <div key={item?.link}>
                    <Link className="" to={item?.link}>
                      <p className="capitalize fs-14 link-title mb-8">{`${
                        index + 1
                      }. ${item?.title}`}</p>
                      <p className="fs-12 text-gray">{item?.link}</p>
                    </Link>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteLink;
