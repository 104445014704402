import React from "react";

const DoorAccessToast = ({ memberName }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.27738 23.6676V2.06763L18.2442 3.90084V21.7946L9.27738 23.6676ZM10.7121 13.4654C11.0525 13.4654 11.3285 13.1894 11.3285 12.849C11.3285 12.5085 11.0525 12.2325 10.7121 12.2325C10.3716 12.2325 10.0956 12.5085 10.0956 12.849C10.0956 13.1894 10.3716 13.4654 10.7121 13.4654Z"
          fill="#60A5FA"
        />
        <path
          d="M5.75586 3.84648H8.75929V5.07106H6.98044V20.4649H8.75929V21.6895H5.75586V3.84648Z"
          fill="#60A5FA"
        />
      </svg>{" "}
      Hello <span style={{ fontWeight: 600 }}>{memberName}</span>
    </div>
  );
};

export default DoorAccessToast;
