import React from "react";

const InputWithoutFormik = (props) => {
  return (
    <div className="form-group1 noselect">
      <label htmlFor="username" className={`${props.className} fs-12`}>
        {props.label}
      </label>
      <input
        type={props.type}
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        className={`${props.className} fs-12 noselect`}
        readOnly={props.readOnly}
      />
    </div>
  );
};

export default InputWithoutFormik;
