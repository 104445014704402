import "../../location/addlocation.scss";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import "./addVoucher.scss";
import { UPDATE_VOUCHER } from "../../../gqloperations/mutations";
import React from "react";
import copyIcon from "../../../assets/images/icons/copy-icon.png";
import { GET_VOUCHER_BY_ID } from "../../../gqloperations/queries";
import { useMemberContext } from "../../../contexts/MemberContext";
import { format } from "date-fns";
import { editVoucherSchema } from "../../../validations/Validation";
import { useHandleError } from "../../../hooks/useHandleError";
import {
  memberTypeForFixed,
  memberTypeForVisit,
} from "../../../constants/commonConstant";
import { Input, Header, Button, Select } from "../../../components";

const EditVoucher = () => {
  const navigate = useNavigate();
  const { idVoucher } = useParams();
  const { voucherType, sidebarLocationId } = useMemberContext();
  const handleError = useHandleError();
  const [updateVoucher, { loading: updateVoucherLoading }] =
    useMutation(UPDATE_VOUCHER);
  const DATE_OPTIONS = {
    weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    time: "numeric",
  };

  const { data: fetchVoucherData } = useQuery(GET_VOUCHER_BY_ID, {
    fetchPolicy: "no-cache",
    variables: {
      id: idVoucher,
    },
  });

  const onSubmit = (values, OnSubmitProps) => {
    let payload = {
      id: idVoucher,
      membershipId: values.membershipId,
      voucherCode: values.voucherCode,
      startDateTime: new Date(values.startDate),
      quantity: values.quantity,
      passFee: values.pass,
      type: voucherType,
      locationId: sidebarLocationId,
      activationFee: values.activation,
      createdBy: values.createdBy,
      updatedAt: new Date(),
      note: values.note,
      memberType: values.memberType,
      joiningFee: values.joiningFee,
    };
    if (values.endDate) {
      payload.endDateTime = new Date(
        new Date(values.endDate).setHours(23, 59, 59, 999)
      );
    }
    if (voucherType === "FIXED") {
      payload = {
        ...payload,
        cost: values.cost,
      };
    } else if (voucherType === "PERCENT") {
      payload = {
        ...payload,
        cost: values.cost,
      };
    } else if (voucherType === "VISIT_PASS") {
      payload = {
        ...payload,
        visit: values.visit,
      };
    }
    updateVoucher({
      variables: {
        input: payload,
      },
      onCompleted: () => {
        OnSubmitProps.setSubmitting(false);
        OnSubmitProps.resetForm();
        toast.success("Voucher Updated Successfully");
        navigate(-1);
      },
      onError: (error) => {
        handleError(`${error}`);
        return;
      },
    });
    OnSubmitProps.setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        voucherType: voucherType,
        used: fetchVoucherData?.getVoucher.used
          ? fetchVoucherData?.getVoucher.used
          : 0,
        createdBy: fetchVoucherData?.getVoucher.createdByDetail?.memberId,
        date: fetchVoucherData?.getVoucher.createdAt,
        code: fetchVoucherData?.getVoucher.voucherCode,
        membershipId: fetchVoucherData?.getVoucher?.membershipDetail?.id,
        quantity: fetchVoucherData?.getVoucher.quantity,
        endDate: fetchVoucherData?.getVoucher.endDateTime
          ? format(
              new Date(fetchVoucherData?.getVoucher.endDateTime),
              "yyyy-MM-dd"
            )
          : "",
        startDate: fetchVoucherData?.getVoucher.startDateTime
          ? format(
              new Date(fetchVoucherData?.getVoucher.startDateTime),
              "yyyy-MM-dd"
            )
          : "",
        pass: fetchVoucherData?.getVoucher.passFee,
        activation: fetchVoucherData?.getVoucher.activationFee,
        note: fetchVoucherData?.getVoucher.note,
        cost: fetchVoucherData?.getVoucher.cost,
        amount: fetchVoucherData?.getVoucher.paymentDelay?.amount,
        frequency: fetchVoucherData?.getVoucher.paymentDelay?.type,
        visit: fetchVoucherData?.getVoucher.visit,
        duration: fetchVoucherData?.getVoucher.duration,
        memberType: fetchVoucherData?.getVoucher.memberType,
        joiningFee: fetchVoucherData?.getVoucher.joiningFee,
      }}
      validationSchema={editVoucherSchema}
      onSubmit={(values, OnSubmitProps) => {
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <>
            <Header
              pageTitle={`${voucherType === "FIXED" ? "Edit fixed Voucher" : ""}
                                           ${
                                             voucherType === "PERCENT"
                                               ? "Edit % Voucher"
                                               : ""
                                           }
                                           ${
                                             voucherType === "VISIT_PASS"
                                               ? "Edit visit pass Voucher"
                                               : ""
                                           }
                                           ${
                                             voucherType === "COMPLIMENTARY"
                                               ? "Edit complimentary Voucher"
                                               : ""
                                           }`}
            />
            <Form>
              <div className="add-info-container">
                <div className="voucher-details-container loc-card">
                  <h3 className="title">Voucher information</h3>
                  <div className="voucher-grid-4 ">
                    <div className="detail address voucher-code-container">
                      <div className="detail address">
                        <h6 className="fs-12 text-gray">Voucher Code </h6>
                        <p className="fs-12">
                          {fetchVoucherData?.getVoucher.voucherCode}
                        </p>
                      </div>
                      <img
                        id="icon"
                        src={copyIcon}
                        alt="icon"
                        onClick={() =>
                          navigator.clipboard.writeText(formik.values.code)
                        }
                      />
                    </div>

                    <div className="detail city">
                      <h6 className="fs-12 text-gray">Created By</h6>
                      <p className="fs-12">
                        {
                          fetchVoucherData?.getVoucher?.createdByDetail
                            ?.givenName
                        }{" "}
                        {fetchVoucherData?.getVoucher?.createdByDetail?.surname}
                      </p>
                    </div>
                    <div className="detail city">
                      <h6 className="fs-12 text-gray">Date and time</h6>
                      <p className="fs-12">
                        {new Date(
                          fetchVoucherData?.getVoucher.createdAt
                        ).toLocaleTimeString("en-GB", DATE_OPTIONS)}
                      </p>
                    </div>
                    <div className="detail state">
                      <h6 className="fs-12 text-gray">Membership</h6>
                      <p className="fs-12">
                        {
                          fetchVoucherData?.getVoucher?.membershipDetail
                            ?.membershipName
                        }
                      </p>
                    </div>
                    {voucherType === "VISIT_PASS" && (
                      <div className="detail suburb">
                        {fetchVoucherData?.getVoucher?.voucherMembers?.items
                          .length ? (
                          <div className="detail">
                            <h6 className="fs-12 text-gray">Visit</h6>
                            <p className="fs-12">
                              {fetchVoucherData?.getVoucher?.visit}
                            </p>
                          </div>
                        ) : (
                          <Input
                            type="number"
                            label="Visit*"
                            name="visit"
                            id="visit"
                            autoComplete="off"
                          />
                        )}
                      </div>
                    )}
                    {voucherType !== "VISIT_PASS" && (
                      <div className="detail suburb">
                        <Input
                          type="number"
                          label="Quantity*"
                          name="quantity"
                          id="quantity"
                          autoComplete="off"
                        />
                      </div>
                    )}
                    <div className="detail post">
                      {!fetchVoucherData?.getVoucher?.used ? (
                        <Input
                          type="date"
                          label="Start Date*"
                          name="startDate"
                          id="startDate"
                          autoComplete="off"
                          min={
                            formik?.values?.startDate
                              ? formik.values.startDate
                              : ""
                          }
                          max={
                            formik?.values?.endDate ? formik.values.endDate : ""
                          }
                        />
                      ) : (
                        <>
                          <h6 className="fs-12 text-gray">Start Date</h6>
                          <p className="fs-12">
                            {formik?.values?.startDate
                              .split("-")
                              .reverse()
                              .join("/")}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="detail ">
                      <Input
                        type="date"
                        label="End Date*"
                        name="endDate"
                        id="endDate"
                        autoComplete="off"
                        min={formik.values?.startDate}
                      />
                    </div>
                    {(voucherType === "FIXED" || voucherType === "PERCENT") && (
                      <div className="detail ">
                        {fetchVoucherData?.getVoucher?.voucherMembers?.items
                          .length ? (
                          <div className="detail ">
                            <h6 className="fs-12 text-gray">
                              {voucherType === "PERCENT"
                                ? "% Discount"
                                : voucherType === "FIXED"
                                ? "PIF/DD (fortnightly)*"
                                : "Cost*"}
                            </h6>
                            <p className="fs-12">
                              {voucherType !== "PERCENT" && "$"}
                              {fetchVoucherData?.getVoucher.cost}
                            </p>{" "}
                          </div>
                        ) : (
                          <Input
                            type="number"
                            label={
                              voucherType === "PERCENT"
                                ? "% Discount"
                                : voucherType === "FIXED"
                                ? "FIF/DD (fortngihtly)*"
                                : "Cost*"
                            }
                            name="cost"
                            id="cost"
                            autoComplete="off"
                          />
                        )}
                      </div>
                    )}
                    {voucherType === "VISIT_PASS" && (
                      <div className="detail suburb">
                        {fetchVoucherData?.getVoucher?.voucherMembers?.items
                          .length ? (
                          <div className="detail">
                            <h6 className="fs-12 text-gray">Quantity</h6>
                            <p className="fs-12">
                              {fetchVoucherData?.getVoucher.quantity}
                            </p>
                          </div>
                        ) : (
                          <Input
                            type="number"
                            label="Quantity"
                            name="quantity"
                            id="quantity"
                            autoComplete="off"
                          />
                        )}
                      </div>
                    )}
                    {voucherType === "COMPLIMENTARY" && (
                      <div className="detail ">
                        <h6 className="fs-12 text-gray">Duration</h6>
                        <p className="fs-12">
                          {fetchVoucherData?.getVoucher.duration === 0 && "0"}
                          {fetchVoucherData?.getVoucher.duration === 1 &&
                            "1 DAY"}
                          {fetchVoucherData?.getVoucher.duration === 7 &&
                            "1 WEEK"}
                          {fetchVoucherData?.getVoucher.duration === 14 &&
                            "2 WEEKS"}
                          {fetchVoucherData?.getVoucher.duration === 21 &&
                            "3 WEEKS"}
                          {fetchVoucherData?.getVoucher.duration === 30 &&
                            "1 MONTH"}
                          {fetchVoucherData?.getVoucher.duration === 365 &&
                            "1 YEAR"}
                          {fetchVoucherData?.getVoucher.duration > 30 &&
                            fetchVoucherData?.getVoucher.duration < 365 &&
                            `${
                              fetchVoucherData?.getVoucher.duration / 30
                            } MONTHS`}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="voucher-grid-4 mb-12">
                    {voucherType === "VISIT_PASS" && (
                      <div className="detail ">
                        {fetchVoucherData?.getVoucher?.voucherMembers?.items
                          .length ? (
                          <div className="detail">
                            <h6 className="fs-12 text-gray">Cost</h6>
                            <p className="fs-12">
                              ${fetchVoucherData?.getVoucher.cost}
                            </p>{" "}
                          </div>
                        ) : (
                          <Input
                            type="number"
                            label="Cost"
                            name="cost"
                            id="cost"
                            autoComplete="off"
                          />
                        )}
                      </div>
                    )}

                    <div className="detail ">
                      {fetchVoucherData?.getVoucher?.voucherMembers?.items
                        .length ? (
                        <div className="detail ">
                          <h6 className="fs-12 text-gray">Joining Fee*</h6>
                          <p className="fs-12">
                            ${fetchVoucherData?.getVoucher.joiningFee}
                          </p>
                        </div>
                      ) : (
                        <Input
                          type="number"
                          label="Joining Fee*"
                          name="joiningFee"
                          id="joiningFee"
                          autoComplete="off"
                        />
                      )}
                    </div>
                    <div className="detail ">
                      {fetchVoucherData?.getVoucher?.voucherMembers?.items
                        .length ? (
                        <div className="detail ">
                          <h6 className="fs-12 text-gray">Pass Fee*</h6>
                          <p className="fs-12">
                            ${fetchVoucherData?.getVoucher.passFee}
                          </p>
                        </div>
                      ) : (
                        <Input
                          type="number"
                          label="Pass Fee*"
                          name="pass"
                          id="pass"
                          autoComplete="off"
                        />
                      )}
                    </div>
                    <div className="detail ">
                      {fetchVoucherData?.getVoucher?.voucherMembers?.items
                        .length ? (
                        <div className="detail ">
                          <h6 className="fs-12 text-gray">Activation Fee*</h6>
                          <p className="fs-12">
                            ${fetchVoucherData?.getVoucher.activationFee}
                          </p>
                        </div>
                      ) : (
                        <Input
                          type="number"
                          label="Activation Fee*"
                          name="activation"
                          id="activation"
                          autoComplete="off"
                        />
                      )}
                    </div>
                    {voucherType === "FIXED" && (
                      <div className="detail">
                        <div className="notice-period input-select-title">
                          <div className="detail ">
                            <h6 className="fs-12 text-gray">Delay payment</h6>
                            <p className="fs-12">
                              {
                                fetchVoucherData?.getVoucher?.paymentDelay
                                  ?.amount
                              }{" "}
                              {fetchVoucherData?.getVoucher?.paymentDelay?.type}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="detail">
                      {!fetchVoucherData?.getVoucher?.used ? (
                        <Select
                          label="Member type*"
                          optionvalues={
                            voucherType === "FIXED" || voucherType === "PERCENT"
                              ? memberTypeForFixed
                              : memberTypeForVisit
                          }
                          selecttype="array"
                          name="memberType"
                          autoComplete="off"
                          className="select-element-value mb-12"
                        />
                      ) : (
                        <>
                          <h6 className="fs-12 text-gray  ">Member Type</h6>
                          <p className="fs-12">
                            {fetchVoucherData?.getVoucher?.memberType}
                          </p>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="desc-row note">
                    <h6 className="fs-12 text-gray mb-12">Notes</h6>
                    <p className="fs-12">{fetchVoucherData?.getVoucher.note}</p>
                  </div>
                </div>
              </div>
              <div className="save-btn right-btn">
                <Button
                  loading={updateVoucherLoading}
                  name={"Save"}
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-confirm btn-large fs-12"
                  disabled={updateVoucherLoading ? true : false}
                />

                <Link to="/dashboard/membership">
                  <Button
                    name="Cancel"
                    btntype="button"
                    btnname="cancel"
                    className="btn btn-deny btn-large fs-12"
                  />
                </Link>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
export default EditVoucher;
