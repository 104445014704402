const GenderNumber = ({className, number, color, gender}) => {
  return (
    <div className="genderNumber">
      <span >{number}</span>
      <span className={className}></span>
      <span className="fs-8">{gender}</span>
    </div>
  );
}

export default GenderNumber;