import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_TOTAL_CHURN } from "../../../gqloperations/queries";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";
import { ReportGeneratorFormWithMonth } from "../../../pages/index";
import "./executiveReportsTables.scss";
import { BeatLoader } from "react-spinners";
import useCustomFetch from "../../../hooks/useCustomFetch";
import { API } from "../../../utils/API";
import ReportCategory from "../ReportCategory";
import { toast } from "react-toastify";
import { useHandleError } from '../../../hooks/useHandleError';

export default function TotalChurnReport() {
  const [generate, setGenerate] = useState(false);
  const [tableData, setTableData] = useState([]);
  const handleError = useHandleError();
  const [endPoint, setEndPoint] = useState();
  const [params, setParams] = useState();
  const { data, isLoading, error } = useCustomFetch(
    endPoint && endPoint,
    params && params
  );
  const handleGenerate = (values, onSubmitProps) => {
    setGenerate(true);
    setEndPoint(API.executiveChurnReport);
    const parameters = {
      brandId: values.brand,
      paymentType: values.reportCategory,
      locationType: values.additionalSelect,
    };
    if (values.location !== "-1") parameters.locationId = values.location;

    setParams(parameters);
  };
  const [currentItem, setCurrentItem] = useState(-1);
  useEffect(() => {
    if (error && endPoint) {
      setTableData([]);
      handleError(error?.response?.data?.message ?? "Error when fetching api");
    } else if (data) setTableData(data.data.data);
  }, [data, isLoading, error]);

  return (
    <>
      <ReportGeneratorFormWithMonth
        brandAndLocation
        onlyStartDate
        noTimezone
        startDateRequired
        noDefaultDate
        additionalSelect={{
          option: [
            { id: "ALL", name: "ALL" },
            { id: "FRANCHISEE", name: "FRANCHISEE" },
            { id: "CORPORATE", name: "CORPORATE" },
          ],
          label: "Location type",
        }}
        optionFirst={[
          { id: "ALL", name: "ALL MEMBERS" },
          { id: "DD", name: "DD MEMBERS" },
          { id: "PIF", name: "PIF MEMBERS" },
        ]}
        handleSubmit={handleGenerate}
      />
      {isLoading && (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      )}
      {!isLoading && generate && (
        <div className="actions-row report-tables mt-24 mb-12">
          {/* <ExportGroup
            data={tableData ? tableData : []}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
          /> */}
          {/* <div className="search-container">
            <form onSubmit={onSearchBarSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="search"
                    id="search"
                    value={searchBarInputVal}
                    onChange={handleChange}
                    placeholder="Search by brand name"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div> */}
        </div>
      )}

      {/* <p className="totalCount">TOTAL: {tableData.length}</p> */}

      {!isLoading && (generate || tableData) && (
        <div className="">
          {/* <Table
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            hasFooter={true}
          /> */}

          {tableData?.length > 0 && (
            <div
              style={{
                padding: "13px 18px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                background: "#1E1E1E",
                color: "#848484",
              }}
            >
              Location{" "}
              {/* <span
              //   style={{
              //     marginLeft: "auto",
              //     width: "100%",
              //     display: "inline-block",
              //   }}
              >
                churn
              </span> */}
            </div>
          )}

          {tableData?.length > 0 &&
            tableData?.map((i, index) => (
              <div key={index} onClick={() => setCurrentItem(index)}>
                <div
                  style={{
                    padding: "13px 18px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #4B4B4B",
                    borderTop: "1px solid #4B4B4B",
                    fontSize: "12px",
                    color: "white",
                    alignItems: "center",
                  }}
                >
                  {i?.location?.name}

                  {currentItem !== index ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.7">
                        <path
                          d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7Z"
                          fill="white"
                        />
                        <path
                          d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"
                          fill="white"
                          fill-opacity="0.7"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.7">
                        <path d="M7 11H17V13H7V11Z" fill="white" />
                        <path
                          d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"
                          fill="white"
                          fill-opacity="0.7"
                        />
                      </g>
                    </svg>
                  )}
                </div>
                {currentItem === index && (
                  <>
                    <div
                      style={{
                        borderTop: "1px solid #4B4B4B",
                        borderBottom: "1px solid #4B4B4B",
                        padding: "7px 20px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "12px",
                        background: "#1E1E1E",
                        color: "white",
                      }}
                    >
                      Month{" "}
                      <span
                      //   style={{
                      //     marginLeft: "auto",
                      //     width: "100%",
                      //     display: "inline-block",
                      //   }}
                      >
                        Churn
                      </span>
                    </div>
                    {i?.churnPrevious12Months?.length > 0 &&
                      i?.churnPrevious12Months?.map((i) => (
                        <div
                          style={{
                            padding: "4px 21px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #292929",
                            borderTop: "1px solid #292929",
                            // border: "1px solid #292929",
                            fontSize: "12px",
                            background: "#1E1E1E",
                            color: "white",
                          }}
                        >
                          {i?.month}
                          <span>{(Number(i?.churn) * 100).toFixed(2)}%</span>
                        </div>
                      ))}
                  </>
                )}
              </div>
            ))}
        </div>
      )}
    </>
  );
}
