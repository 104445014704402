import { areIntervalsOverlapping } from "date-fns";

const checkDateRangesOverlap = (aRange, bRange) => {
  const aStart = new Date(aRange.start);
  const aEnd = new Date(aRange.end);

  const bStart = new Date(bRange.start);
  const bEnd = new Date(bRange.end);

  return areIntervalsOverlapping(
    { start: aStart, end: aEnd },
    { start: bStart, end: bEnd }
  );
};

export default checkDateRangesOverlap;
