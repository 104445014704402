export const getPercentage = (value) => {
  const numericValue = Number(value) * 100;
  const numberString = numericValue.toString();
  const decimalIndex = numberString.indexOf(".");
  const digitsAfterDecimal =
    decimalIndex === -1 ? 0 : numberString.length - decimalIndex - 1;
  const percentage =
    digitsAfterDecimal === 0
      ? numericValue
      : digitsAfterDecimal === 1
      ? numericValue.toFixed(1)
      : numericValue.toFixed(2);
  const percentageStr = percentage.toString();
  return percentageStr?.endsWith("0") && decimalIndex !== -1
    ? `${percentageStr.substring(0, percentageStr.length - 1)} %`
    : `${percentageStr} %`;
};
