import "../addMembership.scss";
import { Button, Checkbox } from "../../../components";
const Payment = (props) => {
  const { setIsAccept } = props;
  return (
    <>
      <div className="user-details-form">
        <p>PAYMENT DETAILS</p>
        <div className="">
          <h3 className="term-header">TERMS AND CONDITION*</h3>
          <p className="term-content">
            <span>If you have chosen to pay by Direct Debit.</span> By
            acknowledging and submitting this Agreement, you authorise us to
            debit all Membership Fees from the nominated credit card via
            instalments in advance. The Agreement outlines the applicable Terms
            and Conditions to your direct debit request. You agree to these
            terms and conditions unless you notify us otherwise before the first
            direct debit day. If you would like to pay via your bank account,
            you can add this within the Member Portal once you have joined.
          </p>
          <p className="term-content">
            <span>
              If you have chosen to pay your membership upfront and in full,
            </span>{" "}
            you authorise us to debit/ charge all Membership Fees upfront and in
            advance from the nominated credit card upon submitting your
            membership application.
          </p>
          <div className="checkbox-agree">
            <Checkbox onChange={(e) => setIsAccept(e.target.checked)} />
            <p>
              The Agreement outlines the applicable Terms and Conditions to your
              payment request. <br />
              You agree to these terms and conditions.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
