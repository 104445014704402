// amritnew;
let awsmobileValue = {
  DEV: {
    aws_project_region: "ap-southeast-2",
    aws_appsync_graphqlEndpoint:
      "https://wetulywggrdmjpo6gzbc5svgie.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    aws_appsync_graphql_subscription_endpoint:
      "wss://wetulywggrdmjpo6gzbc5svgie.appsync-realtime-api.ap-southeast-2.amazonaws.com/graphql",
    aws_appsync_region: "ap-southeast-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_cognito_identity_pool_id:
      "ap-southeast-2:02fdc8b8-5324-4e71-89c4-0b6af764bba6",
    aws_cognito_region: "ap-southeast-2",
    aws_user_pools_id: "ap-southeast-2_jiU2IgzC4",
    aws_user_pools_web_client_id: "156fr33105vqta5a0pss5u6s3s",
    oauth: {},
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
    aws_user_files_s3_bucket:
      "vivaamplifyd4c57bfeb2f0411bb1bd75977277518f90458-amritnew",
    aws_user_files_s3_bucket_region: "ap-southeast-2",
  },
  UAT: {
    aws_project_region: "ap-southeast-2",
    aws_mobile_analytics_app_id: "d0d42421b07e48cb91aed42ee21cf1a9",
    aws_mobile_analytics_app_region: "ap-southeast-2",
    Analytics: {
      AWSPinpoint: {
        appId: "d0d42421b07e48cb91aed42ee21cf1a9",
        region: "ap-southeast-2",
      },
    },
    aws_appsync_graphqlEndpoint:
      "https://r4t5cwwyhngx5fxmmdpwil3su4.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    aws_appsync_region: "ap-southeast-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_appsync_apiKey: "da2-nglhcvkkyrbyjaxjjoluc4wtw4",
    aws_cloud_logic_custom: [
      {
        name: "vivaamplifyREST",
        endpoint:
          "https://pe7d21zzda.execute-api.ap-southeast-2.amazonaws.com/uat",
        region: "ap-southeast-2",
      },
    ],
    aws_cognito_identity_pool_id:
      "ap-southeast-2:189815cb-9278-4f61-a0ce-0fce1e555df5",
    aws_cognito_region: "ap-southeast-2",
    aws_user_pools_id: "ap-southeast-2_6g1AhJhFo",
    aws_user_pools_web_client_id: "5mp7n20o7ubau6t1tpjn7a7iqa",
    oauth: {},
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_configuration: "OPTIONAL",
    aws_cognito_mfa_types: ["TOTP"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["PHONE_NUMBER"],
    aws_user_files_s3_bucket:
      "vivaamplifyd4c57bfeb2f0411bb1bd75977277518f133116-uat",
    aws_user_files_s3_bucket_region: "ap-southeast-2",
  },
  PROD: {
    aws_project_region: "ap-southeast-2",
    aws_mobile_analytics_app_id: "f4fd23cbd94a4950a47f8d50b4a0bf50",
    aws_mobile_analytics_app_region: "ap-southeast-2",
    Analytics: {
      AWSPinpoint: {
        appId: "f4fd23cbd94a4950a47f8d50b4a0bf50",
        region: "ap-southeast-2",
      },
    },
    aws_appsync_graphqlEndpoint:
      "https://kiyhskfv4fcjln4reuchf2da4a.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    aws_appsync_region: "ap-southeast-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_cloud_logic_custom: [
      {
        name: "vivaamplifyREST",
        endpoint:
          "https://merilcuzsg.execute-api.ap-southeast-2.amazonaws.com/prod",
        region: "ap-southeast-2",
      },
    ],
    aws_cognito_identity_pool_id:
      "ap-southeast-2:8e6266bb-8af2-473f-a2ca-697e37eaf8c2",
    aws_cognito_region: "ap-southeast-2",
    aws_user_pools_id: "ap-southeast-2_PQjXE3bPg",
    aws_user_pools_web_client_id: "q13bjf9l998jcgl1bmqpuhps2",
    oauth: {},
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_configuration: "OPTIONAL",
    aws_cognito_mfa_types: ["TOTP"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["PHONE_NUMBER"],
    aws_user_files_s3_bucket:
      "vivaamplifyd4c57bfeb2f0411bb1bd75977277518f163415-prod",
    aws_user_files_s3_bucket_region: "ap-southeast-2",
  },
};

const awsmobile =
  process.env.REACT_APP_ENV === "prod"
    ? awsmobileValue.PROD
    : process.env.REACT_APP_ENV === "uat"
    ? awsmobileValue.UAT
    : awsmobileValue.DEV;

export default awsmobile;
