import "./doorAccess.scss";
import { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { useLocationContext } from "../../../../contexts/LocationContext";
import {
  DELETE_ACCESS_POINT,
  DELETE_DOOR_ACCESS_HOURS,
} from "../../../../gqloperations/mutations";
import formatTime from "../../../../utils/formatTime";
import { Modal, Button, StatusModal } from "../../../../components";
import AddTimePeriodRow from "./AddTimePeriodRow";
import EditMessage from "./EditMessage";
import { useHandleError } from '../../../../hooks/useHandleError';

const DoorAccess = (props) => {
  const [addTime, setAddTime] = useState({});
  const [messageOpen, setMessageOpen] = useState({ doorId: "", status: false });
  const { state, getLocationById } = useLocationContext();
  const [deleteHours] = useMutation(DELETE_DOOR_ACCESS_HOURS);
  const [deleteAccess] = useMutation(DELETE_ACCESS_POINT);
  const [componentName, setComponentName] = useState("");
  const [curRow, setCurRow] = useState({});
  const handleError = useHandleError();

  const handleComponent = (componentname, rowObj) => {
    setComponentName(componentname);
    setCurRow(rowObj);
  };
  const modalClose = () => {
    setComponentName("");
    setCurRow({});
  };

  if (!state.location) return <p>Can not get location data.</p>;
  const doors = state.location.getLocation.accessPoints.items;
  const getTimeTableData = (timePeriods) => {
    const mondayAccessTimes = timePeriods.filter((i) => i.day === "MONDAY");
    const tuesdayAccessTimes = timePeriods.filter((i) => i.day === "TUESDAY");
    const wednesdayAccessTimes = timePeriods.filter(
      (i) => i.day === "WEDNESDAY"
    );
    const thursdayAccessTimes = timePeriods.filter((i) => i.day === "THURSDAY");
    const fridayAccessTimes = timePeriods.filter((i) => i.day === "FRIDAY");
    const saturdayAccessTimes = timePeriods.filter((i) => i.day === "SATURDAY");
    const sundayAccessTimes = timePeriods.filter((i) => i.day === "SUNDAY");
    const tableData = [
      {
        id: "monday",
        day: "MONDAY",
        abbreviation: "MON",
        timePeriod: mondayAccessTimes,
      },
      {
        id: "tuesday",
        day: "TUESDAY",
        abbreviation: "TUE",
        timePeriod: tuesdayAccessTimes,
      },
      {
        id: "wednesday",
        day: "WEDNESDAY",
        abbreviation: "WED",
        timePeriod: wednesdayAccessTimes,
      },
      {
        id: "thursday",
        day: "THURSDAY",
        abbreviation: "THU",
        timePeriod: thursdayAccessTimes,
      },
      {
        id: "friday",
        day: "FRIDAY",
        abbreviation: "FRI",
        timePeriod: fridayAccessTimes,
      },
      {
        id: "saturday",
        day: "SATURDAY",
        abbreviation: "SAT",
        timePeriod: saturdayAccessTimes,
      },
      {
        id: "sunday",
        day: "SUNDAY",
        abbreviation: "SUN",
        timePeriod: sundayAccessTimes,
      },
    ];
    return tableData;
  };
  const handleAddTime = (doorId, dayId) => {
    setAddTime({ doorId, dayId });
  };
  const handleMessage = (doorId) => {
    if (doorId === messageOpen.doorId) {
      setMessageOpen({ ...messageOpen, status: !messageOpen.status });
    } else {
      setMessageOpen({ doorId: doorId, status: true });
    }
  };
  const handleDeleteDoorClick = async (rowObj) => {
    try {
      await deleteAccess({
        variables: {
          input: {
            id: rowObj.id,
          },
        },
        onCompleted: () => {
          getLocationById();
          toast.success("Access Point Deleted Successfully");
          modalClose();
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };

  const handleDeleteClick = async (rowObj) => {
    try {
      await deleteHours({
        variables: {
          input: {
            id: rowObj.id,
          },
        },
        onCompleted: () => {
          getLocationById();
          toast.success("Time Period Deleted Successfully");
          modalClose();
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };
  return (
    <div>
      <div className="right-btn">
        <Link to="/dashboard/location/addDoorAccess">
          <Button
            name="add door access"
            btntype="submit"
            btnname="submit"
            className="btn btn-primary fs-12"
          />
        </Link>
      </div>

      <div className="access-comms-table">
        <div className="table">
          <table>
            <thead>
              <tr className="table-header fs-14">
                <th>Door Location</th>
                <th>Activation Days</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {doors.map((door) => (
                <tr key={door.id}>
                  <td colSpan="3">
                    <div>
                      <table>
                        <tbody>
                          <tr className="fs-12 main-rows">
                            <td
                              className={
                                messageOpen.status
                                  ? "center msg no-underline"
                                  : "center msg "
                              }
                            >
                              <p className="fs-12">{door.accessPointname}</p>
                              <Button
                                name="Message"
                                btntype="button"
                                className="btn btn-lg btn-transparent "
                                click={() => handleMessage(door.id)}
                              />
                            </td>
                            <td
                              className={
                                messageOpen.status
                                  ? "center time-table no-underline"
                                  : "center time-table "
                              }
                            >
                              <table>
                                <tbody>
                                  {getTimeTableData(
                                    door.accessPointTimes.items
                                  ).map((day) => (
                                    <tr key={day.id}>
                                      <td className="day-cell">
                                        <div
                                          className={
                                            day.timePeriod.length > 0
                                              ? "day bg-blue"
                                              : "day bg-gray"
                                          }
                                        >
                                          {day.abbreviation}
                                        </div>
                                      </td>
                                      <td>
                                        <table align="center inner-table">
                                          <tbody>
                                            {day.timePeriod
                                              .slice()
                                              .sort((a, b) => {
                                                const result =
                                                  a.openTime > b.openTime
                                                    ? 1
                                                    : -1;
                                                return result;
                                              })
                                              .map((timePeriod) => (
                                                <tr
                                                  className="time-period"
                                                  key={timePeriod.id}
                                                >
                                                  <td>
                                                    <div className="time-period-value">
                                                      {formatTime(
                                                        timePeriod.openTime
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="time-period-value">
                                                      {formatTime(
                                                        timePeriod.closeTime
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td className="delete-timeslot">
                                                    <MdDeleteOutline
                                                      className="delete-icon"
                                                      onClick={() =>
                                                        handleComponent(
                                                          "delete-template",
                                                          {
                                                            id: timePeriod.id,
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                </tr>
                                              ))}
                                          </tbody>
                                        </table>
                                        {addTime.doorId === door.id &&
                                          addTime.dayId === day.id && (
                                            <AddTimePeriodRow
                                              accessPointId={door.id}
                                              day={day.day}
                                              setAddTime={setAddTime}
                                              getLocationById={getLocationById}
                                              timePeriods={day.timePeriod}
                                            />
                                          )}
                                      </td>
                                      <td className="add-time-col">
                                        <span className="button-td">
                                          <AiOutlinePlusCircle
                                            onClick={() =>
                                              handleAddTime(door.id, day.id)
                                            }
                                          />
                                          <p
                                            className="fs-10 uppercase"
                                            onClick={() =>
                                              handleAddTime(door.id, day.id)
                                            }
                                          >
                                            Add Hours
                                          </p>
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </td>
                            <td
                              className={
                                messageOpen.status
                                  ? "center btn-col no-underline "
                                  : "center btn-col "
                              }
                            >
                              <Button
                                name="delete door"
                                btntype="submit"
                                btnname="submit"
                                className="btn btn-primary btn-lg fs-10"
                                onClick={() =>
                                  handleComponent("delete-door", {
                                    id: door.id,
                                  })
                                }
                              />
                            </td>
                          </tr>
                          {door.id === messageOpen.doorId &&
                            messageOpen.status && (
                              <tr className="message-tr">
                                <td colSpan={3}>
                                  <EditMessage
                                    id={door.id}
                                    swipeMessageText={door.swipeMessageText}
                                    errorMessage={door.errorMessage}
                                    isMessageActive={door.isMessageActive}
                                    messageFrequency={door.messageFrequency}
                                  />
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {componentName && (
            <Modal>
              {componentName === "delete-template" && (
                <StatusModal
                  curRow={curRow}
                  modalHeaderTxt={"Delete Door Hours"}
                  modalParaText={"Are you sure you want to delete these hours?"}
                  handleStatusClick={handleDeleteClick}
                  modalClose={modalClose}
                />
              )}
              {componentName === "delete-door" && (
                <StatusModal
                  curRow={curRow}
                  modalHeaderTxt={"DELETE DOOR"}
                  modalParaText={"Are you sure you want to delete the door?"}
                  handleStatusClick={handleDeleteDoorClick}
                  modalClose={modalClose}
                />
              )}
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

export default DoorAccess;
