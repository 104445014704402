import "./instructors.scss";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineSearch, AiOutlineEdit } from "react-icons/ai";
import { useState, useEffect } from "react";
import { MdOutlineDelete } from "react-icons/md";
import { LIST_CLASS_INSTRUCTORS } from "../../gqloperations/queries";
import { DELETE_CLASS_INSTRUCTOR } from "../../gqloperations/mutations";
import { toast } from "react-toastify";
import { useQuery, useMutation } from "@apollo/client";
import isAfter from "date-fns/isAfter";
import { useMemberContext } from "../../contexts/MemberContext";
import { format } from "date-fns";
import { Modal, StatusModal, Table, Header, Button } from "../../components";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";

const Instructors = () => {
  let navigate = useNavigate();
  const { permissions } = useAuthContext();
  const [tableData, setTableData] = useState();
  const { createLog } = useMemberContext();
  const [isPaginating, setIsPaginating] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [nextToken, setNextToken] = useState(null);
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [columnFilter, setColumnFilter] = useState([]);
  const [searchbarInputVal, setSearchbarInputVal] = useState("");
  const [componentName, setComponentName] = useState("");
  const [curRow, setCurRow] = useState({});

  const [deleteclassInstructor] = useMutation(DELETE_CLASS_INSTRUCTOR);
  const { data: instructordata, refetch } = useQuery(LIST_CLASS_INSTRUCTORS, {
    // comment it out for testing pagination
    // variables: {
    //   limit: 2,
    // },
  });
  const handleEditClick = (id) => {
    navigate(`/dashboard/instructors/editInstructor/${id}`);
  };
  const handleDeleteClick = (rowObj) => {
    let values = {
      id: rowObj.id,
    };

    deleteclassInstructor({
      variables: {
        input: values,
      },

      onCompleted: () => {
        toast.success("Deleted successfully");
        modalClose();
        refetch();
      },
    });
  };
  const handleNextToken = () => {
    if (nextToken) {
      setIsPaginating(true);
      refetch({ nextToken: nextToken });
    }
  };

  const sortByCreatedAtDesc = (arr) => {
    return arr.sort((a, b) => {
      const result = isAfter(new Date(a.createdAt), new Date(b.createdAt));
      return result ? -1 : 1;
    });
  };

  const handleChange = (event) => {
    const searchVal = event?.target?.value?.toUpperCase();
    setSearchbarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const onSearchbarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchbarInputVal));
  };
  const handleComponent = (componentname, rowObj) => {
    setComponentName(componentname);
    setCurRow(rowObj);
  };
  const modalClose = () => {
    setComponentName("");
    setCurRow({ id: "", isActive: false });
  };
  useEffect(() => {
    createLog("CLICKED Instructor tab on the sidebar", "sidebar");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (instructordata) {
      const { items, nextToken } = instructordata?.listClassInstructors;

      if (tableData && isPaginating) {
        const sortedCombinedItems = sortByCreatedAtDesc([
          ...tableData,
          ...items,
        ]);
        setTableData(sortedCombinedItems);
      } else {
        const sortedItems = sortByCreatedAtDesc([...items]);
        setTableData(sortedItems);
      }

      setIsPaginating(false);
      setNextToken(nextToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructordata]);

  const columns = [
    {
      accessorFn: (row) => {
        return row?.surname && row?.givenName
          ? `${row?.surname}, ${row?.givenName}`
          : "";
      },
      header: () => {
        return <span> SURNAME, NAME</span>;
      },

      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "surname",
    },
    {
      accessorKey: "dob",
      header: "DOB",
      cell: (info) =>
        info?.getValue()
          ? format(new Date(info?.getValue()), "dd/MM/yyyy")
          : "",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "dob",
    },
    {
      accessorKey: "instructorMobileNumber",
      header: "MOBILE",
      cell: (info) => info.getValue() ?? "",
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "instructorEmail",
      header: "EMAIL",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      id: "row-actions",
      cell: (props) => (
        <span className="button-td">
          {permissions.includes("CanEditInstructor") && (
            <AiOutlineEdit
              onClick={() => {
                handleEditClick(props.row.original.id);
              }}
            />
          )}
          {permissions.includes("CanDeleteInstructor") && (
            <MdOutlineDelete
              onClick={() => {
                handleComponent("delete-template", {
                  id: props.row.original.id,
                });
              }}
              className="delete-icon"
            />
          )}
        </span>
      ),
    },
  ];
  return (
    <div>
      <Header pageTitle="Instructors" />
      <div className="instructors-container">
        <div className="actions-row mt-32 ">
          <div className="btn-group "></div>
          <div className="left-elements ">
            <div className="search-container">
              <form onSubmit={onSearchbarSubmit}>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="search"
                      id="search"
                      placeholder="Instructor Name"
                      value={searchbarInputVal}
                      onChange={handleChange}
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/* // <div className="right-btn">
            //   <Link to="/dashboard/instructors/addInstructor">
            //     <Button
            //       name="Add Instructor"
            //       btntype="submit"
            //       btnname="submit"
            //       className="btn btn-primary btn-xlarge-wide"
            //     />
            //   </Link>
            // </div> */}
            {permissions.includes("CanAddInstructor") && (
              <div className="right-btn">
                <Link to="/dashboard/instructors/addInstructor">
                  <Button
                    name="Add Instructor"
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-primary btn-xlarge-wide"
                  />
                </Link>
              </div>
            )}
          </div>
        </div>
        {componentName && (
          <Modal>
            {componentName === "delete-template" && (
              <StatusModal
                curRow={curRow}
                modalHeaderTxt={"Delete Instructor"}
                modalParaText={
                  "Are you sure you want to delete this instructor?"
                }
                handleStatusClick={handleDeleteClick}
                modalClose={modalClose}
              />
            )}
          </Modal>
        )}
        <div className="instructors-table">
          {tableData && (
            <Table
              onRowClick={(i) => {
                const id = i.id;
                handleEditClick(id);
                createLog(`Clicked ${i.givenName} ${i.surname}`);
              }}
              data={tableData}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              columns={columns}
              columnFilter={columnFilter}
              setColumnFilter={setColumnFilter}
              rowsPerPage={10}
              handleNextToken={handleNextToken}
              nextToken={nextToken}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              sorting={sorting}
              setSorting={setSorting}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Instructors;
