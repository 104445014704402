import "./updateAmount.scss";
import React from "react";
import { Formik, Form } from "formik";
import Button from "../formUiElements/Button";
import { format, addDays, startOfDay, addMonths, isBefore } from "date-fns";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import Input from "../formUiElements/input/Input";
import { sortAsc } from "../../utils/sort";
import { addFrequencyToDate } from "../../utils/calculateTimePeriod";

const CancelMembershipModal = (props) => {
  const today = new Date();

  const formattedToday = format(new Date(), "EEE d M yy");

  const twentyEightdaysFromToday = addDays(today, 29);

  const minDate = formatInTimeZone(
    twentyEightdaysFromToday,
    props.timezone,
    "yyyy-MM-dd"
  );
  const sixMonthFromToday = addMonths(new Date(), 6);
  const maxDate = () => {
    // if contract is pending we cancel immidiate
    if (props.contract.isPending) {
      return format(new Date(), "yyyy-MM-dd");
    }

    //spread the billing
    const billingsItems = props?.contract.billings;
    //sorted billing base on debit date and future billing only
    const sortedBilling = sortAsc(billingsItems, "debitDate").filter(
      (i) => new Date(i.date) > new Date().valueOf()
    );
    //define the last billing
    const lastBilling =
      sortedBilling?.length > 0
        ? sortedBilling[sortedBilling.length - 1]
        : null;

    if (props.contract.recurring)
      if (
        lastBilling &&
        isBefore(
          utcToZonedTime(
            new Date(lastBilling?.debitDate),
            props?.contract.timezone
          ),
          sixMonthFromToday
        )
      ) {
        return format(
          utcToZonedTime(
            new Date(lastBilling?.debitDate),
            props?.contract.timezone
          ),
          "yyyy-MM-dd"
        );
      } else
        return formatInTimeZone(
          sixMonthFromToday,
          props.timezone,
          "yyyy-MM-dd"
        );
    else
      return format(
        utcToZonedTime(
          props?.contract.endDateTime
            ? props?.contract.endDateTime
            : sixMonthFromToday,
          props?.contract.timezone
        ),
        "yyyy-MM-dd"
      );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        date: `${formattedToday.substring(0, 3)} ${formattedToday
          .substring(4)
          .replaceAll(" ", "/")}`,

        dateOfCancellation: props.contract.isPending
          ? format(new Date(), "yyyy-MM-dd")
          : "",
        reason: "",
      }}
      onSubmit={(values, OnSubmitProps) => {
        props.handleSubmit(values, OnSubmitProps);
      }}
      validate={(values, props) => {
        const { dateOfCancellation, reason } = values;

        let errors = {};

        if (!dateOfCancellation) {
          const errorText = "Please select cancellation date";
          errors.dateOfCancellation = errorText;
        }

        if (!reason) {
          const errorText = "Please provide a reason for cancellation";
          errors.reason = errorText;
        }

        return errors;
      }}
    >
      {(formik) => {
        return (
          <Form className="status-modal cancel-membership pd-32">
            <h3 className="fs-35 uppercase ">{props.headerText}</h3>
            <div className="input-container">
              {!props?.contract?.isPending ? (
                <div className="amount-container pb-18">
                  {/* <Input
                  type="text"
                  label="Date"
                  name="date"
                  id="date"
                  readOnly
                /> */}
                  <div className="flex-col">
                    <label
                      className="fs-12 text-gray"
                      htmlFor="dateOfCancellation"
                    >
                      Date of cancellation
                    </label>

                    <Input
                      type="date"
                      name="dateOfCancellation"
                      autoComplete="off"
                      min={new Date().toISOString().split("T")[0]}
                      // min={startOfDay(new Date())}
                      max={maxDate()}
                    />
                  </div>
                </div>
              ) : (
                <p className="fs-12 text-gray mb-12">
                  NOTE: Cancellation of PENDING memberships are processed
                  immediately.
                </p>
              )}
              <div className="desc-row">
                <label className="fs-12 text-gray" htmlFor="reason">
                  Reason *
                </label>
                <textarea
                  name="reason"
                  id="reason"
                  onChange={formik.handleChange}
                  value={formik.values.reason}
                ></textarea>
                {formik.errors.reason && formik.touched.reason && (
                  <div className="fs-12 error">{formik.errors.reason}</div>
                )}
              </div>
            </div>
            <div className="btn-group">
              <Button
                loading={props.loading ? props.loading : formik.isSubmitting}
                name={"Save"}
                btntype="submit"
                btnname="submit"
                className="btn btn-confirm btn-small"
                disabled={formik.isSubmitting ? true : false}
              />
              <Button
                name="Cancel"
                btntype="submit"
                btnname="submit"
                className="btn btn-deny btn-small"
                click={props.cancelFn}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CancelMembershipModal;
