// import { useIdleTimer } from "react-idle-timer";
// import { useState, useEffect } from "react";
// import { AuthContext } from "../../contexts";
// import Modal from "../modal/Modal";

const IdleTimerContainer = () => {
  // const { handleSignOut } = useContext(AuthContext);
  // const timeout = 10000;
  // const [remaining, setRemaining] = useState(timeout);

  // const handleOnIdle = () => {
  //   handleSignOut();
  // };

  // const { getRemainingTime } = useIdleTimer({
  //   timeout,
  //   onIdle: handleOnIdle,
  // });

  // useEffect(() => {
  //   setRemaining(getRemainingTime());
  //   setInterval(() => {
  //     setRemaining(getRemainingTime());
  //   }, 1000);
  // }, []);

  return (
    <div>
      {/* {remaining < 5000 && remaining >= 1 && (
        <Modal>
          <p>You are about to loged out {Math.ceil(remaining / 1000)}</p>
        </Modal>
      )} */}
    </div>
  );
};

export default IdleTimerContainer;
