import "./locationHours.scss";
import { useState } from "react";
import AccessTimeTable from "./AccessTimeTable";
import { useLocationContext } from "../../../../contexts/LocationContext";

const LocationHours = () => {
  const { state, getLocationById } = useLocationContext();
  const [tab, setTab] = useState("peak_hours");
  if (!state.location) return <p>wrong</p>;
  const locationIs24Hours = state.location.getLocation.is24Hour;
  const locationHours = state.location.getLocation.locationAccessTimes.items;

  const getTableData = (type) => {
    const typeData = locationHours.filter((i) => i.type === type);
    const mondayAccessTimes = typeData
      .filter((i) => i.day === "MONDAY")
      .slice()
      .sort((a, b) => {
        const result = a.openTime > b.openTime ? 1 : -1;
        return result;
      });
    const tuesdayAccessTimes = typeData
      .filter((i) => i.day === "TUESDAY")
      .slice()
      .sort((a, b) => {
        const result = a.openTime > b.openTime ? 1 : -1;
        return result;
      });
    const wednesdayAccessTimes = typeData
      .filter((i) => i.day === "WEDNESDAY")
      .slice()
      .sort((a, b) => {
        const result = a.openTime > b.openTime ? 1 : -1;
        return result;
      });
    const thursdayAccessTimes = typeData
      .filter((i) => i.day === "THURSDAY")
      .slice()
      .sort((a, b) => {
        const result = a.openTime > b.openTime ? 1 : -1;
        return result;
      });
    const fridayAccessTimes = typeData
      .filter((i) => i.day === "FRIDAY")
      .slice()
      .sort((a, b) => {
        const result = a.openTime > b.openTime ? 1 : -1;
        return result;
      });
    const saturdayAccessTimes = typeData
      .filter((i) => i.day === "SATURDAY")
      .slice()
      .sort((a, b) => {
        const result = a.openTime > b.openTime ? 1 : -1;
        return result;
      });
    const sundayAccessTimes = typeData
      .filter((i) => i.day === "SUNDAY")
      .slice()
      .sort((a, b) => {
        const result = a.openTime > b.openTime ? 1 : -1;
        return result;
      });
    const tableData = [
      { id: "monday", day: "MONDAY", timePeriod: mondayAccessTimes },
      { id: "tuesday", day: "TUESDAY", timePeriod: tuesdayAccessTimes },
      { id: "wednesday", day: "WEDNESDAY", timePeriod: wednesdayAccessTimes },
      { id: "thursday", day: "THURSDAY", timePeriod: thursdayAccessTimes },
      { id: "friday", day: "FRIDAY", timePeriod: fridayAccessTimes },
      { id: "saturday", day: "SATURDAY", timePeriod: saturdayAccessTimes },
      { id: "sunday", day: "SUNDAY", timePeriod: sundayAccessTimes },
    ];
    return tableData;
  };
  return (
    <div className="">
      <div className="hours-options mb-12">
        <button
          className={
            tab === "peak_hours" ? "btn btn-primary " : "btn btn-primary gray "
          }
          onClick={() => setTab("peak_hours")}
        >
          peak hours
        </button>
        <button
          className={
            tab === "staff" ? "btn btn-primary " : "btn btn-primary gray "
          }
          onClick={() => setTab("staff")}
        >
          staffed hours
        </button>
        <button
          className={
            tab === "openClose" ? "btn btn-primary" : "btn btn-primary gray"
          }
          onClick={() => setTab("openClose")}
        >
          open hours
        </button>
        <button
          className={
            tab === "creche" ? "btn btn-primary" : "btn btn-primary gray"
          }
          onClick={() => setTab("creche")}
        >
          creche hours
        </button>
      </div>
      {tab === "peak_hours" && (
        <AccessTimeTable
          type="PEAK_HOURS"
          title="PEAK HOURS"
          getLocationById={getLocationById}
          tableData={getTableData("PEAK_HOURS")}
          tab={tab}
        />
      )}
      {tab === "staff" && (
        <AccessTimeTable
          type="STAFF"
          title="STAFFED HOURS"
          getLocationById={getLocationById}
          tableData={getTableData("STAFF")}
          tab={tab}
        />
      )}
      {tab === "openClose" && (
        <AccessTimeTable
          type="OPENCLOSE"
          title="OPENING HOURS"
          is24Hours={locationIs24Hours}
          getLocationById={getLocationById}
          tableData={getTableData("OPENCLOSE")}
          tab={tab}
        />
      )}
      {tab === "creche" && (
        <AccessTimeTable
          type="CREACHE"
          title="CRECHE HOURS"
          getLocationById={getLocationById}
          tableData={getTableData("CREACHE")}
          tab={tab}
        />
      )}
    </div>
  );
};

export default LocationHours;
