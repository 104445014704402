import "./select.scss";
import { useField } from "formik";

const Select = (props) => {
  const [field, meta] = useField(props);
  return (
    <div className="select-element">
      {props.label && props.label !== "" && (
        <label htmlFor={props.name} className={`fs-12 ${props.labelclass}`}>
          {props.label}{" "}
        </label>
      )}
      {props.selecttype === "object" && (
        <>
          <div className="flex-col">
            <select className={props.className} {...field} {...props}>
              <option value="">Select an Option</option>
              {props.optionvalues.map((val, index) => (
                <option value={val[props.objectvalue]} key={index}>
                  {val[props.objectname]}{" "}
                  {props.objectname2 ? val[props.objectname2] : ""}
                </option>
              ))}
            </select>
            <div className="error fs-12">
              {meta.error && meta.touched ? meta.error : null}
            </div>
          </div>
        </>
      )}

      {props.selecttype === "array" && (
        <>
          <div className="flex-col">
            <select className={props.className} {...field} {...props}>
              <option value="">Select an Option</option>

              {props.optionvalues.map((val, index) => (
                <option value={val} key={index}>
                  {val}
                </option>
              ))}
            </select>
            <div className="error fs-12">
              {meta.error && meta.touched ? meta.error : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Select;
