import "./guestEntryLog.scss";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_GUEST_ENTRY_LOG_BY_MEMBER_ID } from "../../gqloperations/queries";
import { BsCardText } from "react-icons/bs";
import { Table, Header } from "../../components";
import { AiOutlineSearch } from "react-icons/ai";
import { useMemberContext } from "../../contexts/MemberContext";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";

export default function GuestEntryLogs() {
  const [globalFilter, setGlobalFilter] = useState("");
  const { createLog } = useMemberContext();
  const [sorting, setSorting] = useState([]);
  const [tableData, setTableData] = useState();
  const { loggedUser } = useAuthContext();
  const [nextToken, setNextToken] = useState(null);
  const [isPaginating, setIsPaginating] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [searchbarInputVal, setSearchbarInputVal] = useState("");

  const {
    loading: guestEntryLoading,
    error: guestEntryError,
    data: guestEntryData,
    refetch,
  } = useQuery(GET_GUEST_ENTRY_LOG_BY_MEMBER_ID, {
    variables: { memberId: loggedUser?.getMember?.memberId },
    onCompleted: (data) => {},
  });
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchbarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    setGlobalFilter(String(searchbarInputVal));
  };

  const handleNextToken = () => {
    if (guestEntryData?.getGuestEntryByMemberId?.nextToken) {
      setIsPaginating(true);
      refetch({
        nextToken: guestEntryData?.getGuestEntryByMemberId?.nextToken,
      });
    }
  };

  useEffect(() => {
    createLog("CLICKED Guest Entry Log tab on the sidebar", "sidebar");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (guestEntryData) {
      if (tableData?.length >= 0) {
        if (tableData && isPaginating) {
          setTableData([
            ...tableData,
            ...guestEntryData?.getGuestEntryByMemberId?.items,
          ]);
        } else {
          setTableData(guestEntryData?.getGuestEntryByMemberId?.items);
        }
        setIsPaginating(false);
      } else {
        setTableData(guestEntryData?.getGuestEntryByMemberId?.items);
      }

      setNextToken(guestEntryData?.getGuestEntryByMemberId?.nextToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestEntryData]);

  const columns = [
    {
      accessorFn: (row) => `${row?.locationDetails?.name}`,
      header: "LOCATION NAME",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "locationName",
    },
    {
      accessorKey: "firstName",
      header: "FIRST NAME",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "firstName",
    },
    {
      accessorKey: "lastName",
      header: "LAST NAME",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "lastName",
    },
    {
      accessorKey: "contactNumber",
      header: "CONTACT NUMBER",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "reasonForEntry",
      header: "ENTRY REASON",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
    },

    {
      accessorFn: (row) =>
        `${row?.staffDetails?.givenName}, ${row?.staffDetails?.surname}`,
      header: "CREATED BY",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
    },
    // {
    //   id: "row-actions",
    //   cell: (props) => (
    //     <BsCardText
    //       onClick={() => {
    //         const id = props.row.original.memberId;
    //         const email = props.row.getAllCells()[5].getValue();
    //         handleRowClick(id, email);
    //       }}
    //     />
    //   ),
    // },
  ];

  if (guestEntryLoading) return <p>loading...</p>;
  if (guestEntryError) return <p>error</p>;
  return (
    <>
      <Header pageTitle="Guest Entry Logs" />
      <div className="entry-log-container">
        <div className="search-container mt-32">
          <form onSubmit={onSubmit}>
            <div className="search-bar">
              <div className="search-bar-container">
                <input
                  className="fs-10"
                  type="text"
                  name="search"
                  id="search"
                  value={searchbarInputVal}
                  onChange={handleChange}
                  placeholder="location, customer name, contact number"
                />
                <button type="submit">
                  <AiOutlineSearch />
                </button>
              </div>
            </div>
          </form>
        </div>
        {tableData && (
          <Table
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            handleNextToken={handleNextToken}
            nextToken={nextToken}
          />
        )}
      </div>
    </>
  );
}
