import "./managementStaff.scss";
import { useMemberContext } from "../../../contexts/MemberContext";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";
import { BeatLoader } from "react-spinners";
import React, { useState } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import Button from "../../formUiElements/Button";
import SearchableSelect from "../../formUiElements/select/SearchableSelect";

import { SET_OLD_VALUES } from "../../../actions";

//icons
import { AiOutlineEdit } from "react-icons/ai";

const ManagementStaff = (props) => {
  const { permissions } = useAuthContext();
  const { memberdata, onSubmit, state } = props;
  const { dispatch } = useMemberContext();

  const [isActive, setIsActive] = useState(true);

  const handleIsActive = () => {
    setIsActive(false);
    dispatch({
      type: SET_OLD_VALUES,
      payload: {
        managerId: state.location.getLocation.managerId ?? "",
        assistantManagerId: state.location.getLocation.assistantManagerId ?? "",
        areaManagerId: state.location.getLocation.areaManagerId ?? "",
      },
    });
  };

  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("management-staff-form").reset();
    setIsActive(true);
  };
  const formatValue = (option) => {
    const formatedValue = `${option.givenName
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase())} ${option.surname
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase())}`;
    return formatedValue;
  };

  return (
    <>
      {state.location && memberdata && memberdata.getMemberByType && (
        <Formik
          enableReinitialize
          initialValues={{
            manager: "",
            assistantManager: "",
            areaManager: "",
          }}
          onSubmit={(values, OnSubmitProps) => {
            let updatedValues = { id: state.id };
            const selectedAreaManager = memberdata?.getMemberByType?.items.find(
              (option) => formatValue(option) === values.areaManager
            );
            const selectedAsstManager = memberdata?.getMemberByType?.items.find(
              (option) => formatValue(option) === values.assistantManager
            );
            const selectedManager = memberdata?.getMemberByType?.items.find(
              (option) => formatValue(option) === values.manager
            );
            if (selectedAreaManager) {
              updatedValues = {
                ...updatedValues,
                areaManagerId: selectedAreaManager.memberId,
              };
            }
            if (selectedManager) {
              updatedValues = {
                ...updatedValues,
                managerId: selectedManager.memberId,
              };
            }
            if (selectedAsstManager) {
              updatedValues = {
                ...updatedValues,
                assistantManagerId: selectedAsstManager.memberId,
              };
            }
            onSubmit(updatedValues, OnSubmitProps);
            setIsActive(true);
          }}
        >
          {(formik) => {
            return (
              <div className="management-staff club-info-container">
                <Form id="management-staff-form">
                  {isActive ? (
                    <>
                      <div className="title-bar">
                        <h3 className="fs-14">Management Staff</h3>
                        {permissions.includes("CanEditLocation") && (
                          <span
                            className="icon-container edit"
                            onClick={handleIsActive}
                          >
                            <AiOutlineEdit />
                          </span>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="title-bar">
                      <h3 className="fs-14">Management Staff</h3>
                      <span className="icon-container ">
                        <div className="sub-containers">
                          <div className="sub-container save">
                            {formik.isSubmitting && (
                              <Button
                                loading={formik.isSubmitting}
                                name={<BeatLoader color="white" />}
                                btntype="submit"
                                btnname="submit"
                                className="btn btn-edit save btn-lg fs-12"
                              />
                            )}
                            {!formik.isSubmitting && (
                              <Button
                                loading={formik.isSubmitting}
                                iconname="BiSave"
                                btntype="submit"
                                btnname="submit"
                                className="btn btn-edit save btn-lg fs-12"
                              />
                            )}
                          </div>
                          <div className="sub-container cancel">
                            <Button
                              iconname="AiOutlineCloseCircle"
                              btntype="reset"
                              btnname="reset"
                              className="btn btn-icon btn-lg fs-12"
                              click={handleCancel}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  )}

                  <div className="details-container ">
                    <div className="detail fs-12 no-underline">
                      <SearchableSelect
                        initialValue={state.location.getLocation.areaManagerId}
                        identifierKey="memberId"
                        displayKey1="givenName"
                        displayKey2="surname"
                        optionList={memberdata.getMemberByType.items}
                        label="Area Manager"
                        name="areaManager"
                        listId="areaManager-list"
                        disabled={isActive ? true : false}
                        placeholder="Search and select a staff"
                      />
                    </div>

                    <div className="detail fs-12 no-underline">
                      <SearchableSelect
                        initialValue={state.location.getLocation.managerId}
                        optionList={memberdata.getMemberByType.items}
                        identifierKey="memberId"
                        displayKey1="givenName"
                        displayKey2="surname"
                        label="Club Manager"
                        name="manager"
                        listId="manager-list"
                        placeholder="Search and select a staff"
                        disabled={isActive ? true : false}
                      />
                    </div>
                    <div className="detail fs-12 no-underline">
                      <SearchableSelect
                        initialValue={
                          state.location.getLocation.assistantManagerId
                        }
                        placeholder="Search and select a staff"
                        identifierKey="memberId"
                        displayKey1="givenName"
                        displayKey2="surname"
                        optionList={memberdata.getMemberByType.items}
                        label="Club Assistant Manager"
                        name="assistantManager"
                        listId="assistant-manager-list"
                        disabled={isActive ? true : false}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default ManagementStaff;
