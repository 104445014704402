import React from "react";
import { Amplify, Auth } from "aws-amplify";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import awsconfig from "./aws-exports";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";

Amplify.configure(awsconfig);

const getToken = async () => {
  try {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    return jwtToken;
  } catch (e) {
    return "";
  }
};

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  };
});
const httpLink = createHttpLink({
  uri: awsconfig.aws_appsync_graphqlEndpoint,
});
const wsLink = new WebSocketLink({
  uri: awsconfig.aws_appsync_graphql_subscription_endpoint,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: async () => {
      try {
        const jwtToken = (await Auth.currentSession())
          .getIdToken()
          .getJwtToken();
        return {
          headers: {
            Authorization: jwtToken ? `Bearer ${jwtToken}` : "",
          },
        };
      } catch (error) {
        return {};
      }
    },
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});
function GraphQlClient({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default GraphQlClient;
