import "./reportgeneratorformwithmonth.scss";
import { Formik, Form } from "formik";
import DatePicker from "../../components/formUiElements/input/DatePicker";
import Select from "../../components/formUiElements/select/Select";
import Button from "../../components/formUiElements/Button";
import { endOfDay, format, parseISO, startOfDay } from "date-fns";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useMemberContext } from "../../contexts/MemberContext";
import { useRef, useState } from "react";

const ReportGeneratorForm = (props) => {
  const {
    onlyStartDate,
    monthSelect,
    brandAndLocation,
    noDefaultSelect,
    noTimezone,
    noDefaultDate,
    noDate,
    additionalSelect,
    //snapshot report
    reportSnapShot,
    //there are 3 location type null|CORPORATE|FRANCHISEE
    //to use pass CORPORATE | FRANCHISEE the null value will be filtered out if one of these passed through
    locationType,
    locationTypeSelection,
  } = props;

  const { locationAccess } = useAuthContext();
  const { sidebarBrandId, sidebarLocationId, sidebarLocationName } =
    useMemberContext();

  const [selectedLocation, setSelectedLocation] = useState({
    id: sidebarLocationId,
    name: sidebarLocationName,
  });

  const currentLocationValue = useRef("");
  const handleClick = (formikData) => {
    if (selectedLocation.name !== "") {
      currentLocationValue.current = selectedLocation;
    }
    setSelectedLocation({ id: "", name: "" });
    formikData.setValues({
      ...formikData.values,
      location: "",
      memberLocationName: "",
    });
  };

  const handleBlur = (formikData) => {
    if (selectedLocation.name === "") {
      setSelectedLocation(currentLocationValue.current);
      formikData.setValues({
        ...formikData.values,
        location: currentLocationValue.current.id,
        memberLocationName: currentLocationValue.current.name,
      });
    }
  };

  const locationOptionRender = (value) => {
    // value is formik.values
    let locationOptionList = [];
    if (locationType) {
      //if the props passed locationType exp: CORPORATE|FRANCHISEE
      //we have to filter the location base on the locationType
      locationOptionList = locationAccess?.brands
        ?.filter((i) => i.brandId === value.brand)[0]
        ?.locations.filter((location) => {
          return Boolean(locationType)
            ? location.locationType === locationType
            : true;
        });
    } else if (
      locationTypeSelection &&
      value.locationTypeValue &&
      value.locationTypeValue !== "ALL"
    ) {
      //if the props pass no locationType exp: CORPORATE|FRANCHISEE
      //but it passed prop to activate the locationType selection
      //we have to filter the location base on the locationTypeSelection
      locationOptionList = locationAccess?.brands
        ?.filter((i) => i.brandId === value.brand)[0]
        ?.locations.filter((location) => {
          return Boolean(locationTypeSelection) && value.locationType !== "ALL"
            ? location.locationType === value.locationTypeValue
            : true;
        });
    } else {
      //if the props pass no locationType|locationType selection
      //we have to filter location base on the brand selection
      locationOptionList = locationAccess?.brands?.filter(
        (i) => i.brandId === value.brand
      )[0]?.locations;
    }
    return value.brand
      ? [{ locationId: "-1", locationName: "All" }, ...locationOptionList]
          ?.map((i) => {
            return {
              id: i.locationId,
              name: i.locationName
                .toLowerCase()
                .replace(/\b\w/g, (char) => char.toUpperCase()), // Convert to title case
            };
          })
          .map((option) => (
            <option key={option.id} value={option.name?.toUpperCase()} />
          ))
      : [];
  };
  function getPreviousMonthDates() {
    // Get the current date
    const currentDate = new Date();

    // Calculate the first day of the previous month
    const firstDayOfPreviousMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );

    // Calculate the last day of the previous month
    const lastDayOfPreviousMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0
    );

    return {
      startDate: format(firstDayOfPreviousMonth, "yyyy-MM-dd"),
      endDate: format(lastDayOfPreviousMonth, "yyyy-MM-dd"),
    };
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        chooseDateFrom: reportSnapShot ? getPreviousMonthDates().startDate : "",
        chooseDateTo: reportSnapShot ? getPreviousMonthDates().endDate : "",
        reportCategory: "",
        brand: sidebarBrandId,
        location: locationType
          ? "-1"
          : sidebarLocationId == 0
          ? "-1"
          : sidebarLocationId,
        memberLocationName: locationType ? "All" : sidebarLocationName,
        locationTypeValue: "ALL",
        additionalSelect: additionalSelect
          ? additionalSelect?.option?.[0]?.id
          : "",
      }}
      onSubmit={async (values, OnSubmitProps) => {
        const variables = {
          ...values,
          // pass the notimezone into thiss component to make it only date string without timezone
          chooseDateFrom: !noTimezone
            ? values.chooseDateFrom !== ""
              ? startOfDay(parseISO(values.chooseDateFrom)).toISOString()
              : ""
            : values.chooseDateFrom,
          chooseDateTo: !noTimezone
            ? values.chooseDateTo !== ""
              ? endOfDay(parseISO(values.chooseDateTo)).toISOString()
              : ""
            : values.chooseDateTo,
        };
        if (!brandAndLocation) {
          delete values.location;
          delete values.brand;
        }
        if (!props.monthSelect) {
          delete values.month;
        }
        await props.handleSubmit(variables, OnSubmitProps);
      }}
      validate={(values, props) => {
        const { reportCategory } = values;
        let errors = {};
        if (!noDefaultSelect && !reportCategory) {
          const errorText = "Please select category";
          errors.reportCategory = errorText;
        }
        if (!noDefaultDate && !values?.chooseDateFrom && !noDate) {
          const errorText = "Please select start date";
          errors.chooseDateFrom = errorText;
        }
        if (monthSelect && !values?.month && !noDate) {
          const errorText = "Please select month";
          errors.month = errorText;
        }
        if (!onlyStartDate && !values.chooseDateTo && !noDate) {
          const errorText = "Please select end date";
          errors.chooseDateTo = errorText;
        }
        if (
          !(brandAndLocation && selectedLocation.id) &&
          brandAndLocation &&
          !values.location &&
          !values.memberLocationName
        ) {
          errors.location = "Please select a location";
        }
        return errors;
      }}
    >
      {(formik) => {
        return (
          <div>
            <Form>
              <div className="report-options-month-container flex-wrap">
                <div className="report-options-inputs">
                  {!noDefaultSelect && (
                    <Select
                      onChange={(e) => {
                        formik.setValues({
                          ...formik.values,
                          reportCategory: e.target.value,
                        });
                      }}
                      optionvalues={props.optionFirst ? props.optionFirst : []}
                      label="Report category"
                      name="reportCategory"
                      selecttype="object"
                      objectvalue="id"
                      objectname="name"
                      autoComplete="off"
                      className="fs-10 select-element-value"
                    />
                  )}
                  {additionalSelect && (
                    <Select
                      onChange={(e) => {
                        formik.setValues({
                          ...formik.values,
                          additionalSelect: e.target.value,
                        });
                      }}
                      optionvalues={
                        props.additionalSelect.option
                          ? props.additionalSelect.option
                          : []
                      }
                      label={additionalSelect.label}
                      name="additionalSelect"
                      selecttype="object"
                      objectvalue="id"
                      objectname="name"
                      autoComplete="off"
                      className="fs-10 select-element-value"
                    />
                  )}
                  {/* select location type FRANCHISE/CORPORATION */}
                  {locationTypeSelection && (
                    <Select
                      onChange={(e) => {
                        formik.setValues({
                          ...formik.values,
                          locationTypeValue: e.target.value,
                        });
                      }}
                      optionvalues={[
                        { id: "ALL", name: "ALL" },
                        { id: "FRANCHISEE", name: "FRANCHISEE" },
                        { id: "CORPORATE", name: "CORPORATE" },
                      ]}
                      label={"Location type"}
                      name="locationTypeValue"
                      selecttype="object"
                      objectvalue="id"
                      objectname="name"
                      autoComplete="off"
                      className="fs-10 select-element-value"
                    />
                  )}
                  <>
                    <Select
                      onChange={(e) => {
                        formik.setValues({
                          ...formik.values,
                          // reportCategory: e.target.value,
                          brand: e.target.value,
                        });
                      }}
                      optionvalues={locationAccess?.brands
                        ?.filter(
                          (brand) =>
                            brand.brandName !== "Club Lime Aquatics" &&
                            brand.brandName !== "Club Lime Ladies" &&
                            brand.brandName !== "Psycle Life" &&
                            brand.brandName !== "Club Lime Studio" &&
                            brand.brandName !== "Golf"
                        )
                        .map((i) => {
                          return { id: i.brandId, name: i.brandName };
                        })}
                      label={"Brand name"}
                      name="brand"
                      selecttype="object"
                      objectvalue="id"
                      objectname="name"
                      autoComplete="off"
                      className="fs-10 select-element-value"
                    />
                    <div className="select-element">
                      <label className="fs-12">Club location</label>

                      <div className="location-value loc-select flex-col">
                        <input
                          type="text"
                          list="generator-form-location-list"
                          placeholder="Search a Location"
                          class="fs-10 select-element-value"
                          name="memberLocationName"
                          onClick={() => handleClick(formik)}
                          onBlur={() => handleBlur(formik)}
                          onChange={(e) => {
                            formik.setValues({
                              ...formik.values,
                              memberLocationName: e.target.value,
                            });
                            const locationValue = formik.values.brand
                              ? [
                                  { locationId: "-1", locationName: "All" },
                                  ...locationAccess.brands?.filter(
                                    (i) => i.brandId === formik.values.brand
                                  )[0]?.locations,
                                ]?.map((i) => {
                                  return {
                                    id: i.locationId,
                                    name: i.locationName
                                      .toLowerCase()
                                      .replace(/\b\w/g, (char) =>
                                        char.toUpperCase()
                                      ), // Convert to title case
                                  };
                                })
                              : [];
                            const selectedValue = locationValue.filter(
                              (i) =>
                                i.name.toLowerCase() ===
                                e.target.value.toLowerCase()
                            );

                            if (selectedValue.length) {
                              setSelectedLocation(selectedValue[0]);
                              formik.setValues({
                                ...formik.values,
                                location: selectedValue[0]?.id,
                                memberLocationName: selectedValue[0]?.name,
                              });
                            }
                          }}
                          value={formik.values?.memberLocationName}
                          autoComplete="off"
                        />
                        <datalist id="generator-form-location-list">
                          {locationOptionRender(formik.values)}
                        </datalist>
                        {formik?.errors?.location && (
                          <div className="error fs-12">
                            {formik?.errors?.location}
                          </div>
                        )}
                      </div>
                    </div>
                  </>

                  {!noDate && (
                    <div className="date-input-container fs-10 flex-col flex-wrap">
                      <p className="show-subtitle text-gray">
                        Show reports {props.onlyStartDate ? "" : "between"}
                      </p>
                      <div className="date-range-container">
                        {!noDefaultDate ? (
                          <div className="date-input">
                            <DatePicker
                              error={formik.errors.chooseDateFrom}
                              name="chooseDateFrom"
                              value={formik.values.chooseDateFrom}
                              onChange={(e) => {
                                const newToValue =
                                  formik.values.chooseDateTo.length > 0
                                    ? formik.values.chooseDateTo
                                    : format(new Date(), "yyyy-MM-dd");

                                formik.setValues({
                                  ...formik.values,
                                  chooseDateFrom: e.target.value,
                                  chooseDateTo: newToValue,
                                });
                              }}
                            />
                          </div>
                        ) : (
                          <p className="show-subtitle text-gray">
                            the last 12 months
                          </p>
                        )}
                        {!props.onlyStartDate && (
                          <div className="date-input">
                            <DatePicker
                              error={formik.errors.chooseDateTo}
                              name="chooseDateTo"
                              value={formik.values.chooseDateTo}
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              min={formik.values.chooseDateFrom}
                              // max={format(new Date(), "yyyy-MM-dd")}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {/* pass the monthSelect into this component to make it have 1 extra selection to select months */}
                  {monthSelect && (
                    <div className="date-input-container fs-10 flex-wrap">
                      {" "}
                      <p className="show-subtitle text-gray">
                        Returning Members in{" "}
                      </p>
                      <DatePicker
                        error={formik.errors.month}
                        type={"month"}
                        name="month"
                        value={formik.values.month}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                    </div>
                  )}
                  <div className="submit-container">
                    <Button
                      loading={formik.isSubmitting}
                      name="GENERATE"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent block btn-lg fs-12 order-last"
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default ReportGeneratorForm;
