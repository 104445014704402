import * as yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";

export const classTemplateSchema = yup.object().shape({
  className: yup.string().required("Class Name is required"),
  classDuration: yup
    .number()
    .min(1, "Class duration should be equal or greater than 1")
    .required("Class Duration is required"),
  daysWithin: yup
    .number()
    .min(0, "Prebook days should be equal or greater than 0"),
  classDescription: yup.string().required("Class Description is required"),
});

export const classScheduleSchema = yup.object().shape({
  isThisRepeated: yup.string().required("Class Occurence is required"),
  studioId: yup.string().required("Studio is required"),
  classScheduleDateTime: yup.string().required("Date is required"),
  bookingLockoutMinutes: yup.number(),
  cancelLockoutMinutes: yup.number(),
  maxBooking: yup
    .number()
    .min(0, "Max booking should be greater than 0")
    .required("Maximum Booking is required"),
  targetBooking: yup
    .number()
    .max(yup.ref("maxBooking"), "Target should not be more than max booking"),
});

export const editClassScheduleSchema = yup.object().shape({
  studioId: yup.string().required("Studio is required"),
  instructorId: yup.string(),
  classScheduleDateTime: yup.string().required("Date is required"),
  bookingLockoutMinutes: yup.number(),
  cancelLockoutMinutes: yup.number(),
  maxBooking: yup
    .number()
    .min(0, "Max booking should be greater than 0")
    .required("Maximum Booking is required"),
  targetBooking: yup
    .number()
    .max(yup.ref("maxBooking"), "Target should not be more than max booking"),
});

export const casualBookingSchema = yup.object().shape({
  casualFirstName: yup.string().required("First Name is required"),
  casualLastName: yup.string().required("Surname is required"),
  casualEmail: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  casualPhone: yup
    .string()
    .required("Phone number is required")
    .test("is-valid-number", "Invalid Number", (casualPhone) => {
      return casualPhone ? isValidPhoneNumber(casualPhone) : true;
    }),
});
