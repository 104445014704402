import { BeatLoader } from "react-spinners";

const Loading = () => {
  return (
    <div className="dashboard-loading">
      <BeatLoader color="white" />
    </div>
  );
};

export default Loading;
