// import "./input.scss";
import { useField } from "formik";

const TextArea = (props) => {
  const [field, meta] = useField(props);

  return (
        <div className="form-group">
          <label
            htmlFor={props.id}
            className={`${props.className} fs-12`}
          >
            {props.label}
          </label>
          <textarea
            type={props.type}
            {...field}
            {...props}
            placeholder={props.placeholder}
            className={`${props.className} fs-12 noselect `}
          />
          <div className="error fs-12">
            {meta.error && meta.touched ? meta.error : null}
          </div>
        </div>
  );
};

export default TextArea;
