import "./dashboard.scss";
import "../pagenotfound/pageNotFound.scss";
import { useEffect, useState } from "react";
import { BsCardText } from "react-icons/bs";
import { FaLessThan, FaGreaterThan } from "react-icons/fa6";
import { Outlet } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import hub_logo from "../../assets/images/hub_logo.svg";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useMemberContext } from "../../contexts/MemberContext";
import { useDeviceType } from "../../hooks/useDeviceType";
import { VersionNotes, Sidebar } from "../../components";
import { BiArrowBack, BiArrowToLeft, BiArrowToRight } from "react-icons/bi";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import usePageHistory from "../../hooks/usePageHistory";
import { SET_PAGE_HISTORY } from "../../actions";

const Dashboard = () => {
  const { isTablet } = useDeviceType();
  const { locationAccess, handleSignOut } = useAuthContext();
  const [isExpand, setIsExpand] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { sidebarLocationData, dispatch } = useMemberContext();
  const pageHistory = usePageHistory();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_HISTORY,
      payload: {
        pageHistory: pageHistory,
      },
    });
  }, [pageHistory]);

  useEffect(() => {
    setIsExpand(isTablet);
  }, [isTablet]);

  const signout = () => {
    handleSignOut();
  };

  if (
    (locationAccess.brands.length === 0 &&
      locationAccess.locationAll.length === 0) ||
    (locationAccess.locationAll.length === 1 &&
      locationAccess.locationName === "All")
  ) {
    return (
      <>
        <div className="page-error-container">
          <div className="message-container pd-32">
            <div className="error-message fs-12 uppercase">
              You have not been assigned access to a location
            </div>
            <div className="error-message-sub fs-12 item-centered text-gray">
              For Support, please contact your BPM
            </div>
            <button className="btn btn-small btn-confirm" onClick={signout}>
              Logout
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {!sidebarLocationData && !locationAccess?.brands && (
        <>
          <div className="dashboard">
            <div className="dashboard-loading">
              <BeatLoader color="white" />
            </div>
          </div>
        </>
      )}
      {sidebarLocationData && locationAccess?.brands && (
        <div className="dashboard">
          <div className="sidebar-wrapper">
            <div
              className={`dashboard-sidebar ${
                isExpand ? "default" : "expanded"
              }`}
            >
              <Sidebar />
            </div>
            <div
              onClick={() => setIsExpand(!isExpand)}
              className={`expand-icon ${isExpand && "move-icon"}`}
            >
              <CgChevronLeft color="white" size="1.8rem" />
            </div>
          </div>
          <div className="dashboard-container">
            {/* <Header /> */}
            <div className="outlet-container">
              {modalOpen && <VersionNotes setModalOpen={setModalOpen} />}
              <Outlet />
            </div>
          </div>

          <footer>
            <div className="footer">
              <div className="footer-left-text flex-row">
                <p className="fs-10 text-gray ">
                  © Viva Labs, a division of Viva Leisure Limited |{" "}
                </p>
                <div
                  className="flex-row version-number"
                  // onClick={() => setModalOpen(true)}
                >
                  <a
                    href="https://www.notion.so/viva-labs/Latest-Release-828ca0bc17ba468c8a078fb230f2b9c1"
                    target="_blank"
                    className="fs-10"
                  >
                    Release Notes
                  </a>
                  <BsCardText className="fs-10" />
                </div>
              </div>

              <img src={hub_logo} alt="logo" width="73" height="20" />
            </div>
          </footer>
        </div>
      )}
    </>
  );
};

export default Dashboard;
