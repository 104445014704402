import { useState } from "react";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useLazyQuery,
} from "@apollo/client";

export const useCustomFunctionHook = () => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const getData = async ({ query, variable }) => {
    try {
      setError(false);
      setLoading(true);
      const result = await client.query({
        query,
        fetchPolicy: "no-cache",
        variables: { ...variable },
      });
      if (result) {
        setData(result.data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getData,
    loading,
    data,
    error,
  };
};
