import "../../suspension.scss";
import { Formik, Form } from "formik";
import { addMonths, format, isBefore, isSameDay } from "date-fns";
import Button from "../../../formUiElements/Button";
import Input from "../../../formUiElements/input/Input";

import { BeatLoader } from "react-spinners";
import { utcToZonedTime } from "date-fns-tz";
import { addFrequencyToDate } from "../../../../utils/calculateTimePeriod";
import { sortAsc } from "../../../../utils/sort";
const EditSuspensionModal = (props) => {
  const initialSuspensionEndDate = () => {
    return handleMaxSusEndDate(props?.suspensionDetails?.suspensionStartDate);
  };
  const handleMaxSusEndDate = (suspensionStartDate) => {
    if (!suspensionStartDate) return "";
    const getmembercontract = props.contract;
    if (getmembercontract.getMemberContract.recurring) {
      //DD
      let suspensionEndDate;
      // const susStartDate = new Date(suspensionStartDate);
      // change to six month from now
      const sixMonthFromStartDate = addMonths(new Date(), 6);
      const contractEndDate = utcToZonedTime(
        new Date(getmembercontract?.getMemberContract.endDateTime),
        getmembercontract?.getMemberContract.timezone
      );

      const contractItems = getmembercontract?.getMemberContract.billings;
      const { items: billingsItems } = contractItems;
      const sortedBilling = sortAsc(billingsItems, "debitDate");
      const lastBilling = sortedBilling[sortedBilling.length - 1];

      // last date of last debit period billing

      if (
        getmembercontract?.getMemberContract.endDateTime &&
        isBefore(contractEndDate, sixMonthFromStartDate)
      ) {
        suspensionEndDate = format(contractEndDate, "yyyy-MM-dd");
      } else if (
        isBefore(
          utcToZonedTime(
            addFrequencyToDate(
              new Date(lastBilling.debitDate),
              getmembercontract?.getMemberContract.paymentFrequency
            ),
            getmembercontract?.getMemberContract.timezone
          ),
          sixMonthFromStartDate
        )
      ) {
        return format(
          utcToZonedTime(
            addFrequencyToDate(
              new Date(lastBilling.debitDate),
              getmembercontract?.getMemberContract.paymentFrequency
            ),
            getmembercontract?.getMemberContract.timezone
          ),
          "yyyy-MM-dd"
        );
      } else suspensionEndDate = format(sixMonthFromStartDate, "yyyy-MM-dd");
      return suspensionEndDate;
    } else {
      let suspensionEndDate;
      const susStartDate = new Date();
      const sixMonthFromStartDate = addMonths(susStartDate, 6);
      const contractEndDate = utcToZonedTime(
        new Date(props.contract?.getMemberContract?.endDateTime),
        props.contract.getMemberContract.timezone
      );
      if (
        (props.contract?.getMemberContract?.endDateTime ||
          props.contract?.getMemberContract?.expiryDateTime) &&
        isBefore(contractEndDate, sixMonthFromStartDate)
      ) {
        suspensionEndDate = format(contractEndDate, "yyyy-MM-dd");
      } else {
        suspensionEndDate = format(sixMonthFromStartDate, "yyyy-MM-dd");
      }
      return suspensionEndDate;
    }
  };
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          suspensionStartDate: format(
            utcToZonedTime(
              new Date(props?.suspensionDetails?.suspensionStartDate),
              props.contract.getMemberContract.timezone
            ),
            "dd/MM/yy"
          ),
          suspensionEndDate: undefined,
          suspensionReason: "",
          suspensionId: props?.suspensionDetails?.suspensionId,
        }}
        onSubmit={(values, OnSubmitProps) => {
          props.handleSubmit(values, OnSubmitProps);
        }}
        validate={(values, prop) => {
          const { suspensionEndDate, suspensionReason } = values;
          let errors = {};
          if (!suspensionEndDate) {
            const errorText = "Please update the suspension end date";
            errors.suspensionEndDate = errorText;
          }

          if (
            isSameDay(
              utcToZonedTime(
                new Date(suspensionEndDate),
                props.contract.getMemberContract.timezone
              ),
              utcToZonedTime(
                new Date(props?.suspensionDetails?.suspensionEndDate),
                props.contract.getMemberContract.timezone
              )
            )
          ) {
            const errorText = `You are already suspended till ${format(
              utcToZonedTime(
                props?.suspensionDetails?.suspensionEndDate,
                props.contract.getMemberContract.timezone
              ),
              "dd/MM/yyyy"
            )}`;
            errors.suspensionEndDate = errorText;
          }
          if (!suspensionReason) {
            const errorText = "Please provide a reason for updating suspension";
            errors.suspensionReason = errorText;
          }

          return errors;
        }}
      >
        {(formik) => {
          return (
            <div className="edit-suspension-modal-container">
              <Form className="status-modal edit-suspension-amount pd-32">
                <h3 className="fs-35 uppercase ">Edit Suspension</h3>

                <div className="input-container">
                  <div className="input-and-dropdown ">
                    <div className="amount-container">
                      <Input
                        type="text"
                        label="Start Date"
                        name="suspensionStartDate"
                        id="suspensionStartDate"
                        readOnly
                      />
                      <div className="pb-18">
                        <Input
                          type="date"
                          id="suspensionEndDate"
                          name="suspensionEndDate"
                          label="End Date"
                          // value={formik.values.suspensionEndDate}
                          onChange={(e) => {
                            formik.setValues({
                              ...formik.values,
                              suspensionEndDate: e.target.value,
                            });
                            props.handleEndDateChange(e.target.value);
                          }}
                          min={format(
                            new Date(
                              props?.suspensionDetails?.suspensionStartDate
                            ).valueOf() > new Date().valueOf()
                              ? utcToZonedTime(
                                  new Date(
                                    props?.suspensionDetails?.suspensionStartDate
                                  ),
                                  props.contract.getMemberContract.timezone
                                )
                              : new Date(),
                            "yyyy-MM-dd"
                          )}
                          // max={format(
                          //   new Date(
                          //     props?.suspensionDetails?.suspensionEndDate
                          //   ),
                          //   "yyyy-MM-dd"
                          // )}
                          max={initialSuspensionEndDate()}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="fs-12">
                    {props.suspensionDetails.upfrontAmount > 0 &&
                    !props.disabled ? (
                      <>
                        Upfront cost: ${props.suspensionDetails.upfrontAmount}
                      </>
                    ) : (
                      <>
                        {props.disabled ? (
                          <BeatLoader color="white" size="8" />
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </p>
                  <div className="desc-row">
                    <label className="fs-12 text-gray" htmlFor="reason">
                      Reason
                    </label>
                    <textarea
                      name="suspensionReason"
                      id="suspensionReason"
                      onChange={formik.handleChange}
                      value={formik.values.suspensionReason}
                    ></textarea>
                    {formik.errors.suspensionReason &&
                      formik.touched.suspensionReason && (
                        <div className="fs-12 error">
                          {formik.errors.suspensionReason}
                        </div>
                      )}
                  </div>
                </div>
                <div className="btn-group">
                  <Button
                    loading={formik.isSubmitting}
                    name={"Save"}
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-confirm btn-small"
                    disabled={
                      formik.isSubmitting || props?.disabled ? true : false
                    }
                  />
                  <Button
                    name="Cancel"
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-deny btn-small"
                    click={props.cancelFn}
                  />
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default EditSuspensionModal;
