import React, { useState } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { AiOutlineEdit } from "react-icons/ai";
import { instructorDescriptionSchema } from "../../validations/Validation";
import Button from "../formUiElements/Button";

const InstructorDescription = ({ instructorInfo, onSubmit }) => {
  const [isActive, setIsActive] = useState(true);

  const handleIsActive = () => {
    setIsActive(false);
  };
  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("instructor-description-form").reset();
    setIsActive(true);
  };

  return (
    <>
      {instructorInfo && (
        <Formik
          enableReinitialize
          initialValues={{
            description: instructorInfo?.description ?? "",
          }}
          validationSchema={instructorDescriptionSchema}
          onSubmit={async (values, OnSubmitProps) => {
            onSubmit(values, OnSubmitProps);
            setIsActive(true);
          }}
        >
          {(formik) => {
            return (
              <div className="instructor-description-container">
                <Form
                  className="first-card pd-18"
                  id="instructor-description-form"
                >
                  {isActive ? (
                    <>
                      <div className="title-bar">
                        <h3 className="fs-14">Description</h3>
                        <span
                          className="icon-container edit"
                          onClick={handleIsActive}
                        >
                          <AiOutlineEdit />
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="title-bar">
                      <h3 className="fs-14">Description</h3>
                      <span className="icon-container ">
                        <div className="sub-containers">
                          <div className="sub-container save">
                            <Button
                              loading={formik.isSubmitting}
                              name={""}
                              iconname={formik.isSubmitting ? "" : "BiSave"}
                              btntype="submit"
                              btnname="submit"
                              className="btn btn-edit save btn-lg fs-12"
                              disabled={formik.isSubmitting ? true : false}
                            />
                          </div>
                          <div className="sub-container cancel">
                            <Button
                              iconname="AiOutlineCloseCircle"
                              btntype="reset"
                              btnname="reset"
                              className="btn btn-icon btn-lg fs-12"
                              click={handleCancel}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  )}
                  <div className="detail">
                    <textarea
                      className="reason-box fs-12"
                      id="description"
                      name="description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                    ></textarea>
                    <div className="fs-12 error">
                      {formik.errors.description &&
                        formik.touched.description &&
                        formik.errors.description}
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      )}
    </>
  );
};
export default InstructorDescription;
