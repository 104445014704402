import React from "react";
import "./radiobutton.scss";

const RadioButton = (props) => {
  return (
    <div className="radio-container">
      {/* <label className="radio" htmlFor={props.label}>
        <input
          type="radio"
          id={props.label}
          name={props.name}
          value={props.value}
          checked={props.checked}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        <span className={`fs-14 ${props.classname}`}>
          {" "}P
          <p>{props.label}</p>{" "}
        </span>
      </label> */}
      <label className="container" htmlFor={props.label}>
        <input
          type="radio"
          id={props.label}
          name={props.name}
          value={props.value}
          checked={props.checked}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        <span className={`fs-14 checkmark ${props.classname}`}> </span>
      </label>
      <p className="fs-14">{props.label}</p>
    </div>
  );
};

export default RadioButton;
