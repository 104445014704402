import "./clubsetting.scss";
import React, { useState } from "react";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";
import { state as stateList, country } from "../../../constants/commonConstant";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";
import { useMemberContext } from "../../../contexts/MemberContext";
import { SET_OLD_VALUES } from "../../../actions";
import {
  locationTypes,
  debtCollectTypes,
} from "../../../constants/commonConstant";
import { clubSettingsSchema } from "../../../validations/LocationValidation";
import {
  Button,
  Input,
  Select,
  ToggleFormik,
  InternationalPhoneInput,
} from "../../../components";

const ClubSettings = (props) => {
  const { permissions } = useAuthContext();
  const { onSubmit, state } = props;
  const [isActive, setIsActive] = useState(true);
  const { dispatch } = useMemberContext();

  const handleIsActive = () => {
    setIsActive(false);
    dispatch({
      type: SET_OLD_VALUES,
      payload: {
        settlementEmail: state.location.getLocation?.settlementEmail ?? "",
        address1: state.location.getLocation.address1 ?? "",
        suburb: state.location.getLocation.suburb ?? "",
        state: state.location.getLocation.state ?? "",
        city: state.location.getLocation.city ?? "",
        country: state.location.getLocation.country ?? "",
        postCode: state.location.getLocation.postCode ?? "",
        name: state.location.getLocation.name ?? "",
        shortName: state.location.getLocation.shortName ?? "",
        locationType: state.location.getLocation.locationType ?? "",
        is24Hour: state.location.getLocation.is24Hour ?? "",
        isClubOpen: state.location.getLocation.isClubOpen ?? "",
        checkOpenTimes: state.location.getLocation.checkOpenTimes ?? "",
        hasGroupFitness: state.location.getLocation.hasGroupFitness ?? "",
        checkClassBookings: state.location.getLocation.checkClassBookings ?? "",
        hasCreche: state.location.getLocation.hasCreche ?? "",
        phoneNumber: state.location.getLocation.phoneNumber ?? "",
        originNumber: state.location.getLocation.originNumber ?? "",
        email: state.location.getLocation.email ?? "",
        ptPackEmail: state.location.getLocation.ptPackEmail ?? "",
        changePriceEmail: state.location.getLocation.changePriceEmail ?? "",
        clubPageUrl: state.location.getLocation.clubPageUrl ?? "",
        walk3DURL: state.location.getLocation.walk3DURL ?? "",
        timeTableOverrideURL:
          state.location.getLocation.timeTableOverrideURL ?? "",
        peakLoad: state.location.getLocation.peakLoad ?? "",
        membershipTarget: state.location.getLocation.membershipTarget ?? "",
        ptTarget: state.location.getLocation.ptTarget ?? "",
        ptTargetRevenue: state.location.getLocation.ptTargetRevenue ?? "",
        debtCollectionType: state.location.getLocation.debtCollectionType ?? "",
      },
    });
  };

  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("clubsetting-staff-form").reset();
    setIsActive(true);
  };
  return (
    <>
      {state.location && (
        <Formik
          enableReinitialize
          initialValues={{
            id: state.id,
            settlementEmail: state.location.getLocation?.settlementEmail ?? "",
            address1: state.location.getLocation.address1 ?? "",
            suburb: state.location.getLocation.suburb ?? "",
            state: state.location.getLocation.state ?? "",
            city: state.location.getLocation.city ?? "",
            country: state.location.getLocation.country ?? "",
            postCode: state.location.getLocation.postCode ?? "",
            name: state.location.getLocation.name ?? "",
            shortName: state.location.getLocation.shortName ?? "",
            locationType: state.location.getLocation.locationType ?? "",
            is24Hour: state.location.getLocation.is24Hour ?? "",
            isClubOpen: state.location.getLocation.isClubOpen ?? "",
            checkOpenTimes: state.location.getLocation.checkOpenTimes ?? "",
            hasGroupFitness: state.location.getLocation.hasGroupFitness ?? "",
            checkClassBookings:
              state.location.getLocation.checkClassBookings ?? "",
            hasCreche: state.location.getLocation.hasCreche ?? "",
            phoneNumber: state.location.getLocation.phoneNumber ?? "",
            originNumber: state.location.getLocation.originNumber ?? "",
            email: state.location.getLocation.email ?? "",
            ptPackEmail: state.location.getLocation.ptPackEmail ?? "",
            changePriceEmail: state.location.getLocation.changePriceEmail ?? "",
            clubPageUrl: state.location.getLocation.clubPageUrl ?? "",
            walk3DURL: state.location.getLocation.walk3DURL ?? "",
            timeTableOverrideURL:
              state.location.getLocation.timeTableOverrideURL ?? "",
            peakLoad: state.location.getLocation.peakLoad ?? "",
            membershipTarget: state.location.getLocation.membershipTarget ?? "",
            ptTarget: state.location.getLocation.ptTarget ?? "",
            ptTargetRevenue: state.location.getLocation.ptTargetRevenue ?? "",
            debtCollectionType:
              state.location.getLocation.debtCollectionType ?? "",
          }}
          validationSchema={clubSettingsSchema}
          onSubmit={async (values, OnSubmitProps) => {
            onSubmit(
              {
                ...values,
                name: values.name.toUpperCase(),
                shortName: values.shortName.toUpperCase(),
                originNumber: values.originNumber.toUpperCase(),
                address1: values.address1.toUpperCase(),
                suburb: values.suburb.toUpperCase(),
                state: values.state.toUpperCase(),
                city: values.city.toUpperCase(),
                country: values.country.toUpperCase(),
                email: values.email.toUpperCase(),
                ptPackEmail: values.ptPackEmail.toUpperCase(),
                changePriceEmail: values.changePriceEmail.toUpperCase(),
                settlementEmail: values.settlementEmail.toUpperCase(),
              },
              OnSubmitProps
            );
            setIsActive(true);
          }}
        >
          {(formik) => {
            return (
              <div className="clubsetting-container">
                <div className="sec-card pd-12">
                  <Form id="clubsetting-staff-form">
                    {isActive ? (
                      <>
                        <div className="title-bar">
                          <h3 className="fs-14">Club Settings</h3>
                          {permissions.includes("CanEditLocation") && (
                            <span
                              className="icon-container edit"
                              onClick={handleIsActive}
                            >
                              <AiOutlineEdit />
                            </span>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="title-bar">
                          <h3 className="fs-14">Club Settings</h3>
                          <span className="icon-container ">
                            <div className="sub-containers">
                              <div className="sub-container save">
                                {/* <IoIosSave /> */}
                                <Button
                                  iconname="BiSave"
                                  btntype="submit"
                                  btnname="submit"
                                  className="btn btn-edit save btn-lg fs-12 "
                                />
                              </div>
                              <div className="sub-container cancel">
                                {/* <ImCancelCircle /> */}
                                <Button
                                  iconname="AiOutlineCloseCircle"
                                  btntype="reset"
                                  btnname="reset"
                                  className="btn  btn-edit cancel btn-lg fs-12"
                                  click={handleCancel}
                                />
                              </div>
                            </div>
                          </span>
                        </div>
                      </>
                    )}
                  </Form>
                  <div className="details-container address">
                    <div className="address-first-col">
                      <div className="detail address">
                        <Input
                          type="text"
                          label="Address"
                          name="address1"
                          id="address1"
                          autoComplete="off"
                          disabled={isActive ? true : false}
                          className={isActive ? "" : "blue"}
                        />
                      </div>
                      <div className="address-sec-row">
                        <div className="detail suburb">
                          <Input
                            type="text"
                            label="Suburb"
                            name="suburb"
                            id="suburb"
                            autoComplete="off"
                            disabled={isActive ? true : false}
                            className={isActive ? "" : "blue"}
                          />
                        </div>
                        <div className="detail state">
                          <Select
                            label="State"
                            optionvalues={stateList}
                            selecttype="array"
                            name="state"
                            id="state"
                            disabled={isActive ? true : false}
                            autoComplete="off"
                            className="fs-12 select-element-value"
                          />
                        </div>
                        <div className="detail post">
                          <Input
                            type="text"
                            label="Post Code"
                            name="postCode"
                            id="postCode"
                            autoComplete="off"
                            disabled={isActive ? true : false}
                            className={isActive ? "" : "blue"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="address-sec-col">
                      <div className="first-row">
                        <div className="detail name">
                          <Input
                            type="text"
                            label="Name *"
                            name="name"
                            id="name"
                            autoComplete="off"
                            disabled={isActive ? true : false}
                            className={isActive ? "" : "blue"}
                          />
                        </div>
                        <div className="detail short-name">
                          <Input
                            type="text"
                            label="Short Name *"
                            name="shortName"
                            id="shortName"
                            autoComplete="off"
                            disabled={isActive ? true : false}
                            className={isActive ? "" : "blue"}
                          />
                        </div>
                        <div className="detail location-type">
                          <Select
                            label="Location Type"
                            optionvalues={locationTypes}
                            selecttype="array"
                            name="locationType"
                            id="locationType"
                            disabled={isActive ? true : false}
                            autoComplete="off"
                            className="select-element-value"
                          />
                        </div>
                      </div>

                      <div className="detail-grid">
                        <div className="container toggles">
                          <div className="time fs-10">
                            <ToggleFormik
                              label="24 Hour Club"
                              name="is24Hour"
                              id="is24Hour"
                              value={formik.is24Hour}
                              disabled={isActive ? true : false}
                              className={isActive ? "" : "blue"}
                            />
                          </div>
                          <div className="open fs-10">
                            <ToggleFormik
                              label="Club Open"
                              name="isClubOpen"
                              id="isClubOpen"
                              value={formik.isClubOpen}
                              disabled={isActive ? true : false}
                              className={isActive ? "" : "blue"}
                            />
                          </div>
                          <div className="openTime fs-10">
                            <ToggleFormik
                              label="Check Open times"
                              name="checkOpenTimes"
                              id="checkOpenTimes"
                              value={formik.checkOpenTimes}
                              disabled={isActive ? true : false}
                              className={isActive ? "" : "blue"}
                            />
                          </div>
                          <div className="groupFitness fs-10">
                            <ToggleFormik
                              label="Group Fitness"
                              name="hasGroupFitness"
                              value={formik.hasGroupFitness}
                              disabled={isActive ? true : false}
                              className={isActive ? "" : "blue"}
                            />
                          </div>
                          <div className="crache fs-10">
                            <ToggleFormik
                              label="Creche"
                              name="hasCreche"
                              id="hasCreche"
                              value={formik.hasCreche}
                              disabled={isActive ? true : false}
                              className={isActive ? "" : "blue"}
                            />
                          </div>
                          <div className="classBookings fs-10">
                            <ToggleFormik
                              label="Class Booking"
                              name="checkClassBookings"
                              id="checkClassBookings"
                              value={formik.checkClassBookings}
                              disabled={isActive ? true : false}
                              className={isActive ? "" : "blue"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="details-container phone-details">
                    <div className="detail">
                      <Input
                        type="text"
                        label="City"
                        name="city"
                        id="city"
                        autoComplete="off"
                        disabled={isActive ? true : false}
                        className={isActive ? "" : "blue"}
                      />
                    </div>
                    <div className="detail">
                      <Select
                        label="Country *"
                        optionvalues={country}
                        selecttype="array"
                        name="country"
                        id="edit-location-country"
                        autoComplete="off"
                        disabled={isActive ? true : false}
                        className="fs-12 select-element-value"
                      />
                      {/* <Input
                        type="text"
                        label="Country"
                        name="country"
                        id="country"
                        autoComplete="off"
                        disabled={isActive ? true : false}
                        className={isActive ? "" : "blue"}
                      /> */}
                    </div>
                    <div className="detail number">
                      <InternationalPhoneInput
                        title="Mobile Number"
                        name="phoneNumber"
                        id="phoneNumber"
                        editable={!isActive}
                      />
                    </div>

                    <div className="detail">
                      <Input
                        type="text"
                        label="Origin Number"
                        name="originNumber"
                        id="originNumber"
                        autoComplete="off"
                        disabled={isActive ? true : false}
                        className={isActive ? "" : "blue"}
                      />
                    </div>
                    {/* <div className="detail-checkbox">
                      <Toggle disabled={isActive ? true : false} />
                      <p className=" fs-10">Phone Number Override</p>
                    </div> */}
                  </div>

                  <div className="mid-row">
                    <div className="container">
                      <div className="prim-email">
                        <div className="detail">
                          <Input
                            type="text"
                            label="Primary email "
                            name="email"
                            id="email"
                            autoComplete="off"
                            disabled={isActive ? true : false}
                            className={isActive ? "" : "blue"}
                          />
                        </div>
                      </div>{" "}
                      <div className="prim-email">
                        <div className="detail">
                          <Input
                            type="text"
                            label="Settlement email "
                            name="settlementEmail"
                            id="settlementEmail"
                            autoComplete="off"
                            disabled={isActive ? true : false}
                            className={isActive ? "" : "blue"}
                          />
                        </div>
                      </div>
                      <div className="pt-email">
                        <div className="detail">
                          <Input
                            type="text"
                            label="PT Pack Email "
                            name="ptPackEmail"
                            id="ptPackEmail"
                            autoComplete="off"
                            disabled={isActive ? true : false}
                            className={isActive ? "" : "blue"}
                          />
                        </div>
                      </div>
                      <div className="price-email">
                        <div className="detail">
                          <Input
                            type="text"
                            label="change price email "
                            name="changePriceEmail"
                            id="changePriceEmail"
                            autoComplete="off"
                            disabled={isActive ? true : false}
                            className={isActive ? "" : "blue"}
                          />
                        </div>
                      </div>
                      <div className="expandedContainer">
                        <div className="club-url">
                          <div className="detail">
                            <Input
                              type="text"
                              label="club page url"
                              name="clubPageUrl"
                              id="clubPageUrl"
                              autoComplete="off"
                              disabled={isActive ? true : false}
                              className={isActive ? "" : "blue"}
                            />
                          </div>
                        </div>
                        <div className="threed-url">
                          <div className="detail">
                            <Input
                              type="text"
                              label="3D walkthrough url "
                              name="walk3DURL"
                              id="walk3DURL"
                              autoComplete="off"
                              disabled={isActive ? true : false}
                              className={isActive ? "" : "blue"}
                            />
                          </div>
                        </div>
                        <div className="override-url">
                          <div className="detail">
                            <Input
                              type="text"
                              label="timetable override url "
                              name="timeTableOverrideURL"
                              id="timeTableOverrideURL"
                              autoComplete="off"
                              disabled={isActive ? true : false}
                              className={isActive ? "" : "blue"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="final-row">
                    <div className="details-container">
                      <div className="detail">
                        <Input
                          type="number"
                          label="peek load"
                          name="peakLoad"
                          id="peakLoad"
                          autoComplete="off"
                          disabled={isActive ? true : false}
                          className={isActive ? "" : "blue"}
                          min={0}
                        />
                      </div>
                      <div className="detail">
                        <Input
                          type="number"
                          label="membership target"
                          name="membershipTarget"
                          id="membershipTarget"
                          autoComplete="off"
                          disabled={isActive ? true : false}
                          className={isActive ? "" : "blue"}
                          min={0}
                        />
                      </div>
                      <div className="detail">
                        <Input
                          type="number"
                          label="PT target"
                          name="ptTarget"
                          id="ptTarget"
                          autoComplete="off"
                          disabled={isActive ? true : false}
                          className={isActive ? "" : "blue"}
                          min={0}
                        />
                      </div>
                      <div className="detail">
                        <Input
                          type="number"
                          label="PT target revenue"
                          name="ptTargetRevenue"
                          id="ptTargetRevenue"
                          autoComplete="off"
                          disabled={isActive ? true : false}
                          className={isActive ? "" : "blue"}
                          min={0}
                        />
                      </div>
                    </div>
                    {permissions.includes("CanEditDebtCollectionType") && (
                      <div className="debt-collection">
                        <div className="detail">
                          <Select
                            label="Debt Collection"
                            optionvalues={debtCollectTypes}
                            selecttype="array"
                            name="debtCollectionType"
                            id="debtCollectionType"
                            disabled={isActive ? true : false}
                            autoComplete="off"
                            className="select-element-value"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      )}
    </>
  );
};
export default ClubSettings;
