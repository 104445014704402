import { useState, useEffect } from "react";
import { axiosClient } from "../api/apiConfig";

const useCustomFetch = (urlParams, params) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const fetchData = async (url) => {
    setError("");
    setIsLoading(true);
    try {
      const data = params
        ? await axiosClient.get(url, { params })
        : await axiosClient.get(url);
      if (data) {
        setData(data);
      }
    } catch (error) {
      // setError(error?.message);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (urlParams) fetchData(`${urlParams}`, params);
  }, [urlParams, params]);
  return { isLoading, error, data };
};

export default useCustomFetch;
