import React from "react";
import { useState } from "react";
import { format } from "date-fns";
import { AiOutlineSearch } from "react-icons/ai";
import { Table } from "../../../components";
const ViewVoucherLocations = (props) => {
  const [searchLocationBarInputVal, setSearchLocationBarInputVal] =
    useState("");
  const [globalFilterLocations, setGlobalFilterLocations] = useState("");
  const [columnLocationsFilters, setColumnLocationsFilters] = useState([]);
  const handleSearchLocation = (event) => {
    const searchVal = event.target.value;
    setSearchLocationBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilterLocations(searchVal);
    }
  };
  const onSearchLocationBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilterLocations(String(searchLocationBarInputVal));
  };
  const columnsLocations = [
    {
      accessorFn: (row) => row?.locationDetail?.name,
      header: "Location",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "location",
    },
    {
      accessorFn: (row) => row?.createdAt,
      header: "Date",
      cell: (info) => {
        const date = info?.getValue()
          ? format(new Date(info?.getValue()), "dd/MM/yyyy")
          : "";
        return date;
      },
      enableSorting: true,
      enableGlobalFilter: true,
      id: "membershipName",
    },
  ];

  return (
    <div>
      <div className="search-and-button-row">
        <div className="search-container ">
          <form onSubmit={onSearchLocationBarSubmit}>
            <div className="search-bar">
              <div className="search-bar-container">
                <input
                  className="fs-10"
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Location"
                  value={searchLocationBarInputVal}
                  onChange={handleSearchLocation}
                />
                <button type="submit">
                  <AiOutlineSearch />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Table
        data={props.data}
        columns={columnsLocations}
        rowsPerPage={10}
        columnFilters={columnLocationsFilters}
        globalFilter={globalFilterLocations}
        setGlobalFilter={setGlobalFilterLocations}
      />
    </div>
  );
};

export default ViewVoucherLocations;
