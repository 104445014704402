import GU from "../assets/images/icons/GU_icon.png";
import CL from "../assets/images/icons/CL_icon.png";
import HR from "../assets/images/icons/HR_icon.png";
import PF from "../assets/images/icons/PF_icon.png";
import AQ from "../assets/images/icons/AQ_icon.png";
import LO from "../assets/images/icons/LO_icon.png";
import PL from "../assets/images/icons/PL_icon.png";
import RB from "../assets/images/icons/RB_icon.png";
import SS from "../assets/images/icons/SS_icon.png";
import CL_Logo from "../assets/images/icons/brand_logos/CL_Logo.png";
import VL_Logo from "../assets/images/icons/brand_logos/VL_Logo.png";
import PLUS_Logo from "../assets/images/icons/brand_logos/PF_Logo.png";
// import LO_Logo from "./assets/images/icons/brand_logos/LO_icon.png";
import RB_Logo from "../assets/images/icons/brand_logos/RB_Logo.png";
import HIIT_Logo from "../assets/images/icons/brand_logos/HR_Logo.png";
import GU_Logo from "../assets/images/icons/brand_logos/GU_Logo.png";

export const brandTypes = [
  { name: "Club Lime", icon: CL, abbreviation: "CL", logo: CL_Logo },
  { name: "Hiit Republic", icon: HR, abbreviation: "HR", logo: HIIT_Logo },
  { name: "GroundUp", icon: GU, abbreviation: "GU", logo: GU_Logo },
  { name: "Plus Fitness", icon: PF, abbreviation: "PF", logo: PLUS_Logo },
  { name: "Rebalance", icon: RB, abbreviation: "RB", logo: RB_Logo },
  { name: "Psycle Life", icon: PL, abbreviation: "PL", logo: GU_Logo },
  { name: "Club Lime Aquatics", icon: AQ, abbreviation: "AQ", logo: CL_Logo },
  { name: "Club Lime Ladies", icon: LO, abbreviation: "LO", logo: RB_Logo },
  { name: "CLSS", icon: SS, abbreviation: "SS", logo: CL_Logo },
  { name: "Club Lime Studio", icon: SS, abbreviation: "SS", logo: GU_Logo },
  { name: "Golf", icon: SS, abbreviation: "SS", logo: VL_Logo },
  { name: "Viva Leisure", icon: SS, abbreviation: "SS", logo: VL_Logo },
];
export const reasonForBlockMember = [
  "Under 18",
  "Tailgating",
  "Aggressive Behaviour",
  "Damaging Property",
  "Other",
];

export const paymentFrequencyArr = ["WEEKLY", "ANNUAL", "STUDENT"];
export const UserData = [
  {
    id: 1,
    year: 2016,
    userGain: 80000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345,
  },
  {
    id: 3,
    year: 2018,
    userGain: 78888,
    userLost: 555,
  },
  {
    id: 4,
    year: 2019,
    userGain: 90000,
    userLost: 4555,
  },
  {
    id: 5,
    year: 2020,
    userGain: 4300,
    userLost: 234,
  },
];

export const heading = ["ID", "Name", "Type", "Dob", "Mobile", "Email"];
export const data = [
  {
    id: "1",
    items: [
      "123",
      "Test",
      "Staff",
      "15/15/1998",
      "0987654321",
      "test@gmail.com",
    ],
  },
  {
    id: "2",
    items: [
      "123",
      "Test",
      "Staff",
      "15/15/1998",
      "0987654321",
      "test@gmail.com",
    ],
  },
  {
    id: "3",
    items: [
      "123",
      "Test",
      "Staff",
      "15/15/1998",
      "0987654321",
      "test@gmail.com",
    ],
  },
  {
    id: "4",
    items: [
      "123",
      "Test",
      "Staff",
      "15/15/1998",
      "0987654321",
      "test@gmail.com",
    ],
  },
  {
    id: "5",
    items: [
      "123",
      "Test",
      "Staff",
      "15/15/1998",
      "0987654321",
      "test@gmail.com",
    ],
  },
];

export const data1 = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export const gender = ["MALE", "FEMALE", "NEUTRAL", "PRIVATE"];
export const state = [
  "ACT",
  "QLD",
  "NSW",
  "VIC",
  "WA",
  "TAS",
  "SA",
  "NT",
  "NZ",
];
export const country = ["AUSTRALIA", "NEW ZEALAND"];
export const certificates = [
  "Degrees/ Deplomas",
  "Professional Certifications",
  "Training Portfolio",
  "FIRST AID",
  "CPR",
  "WWCC (Working With Children Check)",
  "Drivers Licence",
  "Other Identity Documents",
  "Police Check",
  "Other",
];
export const title = ["Mr.", "Mrs."];
export const staffTypes = ["CORPORATE", "FRANCHISE"];
export const frequency = ["DAYS", "WEEKS", "MONTHS", "YEARS"];

export const memberTypeForFixed = ["NEW", "PREVIOUS", "EXIST", "ALL"];
export const memberTypeForVisit = ["NEW", "PREVIOUS", "ALL"];
export const memberTypeForDelayPayment = ["NEW", "PREVIOUS"];

export const membershipTypes = [
  "STAFF_ONLY",
  "MEMBER_ONLY",
  "FITNESS_PASSPORT",
];
export const membershipLevels = ["ADULT", "YOUTH", "JUNIOR"];
export const isThisSeries = ["Single Class", "Whole Series"];
export const paymentType = ["CREDIT_CARD", "DIRECT_DEBIT"];

export const locationTypes = ["CORPORATE", "FRANCHISEE"];
export const locationTypeOptions = ["ALL", "CORPORATE", "FRANCHISEE"];

export const brandId = [
  {
    id: "0abcb677-156c-4a67-b650-62c2a0ae3bd0",
    name: "LIME",
    fullName: "Club Lime",
  },
  {
    id: "97b10c20-aa49-4461-a5ac-ba4c89447923",
    name: "PINK",
    fullName: "Club Lime Ladies",
  },
  {
    id: "87b5314a-5178-4808-a477-50cc706b8b7d",
    name: "PSYCLE",
    fullName: "Psycle Life",
  },
  {
    id: "31ea4330-0142-4f38-bc5d-fafe850994e7",
    name: "GOLF",
    fullName: "Golf",
  },
  {
    id: "516ada8a-3f07-4df4-914d-b727ca5a22f2",
    name: "BLUE",
    fullName: "Club Lime Aquatics",
  },
  {
    id: "a8cd5e5c-986a-4992-bc66-2d71142e5489",
    name: "HIIT",
    fullName: "Hiit Republic",
  },
  { id: "5ebec248-5171-406b-824e-e411cfcc68ce", name: "STUDIO" },
  { id: "48636a81-1023-404b-95c4-21a3746ab8f8", name: "FNF" },
  {
    id: "6ff40134-e719-4fce-978e-0da151d146b5",
    name: "GROUNDUP",
    fullName: "GroundUp",
  },
];

export const role = ["L0", "L1", "L2", "L3", "L4", "L5"];

export const class_occurence = ["SINGLE CLASS", "SERIES CLASS"];

export const barChartData1 = [
  {
    name: "1",
    uv: 4000,
  },
  {
    name: "2",
    uv: 3000,
  },
  {
    name: "3",
    uv: 2000,
  },
  {
    name: "4",
    uv: 2780,
  },
  {
    name: "5",
    uv: 1890,
  },
  {
    name: "6",
    uv: 2390,
  },
  {
    name: "7",
    uv: 3490,
  },
];

export const barChartData2 = [
  {
    name: "1",
    uv: 4000,
  },
  {
    name: "2",
    uv: 3000,
  },
  {
    name: "3",
    uv: 2000,
  },
  {
    name: "4",
    uv: 2780,
  },
  {
    name: "5",
    uv: 1890,
  },
  {
    name: "6",
    uv: 2390,
  },
  {
    name: "7",
    uv: 3490,
  },
  {
    name: "8",
    uv: 4000,
  },
  {
    name: "9",
    uv: 3000,
  },
  {
    name: "10",
    uv: 2000,
  },
  {
    name: "11",
    uv: 2780,
  },
  {
    name: "12",
    uv: 1890,
  },
  {
    name: "13",
    uv: 2390,
  },
  {
    name: "14",
    uv: 3490,
  },
  {
    name: "15",
    uv: 4000,
  },
  {
    name: "16",
    uv: 3000,
  },
  {
    name: "17",
    uv: 2000,
  },
  {
    name: "18",
    uv: 2780,
  },
  {
    name: "19",
    uv: 1890,
  },
  {
    name: "20",
    uv: 2390,
  },
  {
    name: "21",
    uv: 3490,
  },
  {
    name: "22",
    uv: 4000,
  },
  {
    name: "23",
    uv: 3000,
  },
  {
    name: "24",
    uv: 2000,
  },
  {
    name: "25",
    uv: 2780,
  },
  {
    name: "26",
    uv: 1890,
  },
  {
    name: "27",
    uv: 2390,
  },
  {
    name: "28",
    uv: 3490,
  },
  {
    name: "29",
    uv: 1690,
  },
  {
    name: "30",
    uv: 3390,
  },
];
export const barChartData3 = [
  {
    name: "1",
    uv: 4000,
  },
  {
    name: "2",
    uv: 3000,
  },
  {
    name: "3",
    uv: 2000,
  },
  {
    name: "4",
    uv: 2780,
  },
  {
    name: "5",
    uv: 1890,
  },
  {
    name: "6",
    uv: 2390,
  },
  {
    name: "7",
    uv: 3490,
  },
  {
    name: "8",
    uv: 4000,
  },
  {
    name: "9",
    uv: 3000,
  },
  {
    name: "10",
    uv: 2000,
  },
  {
    name: "11",
    uv: 2780,
  },
  {
    name: "12",
    uv: 1890,
  },
];

export const memberReports = [
  {
    id: "allMembers",
    name: "ALL MEMBERS",
    description:
      "List of all active, joiners, leavers, expired, suspended, and future members at a location on a specific date.",
    isFavorite: true,
    permission: "CanViewAllMemberReport",
  },
  {
    id: "memberVisits",
    name: "MEMBERS VISITS",
    description: "Member visits within a specific date range.",
    isFavorite: true,
    permission: "",
  },
];
export const membershipReports = [
  {
    id: "membershipMovement",
    name: "MEMBERSHIP MOVEMENT",
    description:
      "List total membership movement within a specified date range(measured by start & expiry date).",
    isFavorite: true,
    permission: "CanViewMembershipMovementReport",
  },
  {
    id: "membershipBreakup",
    name: "MEMBERSHIP BREAKUP",
    description:
      "The breakup of membership types, membership status on a specific date.",
    isFavorite: false,
    permission: "",
  },
  {
    id: "upgradeDowngrade",
    name: "UPGRADE/DOWNGRADE",
    description: "Upgrades/ downgrades description",
    isFavorite: false,
    permission: "CanViewUpgradeDowngradeReport",
  },
  {
    id: "fpmembership",
    name: "FITNESS PASSPORT MEMBERSHIP",
    description:
      "FP membership report shows members with valid barcodes as well as Fitness Passport Memberships.",
    isFavorite: false,
    permission: "",
  },
  {
    id: "fpVisit",
    name: "FITNESS PASSPORT VISITS",
    description: "FP visits report shows members who visited the location",
    isFavorite: false,
    permission: "",
  },
  {
    id: "youthMembership",
    name: "YOUTH MEMBERSHIP",
    description:
      "Youth membership report shows members who will turn to 16 years old in the next month",
    isFavorite: false,
    permission: "",
  },
];
export const financialReports = [
  {
    id: "payments",
    name: "PAYMENTS",
    description:
      "All payments processed within a specific date range.(Excluding DD)",
    isFavorite: true,
    permission: "CanViewPaymentReport",
  },
  {
    id: "directDebit",
    name: "DIRECT DEBIT",
    description:
      "All direct debit payments processed within a specific date range.",
    isFavorite: false,
    permission: "CanViewDirectDebitReport",
  },
  {
    id: "pifExpiring",
    name: "PIF EXPIRING",
    description:
      "All paid in full memberships expiring within a specified date range.",
    isFavorite: false,
    permission: "CanViewPIFExpiringReport",
  },
  {
    id: "directDebitFailedPayment",
    name: "DIRECT DEBIT - FAILED PAYMENT",
    description:
      "All members who have failed a direct debit within a specified date range.",
    isFavorite: false,
    permission: "CanViewFailedPaymentsReport",
  },
  {
    id: "refunds",
    name: "REFUNDS",
    description: "All member refunds.",
    isFavorite: false,
    permission: "",
  },
];
export const executiveReports = [
  {
    id: "churnReport",
    name: "CHURN REPORT",
    description: "Total churn",
    isFavorite: true,
  },
  {
    id: "totalVisits",
    name: "TOTAL VISITS",
    description: "Total Member Visits",
    isFavorite: true,
  },
  {
    id: "totalLeavers",
    name: "LEAVERS",
    description: "Total Members Expiring (Future or Past)",
    isFavorite: true,
  },
  {
    id: "saleReport",
    name: "SALE REPORT",
    description: "Total sale",
    isFavorite: true,
  },
  {
    id: "totalMember",
    name: "TOTAL MEMBER REPORT",
    description: "Total Active Members",
    isFavorite: true,
  },
  {
    id: "suspendedMember",
    name: "SUSPENDED REPORT",
    description: "Total Suspended Members",
    isFavorite: true,
  },
  {
    id: "suspensionDetail",
    name: "SUSPENSION DETAILS REPORT",
    description: "Suspension details information",
    isFavorite: true,
  },
];
export const snapshotReports = [
  {
    id: "snapshotReport",
    name: "SNAPSHOT REPORT",
    description: "Snapshot report for franchise locations",
    isFavorite: true,
  },
];
export const reportsData = [
  {
    fullName: "ARDILL, THOMAS",
    id: "819561",
    homeClub: "CL",
    mobileNumber: "0415256488",
    email: "nameemail@myownemail.com",
    joinDate: "01/12/83",
    startDate: "01/12/83",
    membershipType: "M",
    membershipStatus: "active",
    billingFrequency: "monthly",
    billingAmount: 135.0,
    accountBalance: 23.0,
    totalPayments: 135.0,
    totalDirectDebits: 135.0,
    dateExpiring: "01/12/24",
    ddFailedPayments: 135.0,
    suspensionStartDate: "01/12/83",
    suspensionEndDate: "01/12/83",
    locationFrom: "CL",
    locationTo: "CL",
  },
  {
    fullName: "MICHELLE ANNE, HAYDEN-BONES",
    id: "710588",
    homeClub: "CL",
    mobileNumber: "0421571369",
    email: "nameemail@myownemail.com",
    joinDate: "14/03/01",
    startDate: "14/03/01",
    membershipType: "ST",
    membershipStatus: "active",
    billingFrequency: "monthly",
    billingAmount: 135.0,
    accountBalance: 23.0,
    totalPayments: 135.0,
    totalDirectDebits: 135.0,
    dateExpiring: "01/12/24",
    ddFailedPayments: 135.0,
    suspensionStartDate: "14/03/01",
    suspensionEndDate: "14/03/01",
    specificDate: "14/03/01",
    locationFrom: "CL",
    locationTo: "CL",
  },
  {
    fullName: "BARLEE, MATTHEW",
    id: "457236",
    homeClub: "CL",
    mobileNumber: "0414987251",
    email: "nameemail@myownemail.com",
    joinDate: "30/11/95",
    startDate: "30/11/95",
    membershipType: "FP",
    membershipStatus: "active",
    billingFrequency: "fortnightly",
    totalPayments: 135.0,
    totalDirectDebits: 135.0,
    dateExpiring: "01/12/24",
    billingAmount: 135.0,
    accountBalance: 23.0,
    ddFailedPayments: 135.0,
    suspensionStartDate: "01/12/83",
    suspensionEndDate: "01/12/83",
    locationFrom: "CL",
    locationTo: "CL",
  },
  {
    fullName: "STICHT, AMELIE",
    id: "458725",
    homeClub: "CL",
    mobileNumber: "0434693452",
    email: "nameemail@myownemail.com",
    joinDate: "19/08/05",
    startDate: "19/08/05",
    membershipType: "M",
    membershipStatus: "active",
    billingFrequency: "fortnightly",
    billingAmount: 135.0,
    accountBalance: 23.0,
    totalPayments: 135.0,
    totalDirectDebits: 135.0,
    dateExpiring: "01/12/24",
    ddFailedPayments: 135.0,
    suspensionStartDate: "01/12/83",
    suspensionEndDate: "01/12/83",
    locationFrom: "CL",
    locationTo: "CL",
  },
  {
    fullName: "CLAMP, KIARA",
    id: "857964",
    homeClub: "CL",
    mobileNumber: "0424923461",
    email: "nameemail@myownemail.com",
    joinDate: "22/05/79",
    startDate: "22/05/79",
    membershipType: "M",
    membershipStatus: "active",
    billingFrequency: "fortnightly",
    billingAmount: 135.0,
    accountBalance: 23.0,
    totalPayments: 135.0,
    totalDirectDebits: 135.0,
    dateExpiring: "01/12/24",
    ddFailedPayments: 135.0,
    suspensionStartDate: "01/12/83",
    suspensionEndDate: "01/12/83",
    locationFrom: "CL",
    locationTo: "CL",
  },
];

export const roles = [
  {
    role: "L1",
    permissions: [
      "CanViewMember",
      "CanViewMemberContracts",
      // "CanViewLocation",
      // "CanViewMember",
      // "CanEditMember",
      // "CanViewMemberActivity",
      // "CanAddMemberBlock",
      // "CanAddGuestEntry",
      // "CanViewVisitHistory",
      // "CanAddNotes",
      // "CanSendSmsToMember",
      // "CanSuspendMembership",
      // "CanViewPaymentHistory",
      // "CanEditMemberClassSchedule",
      // "CanDeleteClubHours",
      // "CanEditClubFeatures",
      // "CanViewMemberContracts",
      // "CanViewMemberAuditOperation",
      // "CanViewClassSchedule",
      // "CanViewClassTemplate",
      // "CanViewBrandClassTemplate",
      // "CanViewClassSchedule",
      // "CanViewNotes",
      // "CanViewSms",
      // "CanViewLocation",
      // "CanAddLocationAccessTimes",
      // "CanViewLocationDoorAccess",
      // "CanViewMemberBlocks",
      // "CanViewBrandLocation",
    ],
  },
  {
    role: "L2",
    permissions: [
      "CanViewMember",
      "CanEditMember",
      "CanAddMemberBlock",
      "CanViewMemberActivity",
      "CanAddGuestEntry",
      "CanViewVisitHistory",
      "CanAddNotes",
      "CanSendSmsToMember",
      "CanAddMembership",
      "CanViewAllMembership",
      "CanAddVoucher",
      "CanViewMembership",
      "CanEditMembershipPrice",
      "CanViewMemberContracts",
      "CanSuspendMembership",
      "CanViewPaymentHistory",
      "CanEditMemberClassSchedule",
      "CanViewMembershipAudit",
      "CanViewClubReporting",
      "CanViewFinancialReporting",
      "CanEditRoleAndPermission",
      "CanDeleteClubHours",
      "CanEditLocationContactDetails",
      "CanEditMembershipAccess",
      "CanEditClubMembership",
      "CanEditClubFeatures",
      "CanViewMemberAuditOperation",
      "CanViewClassSchedule",
      "CanViewClassTemplate",
      "CanViewBrandClassTemplate",
      "CanViewClassSchedule",
      "CanViewNotes",
      "CanViewSms",
      "CanViewLocation",
      "CanAddLocationAccessTimes",
      "CanViewLocationDoorAccess",
      "CanViewMemberBlocks",
      "CanViewBrandLocation",
      "CanAddLocationAudit",
      "CanViewAllLocations",
    ],
  },
  {
    role: "L3",
    permissions: [
      "CanViewMember",
      "CanAddMember",
      "CanEditMember",
      "CanAddMemberBlock",
      "CanViewMemberActivity",
      "CanAddGuestEntry",
      "CanAddGuestEntryRemote",
      "CanViewVisitHistory",
      "CanAddNotes",
      "CanSendSmsToMember",
      "CanAddMembership",
      "CanViewAllMembership",
      "CanAddVoucher",
      "CanViewMembership",
      "CanEditMembershipPrice",
      "CanEditMembershipPayment",
      "CanViewMemberContracts",
      "CanSuspendMembership",
      "CanViewPaymentHistory",
      "CanEditMemberClassSchedule",
      "CanViewMembershipAudit",
      "CanViewClubReporting",
      "CanViewFinancialReporting",
      "CanEditRoleAndPermission",
      "CanDeleteClubHours",
      "CanEditLocationContactDetails",
      "CanEditMembershipAccess",
      "CanEditClubMembership",
      "CanEditClubFeatures",
      "CanViewMemberAuditOperation",
      "CanViewClassSchedule",
      "CanViewClassTemplate",
      "CanViewBrandClassTemplate",
      "CanViewClassSchedule",
      "CanViewNotes",
      "CanViewSms",
      "CanViewLocation",
      "CanAddLocationAccessTimes",
      "CanViewLocationDoorAccess",
      "CanViewMemberBlocks",
      "CanViewBrandLocation",
      "CanAddLocationAudit",
      "CanViewAllLocations",
    ],
  },
  {
    role: "L4",
    permissions: [
      "CanViewMember",
      "CanAddMember",
      "CanEditMember",
      "CanAddMemberBlock",
      "CanViewMemberActivity",
      "CanAddGuestEntry",
      "CanAddGuestEntryRemote",
      "CanViewVisitHistory",
      "CanAddNotes",
      "CanSendSmsToMember",
      "CanAddMembership",
      "CanViewAllMembership",
      "CanAddVoucher",
      "CanViewMembership",
      "CanEditMembershipPrice",
      "CanEditMembershipPayment",
      "CanViewMemberContracts",
      "CanSuspendMembership",
      "CanViewPaymentHistory",
      "CanEditMemberClassSchedule",
      "CanViewMembershipAudit",
      "CanViewClubReporting",
      "CanViewFinancialReporting",
      "CanEditRoleAndPermission",
      "CanDeleteClubHours",
      "CanEditLocationContactDetails",
      "CanEditMembershipAccess",
      "CanEditClubMembership",
      "CanEditClubFeatures",
      "CanViewMemberAuditOperation",
      "CanViewClassSchedule",
      "CanViewClassTemplate",
      "CanViewBrandClassTemplate",
      "CanViewClassSchedule",
      "CanViewNotes",
      "CanViewSms",
      "CanViewLocation",
      "CanAddLocationAccessTimes",
      "CanViewLocationDoorAccess",
      "CanViewMemberBlocks",
      "CanViewBrandLocation",
      "CanAddLocationAudit",
      "CanViewAllLocations",
      "CanEditMembershipLocation",
    ],
  },
  {
    role: "L5",
    permissions: [
      "CanAddNotes",
      "CanViewNotes",
      "CanSendSmsToMember",
      "CanViewSms",
      "CanViewLocation",
      "CanEditLocationContactDetails",
      "CanAddLocationAccessTimes",
      "CanDeleteClubHours",
      "CanViewLocationDoorAccess",
      "CanViewMember",
      "CanAddMember",
      "CanEditMember",
      "CanViewStaff",
      "CanAddStaff",
      "CanEditStaff",
      "CanAddMemberBlock",
      "CanViewMemberActivity",
      "CanAddGuestEntry",
      "CanViewVisitHistory",
      "CanAddMembership",
      "CanViewAllMembership",
      "CanAddVoucher",
      "CanViewMembership",
      "CanEditMembershipPrice",
      "CanEditMembershipPayment",
      "CanViewMemberContracts",
      "CanSuspendMembership",
      "CanViewPaymentHistory",
      "CanEditMemberClassSchedule",
      "CanViewMembershipAudit",
      "CanViewClubReporting",
      "CanViewFinancialReporting",
      "CanEditRoleAndPermission",
      "CanEditMembershipAccess",
      "CanEditClubMembership",
      "CanEditClubFeatures",
      "CanViewMemberAuditOperation",
      "CanViewClassTemplate",
      "CanViewBrandClassTemplate",
      "CanViewClassSchedule",
      "CanViewMemberBlocks",
      "CanViewBrandLocation",
      "CanAddLocationAudit",
      "CanViewAllLocations",
      "CanEditMembershipLocation",
      "CanAddGuestEntryRemote",
      "CanViewHealthQuestion",
      "CanAddHealthQuestion",
      "CanEditHealthQuestion",
      "CanDeleteHealthQuestion",
    ],
  },
];

export const brandData1 = {
  data: {
    getGroupStaffByStaffId: {
      items: [
        {
          groupDetails: {
            name: "TEAM 55555",
            groupLocation: {
              items: [
                {
                  locationDetails: null,
                },
                {
                  locationDetails: {
                    id: "22d798b2-5056-b8ce-df34-08ea4eb93796",
                    name: "Goulburn - Lansdowne Street",
                    brandDetails: {
                      name: "LIME",
                    },
                  },
                },
              ],
            },
          },
        },
        {
          groupDetails: {
            name: "TEAM 1",
            groupLocation: {
              items: [
                {
                  locationDetails: null,
                },
                {
                  locationDetails: {
                    id: "9da8b73f-5056-b8ce-df63-ab3c91a970b1",
                    name: "Cleveland",
                    brandDetails: {
                      name: "LIME",
                    },
                  },
                },
              ],
            },
          },
        },
        {
          groupDetails: {
            name: "TEST 7",
            groupLocation: {
              items: [
                {
                  locationDetails: {
                    id: "b0a9f661-a439-a77d-d6a9-9264c6daedab",
                    name: "Crace",
                    brandDetails: {
                      name: "LIME",
                    },
                  },
                },
                {
                  locationDetails: {
                    id: "df3a51d7-b1b4-4f86-a469-81f178be43fa",
                    name: "HIIT Republic Ballarat",
                    brandDetails: {
                      name: "HIIT",
                    },
                  },
                },
              ],
            },
          },
        },
        {
          groupDetails: {
            name: "ACT REGIONAL",
            groupLocation: {
              items: [
                {
                  locationDetails: {
                    id: "cc8bba1a-5056-b8ce-dffd-ac7ad1d8bbde",
                    name: "Phillip",
                    brandDetails: {
                      name: "LIME",
                    },
                  },
                },
                {
                  locationDetails: {
                    id: "48ebbd39-5056-b8ce-dfd8-6a87248c7226",
                    name: "East Brisbane",
                    brandDetails: {
                      name: "LIME",
                    },
                  },
                },
              ],
            },
          },
        },
      ],
    },
  },
};
export const searchReports = [
  {
    link: "/dashboard/report/upgradeDowngrade",
    title: "UPGRADE DOWNGRADE",
  },
  {
    link: "/dashboard/report/allMembers",
    title: "ALL MEMBERS",
  },
  {
    link: "/dashboard/report/memberVisits",
    title: "MEMBER VISITS",
  },
  {
    link: "/dashboard/report/membershipMovement",
    title: "MEMBERSHIP MOVEMENT",
  },
  {
    link: "/dashboard/report/membershipBreakup",
    title: "MEMBERSHIP BREAKUP",
  },
  {
    link: "/dashboard/report/youthMembership",
    title: "YOUTH MEMBERSHIP",
  },
  {
    link: "/dashboard/report/directDebit",
    title: "DIRECT DEBIT",
  },
  {
    link: "/dashboard/report/payments",
    title: "PAYMENTS",
  },
  {
    link: "/dashboard/report/pifExpiring",
    title: "PIF EXPIRING",
  },
  {
    link: "/dashboard/report/directDebitFailedPayment",
    title: "DIRECT DEBIT - FAILED PAYMENT",
  },
  {
    link: "/dashboard/report/refunds",
    title: "REFUNDS",
  },
  {
    link: "/dashboard/report/fpmembership",
    title: "FITNESS PASSPORT MEMBERSHIP",
  },
  {
    link: "/dashboard/report/fpVisit",
    title: "FITNESS PASSPORT VISITS",
  },
  {
    link: "/dashboard/report/churnReport",
    title: "CHURN REPORT",
  },
  {
    link: "/dashboard/report/totalVisits",
    title: "TOTAL VISITS",
  },
  {
    link: "/dashboard/report/totalLeavers",
    title: "LEAVERS",
  },
  {
    link: "/dashboard/report/saleReport",
    title: "SALE REPORT",
  },
  {
    link: "/dashboard/report/totalMember",
    title: "TOTAL MEMBER REPORT",
  },
  {
    link: "/dashboard/report/suspendedMember",
    title: "SUSPENDED REPORT",
  },
  {
    link: "/dashboard/report/suspensionDetail",
    title: "SUSPENSION DETAILS REPORT",
  },
  {
    link: "/dashboard/report/snapshotReport",
    title: "SNAPSHOT REPORT",
  },
];
export const searchLinks = [
  {
    link: "/dashboard/changepassword",
    title: "change password",
  },
  {
    link: "forgotpassword",
    title: "forgot password",
  },
  {
    link: "/dashboard/profile",
    title: "profile",
  },
  {
    link: "/dashboard/member",
    title: "member",
  },
  {
    link: "/dashboard/member/addmember",
    title: "add member",
  },
  {
    link: "/dashboard/membership",
    title: "membership",
  },
  {
    link: "/dashboard/membership/addvoucher",
    title: "add voucher",
  },
  {
    link: "/dashboard/membership/addmembership",
    title: "add membership",
  },
  {
    link: "/dashboard/member/addMembershipToMember",
    title: "add membership to member",
  },
  {
    link: "/dashboard/classes",
    title: "class",
  },
  {
    link: "/dashboard/classes/addClass",
    title: "add class",
  },
  {
    link: "/dashboard/classes/addClassTemplate",
    title: "add class template",
  },
  {
    link: "/dashboard/accesslogs",
    title: "access logs",
  },
  {
    link: "/dashboard/accesslogs/guestentrylogs",
    title: "guest entry logs",
  },
  {
    link: "/dashboard/accesslogs/memberaccesslogs",
    title: "member access logs",
  },
  {
    link: "/dashboard/instructors",
    title: "instructors",
  },
  {
    link: "/dashboard/instructors/addInstructor",
    title: "add instructor",
  },
  {
    link: "/dashboard/rolesandpermissions",
    title: "roles and permissions",
  },
  {
    link: "/dashboard/financial/generateAba",
    title: "generate aba",
  },
  {
    link: "/dashboard/bulkSms",
    title: "bulk sms",
  },
  {
    link: "/dashboard/report",
    title: "reporting",
  },
  {
    link: "/dashboard/staff",
    title: "staff",
  },
  {
    link: "/dashboard/staff/addstaff",
    title: "add staff",
  },
  {
    link: "/dashboard/groups",
    title: "groups",
  },
  {
    link: "/dashboard/groups/addgroup",
    title: "add group",
  },
  {
    link: "/dashboard/location",
    title: "location",
  },
  {
    link: "/dashboard/location/addLocation",
    title: "add location",
  },
  {
    link: "/dashboard/location/addPinCode",
    title: "add pincode",
  },
  {
    link: "/dashboard/location/addStudio/",
    title: "add Studio",
  },
  {
    link: "/dashboard/location/addDoorAccess",
    title: "add door access",
  },
  {
    link: "/dashboard/location/addDoorAccess",
    title: "add door access",
  },
  {
    link: "/dashboard/report/upgradeDowngrade",
    title: "UPGRADE DOWNGRADE",
  },
  {
    link: "/dashboard/report/allMembers",
    title: "ALL MEMBERS",
  },
  {
    link: "/dashboard/report/memberVisits",
    title: "MEMBER VISITS",
  },
  {
    link: "/dashboard/report/membershipMovement",
    title: "MEMBERSHIP MOVEMENT",
  },
  {
    link: "/dashboard/report/youthMembership",
    title: "YOUTH MEMBERSHIP",
  },
  {
    link: "/dashboard/report/membershipBreakup",
    title: "MEMBERSHIP BREAKUP",
  },
  {
    link: "/dashboard/report/directDebit",
    title: "DIRECT DEBIT",
  },
  {
    link: "/dashboard/report/payments",
    title: "PAYMENTS",
  },
  {
    link: "/dashboard/report/pifExpiring",
    title: "PIF EXPIRING",
  },
  {
    link: "/dashboard/report/directDebitFailedPayment",
    title: "DIRECT DEBIT - FAILED PAYMENT",
  },
  {
    link: "/dashboard/report/refunds",
    title: "REFUNDS",
  },
  {
    link: "/dashboard/report/fpmembership",
    title: "FITNESS PASSPORT MEMBERSHIP",
  },
  {
    link: "/dashboard/report/fpVisit",
    title: "FITNESS PASSPORT VISITS",
  },
  {
    link: "/dashboard/report/churnReport",
    title: "CHURN REPORT",
  },
  {
    link: "/dashboard/report/totalVisits",
    title: "TOTAL VISITS",
  },
  {
    link: "/dashboard/report/totalLeavers",
    title: "LEAVERS",
  },
  {
    link: "/dashboard/report/saleReport",
    title: "SALE REPORT",
  },
  {
    link: "/dashboard/report/totalMember",
    title: "TOTAL MEMBER REPORT",
  },
  {
    link: "/dashboard/report/suspendedMember",
    title: "SUSPENDED REPORT",
  },
  {
    link: "/dashboard/report/suspensionDetail",
    title: "SUSPENSION DETAILS REPORT",
  },
  {
    link: "/dashboard/report/snapshotReport",
    title: "SNAPSHOT REPORT",
  },
];
export const HealthQuestionnaireSection = [
  {
    id: 1,
    label: "health check",
  },
  {
    id: 2,
    label: "medical declaration",
  },
];
export const answerType = ["STRING", "NUMBER"];

export const typeOfPayment = {
  OSB: "Outstanding",
  CMS: "CANCEL MEMBERSHIP",
  MDO: "Membership Downgrade",
  MUU: "Membership Upgrade Upfront",
  SSC: "Standard Suspension Cost",
  SSU: "Standard Suspension Upfront",
  MCU: "Medical Suspension Upfront",
  MAV: "Membership Activation",
  ADR: "Adult Registration",
  UBU: "UnBlock Upfront",
  RNM: "Renew Membership",
  AMS: "Add Membership",
  AVM: "Voucher Apply",
};

export const termsAndConditions = [
  {
    brandName: "Plus Fitness",
    link: "https://www.plusfitness.com.au/terms-and-conditions/",
  },
];

// export const getDashboardMemberSuspendedJoinerLeaverURL = (locationId) => {
//   if (locationId) {
//     return `v1/members/stats?locationId=${locationId}&dimensions=active,suspended,joined,gender,left`;
//   } else {
//     return "v1/members/stats?dimensions=active,suspended,joined,gender,left";
//   }
// };

export const debtCollectTypes = ["ARMA", "OTHER"];

export const maxDateValidate = 6;
export const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
export const mastercardRegex =
  /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/;
export const amexRegex = /^3[47][0-9]{13}$/;
