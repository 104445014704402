import RadialChart from "../../charts/radialChart/RadialChart";

export default function ValueInfo({
  heading,
  value,
  chart,
  children,
  childrenPosition,
}) {
  return (
    <div className="flex-col">
      {childrenPosition === "head" ? (
        <>
          <div className="flex-r margin-top ">
            <div className="fs-10 heading">{heading}</div>
            {children}
          </div>
          {chart ? (
            <RadialChart value={value} />
          ) : (
            <p className="fs-54">{value}</p>
          )}
        </>
      ) : (
        <>
          <div className="fs-10 heading">{heading}</div>
          <div className="flex-r">
            {chart ? (
              <RadialChart value={value} />
            ) : (
              <div className="fs-54">{value}</div>
            )}
            {children}
          </div>
        </>
      )}
    </div>
  );
}
