import "./maintenance.scss";

import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { format, isAfter } from "date-fns";

import { useLazyQuery, useMutation } from "@apollo/client";
import { LIST_MAINTENANCE_DATES } from "../../../../gqloperations/queries";
import {
  CREATE_MAINTENANCE,
  UPDATE_MAINTENANCE,
} from "../../../../gqloperations/mutations";

import { useLocationContext } from "../../../../contexts/LocationContext";
import { useAuthContext } from "../../../../contexts/AuthContext/AuthProvider";

import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

import Button from "../../../formUiElements/Button";

import Modal from "../../../modal/Modal";
import StatusModal from "../../../modal/StatusModal";
import AddMaintenanceModal from "../../../modal/AddMaintenanceModal";

import { sortDesc } from "../../../../utils/sort";
import Table from "../../../table/Table";
import actions from "../../../../assets/images/icons/action_menu.png";
import EditMaintenanceModal from "../../../modal/EditMaintenanceModal";
import checkDateOverlap from "../../../../utils/checkDateOverlap";

const Maintenance = () => {
  const toastOptions = {
    autoClose: 1600,
    pauseOnHover: false,
  };
  const ref = useRef();
  const [getListMaintenanceDates, { refetch, loading: fetching }] =
    useLazyQuery(LIST_MAINTENANCE_DATES);
  const [createMaintenanceDate, { loading: creating }] =
    useMutation(CREATE_MAINTENANCE);
  const [updateMaintenance, { loading: updateMaintenanceLoading }] =
    useMutation(UPDATE_MAINTENANCE);

  const { state } = useLocationContext();
  const { id: locationId } = useParams();
  const { memberId: createdBy } = useAuthContext();
  const [listMaintenanceDates, setListMaintenanceDates] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [showActions, setShowActions] = useState({});
  const [componentName, setComponentName] = useState("");
  const [curRow, setCurRow] = useState({});
  const [disabledAddBtn, setDisabledAddBtn] = useState(false);
  const [nextToken, setNextToken] = useState();

  const handleFormatDate = (value) => {
    try {
      return format(new Date(value), "dd-MM-yyyy");
    } catch (error) {
      return "INVALID_DATETIME";
    }
  };
  const handleGetListMaintenanceDates = async (isNextToken) => {
    await getListMaintenanceDates({
      fetchPolicy: "no-cache",
      variables: {
        filter: { locationId: { eq: locationId } },
        limit: 1000,
        nextToken: isNextToken ? nextToken : null,
      },

      onCompleted: (data) => {
        const {
          listLocationMaintenances: { items: listLocationMaintenances },
        } = data;
        const sorted = sortDesc(listLocationMaintenances, "startDateTime");
        if (!isNextToken) {
          setListMaintenanceDates(
            [...sorted].filter((maintenance) => !maintenance.isDeleted)
          );
        } else {
          setListMaintenanceDates(
            [...listMaintenanceDates, ...sorted].filter(
              (maintenance) => !maintenance.isDeleted
            )
          );
        }

        const isFutureEndDate =
          !isAfter(
            new Date(),
            sorted.filter((maintenance) => !maintenance.isDeleted)[0]
              ?.endDateTime
          ) &&
          sorted.filter((maintenance) => !maintenance.isDeleted)?.length > 0;
        setDisabledAddBtn(isFutureEndDate);
        setNextToken(data.listLocationMaintenances.nextToken);
      },
      onError: (error) => toast.error(`${error}`, toastOptions),
    });
  };

  const handleCreateMaintenanceDate = (startDateTime, endDateTime) => {
    const input = {
      locationId,
      createdBy,
      startDateTime,
      endDateTime,
      isDeleted: false,
    };

    createMaintenanceDate({
      variables: { input },
      onCompleted: () => {
        setComponentName("");
        handleGetListMaintenanceDates();
        toast.success("Created successfully!", toastOptions);
      },
      onError: (error) => toast.error(`${error}`, toastOptions),
    });
  };

  const handleEditMaintenanceDate = (startDateTime, endDateTime, id) => {
    const input = {
      id,
      locationId,
      updatedBy: createdBy,
      startDateTime,
      endDateTime,
      isDeleted: false,
    };

    updateMaintenance({
      variables: { input },
      onCompleted: () => {
        setComponentName("");
        handleGetListMaintenanceDates();
        toast.success("Edited successfully!", toastOptions);
      },
      onError: (error) => toast.error(`${error}`, toastOptions),
    });
  };
  const handleDeleteMaintenanceDate = () => {
    const input = {
      id: curRow.id,
      isDeleted: true,
      updatedBy: createdBy,
    };

    updateMaintenance({
      variables: { input },
      onCompleted: () => {
        setComponentName("");

        handleGetListMaintenanceDates();
        toast.success("Deleted successfully!", toastOptions);
      },
      onError: (error) => toast.error(`${error}`, toastOptions),
    });
  };

  useEffect(() => {
    if (state?.index === 11) {
      handleGetListMaintenanceDates();
    }
  }, [state.index]);

  const handleNextToken = () => {
    if (nextToken) {
      handleGetListMaintenanceDates(nextToken);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      setShowActions({});
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [ref]);

  const handleComponent = (componentName, rowObj) => {
    setComponentName(componentName);
    setCurRow(rowObj.maintenanceDetails);
  };

  const columns = [
    {
      accessorKey: "startDateTime",
      header: "Start Date",
      cell: (info) => handleFormatDate(info?.getValue()),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "startDateTime",
    },
    {
      accessorKey: "endDateTime",
      header: "End Date",
      cell: (info) => handleFormatDate(info?.getValue()),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "endDateTime",
    },
    {
      accessorFn: (row) => {
        return row;
      },
      header: "Ongoing",
      cell: (info) => {
        if (
          checkDateOverlap(new Date(), {
            start: info.getValue().startDateTime,
            end: info.getValue().endDateTime,
          })
        ) {
          return <div className="text-green">YES</div>;
        } else {
          return <div className="text-red">NO</div>;
        }
      },
      enableSorting: true,
      enableGlobalFilter: true,
      id: "onGoing",
    },
    {
      id: "row-actions5",
      header: "ACTIONS",
      cell: (props) => {
        const { id } = props?.row?.original;

        const areActionsVisible = showActions.hasOwnProperty(id);
        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              onClick={(e) => {
                e.stopPropagation();
                setShowActions((prevShowActions) => ({
                  ...prevShowActions,
                  [id]: !areActionsVisible,
                }));
              }}
              alt=""
            />
            {areActionsVisible && (
              <span className="action-options-menu" ref={ref}>
                <ul className="membership-options maintenance-options">
                  <li
                    onClick={() => {
                      handleComponent("edit", {
                        id: id,
                        maintenanceDetails: props?.row?.original,
                      });
                    }}
                  >
                    <Button
                      name="Edit"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li
                    onClick={() => {
                      handleComponent("delete", {
                        id: id,
                        maintenanceDetails: props?.row?.original,
                      });
                    }}
                  >
                    <Button
                      name="Delete"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <main className="maintenance">
        <section className="maintenance__header">
          <Button
            click={() => {
              setComponentName("add");
            }}
            disabled={
              fetching || creating || updateMaintenanceLoading || disabledAddBtn
            }
            name={"Add Date"}
            btntype="button"
            btnname="add-maintenance"
            className={`btn btn-confirm btn-large fs-12 ${
              disabledAddBtn ? "btn-disabled" : ""
            }`}
          />
        </section>

        <section className="maintenance__content">
          {fetching ? (
            <div className="loader-holder">
              <div className="loader">
                <BeatLoader color="white" size={10} />
              </div>
            </div>
          ) : listMaintenanceDates?.length > 0 ? (
            <Table
              data={listMaintenanceDates}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              columns={columns}
              sorting={sorting}
              setSorting={setSorting}
              rowsPerPage={10}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              handleNextToken={handleNextToken}
              nextToken={nextToken}
            />
          ) : (
            <div className="no-data-holder">
              <div class="no-data">
                <h3 class="fs-12 uppercase">No Records Found</h3>
              </div>
            </div>
          )}
        </section>
      </main>
      {componentName && (
        <Modal>
          {componentName === "add" && (
            <AddMaintenanceModal
              modalClose={() => {
                setComponentName("");
              }}
              loading={creating}
              onCreateMaintenance={handleCreateMaintenanceDate}
              listMaintenanceDates={listMaintenanceDates}
            />
          )}
          {componentName === "edit" && (
            <EditMaintenanceModal
              modalClose={() => {
                setComponentName("");
              }}
              loading={updateMaintenanceLoading}
              onEditMaintenance={handleEditMaintenanceDate}
              maintenanceDetails={curRow}
              listMaintenanceDates={listMaintenanceDates}
            />
          )}
          {componentName === "delete" && (
            <StatusModal
              modalClose={() => {
                setComponentName("");
              }}
              handleStatusClick={handleDeleteMaintenanceDate}
              loading={updateMaintenanceLoading}
              disabled={updateMaintenanceLoading}
              modalHeaderTxt="Delete Maintenance"
              modalParaText="Are you sure you want to delete this maintenance?"
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default Maintenance;
