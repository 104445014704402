import { useEffect, useState } from "react";
import Table from "../../table/Table";
import ExportGroup from "../../export/ExportGroup";
import { AiOutlineSearch } from "react-icons/ai";
import { useLazyQuery } from "@apollo/client";
import { GET_TOTAL_ACTIVE_REPORT } from "../../../gqloperations/queries";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";
import { ReportGeneratorFormWithMonth } from "../../../pages/index";
import "./executiveReportsTables.scss";
import { BeatLoader } from "react-spinners";
import useCustomFetch from "../../../hooks/useCustomFetch";
import { API } from "../../../utils/API";
import { toast } from "react-toastify";
import { useHandleError } from '../../../hooks/useHandleError';

export default function TotalMemberReport() {
  const [generate, setGenerate] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [tableData, setTableData] = useState();
  const handleError = useHandleError();

  const columns = [
    {
      accessorKey: "location.name",
      header: "CLUB",
      cell: (info) => info.getValue(),
      footer: "TOTAL",
      enableSorting: true,
      enableGlobalFilter: true,
      id: "club",
    },
    {
      accessorKey: "location.state",
      header: "STATE",
      cell: (info) => info.getValue(),
      // footer: "TOTAL",
      enableSorting: true,
      enableGlobalFilter: true,
      id: "state",
    },
    {
      // accessorKey: "locationDetail",
      header: "REGION",
      cell: (info) => info.getValue(),
      // footer: "TOTAL",
      enableSorting: true,
      enableGlobalFilter: true,
      id: "region",
    },
    {
      accessorKey: "count",
      header: "ACTIVE MEMBER",
      cell: (info) => info.getValue(),
      footer: () => data?.data?.total?.activeCount,
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalMember",
    },
  ];
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  const [endPoint, setEndpoint] = useState();
  const [params, setParams] = useState();
  const { data, isLoading, error } = useCustomFetch(
    endPoint && endPoint,
    params && params
  );
  const handleGenerate = (values, onSubmitProps) => {
    setGenerate(true);
    if (values.chooseDateFrom) {
      const parameters = {
        brandId: values.brand,
        type: values.reportCategory,
        reportDate: values.chooseDateFrom,
        paymentType: values.reportCategory,
        locationType: values.additionalSelect,
        ...(values.location === "-1" ? {} : { locationId: values.location }),
      };
      setParams(parameters);
      setEndpoint(API.executiveActiveMemberReport);
    }
  };
  useEffect(() => {
    if (error && endPoint) {
      setTableData([]);
      handleError(error.response.data?.message ?? "Error when fetching api");
    } else {
      if (data && data.data.data) setTableData(data.data.data);
    }
  }, [data, isLoading, error]);
  return (
    <>
      <ReportGeneratorFormWithMonth
        brandAndLocation
        onlyStartDate
        noTimezone
        startDateRequired
        noDefaultSelect
        additionalSelect={{
          option: [
            { id: "ALL", name: "ALL" },
            { id: "FRANCHISEE", name: "FRANCHISEE" },
            { id: "CORPORATE", name: "CORPORATE" },
          ],
          label: "Location type",
        }}
        handleSubmit={handleGenerate}
      />
      {isLoading && (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      )}
      {!isLoading && generate && (
        <div className="actions-row report-tables mt-24 mb-12">
          <ExportGroup
            data={tableData ? tableData : []}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
          />
          <div className="search-container">
            <form onSubmit={onSearchBarSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="search"
                    id="search"
                    value={searchBarInputVal}
                    onChange={handleChange}
                    placeholder="Search"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* <p className="totalCount">TOTAL: {tableData.length}</p> */}

      {!isLoading && (generate || tableData) && (
        <div className="report-table-container-all no-scroll-bar">
          <Table
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            hasFooter={true}
          />
        </div>
      )}
    </>
  );
}
