import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsCardText } from "react-icons/bs";
import { AiOutlineSearch } from "react-icons/ai";
import { useMemberContext } from "../../../contexts/MemberContext";
import { SET_MEMBER_ID } from "../../../actions";
import { BeatLoader } from "react-spinners";
import useCustomFetch from "../../../hooks/useCustomFetch";
import { useCheckMemberProfile } from "../../../hooks/useCheckMemberProfile";
import { API } from "../../../utils/API";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { Table, ExportGroup, GenerateBox } from "../../../components";
import { useHandleError } from '../../../hooks/useHandleError';

export default function MemberVisitsReport() {
  let navigate = useNavigate();
  const [generate, setGenerate] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [tableData, setTableData] = useState([]);
  const { sidebarLocationId, sidebarBrandId, dispatch, createLog } =
    useMemberContext();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endPoint, setEndPoint] = useState();
  const [params, setParams] = useState();
  const handleError = useHandleError();
  const { data, isLoading, error } = useCustomFetch(
    endPoint && endPoint,
    params && params
  );
  const { checkMemberProfile } = useCheckMemberProfile();
  const paginationData = useRef();
  useEffect(() => {
    if (sidebarBrandId && sidebarLocationId) handleGenerate(100, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarBrandId, sidebarLocationId]);
  useEffect(() => {
    if (endDate && startDate && generate) {
      handleGenerate(100, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generate]);
  useEffect(() => {
    if (error && endPoint) {
      setTableData([]);
      handleError(error?.response?.data?.message ?? "Error when fetching api");
    } else if (data) {
      if (params.page !== 1) {
        setTableData([...tableData, ...data.data.data]);
      } else setTableData([...data.data.data]);
    }
    setGenerate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);
  useEffect(() => {
    setGenerate(false);
  }, [sidebarBrandId, sidebarLocationId]);

  const handleGenerate = (limit, page) => {
    paginationData.current = { limit: limit, page: page };
    let parameters = {
      brandId: sidebarBrandId,
      startDate: startDate,
      locationId: sidebarLocationId,
      endDate: endDate,
    };
    if (sidebarLocationId === 0) {
      delete parameters.locationId;
    }
    if (startDate && endDate) {
      const pagination = limit ? { limit: limit, page: page } : undefined;
      if (pagination) parameters = { ...parameters, ...pagination };

      setEndPoint(API.memberVisitsReport);
      setParams(parameters);
    }
  };
  const handleNextToken = () => {
    handleGenerate(
      paginationData.current.limit,
      paginationData.current.page + 1
    );
  };
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  const handleRowClick = (id, email) => {
    dispatch({
      type: SET_MEMBER_ID,
      payload: {
        memberId: id,
      },
    });
    navigate(`/dashboard/member/${id}`);
  };

  const columns = [
    {
      accessorKey: "memberName",
      header: "NAME",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "memberName",
    },
    {
      accessorKey: "swipePhoto",
      header: "SWIPE PHOTO",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "swipePhoto",
    },
    {
      accessorFn: (originalRow) => {
        if (originalRow?.createdAt) {
          return format(
            new Date(originalRow?.createdAt),
            "dd/MM/yyyy HH:mm:ss"
          );
        } else {
          return "";
        }
      },
      header: "TIME",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "createdAt",
    },
    {
      accessorFn: (originalRow) => {
        return originalRow?.isValid ? "YES" : "NO";
      },
      header: "VALID",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: true,
      id: "isValid",
    },
    {
      accessorKey: "memberHomeClub",
      header: "HOME CLUB",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "memberHomeClub",
    },
    {
      accessorFn: (row) => row,
      header: "LOCATION",
      cell: (info) => {
        let deviceName = "OTHER";
        if (info.getValue()?.deviceType === "DOOR") deviceName = "FOB";
        if (info.getValue()?.deviceType === "MOBILE") deviceName = "APP";
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{info.getValue()?.doorName}</span>
            <span style={{ color: "orange" }}>{deviceName}</span>
            <span>{info.getValue()?.accessPoint}</span>
          </div>
        );
      },
      enableSorting: false,
      enableGlobalFilter: true,
      id: "locationVisit",
    },
    // {
    //   id: "row-actions",
    //   cell: (props) => (
    //     <div className="row-action-container">
    //       <BsCardText
    //         onClick={() => {
    //           const id = props.row.original.memberId;
    //           const email = props.row.getAllCells()[5].getValue();
    //           handleRowClick(id, email);
    //           createLog(
    //             `Clicked ${props.row.original.givenName} ${props.row.original.surname}`,
    //             "member",
    //             `${props.row.original.memberId}`
    //           );
    //         }}
    //       />
    //     </div>
    //   ),
    // },
  ];
  return (
    <>
      <GenerateBox
        type="Payment"
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setGenerate={setGenerate}
      />
      {tableData.length && !isLoading ? (
        <div className="actions-row report-tables mt-24 mb-12">
          <ExportGroup
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
          />
          <div className="search-container">
            <form onSubmit={onSearchBarSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="payment-report-search"
                    id="payment-report-search"
                    value={searchBarInputVal}
                    onChange={handleChange}
                    placeholder="Search by given name or surname"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : null}
      {tableData.length > 0 && (
        <p className="mt-24 fs-14">TOTAL: {data?.data?.total}</p>
      )}

      {isLoading ? (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      ) : null}
      {!isLoading ? (
        <div className="report-table-container-all">
          <Table
            onRowClick={(i) => {
              checkMemberProfile(i.memberName, i.memberId);
            }}
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            nextToken={tableData.length < data?.data?.total}
            handleNextToken={handleNextToken}
          />
        </div>
      ) : null}
    </>
  );
}
