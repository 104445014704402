import "./featureTile.scss";
import React from "react";

const FeatureTile = (props) => {
  // const { imgSrc } = this.props;
  return (
    <div className="feature-tile pd-12">
      <h3 className=" feature-name fs-14 uppercase pb-32 ">
        {props.featureName}
      </h3>
      <img src={props.imgSrc} alt="feature" />
    </div>
  );
};

export default FeatureTile;
