import "./manualEntry.scss";
import { useMemberContext } from "../../contexts/MemberContext";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts";
import { format } from "date-fns";
import { useMutation } from "@apollo/client";

import { CREATE_GUEST_ENTRY } from "../../gqloperations/mutations";
import { GET_GUEST_ENTRY_LOG_BY_MEMBER_ID } from "../../gqloperations/queries";
import { BeatLoader } from "react-spinners";
import Button from "../formUiElements/Button";
import Input from "../formUiElements/input/Input";
import entry from "../../assets/images/entry.png";
import { guestEntrySchema } from "../../validations/Validation";
import InternationalPhoneInput from "../formUiElements/input/InternationalPhoneInput";

const ManualEntry = (props) => {
  const { loggedUser } = useContext(AuthContext);
  const { sidebarLocationData } = useMemberContext();
  const [locationId, setLocationId] = useState("");
  const [locationError, setLocationError] = useState(false);
  const [
    createGuestEntry,
    // {
    //   data: guestentrydata,
    //   loading: guestentryloading,
    //   error: guestentryerror,
    // },
  ] = useMutation(CREATE_GUEST_ENTRY);

  const timestamp = new Date().getTime();
  const date = format(timestamp, "E d/LL/yy k:mm:ss");
  const handleLocationChange = (e) => {
    if (!e.target.value) setLocationError(true);
    const selectedLocation = sidebarLocationData.find(
      (item) => item.locationName.toLowerCase() === e.target.value.toLowerCase()
    );
    if (selectedLocation) {
      setLocationError(false);
      setLocationId(selectedLocation.locationId);
    }
    if (!selectedLocation) setLocationId("");
  };
  const onSubmit = (values, OnSubmitProps) => {
    if (!locationId) {
      setLocationError(true);
      return;
    }
    createGuestEntry({
      variables: {
        input: {
          ...values,
          date: new Date(timestamp),
          locationId,
        },
      },
      onCompleted: () => {
        OnSubmitProps.resetForm();
        setLocationError(false);
        props.onClick();
        toast.success("Guest Entry Created Successfully");
      },
      refetchQueries: [
        {
          query: GET_GUEST_ENTRY_LOG_BY_MEMBER_ID,
          variables: {
            memberId: loggedUser.getMember.memberId,
          },
        },
      ],
    });
    OnSubmitProps.setSubmitting(false);
  };

  // const locationItems = sidebarLocationData?.getLocationByBrandId?.items;
  // const sortedLocationItems = locationItems.sort((a, b) => {
  //   if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  //   if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  //   return 0;
  // });
  return (
    <Formik
      initialValues={{
        date: `${date}`,
        firstName: "",
        locationId: "",
        lastName: "",
        contactNumber: "",
        reasonForEntry: "",
        memberId: `${loggedUser.getMember.memberId}`,
      }}
      validationSchema={guestEntrySchema}
      onSubmit={async (values, OnSubmitProps) => {
        await onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <div className="manual-container non-membership pd-32">
            {sidebarLocationData && (
              <Form>
                <div className="entry-info-container ">
                  <div className="entry-title ">
                    <h3 className="fs-35 pb-12">
                      Allow GUest Entry to Selected Club
                    </h3>
                  </div>
                  <div className="entry-info">
                    <div className="date">
                      <Input
                        type="datetime"
                        label="Date"
                        name="date"
                        id="date"
                        autoComplete="off"
                        disabled="on"
                      />
                    </div>
                    <div className="location">
                      <div className="location-heading fs-12">Location*</div>
                      <div className="select-element location-value loc-select">
                        {/* <p>{sidebarLocationName}</p> */}
                        <input
                          type="text"
                          list="locations-manual-entry"
                          placeholder="--Select a location--"
                          onChange={(e) => handleLocationChange(e)}
                          className="select-element-value"
                        />
                        <datalist id="locations-manual-entry">
                          {sidebarLocationData.map((option) => {
                            return (
                              <option
                                key={option.locationId}
                                value={option.locationName}
                              />
                            );
                          })}
                        </datalist>
                        {locationError && (
                          <p className="error">Invalid location!</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="guest-info-container pb-24">
                  <div className="guest-title pt-18 pb-18">
                    <h3 className="fs-14">Guest Details</h3>
                  </div>
                  <div className="guest-details">
                    <div className="fn">
                      <Input
                        type="text"
                        label="First Name*"
                        name="firstName"
                        id="firstName"
                        autoComplete="off"
                      />
                    </div>
                    <div className="ln">
                      <Input
                        type="text"
                        label="Last Name*"
                        name="lastName"
                        id="lastName"
                        autoComplete="off"
                      />
                    </div>
                    <div className="mobile">
                      <InternationalPhoneInput
                        title="Mobile Number *"
                        name="contactNumber"
                        id="contactNumber"
                        editable={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="reason-container">
                  <div className="reason-message">
                    <p className="reason-title fs-10 text-gray uppercase">
                      Reason for Entry *
                    </p>
                    <textarea
                      className="reason-box fs-12"
                      id="reasonForEntry"
                      name="reasonForEntry"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.reasonForEntry}
                    ></textarea>
                  </div>
                  <div className="entry-view">
                    <p className="reason-title fs-10 text-gray uppercase">
                      Club Entry Live View
                    </p>
                    <img src={entry} alt="entry icon"></img>
                  </div>
                </div>
                {formik.errors.reasonForEntry &&
                  formik.touched.reasonForEntry && (
                    <div id="feedback" className="error fs-12">
                      {formik.errors.reasonForEntry}
                    </div>
                  )}
                <div className="entry-btn-group">
                  {/* {formik.isSubmitting && (
                    <Button
                      name={<BeatLoader color="white" />}
                      btntype="submit"
                      btnname="submit"
                      disabled={true}
                      className="btn btn-confirm btn-small fs-12"
                    />
                  )}
                  {!formik.isSubmitting && (
                    <Button
                      name="Grant Access"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-confirm btn-small fs-12"
                    />
                  )} */}
                  <Button
                    loading={formik.isSubmitting}
                    name={"Grant Access"}
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-confirm btn-small fs-12"
                    disabled={formik.isSubmitting ? true : false}
                  />
                  <Button
                    name="cancel"
                    btntype="button"
                    btnname="cancel"
                    click={props.onClick}
                    className="btn btn-deny btn-small fs-12"
                  />
                </div>
              </Form>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default ManualEntry;
