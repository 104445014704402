import "./editpincode.scss";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { doorAccessSchema } from "../../../validations/LocationValidation";
import {
  UPDATE_DOOR_ACCESS_CODE,
  CREATE_LOCATION_AUDIT_OPERATION,
} from "../../../gqloperations/mutations";
import {
  GET_DOOR_ACCESS_CODE_BY_ID,
  GET_DOOR_ACCESS_CODE_BY_LOCATIONID,
} from "../../../gqloperations/queries";
import { Header, Button, Input } from "../../../components";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";
import { useLocationContext } from "../../../contexts/LocationContext";
import { useHandleError } from '../../../hooks/useHandleError';

const EditPinCode = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { loggedUser } = useAuthContext();
  const handleError = useHandleError();
  const { state, dispatch } = useLocationContext();
  const [oldAttribute, setOldAttribute] = useState({});
  const {
    loading: pinCodeLoading,
    error: pinCodeError,
    data: pinCodeData,
  } = useQuery(GET_DOOR_ACCESS_CODE_BY_ID, {
    variables: {
      id: id,
    },
  });
  const [auditLocationOperation] = useMutation(CREATE_LOCATION_AUDIT_OPERATION);
  const [updatePinCode] = useMutation(UPDATE_DOOR_ACCESS_CODE);
  useEffect(() => {
    if (pinCodeData) {
      setOldAttribute({
        doorName: pinCodeData.getDoorAccessCode.doorName,
        code: pinCodeData.getDoorAccessCode.code,
        instruction: pinCodeData.getDoorAccessCode.instruction,
      });
    }
  }, [pinCodeData]);

  const createLog = async (values) => {
    delete values.id;
    const newAttributeName = Object.keys(values);
    const newAttributeValue = Object.values(values);
    const oldAttributeName = Object.keys(oldAttribute);
    const oldAttributeValue = Object.values(oldAttribute);

    const auditLog = {
      newAttributeValue: newAttributeValue,
      newAttributeName: newAttributeName,
      oldAttributeValue: oldAttributeValue,
      oldAttributeName: oldAttributeName,
      locationId: `${state.id}`,
      pinCodeId: `${id}`,
      updatedBy: loggedUser?.getMember?.memberId,
    };
    try {
      await auditLocationOperation({
        variables: {
          input: auditLog,
        },
        onCompleted: () => {
          dispatch({ type: "SET_INDEX", payload: 6 });
          navigate(`/dashboard/location/specificLocation/${state.id}`);
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };
  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("edit-pincode-form").reset();
    navigate(-1);
  };
  const onSubmit = async (values, OnSubmitProps) => {
    for (const key in values) {
      for (const key1 in oldAttribute) {
        if (values[`${key}`] === oldAttribute[`${key1}`]) {
          delete values[`${key}`];
          delete oldAttribute[`${key}`];
        }
      }
    }
    try {
      await updatePinCode({
        variables: {
          input: { ...values, id },
        },
        onCompleted: () => {
          OnSubmitProps.resetForm();
          createLog(values);
          toast.success("Updated successfully");
        },
        refetchQueries: [
          {
            query: GET_DOOR_ACCESS_CODE_BY_LOCATIONID,
            variables: {
              locationId: `${state.id}`,
            },
          },
        ],
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };

  if (pinCodeLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (pinCodeError) {
    return (
      <div>
        <div>Problem in getting members</div>
      </div>
    );
  }

  return (
    <>
      {pinCodeData && (
        <Formik
          enableReinitialize
          initialValues={{
            doorName: pinCodeData.getDoorAccessCode.doorName,
            code: pinCodeData.getDoorAccessCode.code,
            instruction: pinCodeData.getDoorAccessCode.instruction,
          }}
          validationSchema={doorAccessSchema}
          onSubmit={(values, OnSubmitProps) => {
            onSubmit(values, OnSubmitProps);
          }}
        >
          {(formik) => {
            return (
              <>
                <Form id="edit-pincode-form">
                  <Header pageTitle="Edit Pin Code" />
                  <div className="edit-pin-card pd-12 mt-32 mb-12">
                    <div className="pin-info flex-wrap">
                      <Input
                        type="text"
                        label="Door Name * "
                        name="doorName"
                        id="doorName"
                        autoComplete="off"
                      />
                      <Input
                        type="text"
                        label="Code"
                        name="code"
                        id="code"
                        autoComplete="off"
                      />
                    </div>
                    <div className="edit-instruction">
                      <p className="fs-12 text-gray">Instruction</p>
                      <textarea
                        className="reason-box fs-12"
                        id="instruction"
                        name="instruction"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.instruction}
                      ></textarea>
                    </div>
                  </div>

                  <div className="save-btn right-btn">
                    {/* <Button
                      name="SAVE"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-confirm btn-large fs-12"
                    /> */}
                    <Button
                      loading={formik.isSubmitting}
                      name={"Submit"}
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-confirm btn-large fs-12"
                      disabled={formik.isSubmitting ? true : false}
                    />
                    <Button
                      name="CANCEL"
                      btntype="reset"
                      btnname="reset"
                      className="btn btn-deny btn-large fs-12"
                      click={handleCancel}
                    />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default EditPinCode;
