import "./location.scss";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { AiOutlineEdit, AiOutlineSearch } from "react-icons/ai";
import { useLocationContext } from "../../contexts/LocationContext";
import twentyFourHour from "../../assets/images/icons/twentyfour.png";
import twentyFourHourCrossed from "../../assets/images/icons/twentyfourcrossed.png";
import getTypeIcon from "../../utils/getTypeIcon";
import { brandTypes } from "../../constants/commonConstant";
import { ExportGroup, Table, Header, Button, Card } from "../../components";
import { useCustomFunctionHook } from "../../hooks/useCustomHook";
import { SEARCH_LOCATION } from "../../gqloperations/queries";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useMemberContext } from "../../contexts/MemberContext";

const Location = () => {
  let navigate = useNavigate();
  const { permissions, locationAccess } = useAuthContext();
  const { createLog, sidebarBrandId } = useMemberContext();
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const { dispatch } = useLocationContext();
  const [variable, setVariable] = useState();
  const [tableData, setTableData] = useState([]);
  const [nextToken, setNextToken] = useState();

  const {
    getData: getAllLocation,
    loading: locationLoading,
    data: locationData,
    error: locationError,
  } = useCustomFunctionHook();

  const handleRowClick = (id) => {
    dispatch({ type: "SET_INDEX", payload: 0 });
    navigate(`/dashboard/location/specificLocation/${id}`);
  };

  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal?.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  const handleNextToken = () => {
    if (nextToken) {
      setVariable({
        ...variable,
        nextToken: nextToken,
      });
    }
  };

  useEffect(() => {
    setTableData([]);
    setNextToken();
    setVariable();
    let filterLocationValues = [];
    const getLocationIDsForBrand = (dataArray, targetBrandId) => {
      const brandObject = dataArray.find(
        (item) => item.brandId === targetBrandId
      );

      if (brandObject) {
        return brandObject.locations.map((location) => location.locationId);
      }

      return [];
    };

    const locationValues = getLocationIDsForBrand(
      locationAccess.brands,
      sidebarBrandId
    );

    for (let i = 0; i < locationValues.length; i++) {
      filterLocationValues = [
        ...filterLocationValues,
        { id: { eq: locationValues[i] } },
      ];
    }

    const variables = {
      filters: {
        or: [...filterLocationValues],
        and: { status: { eq: "ACTIVE" } },
      },
      sort: [{ field: "createdAt", direction: "desc" }],
      limit: 1000,
    };
    setVariable(variables);
    createLog("CLICKED Location tab on the sidebar", "sidebar");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarBrandId]);

  useEffect(() => {
    const fetchData = async () => {
      await getAllLocation({ query: SEARCH_LOCATION, variable: variable });
    };
    if (variable) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variable]);

  useEffect(() => {
    if (
      tableData.length === 0 &&
      locationData?.searchLocations?.items.length > 0
    ) {
      setTableData([...locationData?.searchLocations?.items]);
    }
    if (
      tableData.length > 0 &&
      locationData?.searchLocations?.items.length > 0
    ) {
      setTableData([...tableData, ...locationData?.searchLocations?.items]);
    }
    setNextToken(locationData?.searchLocations?.nextToken);
  }, [locationData]);

  const columns = [
    {
      accessorFn: (originalRow) => {
        if (!originalRow?.brandDetails?.name) return;
        const brandName = originalRow?.brandDetails
          ? originalRow?.brandDetails?.name
          : "";
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },

      header: "Brand",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: true,
      enableGlobalFilter: false,
      id: "branddetails",
    },
    {
      accessorFn: (row) =>
        row?.state || !row?.state === null
          ? `${row?.name} (${row?.state})`
          : `${row?.name}`,
      header: "LOCATION",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "name",
    },

    {
      accessorKey: "is24Hour",
      header: "24H",
      cell: (info) =>
        info.getValue() === true ? (
          <img src={twentyFourHour} alt="24 hours" />
        ) : (
          <img src={twentyFourHourCrossed} alt="not 24 hours" />
        ),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "is24hour",
    },
    {
      accessorFn: (originalRow) => {
        if (!originalRow?.managerDetails) return;
        const areaManagerName = `${originalRow?.managerDetails?.givenName} ${originalRow?.managerDetails?.surname}`;
        return areaManagerName;
      },

      header: "CLUB MANAGER",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "managerdetails",
    },
    {
      accessorFn: (originalRow) => {
        if (!originalRow?.areaManagerDetails) return;
        const managerName = `${originalRow?.areaManagerDetails?.givenName} ${originalRow?.areaManagerDetails?.surname}`;
        return managerName;
      },
      header: "AREA MANAGER",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "areamanagerdetails",
    },
    {
      id: "row-actions",
      cell: (props) =>
        permissions.includes("CanViewLocation") && (
          <AiOutlineEdit
            onClick={() => {
              handleRowClick(props.row.original.id);
            }}
          />
        ),
    },
  ];
  // const handleRowClick = (id) => {
  //   dispatch({ type: "SET_INDEX", payload: 0 });
  //   navigate(`/dashboard/location/specificLocation/${id}`);
  // };

  if (locationLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (locationError) {
    return (
      <div>
        <div>Error: Problem getting members</div>
      </div>
    );
  }
  if (locationAccess.locationAll.length === 0) {
    return (
      <div className="dashboard-loading">
        <div>You don't have access to view any location</div>
      </div>
    );
  }

  return (
    <>
      <div className="location">
        <Header pageTitle="Locations" />
        <div className="location-container mt-24">
          <div className="location-info">
            <div className="row1-infocards">
              <div className="col1">
                <Card
                  title="Total Clubs"
                  heading="Total operating clubs"
                  value={1}
                  icon="total"
                />
              </div>
              <div className="col2">
                <Card
                  title="Future"
                  heading="Clubs opening soon"
                  value=""
                  icon="future"
                />
              </div>
              <div className="col3">
                <Card
                  title="AVG. DAILY VISITS"
                  heading="Avg. visits per club"
                  value=""
                  icon="MdOutlineArrowDropDown"
                />
              </div>
              <div className="col4">
                <Card
                  title="AVG. MEMBERS"
                  heading="Average member per club"
                  value=""
                  icon="average"
                />
              </div>
            </div>
            <div className="row2 mt-24 mb-12">
              <div className="flex flex-wrap">
                {tableData.length > 0 && (
                  <ExportGroup
                    data={tableData}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    columns={columns}
                    sorting={sorting}
                  />
                )}
              </div>
              <div className=" actions-row ">
                <div className="left-elements">
                  <div className="search-container">
                    <form onSubmit={onSearchBarSubmit}>
                      <div className="search-bar">
                        <div className="search-bar-container">
                          <input
                            className="fs-10"
                            type="text"
                            name="search"
                            id="search"
                            value={searchBarInputVal}
                            onChange={handleChange}
                            placeholder="Location Name"
                          />
                          <button type="submit">
                            <AiOutlineSearch />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* // <div className="right-btn">
                  //   <Link to="/dashboard/location/addLocation">
                  //     <Button
                  //       name="add Location"
                  //       btntype="submit"
                  //       btnname="submit"
                  //       className="btn-primary fs-12"
                  //     />
                  //   </Link>
                  // </div> */}
                  {permissions.includes("CanCreateLocation") && (
                    <div className="right-btn">
                      <Link to="/dashboard/location/addLocation">
                        <Button
                          name="add Location"
                          btntype="submit"
                          btnname="submit"
                          className="btn-primary fs-12"
                        />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {!locationLoading && !locationError && (
          <>
            <Table
              onRowClick={(i) => {
                if (!permissions.includes("CanViewLocation")) return;
                const id = i.id;
                handleRowClick(id);
                createLog(`Clicked ${i.name}`, "location", `${i.id}`);
              }}
              data={tableData}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              sorting={sorting}
              setSorting={setSorting}
              columns={columns}
              rowsPerPage={10}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              handleNextToken={handleNextToken}
              nextToken={nextToken}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Location;
