import { addDays, addWeeks, addMonths, addYears, subDays } from "date-fns";

export function addFrequencyToDate(date, frequency) {
  let addTime;
  switch (frequency.type) {
    case "DAYS":
      addTime = addDays;
      break;
    case "WEEKS":
      addTime = addWeeks;
      break;
    case "MONTHS":
      addTime = addMonths;
      break;
    case "YEARS":
      addTime = addYears;
      break;
    default:
      throw new Error("addFrequencyToDate: Invalid paymentFrequency type");
  }
  return subDays(addTime(date, frequency.amount), 1);
}
