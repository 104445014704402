import "./sales.scss";
import React, { useState } from "react";
import { Formik, Form } from "formik";
import { useMemberContext } from "../../../contexts/MemberContext";
import { SET_OLD_VALUES } from "../../../actions";
import { clubSalesSchema } from "../../../validations/LocationValidation";
import { Input, Button, ToggleFormik } from "../../../components";
import { toast } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";

const Sales = (props) => {
  const { permissions } = useAuthContext();
  const { onSubmit, state } = props;
  const { dispatch } = useMemberContext();

  const [isActive, setIsActive] = useState(true);
  const handleIsActive = () => {
    setIsActive(false);
    dispatch({
      type: SET_OLD_VALUES,
      payload: {
        openDate: state.location.getLocation.openDate
          ? state.location.getLocation.openDate
          : "",
        isPreSaleOn: state.location.getLocation.isPreSaleOn ?? "",
        isHiddenOnline: state.location.getLocation.isHiddenOnline ?? "",
        isFreeTrialApp: state.location.getLocation.isFreeTrialApp ?? "",
        freeTrialLengthDays:
          state.location.getLocation.freeTrialLengthDays ?? "",
        trialVisitLimit: state.location.getLocation.trialVisitLimit ?? "",
      },
    });
  };

  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("sales-staff-form").reset();
    setIsActive(true);
  };

  return (
    <>
      {state.location && (
        <Formik
          enableReinitialize
          initialValues={{
            id: state.id,
            openDate: state.location.getLocation.openDate
              ? state.location.getLocation.openDate.split("T")[0]
              : "",
            isPreSaleOn: state.location.getLocation.isPreSaleOn ?? "",
            isHiddenOnline: state.location.getLocation.isHiddenOnline ?? "",
            isFreeTrialApp: state.location.getLocation.isFreeTrialApp ?? "",
            freeTrialLengthDays:
              state.location.getLocation.freeTrialLengthDays ?? "",
            trialVisitLimit: state.location.getLocation.trialVisitLimit ?? "",
          }}
          validationSchema={clubSalesSchema}
          onSubmit={async (values, OnSubmitProps) => {
            onSubmit(values, OnSubmitProps);
            setIsActive(true);
          }}
        >
          {(formik) => {
            return (
              <div className="clubsetting-container">
                <div className="third-card pd-12">
                  <Form id="sales-staff-form">
                    {isActive ? (
                      <>
                        <div className="title-bar">
                          <h3 className="fs-14">Sales</h3>
                          {permissions.includes("CanEditLocation") && (
                            <span
                              className="icon-container edit"
                              onClick={handleIsActive}
                            >
                              <AiOutlineEdit />
                            </span>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="title-bar">
                        <h3 className="fs-14">SALES</h3>
                        <span className="icon-container ">
                          <div className="sub-containers">
                            <div className="sub-container save">
                              <Button
                                iconname="BiSave"
                                btntype="submit"
                                btnname="submit"
                                className="btn btn-edit save btn-lg fs-12 "
                              />
                            </div>
                            <div className="sub-container cancel">
                              <Button
                                iconname="AiOutlineCloseCircle"
                                btntype="reset"
                                btnname="reset"
                                className="btn  btn-edit cancel btn-lg fs-12"
                                click={handleCancel}
                              />
                            </div>
                          </div>
                        </span>
                      </div>
                    )}
                  </Form>
                  <div className="details-container">
                    {/* <div className="detail no-underline"> */}
                    <div className=" no-underline">
                      <Input
                        type="date"
                        label="Opening Date"
                        name="openDate"
                        id="openDate"
                        autoComplete="off"
                        disabled={isActive ? true : false}
                        className={isActive ? "" : "blue"}
                      />
                    </div>
                    <div className=" no-underline toggle-container">
                      <div className="test">
                        <div className="toggle-flexbox">
                          <ToggleFormik
                            label="Pre-Sales On"
                            name="isPreSaleOn"
                            id="isPreSaleOn"
                            value={formik.isPreSaleOn}
                            disabled={isActive ? true : false}
                            className={isActive ? "" : "blue"}
                          />
                        </div>
                        <div className="toggle-flexbox">
                          <ToggleFormik
                            label="Hidden Online"
                            name="isHiddenOnline"
                            id="isHiddenOnline"
                            value={formik.isHiddenOnline}
                            disabled={isActive ? true : false}
                            className={isActive ? "" : "blue"}
                          />
                        </div>
                      </div>

                      {/* <div className="toggle-flexbox">
                        <ToggleFormik
                          label="Free Trial App"
                          name="isFreeTrialApp"
                          id="isFreeTrialApp"
                          value={formik.isFreeTrialApp}
                          disabled={isActive ? true : false}
                          className={isActive ? "" : "blue"}
                        />
                      </div> */}
                    </div>
                    {/* <div className="detail no-underline">
                      <Input
                        type="number"
                        label="Free Trial Length Days"
                        name="freeTrialLengthDays"
                        id="freeTrialLengthDays"
                        autoComplete="off"
                        className={
                          isActive
                            ? "select-element-value"
                            : "select-element-value blue"
                        }
                      />
                    </div> */}
                    {/* <div className="detail no-underline">
                      <Input
                        type="number"
                        label="Trial Visits Limit (-1=Unlimited)"
                        name="trialVisitLimit"
                        id="trialVisitLimit"
                        autoComplete="off"
                        disabled={isActive ? true : false}
                        className={isActive ? "" : "blue"}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default Sales;
