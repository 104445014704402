import React from "react";
import { Formik, Form } from "formik";
import Button from "../formUiElements/Button";

const DeclineRefundModal = (props) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        reason: "",
        curRow: props.curRow,
      }}
      onSubmit={(values, OnSubmitProps) => {
        props.handleDeclineRefundSubmit(values, OnSubmitProps);
      }}
      validate={(values, props) => {
        const { amount, reason } = values;

        let errors = {};

        if (!reason) {
          const errorText = "Please choose a reason";
          errors.reason = errorText;
        }

        return errors;
      }}
    >
      {(formik) => {
        return (
          <Form className="status-modal edit-amount pd-32">
            <h3 className="fs-35 uppercase">Decline Request Refund</h3>
            <p className="uppercase fs-12">
              are you sure you want to decline this request refund?
            </p>
            <div className="content-container">
              <div className="reason-container">
                <label className="fs-12" htmlFor="reason">
                  Reason *
                </label>
                <textarea
                  name="reason"
                  id="reason"
                  onChange={formik.handleChange}
                  value={formik.values.reason}
                ></textarea>
                {formik.errors.reason && formik.touched.reason && (
                  <div className="fs-12 error">{formik.errors.reason}</div>
                )}
              </div>
            </div>
            <div className="btn-group">
              <Button
                loading={props.loading}
                name="YES"
                btntype="submit"
                btnname="submit"
                className="btn btn-confirm btn-small"
                disabled={formik.isSubmitting ? true : false}
              />
              <Button
                name="Cancel"
                btntype="submit"
                btnname="submit"
                className="btn btn-deny btn-small"
                click={props.cancelFn}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DeclineRefundModal;
