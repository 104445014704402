import "./profile.scss";
import { useContext } from "react";
import { AuthContext } from "../../contexts";
import {
  Header,
  UserEmploymentInfo,
  UserPersonalInfo,
  UserContactInfo,
} from "../../components";

const Profile = () => {
  const { loggedUser, getLoggedUser } = useContext(AuthContext);
  const { givenName, middleName, surname } = loggedUser?.getMember;
  const pageTitle = `${givenName ? givenName : ""} ${
    middleName ? middleName : ""
  } ${surname ? surname : ""}`;
  return (
    <>
      <Header pageTitle={pageTitle} />
      {loggedUser && (
        <>
          <div className="profile-page mt-32">
            <UserPersonalInfo
              staffData={loggedUser}
              refetch={getLoggedUser}
              showEditBTN={true}
            />
            <UserContactInfo
              staffData={loggedUser}
              refetch={getLoggedUser}
              showEditBTN={true}
            />
            <UserEmploymentInfo
              staffData={loggedUser}
              refetch={getLoggedUser}
              showEditBTN={false}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Profile;
