import "./addpincode.scss";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { GET_DOOR_ACCESS_CODE_BY_LOCATIONID } from "../../../gqloperations/queries";
import { CREATE_DOOR_ACCESS_CODE } from "../../../gqloperations/mutations";
import { Formik, Form } from "formik";
import { doorAccessSchema } from "../../../validations/LocationValidation";
import { toast } from "react-toastify";
import { Header, Button, Input } from "../../../components";
import { useLocationContext } from "../../../contexts/LocationContext";
import { useHandleError } from '../../../hooks/useHandleError';
const AddPinCode = () => {
  const { state, dispatch } = useLocationContext();
  let navigate = useNavigate();
  const handleError = useHandleError();
  const handleCancel = () => {
    dispatch({ type: "SET_INDEX", payload: 6 });
    navigate(`/dashboard/location/specificLocation/${state.id}`);
  };

  const onSubmit = async (values, OnSubmitProps) => {
    try {
      await addDoorAccessCode({
        variables: {
          input: {
            ...values,
            doorName: values.doorName.toUpperCase(),
            code: values.code.toUpperCase(),
            instruction: values.instruction.toUpperCase(),
          },
        },

        onCompleted: () => {
          OnSubmitProps.setSubmitting(false);
          OnSubmitProps.resetForm();
          toast.success("Pin Code Added Successfully");
          dispatch({ type: "SET_INDEX", payload: 5 });
          navigate(`/dashboard/location/specificLocation/${state.id}`);
        },
        refetchQueries: [
          {
            query: GET_DOOR_ACCESS_CODE_BY_LOCATIONID,
            variables: {
              locationId: `${state.id}`,
            },
          },
        ],
      });
    } catch (error) {
      handleError(`${error}`);
    }
    OnSubmitProps.setSubmitting(false);
  };

  //calling a mutations to add location
  const [addDoorAccessCode] = useMutation(CREATE_DOOR_ACCESS_CODE);

  return (
    <>
      {state.location && (
        <Formik
          initialValues={{
            doorName: "",
            code: "",
            locationId: `${state.id}`,
            aliasLocationId: `${state.location.getLocation.aliasId}`,
            instruction: "",
          }}
          validationSchema={doorAccessSchema}
          onSubmit={(values, OnSubmitProps) => {
            onSubmit(values, OnSubmitProps);
          }}
        >
          {(formik, errors, touched) => {
            return (
              <>
                <Form>
                  <Header pageTitle="Add Pin Code" />
                  <div className="pin-card pd-12 mt-32 mb-12">
                    <div className="details-container pin-codes">
                      <div className="pin-info pb-12 flex-wrap">
                        <Input
                          type="text"
                          label="Door Name * "
                          name="doorName"
                          id="doorName"
                          autoComplete="off"
                        />
                        <Input
                          type="text"
                          label="Code *"
                          name="code"
                          id="code"
                          autoComplete="off"
                        />
                      </div>

                      <div>
                        <p className="fs-12 text-gray mb-8">Instruction</p>
                        <textarea
                          className="reason-box fs-12"
                          id="instruction"
                          name="instruction"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.instruction}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="save-btn right-btn">
                    <Button
                      name="SAVE"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-confirm btn-large fs-12 "
                    />
                    <Button
                      name="CANCEL"
                      btntype="reset"
                      btnname="reset"
                      className="btn btn-deny btn-large fs-12"
                      click={handleCancel}
                    />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default AddPinCode;
