import "./addClassTemplate.scss";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { useMutation } from "@apollo/client";
import { classTemplateSchema } from "../../validations/ClassValidation";
import { CREATE_CLASS_TEMPLATE } from "../../gqloperations/mutations";
import { toast } from "react-toastify";
import { useMemberContext } from "../../contexts/MemberContext";
import { Input, Header, Button, ToggleFormik } from "../../components";
import { useHandleError } from '../../hooks/useHandleError';

const AddClass = (props) => {
  const navigate = useNavigate();
  const { sidebarBrandId, sidebarBrandName } = useMemberContext();
  const handleError = useHandleError();

  //calling a mutations to add class Template
  const [addClassTemplate] = useMutation(CREATE_CLASS_TEMPLATE);

  const onSubmit = (values, OnSubmitProps) => {
    addClassTemplate({
      variables: {
        input: {
          ...values,
          brandId: sidebarBrandId,
          className: values.className.toUpperCase(),
          classDescription: values.classDescription.toUpperCase(),
          daysWithin: Number(values.daysWithin),
        },
      },

      onCompleted: () => {
        OnSubmitProps.setSubmitting(false);
        OnSubmitProps.resetForm();
        toast.success("Class Template Created Successfully");
        navigate("/dashboard/classTemplates");
      },

      onError: () => {
        handleError("Class Template Created Failed");
      },
    });
    OnSubmitProps.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        isActive: "",
        hasVirtualClass: "",
        className: "",
        classDuration: "",
        daysWithin: "",
        classDescription: "",
      }}
      validationSchema={classTemplateSchema}
      onSubmit={(values, OnSubmitProps) => {
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <>
            <Header pageTitle="Add Classes Template" />
            <Form>
              <div className="add-class-template-container mt-32">
                <div className="content-container pd-12">
                  <h3 className="fs-14 uppercase pb-24">Class Settings</h3>
                  <div className="first-toggle-container mb-12">
                    <div className="left-toggles flex-wrap">
                      <ToggleFormik
                        label="CLASS ACTIVE"
                        name="isActive"
                        id="isActive"
                        className="fs-10"
                        value={formik.isActive}
                      />
                      <ToggleFormik
                        label="VIRTUAL CLASS"
                        name="hasVirtualClass"
                        id="hasVirtualClass"
                        className="fs-10"
                        value={formik.hasVirtualClass}
                      />
                    </div>
                  </div>
                  <div className="input-row add-class-template pb-24 flex-wrap">
                    <div className="detail">
                      <div className="brand-name text-gray">
                        <h5 className="fs-12">Brand *</h5>
                        <p className="fs-12"> {sidebarBrandName}</p>
                      </div>
                    </div>
                    <div className="detail">
                      <Input
                        type="text"
                        label="Class Name *"
                        name="className"
                        id="className"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail">
                      <Input
                        type="number"
                        label="Duration (min)*"
                        name="classDuration"
                        placeholder=""
                        min="1"
                        id="classDuration"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail">
                      <Input
                        type="number"
                        label="Prebook Days"
                        name="daysWithin"
                        id="daysWithin"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="desc-row">
                    <h3 className="fs-12 text-gray">Class Description*</h3>

                    <textarea
                      className="reason-box fs-12"
                      id="classDescription"
                      name="classDescription"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.classDescription}
                    ></textarea>
                    <div className="fs-12 error">
                      {formik.errors.classDescription &&
                        formik.touched.classDescription &&
                        formik.errors.classDescription}
                    </div>
                  </div>
                </div>
                <div className="right-btn mt-12">
                  <Button
                    loading={formik.isSubmitting}
                    name={"Save"}
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-confirm btn-large fs-12"
                    disabled={formik.isSubmitting ? true : false}
                  />
                  <Link to={`/dashboard/classTemplates`}>
                    <Button
                      name="Cancel"
                      btntype="submit"
                      btnname="submit"
                      // disabled={!Formik.isValid || Formik.isSubmitting}
                      className="btn btn-deny btn-large fs-12"
                    />
                  </Link>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default AddClass;
