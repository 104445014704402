import "./abaFile.scss";
import Header from "../../components/header/Header";
import { useMemberContext } from "../../contexts/MemberContext";
import GenerateABA from "./GenerateABA";
import Rejection from "./Rejection";
import LocationFees from "./LocationFees";
import SettlementReport from "./SettlementReport";

const Financial = () => {
  const { setIndex, index, createLog, member } = useMemberContext();
  const tabs = [
    "Settlement report",
    // "GENERATE ABA",
    // "REJECTION",
    // "Location Fees",
  ];
  return (
    <>
      <Header pageTitle="Financial" />
      <div className="tabs mt-32">
        <div className="tabs-list">
          {tabs.map((tab, idx) => (
            <div
              className={
                index !== idx
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => {
                setIndex(idx);
                createLog(
                  `Clicked Generate ABA tab`,
                  "member",
                  `${member?.getMember?.memberId}`
                );
              }}
            >
              {tab}
            </div>
          ))}
        </div>
        <div className="tabContent" hidden={index !== 0}>
          <SettlementReport />
        </div>
        {/* <div className="tabContent" hidden={index !== 1}>
          <GenerateABA />
        </div>
        <div className="tabContent" hidden={index !== 2}>
          <Rejection />
        </div>
        <div className="tabContent" hidden={index !== 3}>
          <LocationFees />
        </div> */}
      </div>
    </>
  );
};

export default Financial;
