import { termsAndConditions } from "../../../../constants/commonConstant";
import { BeatLoader } from "react-spinners";
import Button from "../../../formUiElements/Button";

const ConditionsTabContent = ({
  newMembershipData,
  handleClick,
  handleBack,
  isSubmitting,
}) => {
  const termsAndConditionsLink = termsAndConditions.find(
    (i) =>
      i.brandName === newMembershipData?.membershipDetails.brandDetails.name
  )?.link;
  return (
    <>
      <div className="conditions-UG-container">
        <div className="info-card mt-32">
          <div className="first-row-settings text-gray fs-12">
            <h3 className="pb-18">TERMS AND CONDITIONS</h3>
            {termsAndConditionsLink ? (
              <a href={termsAndConditionsLink} target="_blank" rel="noreferrer">
                <span style={{ color: "var(--color-persianblue)" }}>
                  Click Here {}
                </span>
                to Read {newMembershipData.membershipDetails.brandDetails.name}{" "}
                Terms and Conditions
              </a>
            ) : (
              <p>
                Please contact admin to access contract terms and conditions
              </p>
            )}
          </div>
        </div>
        <div className="right-btn mt-18">
          <Button
            name="Back"
            btntype="reset"
            btnname="reset"
            className="btn btn-deny btn-large fs-12"
            click={handleBack}
          />
          <Button
            loading={isSubmitting}
            name={"Next"}
            btntype="submit"
            btnname="submit"
            click={handleClick}
            className="btn btn-confirm fs-12"
            disabled={isSubmitting ? true : false}
          />
        </div>
      </div>
    </>
  );
};
export default ConditionsTabContent;
