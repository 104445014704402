import "./featureTileGrid.scss";
import React from "react";
import FeatureTile from "./FeatureTile";

const FeatureTileGrid = (props) => {
  const createTiles = () => {
    return props.listFeatureImgs.map((item, index) => {
      return (
        <FeatureTile
          key={index}
          featureName={item.featureDetails.name}
          imgSrc={item.clubImgUrl}
        />
      );
    });
  };

  return (
    <React.Fragment>
      {!!props.listFeatureImgs && (
        <div className="feature-tile-grid-container">{createTiles()}</div>
      )}
    </React.Fragment>
  );
};

export default FeatureTileGrid;
