import React, { useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import Modal from "../../components/modal/Modal";
import OTPInput from "../../components/formUiElements/input/OTPInput/OTPInput";
import "./modalconfirmotp.scss";
import Button from "../../components/formUiElements/Button";
import { SEND_OTP_CONFIRM_EMAIL } from "../../gqloperations/mutations";
import { useMemberContext } from "../../contexts/MemberContext";
import { useHandleError } from '../../hooks/useHandleError';

function ModalConfirmOtp({
  onClose,
  onComplete,
  newEmail,
  setLoading,
  setNewEmail,
}) {
  const [sendVerifyEmail] = useMutation(SEND_OTP_CONFIRM_EMAIL);
  const [otpType, setOtpType] = useState("ERROR");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [otpValue, setOtpValue] = useState("");
  const [OTPExpire, setOtpExpire] = useState({ seconds: 0, minutes: 0 });
  const { member } = useMemberContext();
  const handleError = useHandleError();

  const OTPMessage = useMemo(() => {
    if (otpType === "SMS")
      return (
        <>
          {" "}
          We’ve sent a 6-Character code to your EMAIL.
          <br /> Please check the mail in your mailbox.
        </>
      );

    if (otpType === "ERROR")
      return (
        <p className="text-textError">
          {" "}
          We are trying to send a 6-Character code.
          <br />
          But there is an error please try again or use a different method.
        </p>
      );
  }, [otpType]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (OTPExpire.seconds > 0) {
        setOtpExpire({ ...OTPExpire, seconds: OTPExpire.seconds - 1 });
      }

      if (OTPExpire.seconds === 0) {
        if (OTPExpire.minutes === 0) {
          clearInterval(interval);
        } else {
          setOtpExpire({ minutes: OTPExpire.minutes - 1, seconds: 59 });
          // setOtpExpire({...minutes:OTPExpire.minutes - 1});
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [OTPExpire.seconds]);

  const sendVerifyToEmail = () => {
    sendVerifyEmail({
      variables: {
        input: {
          email: newEmail,
          memberId: member.getMember.memberId,
          operation: "SEND_OTP_PRIMARY_EMAIL",
        },
      },
      onCompleted: (data) => {
        setLoading(false);
        setOtpType("SMS");
        setSeconds(60);
        setNewEmail("");
        setOtpExpire({ seconds: 60, minutes: 4 });
      },
      onError: (error) => {
        setLoading(false);
        setOtpType("ERROR");
        setNewEmail("");
        handleError(`${error}`);
      },
    });
  };

  useEffect(() => {
    newEmail && newEmail !== "" && sendVerifyToEmail();
  }, [newEmail]);

  const resend = async (otpTypeResend) => {
    newEmail && sendVerifyToEmail();
    setOtpType(otpTypeResend);
  };

  const verifyOtp = async (otp) => {
    await sendVerifyEmail({
      variables: {
        input: {
          operation: "UPDATE_PRIMARY_EMAIL",
          memberId: member.getMember.memberId,
          inputOTPCode: otp,
        },
      },
      onCompleted: (data) => {
        onComplete();
        onClose();
      },
      onError: (error) => {
        setOtpValue("");
        handleError(`${error}`);
      },
    });
  };

  return (
    <div className="container">
      {otpType !== "ERROR" && (
        <Modal>
          <div className="status-modal-otp">
            <>
              <p className="message">
                {OTPMessage}
                {(OTPExpire.seconds > 0 || OTPExpire.minutes > 0) && (
                  <span>
                    <br />
                    Your OTP will be expired in:{" "}
                    {OTPExpire.minutes < 10
                      ? `0${OTPExpire.minutes}`
                      : OTPExpire.minutes}
                    :
                    {OTPExpire.seconds < 10
                      ? `0${OTPExpire.seconds}`
                      : OTPExpire.seconds}
                  </span>
                )}
              </p>
              <OTPInput
                autoFocus
                isNumberInput
                length={6}
                value={otpValue}
                onChangeOTP={(otp) => {
                  setOtpValue(otp);
                  if (otp.length === 6) verifyOtp(otp);
                }}
              />
              <div>
                {seconds > 0 || minutes > 0 ? (
                  <p className="message">
                    Didn't receive the code try again after{" "}
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <>
                    <Button
                      // loading={formik.isSubmitting}
                      name={"RESEND OTP"}
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-confirm btn-small"
                      click={() => {
                        resend("SMS");
                      }}
                    />
                  </>
                )}
                <Button
                  name="Cancel"
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-deny btn-small"
                  click={onClose}
                />
              </div>
            </>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ModalConfirmOtp;
