import { useState } from "react";
/**
 * @param {Object} [initialValues] Optional object containing the initial values for table states
 * @param {string} [initialValues.initialGlobalFilter] Initial value for `globalFilter`
 * @param {{id:string, value:string}[]} [initialValues.initialColumnFilters] Initial value for `columnFilters`
 * @param {{id:string, desc:boolean}[]} [initialValues.initialSorting] Initial value for `sorting`
 */
const useTableStateProps = (initialValues) => {
  const [globalFilter, setGlobalFilter] = useState(
    initialValues?.initialGlobalFilter ?? ""
  );
  const [columnFilters, setColumnFilters] = useState(
    initialValues?.initialColumnFilters ?? []
  );
  const [sorting, setSorting] = useState(initialValues?.initialSorting ?? []);
  return {
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
  };
};

export { useTableStateProps };
