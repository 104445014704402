import React, { useEffect, useState } from "react";
import { Modal } from "../../../../../components";
import axios from "axios";
import "./modalTermsAndCondition.css";
import ReactQuill from "react-quill";
import { AiOutlineCloseCircle } from "react-icons/ai";
function ModalTermsAndCondition({ onClose }) {
  const [termAndCondition, setTermAndCondition] = useState();
  useEffect(() => {
    axios
      .get("https://cms.test.plus.fitness/api/term-and-condition")
      .then((res) => {
        setTermAndCondition(res.data);
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);
  return (
    <Modal>
      {termAndCondition && (
        <div className="modalTermsAndCondition">
          <div
            className="closeButton"
            onClick={() => {
              console.log(typeof onClose);
              if (typeof onClose === "function") {
                onClose();
              }
            }}
          >
            <AiOutlineCloseCircle />
          </div>
          <h1 class="heading heading-underline text-white">
            {termAndCondition.data.attributes.title}
          </h1>

          <ReactQuill
            theme={"bubble"}
            readOnly={true}
            value={termAndCondition.data.attributes.content}
          />
        </div>
      )}
    </Modal>
  );
}

export default ModalTermsAndCondition;
