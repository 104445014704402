import "./guestEntryLog.scss";
import { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { API, graphqlOperation } from "aws-amplify";
import { format, addDays } from "date-fns";
import { toast } from "aws-amplify";
import { getUTCdate } from "../../utils/getUTCdate";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useTableStateProps } from "../../hooks/useTableStateprops";
import { useCheckMemberProfile } from "../../hooks/useCheckMemberProfile";
import {
  LIST_DOORS,
  LIST_MEMBER_ACCESS_LOGS,
} from "../../gqloperations/queries";
import { AiOutlineSearch } from "react-icons/ai";
import { BeatLoader } from "react-spinners";
import { useHandleError } from "../../hooks/useHandleError";
import { MEMBER_ACCESS_LOGS_SUBSCRIPTION } from "../../gqloperations/subscriptions";
import {
  Modal,
  StatusModal,
  Header,
  Table,
  Checkbox,
  DatePicker,
  ExportGroup,
  SelectWithoutFormik,
} from "../../components";
import { filterFns } from "@tanstack/react-table";

const queryItemLimit = 100;
export const MemberAccessLogs = () => {
  const { locationAccess } = useAuthContext();
  const {
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
  } = useTableStateProps();
  const [blockAccessModal, setBlockAccessModal] = useState(false);
  const [newLog, setNewLog] = useState({});
  const [nextToken, setNextToken] = useState("");
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [selectedState, setSelectedState] = useState("All States");
  const [locationsList, setLocationList] = useState([]);
  const handleError = useHandleError();
  const [selectedLocation, setSelectedLocation] = useState({
    locationId: "1",
    locationName: "All Locations",
  });
  const [doorsList, setDoorsList] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateError, setDateError] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [deviceType, setDeviceType] = useState({
    mobile: false,
    door: false,
  });
  const [failedFilter, setFailedFilter] = useState(false);
  const [offlineFilter, setOfflineFilter] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [locationInputValue, setLocationInputValue] = useState("All Locations");
  const [doorInputValue, setDoorInputValue] = useState("All Doors");
  const [selectedDoor, setSelectedDoor] = useState({
    doorId: "1",
    doorName: "All Doors",
  });
  const [filter, setFilter] = useState({});
  const [autoFetch5s, setAutoFetch5s] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const { data: doorsData } = useQuery(LIST_DOORS, {
    variables: {
      limit: 100000,
    },
  });
  const [getMemberAccessLogs, { loading: logsLoading }] = useLazyQuery(
    LIST_MEMBER_ACCESS_LOGS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filter,
        sort: [{ field: "createdAt", direction: "desc" }],
        limit: queryItemLimit,
      },
      onCompleted: (data) => {
        setAutoFetch5s(true);
        setTableData(data?.searchDoorAccessLogs.items);
        setNextToken(data?.searchDoorAccessLogs.nextToken);
      },
      onError: (error) => {
        handleError(`${error}`);
      },
    }
  );
  const { checkMemberProfile } = useCheckMemberProfile();

  //state change, location list change
  useEffect(() => {
    setSelectedLocation({ locationId: "1", locationName: "All Locations" });
    setLocationInputValue("All Locations");
    let allLocations = [{ locationId: "1", locationName: "All Locations" }];
    if (selectedState === "All States") {
      for (let i = 0; i < locationAccess?.state?.length; i++) {
        if (locationAccess?.state[i].state) {
          allLocations = [
            ...allLocations,
            ...locationAccess?.state[i].locations,
          ];
        }
      }
    } else {
      allLocations = [
        ...allLocations,
        ...locationAccess?.state.find((state) => state?.state === selectedState)
          .locations,
      ];
    }
    setLocationList(allLocations);
  }, [selectedState, locationAccess?.state]);
  //state change, location change, doors list change
  useEffect(() => {
    let allDoors = [{ doorId: "1", doorName: "All Doors" }];
    setSelectedDoor({ doorId: "1", doorName: "All Doors" });
    setDoorInputValue("All Doors");
    if (selectedLocation.locationId === "1") {
      for (let i = 0; i < locationsList?.length; i++) {
        doorsData?.listAccessPoints.items.map(
          (door) =>
            door.locationId === locationsList[i].locationId &&
            allDoors.push({ doorId: door.id, doorName: door.accessPointname })
        );
      }
    } else {
      doorsData?.listAccessPoints?.items.map((door) => {
        if (door.locationId === selectedLocation.locationId) {
          allDoors.push({ doorId: door.id, doorName: door.accessPointname });
        }
      });
    }
    setDoorsList(allDoors);
  }, [selectedLocation, locationsList, doorsData]);
  //setSelectedLocation
  useEffect(() => {
    const getSelectedLocation = locationsList.find(
      (item) => item.locationName === locationInputValue
    );
    if (getSelectedLocation) {
      setSelectedLocation({
        ...selectedLocation,
        locationId: getSelectedLocation.locationId,
        locationName: getSelectedLocation.locationName,
      });
      setLocationInputValue(getSelectedLocation.locationName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationInputValue]);
  //setSelectedDoor
  useEffect(() => {
    const getSelectedDoor = doorsList.find(
      (item) => item.doorName.toLowerCase() === doorInputValue?.toLowerCase()
    );
    if (getSelectedDoor) {
      setSelectedDoor({
        ...selectedDoor,
        doorId: getSelectedDoor.doorId,
        doorName: getSelectedDoor.doorName,
      });
      setDoorInputValue(getSelectedDoor.doorName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doorInputValue]);
  useEffect(() => {
    newLog && setTableData([newLog, ...tableData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLog]);
  const getFilter = () => {
    if (dateFrom > dateTo) setDateError("Please select valid date period");
    if (!dateFrom && !dateTo) setDateError("");
    let dataFilter = {};
    let locationFilter = [];
    if (selectedDoor.doorId === "1" && selectedLocation.locationId === "1") {
      locationsList?.map((location) => {
        if (location?.locationId !== "1") {
          locationFilter.push({ locationId: { eq: location.locationId } });
        }
        return null;
      });
    } else if (
      selectedDoor.doorId === "1" &&
      selectedLocation.locationId !== "1"
    ) {
      locationFilter.push({
        locationId: { eq: selectedLocation.locationId },
      });
    } else if (
      selectedDoor.doorId !== "1" &&
      selectedLocation.locationId !== "1"
    ) {
      locationFilter.push({
        doorId: { eq: selectedDoor.doorId },
        locationId: { eq: selectedLocation.locationId },
      });
    } else if (
      selectedDoor.doorId !== "1" &&
      selectedLocation.locationId === "1"
    ) {
      // locationsList?.map((location) => {
      //   if (location?.locationId !== "1") {
      //     locationFilter.push({ locationId: { eq: location.locationId } });
      //   }
      //   return null;
      // });
      locationFilter.push({
        doorId: { eq: selectedDoor.doorId },
      });
    }
    dataFilter = { or: locationFilter };
    let furtherFilter = [{ isPostData: { eq: true } }];
    if (dateFrom && dateTo && (dateFrom < dateTo || dateFrom === dateTo)) {
      setDateError("");
      const utcFrom = getUTCdate(dateFrom);
      const utcTo = getUTCdate(
        format(addDays(new Date(dateTo), 1), "yyyy-MM-dd")
      );
      furtherFilter.push({
        createdAt: {
          range: [utcFrom, utcTo],
        },
      });
    }
    if (failedFilter) {
      furtherFilter.push({ isValid: { eq: false } });
    }
    if (offlineFilter) {
      furtherFilter.push({ isOnline: { eq: false } });
    }
    if (deviceType.mobile || deviceType.door) {
      let deviceTypeFilter = [];
      for (let key in deviceType) {
        if (deviceType[key]) {
          deviceTypeFilter.push({ deviceType: { eq: key.toUpperCase() } });
        }
      }
      furtherFilter.push({ or: deviceTypeFilter });
    }
    if (furtherFilter?.length > 0) {
      dataFilter = { or: locationFilter, and: furtherFilter };
    }
    setFilter(dataFilter);
  };
  useEffect(() => {
    getFilter();
    setAutoFetch5s(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    doorsList,
    selectedLocation,
    selectedDoor,
    dateFrom,
    dateTo,
    deviceType,
    failedFilter,
    offlineFilter,
  ]);
  useEffect(() => {
    getMemberAccessLogs();
    if (
      dateFrom &&
      dateTo &&
      (new Date().getTime() > new Date(dateTo).getTime() ||
        new Date().getTime() < new Date(dateFrom).getTime())
    )
      return;
    const subscriptionFilter = {
      and: filter?.and?.filter((item) => !item.hasOwnProperty("createdAt")),
    };
    let subscription;
    if (subscriptionFilter?.and?.length > 0) {
      subscription = API.graphql(
        graphqlOperation(MEMBER_ACCESS_LOGS_SUBSCRIPTION, {
          filter: subscriptionFilter,
        })
      ).subscribe({
        next: (eventData) => {
          checkLocationFilter(eventData.value.data.onCreateDoorAccessLog) &&
            setNewLog(eventData.value.data.onCreateDoorAccessLog);
        },
        error: (error) => {
          handleError("Unable to get real-time data");
        },
      });
    } else {
      subscription = API.graphql(
        graphqlOperation(MEMBER_ACCESS_LOGS_SUBSCRIPTION)
      ).subscribe({
        next: (eventData) => {
          checkLocationFilter(eventData.value.data.onCreateDoorAccessLog) &&
            setNewLog(eventData.value.data.onCreateDoorAccessLog);
        },
        error: (error) => {
          handleError("Unable to get real-time data");
        },
      });
    }
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  const checkLocationFilter = (log) => {
    let result = false;
    if (filter.or && Object.keys(filter.or[0])[0] === "locationId") {
      const locationIds = filter?.or?.map(
        (condition) => condition.locationId?.eq
      );
      result = locationIds.find((locationId) => locationId === log.locationId);
    }

    if (filter.or && Object.keys(filter.or[0])[0] === "doorId") {
      result = filter.or[0].doorId.eq === log.doorId ? true : false;
    }
    return result;
  };
  const stateList = ["All States"];
  locationAccess?.state.map(
    (state) => state.state && stateList.push(state.state)
  );

  const customGlobalFilter = (row, columnId, filterValue) => {
    const includedString = filterFns.includesString(row, columnId, filterValue);

    if (!includedString) {
      const filterValueLowercase = String(filterValue).toLowerCase();

      const extraFieldsToCheck = [
        String(row?.original?.doorName ?? ""),
        String(row?.original?.cardNumber ?? ""),
      ];

      return extraFieldsToCheck.some((currField) => {
        return currField.toLowerCase().includes(String(filterValueLowercase));
      });
    }
    return includedString;
  };

  const columns = [
    {
      accessorKey: "memberName",
      header: "NAME",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "memberName",
    },
    {
      accessorKey: "swipePhoto",
      header: "SWIPE PHOTO",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "swipePhoto",
    },
    {
      accessorFn: (row) => row?.memberPhoto ?? "",
      header: "LINKS PHOTO",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "memberPhoto",
    },
    {
      accessorFn: (row) => {
        if (row?.processLocationTime) {
          const day = row.processLocationTime.slice(8, 10);
          const month = row.processLocationTime.slice(5, 7);
          const year = row.processLocationTime.slice(0, 4);
          const time = row.processLocationTime.slice(11, 19);
          return `${day}/${month}/${year} ${time}`;
        }
      },
      header: "TIME",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "createdAt",
    },
    {
      accessorKey: "isValid",
      header: "VALID",
      cell: (info) => {
        return info.getValue() ? "YES" : "NO";
      },
      enableSorting: false,
      enableGlobalFilter: true,
      id: "isValid",
    },
    {
      accessorKey: "isOnline",
      header: "ONLINE",
      cell: (info) => {
        return info.getValue() ? "YES" : "NO";
      },
      enableSorting: false,
      enableGlobalFilter: true,
      id: "isOnline",
    },
    {
      accessorKey: "memberHomeClub",
      header: "HOME CLUB",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "memberHomeClub",
    },
    {
      accessorKey: "memberShipName",
      header: "MEMBERSHIP",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "membershipName",
    },
    {
      accessorFn: (info) => {
        let deviceName = "OTHER";
        if (info?.deviceType === "DOOR")
          deviceName = `FOB ${info?.cardNumber ?? ""}`;
        if (info?.deviceType === "MOBILE") deviceName = "APP";
        if (info?.deviceType === "MANUAL") deviceName = "MANUAL";
        const cellInfo = {
          doorName: !!info?.doorName ? info.doorName : "",
          deviceName: deviceName,
        };
        return cellInfo;
      },
      header: "LOCATION",
      cell: (info) => {
        return (
          <div className="location-cell">
            <span>{info.getValue()?.doorName}</span>
            <span className="orange-text">{info.getValue()?.deviceName}</span>
            {/* <span>{info.getValue()?.doorDetails?.accessPoint}</span> */}
          </div>
        );
      },
      enableSorting: false,
      enableGlobalFilter: true,
      id: "memberAccessLocation",
    },
  ];
  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const handleLocationInputChange = (e) => {
    setLocationInputValue(e.target.value);
  };
  const handleDoorInputChange = (e) => {
    setDoorInputValue(e.target.value);
  };
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal?.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  const handleNextToken = () => {
    if (nextToken) {
      getMemberAccessLogs({
        variables: { filter, limit: queryItemLimit, nextToken },
        onCompleted: (data) => {
          setTableData([...tableData, ...data?.searchDoorAccessLogs?.items]);
          setNextToken(data?.searchDoorAccessLogs?.nextToken);
        },
        onError: (error) => {
          handleError(`${error}`);
        },
      });
    }
  };

  return (
    <>
      <div className="member-access-logs-container">
        <Header pageTitle="Member Access Logs" />
        <div className="info-card logs-filter-container mt-32">
          <div className="first-row-settings text-gray fs-12">
            <div className="option-container">
              <SelectWithoutFormik
                label="State"
                edit={true}
                initialValue="All States"
                optionvalue={stateList}
                selecttype="array"
                onChange={handleStateChange}
                name="stateSelect"
                disabled={false}
                className="select-element-value"
              />
            </div>

            <div className="option-container">
              <label>Location</label>
              <input
                type="text"
                list="locations-member-access-logs"
                onChange={handleLocationInputChange}
                value={locationInputValue}
                autoComplete="off"
                className="fs-12"
              />
              {locationsList && (
                <datalist id="locations-member-access-logs">
                  {locationsList.map((option) => {
                    return (
                      <option
                        key={option.locationId}
                        value={option.locationName}
                      />
                    );
                  })}
                </datalist>
              )}
            </div>
            <div className="option-container">
              <label>Door</label>
              <input
                type="text"
                list="doors-member-access-logs"
                onChange={handleDoorInputChange}
                value={doorInputValue}
                autoComplete="off"
                className="fs-12"
              />
              {doorsList && (
                <datalist id="doors-member-access-logs">
                  {doorsList.map((option) => {
                    return (
                      <option key={option.doorId} value={option.doorName} />
                    );
                  })}
                </datalist>
              )}
            </div>
          </div>
          <div className="sec-row-settings">
            <div className="date-selectors">
              <div>
                <h3 className="fs-12">From</h3>
                <DatePicker
                  placeholder="From"
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                    if (e.target.value === "" && dateTo) {
                      setDateError(
                        "Please select the start date, or clear end date to get all logs"
                      );
                    }
                    if (e.target.value && !dateTo) {
                      setDateTo(e.target.value);
                    }
                  }}
                />
              </div>
              <div>
                <h3 className="fs-12">To</h3>
                <DatePicker
                  placeholder="To"
                  value={dateTo}
                  min={dateFrom}
                  onChange={(e) => {
                    if (!dateFrom && !e.target.value) setDateError("");
                    setDateTo(e.target.value);
                  }}
                />
              </div>
              {dateError && <div className="fs-10 red">{dateError}</div>}
            </div>
            <div className="checkboxes-container fs-12">
              <Checkbox
                labelText="APP"
                checked={deviceType.mobile}
                onChange={() => {
                  setDeviceType({ ...deviceType, mobile: !deviceType.mobile });
                }}
              />
              <Checkbox
                labelText="FOB"
                checked={deviceType.door}
                onChange={() => {
                  setDeviceType({
                    ...deviceType,
                    door: !deviceType.door,
                  });
                }}
              />
              <Checkbox
                labelText="OFFLINE"
                checked={offlineFilter}
                onChange={(e) => setOfflineFilter(!offlineFilter)}
              />
              <Checkbox
                labelText="FAILED"
                checked={failedFilter}
                onChange={(e) => setFailedFilter(!failedFilter)}
              />
            </div>
          </div>
        </div>
        <div className="actions-row mt-24">
          <div className="flex-row">
            <div className="btn-group ">
              {tableData && (
                <ExportGroup
                  data={tableData}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  columns={columns}
                  sorting={sorting}
                />
              )}
            </div>
            {/* <Button
              name="Refresh"
              btntype="button"
              btnname="cancel"
              className="btn btn-confirm btn-small refresh-btn"
              loading={logsLoading}
              click={() => getMemberAccessLogs()}
            /> */}
          </div>

          <div className="search-container">
            <form onSubmit={onSearchBarSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="search"
                    id="search"
                    value={searchBarInputVal}
                    onChange={handleChange}
                    placeholder="NAME, HOME CLUB, MEMBERSHIP, DOOR NAME, FOB"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {logsLoading && !autoFetch5s && (
          <div className="loader">
            <BeatLoader color="white" size={15} />
          </div>
        )}
        {autoFetch5s && tableData && (
          <div className="member-access-logs-table">
            <Table
              onRowClick={(i) => {
                const isLocationAccessible =
                  locationAccess.locationAll.includes(i.homeLocationId);
                if (isLocationAccessible) {
                  checkMemberProfile(i.memberName, i.memberId);
                } else setBlockAccessModal(true);
              }}
              data={tableData}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              sorting={sorting}
              setSorting={setSorting}
              columns={columns}
              rowsPerPage={10}
              columnFilter={isFiltered}
              filterFns={{ customGlobalFilter: customGlobalFilter }}
              setColumnFilter={setIsFiltered}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              handleNextToken={handleNextToken}
              nextToken={nextToken}
              globalFilterFn={"customGlobalFilter"}
            />
          </div>
        )}
        {blockAccessModal && (
          <Modal>
            <StatusModal
              modalHeaderTxt="sorry"
              modalParaText="You don't have access to this member's profile"
              modalClose={() => setBlockAccessModal(false)}
              showOneBtn
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default MemberAccessLogs;
