import React, { useState } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { AiOutlineEdit } from "react-icons/ai";
import { instructorAddressDetailSchema } from "../../validations/Validation";
import { state, country } from "../../constants/commonConstant";
import Button from "../formUiElements/Button";
import Select from "../formUiElements/select/Select";
import Input from "../formUiElements/input/Input";

const InstructorAddressDetail = ({ instructorInfo, onSubmit }) => {
  const [isActive, setIsActive] = useState(true);

  const handleIsActive = () => {
    setIsActive(false);
  };
  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("instructor-address-form").reset();
    setIsActive(true);
  };

  return (
    <>
      {instructorInfo && (
        <Formik
          enableReinitialize
          initialValues={{
            address: instructorInfo?.address ?? "",
            suburb: instructorInfo?.suburb ?? "",
            state: instructorInfo?.state ?? "",
            country: instructorInfo?.country ?? "",
            postCode: instructorInfo?.postCode ?? "",
          }}
          validationSchema={instructorAddressDetailSchema}
          onSubmit={async (values, OnSubmitProps) => {
            onSubmit(values, OnSubmitProps);
            setIsActive(true);
          }}
        >
          {(formik) => {
            return (
              <div className="instructor-address-container">
                <Form className="info-card" id="instructor-address-form">
                  {isActive ? (
                    <>
                      <div className="title-bar">
                        <h3 className="fs-14 uppercase">Address Detail</h3>
                        <span
                          className="icon-container edit"
                          onClick={handleIsActive}
                        >
                          <AiOutlineEdit />
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="title-bar">
                      <h3 className="fs-14 uppercase">Address Detail</h3>
                      <span className="icon-container ">
                        <div className="sub-containers">
                          <div className="sub-container save">
                            {/* {formik.isSubmitting && (
                              <Button
                                name={<BeatLoader color="white" />}
                                btntype="submit"
                                btnname="submit"
                                className="btn btn-edit save btn-lg fs-12"
                              />
                            )}
                            {!formik.isSubmitting && (
                              <Button
                                iconname="BiSave"
                                btntype="submit"
                                btnname="submit"
                                className="btn btn-edit save btn-lg fs-12"
                              />
                            )} */}
                            <Button
                              loading={formik.isSubmitting}
                              name={""}
                              iconname={formik.isSubmitting ? "" : "BiSave"}
                              btntype="submit"
                              btnname="submit"
                              className="btn btn-edit save btn-lg fs-12"
                              disabled={formik.isSubmitting ? true : false}
                            />
                          </div>
                          <div className="sub-container cancel">
                            <Button
                              iconname="AiOutlineCloseCircle"
                              btntype="reset"
                              btnname="reset"
                              className="btn btn-icon btn-lg fs-12"
                              click={handleCancel}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  )}
                  <div className="instructor-address-details">
                    <div className="address pb-12">
                      <Input
                        type="text"
                        label="Address"
                        name="address"
                        autoComplete="off"
                        disabled={isActive ? true : false}
                        className="fs-12"
                      />
                    </div>
                    <div className="suburb">
                      <Input
                        type="text"
                        label="Suburb *"
                        name="suburb"
                        autoComplete="off"
                        disabled={isActive ? true : false}
                        className="fs-12"
                      />
                    </div>
                    <div className="state">
                      <Select
                        label="State *"
                        optionvalues={state}
                        selecttype="array"
                        name="state"
                        autoComplete="off"
                        className="fs-12"
                        disabled={isActive ? true : false}
                      />
                    </div>
                    <div className="country">
                      <Select
                        label="Country *"
                        name="country"
                        optionvalues={country}
                        selecttype="array"
                        autoComplete="off"
                        className="fs-12"
                        disabled={isActive ? true : false}
                      />
                    </div>
                    <div className="post">
                      <Input
                        type="text"
                        label="Post Code *"
                        name="postCode"
                        autoComplete="off"
                        className="fs-12"
                        disabled={isActive ? true : false}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      )}
    </>
  );
};
export default InstructorAddressDetail;
