import "./forgotpassword.scss";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import {
  stepOneSchema,
  forgotPasswordSchema,
} from "../../validations/Validation";
import { AuthContext } from "../../contexts";
import hub_logo from "../../assets/images/hub_logo.svg";
import { Button, Input } from "../../components";
import { FiEye, FiEyeOff } from "react-icons/fi";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
  const [visible, setVisible] = useState({
    password: false,
    confirmPassword: false,
  });

  const { handleForgotPassword, handleForgotPasswordSubmit } =
    useContext(AuthContext);
  const [forgotemail, setForgotEmail] = useState("");
  return (
    <>
      {stage === 1 && (
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={stepOneSchema}
          onSubmit={async (values, OnSubmitProps) => {
            await handleForgotPassword(values.email, OnSubmitProps, setStage, setForgotEmail);
          }}
        >
          {(formik) => {
            return (
              <div className="forgot-password">
                <div className="logo">
                  <img src={hub_logo} alt="logo" width="173" height="47" />
                </div>
                <div className="forgot-password-container pd-32">
                  <h1 className="heading fs-35 pb-12">Forgot Password</h1>

                  <Form className="frm-group">
                    <Input
                      type="text"
                      label="Enter you email * "
                      name="email"
                      id="email"
                      autoComplete="off"
                    />

                    <div>
                      <div className="">
                        {/* {formik.isSubmitting && (
                          <>
                            <Button
                              name={<BeatLoader color="white" size={11} />}
                              btntype="submit"
                              btnname="submit"
                              className="btn btn-primary btn-lg fs-12"
                              disabled={formik.isSubmitting}
                            />
                          </>
                        )}
                        {!formik.isSubmitting && (
                          <>
                            <Button
                              name="Send Verification Code"
                              btntype="submit"
                              btnname="submit"
                              className="btn btn-primary btn-lg fs-12"
                            />
                          </>
                        )} */}
                        <Button
                          loading={formik.isSubmitting}
                          name={"Send Verification Code"}
                          btntype="submit"
                          btnname="submit"
                          className="btn btn-primary btn-lg fs-12"
                          disabled={formik.isSubmitting ? true : false}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            );
          }}
        </Formik>
      )}

      {stage === 2 && (
        <Formik
          initialValues={{
            code: "",
            new_password: "",
            confirmNewPassword: "",
          }}
          validationSchema={forgotPasswordSchema}
          onSubmit={async (values, OnSubmitProps) => {
            await handleForgotPasswordSubmit(
              forgotemail,
              values.code,
              values.new_password
            );
            OnSubmitProps.setSubmitting(false);
            OnSubmitProps.resetForm();
            navigate("/", { replace: true });
          }}
        >
          {(formik) => {
            return (
              <div className="forgot-password">
                <div className="logo">
                  <img src={hub_logo} alt="logo" width="173" height="47" />
                </div>
                <div className="forgot-password-container pd-32">
                  <h3 className="heading fs-35 pb-12">Forgot Password</h3>
                  <Form className="form-group">
                    <div className="enter-code pb-24">
                      <Input
                        type="text"
                        label="Enter code * "
                        name="code"
                        id="code"
                        autoComplete="off"
                      />
                    </div>

                    <div className="password pb-24">
                      <Input
                        type={visible.password ? "text" : "password"}
                        icon={true}
                        name="new_password"
                        id="new_password"
                        label="Password"
                      />
                      <span
                        className="icon password-visible fs-14"
                        onClick={() =>
                          setVisible({
                            ...visible,
                            password: !visible.password,
                          })
                        }
                      >
                        {visible.password ? <FiEyeOff /> : <FiEye />}
                      </span>
                    </div>

                    <div className="confirmpassword">
                      <Input
                        type={visible.confirmPassword ? "text" : "password"}
                        icon={true}
                        name="confirmNewPassword"
                        id="confirmNewPassword"
                        label="Confirm Password"
                      />
                      <span
                        className="icon password-visible fs-14"
                        onClick={() =>
                          setVisible({
                            ...visible,
                            confirmPassword: !visible.confirmPassword,
                          })
                        }
                      >
                        {visible.confirmPassword ? <FiEyeOff /> : <FiEye />}
                      </span>
                    </div>

                    <div className="item-centered">
                      {/* {formik.isSubmitting && (
                        <>
                          <Button
                            name={<BeatLoader color="white" size={11} />}
                            btntype="submit"
                            btnname="submit"
                            className="btn btn-primary btn-lg fs-12"
                            disabled={formik.isSubmitting}
                          />
                        </>
                      )} */}
                      <>
                        <Button
                          loading={formik.isSubmitting}
                          name="Update Password"
                          btntype="submit"
                          btnname="submit"
                          className="btn btn-primary btn-lg fs-12"
                        />
                      </>
                    </div>
                  </Form>
                </div>
                {/* {toastMessage} */}
              </div>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default ForgotPassword;
