import { useMutation } from "@apollo/client";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Formik, Form } from "formik";
import { AiOutlineEdit } from "react-icons/ai";
import { UPDATE_MEMBER } from "../../gqloperations/mutations";
import { useMemberContext } from "../../contexts/MemberContext";
import { userPersonalInfoSchema } from "../../validations/profileValidation";
import { useHandleError } from '../../hooks/useHandleError';

// eslint-disable-next-line no-unused-vars
import { gender as genders } from "../../constants/commonConstant";
import {
  Input,
  Button,
  Select,
  HiddenDatePicker,
} from "../../components/index";
import ProfileImage from "../formUiElements/profileImage/ProfileImage";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { BeatLoader } from "react-spinners";

const UserPersonalInfo = ({ staffData, refetch, staffId, showEditBTN }) => {
  const fileInputRef = useRef();
  const { loggedUser } = useAuthContext();
  const [editable, setEditable] = useState(false);
  const [file, setFile] = useState(false);
  const [updateMember] = useMutation(UPDATE_MEMBER);
  const handleError = useHandleError();
  const {
    downloadAndDecode,
    // imgUrl,
    setImgUrl,
    encodeAndUploadImageToAws: uploadImageToAws,
  } = useMemberContext();
  useEffect(() => {
    try {
      (async () => {
        staffData.getMember.imageUrl &&
          (await downloadAndDecode(staffData.getMember.imageUrl));
      })();
    } catch (error) {
      handleError(`Error: ${error}`);
    }
    return () => {
      setImgUrl("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffData.getMember.imageUrl]);

  const { givenName, middleName, surname, dob, memberId, gender } =
    staffData.getMember;

  const handleCancel = async () => {
    toast.warning("Update Cancelled");
    document.getElementById("personalInfoForm").reset();
    setEditable(false);
    if (staffData.getMember.imageUrl) {
      await downloadAndDecode(staffData.getMember.imageUrl);
    } else {
      setImgUrl("");
    }
  };

  const updateProfileImage = async () => {
    const extension = typeof file === "string" ? "png" : file?.name.split(".");

    // otherwise, upload normally
    const extensionName =
      typeof file === "string" ? "png" : extension[extension.length - 1];

    const updatedKey = `/staffImages/${staffId}/profile/${uuidv4()}.${extensionName}`;
    const uploadedFile = await uploadImageToAws(file, updatedKey);
    const imageUrl = uploadedFile?.key;
    return imageUrl;
  };

  const handleImageUpload = async (e, base64Img) => {
    const file = e?.target?.files[0] || base64Img;
    if (!file) return;

    if (file.size > 5_242_880) {
      handleError("Image needs to be less than 5MB");
    } else {
      setFile(file);
      const clientSideImgUrl = base64Img
        ? base64Img
        : URL.createObjectURL(file);
      setImgUrl(clientSideImgUrl);
    }
  };

  const onSubmit = async (values, OnSubmitProps) => {
    let updatedFields = {};

    for (let key in values) {
      if (
        String(values[key]).toUpperCase() !==
        String(staffData.getMember[key]).toUpperCase()
      ) {
        updatedFields[key] = values[key].toUpperCase();
      }
    }

    if (file) {
      const imageUrl = await updateProfileImage();

      updatedFields.imageUrl = imageUrl;
    }
    await updateMember({
      variables: {
        input: { ...updatedFields, memberId },
      },
      onCompleted: () => {
        toast.success("Update successfully");
        setEditable(false);
        refetch();
      },
    });
    OnSubmitProps.setSubmitting(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          givenName: givenName ?? "",
          middleName: middleName ?? "",
          surname: surname ?? "",
          dob: dob ?? "",
          gender: gender ?? "",
        }}
        validationSchema={userPersonalInfoSchema}
        onSubmit={(values, OnSubmitProps) => {
          onSubmit(values, OnSubmitProps);
        }}
      >
        {(formik) => {
          return (
            <Form id="personalInfoForm">
              <div className="personal-info-card flex-wrap">
                {formik.isSubmitting ? (
                  <div
                    className="centered-loading"
                  >
                    <BeatLoader className="loading" color="white" size={10} />
                  </div>
                ) : (
                  <>
                    <ProfileImage
                      disabledCamera={
                        staffData.getMember.memberId !==
                        loggedUser.getMember.memberId
                      }
                      isActive={!editable}
                      handleImageUpload={handleImageUpload}
                    />
                    <div className="info-section">
                      <div className="title-bar">
                        <h3 className="fs-14 uppercase pb-18">
                          Personal Information
                        </h3>
                        {showEditBTN && (
                          <span className="icon-container edit">
                            {editable === false ? (
                              <>
                                <span
                                  className="icon-container edit"
                                  onClick={() => setEditable(true)}
                                >
                                  <AiOutlineEdit />
                                </span>
                              </>
                            ) : (
                              <span className="icon-container">
                                <div className="sub-container save">
                                  <Button
                                    iconname="BiSave"
                                    btntype="submit"
                                    btnname="submit"
                                    className="btn btn-edit save btn-lg fs-12 "
                                  />
                                </div>
                                <div className="sub-container cancel">
                                  <Button
                                    iconname="AiOutlineCloseCircle"
                                    btntype="reset"
                                    btnname="reset"
                                    className="btn  btn-edit cancel btn-lg fs-12"
                                    click={handleCancel}
                                  />
                                </div>
                              </span>
                            )}
                          </span>
                        )}
                      </div>
                      <div className="staff-info">
                        <div className="First">
                          <Input
                            type="text"
                            label="First name *"
                            name="givenName"
                            id="givenName"
                            autoComplete="off"
                            disabled={editable ? false : true}
                            className={editable ? "blue" : ""}
                          />
                        </div>
                        <div className="Middle">
                          <Input
                            type="text"
                            label="Middle name"
                            name="middleName"
                            id="middleName"
                            autoComplete="off"
                            disabled={editable ? false : true}
                            className={editable ? "blue" : ""}
                          />
                        </div>
                        <div className="Last">
                          <Input
                            type="text"
                            label="Last name *"
                            name="surname"
                            id="surname"
                            autoComplete="off"
                            disabled={editable ? false : true}
                            className={editable ? "blue" : ""}
                          />
                        </div>

                        <div className="DOB">
                          <HiddenDatePicker
                            disabled={!editable}
                            name="dob"
                            label="Date of Birth"
                          />
                        </div>

                        <div className="gender">
                          <Select
                            label="Gender *"
                            optionvalues={genders}
                            selecttype="array"
                            name="gender"
                            autoComplete="off"
                            disabled={editable ? false : true}
                            className="select-element-value fs-12"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default UserPersonalInfo;
