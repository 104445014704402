import "./member.scss";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { BeatLoader } from "react-spinners";
import { BsCardText } from "react-icons/bs";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { RiMailSendLine } from "react-icons/ri";
import chart_icon from "../../assets/images/chart_icon.svg";
import {
  SEARCH_MEMBER,
  SEARCH_BY_EMAIL,
  SEARCH_MEMBER_BY_CARD_NUMBER,
  SEARCH_BY_MEMBER_ALIAS_ID,
} from "../../gqloperations/queries";
import { UPDATE_EMAIL_OR_SEND_INVITE } from "../../gqloperations/mutations";
import getMemberContractAmountStatus from "../../utils/getMemberContractAmountStatus";
import { AiOutlineSearch } from "react-icons/ai";
import { CiCircleRemove } from "react-icons/ci";
import getTypeIcon from "../../utils/getTypeIcon";
import { brandTypes } from "../../constants/commonConstant";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useTableStateProps } from "../../hooks/useTableStateprops";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useMemberContext } from "../../contexts/MemberContext";
import { SET_MEMBER_ID, SET_SEARCH_MEMBER_VALUE } from "../../actions";
import { useHandleError } from "../../hooks/useHandleError";
import {
  Modal,
  Header,
  FilterByType,
  Card,
  Table,
  Checkbox,
  FPIconNumber,
} from "../../components";
import ChangeEmailModal from "./ChangeEmailModal";
import { API } from "../../utils/API";
import useCustomFetch from "../../hooks/useCustomFetch";
import InsightsError from "../../components/dashboard/InsightsError";
import { isFitnessMembershipActive } from "../../utils/isContractActive";
const typeTitles = {
  MEMBER: "M",
  STAFF: "ST",
  "FITNESS PASSPORT": "FP",
};
const queryItemLimit = 100;
//no access restriction once the user's location access is more than 100
const noAccessLimitPoint = 100;

const Member = () => {
  let navigate = useNavigate();
  const client = useApolloClient();
  const { locationAccess } = useAuthContext();
  const {
    createLog,
    dispatch,
    sidebarLocationId,
    sidebarBrandId,
    sidebarLocationName,
    searchMemberValue,
    pageHistory,
  } = useMemberContext();
  const {
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
  } = useTableStateProps();
  const [tableData, setTableData] = useState();
  const [
    searchByCardNumberLocationFilter,
    setSearchByCardNumberLocationFilter,
  ] = useState([]);
  const handleError = useHandleError();
  const [brandWiseSearch, setBrandWiseSearch] = useState(false);
  const [initialFilter, setInitialFilter] = useState([]);
  const [initialDataVariables, setInitialDataVariables] = useState({});
  const [nextToken, setNextToken] = useState(null);
  const [filter, setFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [modalName, setModalName] = useState("");
  const [curMember, setCurMember] = useState({});
  const [inviteClickedMap, setInviteClickedMap] = useState({});
  const [memberStatsEndPoint, setMemberStatsEndPoint] = useState();
  const [paramsMemberStatsEndPoint, setParamsMemberStatsEndPoint] = useState();

  const {
    isLoading: memberStatsLoading,
    error: memberStatsError,
    data: memberStatsData,
  } = useCustomFetch(
    memberStatsEndPoint && memberStatsEndPoint,
    paramsMemberStatsEndPoint && paramsMemberStatsEndPoint
  );

  useEffect(() => {
    if (pageHistory.pageHistory) {
      const containsExactMember = (path) => {
        const segments = path.split("/").filter(Boolean);
        return segments.includes("member");
      };
      containsExactMember(pageHistory.pageHistory);
      if (!containsExactMember(pageHistory.pageHistory)) {
        fetchInitialData();
      }
    }
  }, [pageHistory.pageHistory]);

  useEffect(() => {
    if (sidebarLocationId == 0) {
      setMemberStatsEndPoint(API.memberStatsReport);
      setParamsMemberStatsEndPoint({
        brandId: sidebarBrandId,
        dimensions: `active,
          suspended,
          joined,
          gender,
          left,
          future,
          average_age,
          returned,`,
      });
    } else {
      setMemberStatsEndPoint(API.memberStatsReport);
      setParamsMemberStatsEndPoint({
        locationId: sidebarLocationId,
        brandId: sidebarBrandId,
        dimensions: `active,
          suspended,
          joined,
          gender,
          left,
          future,
          average_age,
          returned,`,
      });
    }
  }, [sidebarLocationId]);

  const [updateEmailOrSendInvite, { loading: updateEmailOrSendInviteLoading }] =
    useMutation(UPDATE_EMAIL_OR_SEND_INVITE);

  const searchMemberByEmail = async (filter) => {
    try {
      const result = await client.query({
        query: SEARCH_BY_EMAIL,
        fetchPolicy: "no-cache",
        variables: {
          email: searchMemberValue.toUpperCase(),
          filter,
          // filter: {
          //   or: initialFilter,
          // },
          limit: queryItemLimit,
        },
      });
      if (result) {
        setTableData(result.data?.getMemberByEmail?.items);
        setNextToken(result.data?.getMemberByEmail?.nextToken);
      }
    } catch (error) {
      handleError(`${error}`);
    }
  };

  const fetchInitialData = () => {
    dispatch({
      type: SET_SEARCH_MEMBER_VALUE,
      payload: "",
    });
    searchMembers({
      variables: initialDataVariables,
      onCompleted: (data) => {
        setTableData(data?.searchMembers?.items);
        setNextToken(data?.searchMembers?.nextToken);
      },
      onError: (error) => {
        handleError(`${error}`);
      },
    });
  };

  const searchMemberByAliasId = async (filter) => {
    try {
      const result = await client.query({
        query: SEARCH_BY_MEMBER_ALIAS_ID,
        fetchPolicy: "no-cache",
        variables: {
          aliasMemberId: searchMemberValue.replace(/^!/, ""),
          filter,
          // filter: {
          //   or: initialFilter,
          // },
          limit: queryItemLimit,
        },
      });
      if (result) {
        setTableData(result.data?.getMemberByAliasId?.items);
        setNextToken(null);
      }
    } catch (error) {
      handleError(`${error}`);
    }
  };
  const searchMemberByCardNumber = async (filter) => {
    try {
      const result = await client.query({
        query: SEARCH_MEMBER_BY_CARD_NUMBER,
        fetchPolicy: "no-cache",
        variables: {
          cardNumber: searchMemberValue.replace(/^#/, ""),
          operationName: "getMembersByCardNumber",
          homeLocationIds: filter,
          // homeLocationIds: searchByCardNumberLocationFilter,
        },
      });
      if (result) {
        setTableData(result?.data?.getMembersByCardNumber?.data);
        setNextToken(null);
      }
    } catch (error) {
      handleError(`${error}`);
    }
  };
  const [
    searchMembers,
    {
      loading: searchMembersLoading,
      error: searchMembersError,
      data: searchMembersData,
      refetch,
    },
  ] = useLazyQuery(SEARCH_MEMBER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setTableData(data?.searchMembers?.items);
      setNextToken(data?.searchMembers?.nextToken);
    },
    onError: (error) => {
      handleError(`${error}`);
    },
  });
  useEffect(() => {
    setTableData();
    searchMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandWiseSearch, initialFilter]);

  useEffect(() => {
    let memberLocationFilter = [];
    let searchByCardNumberLocationFilter = [];
    if (sidebarLocationId) {
      setSearchByCardNumberLocationFilter([sidebarLocationId]);
      memberLocationFilter.push({ homeLocationId: { eq: sidebarLocationId } });
    } else if (sidebarBrandId) {
      const brandLocations = locationAccess.brands.find(
        (brand) => brand.brandId === sidebarBrandId
      ).locations;
      brandLocations?.map((location) => {
        memberLocationFilter.push({
          homeLocationId: { eq: location.locationId },
        });
        searchByCardNumberLocationFilter.push(location.locationId);
        return null;
      });
      setSearchByCardNumberLocationFilter(searchByCardNumberLocationFilter);
    } else {
      memberLocationFilter.push({ homeLocationId: { eq: " " } });
      setSearchByCardNumberLocationFilter(locationAccess.locationAll);
    }
    setInitialFilter(memberLocationFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarBrandId, sidebarLocationId]);
  useEffect(() => {
    setInitialDataVariables({
      filter:
        initialFilter.length > noAccessLimitPoint
          ? { type: { eq: "MEMBER" }, brandId: { eq: sidebarBrandId } }
          : {
              type: { eq: "MEMBER" },
              or: initialFilter,
            },
      sort: [{ field: "createdAt", direction: "desc" }],
      limit: queryItemLimit,
    });
  }, [initialFilter]);

  useEffect(() => {
    !searchMemberValue &&
      searchMembers({
        variables: initialDataVariables,
        onCompleted: (data) => {
          setTableData(data?.searchMembers?.items);
          setNextToken(data?.searchMembers?.nextToken);
        },
        onError: (error) => {
          handleError(`${error}`);
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataVariables]);
  const getSearchMemberVariables = () => {
    setGlobalFilter("");
    const searchRangeFilter =
      brandWiseSearch || initialFilter.length > noAccessLimitPoint
        ? { brandId: { eq: sidebarBrandId } }
        : { or: initialFilter };
    if (searchMemberValue.includes("@")) {
      return {
        filter: {
          email: { eq: `${searchMemberValue.toUpperCase()}` },
          ...searchRangeFilter,
          // or: initialFilter,
        },
        sort: [{ field: "createdAt", direction: "desc" }],
        limit: queryItemLimit,
      };
    } else if (/\d/.test(searchMemberValue)) {
      let value = searchMemberValue;
      if (searchMemberValue.startsWith("+")) {
        value = searchMemberValue.replace("+", "");
      }
      if (searchMemberValue.startsWith("0")) {
        value = searchMemberValue.replace("0", "");
      }
      return {
        filter: {
          mobileNumber: { wildcard: `*${value}*` },
          ...searchRangeFilter,
          // or: initialFilter,
        },
        sort: [{ field: "createdAt", direction: "desc" }],
        limit: queryItemLimit,
      };
    } else {
      return {
        filter: {
          or: [
            {
              givenName: { wildcard: `*${searchMemberValue.toUpperCase()}*` },
            },
            { surname: { wildcard: `*${searchMemberValue.toUpperCase()}*` } },
          ],
          and: {
            ...searchRangeFilter,
            // or: initialFilter,
          },
        },
        sort: [{ field: "createdAt", direction: "desc" }],
        limit: queryItemLimit,
      };
    }
  };
  useEffect(() => {
    createLog("CLICKED Member tab on the sidebar", "sidebar");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchMember = () => {
    if (searchMemberValue.includes("@")) {
      searchMemberByEmail(
        brandWiseSearch || initialFilter.length > noAccessLimitPoint
          ? { brandId: { eq: sidebarBrandId } }
          : { or: initialFilter }
      );
      // brandWiseSearch ? [] : searchByCardNumberLocationFilter
      return;
    }
    if (searchMemberValue.startsWith("#")) {
      searchMemberByCardNumber(
        //TODO: backend need to take brandId to allow brand wise search.
        brandWiseSearch ? [] : searchByCardNumberLocationFilter
      );
      return;
    }

    if (searchMemberValue.startsWith("!")) {
      searchMemberByAliasId(
        brandWiseSearch || initialFilter.length > noAccessLimitPoint
          ? { brandId: { eq: sidebarBrandId } }
          : { or: initialFilter }
      );
      return;
    }
    !searchMemberValue
      ? searchMembers({
          variables: initialDataVariables,
          onCompleted: (data) => setTableData(data?.searchMembers?.items),
          onError: (error) => {
            handleError(`${error}`);
          },
        })
      : searchMembers({
          variables: getSearchMemberVariables(),
          onCompleted: (data) => {
            setTableData(data?.searchMembers?.items);
            setNextToken(searchMembersData?.searchMembers?.nextToken);
            setColumnFilters([]);
          },
          onError: (error) => {
            handleError(`${error}`);
          },
        });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    searchMember();
  };
  const handleNextToken = () => {
    setNextToken(searchMembersData?.searchMembers?.nextToken);
    if (searchMembersData?.searchMembers?.nextToken) {
      searchMembers({
        variables: {
          nextToken: searchMembersData?.searchMembers?.nextToken,
          ...getSearchMemberVariables(),
        },
        onCompleted: (data) => {
          setTableData([...tableData, ...data?.searchMembers?.items]);
          setNextToken(searchMembersData?.searchMembers?.nextToken);
        },
        onError: (error) => {
          handleError(`${error}`);
        },
      });
    }
  };

  const handleRowClick = (id, email) => {
    dispatch({
      type: SET_MEMBER_ID,
      payload: {
        memberId: id,
      },
    });
    navigate(`/dashboard/member/${id}`);
  };
  const handleFilterPopUp = (event) => {
    event.stopPropagation();
    setFilter(!filter);
  };

  const closeFilterPopUp = () => {
    setFilter(false);
  };

  const typeColumnFilter = (row, columnId, filterValues) => {
    const columnVal = row.getValue(columnId);
    return filterValues.some((cur) => {
      return columnVal === cur;
    });
  };

  // Custom filter function that will only include rows that have specific column values that contain only letters
  const stringColumnFilter = (row, _columnId, filterValues) => {
    // We are ignoring the columnId param because the columnId will be included in the hardcoded columnIds arr
    // Filter through these columns that contain only letters
    const columnIds = ["homeLocationDetails", "surname", "email"];
    return filterValues.every((curFilterValue) => {
      const doesColumnValIncludeAnyFilterVal = columnIds.some((curColId) => {
        const columnVal = row.getValue(curColId)?.toLowerCase();
        return !!columnVal && columnVal.includes(curFilterValue?.toLowerCase());
      });

      return doesColumnValIncludeAnyFilterVal;
    });
  };

  const createColumnFilterFromWord = (str) => {
    let columnFilter;
    if (/^[a-zA-Z]+$/.test(str)) {
      columnFilter = { id: "surname", value: str };
      // homeLocationDetails does not need to be included as a columnFilter obj because stringColumnFilter will check this column and surname
      // { id: "homeLocationDetails", value: str },
    } else if (/^(\+|[0-9])[0-9]*$/.test(str)) {
      columnFilter = { id: "mobile", value: str };
    } else if (/^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/.test(str)) {
      columnFilter = { id: "email", value: str };
    } else {
      columnFilter = { id: "surname", value: str };
    }
    return columnFilter;
  };

  const createColumnFilters = (strArr) => {
    const columnFilters = strArr.map((cur) => createColumnFilterFromWord(cur));
    const strFilterValues = [];

    const processedColumnFilters = columnFilters.reduce((acc, cur) => {
      if (cur.id === "surname") {
        strFilterValues.push(cur.value);
        return acc;
      }
      acc.push(cur);
      return acc;
    }, []);
    if (strFilterValues.length > 0) {
      processedColumnFilters.push({ id: "surname", value: strFilterValues });
    }
    return processedColumnFilters;
  };
  const searchFn = (e) => {
    const filterValue = e.target.value;
    const filterValueArray = filterValue.split(" ").filter((cur) => cur !== "");
    const filterValueWords = filterValueArray.map((item) => {
      if (/^[0-9]+$/.test(item) && item.charAt(0) === "0") {
        return item.substring(1);
      } else {
        return item;
      }
    });
    if (filterValueWords.length > 1) {
      const columnFilters = createColumnFilters(filterValueWords);
      setColumnFilters(columnFilters);
      setGlobalFilter("");
    } else {
      setGlobalFilter(filterValue.trim());
      setColumnFilters([]);
    }
  };
  const handleEmailClick = (memberId, email) => {
    setModalName("change-email");
    setCurMember((cur) => ({ ...cur, id: memberId, email: email }));
  };

  const handleInviteClick = (memberId, type) => {
    if (type === "sendInvite" && !inviteClickedMap[memberId]) {
      setInviteClickedMap((prevMap) => ({
        ...prevMap,
        [memberId]: true,
      }));

      updateEmailOrSendInvite({
        variables: {
          input: {
            memberId: memberId,
            operation: "SEND_INVITE",
          },
        },
        onCompleted: () => {
          toast.success("The invite has been sent via email!");
          setModalName("");

          setInviteClickedMap((prevMap) => ({
            ...prevMap,
            [memberId]: false,
          }));
        },
        onError: (error) => {
          handleError(`${error}`);
          setModalName("");

          setInviteClickedMap((prevMap) => ({
            ...prevMap,
            [memberId]: false,
          }));
        },
      });
    }
  };

  const onSubmit = (values, OnSubmitProps) => {
    updateEmailOrSendInvite({
      variables: {
        input: {
          memberId: curMember?.id,
          operation: "UPDATE_EMAIL",
          email: values?.email,
        },
      },
      onCompleted: () => {
        setTimeout(() => {
          toast.success("Updated email succesfully");
          refetch();
        }, 3000);
        setTimeout(() => {
          setModalName("");
        }, 2000);
      },
      onError: (error) => {
        handleError(`${error}`);
        setModalName("");
      },
    });
  };

  const columns = [
    {
      accessorKey: "brandDetails",
      header: "BRAND",
      cell: (info) => {
        const brandName = info.getValue() ? info.getValue().name : "";
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },
      enableSorting: true,
      enableGlobalFilter: false,
      id: "branddetails",
    },
    {
      accessorFn: (row) =>
        `${
          row?.state
            ? `${row.homeLocationDetails?.name} (${row.state})`
            : row.homeLocationDetails?.name
        }`,
      header: () => {
        return <span> HOME CLUB</span>;
      },
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "homeLocationDetails",
    },

    {
      accessorFn: (row) => `${row?.surname}, ${row?.givenName}`,
      header: () => {
        return <span> SURNAME, NAME</span>;
      },
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "surname",
      filterFn: "stringColumnFilter",
      enableColumnFilter: true,
    },
    {
      header: (props) => {
        const handleSubmit = (values, OnSubmitProps) => {
          const { checked } = values;
          // The third paramater of the column filter function
          props.column.setFilterValue(checked);

          closeFilterPopUp();
        };

        return (
          <>
            <div onClick={handleFilterPopUp} className="icon">
              <img
                className="sortpopupicon"
                width="13"
                height="10"
                src={chart_icon}
                alt=""
              />
              <span>TYPE</span>
            </div>
            {filter && (
              <div className="member-filter">
                <FilterByType
                  handleSubmit={handleSubmit}
                  cancelFn={closeFilterPopUp}
                />
              </div>
            )}
          </>
        );
      },
      accessorFn: (row) => {
        const isFitMembership = isFitnessMembershipActive(row?.contracts);

        if (isFitMembership) {
          return "FITNESS PASSPORT";
        } else {
          return row?.type;
        }
      },
      cell: (info) => {
        return (
          <span
            className={`${info
              ?.getValue()
              .toLowerCase()
              ?.replace(" ", "-")}-cell`}
          >
            {typeTitles[info?.getValue()]}
          </span>
        );
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "type",
      filterFn: "typeFilter",
      enableColumnFilter: true,
    },
    {
      accessorKey: "mobileNumber",
      header: "MOBILE",
      cell: (info) => {
        const mobile = info.getValue();
        return `${mobile?.slice(0, 3)}***${mobile?.slice(-2)}`;
      },
      enableSorting: false,
      enableGlobalFilter: true,
      id: "mobile",
      filterFn: "includesString",
      enableColumnFilter: true,
    },
    {
      accessorKey: "email",
      header: "EMAIL",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "email",
      filterFn: "includesString",
      enableColumnFilter: true,
    },
    {
      id: "row-actions",
      cell: (props) => {
        const isFitPassportMember = isFitnessMembershipActive(
          props?.row?.original?.contracts
        );

        return (
          <div className="row-action-container">
            {!props.row.original.accepted && (
              <div className="email-btn-container">
                <span className="tooltip">
                  <MdOutlineAlternateEmail
                    onClick={() => {
                      handleEmailClick(
                        props.row.original?.memberId,
                        props.row.original.email
                      );
                    }}
                  />
                  <span className="tooltip-text fs-10">Update Email</span>
                </span>

                <div className="beat-or-send">
                  <span className="tooltip">
                    {inviteClickedMap[props.row.original?.memberId] &&
                    updateEmailOrSendInviteLoading ? (
                      <BeatLoader color="white" size={4} />
                    ) : (
                      <RiMailSendLine
                        className={
                          inviteClickedMap[props.row.original?.memberId] &&
                          updateEmailOrSendInviteLoading
                            ? "disabled"
                            : ""
                        }
                        onClick={() => {
                          if (!inviteClickedMap[props.row.original?.memberId]) {
                            handleInviteClick(
                              props.row.original?.memberId,
                              "sendInvite"
                            );
                          }
                        }}
                      />
                    )}
                    <span className="tooltip-text fs-10">Send Invite</span>
                  </span>
                </div>
              </div>
            )}
            {isFitPassportMember && (
              <FPIconNumber
                type="table"
                fitNumber={isFitPassportMember.fitnessPassportNumber}
                memberDetails={props?.row?.original}
              />
            )}

            <div className="view-details">
              <span className="tooltip">
                <BsCardText
                  onClick={() => {
                    const id = props.row.original.memberId;
                    const email = props.row.getAllCells()[5].getValue();
                    handleRowClick(id, email);
                    createLog(
                      `Clicked ${props.row.original.givenName} ${props.row.original.surname}`,
                      "member",
                      `${props.row.original.memberId}`
                    );
                  }}
                />
                <span className="tooltip-text fs-10">View Details</span>
              </span>
            </div>
          </div>
        );
      },
    },
  ];
  const brandWiseSearchColumns = [
    {
      accessorKey: "brandDetails",
      header: "BRAND",
      cell: (info) => {
        const brandName = info.getValue() ? info.getValue().name : "";
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },
      enableSorting: true,
      enableGlobalFilter: false,
      id: "branddetails",
    },
    {
      accessorFn: (row) =>
        `${
          row?.state
            ? `${row.homeLocationDetails?.name} (${row.state})`
            : row.homeLocationDetails?.name
        }`,
      header: () => {
        return <span> HOME CLUB</span>;
      },
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "homeLocationDetails",
    },
    {
      accessorKey: "contracts",
      header: "",
      cell: (info) => {
        const activeNumber = getMemberContractAmountStatus(
          info.getValue()?.items
        );
        return activeNumber > 0 ? (
          <div className="active-contract-number fs-10">
            {activeNumber + " Active"}
          </div>
        ) : (
          ""
        );
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "contracts",
    },
    {
      accessorFn: (row) => `${row?.surname}, ${row?.givenName}`,
      header: () => {
        return <span> SURNAME, NAME</span>;
      },
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "surname",
      filterFn: "stringColumnFilter",
      enableColumnFilter: true,
    },
    {
      accessorKey: "type",
      header: (props) => {
        const handleSubmit = (values, OnSubmitProps) => {
          const { checked } = values;
          // The third paramater of the column filter function
          props.column.setFilterValue(checked);

          closeFilterPopUp();
        };

        return (
          <>
            <div onClick={handleFilterPopUp} className="icon">
              <img
                className="sortpopupicon"
                width="13"
                height="10"
                src={chart_icon}
                alt=""
              />
              <span>TYPE</span>
            </div>
            {filter && (
              <div className="member-filter">
                <FilterByType
                  handleSubmit={handleSubmit}
                  cancelFn={closeFilterPopUp}
                />
              </div>
            )}
          </>
        );
      },
      cell: (info) => (
        <span
          className={`${info
            .getValue()
            ?.toLowerCase()
            ?.replace(" ", "-")}-cell`}
        >
          {typeTitles[info.getValue()]}
        </span>
      ),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "type",
      filterFn: "typeFilter",
      enableColumnFilter: true,
    },
    {
      accessorKey: "mobileNumber",
      header: "MOBILE",
      cell: (info) => {
        const mobile = info.getValue();
        return `${mobile?.slice(0, 3)}***${mobile?.slice(-2)}`;
      },
      enableSorting: false,
      enableGlobalFilter: true,
      id: "mobile",
      filterFn: "includesString",
      enableColumnFilter: true,
    },
    {
      accessorFn: (row) => {
        let contactInfo = "";
        if (row?.emergencyContactName && row?.emergencyContactNumber) {
          contactInfo = `${row.emergencyContactName} - ${row.emergencyContactNumber}`;
        } else if (row?.emergencyContactName) {
          contactInfo = row.emergencyContactName;
        } else if (row?.emergencyContactNumber) {
          contactInfo = row.emergencyContactNumber;
        } else {
          contactInfo = "";
        }
        return contactInfo;
      },
      header: "Emergency Contact",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "emergencyContact",
    },
    {
      id: "row-actions-brandwise",
      cell: (props) => {
        const isFitPassportMember = isFitnessMembershipActive(
          props?.row?.original?.contracts
        );

        return (
          <div className="fp-loader">
            {isFitPassportMember && (
              <div className="">
                <FPIconNumber
                  type="table"
                  fitNumber={isFitPassportMember?.fitnessPassportNumber}
                  memberDetails={props?.row?.original}
                />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  if (searchMembersError) {
    return (
      <div>
        <div>Error: Problem in getting members</div>
      </div>
    );
  }
  return (
    <div>
      <Header pageTitle="Members" />
      <div className="member">
        <div className="member-container">
          <div className="member-info mt-32">
            <div className="row1-infocards">
              <div className="col1">
                <Card
                  title="Total"
                  heading="Total active memberships"
                  value={
                    memberStatsError ? (
                      <InsightsError />
                    ) : memberStatsLoading ? (
                      <BeatLoader color="white" />
                    ) : (
                      memberStatsData?.data?.active.toLocaleString()
                    )
                  }
                  fetchRecordError={memberStatsError}
                  icon="total"
                />
              </div>
              <div className="col2">
                <Card
                  title="future"
                  heading="Total future memberships"
                  value={
                    memberStatsError ? (
                      <InsightsError />
                    ) : memberStatsLoading ? (
                      <BeatLoader color="white" />
                    ) : (
                      memberStatsData?.data?.future.toLocaleString()
                    )
                  }
                  fetchRecordError={memberStatsError}
                  icon="MdOutlineArrowDropUp"
                />
              </div>
              <div className="col3">
                <Card
                  title="suspended"
                  heading="Total suspended memberships"
                  value={
                    memberStatsError ? (
                      <InsightsError />
                    ) : memberStatsLoading ? (
                      <BeatLoader color="white" />
                    ) : (
                      memberStatsData?.data?.suspended.toLocaleString()
                    )
                  }
                  fetchRecordError={memberStatsError}
                  icon="MdOutlineArrowDropDown"
                />
              </div>
              <div className="col4">
                <Card
                  title="avg.age"
                  heading="Average member age"
                  value={
                    memberStatsError ? (
                      <InsightsError />
                    ) : memberStatsLoading ? (
                      <BeatLoader color="white" />
                    ) : (
                      memberStatsData?.data?.averageAge.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    )
                  }
                  fetchRecordError={memberStatsError}
                  icon="average"
                />
              </div>
            </div>
            {searchMembersLoading ? (
              <div className="report">
                <BeatLoader className="loading" color="white" size={20} />
              </div>
            ) : (
              <div>
                <div className="row2 mt-32">
                  <div className=" actions-row">
                    <div className="left-elements flex-wrap">
                      <div>
                        <Checkbox
                          labelText="Cross-Location Search"
                          checked={brandWiseSearch}
                          onChange={() => {
                            setBrandWiseSearch(!brandWiseSearch);
                          }}
                        />
                      </div>
                      <div className="search-container">
                        <form onSubmit={handleSubmit}>
                          <div className="search-bar">
                            <div className="search-bar-container">
                              <input
                                className="fs-10"
                                type="text"
                                name="search-member-input"
                                id="search-member-input"
                                value={searchMemberValue}
                                onChange={(e) => {
                                  dispatch({
                                    type: SET_SEARCH_MEMBER_VALUE,
                                    payload: e.target.value.trim(),
                                  });
                                }}
                                placeholder="first name, surname, mobile or email"
                              />
                              {searchMemberValue && (
                                <div
                                  className="remove-icon"
                                  onClick={fetchInitialData}
                                >
                                  <CiCircleRemove />
                                </div>
                              )}

                              <button type="submit">
                                <AiOutlineSearch />
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="add-btn">
                        <Link to={`/dashboard/member/addmember`}>
                          <button
                            type="button"
                            className="btn btn-primary btn-lg fs-12"
                          >
                            Add a member
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {modalName && (
                  <Modal>
                    <div className="send-sms-container pd-32">
                      <ChangeEmailModal
                        onClick={() => setModalName("")}
                        email={curMember?.email}
                        onSubmit={onSubmit}
                      />
                    </div>
                  </Modal>
                )}

                {tableData &&
                  (brandWiseSearch ? (
                    <div className="brandwise-table">
                      <Table
                        data={tableData}
                        columns={brandWiseSearchColumns}
                        handleNextToken={handleNextToken}
                        nextToken={nextToken}
                        brandWiseSearch={brandWiseSearch}
                        hasSearchBar={true}
                        searchFn={searchFn}
                        columnFilter={isFiltered}
                        setColumnFilter={setIsFiltered}
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        filterFns={{
                          typeFilter: typeColumnFilter,
                          stringColumnFilter,
                        }}
                      />
                    </div>
                  ) : (
                    <Table
                      onRowClick={(i) => {
                        const id = i.memberId;
                        const email = i.email;
                        handleRowClick(id, email);
                        createLog(
                          `Clicked ${i.givenName} ${i.surname}`,
                          "member",
                          `${i.memberId}`
                        );
                      }}
                      data={tableData}
                      columns={columns}
                      columnFilter={isFiltered}
                      setColumnFilter={setIsFiltered}
                      rowsPerPage={10}
                      filterFns={{
                        typeFilter: typeColumnFilter,
                        stringColumnFilter,
                      }}
                      columnFilters={columnFilters}
                      setColumnFilters={setColumnFilters}
                      handleNextToken={handleNextToken}
                      nextToken={nextToken}
                      sorting={sorting}
                      setSorting={setSorting}
                      hasSearchBar={true}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                      searchFn={searchFn}
                    />
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Member;
