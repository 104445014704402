import React from "react";
import "../../pages/Dashboard/dashboard.scss";
import { Modal, Button } from "../../components";
import { AiOutlineCloseCircle } from "react-icons/ai";
const VersionNotes = ({ setModalOpen }) => {
  const handleClose = () => {
    setModalOpen(false); // Calls onClose from Dashboard to close modal
  };

  return (
    <div className="modal-container version-updates-modal">
      <Modal>
        <div className="capitalize flex-col">
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <span onClick={handleClose}>
              <AiOutlineCloseCircle />
            </span>
          </div>
          <h3 className=" version-title mb-18">What's New</h3>
          <p className="fs-18">Release Ver 1.43.3 - 9th May 2024</p>
          <br />
          <p className="fs-12">
            The Viva Labs teams are excited to announce the latest updates to
            the HUB. This release includes new superpowers, enhanced features,
            and several squished bugs to improve your experience and operational
            efficiency. Here’s what’s new:
          </p>
        </div>
        <div className="version-improvements capitalize flex-col">
          <h3 className="fs-18 mb-12 changes-headers">New Superpowers 🦸 </h3>
          <ol className="version-improvements-list fs-14">
            <li>
              Add Membership to Member via Multi-Step Form: Streamline your
              member management by using a multi-step form, now integrated into
              the HUB flow from Step 2 (Adding a new Member). This enhanced
              process supports both Cash and EFTPOS payments, offering
              flexibility and ease in managing memberships.
            </li>
            <li>
              PIF Renewals Functionality: For uninterrupted service, HUB users
              can now renew their Paid-In-Full (PIF) memberships directly
              through the HUB. Renew existing memberships without additional
              joining, admin, or access fees. It’s all about the membership
              amount, with the full capability to edit fees as needed.
            </li>
          </ol>
        </div>
        <hr></hr>
        <div className="version-improvements capitalize flex-col">
          <h3 className="fs-18 mb-12 changes-headers">Enhanced Features ✨</h3>
          <ol className="version-improvements-list fs-14">
            <ol>
              <li>
                Expanded Payment Options for Membership Services: To ensure
                versatility and convenience in transactions, we've expanded the
                available payment methods. Members can now renew or add
                memberships using:
                <ul>
                  <li>Cash Payments</li>
                  <li>EFTPOS</li>
                </ul>
              </li>

              <li>
                Streamlined Fee Adjustments: We've simplified the process of
                editing fees during the renewal of PIF memberships, ensuring
                that you can easily adjust fees directly, without the need for
                vouchers.
              </li>
            </ol>
          </ol>
        </div>
        <hr></hr>

        <hr></hr>
        <p className="fs-18">Release Ver 1.43.2 - 6th May 2024</p>
        <div className="version-improvements capitalize flex-col">
          <h3 className="fs-18 mb-12 changes-headers">New Superpowers 🦸 </h3>
          <ol className="version-improvements-list fs-14">
            <li>
              Flexi Membership Visibility: Flexi Memberships are now fully
              integrated and visible under the locations module, ensuring you
              have complete access to all membership options across different
              locations.
            </li>
          </ol>
        </div>
        <hr></hr>
        <div className="version-improvements capitalize flex-col">
          <h3 className="fs-18 mb-12 changes-headers">Enhanced Features ✨</h3>
          <ol className="version-improvements-list fs-14">
            <ol>
              <li>
                Accurate Fee Display in Agreements: We've improved the accuracy
                in our agreement module. Fees are now correctly displayed,
                picking the right fee directly from the locations membership tab
                to avoid any confusion between FOB fees and activation fees.
              </li>

              <li>
                Payment Method Flexibility for Minors: For members under 18,
                once the membership has been activated, there is now the
                flexibility to change the primary payment method to a bank
                account, providing more options for financial management.
              </li>

              <li>
                Membership Management Improvements: Upgrading or downgrading a
                membership is now seamless with no more blank screens — an issue
                due to errors in fetching memberships has been resolved.
              </li>

              <li>
                Enhanced Sorting Filters: Adding sorting filters under
                Memberships for the LIVE and STATUS columns helps you better
                manage and view membership statuses efficiently.
              </li>
            </ol>
          </ol>
        </div>
        <hr></hr>
        <div className="version-improvements capitalize flex-col">
          <h3 className="fs-18 mb-12 changes-headers">Squished Bugs 🐛</h3>
          <ol className="version-improvements-list fs-14">
            <li>
              Batch Payment Receipts: Users can now download historical receipts
              for batch payments just as they have been able to for transaction
              payments, ensuring comprehensive access to payment histories.
            </li>
            <li>
              Corrected Payment Failed Message: The misleading failed payment
              message has been fixed to reflect accurate transaction statuses.
            </li>

            <li>
              Direct Debit Widget Update: We've removed the word "ATTEMPTED"
              from the Direct Debit widget on the dashboard for clarity and to
              better reflect the actual transaction status.
            </li>
            <li>
              Staff Icon Display Fix: The incorrect display of staff as 'FP'
              (icon) has been corrected to ensure proper representation in the
              system.
            </li>
          </ol>
        </div>
        {/* <hr></hr> */}
        {/* <div className="version-improvements capitalize flex-col">
          <h3 className="fs-18 mb-12 changes-headers">
            New Voucher Management 🎫
          </h3>
          <ol className="version-improvements-list fs-14">
            <li>
              Create, manage, and redeem vouchers with custom amounts, dates,
              and redemption rules.
            </li>
            <li>
              Integrates with billing and payments to apply vouchers at signup
              and checkout.
            </li>
            <li>
              Generate unique voucher codes and limit number of redemptions.
            </li>
            <li>
              Create different voucher types like fixed, percentage, visit pass,
              and complimentary.
            </li>
            <li>Reporting tracks voucher usage and redemptions.</li>
            <li>Email/text new users with their voucher information.</li>
          </ol>
        </div> */}
        <hr></hr>
        <p className="fs-12">
          We hope you enjoy these new features and improvements. As always, we
          appreciate your feedback and are here to support you in getting the
          most out of the HUB.
        </p>
        <p style={{ textDecoration: "underline" }} className="fs-12">
          To take advantage of these new features and improvements, please
          refresh your browser now.
        </p>
        <p className="fs-12">
          For any questions or additional support, please contact your BPMs{" "}
        </p>
        {/* <div className="version-improvements capitalize flex-col">
          <h3 className="fs-18 mb-12 changes-headers">
            Performance Improvements 📊
          </h3>
          <ol className="version-improvements-list fs-14">
            <li>Optimised outstanding balance querying for faster access.</li>
            <li>Added indexes to settlement dates to improve access speed.</li>
          </ol>
        </div> */}
        {/* <div className="capitalize flex-col">
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <span onClick={handleClose}>
              <AiOutlineCloseCircle />
            </span>
          </div>
          <h3 className=" version-title mb-18">What's New</h3>
          <p className="fs-18">Release Ver. 1.3.2 - Nov 28, 2023</p>
        </div>
        <div className="version-improvements capitalize flex-col">
          <h3 className="fs-18 mb-12 changes-headers">New Features:</h3>
          <ol className="version-improvements-list fs-14">
            <li>
              <strong>Location Details Expansion:</strong> You can now view
              account name, BSB, and account number fields within location
              details. We've also ensured that these fields are properly
              validated for accuracy and proper formatting.
            </li>
            <li>
              <strong>Updated Membership Module Permissions:</strong> We've made
              some changes to our membership module permissions to enhance
              security and control over different access levels.
            </li>
          </ol>
        </div>
        <hr></hr>
        <div className="version-improvements capitalize flex-col">
          <h3 className="fs-18 mb-12 changes-headers">Improvements:</h3>
          <ol className="version-improvements-list fs-14">
            <li>
              <strong>Help Link Update:</strong> We've updated the Help link in
              the Sidebar for easier access to our support documents.
            </li>
            <li>
              <strong>Enhanced Email Tooltips:</strong> The "send email" and
              "update email" icons under the staff and member landing page now
              include tooltips for better guidance.
            </li>
            <li>
              <strong>Optimised Location Access Display:</strong> We've
              optimised the membership pricing display to show user location
              access that correlates with their specific brand membership. Now,
              it uses only one query instead of two, making the process faster
              and more efficient.
            </li>
            <li>
              <strong>Real-Time Member Access Logs:</strong> On the member
              access logs page, we've improved the system to fetch updated data
              every 5 seconds, providing you with the most current information
              without needing to manually refresh the page.
            </li>
          </ol>
        </div>
        <hr></hr>
        <div className="version-improvements capitalize flex-col">
          <h3 className="fs-18 mb-12 changes-headers">Fixes:</h3>
          <ol className="version-improvements-list fs-14">
            <li>
              <strong>OTP Login Screen:</strong> We've addressed an issue with
              the OTP login screen to ensure it follows the correct flow.
            </li>
            <li>
              <strong>Class Time Display:</strong> The class time display has
              been fixed and is now correctly converted from UTC time to
              Australian time zone.
            </li>
            <li>
              <strong>Entry Snapshot Visibility:</strong> We've hidden the last
              entry snapshot, which was previously visible despite being
              unavailable.
            </li>
            <li>
              <strong>Location Application Fixes:</strong> We've fixed the Apply
              to location feature to correctly display the location access of
              the user and determine if these locations correspond to the
              specific brand associated with their membership.
            </li>
          </ol>
        </div>
        <hr />
        <div className="capitalize flex-col">
          <div style={{ display: "flex", flexDirection: "row-reverse" }}></div>
          <p className="fs-18">Release Ver. 1.3.1 - Nov 22, 2023</p>
        </div>
        <div className="version-improvements capitalize flex-col">
          <h3 className="fs-18 mb-12 changes-headers">New Features:</h3>
          <ol className="version-improvements-list fs-14">
            <li>
              Added membership level when adding a new membership so you can now
              assign levels like Adult, Youth and Junior.
            </li>
            <li>
              Added search by member email when booking classes to easily look
              for members for class bookings.
            </li>
            <li>
              Added search by member email when booking classes to easily look
              for members for class bookings.
            </li>
            <li>
              Added rate limiting for OTP login API to prevent brute force
              attacks. Your logins are now more secure.
            </li>
            <li>
              Generated settlement reports automatically to simplify your
              accounting.
            </li>
            <li>Delayed membership payment date to create promos.</li>
            <li>
              Enhanced settlement report generation with more input options for
              easier manual creation.
            </li>
            <li>
              Added SMS text replies so you can configure automatic responses to
              members.
            </li>
          </ol>
        </div>
        <hr></hr>
        <div className="version-improvements capitalize flex-col">
          <h3 className="fs-18 mb-12 changes-headers">Improvements:</h3>
          <ol className="version-improvements-list fs-14">
            <li>
              Updated sidebar location interaction for staff to improve
              navigation.
            </li>
            <li>
              Updated styling of membership type and level fields when adding
              membership for clearer data entry.
            </li>
            <li>
              Updated mutation for updating member email to initiate invite
              send.
            </li>
            <li>
              Added membership level on view membership page to see all member
              details in one place.
            </li>
            <li>
              Updated Roles and Permissions for more fine-grained control.
            </li>
            <li>
              Enhanced input cases for manual settlement report generation to
              handle more scenarios.
            </li>
            <li>Updated invoice report schema to fetch the data you need.</li>
            <li>
              Added location details to the class schedule so you can see where
              classes are held.
            </li>
            <li>
              Added validation for card numbers to prevent bogus data from being
              saved.
            </li>
          </ol>
        </div>
        <hr></hr>
        <div className="version-improvements capitalize flex-col">
          <h3 className="fs-18 mb-12 changes-headers">Fixes:</h3>
          <ol className="version-improvements-list fs-14">
            <li>
              Fixed membership location contract length display for accurate
              membership details.
            </li>
            <li>
              Fixed limits on changing membership locations to match business
              rules.
            </li>
            <li>
              Fixed alignment issues on multiple pages so the UI stays orderly.
            </li>
            <li>
              Fixed medical suspension modal text for clear communication.
            </li>
            <li>Fixed class booking issues to prevent double bookings.</li>
            <li>
              Fixed change email button functionality so members can update
              contacts.
            </li>
            <li>
              Prevented clicking when button disabled for proper disabled state.
            </li>
            <li>
              Validated health questionnaire responses on signup to keep member
              data complete.
            </li>
            <li>
              Fixed payment information syncing to keep billing current.
              Transaction history in sync.
            </li>
            <li>
              Fixed OTP login email default to send verification messages.
            </li>
            <li>
              Fixed end date validation when suspending membership to prevent
              conflicts.
            </li>
            <li>Fixed batch payment issues to properly calculate statements</li>
            <li>Fixed door access issues that were causing access denials.</li>
            <li>
              Fixed send invite loader display so you know invites are
              processing.
            </li>
            <li>Fixed member table join logic to surface the correct data.</li> */}
        {/* <li>
              Frontend Fixes:
              <ul>
                <li>
                  Fixed styling and responsiveness issues in adding membership,
                  suspensions, classes etc.
                </li>
                <li>
                  Resolved bugs in member details, search members, and junior
                  member downloads.
                </li>
                <li>
                  Updated text labels, removed static values, and resize
                  elements.
                </li>
                <li>Fixed payment and VivaPay integration issues.</li>
              </ul>
            </li> */}

        {/* <li>
              Backend Fixes:
              <ul>
                <li>
                  Fixed bugs in member blocking, changing membership, and
                  approving under 18 members.
                </li>
                <li>
                  Resolved issues in generating PDFs, managing agreements, and
                  member receipts.
                </li>
                <li>
                  Removed suspension cost from Hub and portal as per new
                  requirements.
                </li>
                <li>
                  Fixed member report generation and door discovery distance
                  configurations.
                </li>
                <li>
                  Rectified errors in adding members, contracts and configs.
                </li>
              </ul>
            </li> */}
        {/* </ol>
        </div> */}
        <div className="btn-group flex-row">
          <Button
            name="Close"
            btntype="button"
            btnname="button"
            className="btn btn-lightgray  btn-small"
            click={handleClose}
          />
        </div>
      </Modal>
    </div>
  );
};

export default VersionNotes;
