import "./select.scss";

const SelectWithoutFormik = (props) => {
  const {
    label,
    edit,
    initialKey,
    initialValue,
    onChange,
    name,
    optionvalue,
    optiondisplay,
    optionkey,
    className,
    disabled,
    selecttype,
    value,
  } = props;

  return (
    <div className="select-element">
      <label className="fs-12">{label}</label>
      {selecttype === "object" && (
        <>
          <select
            value={value}
            name={name}
            className={className}
            onChange={onChange}
            disabled={disabled}
          >
            {edit ? (
              <option key={initialKey}>{initialValue}</option>
            ) : (
              <option value="">--Select an Option--</option>
            )}
            {optionvalue.map((option) => (
              <option key={option.id} value={option[optionkey]}>
                {option[optiondisplay]}
              </option>
            ))}
          </select>
        </>
      )}

      {selecttype === "array" && (
        <>
          <select
            name={name}
            className={className}
            onChange={onChange}
            disabled={disabled}
          >
            {initialValue ? (
              <option value={initialValue} key={initialValue}>
                {initialValue}
              </option>
            ) : (
              <option value="" key="">
                --Select an Option--
              </option>
            )}
            {optionvalue.map((val, index) => (
              <option value={val} key={index}>
                {val}
              </option>
            ))}
          </select>
        </>
      )}
    </div>
  );
};

export default SelectWithoutFormik;
