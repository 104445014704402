import "./modal.scss";
import "../../components/location/singleLocation/maintenance_tab/maintenance.scss";

import React, { useState } from "react";
import { format, startOfDay, endOfDay, isAfter, isBefore } from "date-fns";

import { Formik, Form } from "formik";
import DatePicker from "../formUiElements/input/DatePicker";
import Button from "../formUiElements/Button";

import { maintenanceDatesSchema } from "../../validations/Validation";

const EditMaintenanceModal = (props) => {
  const { modalClose, maintenanceDetails, onEditMaintenance, loading } = props;

  const [overlappedWith, setOverlappedWith] = useState([]);

  const today = new Date();

  const startDateEditable = isAfter(
    new Date(maintenanceDetails.startDateTime),
    startOfDay(today)
  );
  const endDateEditable = isAfter(
    new Date(maintenanceDetails.endDateTime),
    startOfDay(today)
  );

  const handleFormChange = () => {
    if (overlappedWith?.length > 0) setOverlappedWith([]);
  };

  const handleSubmit = async (values, submitProps) => {
    const { startDateTime, endDateTime } = values;

    const start = startOfDay(new Date(startDateTime)).toISOString();
    const end = endOfDay(new Date(endDateTime)).toISOString();
    const maintenanceId = props.maintenanceDetails.id;

    onEditMaintenance(start, end, maintenanceId);
  };

  return (
    <div className="add-maintenance">
      <Formik
        initialValues={{
          startDateTime: format(
            new Date(maintenanceDetails.startDateTime),
            "yyyy-MM-dd"
          ),
          endDateTime: format(
            new Date(maintenanceDetails.endDateTime),
            "yyyy-MM-dd"
          ),
        }}
        onSubmit={handleSubmit}
        validationSchema={maintenanceDatesSchema}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form onChange={handleFormChange} className="status-modal pd-32">
              <h3 className="fs-35 uppercase">Edit Maintenance</h3>

              <div className="date-range">
                {startDateEditable ? (
                  <div className="date-control">
                    <label
                      htmlFor="startDateTime"
                      className="label fs-12 text-gray"
                    >
                      Start date
                    </label>
                    <DatePicker
                      name="startDateTime"
                      className="control fs-12 text-white"
                      value={formik.values.startDateTime}
                      error={formik.errors.startDateTime}
                      onChange={formik.handleChange}
                      min={format(today, "yyyy-MM-dd")}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="start-date">
                      <h6 className="fs-12 text-gray">Start date</h6>
                      <p className="fs-12">
                        {format(
                          new Date(maintenanceDetails.startDateTime),
                          "dd-MM-yyyy"
                        )}
                      </p>
                    </div>
                    {formik.errors.startDateTime &&
                      formik.touched.startDateTime && (
                        <div className="error fs-12 text-red">
                          {formik.errors.startDateTime}
                        </div>
                      )}
                  </div>
                )}
                {endDateEditable ? (
                  <div className="date-control">
                    <label
                      htmlFor="endDateTime"
                      className="label fs-12 text-gray"
                    >
                      End date
                    </label>
                    <DatePicker
                      name="endDateTime"
                      className="control fs-12 text-white"
                      value={formik.values.endDateTime}
                      error={formik.errors.endDateTime}
                      onChange={formik.handleChange}
                      min={format(today, "yyyy-MM-dd")}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="start-date">
                      <h6 className="fs-12 text-gray">End date</h6>
                      <p className="fs-12">
                        {" "}
                        {format(
                          new Date(maintenanceDetails.endDateTime),
                          "dd-MM-yyyy"
                        )}
                      </p>
                    </div>
                    {formik.errors.endDateTime &&
                      formik.touched.endDateTime && (
                        <div className="error fs-12 text-red">
                          {formik.errors.endDateTime}
                        </div>
                      )}
                  </div>
                )}
              </div>

              <div className="btn-group">
                <Button
                  loading={loading}
                  disabled={loading}
                  name="Save"
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-confirm btn-small"
                />

                <Button
                  name="Cancel"
                  btntype="button"
                  btnname="cancel-button"
                  className="btn btn-deny btn-small"
                  click={modalClose}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditMaintenanceModal;
