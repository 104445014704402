import "./editInstructor.scss";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_CLASS_INSTRUCTOR } from "../../gqloperations/mutations";
import {
  GET_CLASS_INSTRUCTOR,
  LIST_CLASS_INSTRUCTORS,
} from "../../gqloperations/queries";
import { toast } from "react-toastify";
import {
  Header,
  CredentialTable,
  InstructorPersonalInfo,
  InstructorContactDetail,
  InstructorAddressDetail,
  InstructorDescription,
  UploadCredential,
} from "../../components";
import { useHandleError } from '../../hooks/useHandleError';

const EditInstructor = () => {
  const { id } = useParams();
  const handleError = useHandleError();
  const [updateClassInstructor] = useMutation(UPDATE_CLASS_INSTRUCTOR);
  const {
    loading: instructorLoading,
    error: instructorError,
    data: instructorData,
    refetch,
  } = useQuery(GET_CLASS_INSTRUCTOR, {
    variables: {
      id,
    },
  });
  const onSubmit = async (values, OnSubmitProps) => {
    let updatedFields = {};
    for (let key in values) {
      if (
        String(values[key]).toUpperCase() !==
        String(instructorData.getClassInstructor[key]).toUpperCase()
      ) {
        updatedFields[key] = values[key].toUpperCase();
      }
    }

    try {
      await updateClassInstructor({
        variables: {
          input: {
            ...updatedFields,
            id,
          },
        },
        onCompleted: () => {
          OnSubmitProps.setSubmitting(false);
          OnSubmitProps.resetForm();
          toast.success("Instructor Edited Successfully");
          refetch();
        },
        refetchQueries: [
          {
            query: LIST_CLASS_INSTRUCTORS,
          },
        ],
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };
  if (instructorLoading) return <p>Loading</p>;
  if (instructorError) return <p>Something Wrong!</p>;

  return (
    <div className="add-staff pb-32">
      <Header pageTitle="Edit Instructor" />
      <div className="edit-instructor-elements add-staff-container mt-32">
        <InstructorPersonalInfo
          instructorInfo={instructorData?.getClassInstructor}
          onSubmit={onSubmit}
        />
        <InstructorContactDetail
          instructorInfo={instructorData?.getClassInstructor}
          onSubmit={onSubmit}
        />
        <InstructorAddressDetail
          instructorInfo={instructorData?.getClassInstructor}
          onSubmit={onSubmit}
        />
        <InstructorDescription
          instructorInfo={instructorData?.getClassInstructor}
          onSubmit={onSubmit}
        />
        <UploadCredential instructorId={id} />
        <CredentialTable instructorInfo={instructorData?.getClassInstructor} />

        {/* <div className="btn-group right-btn">
          <Button
            name="SAVE"
            btntype="submit"
            btnname="submit"
            className="btn btn-confirm fs-12"
          />
          <Link to={`/dashboard/instructors`}>
            <Button
              name="Cancel"
              btntype="button"
              className="btn btn-deny  fs-12"
            />
          </Link>
        </div> */}
      </div>
    </div>
  );
};
export default EditInstructor;
