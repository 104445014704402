import "./fpiconnumber.scss";
import { UPDATE_FITNESS_PASSPORT } from "../../gqloperations/mutations";
import { BeatLoader } from "react-spinners";
import { BsPassport } from "react-icons/bs";
import { toast } from "react-toastify";
import { useMemberContext } from "../../contexts/MemberContext";
import { useHandleError } from "../../hooks/useHandleError";
import { useMutation } from "@apollo/client";
const FPIconNumber = ({ type, fitNumber, memberDetails }) => {
  const handleError = useHandleError();
  const { sidebarLocationId, sidebarLocationName } = useMemberContext();
  const [updateFitnessPassport, { loading: updateFitnessPassportLoading }] =
    useMutation(UPDATE_FITNESS_PASSPORT);

  const handleFitPassportClick = (fitNumber, memberDetails) => {
    const findLocationId = sidebarLocationId
      ? sidebarLocationId
      : memberDetails?.homeLocationId;
    updateFitnessPassport({
      variables: {
        locationId: findLocationId,
        FitnessPassportNumber: fitNumber,
      },
      onCompleted: (data) => {
        if (data.updateFitnessPassport) {
          toast.success(
            `Fitness Passport: Entry permitted at ${
              sidebarLocationId
                ? sidebarLocationName
                : memberDetails.homeLocationDetails.name
            }`
          );
        } else {
          toast.error(
            `Fitness Passport: Entry denied at ${
              sidebarLocationId
                ? sidebarLocationName
                : memberDetails?.homeLocationDetails?.name
            }`
          );
        }
      },
      onError: (error) => {
        handleError(
          `Fitness Passport: Entry denied at ${
            sidebarLocationId
              ? sidebarLocationName
              : memberDetails?.homeLocationDetails?.name
          }`
        );
      },
    });
  };
  if (type === "table") {
    return (
      <>
        {updateFitnessPassportLoading ? (
          <BeatLoader color="white" size={4} className="beat-loader" />
        ) : (
          <div className="fitness-passport">
            <span className={`tooltip ${!fitNumber ? "tooltip-red" : ""}`}>
              <BsPassport
                onClick={() => {
                  if (fitNumber) {
                    handleFitPassportClick(fitNumber, memberDetails);
                  }
                }}
              />
              <span className="tooltip-text fs-10">
                {fitNumber
                  ? "Validate Fitness passport"
                  : "No fitness barcode "}
              </span>
            </span>
          </div>
        )}
      </>
    );
  }
  if (type === "icon") {
    const iconText = fitNumber ? `FP: ${fitNumber}` : "FP";
    return (
      <div
        className="fp-container fs-10"
        onClick={() => {
          if (fitNumber) {
            handleFitPassportClick(fitNumber, memberDetails);
          }
        }}
      >
        {updateFitnessPassportLoading ? (
          <BeatLoader color="white" size={4} className="beat-loader" />
        ) : (
          <span>{iconText}</span>
        )}
      </div>
    );
  }
};
export default FPIconNumber;
