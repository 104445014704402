import './locationHours.scss';

import { useState } from 'react';

import { MdDeleteOutline } from 'react-icons/md';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';

import addTimeIcon from '../../../../assets/images/icons/location/add_time.png';
import { useAuthContext } from '../../../../contexts/AuthContext/AuthProvider';
import { DELETE_LOCATION_HOURS } from '../../../../gqloperations/mutations';
import formatTime from '../../../../utils/formatTime';
import Modal from '../../../modal/Modal';
import StatusModal from '../../../modal/StatusModal';
import AddTimePeriodRow from './AddTimePeriodRow';
import { useHandleError } from '../../../../hooks/useHandleError';

export default function AccessTimeTable({
  type,
  title,
  tableData,
  is24Hours,
  getLocationById,
  tab,
}) {
  const { permissions } = useAuthContext();
  const [addTime, setAddTime] = useState("");
  const [componentName, setComponentName] = useState("");
  const [curRow, setCurRow] = useState({});
  const [deleteHours] = useMutation(DELETE_LOCATION_HOURS, {});
  const handleError = useHandleError();
  
  const handleComponent = (componentname, rowObj) => {
    setComponentName(componentname);
    setCurRow(rowObj);
  };
  const modalClose = () => {
    setComponentName("");
    setCurRow({});
  };

  const handleModalHeaderTxt = (tab) => {
    if (tab === "staff") {
      return "DELETE STAFFED HOURS";
    } else if (tab === "openClose") {
      return "DELETE OPEN HOURS";
    } else if (tab === "creche") {
      return "DELETE CRECHE HOURS";
    }
  };
  const handleDeleteClick = async (rowObj) => {
    try {
      await deleteHours({
        variables: {
          input: {
            id: rowObj.id,
          },
        },
        onCompleted: () => {
          getLocationById();
          toast.success("Time Period Deleted Successfully");
          modalClose();
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };
  return (
    <>
      <div className="staffed-table">
        {is24Hours ? (
          <div className="no-data-holder">
            <p className="no-data fs-14">This is a 24/7 Location</p>
          </div>
        ) : (
          <div className="table">
            <table>
              <tbody>
                {tableData.map((day) => (
                  <tr className="main-row header" key={day.id}>
                    <th className="fs-14">{day.day}</th>
                    <td>
                      <table>
                        <tbody className="time-display-table">
                          {day.timePeriod.map((timePeriod) => (
                            <tr className="no-underline" key={timePeriod.id}>
                              <td className="center">
                                <p className="time-select fs-10">
                                  {formatTime(timePeriod.openTime)}
                                </p>
                              </td>
                              <td className="center time-field">
                                <p className="time-select fs-10">
                                  {formatTime(timePeriod.closeTime)}
                                </p>
                              </td>
                              <td className="center">
                                {permissions.includes("CanDeleteClubHours") && (
                                  <MdDeleteOutline
                                    onClick={() =>
                                      handleComponent("delete-template", {
                                        id: timePeriod.id,
                                      })
                                    }
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {addTime === day.id && (
                        <AddTimePeriodRow
                          type={type}
                          day={day.day}
                          setAddTime={setAddTime}
                          getLocationById={getLocationById}
                          timePeriods={day.timePeriod}
                        />
                      )}
                    </td>
                    <td className="add-slot">
                      {permissions.includes("CanAddLocationAccessTimes") && (
                        <button
                          className="fs-10 btn-xsmall uppercase"
                          onClick={() => setAddTime(day.id)}
                        >
                          <img src={addTimeIcon} alt="add hour" />
                          <span>
                            add hours
                          </span>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {componentName && (
              <Modal>
                {componentName === "delete-template" && (
                  <StatusModal
                    curRow={curRow}
                    modalHeaderTxt={handleModalHeaderTxt(tab)}
                    modalParaText={
                      "Are you sure you want to delete these hours?"
                    }
                    handleStatusClick={handleDeleteClick}
                    modalClose={modalClose}
                  />
                )}
              </Modal>
            )}
          </div>
        )}
      </div>
    </>
  );
}
