import "./smslocation.scss";
import { useEffect, useState } from "react";
import { IncomingSMS, OutgoingSMS } from "../../../../components";
import { useLocationContext } from "../../../../contexts/LocationContext";

const SMSLocation = () => {
  const { state } = useLocationContext();
  const [tabName, setTabName] = useState("outgoing");

  useEffect(() => {
    if (state.index !== 8) {
      setTabName("outgoing");
    }
  }, [state.index]);

  return (
    <>
      <div className="">
        <div className="tabs ">
          <div className="tabs-list">
            <div
              className={
                tabName !== "outgoing"
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => {
                setTabName("outgoing");
              }}
            >
              OUTGOING
            </div>

            <div
              className={
                tabName !== "incoming"
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => {
                setTabName("incoming");
              }}
            >
              INCOMING
            </div>
          </div>

          {state.index === 8 && tabName === "outgoing" && (
            <div className="tabContent">
              <OutgoingSMS />
            </div>
          )}
          {state.index === 8 && tabName === "incoming" && (
            <div className="tabContent">
              <IncomingSMS />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SMSLocation;
