import "./toggle.scss";
import { useField } from "formik";
const ToggleFormik = (props) => {
  const [field] = useField(props);
  const checked = `${field.value}`;
  return (
    <>
      {checked === "true" && (
        <div className="toggle">
          <label className="switch">
            {1}
            <input
              type="checkbox"
              // id={props.id}
              // name={props.name}
              {...field}
              {...props}
              checked
            />

            <span className="slider round"></span>
          </label>
          <label htmlFor={props.label} className={props.classname}>
            {props.label}
          </label>
        </div>
      )}

      {checked !== "true" && (
        <div className="toggle">
          <>
            <label className="switch">
              <input
                type="checkbox"
                // id={props.id}
                // name={props.name}
                {...field}
                {...props}
              />

              <span className="slider round"></span>
            </label>
            <label htmlFor={props.label} className={props.classname}>
              {" "}
              {props.label}
            </label>
          </>
        </div>
      )}
    </>
  );
};

export default ToggleFormik;
