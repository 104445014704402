import { FaRegCircleCheck } from "react-icons/fa6";
import "../addMembership.scss";
import { Button, Checkbox, InputWithoutFormik } from "../../../components";
import { useState } from "react";
import Switch from "../../../components/formUiElements/Switch";
import ModalTermsAndCondition from "../../../pages/member/AddMember/elements/modals/ ModalTermsAndCondition";
import ModalPrivacyPolicy from "../../../pages/member/AddMember/elements/modals/ModalPrivacyPolicy";
import PreExerciseModal from "../../member/AddMember/elements/modals/PreExerciseModal";
const HealthQuestionaires = (props) => {
  const {
    listHealthQuestion,
    setListHealthQuestion,
    isHaveInjury,
    setIsHaveInjury,
    confirmBoxes,
    setConfirmBoxes,
  } = props;

  const [modalName, setModalName] = useState("");

  const missMatchQuestionAnswerMessage = (
    <div className="tooltip">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        style={{
          color: "red",
        }}
      >
        <path
          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 8V13"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.9941 16H12.0031"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>{" "}
      <span className="tooltiptext">
        You must seek medical guidance/advice before proceeding.
      </span>
    </div>
  );
  const RenderHealthQuestionAndMedicalDeclaration = (type) => (
    <>
      {listHealthQuestion
        .filter((i) => i.sectionLabel.toLowerCase() === type)
        .filter((i) => i.answerType === "NUMBER")
        .map((i, index) => {
          return (
            <>
              <div key={index} className="medical-declare">
                <p>
                  {i.mandatory &&
                    ((i.expectedAnswer !== "" &&
                      i.userValue !== i.expectedAnswer) ||
                      i.userValue === "") &&
                    missMatchQuestionAnswerMessage}
                  {i.question}
                </p>
                <Switch
                  value={i.userValue}
                  onChange={(value) => {
                    const data = [...listHealthQuestion].map((item) => {
                      return {
                        ...item,
                        userValue:
                          item.id === i.id
                            ? value === true
                              ? "yes"
                              : value === false
                              ? "no"
                              : item.userValue
                            : item.userValue,
                      };
                    });
                    setListHealthQuestion([...data]);
                  }}
                />
              </div>
            </>
          );
        })}
      {listHealthQuestion
        .filter((i) => i.sectionLabel.toLowerCase() === type)
        .filter((i) => i.answerType === "STRING")
        .map((i, index) => {
          return (
            <div key={index} className="medical-declare stringInput">
              <InputWithoutFormik
                type="text"
                label={i.question}
                value={i.userValue}
                onChange={(e) => {
                  const data = [...listHealthQuestion].map((item) => {
                    return {
                      ...item,
                      userValue:
                        item.id === i.id ? e.target.value : item.userValue,
                    };
                  });
                  setListHealthQuestion([...data]);
                }}
                placeholder={i.question}
              />
              {i.mandatory &&
                ((i.expectedAnswer !== "" &&
                  i.userValue !== i.expectedAnswer) ||
                  i.userValue === "") &&
                missMatchQuestionAnswerMessage}
            </div>
          );
        })}
    </>
  );
  return (
    <>
      {modalName === "pre_exercise" && (
        <PreExerciseModal
          listQuestion={listHealthQuestion}
          onClose={() => setModalName("")}
        />
      )}
      {modalName === "terms_and_condition" && (
        <ModalTermsAndCondition
          onClose={() => setModalName("")}
        ></ModalTermsAndCondition>
      )}
      {modalName === "privacy_policy" && (
        <ModalPrivacyPolicy
          onClose={() => setModalName("")}
        ></ModalPrivacyPolicy>
      )}
      <div className="user-details-form">
        <p>HEALTH CHECK</p>
        <div className="health-check">
          <p>Do you have any injuries, disabilities or current conditions? *</p>
          <div className="yesNOcontainer">
            <Button
              name="YES"
              btntype="button"
              className={`btn  btn-small ${
                isHaveInjury ? "btn-primary" : "btn-transparent"
              } fs-12`}
              click={() => setIsHaveInjury(true)}
            ></Button>{" "}
            <Button
              name="NO"
              btntype="button"
              className={`btn  btn-small ${
                !isHaveInjury ? "btn-primary" : "btn-transparent"
              } fs-12`}
              click={() => setIsHaveInjury(false)}
            ></Button>
          </div>
        </div>
        {isHaveInjury && (
          <div>
            <div className="relevant-box">
              <h3 className="term-header">
                PLEASE SELECT THE RELEVANT BOX BELOW*
              </h3>
              <p className="note">
                Note you must select one option, in order to proceed with this
                gym membership.
              </p>
            </div>
            <p className="healthQuestion-section-title">HEALTH CHECK</p>
            <div className="medical-declare-container">
              {RenderHealthQuestionAndMedicalDeclaration("health check")}
            </div>
            <p className="healthQuestion-section-title">MEDICAL DECLARATION</p>
            <div className="medical-declare-container">
              {RenderHealthQuestionAndMedicalDeclaration("medical declaration")}
            </div>
          </div>
        )}

        <div className="term-container">
          <h3 className="term-header">TERMS AND CONDITION*</h3>

          <div className="term-content">
            <p>
              I agree to the{" "}
              <span
                onClick={() => {
                  setModalName("terms_and_condition");
                }}
              >
                terms & conditions
              </span>{" "}
              and the{" "}
              <span
                onClick={() => {
                  setModalName("privacy_policy");
                }}
              >
                privacy policy
              </span>{" "}
              attached to my membership.*
            </p>
            <Checkbox
              checked={confirmBoxes.terms}
              onChange={(e) => {
                setConfirmBoxes({
                  ...confirmBoxes,
                  terms: e.target.checked,
                });
              }}
            />
          </div>
          <div className="term-content">
            <p>
              I confirm I meet the requirements from the{" "}
              <span
                className="exercise-check-list"
                onClick={() => {
                  setModalName("pre_exercise");
                }}
              >
                pre-exercise checklist
              </span>
            </p>
            <Checkbox
              checked={confirmBoxes.pre_exercise}
              onChange={(e) => {
                setConfirmBoxes({
                  ...confirmBoxes,
                  pre_exercise: e.target.checked,
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HealthQuestionaires;
