import { PieChart, Pie, Cell, Sector} from "recharts";

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
  } = props;

  return (
    <g>
      <text fontSize={17} fontFamily={"Bebas Neue"} fontWeight="200" x={cx} y={cy-10} dy={8} textAnchor="middle" fill={"#fff"}>
      {`${(percent * 100).toFixed(0)}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const RadialChart = ({value}) => {
  const rate = Number(value)
  const data = [
    { name: "success", value: rate },
    { name: "total - success", value: 100 - rate}
  ]
  return (
    <PieChart width={100} height={60}>
      <defs>
        <linearGradient id="color-rate" x1="1" y1="-0.3" x2="-0.2" y2="0.6">
          <stop offset="60%" stopColor="#2533D5" stopOpacity={0.8}/>
          <stop offset="100%" stopColor="#010258" stopOpacity={0.6}/>
        </linearGradient>
      </defs>
      <Pie
        data={data}
        activeIndex={0}
        activeShape={renderActiveShape}
        startAngle={180}
        endAngle={0}
        cy={40}
        cx={40}
        innerRadius={24}
        outerRadius={40}
        dataKey="value"
      >
        {data.map((entry, index) => {
          if (index  === 0) {
            return <Cell key={`cell-${index}`} stroke={""} fill="url(#color-rate)"/>
          }
          return <Cell key={`cell-${index}`} stroke={""} fill={"#1E1E1E"} />
        })}
      </Pie>
    </PieChart>
  );
}
export default RadialChart;