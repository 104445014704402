import "./addstaff.scss";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import {
  gender,
  state,
  staffTypes,
  country,
} from "../../constants/commonConstant";
import { getRoleOptionsByRole } from "../../utils/getRoleOptionsByRole";
import { staffSchema } from "../../validations/Validation";
import { useMemberContext } from "../../contexts/MemberContext";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { ADD_STAFF, UPDATE_MEMBER } from "../../gqloperations/mutations";
import {
  InternationalPhoneInput,
  Header,
  Select,
  Button,
  Input,
} from "../../components";
import { useHandleError } from '../../hooks/useHandleError';

const AddStaff = () => {
  const { locationAccess, loggedUser } = useAuthContext();
  const roleOptions = getRoleOptionsByRole(loggedUser.getMember.role);
  const {
    sidebarBrandId,
    sidebarLocationName,
    sidebarLocationId,
    encodeAndUploadImageToAws: uploadImageToAws,
  } = useMemberContext();
  const navigate = useNavigate();
  const fileInputRef = useRef();
  // const [key, setKey] = useState(false);
  const [file, setFile] = useState();
  const [clientSideImg, setClientSideImg] = useState();
  const [homeClubError, setHomeClubError] = useState("");
  const [locationItems, setLocationItems] = useState([]);
  const handleError = useHandleError();
  const [datalistInputValue, setDatalistInputValue] = useState(
    sidebarLocationId === 0 ? "" : sidebarLocationName
  );
  const [location, setLocation] = useState(
    sidebarLocationId === 0
      ? { locationId: "", locationName: "" }
      : {
          locationId: sidebarLocationId,
          locationName: sidebarLocationName,
        }
  );
  const [selectedBrandId, seSelectedBrandId] = useState(sidebarBrandId);

  const [addStaff] = useMutation(ADD_STAFF);
  const [updateStaff] = useMutation(UPDATE_MEMBER);
  useEffect(() => {
    const selectedBrand = locationAccess.brands.find(
      (brand) => brand.brandId === selectedBrandId
    );
    setLocationItems(selectedBrand?.locations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrandId]);
  useEffect(() => {
    if (selectedBrandId !== sidebarBrandId) {
      setLocation({ locationId: "", locationName: "" });
      setDatalistInputValue("");
    } else {
      setLocation(
        sidebarLocationId === 0
          ? { locationId: "", locationName: "" }
          : {
              locationId: sidebarLocationId,
              locationName: sidebarLocationName,
            }
      );
      setDatalistInputValue(sidebarLocationId === 0 ? "" : sidebarLocationName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrandId]);
  useEffect(() => {
    setHomeClubError("");
    setLocation({ locationId: "", locationName: "" });
    const selectedLocation = locationItems.find(
      (item) =>
        item.locationName.toLowerCase() === datalistInputValue?.toLowerCase()
    );
    if (selectedLocation) {
      setLocation({
        ...location,
        locationId: selectedLocation.locationId,
        locationName: selectedLocation.locationName,
      });
      setDatalistInputValue(selectedLocation.locationName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datalistInputValue]);

  const handleChange = (e) => {
    setDatalistInputValue(e.target.value);
  };
  const handleCancel = () => {
    navigate(-1);
  };

  // const updateStaffImage = async (staffId, OnSubmitProps) => {
  //   const extension = file.name.split(".");
  //   if (file.size > 5_242_880) {
  //     //  This needs to be here due to it has two mutations
  //     handleError("Image needs to be less than 5MB");
  //   } else {
  //     const extensionName = extension[extension.length - 1];

  //     const updatedKey = `/memberImages/${
  //       staffId.addStaff
  //     }/profile/${uuidv4()}.${extensionName}`;

  //     const uploadedFile = await uploadImageToAws(file, updatedKey);

  //     const imageUrl = uploadedFile?.key;

  //     updateStaff({
  //       variables: {
  //         input: {
  //           memberId: staffId.addStaff,
  //           imageUrl,
  //         },
  //       },
  //       onCompleted: () => {
  //         console.log("imageUrl has been updated successfully");
  //         OnSubmitProps.setSubmitting(false);
  //       },
  //       onError: (err) => {
  //         handleError(`${err}`);
  //         OnSubmitProps.setSubmitting(false);
  //       },
  //     });
  //   }

  //   setTimeout(() => {
  //     navigate(-1);
  //   }, 2000);
  // };

  const onSubmit = async (values, OnSubmitProps) => {
    const basicInput = {
      // imageUrl: await uploadImage(),
      givenName: values.givenName.toUpperCase(),
      middleName: values.middleName.toUpperCase(),
      surname: values.surname.toUpperCase(),
      dob: values.dob,
      gender: values.gender,
      mobileNumber: values.mobileNumber,
      email: values.email.toUpperCase(),
      address: values.address.toUpperCase(),
      suburb: values.suburb.toUpperCase(),
      state: values.state.toUpperCase(),
      postCode: values.postCode,
      country: values.country.toUpperCase(),
      staffKind: values.user,
      role: values.role.toUpperCase(),
      joinDate: values.joinDate,
      homeLocationId: location.locationId,
      brandId: selectedBrandId,
    };
    if (file) {
      const extension = file.name.split(".");
      if (file.size > 5_242_880) {
        //  This needs to be here due to it has two mutations
        handleError("Image needs to be less than 5MB");
      } else {
        const extensionName = extension[extension.length - 1];
        const date = format(new Date(), "yyyyMMdd");
        const updatedKey = `memberImages/temp/${date}/${uuidv4()}.${extensionName}`;

        const uploadedFile = await uploadImageToAws(file, updatedKey);

        const imageUrl = uploadedFile?.key;
        basicInput.imageUrl = "/" + imageUrl;
      }
    }

    addStaff({
      variables: {
        input: values.secondaryEmail
          ? {
              ...basicInput,
              secondaryEmail: values.secondaryEmail.toUpperCase(),
            }
          : basicInput,
      },
      onCompleted: (data) => {
        toast.success("Staff Added Successfully");
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      },
      onError: (err) => {
        handleError(`${err}`);
        OnSubmitProps.setSubmitting(false);
      },
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file.size > 5_242_880) {
      handleError("Image needs to be less than 5MB");
    } else {
      setFile(file);

      const clientSideImgUrl = URL.createObjectURL(file);
      setClientSideImg(clientSideImgUrl);
    }

    // const extension = file.name.split(".")[1];
    // const name = file.name.split(".")[0];
    // This path is different and its because the staffId is only given after the first mutation
    // setKey(`memberImages/${uuidv4()}${name}.${extension}`);
  };
  const handleImgUploadBtn = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        title: "",
        givenName: "",
        middleName: "",
        surname: "",
        dob: "",
        gender: "",
        mobileNumber: "",
        email: "",
        secondaryEmail: "",
        address: "",
        suburb: "",
        state: "",
        postCode: "",
        country: "Australia",
        user: "",
        role: "",
        joinDate: "",
      }}
      validationSchema={staffSchema}
      onSubmit={(values, OnSubmitProps) => {
        if (!location.locationId) {
          OnSubmitProps.setSubmitting(false);
          setHomeClubError("Please select a location!");
          return;
        }
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <div className="add-staff">
            <Header pageTitle="Add Staff" />
            <div className="add-staff-container mt-32">
              <Form className="add-staff-container">
                <div className="first-card pd-18 flex-wrap">
                  <input
                    type="file"
                    onChange={handleImageUpload}
                    accept="image/*"
                    ref={fileInputRef}
                    name="imageUrl"
                    className="staff-img-input pb-12"
                  />
                  <div className="profile-img-container">
                    <img
                      src={
                        clientSideImg
                          ? clientSideImg
                          : "https://via.placeholder.com/150"
                      }
                      alt="class"
                    />
                    <button className="btn" onClick={handleImgUploadBtn}>
                      Upload Picture
                    </button>
                  </div>
                  <div className="personal-information-container ">
                    <div className="title-bar pb-18">
                      <h3 className="fs-14 ">Personal Information</h3>
                    </div>
                    <div className="details-container staff-gen-info">
                      <div className="detail first-name">
                        <Input
                          type="text"
                          label="First Name * "
                          name="givenName"
                          autoComplete="off"
                        />
                      </div>
                      <div className="detail mid-name">
                        <Input
                          type="text"
                          label="Middle Name  "
                          name="middleName"
                          autoComplete="off"
                        />
                      </div>
                      <div className="detail surname">
                        <Input
                          type="text"
                          label="Last Name * "
                          name="surname"
                          id="surname"
                          autoComplete="off"
                        />
                      </div>
                      <div className="detail dob">
                        <Input
                          type="date"
                          label="D.O.B *"
                          name="dob"
                          autoComplete="off"
                          max={new Date().toISOString().split("T")[0]}
                        />
                      </div>
                      <div className="detail gender">
                        <Select
                          label="Gender *"
                          optionvalues={gender}
                          selecttype="array"
                          name="gender"
                          autoComplete="off"
                          className="fs-12"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="contact-information-card pd-18">
                  <div className="title-bar pb-18">
                    <h3 className="fs-14 uppercase">Contact Information</h3>
                  </div>
                  <div className="details-container contact-details">
                    <div className="detail mobile">
                      <InternationalPhoneInput
                        title="Mobile Number *"
                        name="mobileNumber"
                        editable={true}
                      />
                    </div>
                    <div className="detail email">
                      <Input
                        type="text"
                        label="Email * "
                        name="email"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail sec-email">
                      <Input
                        type="text"
                        label="Secondary Email  "
                        name="secondaryEmail"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail address">
                      <Input
                        type="text"
                        label="Address"
                        name="address"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail suburb">
                      <Input
                        type="text"
                        label="Suburb *"
                        name="suburb"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail state">
                      <Select
                        label="State *"
                        optionvalues={state}
                        selecttype="array"
                        name="state"
                        autoComplete="off"
                        className="fs-12"
                      />
                    </div>
                    <div className="detail post">
                      <Input
                        type="text"
                        label="Post Code *"
                        name="postCode"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail country">
                      <Select
                        label="Country *"
                        optionvalues={country}
                        selecttype="array"
                        name="country"
                        id="add-staff-country"
                        autoComplete="off"
                        className="fs-12 select-element-value"
                      />
                    </div>
                  </div>
                </div>
                <div className="staff-access-card pd-18">
                  <div className="title-bar pb-18">
                    <h3 className="fs-14 uppercase">Staff Access</h3>
                  </div>
                  <div className="details-container staff-access">
                    <div className="user">
                      <Select
                        label="User Type * "
                        optionvalues={staffTypes}
                        selecttype="array"
                        name="user"
                        autoComplete="off"
                        className="fs-12"
                      />
                    </div>
                    <div className="role">
                      <Select
                        label="Role *"
                        optionvalues={roleOptions}
                        selecttype="array"
                        name="role"
                        autoComplete="off"
                        className="fs-12"
                      />
                    </div>
                    <div className="joining-date">
                      <Input
                        type="date"
                        label="Joining Date *"
                        name="joinDate"
                        autoComplete="off"
                        max={new Date().toISOString().split("T")[0]}
                      />
                    </div>
                    <div className="brand">
                      <h3 className="fs-12 text-gray">Brand *</h3>
                      <select
                        value={selectedBrandId}
                        onChange={(e) => seSelectedBrandId(e.target.value)}
                        className="select-element"
                      >
                        {locationAccess.brands.map((brand) => {
                          return (
                            <option key={brand.brandId} value={brand.brandId}>
                              {brand.brandName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="home">
                      <div className="select-element location-value loc-select ">
                        <h3 className="fs-12 text-gray">Home Club *</h3>
                        <input
                          type="text"
                          list="locations-add-staff"
                          placeholder="Search a Location"
                          onChange={(e) => handleChange(e)}
                          value={datalistInputValue}
                          autoComplete="off"
                          className="fs-12"
                        />
                        {locationItems && (
                          <datalist id="locations-add-staff">
                            {locationItems.map((option) => {
                              return (
                                <option
                                  key={option.locationId}
                                  value={option.locationName}
                                />
                              );
                            })}
                          </datalist>
                        )}
                        {homeClubError && (
                          <p className="error fs-12">{homeClubError}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="add-btn-container right-btn pb-32">
                  <Button
                    loading={formik.isSubmitting}
                    name={"Save"}
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-confirm fs-12"
                    disabled={formik.isSubmitting ? true : false}
                  />
                  <Button
                    name="Cancel"
                    btntype="button"
                    className="btn btn-deny  fs-12"
                    click={handleCancel}
                  />
                </div>
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddStaff;
