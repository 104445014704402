import React, { useEffect, useState } from "react";
import { useField } from "formik";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./internationalPhoneInput.scss";

const InternationalPhoneInput = (props) => {
  const [field, meta, helpers] = useField(props);
  const [displayedValue, setDisplayedValue] = useState();

  const handleChange = (value, country) => {
    helpers.setTouched(true);
    const newValue = value?.startsWith(country.dialCode + "0")
      ? country.dialCode + value.slice(country.dialCode.length + 1)
      : value;
    helpers.setValue(`+${newValue}`);
  };

  const handleBlur = (value, country) => {
    if (typeof value === "string" && value.startsWith(country.dialCode + "0")) {
      const newValue =
        country.dialCode + value.slice(country.dialCode.length + 1);
      setDisplayedValue(newValue);
    }
  };

  useEffect(() => {
    setDisplayedValue(field.value);
  }, [field.value]);

  return (
    <div className="international-phone-input-container">
      <p className="fs-12 text-gray">{props.title}</p>
      <ReactPhoneInput
        {...props}
        {...field}
        inputProps={{
          name: `${props.name}`,
        }}
        autoFormat={false}
        value={displayedValue}
        country="au"
        onChange={handleChange}
        onBlur={handleBlur}
        enableSearch
        disableSearchIcon={true}
        disabled={!props.editable}
      />
      {meta.touched && meta.error ? (
        <div className="error fs-12">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default InternationalPhoneInput;