import { React, useState } from "react";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { AiOutlineSearch } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { toast } from "react-toastify";
import {
  GET_GROUP_BY_STAFFID,
  LIST_GROUPS,
  GET_MEMBER_BY_ID,
} from "../../gqloperations/queries";
import {
  ADD_STAFF_TO_GROUP,
  DELETE_GROUP_STAFF,
} from "../../gqloperations/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {StatusModal, Modal, Checkbox, Header, Table } from "../../components";
import { useHandleError } from '../../hooks/useHandleError';

const StaffGroup = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [nextToken, setNextToken] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [rowSelection, setRowSelection] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [addGroupAccessLoading, setAddGroupAccessLoading] = useState(false);
  const [checkedRows, setCheckedRows] = useState({ isAllRowsChecked: false });
  const [groupAccessToDelete, setGroupAccessToDelete] = useState("");
  const [addStaffGroup] = useMutation(ADD_STAFF_TO_GROUP);
  const [removeGroupAccess] = useMutation(DELETE_GROUP_STAFF);
  const handleError = useHandleError();
  const {
    loading: getLocationsLoading,
    error: getLocationsError,
    data: getLocationsData,
    refetch: listGroupRefetch,
  } = useQuery(LIST_GROUPS, {
    onCompleted: (data) => {
      setTableData(data.listGroups.items);
    },
    onError: (error) => {
      handleError(`${error}`);
    },
  });
  const {
    loading: getStaffLoading,
    error: getStaffError,
    data: getStaffData,
  } = useQuery(GET_MEMBER_BY_ID, {
    variables: {
      memberId: id,
    },
  });
  let staffFullName = "";
  if (getStaffData) {
    const { givenName, middleName, surname } = getStaffData.getMember;
    staffFullName = `${givenName ?? ""} ${middleName ?? ""} ${surname ?? ""}`;
  }
  const {
    loading: getGroupLoading,
    error: getGroupError,
    data: getGroupData,
    refetch: getStaffGroupAccessRefetch,
  } = useQuery(GET_GROUP_BY_STAFFID, {
    variables: {
      staffId: id,
    },
    onError: (error) => {
      handleError(`${error}`);
    },
  });
  const handleCancel = () => {
    navigate(-1);
  };
  const handleCompleted = () => {
    toast.success("Updated successfully");
    setRowSelection([]);
    setAddGroupAccessLoading(false);
    getStaffGroupAccessRefetch();
    listGroupRefetch();
  };
  const handleClick = async (groupId) => {
    setAddGroupAccessLoading(true);
    const groupIds = groupId.map((item) => item.original.id);
    const appliedGroupId = getGroupData?.getGroupStaffByStaffId?.items.map(
      (group) => {
        return group.groupId;
      }
    );
    const filteredGroupId = groupIds.filter((group) => {
      return !appliedGroupId.includes(group);
    });
    try {
      const addResults = await addStaffGroup({
        variables: {
          groupIds: filteredGroupId,
          operation: "ADD_STAFF_TO_GROUP",
          staffId: id,
        },
      });
      addResults && handleCompleted();
      //   if (filteredGroupId.length !== 0) {
      //   const addResults = await Promise.all(
      //     filteredGroupId.map((groupId) =>

      //     )
      //   );
      // }
      // const addResults =
      //   filteredGroupId.length !== 0
      //     ? await Promise.all(
      //         filteredGroupId.map((groupId) =>
      //           addStaffGroup({
      //             variables: {
      //               groupId,
      //               operation: "ADD_STAFF_TO_GROUP",
      //               staffId: id,
      //             },
      //           })
      //         )
      //       )
      //     : true;
      // console.log(addResults);
    } catch (e) {
      handleError(`${e}`);
    }
  };

  const handleNextToken = () => {
    if (getLocationsData?.listLocations?.nextToken) {
      setNextToken(getLocationsData?.listLocations?.nextToken);
    }
  };
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  const typeColumnFilter = (row, columnId, filterValues) => {
    const columnVal = row.getValue(columnId);
    return filterValues.some((cur) => {
      return columnVal === cur;
    });
  };
  const handleRemoveGroup = () => {
    removeGroupAccess({
      variables: {
        input: {
          id: groupAccessToDelete,
        },
      },
      onCompleted: () => {
        setGroupAccessToDelete("");
        toast.success("Remove group access successfully");
        getStaffGroupAccessRefetch();
        listGroupRefetch();
        setRowSelection([]);
      },
      onError: (error) => {
        handleError(`${error}`);
      },
    });
  };
  const columns = [
    {
      id: "select",
      header: ({ table }) => {
        return (
          <div className="head-checkbox-container">
            <div className="px-1">
              {checkedRows?.isAllRowsChecked ? (
                <input type="checkbox" checked disabled />
              ) : (
                <Checkbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                  }}
                />
              )}
            </div>
            <div>SELECT ALL</div>
          </div>
        );
      },
      cell: ({ row }) => (
        <div className="px-1">
          {checkedRows?.hasOwnProperty(row.original.id) ? (
            <input type="checkbox" checked disabled />
          ) : (
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          )}
        </div>
      ),
    },
    {
      accessorFn: (row) => row.name,
      header: "GROUP NAME",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "groupName",
    },
    {
      id: "row-actions",
      cell: (props) => {
        const groupStaffs = props.row.original.groupStaff.items;
        if (groupStaffs.find((staff) => staff.staffId === id)) {
          return (
            <span className="button-td">
              <MdDeleteOutline
                className="delete-icon"
                onClick={() => {
                  setGroupAccessToDelete(
                    groupStaffs.find((staff) => staff.staffId === id).id
                  );
                }}
              />
            </span>
          );
        }
        return "";
      },
    },
  ];

  useEffect(() => {
    const numOfRows = tableData.length ?? 0;
    if (numOfRows <= 0) {
      setCheckedRows({ isAllRowsChecked: true });
      return;
    }
    const checkedRowsObj = tableData?.reduce((acc, cur) => {
      const doesGroupMatch = getGroupData?.getGroupStaffByStaffId?.items.find(
        (group) => group.groupId === cur.id
      );
      if (doesGroupMatch) {
        acc[cur.id] = true;
      }
      return acc;
    }, {});

    const numOfCheckedRows = Object.keys(checkedRowsObj)?.length ?? 0;
    const isAllRowsChecked = numOfCheckedRows === numOfRows;

    checkedRowsObj.isAllRowsChecked = isAllRowsChecked;
    setCheckedRows(checkedRowsObj);
  }, [tableData, getGroupData]);

  if (getGroupLoading || getLocationsLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (getGroupError) {
    return (
      <div>
        <div>Error: Problem getting groups</div>
      </div>
    );
  }
  if (getLocationsError) {
    return (
      <div>
        <div>Error: Problem getting locations</div>
      </div>
    );
  }
  return (
    <>
      <div className="apply-group-to-loc">
        <Header pageTitle="Add / Remove Group" />
        <div className="apply-group-to-loc-content">
          <div className="actions-row mt-32 flex-wrap">
            {getStaffLoading && <BeatLoader color="white" />}
            {getStaffError && (
              <p className="uppercase group-name fs-12">
                Error: Problem getting staff name
              </p>
            )}
            {getStaffData && (
              <p className="uppercase group-name fs-12">{staffFullName}</p>
            )}
            <div className="search-container mt-12">
              <form onSubmit={onSearchBarSubmit}>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="search"
                      id="search"
                      value={searchBarInputVal}
                      onChange={handleChange}
                      placeholder="Search by group name"
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {tableData && (
            <div className="table landing-table staff-add-remove-group-table mt-12">
              <Table
                data={tableData}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columns={columns}
                rowsPerPage={10}
                handleNextToken={handleNextToken}
                nextToken={nextToken}
                filterFns={{ typeFilter: typeColumnFilter }}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleCancel={handleCancel}
                handleClick={handleClick}
                showTwoBtns={true}
                sorting={sorting}
                setSorting={setSorting}
                btnName="Add"
                isBtnLoading={addGroupAccessLoading}
                isBtnDisabled={
                  checkedRows.isAllRowsChecked ||
                  Object.keys(rowSelection).length === 0
                }
              />
            </div>
          )}
        </div>
        {groupAccessToDelete && (
          <Modal>
            <StatusModal
              modalHeaderTxt="Remove Group"
              modalParaText="Are you sure you want to remove this group"
              handleStatusClick={handleRemoveGroup}
              modalClose={() => setGroupAccessToDelete("")}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default StaffGroup;
