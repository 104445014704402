import React from "react";
import { Formik, Form } from "formik";
import Button from "../formUiElements/Button";
import Input from "../formUiElements/input/Input";

const RefundModal = (props) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        // amount: props.refundAmount.toFixed(2),
        reason: "",
      }}
      onSubmit={(values, OnSubmitProps) => {
        props.handleRefundSubmit(values, OnSubmitProps);
      }}
      validate={(values, props) => {
        const { amount, reason } = values;

        let errors = {};

        // if (amount.length === 0) {
        //   const errorText = "Please put amount";
        //   errors.amount = errorText;
        // }

        // if (amount < 0) {
        //   const errorText = "Amount needs to be greater than 0";
        //   errors.amount = errorText;
        // }
        if (!reason) {
          const errorText = "Please choose a reason";
          errors.reason = errorText;
        }

        return errors;
      }}
    >
      {(formik) => {
        return (
          <Form className="status-modal edit-amount pd-32">
            <h3 className="fs-35 uppercase">
              {props.requestRefund ? "Request a refund" : "Refund"}
            </h3>
            <p className="uppercase fs-12">
              {props.requestRefund
                ? "are you sure you want to request a refund?"
                : "are you sure you want to refund?"}
            </p>
            <div className="content-container">
              {/* <div>
                <Input
                  type="text"
                  label="Amount"
                  name="amount"
                  id="amount"
                  className="amount-input"
                  value={`$${formik.values.amount}`}
                  disabled
                />
              </div> */}
              <div className="reason-container">
                <label className="fs-12" htmlFor="reason">
                  Reason *
                </label>
                <textarea
                  name="reason"
                  id="reason"
                  onChange={formik.handleChange}
                  value={formik.values.reason}
                ></textarea>
                {formik.errors.reason && formik.touched.reason && (
                  <div className="fs-12 error">{formik.errors.reason}</div>
                )}
              </div>
            </div>
            <div className="btn-group">
              <Button
                loading={formik.isSubmitting}
                name="YES"
                btntype="submit"
                btnname="submit"
                className="btn btn-confirm btn-small"
                disabled={formik.isSubmitting ? true : false}
              />
              <Button
                name="Cancel"
                btntype="submit"
                btnname="submit"
                className="btn btn-deny btn-small"
                click={props.cancelFn}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RefundModal;
