import "./membership.scss";
import { React, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import isAfter from "date-fns/isAfter";
import {
  getPaymentFrequencyString,
  getContractLengthString,
} from "../../utils/getDisplayString";
import {
  LIST_MEMBERSHIPS,
  GET_MEMBERSHIP_TYPE_BY_ID,
} from "../../gqloperations/queries";
import { UPDATE_MEMBERSHIP } from "../../gqloperations/mutations";
import { useMemberContext } from "../../contexts/MemberContext";
import { AiOutlineSearch } from "react-icons/ai";
import actions from "../../assets/images/icons/action_menu.png";
import { brandTypes } from "../../constants/commonConstant";
import getTypeIcon from "../../utils/getTypeIcon";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import Voucher from "./voucher/Voucher";
import { useHandleError } from "../../hooks/useHandleError";
import {
  Error,
  Modal,
  Loading,
  Toggle,
  Table,
  Button,
  Header,
  StatusModal,
  ExportGroup,
} from "../../components";

const Membership = (props) => {
  const { permissions, loggedUser } = useAuthContext();
  const { sidebarBrandId, createLog, setIndex, index } = useMemberContext();
  const navigate = useNavigate();
  const [showActions, setShowActions] = useState({});
  const [tableData, setTableData] = useState();
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [isPaginating, setIsPaginating] = useState(false);
  const [componentName, setComponentName] = useState("");
  const [curRow, setCurRow] = useState({});
  const handleError = useHandleError();

  const [updateMembership] = useMutation(UPDATE_MEMBERSHIP);
  const ref = useRef();
  const {
    data: listMembershipData,
    loading: listMembershipLoading,
    error: listMembershipError,
    refetch,
  } = useQuery(LIST_MEMBERSHIPS, {
    fetchPolicy: "no-cache",
    variables: {
      limit: 1000,
      nextToken: null,
      filter: {
        and: [
          { isDeleted: { ne: true } },
          { brandId: { eq: `${sidebarBrandId}` } },
        ],
      },
    },
  });
  useEffect(() => {
    if (!canSeeMembershipModule) setIndex(1);
    createLog("CLICKED Membership tab on the sidebar", "sidebar");
    return () => {
      setIndex(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      setShowActions({});
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [ref]);

  useEffect(() => {
    if (listMembershipData) {
      const { items, nextToken } = listMembershipData?.listMemberships;
      if (tableData && isPaginating) {
        const sortedCombinedItems = sortByCreatedAtDesc([
          ...tableData,
          ...items,
        ]);
        setTableData(sortedCombinedItems);
      } else {
        const sortedItems = sortByCreatedAtDesc([...items]);
        setTableData(sortedItems);
      }

      setIsPaginating(false);
      setNextToken(nextToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listMembershipData]);
  const handleRowClick = (baseUrl, id) => {
    navigate(`${baseUrl}${id}`);
  };
  const modalClose = () => {
    setComponentName("");
    setCurRow({});
  };
  const handleComponent = (componentName, rowObj) => {
    setComponentName(componentName);
    setCurRow(rowObj);
  };

  const handleApplyMembershipTo = (id) => {
    navigate(`/dashboard/membership/applymembershipto/${id}`);
  };

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const handleDeleteClick = async (rowObj) => {
    let values = {
      id: rowObj.membershipId,
      isDeleted: true,
      isActive: false,
    };
    try {
      await updateMembership({
        variables: {
          input: values,
        },
        onCompleted: () => {
          refetch();
          toast.success("Membership has been deleted successfully");
          modalClose();
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };

  const handleStatusClick = async (rowObj) => {
    let values = {
      id: rowObj.id,
      isActive: !rowObj.isActive,
    };

    try {
      await updateMembership({
        variables: {
          input: values,
        },
        onCompleted: () => {
          refetch();
          toast.success("Updated successfully");
          modalClose();
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };

  const handleLiveClick = async (rowObj) => {
    let values = {
      id: rowObj.id,
      isLiveOnWebsite: !rowObj.isLiveOnWebsite,
    };

    try {
      await updateMembership({
        variables: {
          input: values,
        },
        onCompleted: () => {
          refetch();
          toast.success("Updated successfully");
          modalClose();
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };

  const handlePeakClick = async (rowObj) => {
    const membershipId = rowObj.id;
    let values = {
      id: membershipId,
      isPeak: !rowObj.isPeak,
    };

    try {
      await updateMembership({
        variables: {
          input: values,
        },
        onCompleted: () => {
          refetch();
          toast.success("Updated successfully");
          modalClose();
        },
        refetchQueries: [
          {
            query: GET_MEMBERSHIP_TYPE_BY_ID,
            variables: {
              id: `${membershipId}`,
            },
          },
        ],
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };
  const handleNextToken = () => {
    if (listMembershipData?.listMemberships?.nextToken) {
      setIsPaginating(true);
      refetch({ nextToken: listMembershipData.listMemberships.nextToken });
    }
  };

  const sortByCreatedAtDesc = (arr) => {
    return arr.sort((a, b) => {
      const result = isAfter(new Date(a.createdAt), new Date(b.createdAt));
      return result ? -1 : 1;
    });
  };

  const columns = [
    {
      accessorKey: "brandDetails",
      header: "BRAND",
      cell: (info) => {
        const brandName = info.getValue() ? info?.getValue()?.name : "";
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "branddetails",
    },
    {
      accessorKey: "membershipName",
      header: "MEMBERSHIP NAME",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "membershipName",
    },
    {
      accessorFn: (row) => {
        return !row.recurring
          ? "PIF"
          : getPaymentFrequencyString(row.paymentFrequency);
      },
      header: "PAYMENT FREQUENCY",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "paymentFrequency",
    },
    {
      accessorKey: "contractLength",
      header: "CONTRACT LENGTH",
      cell: (info) =>
        info?.getValue() ? getContractLengthString(info.getValue()) : "",
      enableSorting: true,
      enableGlobalFilter: false,
      id: "contractLength",
    },
    {
      accessorKey: "costPrice",
      header: "COST",
      cell: (info) => `$${info?.getValue()}`,
      enableSorting: false,
      enableGlobalFilter: false,
      id: "costPrice",
    },

    {
      accessorKey: "isLiveOnWebsite",
      header: "LIVE",
      cell: (info) => {
        const row = info?.row?.original;
        return (
          <Toggle
            checked={row?.isLiveOnWebsite}
            onChange={() =>
              handleComponent("live-confirm", {
                id: row?.id,
                membershipName: row?.membershipName,
                isLiveOnWebsite: row?.isLiveOnWebsite,
              })
            }
          />
        );
      },
      enableSorting: true,
      enableGlobalFilter: false,
      sortingFn: "basic",
      id: "isLiveOnWebsite",
    },
    {
      accessorFn: (row) => {
        return (
          <Toggle
            checked={row?.isPeak}
            value={row?.isPeak}
            onChange={() =>
              handleComponent("peak-confirm", {
                id: row.id,
                membershipName: row.membershipName,
                isPeak: row?.isPeak,
              })
            }
          />
        );
      },
      header: "peak hours",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "isPeak",
    },

    {
      accessorKey: "isActive",
      header: "STATUS",
      cell: (info) => {
        const row = info?.row?.original;
        return (
          <Toggle
            checked={row?.isActive}
            value={row?.isActive}
            onChange={() =>
              handleComponent("status-confirm", {
                id: row?.id,
                membershipName: row?.membershipName,
                isActive: row?.isActive,
              })
            }
          />
        );
      },
      enableSorting: true,
      enableGlobalFilter: false,
      sortingFn: "basic",
      id: "active",
    },

    {
      id: "row-actions5",
      header: "ACTIONS",
      cell: (props) => {
        const { id } = props?.row?.original;

        const areActionsVisible = showActions.hasOwnProperty(id);
        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              onClick={(e) => {
                e.stopPropagation();
                setShowActions({
                  // Comment this out to allow more than one row open at a time
                  // ...showActions,
                  [id]: true,
                });
              }}
              alt=""
            />
            {areActionsVisible && (
              <span className="action-options-menu" ref={ref}>
                <ul className="membership-options">
                  {permissions.includes("CanApplyMembershipToLocation") &&
                    props?.row?.original.isActive && (
                      <li
                        onClick={() => {
                          handleApplyMembershipTo(id);
                        }}
                      >
                        <Button
                          name="apply to location"
                          btntype="submit"
                          btnname="submit"
                          className="btn btn-transparent btn-small"
                        />
                      </li>
                    )}
                  {permissions.includes("CanEditMembershipPrice") &&
                    props?.row?.original.isActive && (
                      <li
                        onClick={() => {
                          handleRowClick(
                            "/dashboard/membership/changemembershipprice/",
                            id
                          );
                        }}
                      >
                        <Button
                          name="change price"
                          btntype="submit"
                          btnname="submit"
                          className="btn btn-transparent btn-small"
                        />
                      </li>
                    )}
                  {permissions.includes("CanViewMembership") && (
                    <li
                      onClick={() => {
                        handleView(id);
                      }}
                    >
                      <Button
                        name="view"
                        btntype="submit"
                        btnname="submit"
                        className="btn btn-transparent btn-small"
                      />
                    </li>
                  )}

                  {permissions.includes("CanEditMembership") &&
                    props?.row?.original.isActive && (
                      <li
                        onClick={() => {
                          handleEdit(id);
                        }}
                      >
                        <Button
                          name="edit"
                          btntype="submit"
                          btnname="submit"
                          className="btn btn-transparent btn-small"
                        />
                      </li>
                    )}
                  {permissions.includes("CanDeleteMembership") && (
                    <li
                      onClick={() => {
                        handleComponent("delete-template", {
                          membershipId: id,
                        });
                      }}
                    >
                      <Button
                        name="delete"
                        btntype="submit"
                        btnname="submit"
                        className="btn btn-transparent btn-small"
                      />
                    </li>
                  )}
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const handleEdit = (membershipTypeId) => {
    navigate(`/dashboard/membership/editmembershiptype/${membershipTypeId}`);
  };
  const handleView = (membershipTypeId) => {
    navigate(`/dashboard/membership/viewmembershiptype/${membershipTypeId}`);
  };
  const canSeeMembershipModule =
    loggedUser.getMember.role === "L5" || loggedUser.getMember.role === "L4";

  if (listMembershipLoading) {
    return <Loading />;
  }

  if (listMembershipError) {
    return <Error>Problem in getting memberships</Error>;
  }

  return (
    <>
      <div className="membership-container">
        <Header pageTitle="Memberships" />
        <div className="tabs mt-32">
          <div className="tabs-list">
            {canSeeMembershipModule && (
              <div
                className={
                  index !== 0
                    ? "tab-head fs-14 back-mediumgray"
                    : "tab-head fs-14 back-persianblue"
                }
                onClick={() => {
                  setIndex(0);
                }}
              >
                MEMBERSHIP
              </div>
            )}

            <div
              className={
                index !== 1
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => {
                setIndex(1);
              }}
            >
              VOUCHERS
            </div>
          </div>
        </div>
        {index === 0 ? (
          <div className="tabContent">
            {!listMembershipLoading && !listMembershipError && tableData && (
              <>
                <div className=" actions-row mt-32">
                  <ExportGroup
                    data={tableData}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    columns={columns}
                    sorting={sorting}
                  />
                  <div className="left-elements ">
                    <div className="search-and-button-row">
                      <div className="search-container">
                        <form onSubmit={onSearchBarSubmit}>
                          <div className="search-bar">
                            <div className="search-bar-container">
                              <input
                                className="fs-10"
                                type="text"
                                name="search"
                                id="search"
                                value={searchBarInputVal}
                                onChange={handleChange}
                                placeholder="Membership name"
                              />
                              <button type="submit">
                                <AiOutlineSearch />
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    {permissions.includes("CanAddMembership") && (
                      <Link to="/dashboard/membership/addmembership">
                        <div className="add-membership-btn">
                          <Button
                            name="Add A Membership"
                            btntype="submit"
                            btnname="submit"
                            className="btn btn-primary add-membership-btn"
                            click={props.openmodalclick}
                          />
                        </div>
                      </Link>
                    )}
                  </div>
                </div>

                <div className="landing-table memberships">
                  <Table
                    onRowClick={(i) => {
                      if (!permissions.includes("CanViewMembership")) return;
                      const id = i.id;
                      handleView(id);
                      createLog(`Clicked ${i.membershipName}`);
                    }}
                    data={tableData}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    sorting={sorting}
                    setSorting={setSorting}
                    columns={columns}
                    rowsPerPage={10}
                    handleNextToken={handleNextToken}
                    nextToken={nextToken}
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="tabContent">
            <Voucher />
          </div>
        )}
      </div>
      <div className="membership-landing-content">
        {componentName && (
          <Modal>
            {componentName === "delete-template" && (
              <StatusModal
                curRow={curRow}
                modalHeaderTxt={"Delete Membership"}
                modalParaText={
                  "Are you sure you want to delete this membership?"
                }
                handleStatusClick={handleDeleteClick}
                modalClose={modalClose}
              />
            )}
            {componentName === "live-confirm" && (
              <StatusModal
                curRow={curRow}
                modalHeaderTxt={`${
                  curRow.isLiveOnWebsite ? "Deactivate" : "Activate"
                } Live Membership  `}
                modalParaText={`Are you sure you  want to make this membership ${
                  curRow.isLiveOnWebsite ? "no longer live " : "live"
                } on the website?`}
                handleStatusClick={handleLiveClick}
                modalClose={modalClose}
              />
            )}
            {componentName === "status-confirm" && (
              <StatusModal
                curRow={curRow}
                modalHeaderTxt={`${
                  curRow.isActive ? "deactivate" : "activate"
                } Membership`}
                modalParaText={`Are you sure you want to ${
                  curRow.isActive ? "deactivate" : "activate"
                } this membership?`}
                handleStatusClick={handleStatusClick}
                modalClose={modalClose}
              />
            )}
            {componentName === "peak-confirm" && (
              <StatusModal
                curRow={curRow}
                modalHeaderTxt={`${
                  curRow.isPeak ? "deactivate" : "activate"
                } peak hours`}
                modalParaText={`Are you sure you want to ${
                  curRow.isPeak ? "deactivate" : "activate"
                } peak hours for this Membership?`}
                handleStatusClick={handlePeakClick}
                modalClose={modalClose}
              />
            )}
          </Modal>
        )}
      </div>
    </>
  );
};
export default Membership;
