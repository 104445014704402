import React from "react";

import "./congrats.scss";
import { NavigateButtonGroup } from "../index";
import { Button } from "../../../../components";
import appStore from "../../../../assets/images/appstore.png";
import chPlay from "../../../../assets/images/chplay.png";
import { useAddMemberContext } from "../../../../contexts/AddMemberContext";
import { format } from "date-fns";
import { useMemberContext } from "../../../../contexts/MemberContext";
import { useNavigate } from "react-router-dom";
import { SET_MEMBER_ID } from "../../../../actions";

function Congrats() {
  const { memberData, memberInformation } = useAddMemberContext();
  const { dispatch } = useMemberContext();
  let navigate = useNavigate();

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <div>
      <div className="welcome">
        <h3>
          WELCOME,{memberData.memberDetails.givenName}{" "}
          {memberData.memberDetails.surname}
        </h3>
        <div className="user-info">
          <div className="membership-info">
            <p className="title">Email</p>
            <p className="start-date">{memberData.memberDetails.email}</p>
          </div>
          <div className="membership-info">
            <p className="title">Membership start date</p>
            <p className="start-date">
              {format(
                memberData.membershipStartDate
                  ? new Date(memberData.membershipStartDate)
                  : new Date(),
                "dd/MM/yyyy"
              )}
            </p>
          </div>
        </div>
      </div>
      <Button
        name="View"
        btntype="submit"
        btnname="submit"
        className="btn btn-transparent btn-small view-btn"
        click={() => {
          dispatch({
            type: SET_MEMBER_ID,
            payload: {
              memberId: memberInformation?.signupData?.memberId,
            },
          });
          navigate(
            `/dashboard/member/${memberInformation?.signupData?.memberId}`
          );
        }}
      />
      <div className="user-details-form">
        <p>NEXT STEPS</p>
        <div className="">
          <div className="step-container">
            <div className="step-name">
              <h1>1</h1>
              <p>CHECK YOUR EMAIL</p>
            </div>
            <div className="step-detail">
              <p>
                {" "}
                We’ve sent your membership details and payment receipt <br /> to
                your nominated email.
              </p>
            </div>
          </div>
          <div className="step-container">
            <div className="step-name">
              <h1>2</h1>
              <p>CHECK THE APP</p>
            </div>
            <div className="step-2-detail">
              <p>Download the Plus Fitness app, get it setup on your phone</p>
              <div className="logo">
                <img
                  className=""
                  src={appStore}
                  alt="appstore"
                  onClick={() =>
                    openInNewTab(
                      "https://apps.apple.com/au/app/plus-fitness-member-id/id1612191369"
                    )
                  }
                />
                <img
                  className="h-[53px] w-auto cursor-pointer"
                  src={chPlay}
                  alt="ggplay"
                  onClick={() =>
                    openInNewTab(
                      "https://play.google.com/store/apps/details?id=com.vivaleisure.plus_fitness_member_id&pli=1"
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="step-container">
            <div className="step-name">
              <h1>3</h1>
              <p>
                COME TO YOUR CLUB AND <br /> START TRAINING
              </p>
            </div>
            <div className="step-detail">
              <p>
                Congratulations on taking the first step of your fitness journey
                at Plus Fitness.
                <br />
                Simply use the mobile member app to access your gym and get your
                sweat on!
              </p>
            </div>
          </div>
        </div>
      </div>
      <NavigateButtonGroup />
    </div>
  );
}

export default Congrats;
