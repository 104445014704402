import React from "react";
import Button from "../Button";

//icons
import { AiOutlineEdit } from "react-icons/ai";

export const EditButton = (props) => {
  return (
    <div>
      {props.isEditing ? (
        <>
          <span className="icon-container">
            <div className="sub-container save">
              {/* <IoIosSave /> */}
              <Button
                iconname="BiSave"
                btntype="submit"
                btnname="submit"
                className="btn btn-edit save btn-lg fs-10 "
              />
            </div>
            <div className="sub-container cancel">
              {/* <ImCancelCircle /> */}
              <Button
                iconname="AiOutlineCloseCircle"
                btntype="reset"
                btnname="reset"
                className="btn btn-edit cancel btn-lg fs-10"
                click={props.handleCancel}
              />
            </div>
          </span>
        </>
      ) : (
        <span className="icon-container edit " onClick={props.handleClick}>
          <AiOutlineEdit />
        </span>
      )}
    </div>
  );
};
