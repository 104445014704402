import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { BeatLoader } from "react-spinners";
import { GET_GROUP } from "../../gqloperations/queries";
import { GroupDetailsTable, Header} from "../../components";
import isAfter from "date-fns/isAfter";

const ViewGroup = () => {
  const { id } = useParams();
  const [index, setIndex] = useState(0);
  const [groupLocations, setGroupLocations] = useState([]);
  const [groupStaffs, setGroupStaffs] = useState([]);

  const sortByCreatedAtDesc = (arr) => {
    return arr.sort((a, b) => {
      const result = isAfter(new Date(a.createdAt), new Date(b.createdAt));
      return result ? -1 : 1;
    });
  };

  const {
    loading: getGroupLoading,
    error: getGroupError,
    data: getGroupData,
    refetch,
  } = useQuery(GET_GROUP, {
    variables: {
      id,
    },
    onCompleted: (data) => {
      const { items: locations } = data.getGroup.groupLocation;
      setGroupLocations(locations);
      const { items: staffs } = data.getGroup.groupStaff;

      setGroupStaffs(sortByCreatedAtDesc([...staffs]));
    },
  });

  if (getGroupLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (getGroupError) {
    return (
      <div>
        <div>Error: Problem getting group information</div>
      </div>
    );
  }
  return (
    <>
      <Header pageTitle="Group Details" />
      <div className="group-details-container">
        <div className="tabs mt-32">
          <div className="tabs-list">
            <div
              className={
                index !== 0
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => {
                setIndex(0);
              }}
            >
              Location
            </div>
            <div
              className={
                index !== 1
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => {
                setIndex(1);
              }}
            >
              staff
            </div>
          </div>
          {/* <div className="tabContent" hidden={index !== 0}>
            HKLJSADl
          </div>
          <div className="tabContent" hidden={index !== 1}>
            HE
          </div> */}
        </div>

        {/* <div className="tabs-list">
        <div
          onClick={() => setIndex(0)}
        >
          Location
        </div>

        <div
          onClick={() => setIndex(1)}
        >
          staff
        </div>
      </div> */}
        <div className="group-details-content mt-32">
          <h3 className="group-name uppercase">
            Group Name : {getGroupData?.getGroup.name ?? ""}
          </h3>

          <GroupDetailsTable
            tableData={index === 0 ? groupLocations : groupStaffs}
            searchBy={index === 0 ? "Location Name" : "First Name, Surname "}
            refetch={refetch}
          />
        </div>
      </div>
    </>
  );
};

export default ViewGroup;
