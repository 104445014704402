import React, { useState } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";
import { instructorPersonalInfoSchema } from "../../validations/Validation";
import { gender } from "../../constants/commonConstant";

import {
  Button,
  Input,
  Select,
  HiddenDatePicker,
} from "../../components/index";

const InstructorPersonalInfo = ({ instructorInfo, onSubmit }) => {
  const [isActive, setIsActive] = useState(true);
  const handleIsActive = () => {
    setIsActive(false);
  };

  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("instructor-personal-info-form").reset();
    setIsActive(true);
  };

  return (
    <>
      {instructorInfo && (
        <Formik
          enableReinitialize
          initialValues={{
            instructorDisplayName: instructorInfo?.instructorDisplayName ?? "",
            givenName: instructorInfo?.givenName ?? "",
            surname: instructorInfo?.surname ?? "",
            dob: instructorInfo?.dob ?? "",
            gender: instructorInfo?.gender ?? "",
          }}
          validationSchema={instructorPersonalInfoSchema}
          onSubmit={async (values, OnSubmitProps) => {
            onSubmit(values, OnSubmitProps);
            setIsActive(true);
          }}
        >
          {(formik) => {
            return (
              <div className="instructor-personal-container">
                <Form
                  className="info-card pd-18"
                  id="instructor-personal-info-form"
                >
                  {isActive ? (
                    <>
                      <div className="title-bar">
                        <h3 className="fs-14 uppercase">
                          Personal Information
                        </h3>
                        <span
                          className="icon-container edit"
                          onClick={handleIsActive}
                        >
                          <AiOutlineEdit />
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="title-bar">
                      <h3 className="fs-14 uppercase">Personal Information</h3>
                      <span className="icon-container ">
                        <div className="sub-containers">
                          <div className="sub-container save">
                            <Button
                              name={""}
                              loading={formik.isSubmitting ? 1 : 0}
                              iconname={formik.isSubmitting ? "" : "BiSave"}
                              btntype="submit"
                              btnname="submit"
                              className="btn btn-edit save btn-lg fs-12"
                              disabled={formik.isSubmitting ? true : false}
                            />
                          </div>
                          <div className="sub-container cancel">
                            <Button
                              iconname="AiOutlineCloseCircle"
                              btntype="reset"
                              btnname="reset"
                              className="btn btn-icon btn-lg fs-12"
                              click={handleCancel}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  )}
                  <div className="personal-info-details">
                    <div className="detail name">
                      <Input
                        type="text"
                        label="Given Name* "
                        name="givenName"
                        autoComplete="off"
                        disabled={isActive ? true : false}
                      />
                    </div>
                    <div className="detail surname">
                      <Input
                        type="text"
                        label="Surname* "
                        name="surname"
                        id="surname"
                        disabled={isActive ? true : false}
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail no-underline">
                      <Input
                        type="text"
                        label="Display Name *"
                        name="instructorDisplayName"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail dob">
                      <HiddenDatePicker
                        disabled={isActive}
                        name="dob"
                        label="Date of Birth"
                      />
                    </div>
                    <div className="detail gender">
                      <Select
                        label="Gender"
                        optionvalues={gender}
                        selecttype="array"
                        name="gender"
                        autoComplete="off"
                        disabled={isActive ? true : false}
                        className="fs-12"
                      />
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      )}
    </>
  );
};
export default InstructorPersonalInfo;
