import format from "date-fns-tz/format";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import { startOfDay, isAfter, isBefore, isSameDay, endOfDay } from "date-fns";

const isSuspensionActive = ({ suspensionEndDate }) => {
  return (
    suspensionEndDate &&
    (isAfter(suspensionEndDate, new Date()) ||
      isSameDay(suspensionEndDate, new Date()))
  );
};
const getInfoModalParaText = (curModalDetails, timezone) => {
  let paraText;

  const expiryDate = new Date(curModalDetails.expiryDateTime);
  const text = formatInTimeZone(expiryDate, timezone, "MMMM do yyyy");
  // cancellation
  if (
    isAfter(
      startOfDay(
        utcToZonedTime(new Date(curModalDetails?.expiryDateTime), timezone)
      ),
      new Date()
    )
  ) {
    // medical suspension before approvement
    paraText = `This membership will be cancelled on ${text}`;
  } else if (
    curModalDetails?.suspensionDetails?.suspensionType.toLowerCase() ===
      "medical" &&
    curModalDetails?.suspensionDetails?.suspensionStatus.toLowerCase() ===
      "pending"
  ) {
    paraText = `Pending medical suspension is awaiting for approval, suspension is scheduled from ${
      format(
        utcToZonedTime(
          curModalDetails?.suspensionDetails?.suspensionStartDateTime,
          timezone
        ),
        "dd/MM/yy"
      )
      //   format(
      //   new Date(curModalDetails?.suspensionDetails?.suspensionStartDateTime),
      //   "MMMM do yyyy"
      // )
    } 
    to ${
      format(
        utcToZonedTime(
          curModalDetails?.suspensionDetails?.suspensionEndDateTime,
          timezone
        ),
        "dd/MM/yy"
      )
      //   format(
      //   new Date(curModalDetails?.suspensionDetails?.suspensionEndDateTime),
      //   "MMMM do yyyy"
      // )
    }`;
  }
  // suspension
  else if (
    isSuspensionActive({
      suspensionEndDate: utcToZonedTime(
        curModalDetails?.suspensionDetails?.suspensionEndDateTime,
        timezone
      ),
    }) &&
    curModalDetails?.suspensionDetails?.cancelledDateTime === null
  ) {
    paraText = `This membership has a suspension from ${format(
      // new Date(curModalDetails?.suspensionStartDate),
      utcToZonedTime(curModalDetails?.suspensionStartDate, timezone),

      "MMMM do yyyy"
    )} to ${format(
      utcToZonedTime(curModalDetails?.suspensionEndDate, timezone),
      // new Date(curModalDetails?.suspensionEndDate),
      "MMMM do yyyy"
    )}`;
  } else if (
    // junior after parental signature
    curModalDetails?.isJuniorMemberPending &&
    !curModalDetails?.signature
  ) {
    paraText = `Membership activation awaits parental approval and will shift to active status upon consent. Membership request was submitted on ${format(
      new Date(curModalDetails?.createdAt),
      "MMMM do yyyy"
    )}`;
  } else if (
    curModalDetails?.isJuniorMemberPending &&
    curModalDetails?.signature &&
    !curModalDetails?.suspensionStartDate
  ) {
    paraText = "Parent consent received, please activate membership";
  } else {
    paraText = "";
  }
  return paraText;
};

const getInfoModalHeaderText = (curModalDetails, timezone) => {
  let headerText;

  if (
    isAfter(
      startOfDay(
        new Date(utcToZonedTime(curModalDetails?.expiryDateTime, timezone))
      ),
      new Date()
    )
  ) {
    headerText = "Membership Cancellation";
  } else if (
    curModalDetails?.suspensionDetails?.suspensionType.toLowerCase() ===
      "medical" &&
    curModalDetails?.suspensionDetails?.suspensionStatus.toLowerCase() ===
      "pending"
  ) {
    headerText = "Medical Suspension";
  } else if (
    curModalDetails?.expiryDateTime &&
    !curModalDetails.isJuniorMemberPending &&
    (!curModalDetails?.suspensionDetails?.suspensionEndDateTime ||
      isBefore(
        startOfDay(
          new Date(curModalDetails?.suspensionDetails?.suspensionEndDateTime)
        ),
        new Date()
      ))
  ) {
    headerText = "Revoke Membership cancellation";
  } else if (curModalDetails.isJuniorMemberPending) {
    headerText = "Membership Activation Pending";
  } else if (curModalDetails?.suspensionDetails?.suspensionStartDateTime) {
    headerText = "Membership Suspension";
  } else {
    headerText = "";
  }
  return headerText;
};

export { getInfoModalHeaderText, getInfoModalParaText };
