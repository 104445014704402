import * as yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";

export const locationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  shortName: yup.string().required("Short name is required"),
  locationType: yup.string(),
  address1: yup.string().required("Address is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  country: yup.string().required("Country is required"),
  suburb: yup.string().required("Suburb is required"),
  postCode: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .required("Postcode is required"),
  ddDescriptorCreditCard: yup
    .string()
    .max(100, "Maximum 100 characters allowed"),
  ddDescriptorBankAccount: yup
    .string()
    .max(16, "Maximum 16 characters allowed"),
});

export const doorAccessSchema = yup.object().shape({
  doorName: yup.string().required("Name is required"),
  code: yup.string().required("Code is required"),
});

export const accessPointSchema = yup.object().shape({
  doorName: yup.string().required("doorName is required"),
  accessPointname: yup.string().required("accessPointname is required"),
});

export const clubSettingsSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  address1: yup.string().max(100, "Maximum 100 characters allowed"),
  locationType: yup.string(),
  city: yup.string().required("City is required"),
  postCode: yup.string().matches(/^[0-9]+$/, "Must be only digits"),
  email: yup.string().notRequired().email("Invalid Email Address").nullable(),
  shortName: yup.string().required("Short name is required"),
  ptPackEmail: yup
    .string()
    .notRequired()
    .email("Invalid Email Address")
    .nullable(),
  changePriceEmail: yup
    .string()
    .notRequired()
    .email("Invalid Email Address")
    .nullable(),
  phoneNumber: yup
    .string()
    .test("is-valid-number", "Invalid Number", (phoneNumber) => {
      return phoneNumber ? isValidPhoneNumber(phoneNumber) : true;
    }),
  peakLoad: yup.string().matches(/^[0-9]+$/, "Must be 0 or positive integer"),
  membershipTarget: yup
    .string()
    .matches(/^[0-9]+$/, "Must be 0 or positive integer"),
  ptTarget: yup.string().matches(/^[0-9]+$/, "Must be 0 or positive integer"),
  ptTargetRevenue: yup
    .string()
    .matches(/^[0-9]+$/, "Must be 0 or positive integer"),
});

export const clubSalesSchema = yup.object().shape({
  freeTrialLengthDays: yup
    .string()
    .matches(/^\d+$/, "Must be 0 or positive integer"),
  trialVisitLimit: yup
    .string()
    .matches(/^-1$|^\d+$/, "Must be -1, 0 or positive integer"),
});

export const bankDetailsSchema = yup.object().shape({
  ddDescriptorCreditCard: yup
    .string()
    .max(100, "Maximum 100 characters allowed"),
  ddDescriptorBankAccount: yup
    .string()
    .max(16, "Maximum 16 characters allowed"),
  bsb: yup
    .string()
    .min(6)
    .max(6, "Maximum 6 digist allowed")
    .matches(/^\d+$/, "Bsb must contain only numbers")
    .required("Bsb code is Required"),
  accountName: yup.string().min(1).required("Field is required"),
  accountNumber: yup
    .string()
    .min(6)
    .max(10, "Maximum 10 digist allowed")
    .required(),
  abn: yup.string().min(11).max(11, "Maximum 11 digist allowed").required(),
});

export const clubLeadsSchema = yup.object().shape({
  leadsEmailAddress: yup
    .string()
    .notRequired()
    .email("Invalid Email Address")
    .nullable(),
});
export const locationFeatureSchema = yup.object().shape({
  "24/7 Digital Access": yup.boolean().required(),
  "25m Pool": yup.boolean().required(),
  "50m Pool": yup.boolean().required(),
  "Accessible 24/7": yup.boolean().required(),
  Creche: yup.boolean().required(),
  "Cycle Studio": yup.boolean().required(),
  "Endless Cardio Machines": yup.boolean().required(),
  "Functional fitness at its finest": yup.boolean().required(),
  "Group Fitness": yup.boolean().required(),
  "Ladies Only Training Space": yup.boolean().required(),
  "Less Wait for our weights": yup.boolean().required(),
  "Outdoor Zone": yup.boolean().required(),
  "Personal Training": yup.boolean().required(),
  Pool: yup.boolean().required(),
  Sauna: yup.boolean().required(),
  Spa: yup.boolean().required(),
  "Steam Room": yup.boolean().required(),
});

export const locationStudioSchema = yup.object().shape({
  studioName: yup.string().required("Studio name is required"),
});
