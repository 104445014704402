import "./fpbarcodemodal.scss";
import { Formik, Form } from "formik";
import Input from "../formUiElements/input/Input";
import Button from "../formUiElements/Button";

const FPbarcodeModal = (props) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        fitnessPassportNum: props.fitnessPassportNum ?? "",
      }}
      onSubmit={(values, OnSubmitProps) => {
        props.handleFitnessPassportSubmit(values, OnSubmitProps);
      }}
      validate={(values, props) => {
        const { fitnessPassportNum } = values;

        let errors = {};

        if (!fitnessPassportNum) {
          const errorText = "Please provide bardcode number";
          errors.fitnessPassportNum = errorText;
        }
        if (!fitnessPassportNum.match(/^\d+$/)) {
          const errorText = "Only numbers allowed";
          errors.fitnessPassportNum = errorText;
        }

        return errors;
      }}
      validateOnChange={true}
    >
      {(formik) => {
        return (
          <Form className="status-modal cancel-membership pd-32">
            <h3 className="fs-35 uppercase ">Fitness Passport</h3>
            <div className="fitpassport-container">
              <div className="placeholder">
                <Input
                  type="text"
                  label="Barcode"
                  name="fitnessPassportNum"
                  id="fitnessPassportNum"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    // Setting this because <Input> only displays errors on "touched" formik fields
                    formik.setFieldTouched("fitnessPassportNum");
                    formik.handleChange(e);
                  }}
                />
              </div>
              {/* <div className="editbtn-container pb-18">
                {props.fitnessPassportNum && !editable && (
                  <AiOutlineEdit onClick={handleEditClick} />
                )}

                {props.fitnessPassportNum && editable && (
                  <Button
                    iconname="AiOutlineCloseCircle"
                    btntype="reset"
                    btnname="reset"
                    className="btn btn-edit cancel btn-lg fs-12"
                    click={() => {
                      setEditable(false);
                      formik.setFieldValue(
                        "fitnessPassportNum",
                        props.fitnessPassportNum ?? ""
                      );
                    }}
                  />
                )}
              </div> */}
            </div>
            <div className="btn-group modal-btn">
              <Button
                loading={formik.isSubmitting}
                name="Save"
                btntype="submit"
                btnname="submit"
                className="btn btn-confirm btn-small"
                disabled={formik.isSubmitting ? true : false}
              />
              <Button
                name="Cancel"
                btntype="submit"
                btnname="submit"
                className="btn btn-deny btn-small"
                click={props.cancelFn}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FPbarcodeModal;
