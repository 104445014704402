import "./bulkSms.scss";
import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { SMS_SENDER } from "../../gqloperations/mutations";
import { useMemberContext } from "../../contexts/MemberContext";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { bulkSmsSchema } from "../../validations/Validation";
import { Select, Button, Header } from "../../components";
import { useHandleError } from '../../hooks/useHandleError';

const BulkSms = () => {
  const { sidebarBrandId, sidebarLocationId, sidebarLocationName } =
    useMemberContext();
  const { locationAccess } = useAuthContext();
  const [sendSMS] = useMutation(SMS_SENDER);
  const [locationItems, setLocationItems] = useState([]);
  const [locationError, setLocationError] = useState("");
  const handleError = useHandleError();
  const [datalistInputValue, setDatalistInputValue] = useState(
    !sidebarLocationId? "" : sidebarLocationName
  );
  const [location, setLocation] = useState(
    !sidebarLocationId
      ? { locationId: "", locationName: "" }
      : {
          locationId: sidebarLocationId,
          locationName: sidebarLocationName,
        }
  );
  const [selectedBrandId, seSelectedBrandId] = useState(sidebarBrandId);
  useEffect(() => {
    const selectedBrand = locationAccess.brands.find(
      (brand) => brand.brandId === selectedBrandId
    );
    setLocationItems(selectedBrand?.locations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrandId]);
  useEffect(() => {
    if (selectedBrandId !== sidebarBrandId) {
      setLocation({ locationId: "", locationName: "" });
      setDatalistInputValue("");
    } else {
      setLocation(
        !sidebarLocationId
          ? { locationId: "", locationName: "" }
          : {
              locationId: sidebarLocationId,
              locationName: sidebarLocationName,
            }
      );
      setDatalistInputValue(!sidebarLocationId ? "" : sidebarLocationName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrandId]);
  useEffect(() => {
    setLocationError("");
    setLocation({ locationId: "", locationName: "" });
    if (!datalistInputValue) {
      setLocation({});
    }
    const selectedLocation = locationItems.find(
      (item) =>
        item.locationName.toLowerCase() === datalistInputValue?.toLowerCase()
    );
    if (selectedLocation) {
      setLocation({
        ...location,
        locationId: selectedLocation.locationId,
        locationName: selectedLocation.locationName,
      });
      setDatalistInputValue(selectedLocation.locationName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datalistInputValue]);
  const categories = [
    { id: "ALL_MEMBERS", name: "ALL MEMBERS" },
    { id: "ACTIVE_MEMBERS", name: "ACTIVE MEMBERS" },
    { id: "SUSPENDED_MEMBERS", name: "SUSPENDED MEMBERS" },
    { id: "EXPIRED_MEMBERS", name: "MEMBERSHIPN EXPIRED" },
    { id: "DD_FAILED", name: "DIRECT DEBIT FAILED" },
    {
      id: "PIF_EXPIRING",
      name: "PIF EXPIRING",
    },
  ];
  const handleChange = (e) => {
    setDatalistInputValue(e.target.value);
  };
  const onSubmit = (values, OnSubmitProps) => {
    if (!location.locationId) {
      OnSubmitProps.setSubmitting(false);
      return;
    }
    const { brandIdFilter, ...smsVariables } = values;
    smsVariables.type = "BULK";
    smsVariables.locationIdFilter = location.locationId;
    smsVariables.originNumber = locationAccess.brands.find(
      (brand) => brand.brandId === selectedBrandId
    ).brandName;
    sendSMS({
      variables: smsVariables,
      onCompleted: (data) => {
        toast.success("Bulk SMS Send Successfully");
        OnSubmitProps.setSubmitting(false);
        OnSubmitProps.resetForm();
      },
      onError: (error) => {
        handleError(error);
        OnSubmitProps.setSubmitting(false);
      },
    });
    // sendSMS({
    //   variables: {
    //     input: values.secondaryEmail
    //       ? {
    //           ...basicInput,
    //           secondaryEmail: values.secondaryEmail.toUpperCase(),
    //         }
    //       : basicInput,
    //   },
    //   onCompleted: (data) => {
    //     toast.success("Staff Added Successfully");

    //     if (file) {
    //       updateStaffImage(data);
    //     } else {
    //       setTimeout(() => {
    //         navigate(-1);
    //       }, 2000);
    //     }
    //   },
    //   onError: (err) => {
    //     handleError(err);
    //   },
    // });
  };
  const handleClick = (formik) => {
    formik.resetForm();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        filter: "",
        message: "",
      }}
      validationSchema={bulkSmsSchema}
      onSubmit={(values, OnSubmitProps) => {
        if (!location.locationId) {
          OnSubmitProps.setSubmitting(false);
          setLocationError("Please select a location!");
          return;
        }
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <div>
            <Header pageTitle="Bulk SMS" />
            <Form>
              <div className="bulk-sms-container info-card mt-32 ">
                <div className="selections-container">
                  <div id="brand">
                    <h3 className="fs-12 text-gray">Brand *</h3>
                    <select
                      className="select-element-value"
                      value={selectedBrandId}
                      onChange={(e) => seSelectedBrandId(e.target.value)}
                    >
                      {locationAccess.brands.map((brand) => {
                        return (
                          <option key={brand.brandId} value={brand.brandId}>
                            {brand.brandName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div id="loc">
                    <h3 className="fs-12 text-gray">Location *</h3>
                    <input
                      type="text"
                      list="locations-bulk-sms"
                      placeholder="Search a Location"
                      onChange={(e) => handleChange(e)}
                      value={datalistInputValue}
                      autoComplete="off"
                      className="fs-12 select-element-value"
                    />
                    {locationItems && (
                      <datalist id="locations-bulk-sms">
                        {locationItems.map((option) => {
                          return (
                            <option
                              key={option.locationId}
                              value={option.locationName}
                            />
                          );
                        })}
                      </datalist>
                    )}
                    {locationError && (
                      <p className="error fs-12">{locationError}</p>
                    )}
                  </div>
                  <div id="smsTo">
                    <Select
                      label="Send Bulk SMS To *"
                      name="filter"
                      optionvalues={categories}
                      selecttype="object"
                      objectvalue="id"
                      objectname="name"
                      autoComplete="off"
                      className="fs-12 select-element-value"
                    />
                  </div>
                </div>
                <div>
                  <p className="reason-title fs-12 text-gray">Message *</p>
                  <textarea
                    className="reason-box fs-12"
                    id="message"
                    name="message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  ></textarea>
                  <div className="fs-12 error">
                    {formik.errors.message &&
                      formik.touched.message &&
                      formik.errors.message}
                  </div>
                </div>
              </div>
              <div className="btn-group item-right">
                <Button
                  loading={formik.isSubmitting}
                  name={"Send"}
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-confirm fs-12"
                />
                <Button
                  name="cancel"
                  btntype="reset"
                  btnname="cancel"
                  click={() => handleClick(formik)}
                  className="btn btn-deny fs-12"
                />
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default BulkSms;
