import "./bankDetails.scss";
import { useState } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { bankDetailsSchema } from "../../../validations/LocationValidation";
import Input from "../../formUiElements/input/Input";
import Button from "../../formUiElements/Button";
import { AiOutlineEdit } from "react-icons/ai";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";
export default function BankDetails(props) {
  const { onSubmit, state } = props;
  const { permissions } = useAuthContext();
  const [isActive, setIsActive] = useState(true);

  const handleIsActive = () => {
    setIsActive(false);
  };

  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("bank-details-form").reset();
    setIsActive(true);
  };

  return (
    <>
      {state.location && (
        <Formik
          enableReinitialize
          initialValues={{
            id: state.id,
            ddDescriptorCreditCard:
              state.location.getLocation.ddDescriptorCreditCard ?? "",
            ddDescriptorBankAccount:
              state.location.getLocation.ddDescriptorBankAccount ?? "",
            accountName: state.location.getLocation.accountName ?? "",
            accountNumber: state.location.getLocation.accountNumber ?? "",
            bsb: state.location.getLocation.bsb ?? "",
            abn: state.location.getLocation.abn ?? "",
          }}
          validationSchema={
            state.location.getLocation.state !== "NZ" ? bankDetailsSchema : null
          }
          onSubmit={async (values, OnSubmitProps) => {
            onSubmit(values, OnSubmitProps);
            setIsActive(true);
          }}
        >
          {(formik) => {
            return (
              <div className="bank-details-container sec-card loc-card">
                <Form id="bank-details-form">
                  {isActive ? (
                    <>
                      <div className="title-bar ">
                        <h3 className="fs-14 pb-12">Bank Details</h3>
                        {permissions.includes("CanEditLocation") && (
                          <span
                            className="icon-container edit"
                            onClick={handleIsActive}
                          >
                            <AiOutlineEdit />
                          </span>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="title-bar">
                      <h3 className="fs-14 pb-12">Bank Details</h3>
                      <span className="icon-container ">
                        <div className="sub-containers">
                          <div className="sub-container save">
                            <Button
                              loading={formik.isSubmitting}
                              name={""}
                              iconname="BiSave"
                              btntype="submit"
                              btnname="submit"
                              className="btn btn-edit save btn-lg fs-12"
                              disabled={formik.isSubmitting ? true : false}
                            />
                          </div>
                          <div className="sub-container cancel">
                            <Button
                              iconname="AiOutlineCloseCircle"
                              btntype="reset"
                              btnname="reset"
                              className="btn btn-icon btn-lg fs-12"
                              click={handleCancel}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  )}

                  <div className="detail loc flex-row">
                    {state.location.getLocation.state !== "NZ" && (
                      <div className="details-first-row">
                        <div className="">
                          <Input
                            type="text"
                            label="DD Descriptor Credit Card"
                            name="ddDescriptorCreditCard"
                            id="ddDescriptorCreditCard"
                            autoComplete="off"
                            disabled={isActive}
                          />
                        </div>
                        <div>
                          <Input
                            type="text"
                            label="DD Descriptor bank account"
                            name="ddDescriptorBankAccount"
                            id="ddDescriptorBankAccount"
                            autoComplete="off"
                            disabled={isActive}
                          />
                        </div>
                      </div>
                    )}
                    <div className="details-second-row">
                      <div>
                        <Input
                          type="text"
                          label={
                            state.location.getLocation.state !== "NZ"
                              ? "Account Name"
                              : "Account Holder Name"
                          }
                          name="accountName"
                          id="accountName"
                          autoComplete="off"
                          disabled={isActive}
                        />
                      </div>
                      {state.location.getLocation.state !== "NZ" && (
                        <div>
                          <Input
                            type="text"
                            label="BSB"
                            name="bsb"
                            id="bsb"
                            autoComplete="off"
                            disabled={isActive}
                          />
                        </div>
                      )}

                      <div>
                        <Input
                          type="number"
                          label={
                            state.location.getLocation.state !== "NZ"
                              ? "Account Number"
                              : "Bank Code"
                          }
                          name="accountNumber"
                          id="accountNumber"
                          autoComplete="off"
                          disabled={isActive}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="details-third-row">
                        <Input
                          type="number"
                          label={
                            state.location.getLocation.state !== "NZ"
                              ? "abn"
                              : "NZBN"
                          }
                          name="abn"
                          id="abn"
                          autoComplete="off"
                          disabled={isActive}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      )}
    </>
  );
}
