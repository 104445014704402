import { startOfDay, isAfter, isBefore, isSameDay, endOfDay } from "date-fns";

const currentDate = new Date();

const isContractCancelled = (data) => {
  if (!data) return false;
  const { expiryDateTime: cancelDateTime } = data;
  if (cancelDateTime) {
    return (
      isBefore(startOfDay(new Date(cancelDateTime)), currentDate) ||
      isSameDay(new Date(cancelDateTime), currentDate)
    );
  }
  return false;
};

const isContractExpired = (data) => {
  if (!data) return false;

  const { endDateTime } = data;
  if (endDateTime) {
    return isBefore(endOfDay(new Date(endDateTime)), currentDate);
  }
  return false;
};

const isContractActive = (data) => {
  if (!data) return false;

  return !isContractCancelled(data) && !isContractExpired(data);
};

const isFitnessMembershipActive = (data) => {
  if (!data) return false;

  return data.items.find((item) => {
    if (!item.membershipDetails) return false;

    const { membershipType, membershipName } = item.membershipDetails;

    const isFitnessPassportMembership = membershipType === "FITNESS_PASSPORT";

    if (!isFitnessPassportMembership) return false;

    return !isContractCancelled(item) && !isContractExpired(item);
  });
};

export {
  isContractCancelled,
  isContractExpired,
  isContractActive,
  isFitnessMembershipActive,
};
