import { gql } from "@apollo/client";

export const ADD_STAFF = gql`
  mutation AddStaff($input: AddStaff!) {
    addStaff(input: $input)
  }
`;

export const ADD_LOCATION = gql`
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      id
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation updateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      id
    }
  }
`;

export const UPDATE_MEMBER = gql`
  mutation updateMember($input: UpdateMemberInput!) {
    updateMember(input: $input) {
      memberId
    }
  }
`;
export const UPDATE_STAFF_ROLE = gql`
  mutation updateStaffRole($input: UpdateRole!) {
    updateStaffRole(input: $input)
  }
`;
export const CREATE_DOOR_ACCESS_CODE = gql`
  mutation createDoorAccessCode($input: CreateDoorAccessCodeInput!) {
    createDoorAccessCode(input: $input) {
      id
    }
  }
`;
export const CREATE_DOOR_ACCESS_LOG = gql`
  mutation createDoorAccessLog($input: CreateDoorAccessLogInput!) {
    createDoorAccessLog(input: $input) {
      id
      memberId
      memberName
      swipePhoto
      memberPhoto
      createdAt
      isValid
      memberHomeClub
      deviceType
      cardNumber
      homeLocationId
      doorId
      locationId
    }
  }
`;
export const CREATE_CLASS_TEMPLATE = gql`
  mutation createClassTemplate($input: CreateClassTemplateInput!) {
    createClassTemplate(input: $input) {
      id
    }
  }
`;

export const UPDATE_CLASS_TEMPLATE = gql`
  mutation updateClassTemplate($input: UpdateClassTemplateInput!) {
    updateClassTemplate(input: $input) {
      id
    }
  }
`;

export const DELETE_CLASS_TEMPLATE = gql`
  mutation deleteClassTemplate($input: DeleteClassTemplateInput!) {
    deleteClassTemplate(input: $input) {
      id
    }
  }
`;

export const CREATE_CLASS_SCHEDULE = gql`
  mutation createClassSchedule($input: CreateClassScheduleInput!) {
    createClassSchedule(input: $input) {
      id
    }
  }
`;

export const UPDATE_CLASS_SCHEDULE = gql`
  mutation updateClassSchedule($input: UpdateClassScheduleInput!) {
    updateClassSchedule(input: $input) {
      id
    }
  }
`;

export const UPDATE_CLASS_SCHEDULE_SERIES = gql`
  mutation updateSeriesClass(
    $isThisSeries: Boolean!
    $seriesId: ID!
    $classScheduleId: ID!
    $studioId: ID
    $instructorId: ID
    $classTemplateId: ID
    $classScheduleDateTime: AWSDateTime
    $bookingLockoutMinutes: Int
    $cancelLockoutMinutes: Int
    $maxBooking: Int
    $targetBooking: Int
    $maxTeam: Int
    $maxPerTeam: Int
    $bookingMessage: String
    $isBookable: Boolean
    $canWaitlist: Boolean
    $imageUrl: String
  ) {
    updateSeriesClass(
      isThisSeries: $isThisSeries
      seriesId: $seriesId
      classScheduleId: $classScheduleId
      studioId: $studioId
      instructorId: $instructorId
      classTemplateId: $classTemplateId
      classScheduleDateTime: $classScheduleDateTime
      bookingLockoutMinutes: $bookingLockoutMinutes
      cancelLockoutMinutes: $cancelLockoutMinutes
      maxBooking: $maxBooking
      targetBooking: $targetBooking
      maxTeam: $maxTeam
      maxPerTeam: $maxPerTeam
      bookingMessage: $bookingMessage
      isBookable: $isBookable
      canWaitlist: $canWaitlist
      imageUrl: $imageUrl
    )
  }
`;

export const UPDATE_DOOR_ACCESS_CODE = gql`
  mutation updateDoorAccessCode($input: UpdateDoorAccessCodeInput!) {
    updateDoorAccessCode(input: $input) {
      id
    }
  }
`;

export const DELETE_DOOR_ACCESS_CODE = gql`
  mutation deleteDoorAccessCode($input: DeleteDoorAccessCodeInput!) {
    deleteDoorAccessCode(input: $input) {
      id
    }
  }
`;

export const AUDIT_OPERATION = gql`
  mutation createAuditOperation($input: CreateAuditOperationInput!) {
    createAuditOperation(input: $input) {
      id
    }
  }
`;

export const CREATE_LOCATION_AUDIT_OPERATION = gql`
  mutation createLocationAuditOperation(
    $input: CreateLocationAuditOperationInput!
  ) {
    createLocationAuditOperation(input: $input) {
      id
    }
  }
`;

export const AUDIT_VISIT_LOG = gql`
  mutation createAuditVisitLog($input: CreateAuditVisitLogInput!) {
    createAuditVisitLog(input: $input) {
      id
    }
  }
`;

export const CREATE_GUEST_ENTRY = gql`
  mutation createGuestEntry($input: CreateGuestEntryInput!) {
    createGuestEntry(input: $input) {
      id
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation createNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      id
      createdAt
    }
  }
`;

export const UPDATE_LOCATION_FEATURE = gql`
  mutation updateLocationFeature($input: UpdateLocationFeatureInput!) {
    updateLocationFeature(input: $input) {
      id
      isAvailable
      featureDetails {
        id
        name
        imageUrl
      }
    }
  }
`;
export const CREATE_LOCATION_FEATURE = gql`
  mutation createLocationFeature($input: CreateLocationFeatureInput!) {
    createLocationFeature(input: $input) {
      id
      locationId
      isAvailable
      featureDetails {
        id
        name
        imageUrl
      }
    }
  }
`;
export const DELETE_LOCATION_HOURS = gql`
  mutation deleteLocationAccessTimes($input: DeleteLocationAccessTimesInput!) {
    deleteLocationAccessTimes(input: $input) {
      id
    }
  }
`;
export const ADD_LOCATION_HOURS = gql`
  mutation createLocationAccessTimes($input: CreateLocationAccessTimesInput!) {
    createLocationAccessTimes(input: $input) {
      id
      day
      type
      openTime
      closeTime
      locationId
    }
  }
`;
export const ADD_DOOR_ACCESS_HOURS = gql`
  mutation createAccessPointTimes($input: CreateAccessPointTimesInput!) {
    createAccessPointTimes(input: $input) {
      id
    }
  }
`;
export const DELETE_DOOR_ACCESS_HOURS = gql`
  mutation deleteAccessPointTimes($input: DeleteAccessPointTimesInput!) {
    deleteAccessPointTimes(input: $input) {
      id
    }
  }
`;
export const DELETE_ACCESS_POINT = gql`
  mutation deleteAccessPoint($input: DeleteAccessPointInput!) {
    deleteAccessPoint(input: $input) {
      id
    }
  }
`;
export const ADD_DOOR_ACCESS = gql`
  mutation createAccessPoint($input: CreateAccessPointInput!) {
    createAccessPoint(input: $input) {
      id
    }
  }
`;
export const UPDATE_ACCESS_MESSAGE = gql`
  mutation updateAccessPoint($input: UpdateAccessPointInput!) {
    updateAccessPoint(input: $input) {
      id
    }
  }
`;

export const UPDATE_CLASS_BOOKING = gql`
  mutation updateClassBooking($input: UpdateClassBookingInput!) {
    updateClassBooking(input: $input) {
      id
      hasAttended
      memberDetails {
        givenName
      }
    }
  }
`;

export const ADD_MEMBERSHIP_TO_BRAND = gql`
  mutation addMembershipToBrand(
    $membershipInput: MembershipInput!
    $paymentFrequency: FrequencyInput!
    $paymentDelay: FrequencyInput
    $contractLength: FrequencyInput
    $noticePeriod: FrequencyInput!
    $membershipPaymentType: [MembershipPaymentInput]!
    $membershipActivationDate: MembershipActivationDateInput!
    $membershipType: MEMBERSHIP_TYPE!
    $membershipLevel: MEMBERSHIP_LEVEL!
    $isPeak: Boolean
  ) {
    addMembershipToBrand(
      membershipInput: $membershipInput
      paymentFrequency: $paymentFrequency
      paymentDelay: $paymentDelay
      contractLength: $contractLength
      noticePeriod: $noticePeriod
      membershipPaymentType: $membershipPaymentType
      membershipActivationDate: $membershipActivationDate
      membershipType: $membershipType
      membershipLevel: $membershipLevel
      isPeak: $isPeak
    )
  }
`;

export const ADD_CASUAL_BOOKING = gql`
  mutation addCasualBooking(
    $casualFirstName: String!
    $casualLastName: String!
    $casualPhone: AWSPhone!
    $casualEmail: AWSEmail!
    $classSchedule: ID!
  ) {
    addCasualBooking(
      casualFirstName: $casualFirstName
      casualLastName: $casualLastName
      casualPhone: $casualPhone
      casualEmail: $casualEmail
      classSchedule: $classSchedule
    ) {
      message
      bookingId
    }
  }
`;

export const ADD_MEMBERSHIP_TO_LOCATION = gql`
  mutation addMembershipToLocation($locationIds: [ID!]!, $membershipId: ID!) {
    addMembershipToLocation(
      locationIds: $locationIds
      membershipId: $membershipId
    )
  }
`;

export const CREATE_MEMBERSHIP_ACTIVATION_DATE = gql`
  mutation CreateMembershipActivationDate(
    $input: CreateMembershipActivationDateInput!
    $condition: ModelMembershipActivationDateConditionInput
  ) {
    createMembershipActivationDate(input: $input, condition: $condition) {
      id
      membershipId
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_MEMBERSHIP_ACTIVATION_DATE = gql`
  mutation updateMembershipActivationDate(
    $input: UpdateMembershipActivationDateInput!
    $condition: ModelMembershipActivationDateConditionInput
  ) {
    updateMembershipActivationDate(input: $input, condition: $condition) {
      id
    }
  }
`;

export const UPDATE_MEMBERSHIP_DESCRIPTION = gql`
  mutation updateMembership($input: UpdateMembershipInput!) {
    updateMembership(input: $input) {
      id
    }
  }
`;

export const DELETE_MEMBERSHIP_ACTIVATION_DATE = gql`
  mutation deleteMembershipActivationDate(
    $input: DeleteMembershipActivationDateInput!
    $condition: ModelMembershipActivationDateConditionInput
  ) {
    deleteMembershipActivationDate(input: $input, condition: $condition) {
      id
    }
  }
`;

export const ADD_MEMBERSHIP_TO_MEMBER = gql`
  mutation addMembershipToMember(
    $memberId: ID!
    $membershipLocationId: ID!
    $paymentType: PAYMENTMETHODTYPE!
    $futurePaymentType: PAYMENTMETHODTYPE!
    $paymentInformationId: ID
    $futurePaymentInformationId: ID
    $transactionId: ID
    $orderId: ID
    $membershipStartDate: AWSDate
  ) {
    addMembershipToMember(
      memberId: $memberId
      membershipLocationId: $membershipLocationId
      paymentType: $paymentType
      futurePaymentType: $futurePaymentType
      transactionId: $transactionId
      orderId: $orderId
      paymentInformationId: $paymentInformationId
      futurePaymentInformationId: $futurePaymentInformationId
      membershipStartDate: $membershipStartDate
    )
  }
`;

export const UPDATE_MEMBERSHIP_LOCATION = gql`
  mutation updateMembershipLocation(
    $input: UpdateMembershipLocationInput!
    $condition: ModelMembershipLocationConditionInput
  ) {
    updateMembershipLocation(input: $input, condition: $condition) {
      id
    }
  }
`;
export const UPDATE_MEMBERSHIP = gql`
  mutation updateMembership($input: UpdateMembershipInput!) {
    updateMembership(input: $input) {
      id
      isDeleted
    }
  }
`;
export const UPDATE_MEMBERSHIP_LOCATION_STATUS_PRICE = gql`
  mutation updatePriceForMembershipLocation(
    $membershipId: ID!
    $locationIds: [ID!]!
    $costPrice: Float
    $isActive: Boolean
  ) {
    updatePriceForMembershipLocation(
      membershipId: $membershipId
      locationIds: $locationIds
      costPrice: $costPrice
      isActive: $isActive
    )
  }
`;
export const UPDATE_PRICE_FOR_MEMBERSHIP_LOCATION = gql`
  mutation updatePriceForMembershipLocation(
    $membershipId: ID!
    $locationIds: [ID!]!
    $costPrice: Float
    $joiningFee: Float
    $fobFee: Float
    $activationFee: Float
  ) {
    updatePriceForMembershipLocation(
      joiningFee: $joiningFee
      fobFee: $fobFee
      activationFee: $activationFee
      membershipId: $membershipId
      locationIds: $locationIds
      costPrice: $costPrice
    )
  }
`;

export const UPDATE_NOTE = gql`
  mutation updateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      id
      note
    }
  }
`;

export const UPDATE_ISACTIVE_FOR_MEMBERSHIP_LOCATION = gql`
  mutation updatePriceForMembershipLocation(
    $membershipId: ID!
    $locationIds: [ID!]!
    $isActive: Boolean
  ) {
    updatePriceForMembershipLocation(
      membershipId: $membershipId
      locationIds: $locationIds
      isActive: $isActive
    )
  }
`;

export const UPDATE_DIRECT_DEBIT = gql`
  mutation updateDirectDebit(
    $billingId: ID!
    $updatedBy: ID!
    $reason: String!
    $description: String!
    $amount: Float!
  ) {
    updateDirectDebit(
      billingId: $billingId
      updatedBy: $updatedBy
      reason: $reason
      description: $description
      amount: $amount
    )
  }
`;

export const UPDATE_LOCATION_STUDIO = gql`
  mutation updateLocationStudio($input: UpdateLocationStudioInput!) {
    updateLocationStudio(input: $input) {
      id
    }
  }
`;

export const DELETE_LOCATION_STUDIO = gql`
  mutation deleteLocationStudio($input: DeleteLocationStudioInput!) {
    deleteLocationStudio(input: $input) {
      id
    }
  }
`;

export const CREATE_LOCATION_STUDIO = gql`
  mutation createLocationStudio($input: CreateLocationStudioInput!) {
    createLocationStudio(input: $input) {
      id
    }
  }
`;

export const CREATE_CLASS_INSTRUCTOR = gql`
  mutation createClassInstructor($input: CreateClassInstructorInput!) {
    createClassInstructor(input: $input) {
      id
    }
  }
`;

export const DELETE_CLASS_INSTRUCTOR = gql`
  mutation deleteClassInstructor($input: DeleteClassInstructorInput!) {
    deleteClassInstructor(input: $input) {
      id
    }
  }
`;

export const UPDATE_CLASS_INSTRUCTOR = gql`
  mutation updateClassInstructor($input: UpdateClassInstructorInput!) {
    updateClassInstructor(input: $input) {
      id
    }
  }
`;

export const UPDATE_MEMBERSHIP_AMOUNT = gql`
  mutation updateMembershipAmount(
    $memberContractId: ID!
    $updatedBy: ID!
    $reason: String!
    $description: String!
    $amountToUpdate: Float!
    $type: EVENT_FOR_MEMBERSHIP_UPDATE
  ) {
    updateMembershipAmount(
      memberContractId: $memberContractId
      updatedBy: $updatedBy
      reason: $reason
      description: $description
      amountToUpdate: $amountToUpdate
      type: $type
    )
  }
`;

export const SUSPEND_MEMBERSHIP = gql`
  mutation suspendMembership(
    $memberContractId: ID
    $suspendFrom: AWSDate
    $suspendTo: AWSDate
    $suspensionAmount: Float
    $suspensionReason: String
    $imageUrl: String
    $suspensionType: SUSPENSION_TYPE
    $operation: SUSPENSION_OPERATION!
    $suspensionEndDate: AWSDate
    $suspensionId: ID
  ) {
    suspendMembership(
      memberContractId: $memberContractId
      suspendFrom: $suspendFrom
      suspendTo: $suspendTo
      suspensionAmount: $suspensionAmount
      suspensionReason: $suspensionReason
      imageUrl: $imageUrl
      suspensionType: $suspensionType
      operation: $operation
      suspensionEndDate: $suspensionEndDate
      suspensionId: $suspensionId
    ) {
      statusCode
      data {
        message
      }
    }
  }
`;
export const UPFRONT_SUSPENSION = gql`
  mutation suspendMembership(
    $operation: SUSPENSION_OPERATION!
    $suspensionId: ID!
    $suspensionEndDate: AWSDate
  ) {
    suspendMembership(
      operation: $operation
      suspensionId: $suspensionId
      suspensionEndDate: $suspensionEndDate
    ) {
      statusCode
      data {
        amount
        message
      }
    }
  }
`;
export const UPFRONT_SUSPENSION_MEDICAL = gql`
  mutation suspendMedicalMembership(
    $operation: MEDICAL_SUSPENSION_OPERATION!
    $suspensionId: ID!
    $suspensionEndDate: AWSDate
  ) {
    suspendMedicalMembership(
      operation: $operation
      suspensionId: $suspensionId
      suspensionEndDate: $suspensionEndDate
    ) {
      statusCode
      data {
        amount
        message
      }
    }
  }
`;
export const ADD_STAFF_LOCATION_ACCESS = gql`
  mutation createStaffLocationAccess($input: CreateStaffLocationAccessInput!) {
    createStaffLocationAccess(input: $input) {
      id
    }
  }
`;

export const DELETE_STAFF_LOCATION_ACCESS = gql`
  mutation deleteStaffLocationAccess($input: DeleteStaffLocationAccessInput!) {
    deleteStaffLocationAccess(input: $input) {
      id
    }
  }
`;

export const ADD_STAFF_TO_GROUP = gql`
  mutation addStaffToGroup(
    $groupIds: [ID!]!
    $staffId: ID!
    $operation: ADD_STAFF_TO_GROUP!
  ) {
    addStaffToGroup(
      groupIds: $groupIds
      staffId: $staffId
      operation: $operation
    )
  }
`;
export const CREATE_GROUP = gql`
  mutation createGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      id
    }
  }
`;
export const UPDATE_GROUP = gql`
  mutation updateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      id
    }
  }
`;
export const DELETE_GROUP = gql`
  mutation deleteGroup($groupId: ID!, $operation: DELETE_GROUP!) {
    deleteGroup(groupId: $groupId, operation: $operation)
  }
`;
export const ADD_LOCATION_TO_GROUP = gql`
  mutation addLocationToGroup(
    $groupId: ID!
    $locationIds: [ID!]!
    $operation: ADD_LOCATION_TO_GROUP!
  ) {
    addLocationToGroup(
      groupId: $groupId
      locationIds: $locationIds
      operation: $operation
    )
  }
`;
export const CREATE_MEMBER_REQUEST = gql`
  mutation createMemberRequest(
    $memberId: ID!
    $requestedBy: ID!
    $requestType: REQUEST_TYPE!
  ) {
    createMemberRequest(
      memberId: $memberId
      requestedBy: $requestedBy
      requestType: $requestType
    )
  }
`;
export const DELETE_GROUP_LOCATION = gql`
  mutation deleteGroupLocation($input: DeleteGroupLocationInput!) {
    deleteGroupLocation(input: $input) {
      id
    }
  }
`;

export const DELETE_GROUP_STAFF = gql`
  mutation deleteGroupStaff($input: DeleteGroupStaffInput!) {
    deleteGroupStaff(input: $input) {
      id
    }
  }
`;

export const BLOCK_MEMBER = gql`
  mutation blockMember(
    $memberId: ID!
    $blockedBy: ID!
    $reasonsForBlocking: String!
    $blockTo: AWSDate
    $blockType: BLOCK_TYPE!
    $operation: EVENT_FOR_BLOCK!
  ) {
    blockMember(
      memberId: $memberId
      blockedBy: $blockedBy
      reasonsForBlocking: $reasonsForBlocking
      blockTo: $blockTo
      blockType: $blockType
      operation: $operation
    )
  }
`;

export const UNBLOCK_MEMBER = gql`
  mutation unblockMember(
    $memberId: ID!
    $unblockedBy: ID!
    $reasonsForUnblocking: String!
    $operation: EVENT_FOR_UNBLOCK!
  ) {
    unblockMember(
      memberId: $memberId
      unblockedBy: $unblockedBy
      reasonsForUnblocking: $reasonsForUnblocking
      operation: $operation
    )
  }
`;
export const UPFRONT_UNBLOCK_MEMBER = gql`
  mutation upfrontUnblockMember(
    $operation: EVENT_FOR_UPFRONT_UNBLOCK!
    $memberId: ID!
  ) {
    upfrontUnblockMember(operation: $operation, memberId: $memberId) {
      statusCode
      data {
        amount
        message
      }
    }
  }
`;

export const CANCEL_MEMBERSHIP = gql`
  mutation cancelMembership(
    $memberId: ID!
    $memberContractId: ID!
    $endDate: AWSDate!
    $updateBy: ID
    $cancelImmediately: Boolean
  ) {
    cancelMembership(
      memberId: $memberId
      memberContractId: $memberContractId
      endDate: $endDate
      updatedBy: $updateBy
      cancelImmediately: $cancelImmediately
    )
  }
`;

export const BOOK_CLASS = gql`
  mutation bookClass($memberId: ID!, $classSchedule: ID!) {
    bookClass(memberId: $memberId, classSchedule: $classSchedule) {
      message
      bookingId
    }
  }
`;

export const CANCEL_BOOKING = gql`
  mutation cancelBooking($bookingId: ID!) {
    cancelBooking(bookingId: $bookingId)
  }
`;

export const REVOKE_MEMBERSHIP_CANCELLATION = gql`
  mutation revokeMembershipCancellation(
    $memberId: ID!
    $memberContractId: ID!
    $operation: REVOKE_MEMBERSHIP_CANCELLATION_OPERATION!
  ) {
    revokeMembershipCancellation(
      memberId: $memberId
      memberContractId: $memberContractId
      operation: $operation
    ) {
      statusCode
      data {
        message
      }
    }
  }
`;
export const UPFRONT_REVOKE_MEMBERSHIP = gql`
  mutation revokeMembershipCancellation(
    $operation: REVOKE_MEMBERSHIP_CANCELLATION_OPERATION!
    $memberContractId: ID!
    $memberId: ID!
  ) {
    revokeMembershipCancellation(
      operation: $operation
      memberContractId: $memberContractId
      memberId: $memberId
    ) {
      statusCode
      data {
        amount
        message
      }
    }
  }
`;

export const ADD_CARD_TO_MEMBER = gql`
  mutation addCardToMember(
    $operationName: String!
    $cardNumber: String!
    $memberId: ID!
    $endDateTime: AWSDateTime
  ) {
    addCardToMember(
      operationName: $operationName
      cardNumber: $cardNumber
      memberId: $memberId
      endDateTime: $endDateTime
    ) {
      statusCode
      message
      data {
        cardNumber
      }
    }
  }
`;

export const UPDATE_CARD_OF_MEMBER = gql`
  mutation updateCardOfMember(
    $operationName: String!
    $id: ID!
    $cardNumber: String!
    $memberId: ID!
    $endDateTime: AWSDateTime
  ) {
    updateCardOfMember(
      operationName: $operationName
      id: $id
      cardNumber: $cardNumber
      memberId: $memberId
      endDateTime: $endDateTime
    ) {
      statusCode
      message
    }
  }
`;

export const REPORT_CARD_LOST = gql`
  mutation reportCardLost(
    $operationName: String!
    $id: ID!
    $isLost: Boolean!
    $lostReason: String
  ) {
    reportCardLost(
      operationName: $operationName
      id: $id
      isLost: $isLost
      lostReason: $lostReason
    ) {
      statusCode
      message
    }
  }
`;

export const CHANGE_MEMBERSHIP_FOR_MEMBER = gql`
  mutation changeMembership(
    $memberId: ID!
    $oldMemberContractId: ID!
    $newMembershipId: ID!
    $membershipLocationId: ID!
    $getInformation: Boolean!
  ) {
    changeMembership(
      memberId: $memberId
      oldMemberContractId: $oldMemberContractId
      newMembershipId: $newMembershipId
      membershipLocationId: $membershipLocationId
      getInformation: $getInformation
    ) {
      message
      newMemberContractId
      prorata
      downgradeFee
      debitCost
      upfrontPaymentAmount
      firstDDDate
      cancellationFee
    }
  }
`;

export const UPDATE_STAFF_ACCESS = gql`
  mutation UpdateStaffAccess(
    $email: AWSEmail!
    $operation: STAFF_UPDATE_OPERATION
  ) {
    updateStaffAccess(email: $email, operation: $operation)
  }
`;

export const ADD_MEMBER = gql`
  mutation addMember($input: AddMember!) {
    addMember(input: $input)
  }
`;
export const CREATE_INSTRUCTOR_CREDENTIAL = gql`
  mutation createInstructorCredential(
    $input: CreateInstructorCredentialInput!
  ) {
    createInstructorCredential(input: $input) {
      credentialType
      id
      imageUrl
      isDeleted
      credentialName
    }
  }
`;

export const DELETE_INSTRUCTOR_CREDENTIAL = gql`
  mutation deleteInstructorCredential(
    $input: DeleteInstructorCredentialInput!
  ) {
    deleteInstructorCredential(input: $input) {
      id
    }
  }
`;
export const SMS_SENDER = gql`
  mutation smsSender(
    $type: String!
    $originNumber: String!
    $message: String!
    $locationIdFilter: ID
    $filter: String
  ) {
    smsSender(
      type: $type
      originNumber: $originNumber
      message: $message
      locationIdFilter: $locationIdFilter
      filter: $filter
    )
  }
`;

export const SMS_SINGLE_SMS = gql`
  mutation smsSingleSender(
    $type: String!
    $destinationNumber: AWSPhone!
    $locationId: ID!
    $message: String!
    $memberId: ID!
    $reason: String!
  ) {
    smsSingleSender(
      type: $type
      destinationNumber: $destinationNumber
      locationId: $locationId
      message: $message
      memberId: $memberId
      reason: $reason
    )
  }
`;

export const EDIT_HEALTHQUESTION = gql`
  mutation updateHealthQuestionnaire($input: UpdateHealthQuestionnaireInput!) {
    updateHealthQuestionnaire(input: $input) {
      id
      isActive
      isDeleted
    }
  }
`;

export const CREATE_HEALTHQUESTION = gql`
  mutation createHealthQuestionnaire($input: CreateHealthQuestionnaireInput!) {
    createHealthQuestionnaire(input: $input) {
      id
    }
  }
`;

export const UPLOAD_REJECTION = gql`
  mutation createRejectionProcess($input: CreateRejectionProcessInput!) {
    createRejectionProcess(input: $input) {
      fileName
      isProcessed
      s3Key
    }
  }
`;
export const PROCESS_REJECTION = gql`
  mutation processRejectionFile($id: String!, $type: String!) {
    processRejectionFile(id: $id, type: $type) {
      data
      statusCode
    }
  }
`;
export const DELETE_REJECTION = gql`
  mutation processRejectionFile($id: String!, $type: String!) {
    processRejectionFile(id: $id, type: $type) {
      data
      statusCode
    }
  }
`;

export const SUSPEND_MEMBERSHIP_MEDICAL = gql`
  mutation suspendMedicalMembership(
    $memberContractId: ID
    $suspensionId: ID
    $suspendFrom: AWSDate
    $suspendTo: AWSDate
    $suspensionReason: String
    $imageUrl: String
    $operation: MEDICAL_SUSPENSION_OPERATION!
    $suspensionEndDate: AWSDate
  ) {
    suspendMedicalMembership(
      suspensionId: $suspensionId
      memberContractId: $memberContractId
      suspendFrom: $suspendFrom
      suspendTo: $suspendTo
      suspensionReason: $suspensionReason
      imageUrl: $imageUrl
      operation: $operation
      suspensionEndDate: $suspensionEndDate
    ) {
      statusCode
      data {
        message
      }
    }
  }
`;

export const MEDICAL_SUSPENSION_OPERATION = gql`
  mutation suspendMedicalMembership(
    $operation: MEDICAL_SUSPENSION_OPERATION!
    $suspensionId: ID!
    $suspensionStatus: SUSPENSION_STATUS
  ) {
    suspendMedicalMembership(
      operation: $operation
      suspensionId: $suspensionId
      suspensionStatus: $suspensionStatus
    ) {
      statusCode
      data {
        amount
        message
      }
    }
  }
`;

export const CHARGE_SINGLE_PAYMENT = gql`
  mutation chargeSinglePaymentViaVivaPay(
    $brandId: ID!
    $amount: Float!
    $memberId: ID!
    $futurePaymentOption: FUTURE_PAYMENT_OPTION
    $operation: TRANSACTION_TYPE_MAPPING!
    $paymentInformationId: ID
    $suspensionArguments: SuspensionArgumentsInput
    $changeMembershipArgumentsInput: ChangeMembershipArgumentsInput
    $bankAccountDetails: BankAccountInput
    $unblockMemberArguments: UnblockMemberArgumentsInput
    $applyVoucherMemberArguments: ApplyVoucherMemberArguments
    $addMembershipArguments: AddMembershipArguments
    $outstandingBalanceArguments: OutstandingBalanceArgumentsInput
    $cancelMembershipArguments: CancelMembershipArguments
    $currentPaymentOption: FUTURE_PAYMENT_OPTION
    $renewMembershipArguments: RenewMembershipArgumentsInput
    $membershipActivationArguments: MembershipActivationArgumentsInput
  ) {
    chargeSinglePaymentViaVivaPay(
      brandId: $brandId
      amount: $amount
      memberId: $memberId
      futurePaymentOption: $futurePaymentOption
      operation: $operation
      paymentInformationId: $paymentInformationId
      suspensionArguments: $suspensionArguments
      changeMembershipArguments: $changeMembershipArgumentsInput
      bankAccountDetails: $bankAccountDetails
      unblockMemberArguments: $unblockMemberArguments
      applyVoucherMemberArguments: $applyVoucherMemberArguments
      addMembershipArguments: $addMembershipArguments
      outstandingBalanceArguments: $outstandingBalanceArguments
      cancelMembershipArguments: $cancelMembershipArguments
      currentPaymentOption: $currentPaymentOption
      renewMembershipArguments: $renewMembershipArguments
      membershipActivationArguments: $membershipActivationArguments
    ) {
      statusCode
      message
    }
  }
`;
export const UPDATE_DEBT_COLLECTION = gql`
  mutation updateDebtCollection($input: UpdateDebtCollectionInput!) {
    updateDebtCollection(input: $input) {
      debtStatus
    }
  }
`;
export const ADD_MEMBER_PAYMENT_INFORMATION = gql`
  mutation addMemberPaymentInformation(
    $input: AddMemberPaymentInformationRequest!
  ) {
    addMemberPaymentInformation(input: $input) {
      cardRequestId
      paymentInformationId
      cardNumber
      cardType
      cardHolderName
      cardExpiryDate
      statusCode
      message
      error
    }
  }
`;

export const APPROVE_UNDER_18_MEMBER = gql`
  mutation approveUnder18Member(
    $memberId: ID!
    $operationName: String!
    $memberContractId: ID!
    $paymentInformationId: String
  ) {
    approveUnder18Member(
      memberId: $memberId
      operationName: $operationName
      paymentInformationId: $paymentInformationId
      memberContractId: $memberContractId
    )
  }
`;
export const CREATE_VOUCHER = gql`
  mutation createVoucher($input: CreateVoucherInput!) {
    createVoucher(input: $input) {
      id
    }
  }
`;
export const UPDATE_VOUCHER = gql`
  mutation updateVoucher($input: UpdateVoucherInput!) {
    updateVoucher(input: $input) {
      id
    }
  }
`;
export const DELETE_VOUCHER = gql`
  mutation deleteVoucher($input: DeleteVoucherInput!) {
    deleteVoucher(input: $input) {
      id
    }
  }
`;
export const SEND_VOUCHER = gql`
  mutation sendVoucher($input: SendVoucherRequest) {
    sendVoucher(input: $input)
  }
`;

export const ADD_LOCATION_TO_VOUCHER = gql`
  mutation addLocationsToVoucher($input: ApplyVoucherRequest) {
    addLocationsToVoucher(input: $input)
  }
`;

export const UPDATE_PAYMENT_INFO = gql`
  mutation updatePaymentInformation(
    $memberId: ID!
    $action: String!
    $bankAccount: UpdateBankDetailInput
    $creditCard: UpdateCardDetailInput
  ) {
    updatePaymentInformation(
      memberId: $memberId
      action: $action
      bankAccount: $bankAccount
      creditCard: $creditCard
    ) {
      body {
        accountName
        accountNumber
        cardExpiryDate
        cardNumber
        cardType
        message
        paymentInformationId
        bsb
        cardHolderName
      }
      message
      cardRequestId
    }
  }
`;

export const UPDATE_EMAIL_OR_SEND_INVITE = gql`
  mutation updateEmailOrSendInvite($input: UpdateEmailOrSendInvite!) {
    updateEmailOrSendInvite(input: $input) {
      statusCode
      message
    }
  }
`;

export const SIGNUP_MEMBER_UNDER_18 = gql`
  mutation signupMemberViaWebUnder18(
    $input: SignupMemberViaWebUnder18Request!
  ) {
    signupMemberViaWebUnder18(input: $input) {
      memberId
      message
      error
    }
  }
`;
export const UPDATE_CONSENT_FORM = gql`
  mutation updateConsentFormDetail($input: UpdateConsentFormDetailInput!) {
    updateConsentFormDetail(input: $input) {
      id
      consentFormInviteTokenExpiry
    }
  }
`;
export const SEND_OTP_CONFIRM_EMAIL = gql`
  mutation updateEmailOrSendInvite($input: UpdateEmailOrSendInvite!) {
    updateEmailOrSendInvite(input: $input) {
      message
      statusCode
    }
  }
`;
export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      id
    }
  }
`;
export const CREATE_ACTION_NOTIFICATION = gql`
  mutation createActionNotification($input: CreateActionNotificationInput!) {
    createActionNotification(input: $input) {
      id
      notificationId
      memberId
      action
    }
  }
`;
export const CALCULATE_VOUCHER_AMOUNT = gql`
  mutation calculateVoucherAmount($input: CalculateVoucherAmountRequest) {
    calculateVoucherAmount(input: $input) {
      statusCode
      data {
        totalFee
        discountValue
      }
    }
  }
`;

export const UPDATE_FITNESSPASSPORT = gql`
  mutation updateFitnessPassport(
    $memberContractId: ID!
    $FitnessPassportNumber: String!
  ) {
    updateFitnessPassport(
      memberContractId: $memberContractId
      FitnessPassportNumber: $FitnessPassportNumber
    )
  }
`;

export const REFUND = gql`
  mutation refundTransaction(
    $transactionId: ID
    $refundType: String!
    $billingId: ID
    $reason: String
  ) {
    refundTransaction(
      transactionId: $transactionId
      refundType: $refundType
      billingId: $billingId
      reason: $reason
    ) {
      refundAmount
    }
  }
`;
export const PRE_SIGNUP_UNDER_18 = gql`
  mutation signupMemberViaWebPreparePaymentUnder18(
    $input: SignupMemberViaWebPreparePaymentUnder18Request!
  ) {
    signupMemberViaWebPreparePaymentUnder18(input: $input) {
      memberId
      tokenId
      prospectId
      message
    }
  }
`;
export const PRE_SINGUP_OVER_18 = gql`
  mutation signupMemberViaWebPreparePaymentOver18(
    $input: SignupMemberViaWebPreparePaymentRequest!
  ) {
    signupMemberViaWebPreparePaymentOver18(input: $input) {
      memberId
      tokenId
      prospectId
      message
    }
  }
`;
export const SINGUP_MEMBER_OVER_18 = gql`
  mutation SignupMemberViaWebOver18($input: SignupMemberViaWebRequest!) {
    signupMemberViaWebOver18(input: $input) {
      memberId
      message
    }
  }
`;
export const SINGUP_MEMBER_UNDER_18 = gql`
  mutation signupMemberViaWebUnder18(
    $input: SignupMemberViaWebUnder18Request!
  ) {
    signupMemberViaWebUnder18(input: $input) {
      memberId
      message
      error
    }
  }
`;
export const ADD_PAYMENT_INFORMATION = gql`
  mutation addMemberPaymentInformation(
    $input: AddMemberPaymentInformationRequest!
  ) {
    addMemberPaymentInformation(input: $input) {
      cardRequestId
      paymentInformationId
      cardNumber
      cardType
      cardHolderName
      cardExpiryDate
      statusCode
      message
      error
    }
  }
`;
export const UPDATE_PAYMENT_INFORMATION = gql`
  mutation updatePaymentInformation(
    $memberId: ID!
    $action: String!
    $bankAccount: UpdateBankDetailInput
    $creditCard: UpdateCardDetailInput
    $isDeleteOldPayment: Boolean
    $requestApplication: String
  ) {
    updatePaymentInformation(
      memberId: $memberId
      action: $action
      bankAccount: $bankAccount
      creditCard: $creditCard
      isDeleteOldPayment: $isDeleteOldPayment
      requestApplication: $requestApplication
    ) {
      body {
        accountName
        accountNumber
        cardExpiryDate
        cardNumber
        cardType
        message
        paymentInformationId
        bsb
        cardHolderName
      }
      message
      cardRequestId
    }
  }
`;
export const GET_STATUS_PAYMENT_UNDER_18 = gql`
  mutation getStatusPaymentUnder18($memberId: ID!, $operationName: String!) {
    getStatusPaymentUnder18(
      memberId: $memberId
      operationName: $operationName
    ) {
      message
      isPaid
      futurePaymentInformationId
      paymentType
    }
  }
`;
export const NOTIFICATION_ACTION = gql`
  mutation notificationActions($input: NotificationActionRequest!) {
    notificationActions(input: $input) {
      body
      statusCode
      message
    }
  }
`;
export const RENEW_MEMBERSHIP = gql`
  mutation renewMembership(
    $amount: Float!
    $memberId: ID!
    $membershipId: ID!
    $locationId: ID!
    $membershipLocationId: ID!
    $paymentType: PAYMENTMETHODTYPE!
    $firstPaymentInformationId: ID
    $nextPaymentInformationId: ID
    $nextPaymentType: PAYMENTMETHODTYPE!
    $membershipAmount: Float
    $joiningFee: Float
    $activationFee: Float
    $fobFee: Float
    $membershipStartDate: AWSDateTime
  ) {
    renewMembership(
      amount: $amount
      memberId: $memberId
      membershipId: $membershipId
      locationId: $locationId
      membershipLocationId: $membershipLocationId
      paymentType: $paymentType
      firstPaymentInformationId: $firstPaymentInformationId
      nextPaymentInformationId: $nextPaymentInformationId
      nextPaymentType: $nextPaymentType
      membershipAmount: $membershipAmount
      joiningFee: $joiningFee
      activationFee: $activationFee
      fobFee: $fobFee
      membershipStartDate: $membershipStartDate
    ) {
      message
      statusCode
    }
  }
`;
export const REFUND_TRANSACTION = gql`
  mutation modifyRefundTransaction(
    $refundId: ID
    $operation: String
    $status: REFUND_STATUS
    $reason: String
  ) {
    modifyRefundTransaction(
      refundId: $refundId
      operation: $operation
      status: $status
      reason: $reason
    )
  }
`;
export const CREATE_MAINTENANCE = gql`
  mutation createLocationMaintenance($input: CreateLocationMaintenanceInput!) {
    createLocationMaintenance(input: $input) {
      locationId
      createdAt
      createdBy
    }
  }
`;
export const UPDATE_MAINTENANCE = gql`
  mutation updateLocationMaintenance($input: UpdateLocationMaintenanceInput!) {
    updateLocationMaintenance(input: $input) {
      id
      locationId
      startDateTime
      endDateTime
      updatedAt
    }
  }
`;
export const DEBT_COLLECTION_ACTIONS = gql`
  mutation debtActions($input: DebtActionRequest!) {
    debtActions(input: $input) {
      body
      statusCode
      message
    }
  }
`;

export const CRATE_MEMBER_YEARLY_INVOICE = gql`
  mutation createMemberInvoiceYearly(
    $operationName: String!
    $memberIds: [ID]!
    $dateFrom: AWSDate!
    $dateTo: AWSDate!
  ) {
    createMemberInvoiceYearly(
      operationName: $operationName
      memberIds: $memberIds
      dateFrom: $dateFrom
      dateTo: $dateTo
    )
  }
`;

export const SEND_SETTLEMENT_REPORT = gql`
  mutation sendSettlementReportEmail($input: SendSettlementReportEmailInput!) {
    sendSettlementReportEmail(input: $input) {
      message
      statusCode
    }
  }
`;

export const UPDATE_OUTSTANDING_AMOUNT = gql`
  mutation updateOutstandingAmount(
    $memberId: String
    $amount: Float!
    $reason: String
    $operationName: String!
  ) {
    updateOutstandingAmount(
      memberId: $memberId
      amount: $amount
      reason: $reason
      operationName: $operationName
    )
  }
`;
export const GENERATE_ABA_FILE = gql`
  mutation generateABA(
    $action: String!
    $brandId: String!
    $endDate: AWSDate
    $batchBrandId: String
    $refundIds: [ID!]
  ) {
    generateABA(
      action: $action
      brandId: $brandId
      endDate: $endDate
      batchBrandId: $batchBrandId
      refundIds: $refundIds
    ) {
      statusCode
      message
      url
    }
  }
`;

export const UPDATE_FITNESS_PASSPORT = gql`
  mutation updateFitnessPassport(
    $locationId: ID
    $memberContractId: ID
    $FitnessPassportNumber: String!
  ) {
    updateFitnessPassport(
      locationId: $locationId
      memberContractId: $memberContractId
      FitnessPassportNumber: $FitnessPassportNumber
    )
  }
`;

export const SEND_SLACK_MESSAGE = gql`
  mutation sendSlackMessage(
    $operationName: String!
    $destination: String!
    $message: String!
  ) {
    sendSlackMessage(
      operationName: $operationName
      destination: $destination
      message: $message
    )
  }
`;
