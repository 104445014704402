import "./toggle.scss";

const Toggle = (props) => {
  return (
    <div className="toggle">
      <label className="switch">
        <input
          type="checkbox"
          id={props.label}
          name={props.name}
          value={props.value}
          checked={props.checked}
          onChange={props.onChange}
          disabled={props.disabled}
          readOnly={props.readOnly}
        />

        <span className="slider round"></span>
      </label>
      <label htmlFor={props.label} className={props.className}>
        {" "}
        {props.label}{" "}
      </label>
    </div>
  );
};

export default Toggle;
