import React, { useEffect, useState } from "react";
import "./switch.css";
function Switch(props) {
  const [value, setValue] = useState(props.value ?? null);
  useEffect(() => {
    props && props.onChange && props?.onChange(value);
  }, [value]);
  return (
    <div className="switch-container">
      <div onClick={() => setValue(true)} className={`${value && "active"}`}>
        YES
      </div>
      <div
        onClick={() => setValue(false)}
        className={`${value === false && "active"}`}
      >
        NO
      </div>
    </div>
  );
}

export default Switch;
