const getMembershipTypeAndAmount = (membership) => {
  if (!membership.recurring) {
    return `$${membership.costPrice} (PIF)`;
  } else {
    const paymentFrequencyType =
      (membership.paymentFrequency.type === "WEEKS" && "WEEKLY") ||
      (membership.paymentFrequency.type === "MONTHS" && "MONTHLY")||(
        membership.paymentFrequency.type === "YEARS" && "YEARLY"
      );
    return `$${(
      membership.costPrice / membership.paymentFrequency.amount
    ).toFixed(2)} (${paymentFrequencyType})`;
  }
};

const getPaymentFrequency = (membership) => {
  if (
    membership.paymentFrequency.type === "WEEKS" &&
    membership.paymentFrequency.amount === 2
  )
    return "Fortnightly";
  if (
    membership.paymentFrequency.type === "WEEKS" &&
    membership.paymentFrequency.amount === 1
  )
    return "Weekly";
  if (
    membership.paymentFrequency.type === "MONTHS" &&
    membership.paymentFrequency.amount === 1
  )
    return "Monthly";
  if (
    membership.paymentFrequency.type === "MONTHS" &&
    membership.paymentFrequency.amount === 3
  )
    return "Every 3 Months";

  if (
    membership.paymentFrequency.type === "YEARS" &&
    membership.paymentFrequency.amount === 1
  )
    return "Yearly";
};

module.exports = {
  getMembershipTypeAndAmount,
  getPaymentFrequency,
};
