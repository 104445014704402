import React, { useState } from "react";
import { useMemberContext } from "../../contexts/MemberContext";
import { BeatLoader } from "react-spinners";
import "./viewHealthQuestionnaire.scss";
import { useParams } from "react-router-dom";
import { GET_HEALTHQUESTIONNAIRE_BY_ID } from "../../gqloperations/queries";
import { useQuery } from "@apollo/client";
import { Toggle, Header } from "../../components";

function ViewHealthQuestionnaire() {
  const { sidebarBrandName } = useMemberContext();
  const { id } = useParams();
  const [questionnaire, setQuestionnaire] = useState();

  const { loading, error } = useQuery(GET_HEALTHQUESTIONNAIRE_BY_ID, {
    variables: {
      id,
    },
    onCompleted: (data) => {
      setQuestionnaire(data.getHealthQuestionnaire);
    },
  });

  if (loading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );

  if (error) {
    return (
      <div>
        <div>Problem in getting health question</div>
      </div>
    );
  }
  return (
    <div>
      <Header pageTitle="View Question" />
      <div className="view-question-page main mt-32 pd-18 mb-12">
        <div className="view-card mb-24">
          <div className="question-title-details">
            <div className="inputs">
              <div>
                <h3 className="fs-14">HEALTH QUESTION</h3>
              </div>
              <div>
                <h3 className="fs-12 text-gray">Brand</h3>
                <p className="fs-12">{sidebarBrandName}</p>
              </div>
              <div>
                <h3 className="fs-12 text-gray">Section Label:</h3>
                <p className="fs-12">{questionnaire?.sectionLabel}</p>
              </div>
              <div>
                <h3 className="fs-12 text-gray">Answer Type:</h3>
                <p className="fs-12">{questionnaire?.answerType}</p>
              </div>
              {/* <ToggleFormik
                  label="Active"
                  name="isActive"
                  className="fs-10"
                />{" "}
                <ToggleFormik
                  label="Mandatory"
                  name="mandatory"
                  className="fs-10"
                /> */}
              <div>
                <Toggle
                  id="isActive"
                  name="isActive"
                  label="is Active"
                  value={questionnaire?.isActive}
                  checked={questionnaire?.isActive}
                />
                <Toggle
                  id="mandatory"
                  name="Mandatory"
                  label="Mandatory"
                  value={questionnaire?.mandatory}
                  checked={questionnaire?.mandatory}
                />
              </div>{" "}
              <div className="desc-row">
                <h3 className="fs-12 text-gray">Question *</h3>
                <textarea
                  disabled
                  className="reason-box fs-12"
                  id="question"
                  name="question"
                  value={questionnaire?.question}
                ></textarea>
              </div>
              <div className="desc-row">
                <h3 className="fs-12 text-gray">Expected Answer *</h3>
                <textarea
                  disabled
                  className="reason-box fs-12"
                  id="expectedAnswer"
                  name="expectedAnswer"
                  value={
                    questionnaire?.expectedAnswer === "other" ||
                    questionnaire?.expectedAnswer === null ||
                    questionnaire?.expectedAnswer === ""
                      ? "other"
                      : questionnaire?.expectedAnswer
                  }
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewHealthQuestionnaire;
