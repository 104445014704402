import "./guestEntryLog.scss";
import { Link } from "react-router-dom";
import { Header } from "../../components";

const AccessLogs = () => {
  return (
    <>
      <div className="access-logs-container">
        <Header pageTitle="Access Logs" />
        <div className="log-type-button-container mt-32">
          <Link className="log-card fs-35" to={"/dashboard/accesslogs/memberaccesslogs"}>
            {" "}
            MEMBER ACCESS
          </Link>
          <Link className="log-card fs-35" to={"/dashboard/accesslogs/guestentrylogs"}>
            {" "}
            GUEST ENTRY
          </Link>
        </div>
      </div>
    </>
  );
};

export default AccessLogs;
