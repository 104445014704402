/* eslint-disable no-lone-blocks */
import "./classes.scss";
import React, { useMemo, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { UPDATE_CLASS_SCHEDULE } from "../../gqloperations/mutations";
import { GET_CLASSSCHEDULE_BY_LOCATIONID } from "../../gqloperations/queries";
import { AiOutlineSearch } from "react-icons/ai";
import { useQuery, useMutation } from "@apollo/client";
import { format, addDays } from "date-fns";
import { useClassContext } from "../../contexts/ClassContext";
import { useMemberContext } from "../../contexts/MemberContext";
import { useEffect } from "react";
import { EDIT_TABLE_DATA } from "../../actions";
import { toast } from "react-toastify";
import { FaRegCalendarCheck } from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { utcToZonedTime } from "date-fns-tz";
import { useHandleError } from '../../hooks/useHandleError';
import {
  Filter,
  Header,
  EXCELExport,
  CSVExport,
  StatusModal,
  Modal,
  ClassStats,
} from "../../components";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";


const classTimeFromUTCtoAusTimeZone = (curr, timeZone) => {
  const getTimezoneOffset = (timeZone, date = new Date()) => {
    const tz = date.toLocaleString("en", {timeZone, timeStyle: "long"}).split(" ").slice(-1)[0];
    const dateString = date.toString();
    const offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);
    
    // return UTC offset in hours
    return offset  / (1000 * 3600);
  }

  const offset = getTimezoneOffset(timeZone, new Date(curr.createdAt))

  const convertToFixedAEST = (utcDate, offset) => {
    const date = new Date(utcDate)
    const fixedOffsetDate = new Date(date.getTime() + offset * 60 * 60 * 1000).toISOString();
    return fixedOffsetDate.substring(fixedOffsetDate.indexOf("T") + 1, fixedOffsetDate.indexOf("T") + 6)
  };

  const startTime = convertToFixedAEST(curr.classScheduleDateTime, offset)
  const endTime = convertToFixedAEST(new Date(new Date(curr.classScheduleDateTime).getTime()+ curr.classTemplateDetails.classDuration * 60000).toUTCString(), offset)

  return `${startTime}-${endTime}`;
};

const Classes = () => {
  let navigate = useNavigate();
  const { sidebarLocationId, sidebarBrandId, createLog } = useMemberContext();
  const { permissions } = useAuthContext();
  const [filter, setFilter] = useState(false);
  const [tableData, setTableData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [filterVal, setFilterVal] = useState("");
  const [exportData, setExportData] = useState();
  const handleError = useHandleError();
  const { dispatch, editTableData, clearClassTemplateData } = useClassContext();
  const [componentName, setComponentName] = useState("");
  const [classTimezone, setClassTimezone] = useState("Australia/Sydney");
  const [curRow, setCurRow] = useState({
    id: "",
    className: "",
    isActive: false,
  });
  const { sidebarLocationId: locationId } = useMemberContext();
  const headerOfExport = [
    "TIME",
    "CLASS",
    "INSTRUCTOR",
    "LOCATION",
    "OCCURRENCE",
    "ATTENDEES",
    "",
    "",
    "",
  ];
  const dateFilter = useMemo(() => {
    const startDate = format(
      utcToZonedTime(new Date(), "UTC"),
      "yyyy-MM-dd'T'HH:mm:ss'Z'",
      { timeZone: "UTC" }
    );
    const endDate = format(
      utcToZonedTime(addDays(new Date(), 14), "UTC"),
      "yyyy-MM-dd'T'HH:mm:ss'Z'",
      { timeZone: "UTC" }
    );
    return { startDate, endDate };
  }, []);
  const {
    data: getclassschedulebyiddata,
    loading: getClassScheduleByIdDataLoading,
    error: getClassScheduleByIdDataError,
    refetch: getclassschedulebyidrefetch,
  } = useQuery(GET_CLASSSCHEDULE_BY_LOCATIONID, {
    variables: {
      locationId: `${locationId}`,
      classScheduleDateTime: {
        ge: dateFilter.startDate,
      },
      limit: 1000,
      sortDirection: "ASC",
    },
    onCompleted: (data) => {
      setClassTimezone(
        data?.getClassScheduleByLocationId?.items[0]?.locationDetails
          ?.timezone ?? "Australia/Sydney"
      );
    },
  });



  const handleComponent = (componentname, rowObj) => {
    setComponentName(componentname);
    setCurRow(rowObj);
  };
  const modalClose = () => {
    setComponentName("");
    setCurRow({ id: "", isActive: false });
  };

  const handleBookingClick = (scheduledClass) => {
    scheduledClass.isBookable &&
      navigate(`/dashboard/classes/bookingclass/${scheduledClass.id}`);
  };
  const handleEditClick = (scheduledClassId) => {
    navigate(`/dashboard/classes/editClass/${scheduledClassId}`);
  };

  const handleDeleteClick = (rowObj) => {
    let values = {
      id: rowObj.id,
      isActive: false,
    };
    updateClassSchedule({
      variables: {
        input: values,
      },
      onCompleted: () => {
        toast.success("Updated successfully");
        modalClose();
        getclassschedulebyidrefetch();
      },
      onError: (error) => {
        handleError(`${error}`);
      },
    });
  };

  //calling a mutation
  const [updateClassSchedule] = useMutation(UPDATE_CLASS_SCHEDULE);

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const closeFilter = () => {
    setFilter(false);
  };
  const convertUTCtoAusTimezone = (utcDate, timeZone) => {
    const options = {
      timeZone,
      weekday: "long",
      day: "numeric",
      month: "short",
      year: "numeric",
    };

    const localDate = new Date(utcDate).toLocaleString("en-AU", options);
    return localDate;
  };
  const groupByDate = (arr) => {
    return arr.reduce((accObj, curItem) => {
      const localisedDate = convertUTCtoAusTimezone(
        curItem?.classScheduleDateTime,
        classTimezone
      );

      if (accObj[localisedDate]) {
        accObj[localisedDate].push(curItem);
      } else {
        accObj[localisedDate] = [curItem];
      }
      return accObj;
    }, {});
  };

  const notCancelledBookinItems = (arr) => {
    return arr.filter((cur) => !cur.cancelledDateTime);
  };

  const exportFormatter = (obj) => {
    const formattedExportArr = Object.keys(obj).map((curDate) => {
      const isToday =
        convertUTCtoAusTimezone(new Date(), classTimezone) === curDate;

      // Create a string with "today" if curDate === today, and the curDate day number and month
      const dateHeaderText = isToday ? `TODAY, ${curDate}` : curDate;
      // Create row with just the current day and month
      const dateHeaderRow = [dateHeaderText, "", "", "", "", "", "", ""];
      // Create rows with class times that belong to current day
      const dateRows = obj[curDate].map((cur) => {
        const classTime = classTimeFromUTCtoAusTimeZone(cur, classTimezone);
        const occurrence = cur?.isThisSeries ? "Series" : "Single";

        const notCancelledItems = notCancelledBookinItems(cur?.bookings.items);
        const attendees = `${notCancelledItems?.length}/${cur?.maxBooking}`;

        return [
          classTime,
          cur.classTemplateDetails?.className,
          `${cur.instructorDetails?.givenName ?? ""} ${
            cur.instructorDetails?.surname ?? ""
          }`,
          cur.studioDetails?.studioName,
          occurrence,
          attendees,
          "",
          "",
          "",
        ];
      });
      return [dateHeaderRow, ...dateRows];
    });
    // flatten the nested arrays
    return formattedExportArr.flat();
  };

  useEffect(() => {
    createLog("CLICKED Classes tab on the sidebar", "sidebar");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (locationId) {
      getclassschedulebyidrefetch();
    }
  }, [locationId, getclassschedulebyidrefetch]);

  useEffect(() => {
    if (getclassschedulebyiddata) {
      dispatch({
        type: EDIT_TABLE_DATA,
        payload: getclassschedulebyiddata,
      });
    }
  }, [getclassschedulebyiddata, dispatch]);

  const onSubmit = (event) => {
    event.preventDefault();
    setFilterVal(searchValue.toUpperCase());
  };

  useEffect(() => {
    if (editTableData?.getClassScheduleByLocationId?.items?.length > 0) {
      let finalData = editTableData?.getClassScheduleByLocationId?.items.filter(
        (item) => item.isActive !== false
      );

      if (filterVal?.length > 0) {
        finalData = finalData.filter((cur) => {
          return cur?.classTemplateDetails?.className.includes(filterVal);
        });
      }

      const dataGroupedByDate = groupByDate(finalData);
      setExportData(exportFormatter(dataGroupedByDate));
      setTableData(dataGroupedByDate);
    } else {
      setTableData("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editTableData, filterVal]);

  if (!locationId) {
    return (
      <div className="no-data-holder">
        <div className="no-data">
          <h3 className="fs-12 uppercase">
            Please Choose a Club Location First
          </h3>
        </div>
      </div>
    );
  }
  if (getClassScheduleByIdDataLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (getClassScheduleByIdDataError) {
    return (
      <div>
        <div>Error: Problem getting classes</div>
      </div>
    );
  }

  return (
    <>
      <div>
        <Header pageTitle="Classes List" />
        <div className="class">
          <div className="class-container template">
            <div className="class-info">
              <ClassStats />
              <div className="row2 actions-row ">
                {/* <div className="fs-35">Scheduled Classes</div> */}
                <div className="btn-group">
                  {exportData && (
                    <React.Fragment>
                      {/* <CopyToClipboard data={[headerOfExport, ...exportData]} /> */}
                      <EXCELExport
                        data={[headerOfExport, ...exportData]}
                        fileName="MyExcel.xlsx"
                        sheetName="MySheet1"
                      />
                      <CSVExport
                        data={[headerOfExport, ...exportData]}
                        fileName="MyCSV.csv"
                      />
                      {/* <PDFExportPrint
                    header={headerOfExport}
                    body={exportData}
                    fileName="MyPDF"
                  /> */}
                    </React.Fragment>
                  )}
                </div>
                <div className="left-elements ">
                  <div className="search-container">
                    <form onSubmit={onSubmit}>
                      <div className="search-bar">
                        <div className="search-bar-container ">
                          <input
                            className="fs-10"
                            type="text"
                            name="search"
                            id="search"
                            value={searchValue}
                            onChange={handleChange}
                            placeholder="CLASS NAME"
                          />
                          <button type="submit">
                            <AiOutlineSearch />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* // <div
                  //   className="right-btn"
                  //   onClick={() => clearClassTemplateData()}
                  // >
                  //   <Link
                  //     to="/dashboard/classes/addClass"
                  //     className="btn btn-primary btn-lg fs-12" */}
                  {permissions.includes("CanAddClass") && (
                    <div
                      className="right-btn"
                      onClick={() => clearClassTemplateData()}
                    >
                      <Link
                        to="/dashboard/classes/addClass"
                        className="btn btn-primary btn-lg fs-12"
                      >
                        Add Class
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="table">
              <table>
                <thead>
                  <tr className="table-header fs-14">
                    <th>
                      <div className="sort">
                        <span>Time</span>
                      </div>
                    </th>
                    <th>
                      <div className="icon">
                        <span>Class</span>
                      </div>

                      {filter && (
                        <div className="class-filter">
                          <Filter closefilter={closeFilter} />
                        </div>
                      )}
                    </th>
                    <th>
                      <div className="icon">
                        <span>Instructor</span>
                      </div>
                    </th>
                    <th>
                      <div>
                        <span>Location</span>
                      </div>
                    </th>
                    <th>
                      <div>
                        <span>Occurrence</span>
                      </div>
                    </th>
                    <th>
                      <div>
                        <span>Attendees</span>
                      </div>
                    </th>
                    <th></th>
                  </tr>
                </thead>

                {tableData && Object.keys(tableData).length > 0 ? (
                  <tbody>
                    {Object.keys(tableData).map((curDate, _index) => {
                      const isToday =
                        convertUTCtoAusTimezone(new Date(), classTimezone) ===
                        curDate;

                      // Create a string with "today" if curDate === today, and the curDate day number and month
                      const dateHeaderText = isToday
                        ? `TODAY, ${curDate}`
                        : curDate;
                      return (
                        <React.Fragment key={curDate}>
                          <tr>
                            <th
                              className="date-header text-gray fs-14"
                              colSpan="7"
                            >
                              {dateHeaderText}
                            </th>
                          </tr>

                          {tableData[curDate].map((cur, index) => {
                            return (
                              <tr
                                className="fs-12"
                                key={index}
                                onDoubleClick={() => handleEditClick(cur.id)}
                              >
                                <td className="center">
                                  {classTimeFromUTCtoAusTimeZone(
                                    cur,
                                    cur?.locationDetails?.timezone ??
                                      "Australia/Sydney"
                                  )}
                                </td>

                                <td className="center">
                                  {cur?.classTemplateDetails?.className}
                                </td>
                                <td className="center">
                                  {cur.instructorDetails?.givenName}{" "}
                                  {cur.instructorDetails?.surname}
                                </td>
                                <td className="center">
                                  {cur?.studioDetails?.studioName}
                                </td>
                                <td className="center">
                                  {cur?.isThisSeries ? "Series" : "Single"}
                                </td>
                                <td className="center">
                                  {
                                    notCancelledBookinItems(cur?.bookings.items)
                                      ?.length
                                  }
                                  /{cur?.maxBooking}
                                </td>

                                <td className="center">
                                  <span className="button-td">
                                    <div>
                                      {cur.isBookable ? (
                                        <FaRegCalendarCheck
                                          onClick={() => {
                                            handleBookingClick(cur);
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {permissions.includes("CanEditClass") && (
                                      <AiOutlineEdit
                                        onClick={() => handleEditClick(cur.id)}
                                      />
                                    )}
                                    {permissions.includes("CanDeleteClass") && (
                                      <MdOutlineDelete
                                        onClick={() => {
                                          handleComponent("delete-template", {
                                            id: cur.id,
                                            isActive: cur.isActive,
                                            className:
                                              cur?.classTemplateDetails
                                                ?.className,
                                          });
                                        }}
                                        className="delete-icon"
                                      />
                                    )}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr className="no-hover-style">
                      <td colSpan={10} className="in-table">
                        <div className="no-hover-style">
                          <div className="no-data-holder ">
                            <div className="no-data">
                              <h3 className="fs-12 uppercase">
                                No Records Found
                              </h3>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              {componentName && (
                <Modal>
                  {componentName === "delete-template" && (
                    <StatusModal
                      curRow={curRow}
                      className={curRow.className}
                      modalHeaderTxt={"Delete Class"}
                      modalParaText={
                        "Are you sure you want to delete this class?"
                      }
                      handleStatusClick={handleDeleteClick}
                      modalClose={modalClose}
                    />
                  )}
                  {/* {componentName === "class-not-bookable" && (
                  <div className="status-modal">
                    <h3 className="fs-35 uppercase ">
                      THIS CLASS IS NOT BOOKABLE
                    </h3>
                    <Button
                      name="CLOSE"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-confirm btn-small"
                      click={modalClose}
                    />
                  </div>
                )} */}
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Classes;
