import React from "react";
import { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { AiOutlineSearch } from "react-icons/ai";
import { useMemberContext } from "../../../contexts/MemberContext";
import { brandTypes } from "../../../constants/commonConstant";
import getTypeIcon from "../../../utils/getTypeIcon";
import { BeatLoader } from "react-spinners";
import useCustomFetch from "../../../hooks/useCustomFetch";
import { useCheckMemberProfile } from "../../../hooks/useCheckMemberProfile";
import { API } from "../../../utils/API";
import { toast } from "react-toastify";
import { Table, Button, Toggle, GenerateBox } from "../..";
import { useHandleError } from '../../../hooks/useHandleError';

const FPVisitsReport = () => {
  const paginationData = useRef();
  const [generate, setGenerate] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [tableData, setTableData] = useState([]);
  const handleError = useHandleError();
  const [isSummary, setIsSummary] = useState(false);
  const { sidebarLocationId, sidebarBrandId, sidebarBrandName } =
    useMemberContext();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endPoint, setEndPoint] = useState();
  const [params, setParams] = useState();
  const [summaryEndpoint, setSummaryEndpoint] = useState();
  const [exportEndpoint, setExportEndpoint] = useState();
  const { data, isLoading, error } = useCustomFetch(
    endPoint && endPoint,
    params && params
  );
  const { data: summary } = useCustomFetch(
    summaryEndpoint?.endPoint && summaryEndpoint?.endPoint,
    summaryEndpoint?.params && summaryEndpoint?.params
  );
  const { data: exportData } = useCustomFetch(
    exportEndpoint?.endPoint && exportEndpoint?.endPoint,
    exportEndpoint?.params && exportEndpoint?.params
  );

  const { checkMemberProfile } = useCheckMemberProfile();
  useEffect(() => {
    if (sidebarBrandId && sidebarLocationId) handleGenerate(100, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarBrandId, sidebarLocationId]);
  useEffect(() => {
    if (endDate && startDate && generate) {
      handleGenerate(100, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generate]);
  useEffect(() => {
    if (error && endPoint) {
      setTableData([]);
      handleError(error?.response?.data?.message ?? "Error when fetching api");
    } else if (data) {
      if (params.page !== 1) {
        setTableData([...tableData, ...data.data.data]);
      } else setTableData([...data.data.data]);
    }
    setGenerate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  useEffect(() => {
    setGenerate(false);
  }, [sidebarBrandId, sidebarLocationId]);

  const handleGenerate = (limit, page) => {
    paginationData.current = { limit: limit, page: page };
    let parameters = {
      brandId: sidebarBrandId,
      startDate: startDate,
      locationId: sidebarLocationId,
      endDate: endDate,
    };
    if (sidebarLocationId === 0) {
      delete parameters.locationId;
    }
    if (startDate && endDate) {
      const pagination = limit ? { limit: limit, page: page } : undefined;

      if (pagination) parameters = { ...parameters, ...pagination };

      setEndPoint(API.fpVisits);
      setParams(parameters);
      setExportEndpoint({ endPoint: API.fpVisitsExport, params: parameters });
      setSummaryEndpoint({
        endPoint: API.fpVisitsSummary,
        params: parameters,
      });
    }
  };
  const handleNextToken = () => {
    handleGenerate(
      paginationData.current.limit,
      paginationData.current.page + 1
    );
  };
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  const exportToExcel = () => {
    const a = document.createElement("a");
    a.href = exportData.data.data;
    a.target = "_blank";
    a.click();
    a.remove();
  };
  const sumColumn = [
    {
      accessorKey: "visitLocationName",
      header: "LOCATION",
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "visitcount",
      header: "TOTAL MEMBERS",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "ddfailedpayment",
    },
  ];
  const columns = [
    {
      accessorFn: (row) => {
        return row?.visitLocationName;
      },
      header: "VISITED CLUB",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "visitedLocation",
    },
    {
      accessorFn: (row) => {
        return row.surname + ", " + row.givenName;
      },
      header: "SURNAME, NAME",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "fullName",
    },
    {
      header: "BRAND",
      cell: (info) => {
        const icon = getTypeIcon(sidebarBrandName, brandTypes);
        return icon;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "brand",
    },
    {
      accessorFn: (row) => {
        return row?.email;
      },
      header: "EMAIL",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "email",
    },
    {
      accessorFn: (row) => {
        return row?.homeLocationName;
      },
      header: "HOME CLUB",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "homeClub",
    },
    {
      accessorFn: (row) => {
        return row.fitnessPassportNumber;
      },
      header: "FP NUMBER",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: true,
      id: "fpNumber",
    },
    {
      accessorFn: (originalRow) => {
        if (originalRow?.visitTime) {
          return format(
            new Date(originalRow?.visitTime),
            "dd/MM/yyyy HH:mm:ss"
          );
        } else {
          return "";
        }
      },
      header: "VISIT TIME",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "visitTime",
    },
  ];

  return (
    <>
      <GenerateBox
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setGenerate={setGenerate}
      />
      {tableData?.length > 0 && !isLoading ? (
        <div className="actions-row report-tables mt-24 mb-12">
          <div className="action-buttons">
            <Button
              name="csv"
              btntype="submit"
              btnname="icon-btn"
              className="btn btn-transparent fs-12"
              icon="csv_icon"
              click={exportToExcel}
            />
            <Toggle
              label="SUMMARY REPORT"
              id="hasVirtualClass"
              name="Virtual Class"
              value={isSummary}
              checked={isSummary}
              onChange={(e) => {
                setIsSummary(e.target.checked);
              }}
            />
          </div>
          {!isSummary && (
            <div className="search-container">
              <form onSubmit={onSearchBarSubmit}>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="fpVisits-report-search"
                      id="fpVisits-report-search"
                      value={searchBarInputVal}
                      onChange={handleChange}
                      placeholder="Search by surname, email, home club"
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      ) : null}
      {tableData.length > 0 && (
        <p className="mt-24 fs-14">TOTAL: {data?.data?.total}</p>
      )}

      {isLoading ? (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      ) : null}
      {!isLoading ? (
        <div className="report-table-container-all">
          <Table
            onRowClick={(i) => {
              checkMemberProfile(i.memberName, i.memberId);
            }}
            data={isSummary ? summary?.data.data : tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={isSummary ? sumColumn : columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            nextToken={tableData.length < data?.data.total}
            handleNextToken={handleNextToken}
          />
        </div>
      ) : null}
    </>
  );
};

export default FPVisitsReport;
