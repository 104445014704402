import { Form, Formik } from "formik";
import React from "react";
import { useMemberContext } from "../../contexts/MemberContext";
import { HealthQuestionnaireSchema } from "../../validations/healthQuestionValidation";
import "./addHealthQuestionnaire.scss";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { GET_ALL_HEALTHQUESTIONNAIRE } from "../../gqloperations/queries";
import { CREATE_HEALTHQUESTION } from "../../gqloperations/mutations";
import { useMutation } from "@apollo/client";
import { HealthQuestionnaireSection, answerType } from "../../constants/commonConstant";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useHandleError } from '../../hooks/useHandleError';
import {
  RadioButton,
  ToggleFormik,
  Select,
  Header,
  Button,
} from "../../components";

function AddHealthQuestionnaire() {
  const { loggedUser } = useAuthContext();
  const navigate = useNavigate();
  const { sidebarBrandId, sidebarBrandName } = useMemberContext();
  const handleError = useHandleError();
  const [createHealthQuestionnaire] = useMutation(CREATE_HEALTHQUESTION);

  const onSubmit = async (values, OnSubmitProps) => {
    let variables = {
      answerType: "",
      brandId: "",
      isActive: false,
      mandatory: false,
      question: "",
      sectionId: "",
      sectionLabel: "",
      expectedAnswer: "",
    };
    const getVariable = () => {
      variables.createdBy = loggedUser.getMember.memberId;
      variables.answerType = values.answerType;
      variables.brandId = sidebarBrandId;
      variables.isDeleted = false;
      variables.isActive = values.isActive;
      variables.mandatory = values.mandatory;
      variables.question = values.question;
      variables.expectedAnswer =
        values.expectedAnswer === "other" || values.expectedAnswer === ""
          ? ""
          : values.expectedAnswer;
      variables.sectionId = HealthQuestionnaireSection.filter(
        (i) => i.label.toLowerCase() === values.sectionLabel.toLowerCase()
      )[0].id;
      variables.sectionLabel = values.sectionLabel;

      return variables;
    };
    try {
      await createHealthQuestionnaire({
        variables: { input: getVariable() },
        onCompleted: () => {
          OnSubmitProps.setSubmitting(false);
          OnSubmitProps.resetForm();
          toast.success("Question Created Successfully");
          navigate(-1);
        },
        refetchQueries: [
          {
            query: GET_ALL_HEALTHQUESTIONNAIRE,
            variables: {
              filter: {
                and: [
                  { isDeleted: { ne: true } },
                  { brandId: { eq: `${sidebarBrandId}` } },
                ],
              },
            },
          },
        ],
      });
    } catch (error) {
      handleError(`${error}`);
    }
    OnSubmitProps.setSubmitting(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <div>
      <Formik
        initialValues={{
          mandatory: false,
          isActive: true,
          brandId: "",
          section: "",
          sectionLabel: "",
          answerType: "",
          expectedAnswer: "",
        }}
        validationSchema={HealthQuestionnaireSchema}
        onSubmit={(values, OnSubmitProps) => {
          onSubmit(values, OnSubmitProps);
        }}
      >
        {(formik) => {
          return (
            <>
              <Header pageTitle="Add Question" />
              <Form>
                <div className="add-question-page main mt-32 pd-18 mb-12">
                  <div className="add-card mb-24">
                    <div className="question-title-details">
                      <div className="inputs">
                        <div>
                          <h3 className="fs-14">HEALTH QUESTION</h3>
                        </div>
                        <div>
                          <h3 className="fs-12 text-gray">Brand</h3>
                          <p className="fs-12">{sidebarBrandName}</p>
                        </div>
                        <Select
                          label="Section label"
                          optionvalues={HealthQuestionnaireSection.map(
                            (i) => i.label
                          )}
                          selecttype="array"
                          name="sectionLabel"
                          id="sectionLabel"
                          autoComplete="off"
                          className="select-element-value"
                          labelclass="text-gray"
                        />
                        <Select
                          label="Answer type"
                          optionvalues={answerType}
                          selecttype="array"
                          name="answerType"
                          id="answerType"
                          autoComplete="off"
                          className="select-element-value"
                          labelclass="text-gray"
                        />
                        <div>
                          <ToggleFormik
                            label="Active"
                            name="isActive"
                            className="fs-10"
                          />{" "}
                          <ToggleFormik
                            label="Mandatory"
                            name="mandatory"
                            className="fs-10"
                          />
                        </div>
                        <div className="desc-row">
                          <h3 className="fs-12">Question *</h3>
                          <textarea
                            className="reason-box fs-12"
                            id="question"
                            name="question"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.question}
                          ></textarea>
                          {formik?.errors?.question ? (
                            <div className="error fs-12">
                              {formik.errors.question}
                            </div>
                          ) : null}
                        </div>
                        {formik.values.answerType.toLowerCase() === "number" &&
                          formik.values.mandatory && (
                            <div className="desc-row">
                              <h3 className="fs-12">Expected Answer *</h3>

                              <div>
                                <RadioButton
                                  name="expectedAnswer"
                                  id="expectedAnswer"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"yes"}
                                  label="yes"
                                />
                                <RadioButton
                                  name="expectedAnswer"
                                  id="expectedAnswer"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"no"}
                                  label="no"
                                />
                                <RadioButton
                                  name="expectedAnswer"
                                  id="expectedAnswer"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"other"}
                                  label="other"
                                />
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-btn pb-24">
                  <Button
                    loading={formik.isSubmitting}
                    name={"Save"}
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-large btn-confirm  fs-12"
                    disabled={formik.isSubmitting ? true : false}
                  />
                  <Button
                    name="Cancel"
                    btntype="reset"
                    btnname="submit"
                    className="btn btn-large btn-deny fs-12"
                    click={handleCancel}
                  />
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
}

export default AddHealthQuestionnaire;
