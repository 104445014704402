// import "./bankAccountRefund.scss";
import React, { useEffect, useRef, useState } from "react";
import actions from "../../assets/images/icons/action_menu.png";
import { useMutation } from "@apollo/client";
import { useMemberContext } from "../../contexts/MemberContext";
import { BeatLoader } from "react-spinners";
import {
  Button,
  Table,
  Header,
  GenerateBox,
  Modal,
  StatusModal,
} from "../../components";
import { toast } from "react-toastify";
import { sortDesc } from "../../utils/sort";
import { useHandleError } from '../../hooks/useHandleError';
import { DEBT_COLLECTION_ACTIONS } from "../../gqloperations/mutations";

function DebtCollection() {
  const ref = useRef();
  const { sidebarBrandId } = useMemberContext();
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [showActions, setShowActions] = useState({});
  const [componentName, setComponentName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [curRow, setCurRow] = useState({});
  const [deptCollectionActions, { loading: deptCollectionActionsLoading }] =
    useMutation(DEBT_COLLECTION_ACTIONS);
  const [sentLoading, setSentLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [generate, setGenerate] = useState(false);
  const handleError = useHandleError();

  const handleDownloadExcel = async (url, fileName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = urlObject;
      link.download = fileName;
      link.target = "_blank";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(urlObject);
    } catch (err) {
      handleError("Error on download excel file try again later");
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      setShowActions({});
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [ref]);

  const getListDebtCollectionArma = (values) => {
    deptCollectionActions({
      fetchPolicy: "no-cache",
      variables: {
        input: {
          action: "READ_ALL_DEBT_REPORT",
          brandId: sidebarBrandId,
          isArma: true,
          startDate: startDate,
          endDate: endDate,
        },
      },
      onCompleted: (data) => {
        setGenerate(false);
        const sortSentreport = JSON.parse(data.debtActions.body).data.sort(
          (a, b) => {
            if (!a.exportedDate) return -1;
            if (!b.exportedDate) return 1;
            return 0;
          }
        );
        setTableData(sortDesc(sortSentreport, "exportedDate"));
      },
      onError: (err) => {
        setGenerate(false);
        handleError(`${err}`);
      },
    });
  };

  const handleSentToArma = () => {
    setSentLoading(true);
    deptCollectionActions({
      fetchPolicy: "no-cache",
      variables: {
        input: {
          action: "SEND_TO_ARMA",
          brandId: sidebarBrandId,
          groupId: curRow.groupId,
        },
      },
      onCompleted: (data) => {
        toast.success("File sent to Arma successfully");
        setSentLoading(false);
        setComponentName("");
        setTableData([JSON.parse(data.debtActions.body)]);
        getListDebtCollectionArma();
      },
      onError: (err) => {
        setSentLoading(false);

        handleError(`${err}`);
      },
    });
  };

  useEffect(() => {
    if (generate) {
      getListDebtCollectionArma();
    }
  }, [generate]);

  const columns = [
    {
      id: "totalMembers",
      accessorFn: (record) => `${record.totalMembers}`,
      header: "Total Members",
      cell: (info) => info.getValue(),
    },
    {
      id: "Total Overdue Amount",
      accessorFn: (record) => `$${record.totalOverdueAmount?.toFixed(2)}`,
      header: "Total Overdue Amount",
      cell: (info) => info.getValue(),
    },
    {
      id: "Total Fee Amount",
      accessorFn: (record) => `$${record.totalNetAmount?.toFixed(2)}`,
      header: "Total Fee Amount",
      cell: (info) => info.getValue(),
    },
    {
      id: "isSent",
      accessorFn: (record) => {
        return record.isSent;
      },
      header: "Sent",
      cell: (info) => {
        if (info.getValue()) {
          return <div className="text-green">Yes</div>;
        } else {
          return <div className="text-red">No</div>;
        }
      },
    },
    {
      id: "row-actions5",
      header: "ACTIONS",
      cell: (props) => {
        const { groupId } = props?.row?.original;

        const areActionsVisible = showActions.hasOwnProperty(groupId);
        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              onClick={(e) => {
                e.stopPropagation();
                setShowActions((prevShowActions) => ({
                  ...prevShowActions,
                  [groupId]: !areActionsVisible,
                }));
              }}
              alt=""
            />
            {areActionsVisible && (
              <span className="action-options-menu" ref={ref}>
                <ul className="membership-options">
                  <li
                    onClick={() => {
                      handleDownloadExcel(
                        props.row.original.reportUrlS3,
                        "Report.csv"
                      );
                    }}
                  >
                    <Button
                      name="Download Excel"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li
                    onClick={() => {
                      if (!props.row.original.isSent) {
                        handleComponent("sent-arma", props.row.original);
                      }
                    }}
                  >
                    <Button
                      name="File sent to Arma"
                      btntype="submit"
                      btnname="submit"
                      className={`btn btn-transparent btn-small ${
                        props.row.original.isSent ? "btn-disabled" : ""
                      }`}
                    />
                  </li>
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const handleComponent = (componentName, rowObj) => {
    setComponentName(componentName);
    setCurRow(rowObj);
  };

  const modalClose = () => {
    setComponentName("");
    setCurRow({});
  };

  return (
    <div className="bank-refund-container">
      <Header pageTitle="Debt collection" />
      <GenerateBox
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setGenerate={setGenerate}
        type="debtCollection"
      />

      {componentName && (
        <Modal>
          {componentName === "sent-arma" && (
            <StatusModal
              curRow={curRow}
              modalHeaderTxt={"Sent to Arma"}
              modalParaText={"HAVE YOU SENT THIS FILE TO ARMA?"}
              handleStatusClick={handleSentToArma}
              modalClose={modalClose}
              loading={sentLoading}
            />
          )}
        </Modal>
      )}

      <div className="landing-table">
        {deptCollectionActionsLoading && !sentLoading ? (
          <div className="report">
            <BeatLoader className="loading" color="white" size={20} />
          </div>
        ) : (
          <Table
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
            setSorting={setSorting}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
          />
        )}
      </div>
    </div>
  );
}

export default DebtCollection;
