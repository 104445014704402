import { CSVLink } from "react-csv";
import Button from "../formUiElements/Button";

const CSVExport = ({ data, fileName }) => (
  <CSVLink data={data} filename={fileName}>
    <Button
      name="csv"
      btntype="submit"
      btnname="icon-btn"
      className="btn btn-transparent fs-12"
      icon="csv_icon"
    />
  </CSVLink>
);

export default CSVExport;
