import {
  SET_MEMBER,
  SET_SIDEBAR_BRAND_LOCATION_ID,
  SET_SIDEBAR_LOCATION_ID,
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_SIDEBAR_BRAND_ID,
  SET_OLD_VALUES,
  SET_LOCATION_DATA_BY_BRAND_ID,
  SET_IMG_URL,
  SET_MEMBER_ID,
  SET_INDEX,
  SET_MEMBERSHIPS_BY_LOCATION_ID,
  SET_MEMBERCONTRACT_BY_MEMBERSHIP_ID,
  SET_SELECTED_MEMBERSHIP,
  SET_IPADDRESS,
  SET_MEMBER_INTERACTION,
  SET_VOUCHER_TYPE,
  SET_STATS_DATA,
  SET_USER_SETTINGS,
  SET_SEARCH_MEMBER_VALUE,
  SET_PAGE_HISTORY,
} from "../actions";

const member_reducer = (state, action) => {
  if (action.type === SET_MEMBERCONTRACT_BY_MEMBERSHIP_ID) {
    return {
      ...state,
      memberContractByMembershipId: action.payload,
    };
  }
  if (action.type === SET_IPADDRESS) {
    return { ...state, ipAddress: action.payload };
  }
  if (action.type === SET_MEMBER_INTERACTION) {
    return { ...state, memberInteraction: action.payload };
  }
  if (action.type === SET_USER_SETTINGS) {
    return { ...state, userSettings: action.payload.userSettings };
  }
  if (action.type === SET_STATS_DATA) {
    return { ...state, statsData: action.payload };
  }
  if (action.type === SET_SELECTED_MEMBERSHIP) {
    return {
      ...state,
      selectedMembershipId: action.payload,
    };
  }
  if (action.type === OPEN_MODAL) {
    return {
      ...state,
      modal: true,
    };
  }
  if (action.type === CLOSE_MODAL) {
    return {
      ...state,
      modal: false,
    };
  }

  if (action.type === SET_IMG_URL) {
    return {
      ...state,
      imgUrl: action.payload.imgUrl,
    };
  }

  if (action.type === SET_OLD_VALUES) {
    return {
      ...state,
      oldValues: action.payload,
    };
  }

  if (action.type === SET_LOCATION_DATA_BY_BRAND_ID) {
    return {
      ...state,
      sidebarLocationId: action.payload.sidebarLocationId,
      sidebarLocationName: action.payload.sidebarLocationName,
      sidebarLocationData: action.payload.sidebarLocationData,
    };
  }

  if (action.type === SET_SIDEBAR_BRAND_LOCATION_ID) {
    return {
      ...state,
      sidebarBrandId: action.payload.sidebarBrandId,
      sidebarBrandName: action.payload.sidebarBrandName,

      role: action.payload.role,
    };
  }
  if (action.type === SET_SIDEBAR_LOCATION_ID) {
    return {
      ...state,
      sidebarLocationId: action.payload.sidebarLocationId,
      sidebarLocationName: action.payload.sidebarLocationName,
    };
  }

  if (action.type === SET_SIDEBAR_BRAND_ID) {
    return {
      ...state,
      sidebarBrandId: action.payload.sidebarBrandId,
      sidebarBrandName: action.payload.sidebarBrandName,
      sidebarLocationId: "",
      sidebarLocationName: "",
    };
  }

  if (action.type === SET_MEMBER) {
    return {
      ...state,
      member: action.payload,
      getMembershipByLocationData: "",
    };
  }

  if (action.type === SET_MEMBER_ID) {
    return {
      ...state,
      memberInteraction: "",
      memberId: action.payload.memberId,
      member: "",
      selectedMembershipId: null,
      memberContractByMembershipId: {},
    };
  }

  if (action.type === SET_INDEX) {
    return {
      ...state,
      index: action.payload.index,
    };
  }
  if (action.type === SET_MEMBERSHIPS_BY_LOCATION_ID) {
    return {
      ...state,
      getMembershipByLocationData: action.payload,
    };
  }
  if (action.type === SET_VOUCHER_TYPE) {
    return {
      ...state,
      voucherType: action.payload.voucherType,
    };
  }
  if (action.type === SET_SEARCH_MEMBER_VALUE) {
    return {
      ...state,
      searchMemberValue: action.payload,
    };
  }
  if (action.type === SET_PAGE_HISTORY) {
    return {
      ...state,
      pageHistory: action.payload,
    };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default member_reducer;
