import "./input.scss";
import { useField } from "formik";
import { AiOutlineCalendar } from "react-icons/ai";

const Input = (props) => {
  const [field, meta] = useField(props);
  return (
    <>
      {props.icon && props.type !== "date" && (
        <div className="form-group1 noselect">
          <label
            htmlFor={props.id || "username"}
            className={`${props.className} fs-12`}
          >
            {props.label}
          </label>
          <input
            type={props.type}
            {...field}
            {...props}
            placeholder={props.placeholder}
            className={`${props.className} fs-12 noselect`}
          />
          {/* {props.type === "date" && <AiOutlineCalendar />} */}

          <div className="error fs-12">
            {meta.error && meta.touched ? meta.error : null}
          </div>
        </div>
      )}
      {!props.icon && props.type !== "date" && (
        <div className="form-group">
          <label
            htmlFor={props.id || "username"}
            className={`${props.className} fs-12`}
          >
            {props.label}
          </label>
          <input
            type={props.type}
            {...field}
            {...props}
            placeholder={props.placeholder}
            className={`${props.className} fs-12 noselect`}
          />
          {/* {props.type === "date" && (
            <AiOutlineCalendar
              className={meta.error && meta.touched ? "small-box" : "large-box"}
            />
          )} */}
          <div className="error fs-12">
            {meta.error && meta.touched ? meta.error : null}
          </div>
        </div>
      )}
      {!props.icon && props.type === "date" && (
        <div className="form-group">
          <label
            htmlFor={props.id || "username"}
            className={`${props?.className ? props?.className : ""} fs-12`}
          >
            {props.label}
          </label>
          <div className="date-container">
            <input
              type={props.type}
              {...field}
              {...props}
              placeholder={props.placeholder}
              className={`${
                props?.className ? props?.className : ""
              } fs-12 noselect `}
            />
            {props.type === "date" && (
              <AiOutlineCalendar
                className={
                  meta.error && meta.touched ? "small-box" : "large-box"
                }
              />
            )}
          </div>
          <div className="error fs-12">
            {meta.error && meta.touched ? meta.error : null}
          </div>
        </div>
      )}
    </>
  );
};

export default Input;
