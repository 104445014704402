import "./blockMember.scss";
import { Formik, Form, Field } from "formik";
import Button from "../formUiElements/Button";
import DatePicker from "../formUiElements/input/DatePicker";
import Select from "../formUiElements/select/Select";
import { toast } from "react-toastify";
import { reasonForBlockMember } from "../../constants/commonConstant";
import {
  UNBLOCK_MEMBER,
  BLOCK_MEMBER,
  UPFRONT_UNBLOCK_MEMBER,
} from "../../gqloperations/mutations";
import { useMutation } from "@apollo/client";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import MakePaymentModal from "../modal/MakePaymentModal";
import React, { useState } from "react";
import { format } from "date-fns";
import { useHandleError } from '../../hooks/useHandleError';

const BlockModal = ({
  memberId,
  isUnblocking,
  onClick,
  locationId,
  membershipType,
  expiry,
}) => {
  const { loggedUser } = useAuthContext();

  const today = new Date();
  const [blockMember] = useMutation(BLOCK_MEMBER);
  const [unblockMember] = useMutation(UNBLOCK_MEMBER);
  const [upfrontUnblockMember] = useMutation(UPFRONT_UNBLOCK_MEMBER);
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [payloadUnblock, setPayloadUnblock] = useState();
  const [amount, setAmount] = useState(0);
  const handleError = useHandleError();

  const handleSubmit = (values, OnSubmitProps) => {
    const { blockTo, blockType, reason, reasonDetails } = values;

    let input = {
      memberId,
    };

    if (isUnblocking) {
      const handleUpfrontUnblock = (res) => {
        if (res?.upfrontUnblockMember?.data?.amount > 0) {
          OnSubmitProps.resetForm();
          setAmount(res.data.upfrontUnblockMember.data.amount);
          setShowModalPayment(true);
          setPayloadUnblock({
            unblockMemberArguments: {
              unblockedBy: loggedUser?.getMember?.memberId,
              reasonsForUnblocking: reasonDetails,
            },
          });
        } else if (res.upfrontUnblockMember.data.amount === 0) {
          unblockMember({
            variables: {
              ...input,
              unblockedBy: loggedUser?.getMember?.memberId,
              reasonsForUnblocking: reasonDetails,
              operation: "UNBLOCK_MEMBER",
            },
            onCompleted: () => {
              OnSubmitProps.resetForm();
              onClick();
              toast.success("Member has been unblocked successfully");
            },

            onError: (error) => {
              onClick();
              handleError(`${error}`);
            },
          });
        }
      };

      upfrontUnblockMember({
        variables: {
          ...input,
          operation: "UPFRONT_UNBLOCK_MEMBER",
          memberId: memberId,
        },
        onCompleted: (res) => {
          handleUpfrontUnblock(res);
        },
        onError: (error) => {
          onClick();
          handleError(`${error}`);
        },
      });
    } else {
      input = {
        ...input,
        blockedBy: loggedUser?.getMember?.memberId,
        operation: "BLOCK_MEMBER",
        blockType,
      };
      if (blockTo) {
        input = { ...input, blockTo };
      }
      if (reason === "Other") {
        input = {
          ...input,
          reasonsForBlocking: reasonDetails,
        };
      } else {
        input = {
          ...input,
          reasonsForBlocking: reason,
        };
      }

      blockMember({
        variables: {
          ...input,
        },
        onCompleted: () => {
          OnSubmitProps.resetForm();
          onClick();
          toast.success("Member has been blocked successfully");
        },
        onError: (error) => {
          onClick();
          handleError(`${error}`);
        },
      });
    }
    OnSubmitProps.setSubmitting(true);
  };
  const unblockMemberActionSuccess = () => {
    onClick();
    toast.success("Member has been unblocked successfully");
  };
  const unblockMemberActionError = (error) => {
    onClick();
    handleError(`${error}`);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          blockType: "CHARGE",
          blockTo: "",
          reason: "",
          reasonDetails: "",
        }}
        onSubmit={(values, OnSubmitProps) => {
          handleSubmit(values, OnSubmitProps);
        }}
        validate={(values, props) => {
          const { blockType, reason, reasonDetails, blockTo } = values;

          let errors = {};
          if (!isUnblocking) {
            if (!blockType) {
              const errorText = "Please choose charging options";
              errors.blockType = errorText;
            }
            if (!reason) {
              errors.reason = "Reason is required!";
            }
            if (
              membershipType === "pif" &&
              !blockTo &&
              blockType === "NO_CHARGE"
            ) {
              const errorText = "Block to is required!";
              errors.blockTo = errorText;
            }
            if (reason === "Other" && !reasonDetails) {
              const errorText = "Please provide a reason for blocking";
              errors.reasonDetails = errorText;
            }
          } else {
            if (!reasonDetails) {
              const errorText = "Please provide a reason for unblocking";
              errors.reasonDetails = errorText;
            }
          }

          return errors;
        }}
      >
        {(formik) => {
          return (
            <div className="block-popup pd-32">
              <Form>
                <div className="block-title ">
                  <h3 className="fs-35 pb-18 uppercase">
                    {isUnblocking
                      ? "Unblock Member Access"
                      : "Block Member Access"}
                  </h3>
                </div>

                {!isUnblocking && (
                  <div className="block-options-container pb-18">
                    <div
                      role="group"
                      aria-labelledby="my-radio-group"
                      className="radio-group fs-12"
                    >
                      <div className="charge-options-container">
                        <label>
                          <Field type="radio" name="blockType" value="CHARGE" />
                          CONTINUE CHARGING MEMBERSHIP
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="blockType"
                            value="NO_CHARGE"
                          />
                          STOP CHARGING MEMBERSHIP
                        </label>
                      </div>
                      {formik.errors.blockType && (
                        <div className="fs-12 err-text">
                          {formik.errors.blockType}
                        </div>
                      )}
                    </div>
                    <div className="row-two-container pb-18">
                      <div className="date-select">
                        <label className="fs-12 text-gray" htmlFor="to">
                          To
                        </label>
                        <div className="flex-col">
                          <DatePicker
                            className="fs-12 text-white"
                            id="to"
                            name="blockTo"
                            value={formik.values.blockTo}
                            onChange={formik.handleChange}
                            min={today.toISOString().split("T")[0]}
                            max={expiry}
                          />
                          <div className="fs-12 error">
                            {formik.errors.blockTo
                              ? formik.errors.blockTo
                              : null}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`drop-down ${
                          formik.errors.blockTo && "pb-18"
                        }`}
                      >
                        <Select
                          label="Reason"
                          optionvalues={reasonForBlockMember}
                          selecttype="array"
                          name="reason"
                          autoComplete="off"
                          className="fs-12 select-element-value"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {(formik.values.reason ===
                  reasonForBlockMember[reasonForBlockMember.length - 1] ||
                  isUnblocking) && (
                  <div className="block-reason-container">
                    <label htmlFor="reason" className="fs-12">
                      Reasons for {isUnblocking ? "unblocking" : "blocking"}:
                    </label>
                    <textarea
                      className="fs-8 text-box"
                      id="reasonDetails"
                      name="reasonDetails"
                      onChange={formik.handleChange}
                      value={formik.values.reasonDetails}
                    ></textarea>
                    {formik.touched.reasonDetails &&
                      formik.errors.reasonDetails && (
                        <div className="fs-12 err-text">
                          {formik.errors.reasonDetails}
                        </div>
                      )}
                  </div>
                )}
                <div className="block-btn-container">
                  <Button
                    loading={formik.isSubmitting}
                    name={isUnblocking ? "UNBLOCK MEMBER" : "BLOCK MEMBER"}
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-confirm btn-lg fs-12"
                    disabled={formik.isSubmitting}
                  />

                  <Button
                    name="CANCEL"
                    btntype="button"
                    btnname="cancel"
                    click={onClick}
                    className="btn btn-deny btn-lg fs-12"
                  />
                </div>
              </Form>
              {showModalPayment && (
                <MakePaymentModal
                  amount={amount}
                  setShowModalPayment={setShowModalPayment}
                  operation="UBU"
                  locationId={locationId}
                  payloadData={payloadUnblock}
                  onApiCompleted={() => unblockMemberActionSuccess()}
                  onApiError={(e) => unblockMemberActionError(e)}
                />
              )}
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default BlockModal;
