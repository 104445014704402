import {
  endOfDay,
  format,
  isAfter,
  isBefore,
  isSameDay,
  startOfDay,
} from "date-fns";

const getMemberContractAmountStatus = (contractArray) => {
  let activeAmount = 0;
  if (contractArray?.length > 0) {
    for (let i = 0; i < contractArray?.length; i++) {
      const currentDateTime = format(new Date(), "yyyy-MM-dd");
      const isCancelled = contractArray[i]?.expiryDateTime
        ? isBefore(
            startOfDay(new Date(contractArray[i]?.expiryDateTime)),
            new Date()
          ) || isSameDay(new Date(contractArray[i]?.expiryDateTime), new Date())
        : false;
      const isSuspended = contractArray[i]?.suspensions?.items?.find((item) => {
        return (
          (item?.cancelledDateTime === null &&
            item?.suspensionType === "MEDICAL" &&
            item?.suspensionStatus === "APPROVED") ||
          (item?.suspensionType !== "MEDICAL" &&
            currentDateTime >= item?.suspensionStartDateTime &&
            currentDateTime <= item?.suspensionEndDateTime &&
            item?.cancelledDateTime === null)
        );
      });
      const isExpired = contractArray[i]?.endDateTime
        ? isBefore(
            endOfDay(new Date(contractArray[i]?.endDateTime)),
            new Date()
          )
        : false;
      const isPending =
        contractArray[i].isPending && !contractArray[i].startDateTime;
      const isFuture =
        contractArray[i]?.startDateTime &&
        isAfter(
          startOfDay(new Date(contractArray[i]?.startDateTime)),
          new Date()
        );
      !isCancelled &&
        !isSuspended &&
        !isPending &&
        !isFuture &&
        !isExpired &&
        activeAmount++;
    }
  }
  return activeAmount;
};

export default getMemberContractAmountStatus;
