import { useField } from "formik";

const SearchableSelect = (props) => {
  const [field] = useField(props);
  const {
    label,
    listId,
    initialValue,
    optionList,
    disabled,
    identifierKey,
    displayKey1,
    displayKey2,
    placeholder,
  } = props;
  const getNameById = () => {
    const selectedItem = optionList?.find(
      (item) => item[identifierKey] === initialValue
    );
    if (!selectedItem) return "";
    return `${selectedItem[displayKey1]} ${selectedItem[displayKey2]}` || "";
  };
  return (
    <div className="form-group">
      <label className="text-gray">{label}</label>
      <input
        list={listId}
        name="areaManager"
        className="fs-12 select-element searchableSearchInput"
        disabled={disabled}
        {...field}
        placeholder={initialValue ? getNameById() : placeholder}
      />
      <datalist id={listId}>
        {optionList?.map((option) => {
          const displayValue = `${option[displayKey1]
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase())} ${option[
            displayKey2
          ]
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase())}`;
          return <option key={option[identifierKey]} value={displayValue} />;
        })}
      </datalist>
    </div>
  );
};

export default SearchableSelect;
