import "./staffReportDetailsModal.scss";
import React from "react";
import Button from "../../formUiElements/Button";

const StaffReportDetailsModal = (props) => {
  return (
    <div className="status-modal staff-audit-report-container ">
      <h3 className="fs-35 uppercase "> Staff audit report details </h3>
      <div className="date-container">
        <label htmlFor="date" className="fs-12 text-gray uppercase">
          Date
        </label>
        <input
          type="text"
          id="date"
          name="date"
          className="select-element-value"
          value={props?.details?.date}
          readOnly
        />
      </div>

      <div className="textarea-container">
        <label className="fs-12 text-gray ">What was done</label>
        <textarea
          value={props?.details?.whatWasDoneDetails}
          readOnly
        ></textarea>
      </div>

      <div className="btn-group">
        <Button
          name="CLOSE"
          btntype="reset"
          btnname="cancel"
          className="btn btn-confirm"
          click={props.modalClose}
        />
      </div>
    </div>
  );
};

export default StaffReportDetailsModal;
