const roleOptions = [
  { role: "L5", options: ["L0", "L1", "L2", "L3", "L4", "L5"] },
  { role: "L4", options: ["L0", "L1", "L2", "L3"] },
  { role: "L3", options: ["L0", "L1", "L2"] },
  { role: "L2", options: ["L0", "L1"] },
  { role: "L1", options: ["L0"] },
];
export const getRoleOptionsByRole = (role) => {
  const foundRole = roleOptions.find((item) => item.role === role);
  return foundRole ? foundRole.options : [];
}
