import "./addClassTemplate.scss";
import { useParams, useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { UPDATE_CLASS_TEMPLATE } from "../../gqloperations/mutations";
import { useQuery, useMutation } from "@apollo/client";
import { GET_CLASS_TEMPLATE_BY_ID } from "../../gqloperations/queries";
import { classTemplateSchema } from "../../validations/ClassValidation";
import { BeatLoader } from "react-spinners";
import { ToggleFormik, Input, Button, Header } from "../../components";
import { useHandleError } from '../../hooks/useHandleError';

const EditClassTemplate = () => {
  const navigate = useNavigate();
  const handleError = useHandleError();
  let { id } = useParams();
  const [oldAttribute, setOldAttribute] = useState({});

  //   const createLog = (values) => {
  //     delete values.id;
  //     const newAttributeName = Object.keys(values);
  //     const newAttributeValue = Object.values(values);
  //     const oldAttributeName = Object.keys(oldAttribute);
  //     const oldAttributeValue = Object.values(oldAttribute);

  //     const auditLog = {
  //       newAttributeValue: newAttributeValue,
  //       newAttributeName: newAttributeName,
  //       oldAttributeValue: oldAttributeValue,
  //       oldAttributeName: oldAttributeName,
  //       locationId: `${state.id}`,
  //       pinCodeId: `${id}`,
  //       updatedBy: updatedBy,
  //       // ipAddress: ipAddress,
  //     };

  //     auditLocationOperation({
  //       variables: {
  //         input: auditLog,
  //       },
  //       onCompleted: () => {
  //         // dispatch({ type: "SET_INDEX", payload: 5 });
  //         navigate(`/dashboard/location/specificLocation/${state.id}`);
  //       },
  //     });
  //   };

  //calling a mutation
  // const [
  //   auditLocationOperation,
  //   {
  //     data: locationauditoperationdata,
  //     loading: locationauditoperationloading,
  //     error: locationauditoperationerror,
  //   },
  // ] = useMutation(CREATE_LOCATION_AUDIT_OPERATION);

  const [updateClassTemplate] = useMutation(UPDATE_CLASS_TEMPLATE);

  //Saving into the database
  const onSubmit = (values, OnSubmitProps) => {
    delete values.brandName;
    values = {
      ...values,
      className: values.className.toUpperCase(),
      classDescription: values.classDescription.toUpperCase(),
      daysWithin: Number(values.daysWithin),
    };

    for (const key in values) {
      for (const key1 in oldAttribute) {
        if (values[`${key}`] === oldAttribute[`${key1}`]) {
          delete values[`${key}`];
          delete oldAttribute[`${key}`];
        }
      }
    }

    updateClassTemplate({
      variables: {
        input: values,
      },

      onCompleted: () => {
        OnSubmitProps.resetForm();
        // createLog(values);
        toast.success("Updated successfully");
        navigate("/dashboard/classTemplates");
      },
      onError: () => {
        handleError("Update failed");
      },
      refetchQueries: [
        {
          query: GET_CLASS_TEMPLATE_BY_ID,
          variables: {
            id: `${id}`,
          },
        },
      ],
    });
  };

  const {
    loading: classTemplateLoading,
    error: classTemplateError,
    data: classTemplatedata,
  } = useQuery(GET_CLASS_TEMPLATE_BY_ID, {
    variables: {
      id: id,
    },
  });

  useEffect(() => {
    if (classTemplatedata) {
      setOldAttribute({
        // doorName: classTemplatedata.getDoorAccessCode.doorName,
        // code: classTemplatedata.getDoorAccessCode.code,
        // instruction: classTemplatedata.getDoorAccessCode.instruction,
      });
    }
  }, [classTemplatedata]);
  if (classTemplateLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (classTemplateError) {
    return (
      <div>
        <div>Error: Problem getting classTemplates</div>
      </div>
    );
  }
  return (
    <>
      {classTemplatedata && (
        <Formik
          enableReinitialize
          initialValues={{
            id: `${id}`,
            isActive: classTemplatedata.getClassTemplate.isActive,
            hasVirtualClass: classTemplatedata.getClassTemplate.hasVirtualClass,
            brandId: classTemplatedata.getClassTemplate.brandId,
            // brandName: classTemplatedata.getClassTemplate.brandDetails.name,
            className: classTemplatedata.getClassTemplate.className,
            classDuration: classTemplatedata.getClassTemplate.classDuration,
            daysWithin: classTemplatedata.getClassTemplate.daysWithin,
            classDescription:
              classTemplatedata.getClassTemplate.classDescription,
          }}
          validationSchema={classTemplateSchema}
          onSubmit={(values, OnSubmitProps) => {
            onSubmit(values, OnSubmitProps);
          }}
        >
          {(formik, errors, touched) => {
            return (
              <>
                <Header pageTitle="Edit Class Template" />
                <Form id="edit-classtemplate-form">
                  <div className="edit-class-template-container">
                    <div className="top-bar mt-32 mb-12">
                      <div className="top-sub-bar">
                        <div className="template-class-name">
                          <h3 className="fs-12">
                            {classTemplatedata?.getClassTemplate?.className}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="content-container pd-12">
                      <h3 className="fs-14 uppercase pb-24">Class Settings</h3>
                      <div className="first-toggle-container">
                        <div className="left-toggles flex-wrap">
                          <ToggleFormik
                            label="CLASS ACTIVE"
                            name="isActive"
                            id="isActive"
                            value={formik.isActive}
                          />
                          <ToggleFormik
                            label="VIRTUAL CLASS"
                            name="hasVirtualClass"
                            id="hasVirtualClass"
                            value={formik.hasVirtualClass}
                          />
                        </div>
                      </div>
                      <div className="input-row add-class-template mt-12 flex-wrap">
                        <div className="detail">
                          <div className="brand-name text-gray">
                            <h5 className="fs-12">Brand *</h5>
                            <p className="fs-12">
                              {
                                classTemplatedata.getClassTemplate.brandDetails
                                  .name
                              }
                            </p>
                          </div>
                        </div>
                        {/* <div className="detail">
                          <Input
                            type="text"
                            label="Brand *"
                            name="brandName"
                            id="brandName"
                            disabled
                          />
                        </div> */}
                        <div className="detail">
                          <Input
                            type="text"
                            label="Class Name *"
                            name="className"
                            id="className"
                            autoComplete="off"
                          />
                        </div>
                        <div className="detail">
                          <Input
                            type="number"
                            label="Duration *"
                            name="classDuration"
                            id="classDuration"
                            autoComplete="off"
                          />
                        </div>
                        <div className="detail">
                          <Input
                            type="number"
                            label="Prebook Days"
                            name="daysWithin"
                            id="daysWithin"
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="desc-row mt-12">
                        <h3 className="fs-12 text-gray">Class Description *</h3>

                        <textarea
                          className="reason-box fs-12"
                          id="classDescription"
                          name="classDescription"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.classDescription}
                        ></textarea>
                        <div className="fs-12 error">
                          {formik.errors.classDescription &&
                            formik.errors.classDescription}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="right-btn">
                  <Button
                    name="SAVE"
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-green-standard btn-xsmall fs-12 "
                  />
                  <Link to="/dashboard/classTemplates">
                    <Button
                      name="CANCEL"
                      btntype="reset"
                      btnname="reset"
                      className="btn btn-transparent-red btn-xsmall fs-12"
                      click={handleCancel}
                    />
                  </Link>
                </div> */}
                  <div className="right-btn mt-12">
                    {/* {formik.isSubmitting && (
                      <Button
                        name={<BeatLoader color="white" />}
                        btntype="submit"
                        btnname="submit"
                        disabled={true}
                        className="btn btn-confirm btn-large fs-12"
                      />
                    )}
                    {!formik.isSubmitting && (
                      <Button
                        name="SAVE"
                        btntype="submit"
                        btnname="submit"
                        // disabled={!Formik.isValid || Formik.isSubmitting}
                        className="btn btn-confirm btn-large fs-12"
                      />
                    )} */}
                    <Button
                      loading={formik.isSubmitting}
                      name={"Save"}
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-confirm btn-large fs-12"
                      disabled={formik.isSubmitting ? true : false}
                    />
                    <Link to={`/dashboard/classTemplates`}>
                      <Button
                        name="Cancel"
                        btntype="submit"
                        btnname="submit"
                        // disabled={!Formik.isValid || Formik.isSubmitting}
                        className="btn btn-deny btn-large fs-12"
                      />
                    </Link>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default EditClassTemplate;
