import "./suspension.scss";
import { useState, useEffect } from "react";
import Header from "../header/Header";
import { isAfter, isBefore, endOfToday, addDays, format } from "date-fns";
import MedicalSuspension from "./memberInteraction/MedicalSuspension";
import StandardSuspension from "./memberships/suspension/StandardSuspension";
import { GET_MEMBER_CONTRACT_BY_CONTRACTID } from "../../gqloperations/queries";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useMemberContext } from "../../contexts/MemberContext";

const AddSuspension = (props) => {
  const { contractId } = useParams();
  const { createLog, member } = useMemberContext();
  const [index, setIndex] = useState(0);
  const [nextDebitDate, setNextDebitDate] = useState();
  const [paymentFrequencyType, setPaymentFrequencyType] = useState();

  const isAfterToday = (date) => isAfter(date, endOfToday());
  const sortByDate = (dateArray, direction = "desc") => {
    if (direction === "asc") {
      return dateArray.sort((a, b) => {
        const result = isBefore(new Date(a.debitDate), new Date(b.debitDate));
        return result ? -1 : 1;
      });
    } else {
      return dateArray.sort((a, b) => {
        const result = isAfter(new Date(a.debitDate), new Date(b.debitDate));
        return result ? -1 : 1;
      });
    }
  };

  const {
    data: getmembercontract,
    loading: getmembercontractloading,
    error: getmembercontracterror,
  } = useQuery(GET_MEMBER_CONTRACT_BY_CONTRACTID, {
    onCompleted: (data) => {
      setPaymentFrequencyType(data?.getMemberContract?.recurring);
      const { items } = data?.getMemberContract?.billings;
      const unprocessedNextDebits = items.filter(
        (cur) => !cur.isProcessed && isAfterToday(new Date(cur?.debitDate))
      );
      if (unprocessedNextDebits.length <= 0) return;
      const sortedDebits = sortByDate(unprocessedNextDebits, "asc");

      const { debitDate: upcomingDebitDate } = sortedDebits?.[0];

      setNextDebitDate(format(new Date(upcomingDebitDate), "yyyy-MM-dd"));
    },
    variables: {
      id: contractId,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    createLog(
      `Visited Add suspension page ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
      "member",
      `${member?.getMember?.memberId}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (getmembercontractloading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );

  if (getmembercontracterror)
    return (
      <div>
        <div>Couldn't get member contract</div>
      </div>
    );

  if (
    !getmembercontract?.getMemberContract?.billings?.items?.length > 0 &&
    !getmembercontract?.getMemberContract?.endDateTime
  )
    return (
      <div>
        <Header pageTitle="Add A Suspension" />
        <div className="no-data-holder">
          <div className="no-data">
            <h3 className="fs-12 uppercase">
              You cannot suspend a member without a billing.
            </h3>
          </div>
        </div>
      </div>
    );
  return (
    <>
      <Header pageTitle="Add A Suspension" />
      <div className="suspension-container mt-32">
        {`${member.getMember.givenName} ${member.getMember.surname}`}
        <div className="tabs mt-32">
          <div className="tabs-list">
            <div
              className={
                index !== 0
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => {
                setIndex(0);
                createLog(
                  `Clicked Standard tab on AddSuspension page ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
                  "member",
                  `${member?.getMember?.memberId}`
                );
              }}
            >
              Standard
            </div>
            <div
              className={
                index !== 1
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => {
                setIndex(1);
                createLog(
                  `Clicked Medical tab on AddSuspension page ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
                  "member",
                  `${member?.getMember?.memberId}`
                );
              }}
            >
              Medical
            </div>
          </div>
          <>
            <div className="tabContent" hidden={index !== 1}>
              {getmembercontract && (
                <MedicalSuspension getmembercontract={getmembercontract} />
              )}
            </div>
            <div className="tabContent" hidden={index !== 0}>
              <StandardSuspension
                minimumStartDate={
                  paymentFrequencyType
                    ? nextDebitDate
                    : format(addDays(new Date(), 1), "yyyy-MM-dd")
                }
                getmembercontract={getmembercontract}
              />
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default AddSuspension;
