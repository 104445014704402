import "./generatereportcard.scss";

const GenerateReportCard = ({title, subtitle, value, children}) => {
  return (
    <div className="generate-report-card">
      <div className="generate-report-card-container">
        <div className="top">
          <div className="left">
            <div className="fs-14 title">{title}</div>
            <div className="fs-10 date">{subtitle}</div>
          </div>
          <div className="right">
            <div className="fs-8 title">total</div>
            <div className="fs-54">{value}</div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default GenerateReportCard;
