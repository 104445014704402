import React from "react";
import Button from "../formUiElements/Button";
import { Form, Formik } from "formik";
import DatePicker from "../formUiElements/input/DatePicker";
import { format, isAfter } from "date-fns";
import { useMutation } from "@apollo/client";
import { CRATE_MEMBER_YEARLY_INVOICE } from "../../gqloperations/mutations";
import { utcToZonedTime } from "date-fns-tz";
import { useMemberContext } from "../../contexts/MemberContext";
import "./financialyearmodal.scss";
import { Storage } from "aws-amplify";
import { useHandleError } from '../../hooks/useHandleError';

function FinancialYearExportModal({ closeModal }) {
  const { member } = useMemberContext();
  const handleError = useHandleError();
  const [generateYearlyInvoice] = useMutation(CRATE_MEMBER_YEARLY_INVOICE);
  const handleDownloadInvoice = async (invoice) => {
    try {
      const url = await Storage.get(invoice.split("public/")[1]);
      const response = await fetch(url);
      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlObject;
      link.target = "_blank";
      link.download = invoice?.split("/")[invoice.split("/").length - 1];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
      handleError("Error on download receipt try again later");
    }
  };
  return (
    <div className="financialYearModal">
      <div className="status-modal payment-log">
        <h3 className="fs-35 uppercase">Generate Report</h3>
        <Formik
          enableReinitialize
          initialValues={{
            chooseDateFrom: "",
            chooseDateTo: "",
          }}
          validate={(values) => {
            let errors = {};
            const { chooseDateFrom, chooseDateTo } = values;
            const formattedFrom = chooseDateFrom
              ? format(new Date(chooseDateFrom), "yyyy-MM-dd")
              : "";
            const formattedTo = chooseDateTo
              ? format(new Date(chooseDateTo), "yyyy-MM-dd")
              : "";
            if (!chooseDateFrom) {
              errors.chooseDateFrom = "Please select a start date";
            }
            if (
              chooseDateFrom &&
              chooseDateTo &&
              isAfter(new Date(formattedFrom), new Date(formattedTo))
            ) {
              errors.chooseDate = "Invalid date has been selected";
            }
            return errors;
          }}
          onSubmit={async (values, OnSubmitProps) => {
            const data = await generateYearlyInvoice({
              variables: {
                operationName: "GENERATE_INVOICE_YEARLY",
                memberIds: member.getMember.memberId,
                dateFrom: format(
                  utcToZonedTime(
                    new Date(values.chooseDateFrom),
                    member?.getMember?.homeLocationDetails?.timezone ??
                      "Australia/Sydney"
                  ),
                  "yyyy-MM-dd"
                ),
                dateTo: format(
                  utcToZonedTime(
                    new Date(values.chooseDateTo),
                    member?.getMember?.homeLocationDetails?.timezone ??
                      "Australia/Sydney"
                  ),
                  "yyyy-MM-dd"
                ),
              },
              onError: (err) => {
                handleError(`${err}`);
              },
            });
            await handleDownloadInvoice(data.data.createMemberInvoiceYearly);
          }}
        >
          {(formik) => {
            console.log(formik, formik.isSubmitting);
            return (
              <div>
                <Form>
                  <div className="report-options-container flex-wrap">
                    <p className="show-subtitle text-gray">
                      Show Payments Between
                    </p>
                    <div className="date-input-container fs-10 flex-wrap pb-8">
                      <div className="date-input">
                        <DatePicker
                          name="chooseDateFrom"
                          value={formik.values.chooseDateFrom}
                          onChange={(e) => {
                            const newToValue =
                              formik.values.chooseDateTo.length > 0
                                ? formik.values.chooseDateTo
                                : format(new Date(), "yyyy-MM-dd");

                            formik.setFieldValue("chooseDateTo", newToValue);
                            formik.handleChange(e);
                          }}
                        />
                      </div>
                      <div className="date-input">
                        {/* <p>To</p> */}
                        <DatePicker
                          name="chooseDateTo"
                          value={formik.values.chooseDateTo}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                    {(formik.errors.chooseDateFrom ||
                      formik.errors.chooseDate) && (
                      <div>
                        <p className="warning-text error fs-10">
                          {formik.errors.chooseDateFrom ||
                            formik.errors.chooseDate}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="btnContainer">
                    <Button
                      loading={formik.isSubmitting}
                      name="GENERATE"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-confirm  block btn-large fs-12"
                    />
                    <Button
                      name="close"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-large btn-transparent fs-12"
                      click={() => closeModal()}
                    />
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default FinancialYearExportModal;
