import "../applyMembershipTo.scss";
import { React, useState, useEffect } from "react";
import { Header, Checkbox, Table } from "../../../components";
import { AiOutlineSearch } from "react-icons/ai";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";
import { useMemberContext } from "../../../contexts/MemberContext";
import { useNavigate, useParams } from "react-router-dom";
import { ADD_LOCATION_TO_VOUCHER } from "../../../gqloperations/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useHandleError } from '../../../hooks/useHandleError';
import {
  GET_VOUCHER_LOCATION,
  GET_VOUCHER_BY_ID,
} from "../../../gqloperations/queries";
import { BeatLoader } from "react-spinners";

const AddVoucherToLocation = (props) => {
  const { locationAccess } = useAuthContext();
  const { voucherId } = useParams();
  const navigate = useNavigate();
  const [nextToken] = useState(null);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [rowSelection, setRowSelection] = useState([]);
  const [checkedRows, setCheckedRows] = useState({ isAllRowsChecked: false });
  const [tableData, setTableData] = useState([]);
  const { sidebarBrandId } = useMemberContext();
  const handleError = useHandleError();

  const [addLocationToVoucher, { loading: addLocationToVoucherLoading }] =
    useMutation(ADD_LOCATION_TO_VOUCHER);

  const {
    data: getVoucherLocationData,
    loading: getVoucherLocationLoading,
    error: getVoucherLocationError,
    refetch,
  } = useQuery(GET_VOUCHER_LOCATION, {
    fetchPolicy: "no-cache",
    variables: {
      voucherId,
    },
    onCompleted: (data) => {},
  });

  const { data: fetchVoucherData } = useQuery(GET_VOUCHER_BY_ID, {
    fetchPolicy: "no-cache",
    variables: {
      id: voucherId,
    },
    onCompleted: (data) => {},
  });

  useEffect(() => {
    const findLocationsByBrand = locationAccess?.brands.find(
      (curBrand) => curBrand.brandId === sidebarBrandId
    );
    // if (tableData?.length < 1) return;
    const locationData = findLocationsByBrand?.locations?.map((curRow) => {
      const isVoucherAppliedToRow =
        getVoucherLocationData?.getVoucherLocationByVoucherId?.items?.some(
          (curLocation) => curLocation.locationId === curRow.locationId
        );

      return {
        ...curRow,
        isVoucherAppliedToRow,
      };
    });
    setTableData(locationData);

    // setGetLocation(filterLocationValues);
  }, [sidebarBrandId, locationAccess, getVoucherLocationData]);

  // useEffect(() => {

  // }, [getVoucherLocationData]);

  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  const handleClick = () => {
    //  TODO: need to send mutation here once BE fixes the argument to accept location ids as an array form
    const selectedRowIndexes = Object.keys(rowSelection);

    const selectedLocationId = selectedRowIndexes.map(
      (curIndex) => tableData[curIndex].locationId
    );
    addLocationToVoucher({
      variables: {
        input: {
          action: "APPLY_LOCATIONS",
          data: {
            locationIds: selectedLocationId,
            voucherId,
          },
        },
      },
      onCompleted: (data) => {
        toast.success("Applied successfully!");
        setRowSelection({});
        refetch();
      },

      onError: (err) => {
        handleError(`${err}`);
      },
    });
  };
  const columns = [
    {
      id: "select",
      header: ({ table }) => {
        return (
          <div className="head-checkbox-container">
            <div className="px-1">
              {checkedRows?.isAllRowsChecked ? (
                <Checkbox type="checkbox" disabled={true} />
              ) : (
                // <input type="checkbox" checked disabled />
                <Checkbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                  }}
                />
              )}
            </div>
            <div>SELECT ALL</div>
          </div>
        );
      },
      cell: ({ row }) => (
        <div className="px-1">
          {checkedRows?.hasOwnProperty(row.original.id) ||
          row?.original?.isVoucherAppliedToRow ? (
            // <input type="checkbox" checked disabled />
            <Checkbox type="checkbox" disabled={true} />
          ) : (
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          )}
        </div>
      ),
    },

    {
      accessorFn: (row) => row?.locationName ?? "",
      header: "Location",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "locationName",
    },
  ];

  if (getVoucherLocationLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (getVoucherLocationError) {
    return (
      <div>
        <div>Error: Problem getting locations</div>
      </div>
    );
  }
  return (
    <>
      <div className="apply-membership-to-container">
        <Header pageTitle="Apply to Location" />

        <div className="membership-landing-content">
          <div className="search-and-button-row mt-32 header-voucher">
            <p className="pl-42 voucher-code">
              {fetchVoucherData?.getVoucher?.voucherCode}
            </p>
            <div className="search-container">
              <form onSubmit={onSearchBarSubmit}>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="search"
                      id="search"
                      value={searchBarInputVal}
                      onChange={handleChange}
                      placeholder="Search by location name"
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="table landing-table">
            <Table
              data={tableData}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              columns={columns}
              rowsPerPage={10}
              nextToken={nextToken}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              showTwoBtns={true}
              handleCancel={() => navigate(-1)}
              handleClick={handleClick}
              btnName="Apply"
              isBtnLoading={addLocationToVoucherLoading}
              isBtnDisabled={
                checkedRows.isAllRowsChecked ||
                Object.keys(rowSelection).length === 0
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVoucherToLocation;
