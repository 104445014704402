import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useMemberContext } from "../../../contexts/MemberContext";
import { API } from "../../../utils/API";
import useCustomFetch from "../../../hooks/useCustomFetch";
import GenerateBox from "../GenerateBox";
import Table from "../../table/Table";
import { BeatLoader } from "react-spinners";
import ExportGroup from "../../export/ExportGroup";
import { AiOutlineSearch } from "react-icons/ai";
import { useHandleError } from '../../../hooks/useHandleError';

export default function MembershipBreakupReport() {
  const paginationData = useRef();
  const { sidebarLocationId, sidebarBrandId } = useMemberContext();
  const [endPoint, setEndPoint] = useState();
  const [params, setParams] = useState();
  const [tableData, setTableData] = useState([]);

  const [generate, setGenerate] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [startDate, setStartDate] = useState("");
  const handleError = useHandleError();
  
  const { data, isLoading, error } = useCustomFetch(
    endPoint && endPoint,
    params && params
  );
  useEffect(() => {
    if (startDate && generate) {
      handleGenerate(100, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generate]);
  useEffect(() => {
    if (error && endPoint) {
      setTableData([]);
      handleError(error?.response?.data?.message ?? "Error when fetching api");
    } else if (data) {
      if (params.page !== 1) {
        setTableData([...tableData, ...data.data.data]);
      } else setTableData([...data.data.data]);
    }
    setGenerate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);
  const columns = [
    {
      accessorKey: "membershipName",
      header: "MEMBERSHIP",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "membershipName",
    },
    {
      accessorKey: "membershipType",
      header: "MEMBERSHIP TYPE",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "membershipType",
    },
    {
      accessorFn: (row) => `${row?.isActive ? "YES" : "NO"}`,
      header: "STATUS",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "isActive",
    },
    {
      accessorFn: (row) => `${row?.isLiveOnWebsite ? "YES" : "NO"}`,
      header: "LIVE",
      cell: (info) => (info.getValue() ? "Yes" : "No"),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "isLiveOnWebsite",
    },
    {
      accessorKey: "activeMembersCount",
      header: "ACTIVE MEMBER",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "activeMembersCount",
    },
  ];
  const handleGenerate = (limit, page) => {
    paginationData.current = { limit: limit, page: page };
    let parameters = {
      brandId: sidebarBrandId,
      reportDate: startDate,
      locationId: sidebarLocationId,
    };
    if (sidebarLocationId === 0) {
      delete parameters.locationId;
    }
    if (startDate) {
      const pagination = limit ? { limit: limit, page: page } : undefined;
      if (pagination) parameters = { ...parameters, ...pagination };
      setEndPoint(API.membershipBreakupReport);
      setParams(parameters);
    }
  };
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const handleNextToken = () => {
    handleGenerate(
      paginationData.current.limit,
      paginationData.current.page + 1
    );
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  return (
    <>
      <GenerateBox
        setStartDate={setStartDate}
        setGenerate={setGenerate}
        onlyStartDate={true}
        type="membershipBreakup"
      />
      {tableData.length && !isLoading ? (
        <div className="actions-row report-tables mt-24 mb-12">
          <ExportGroup
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
          />
          <div className="search-container">
            <form onSubmit={onSearchBarSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="payment-report-search"
                    id="payment-report-search"
                    value={searchBarInputVal}
                    onChange={handleChange}
                    placeholder="Search by Membership Name"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : null}
      {tableData.length > 0 && (
        <p className="mt-24 fs-14">TOTAL: {data?.data?.total}</p>
      )}

      {isLoading ? (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      ) : null}
      {!isLoading ? (
        <div className="report-table-container-all">
          <Table
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            hasFooter={false}
            nextToken={tableData.length < data?.data?.total}
            handleNextToken={handleNextToken}
          />
        </div>
      ) : null}
    </>
  );
}
