import { zonedTimeToUtc, utcToZonedTime, format } from "date-fns-tz";
//only accept date as string, do not accept date obj, eg: date="2023-06-26" or "2023-12-18T10:30:00".
export const getUTCdate = (date, timezone) => {
  //if no timezone arg, take user location system setting timezone
  const currentTimeZone =
    timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcDate = zonedTimeToUtc(date, currentTimeZone);
  return utcDate.toISOString();
};

export const readUTCtimeToTargetTimeZone = (
  utcTime,
  targetTimezone,
  formatShape
) => {
  const convertedDateTime = utcToZonedTime(utcTime, targetTimezone);
  const formattedDateTime = format(convertedDateTime, formatShape);
  return formattedDateTime;
};
