import { useNavigate } from "react-router-dom";
import { useMemberContext } from "../contexts/MemberContext";
import { SET_MEMBER_ID } from "../actions";

export const useCheckMemberProfile = () => {
  const navigate = useNavigate();
  const { createLog, dispatch } = useMemberContext();
  const checkMemberProfile = (memberName, memberId) => {
    dispatch({
      type: SET_MEMBER_ID,
      payload: {
        memberId: memberId,
      },
    });
    navigate(`/dashboard/member/${memberId}`);
    createLog(`Clicked ${memberName}`, "member", `${memberId}`);
  };
  return { checkMemberProfile };
};
