import * as yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";

export const userPersonalInfoSchema = yup.object().shape({
  givenName: yup.string().required("Given Name is required"),
  surname: yup.string().required("Surname is required"),
  dob: yup
    .string()
    .required("DOB is Required"),
    gender: yup.string().required("Gender is required")
});

export const userContactInfoSchema = yup.object().shape({
  mobileNumber: yup
    .string()
    .required("Phone number is required")
    .test("is-valid-number", "Invalid Number", (mobileNumber) => {
      return mobileNumber ? isValidPhoneNumber(mobileNumber) : true;
    }),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  secondaryEmail:  yup
    .string()
    .email("Invalid email address"),
  state: yup.string().required("State is required"),
  address: yup.string(),
  postCode: yup
    .string()
    .required("Post Code is required")
    .matches(/^[0-9]+$/, "Must be only digits"),
  country: yup.string().required("Country is required"),
  suburb: yup.string().required("Suburb is required"),
});

export const userEmploymentSchema = yup.object().shape({
  joinedDateTime: yup.string().required("Join date is required"),
  role: yup.string().required("Role is required"),
  aliasMemberId: yup.string().required("MemberId is required"),
  staffKind: yup.string().required("Type is required"),
});
