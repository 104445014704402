import "./modal.scss";
import "../../components/location/singleLocation/maintenance_tab/maintenance.scss";

import React, { useState } from "react";
import { format, startOfDay, endOfDay } from "date-fns";

import { Formik, Form } from "formik";
import DatePicker from "../formUiElements/input/DatePicker";
import Button from "../formUiElements/Button";

import { maintenanceDatesSchema } from "../../validations/Validation";
import checkDateRangesOverlap from "../../utils/checkDateRangesOverlap";

const AddMaintenanceModal = (props) => {
  const { modalClose, listMaintenanceDates, onCreateMaintenance, loading } =
    props;

  const [overlappedWith, setOverlappedWith] = useState([]);

  const today = new Date();

  const handleFormatDate = (value) => {
    try {
      return format(new Date(value), "yyyy-MM-dd");
    } catch (error) {
      return "INVALID_DATETIME";
    }
  };

  const handleFormChange = () => {
    if (overlappedWith?.length > 0) setOverlappedWith([]);
  };

  const handleSubmit = async (values, submitProps) => {
    const { setErrors } = submitProps;
    const { startDateTime, endDateTime } = values;

    const start = startOfDay(new Date(startDateTime)).toISOString();
    const end = endOfDay(new Date(endDateTime)).toISOString();

    if (listMaintenanceDates.length > 0) {
      const overlappedWith = listMaintenanceDates
        .map((maintenance, index) => ({ ...maintenance, index: index + 1 }))
        .filter((maintenance) =>
          checkDateRangesOverlap(
            { start: startDateTime, end: endDateTime },
            { start: maintenance.startDateTime, end: maintenance.endDateTime }
          )
        );

      if (overlappedWith.length > 0) {
        setOverlappedWith([...overlappedWith]);
        return setErrors({
          startDateTime: "Overlapped",
          endDateTime: "Overlapped",
        });
      }
    }

    onCreateMaintenance(start, end);
  };

  return (
    <div className="add-maintenance">
      <Formik
        initialValues={{ startDateTime: "", endDateTime: "" }}
        onSubmit={handleSubmit}
        validationSchema={maintenanceDatesSchema}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form onChange={handleFormChange} className="status-modal pd-32">
              <h3 className="fs-35 uppercase">Add Maintenance</h3>

              <div className="date-range">
                <div className="date-control">
                  <label
                    htmlFor="startDateTime"
                    className="label fs-12 text-gray"
                  >
                    Start date
                  </label>
                  <DatePicker
                    name="startDateTime"
                    className="control fs-12 text-white"
                    value={formik.values.startDateTime}
                    error={formik.errors.startDateTime}
                    onChange={formik.handleChange}
                    min={format(today, "yyyy-MM-dd")}
                  />
                </div>

                <div className="date-control">
                  <label
                    htmlFor="endDateTime"
                    className="label fs-12 text-gray"
                  >
                    End date
                  </label>
                  <DatePicker
                    name="endDateTime"
                    className="control fs-12 text-white"
                    value={formik.values.endDateTime}
                    error={formik.errors.endDateTime}
                    onChange={formik.handleChange}
                    min={format(today, "yyyy-MM-dd")}
                  />
                </div>
              </div>

              {overlappedWith?.length > 0 && (
                <div className="overlapped-wrapper">
                  <p className="title fs-12 text-gray">Overlapped with:</p>
                  <div className="overlapped-with">
                    {overlappedWith.map((maintenance) => (
                      <div key={maintenance.id} className="overlapped fs-12">
                        <p>{maintenance.index}</p>
                        <div className="overlapped-date-range">
                          <p>{handleFormatDate(maintenance.startDateTime)}</p>
                          <p>{handleFormatDate(maintenance.endDateTime)}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="btn-group">
                <Button
                  loading={loading}
                  disabled={loading}
                  name="Add"
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-confirm btn-small"
                />

                <Button
                  name="Cancel"
                  btntype="button"
                  btnname="cancel-button"
                  className="btn btn-deny btn-small"
                  click={modalClose}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddMaintenanceModal;
