import "./studio.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { locationStudioSchema } from "../../../../validations/LocationValidation";
import { GET_STUDIO_BY_ID } from "../../../../gqloperations/queries";
import { UPDATE_LOCATION_STUDIO } from "../../../../gqloperations/mutations";
import { useLocationContext } from "../../../../contexts/LocationContext";
import { Input, Button, Header} from "../../../../components";
import { useHandleError } from '../../../../hooks/useHandleError';

const EditStudio = () => {
  const { state, dispatch, getLocationById } = useLocationContext();
  const [editStudio] = useMutation(UPDATE_LOCATION_STUDIO);
  let navigate = useNavigate();
  const handleError = useHandleError();
  let { id } = useParams();
  const {
    loading: studioLoading,
    error: studioError,
    data: studioData,
    refetch,
  } = useQuery(GET_STUDIO_BY_ID, {
    variables: {
      id: id,
    },
  });

  const handleCancel = () => {
    dispatch({ type: "SET_INDEX", payload: 6 });
    navigate(`/dashboard/location/specificLocation/${state.id}`);
  };

  const onSubmit = async (values, OnSubmitProps) => {
    try {
      await editStudio({
        variables: {
          input: {
            studioName: values.studioName.toUpperCase(),
            description: values.description,
            id,
          },
        },
        onCompleted: () => {
          OnSubmitProps.setSubmitting(false);
          OnSubmitProps.resetForm();
          toast.success("Studio Edited Successfully");
          dispatch({ type: "SET_INDEX", payload: 3 });
          navigate(`/dashboard/location/specificLocation/${state.id}`);
          getLocationById();
          refetch();
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
    OnSubmitProps.setSubmitting(false);
  };

  if (studioLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (studioError) {
    return (
      <div>
        <div>Couldn't load members</div>
      </div>
    );
  }
  return (
    <>
      {state.location && (
        <Formik
          initialValues={{
            studioName: studioData?.getLocationStudio?.studioName,
            description: studioData?.getLocationStudio?.description,
          }}
          validationSchema={locationStudioSchema}
          onSubmit={(values, OnSubmitProps) => {
            onSubmit(values, OnSubmitProps);
          }}
        >
          {(formik) => {
            return (
              <>
                <Form>
                  <Header pageTitle="Edit Studio" />
                  <div className="edit-studio-container pin-card pd-12 mt-32 mb-12">
                    <div className="details-container pin-codes">
                      <div className="pb-12">
                        <Input
                          type="text"
                          label="Studio Name * "
                          name="studioName"
                          id="studioName"
                          autoComplete="off"
                        />
                      </div>
                      <div>
                        <p className="fs-12 pb-12 text-gray">Description</p>
                        <textarea
                          className="reason-box fs-12"
                          id="description"
                          name="description"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="save-btn right-btn mt-18">
                    {/* <Button
                      name="SAVE"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-confirm btn-large fs-12 "
                    />
                    <Button
                      name="CANCEL"
                      btntype="reset"
                      btnname="reset"
                      className="btn btn-deny btn-large fs-12"
                      click={handleCancel}
                    /> */}
                    <Button
                      loading={formik.isSubmitting}
                      name={"Save"}
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-primary btn-lg fs-12"
                      disabled={formik.isSubmitting ? true : false}
                    />
                    <Button
                      name="Cancel"
                      btntype="reset"
                      btnname="reset"
                      className="btn btn-large btn-deny"
                      click={handleCancel}
                    />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};
export default EditStudio;
