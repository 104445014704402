import "./addNote.scss";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts";
import { BeatLoader } from "react-spinners";
import { CREATE_NOTE } from "../../gqloperations/mutations";
import { GET_NOTES_BY_MEMBERID } from "../../gqloperations/queries";
import { useMutation } from "@apollo/client";
import React, { useContext } from "react";
import Button from "../formUiElements/Button";
import { useMemberContext } from "../../contexts/MemberContext";
import { useHandleError } from '../../hooks/useHandleError';

const AddNote = (props) => {
  const { member, notesByMemberId, setMemberInteraction } = useMemberContext();
  const { id } = useParams();
  const { loggedUser } = useContext(AuthContext);
  const timestamp = new Date().getTime();
  const date = format(timestamp, "E d/LL/yy k:mm:ss");
  const handleError = useHandleError();

  const onSubmit = (values, OnSubmitProps) => {
    setMemberInteraction("");
    createNote({
      variables: {
        input: {
          ...values,
        },
      },
      onCompleted: () => {
        OnSubmitProps.resetForm();
        props.onClick();
        toast.success("Note Created Successfully");
        setMemberInteraction("notes");
        // notesByMemberId({ memberId: id });
      },
      onError: () => {
        handleError("Note Creation Failed");
      },
    });
    OnSubmitProps.setSubmitting(false);
  };

  //calling a mutations to Guest Entry
  const [createNote] = useMutation(CREATE_NOTE);
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          memberId: `${member.getMember.memberId}`,
          note: "",
          addedById: `${loggedUser.getMember.memberId}`,
          addedBy: `${loggedUser.getMember.givenName}`,
        }}
        // validationSchema={guestEntrySchema}
        onSubmit={async (values, OnSubmitProps) => {
          await onSubmit(values, OnSubmitProps);
        }}
      >
        {(formik) => {
          return (
            <div className="note-container pd-32">
              <Form>
                <div className="note-header pb-18 uppercase">
                  <h2 className="fs-35">Add New Note</h2>
                </div>
                <div className="log-details-container">
                  <div className="date fs-12">
                    <p className="date-title fs-10 text-gray">Date</p>
                    <p className="date-content">{date}</p>
                  </div>

                  <div className="name">
                    <p className="name-title fs-10 text-gray">Added By</p>
                    <p className="name-content fs-12">
                      {loggedUser?.getMember?.givenName}{" "}
                      {loggedUser?.getMember?.surname}
                    </p>
                  </div>
                </div>

                <div className="note-content-container">
                  <h3 className="fs-10 text-gray">Note</h3>
                  <div className="note-content">
                    <textarea
                      className="reason-box fs-12"
                      id="note"
                      name="note"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.note}
                    ></textarea>
                  </div>
                </div>

                <div className="btn-container">
                  {/* {formik.isSubmitting && (
                    <Button
                      name={<BeatLoader color="white" />}
                      btntype="submit"
                      btnname="submit"
                      disabled={true}
                      className="btn btn-transparent btn-small fs-8"
                    />
                  )}
                  {!formik.isSubmitting && (
                    <Button
                      name="Update"
                      btntype="submit"
                      btnname="submit"
                      // disabled={!Formik.isValid || Formik.isSubmitting}
                      className="btn btn-confirm btn-small fs-12"
                    />
                  )} */}
                  <Button
                    loading={formik.isSubmitting}
                    name={"Update"}
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-primary btn-lg fs-12"
                    disabled={formik.isSubmitting ? true : false}
                  />
                  <Button
                    name="cancel"
                    btntype="button"
                    btnname="cancel"
                    click={props.onClick}
                    className="btn btn-deny btn-small fs-12"
                  />
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default AddNote;
