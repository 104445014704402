import React from "react";
import { Formik, Form } from "formik";
import { Button, Input } from "../../components";

const ChangeEmailModal = (props) => {
  return (
    <Formik
      initialValues={{
        email: "",
        oldEmail: props.email,
      }}
      //   validationSchema={smsSchema}
      onSubmit={(values, OnSubmitProps) => {
        props.onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <div className="">
            <div className="">
              <h3 className="fs-35 item-centered pb-24">Update Email</h3>
            </div>
            <Form>
              <div className="member-details">
                <Input
                  type="text"
                  label="old email"
                  name="oldEmail"
                  id="oldEmail"
                  readOnly
                />

                <Input type="text" label="new email" name="email" id="email" />
              </div>

              <div className="entry-btn-group item-centered">
                <Button
                  loading={formik.isSubmitting}
                  name="save"
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-confirm fs-12"
                  disabled={formik.isSubmitting ? true : false}
                />
                <Button
                  name="cancel"
                  btntype="button"
                  btnname="cancel"
                  click={props.onClick}
                  className="btn btn-deny btn-small fs-12"
                />
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default ChangeEmailModal;
